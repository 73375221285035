import React, { useMemo, useCallback } from 'react';
import PT from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import IngredientTitle from './IngredientTitle';
import IngredientValue from './IngredientValue';

import GET_ITEM, { tableParameterFragment, tableValueFragment } from '../../../gql/get-item';
import { UPDATE_FORMULATION_VALUE } from '../../../../../services/formulations/gql';
import { cache } from '../../../../../services/apollo-client/client';
import { openAppSnackbarNotification } from '../../../../../services/snackbar-notifications/actions';

const ADD_FORMULATION_VALUE = gql`
  ${tableParameterFragment}
  ${tableValueFragment}
  mutation AddFormulationValue($itemId: ID!, $formulationId: ID!, $titleItemId: ID!, $value: Float) {
    addFormulationValue(
      tableItemId: $itemId,
      tableProtocolId: $formulationId,
      titleTableItemId: $titleItemId,
      value: $value
    ) {
      tableParameter {
        ...TableParameterFragment
      }
      tableValue {
        ...TableValueFragment
      }
    }
  }
`;

const DELETE_FORMULATION_VALUE = gql`
  mutation DeleteFormulationValue($itemId: ID!, $parameterId: ID!) {
    deleteFormulationValue(tableItemId: $itemId, tableParameterId: $parameterId)
  }
`;

const FormulationIngredient = ({
  itemId,
  protocolId,
  id,
  stateKey,
  readOnly,
  title,
  value,
  unit,
  edit,
  tableIds,
  usedItemIds,
  allIngredientsByItemId,
  onEdit,
  onCancel,
  onChangeTitle,
  onChangeValue,
  onSubmit,
  onAddFormulationValue,
  onUpdateFormulationValue
}) => {
  const [addFormulationValue, { loading: addLoading }] = useMutation(ADD_FORMULATION_VALUE);
  const [updateFormulationValue, { loading: updateLoading }] = useMutation(UPDATE_FORMULATION_VALUE);
  const [deleteFormulationValue, { loading: deleteLoading }] = useMutation(DELETE_FORMULATION_VALUE);

  const dispatch = useDispatch();

  const disableSubmit = useMemo(() => {
    if(!id)
      return !title || !value;

    return !value;
  }, [id, title, value]);

  const handleEdit = useCallback(() => {
    onEdit(stateKey);
  }, [stateKey, onEdit]);

  const handleCancel = useCallback(() => {
    onCancel(stateKey);
  }, [stateKey, onCancel]);

  const handleChangeTitle = useCallback((value) => {
    if(value)
      onChangeTitle(stateKey, {
        id: value.value,
        title: value.label,
        code: value.code
      });
  }, [stateKey, onChangeTitle]);

  const handleChangeValue = useCallback((ev) => {
    const value = Number.parseFloat(ev.target.value);

    onChangeValue(stateKey, Number.isNaN(value) ? null : value);
  }, [stateKey, onChangeValue]);

  const handleSubmit = useCallback(async () => {
    try {
      const existingIngredient = allIngredientsByItemId[title.id];

      if(!id && !existingIngredient) {
        const { data } = await addFormulationValue({
          variables: {
            itemId,
            formulationId: protocolId,
            titleItemId: title.id,
            value
          }
        });

        cache.updateQuery({
          query: GET_ITEM,
          variables: { itemId }
        }, ({ tableItem }) => {
          const protocolIndex = tableItem.table.tableProtocols.findIndex(
            ({ id }) => id === protocolId
          );
          const protocol = tableItem.table.tableProtocols[protocolIndex];
          const protocols = tableItem.table.tableProtocols.toSpliced(protocolIndex, 1, {
            ...protocol,
            tableParameters: protocol.tableParameters.concat(data.addFormulationValue.tableParameter)
          });

          return {
            tableItem: {
              ...tableItem,
              table: {
                ...tableItem.table,
                tableProtocols: protocols
              },
              values: tableItem.values.concat(data.addFormulationValue.tableValue)
            }
          };
        });

        onAddFormulationValue?.(data.addFormulationValue);
      } else {
        const { data } = await updateFormulationValue({
          variables: {
            itemId,
            parameterId: id ?? existingIngredient.id,
            value
          }
        });

        onUpdateFormulationValue?.(data.updateFormulationValue);
      }

      onSubmit(stateKey);
    } catch(e) {
      dispatch(openAppSnackbarNotification({
        variant: 'ERROR',
        message: e.message
      }));
    }
  }, [
    itemId,
    protocolId,
    id,
    stateKey,
    title,
    value,
    allIngredientsByItemId,
    dispatch,
    addFormulationValue,
    updateFormulationValue,
    onSubmit,
    onAddFormulationValue,
    onUpdateFormulationValue
  ]);

  const handleDelete = useCallback(async () => {
    try {
      await deleteFormulationValue({
        variables: {
          itemId,
          parameterId: id
        }
      });

      const deletedValueCacheId = cache.identify({
        __typename: 'TableValueQuantity',
        tableItemId: itemId,
        tableParameterId: id
      });

      cache.evict({ id: deletedValueCacheId });
    } catch(e) {
      dispatch(openAppSnackbarNotification({
        variant: 'ERROR',
        message: e.message
      }));
    }
  }, [itemId, id, deleteFormulationValue, dispatch]);

  return (
    <TableRow
      sx={{
        maxWidth: '786px'
      }}
    >
      <TableCell
        align="left"
        sx={{
          padding: '2px 0',
          width: '50%'
        }}
      >
        <IngredientTitle
          readOnly={Boolean(id)}
          value={title}
          tableIds={tableIds}
          usedItemIds={usedItemIds}
          onChange={handleChangeTitle}
        />
      </TableCell>

      <TableCell
        align="left"
        sx={{
          padding: '2px 0',
          width: '50%'
        }}
      >
        <IngredientValue
          readOnly={readOnly}
          edit={edit}
          value={value}
          unit={unit}
          disableSubmit={disableSubmit}
          loading={addLoading || updateLoading || deleteLoading}
          onEdit={handleEdit}
          onCancel={handleCancel}
          onChange={handleChangeValue}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      </TableCell>
    </TableRow>
  );
};

FormulationIngredient.propTypes = {
  itemId: PT.string,
  protocolId: PT.string,
  id: PT.string,
  stateKey: PT.string,
  readOnly: PT.bool,
  title: PT.shape({
    id: PT.string,
    title: PT.string,
    code: PT.string,
    viewerMaxRole: PT.string
  }),
  value: PT.number,
  unit: PT.shape({
    name: PT.string
  }),
  tableIds: PT.arrayOf(PT.string),
  allIngredientsByItemId: PT.object,
  usedItemIds: PT.instanceOf(Set),
  edit: PT.bool,
  onEdit: PT.func,
  onCancel: PT.func,
  onChangeTitle: PT.func,
  onChangeValue: PT.func,
  onSubmit: PT.func,
  onAddFormulationValue: PT.func,
  onUpdateFormulationValue: PT.func
};

export default FormulationIngredient;
