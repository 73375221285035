import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Menu from '@mui/material/Menu';
import ArrowRight from '@mui/icons-material//ArrowRight';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import Box from '@mui/material/Box';

import { SubMenuListItem, Link } from './styles';

const useStyles = makeStyles({
  popOverRoot: {
    pointerEvents: 'none'
  }
});

const SubMenuItem = ({ link, label, submenu, onClick, parentOpen, onItemClick }) => {
  const currentlyHovering = useRef(false);

  const styles = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleHover = useCallback((event) => {
    if (submenu && anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }, [submenu, anchorEl]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMenuHover = useCallback(() => {
    if (submenu) {
      currentlyHovering.current = true;
    }
  }, [submenu]);

  const handleClick = useCallback(() => {
    onClick();
    onItemClick?.();
  }, [onClick, onItemClick]);

  const handleCloseHover = useCallback(() => {
    currentlyHovering.current = false;

    setTimeout(() => {
      if (!currentlyHovering.current) {
        handleClose();
      }
    }, 50);
  }, [handleClose]);

  return (
    <SubMenuListItem
      href={link}
      to={link}
      component={link ? Link : Box}
      onClick={handleClick}
      onMouseEnter={handleHover}
      onMouseLeave={handleClose}
    >
      <ListItemText className="menu-item-text">
        <Typography variant="body1">{label}</Typography>
        {submenu && <ArrowRight />}
      </ListItemText>
      {submenu && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={parentOpen && open}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handleClose}
          MenuListProps={{
            onMouseEnter: handleMenuHover,
            onMouseLeave: handleCloseHover,
            style: {
              pointerEvents: 'auto',
              maxHeight: '300px',
              overflow: 'auto'
            }
          }}
          PopoverClasses={{
            root: styles.popOverRoot
          }}
        >
          {submenu?.map((key) => (
            <SubMenuItem
              key={key.label + key.link}
              label={key.label}
              link={key.link}
              onClick={handleClose}
              onItemClick={key.onItemClick}
            />)
          )}
        </Menu>)}
    </SubMenuListItem>
  );
};

SubMenuItem.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
  submenu: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
  })),
  onClick: PropTypes.func,
  parentOpen: PropTypes.bool,
  onItemClick: PropTypes.func,
};

export default SubMenuItem;
