import styled from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { COLOR_BLACK, COLOR_PRIMARY_CONTRAST, COLOR_PRIMARY_DARK } from '../../../../../styles';

export const ListItem = styled(Box)`
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  padding: 4px;
    
  border-left: ${({ selected }) => selected ? `1px solid ${COLOR_PRIMARY_DARK}` : 'none'};
  background-color: ${({ selected }) => selected ? COLOR_PRIMARY_CONTRAST : 'none'};

  div {
    background-color: 'red';
    color: 'red';
  }
    
  &:hover {
    cursor: pointer;

    .menu {
      visibility: initial;
    }

    ${({ selected }) => !selected &&
      `
        .MuiSvgIcon-root {
          color: ${COLOR_BLACK}
        }
        
        .MuiTypography-root {
          color: ${COLOR_BLACK}
        }
    `}
  }
`;

export const InfoBlock = styled(Box)`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
`;

export const MenuBlock = styled(Box).attrs({ className: 'menu' })`
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #FFF;
  padding: 4px;
  gap: 4px;
  visibility: ${({ changed }) => !changed ? 'hidden' : 'initial'};

  :hover {
    cursor: pointer;
  }
`;

export const Title = styled(Typography)`
  color: ${({ selected }) => selected ? COLOR_PRIMARY_DARK : '#666'};
  font-size: 14px;
`;

export const ChangedLabel = styled(Typography)`
  color: ${COLOR_PRIMARY_DARK};
  font-size: 10px;
`;
