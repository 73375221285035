import { gql } from '@apollo/client';

export const FETCH_TOP_TABLES = gql`
  query FetchTopTables {
    platformStats {
      topTables {
        id
        title
        numberOfTableItems
        numberOfNewTableItems
        lastActive
      }
    }
  }
`;
