import styled from 'styled-components';
import MenuItemMUI from '@mui/material/MenuItem';

const MenuItem = styled(MenuItemMUI)`
  &:hover {
    background-color: #ECF9FB;
  }
`;

export default MenuItem;
