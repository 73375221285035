import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { generatePath } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

import { SNACKBAR_TYPES } from '../../../components/AppSnackbar';
import { openAppSnackbarNotification } from '../../../services/snackbar-notifications/actions';
import SuccessCreateMessage from '../../../components/SuccessCreateMessage';

import { GET_FOLDERS, CREATE_FOLDER } from './gql';
import { FOLDERS } from '../gql';

import { COLOR_BLACK } from '../../../styles';
import { hasAdminAccess } from '../../../utils/roles';

import { routes } from '../../../services/session/constants';
import TYPE from '../../../components/SuccessCreateMessage/constants';

const styles = {
  dialog: {
    '.MuiPaper-root': {
      width: '530px',
    }
  },
  formControl: {
    margin: '10px 0'
  },
  label: {
    position: 'relative',
    transform: 'translate(0, 6px) scale(0.75)',
    color: COLOR_BLACK
  },
  input: {
    borderRadius: '4px',
    height: '32px',
    fontSize: '14px',
    '.MuiOutlinedInput-input': {
      p: '4px 8px'
    }
  }
};

const AddFolderDialog = ({ onClose, defaultFolder, refetchList }) => {

  const dispatch = useDispatch();

  const { data } = useQuery(GET_FOLDERS, {
    fetchPolicy: 'cache-and-network'
  });
  const [createFolder, { loading }] = useMutation(CREATE_FOLDER, {
    refetchQueries: refetchList ?? [FOLDERS]
  });

  const [title, setTitle] = useState('');
  const [folder, setFolder] = useState(defaultFolder ?? '');
  const [error, setError] = useState(null);

  const handleTitleChange = useCallback((e) => {
    setTitle(e.target.value);
  }, []);

  const handleFolderChange = useCallback((e) => {
    setFolder(e.target.value);
  }, []);

  const handleSubmit = useCallback(async () => {
    setError(null);

    const variables = {
      data: {
        title: title,
      },
    };

    if (folder)
      variables.data.parentId = folder;

    try {
      const { data } = await createFolder({
        variables
      });

      dispatch(
        openAppSnackbarNotification({
          variant: SNACKBAR_TYPES.SUCCESS,
          message: (
            <SuccessCreateMessage
              title={data.createFolder.title}
              link={generatePath(routes.FOLDER_SETTINGS, {
                id: data.createFolder.id,
              })}
              type={TYPE.FOLDER}
            />
          )
        })
      );

      onClose();
    } catch (e) {
      setError(e.message);
    }
  }, [createFolder, dispatch, folder, onClose, title]);

  const foldersList = useMemo(() => {
    const folders = data?.folders ?? [];

    return folders.filter(folder => hasAdminAccess(folder.viewerMaxRole))
      .sort((first, second) => first.title.localeCompare(second.title));
  }, [data?.folders]);

  return (
    <Dialog
      open
      sx={styles.dialog}
      onClose={onClose}
    >
      <DialogTitle>
        Add Folder
      </DialogTitle>

      <DialogContent>
        <FormControl
          required
          fullWidth
        >
          <InputLabel
            shrink
            sx={styles.label}
          >
            Name
          </InputLabel>

          <OutlinedInput
            sx={styles.input}
            value={title}
            onChange={handleTitleChange}
            autoFocus
          />
        </FormControl>

        <FormControl
          fullWidth
          sx={styles.formControl}
        >
          <InputLabel
            shrink
            sx={styles.label}
          >
            Folder
          </InputLabel>

          <Select
            sx={styles.input}
            value={folder}
            onChange={handleFolderChange}
            renderValue={(value) => (
              <Typography variant="body2">
                {value ? foldersList.find(folder => folder.id === value).title : ''}
              </Typography>
            )}
          >
            <MenuItem
              value={''}
            >
              None
            </MenuItem>

            {foldersList.map(folder => (
              <MenuItem
                key={folder.id}
                value={folder.id}
              >
                {folder.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {error ?
          <FormHelperText error>
            {error}
          </FormHelperText>
          : null
        }
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
        >
          Cancel
        </Button>

        <LoadingButton
          loading={loading}
          onClick={handleSubmit}
          disabled={!title}
          variant="contained"
        >
          Add Folder
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

AddFolderDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  defaultFolder: PropTypes.string,
  refetchList: PropTypes.array,
};

export default AddFolderDialog;
