import React from 'react';
import PropTypes from 'prop-types';

const FileDropAreaTarget = ({
  dndEvents,
  children,
  className = '',
  ...props
}) => (
  <div
    {...dndEvents}
    {...props}
    className={className}
  >
    {children}
  </div>
);

FileDropAreaTarget.propTypes = {
  dndEvents: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default FileDropAreaTarget;
