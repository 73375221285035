import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';
import { createEditor } from 'slate';
import PT from 'prop-types';

import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import FormulaTextarea from './FormulaTextarea';
import UnitsBlock from './UnitsBlock';
import FormulasList from './FormulasList';
import ColumnsList from './ColumnsList';
import withColumns from './FormulaTextarea/utils/withColumns';

import { FORMULAS } from './gql';
import { COLOR_GREY_LIGHTER } from '../../../../../../../../../../styles';

const styles = {
  formControl: {
    marginTop: '10px'
  },
  label: {
    transform: 'translate(0px, 0px) scale(1)',
    fontSize: '12px',
    lineHeight: '20px',
    color: 'rgba(0,0,0,.6)'
  },
  formulationsBlock: {
    display: 'flex',
    flexDirection: 'row',
    mt: '10px',
    gap: '8px'
  },
  formulationsIcon: {
    color: COLOR_GREY_LIGHTER,
    width: '12px',
    height: '12px'
  },
  formulationsTitle: {
    fontSize: '10px',
    textTransform: 'uppercase',
    color: COLOR_GREY_LIGHTER
  }
};

const FormulaEditor = ({ columns, parameterId, calculation, unit, setCalculation, setUnit }) => {
  const { data: formulasData } = useQuery(FORMULAS);

  const [editor] = useState(() => withColumns(withHistory(withReact(createEditor()))));
  const [search, setSearch] = useState('');

  const filteredColumnsList = useMemo(() => {
    return columns.map((column) => {
      const filteredChildren = column.tableParameters?.filter(child =>
        (child.title ?? child.titleTableItem.title).toLowerCase().includes(search.toLowerCase())
      );

      if (filteredChildren.length || column.title.toLowerCase().includes(search.toLowerCase()))
        return { ...column, tableParameters: filteredChildren };

      return null;
    }).filter(Boolean);
  }, [columns, search]);

  const columnsList = useMemo(() => {
    return filteredColumnsList.reduce((acc, tableProtocol) => {
      const parameters = tableProtocol.tableParameters?.map(param => ({
        id: param.id,
        title: `${param.title ?? param.titleTableItem.title}`,
        fullTitle: `${tableProtocol.title} / ${param.title ?? param.titleTableItem.title}`
      }));

      return acc.concat(parameters ?? []).filter(column => column.id !== parameterId);
    }, []) || [];
  }, [filteredColumnsList, parameterId]);

  const formulasList = useMemo(() => {
    return formulasData?.mathOperations || [];
  }, [formulasData?.mathOperations]);

  const formulasNamesList = useMemo(() => {
    return formulasList.map(formula => formula.functionName);
  }, [formulasList]);

  return (
    <Box>
      <FormControl
        fullWidth
        sx={styles.formControl}
      >
        <Typography
          shrink
          sx={styles.label}
          variant="outlined"
          disableAnimation
        >
          Field value equal to
        </Typography>

        <FormulaTextarea
          value={calculation}
          setValue={setCalculation}
          columns={columnsList}
          formulasNamesList={formulasNamesList}
          search={search}
          setSearch={setSearch}
          editor={editor}
        />
      </FormControl>

      <UnitsBlock
        setUnit={setUnit}
        unit={unit}
      />

      <Box
        sx={styles.formulationsBlock}
      >
        <FormulasList
          editor={editor}
          formulas={formulasList}
        />

        <ColumnsList
          protocols={filteredColumnsList}
          editor={editor}
          open={!!search}
        />
      </Box>
    </Box>
  );
};

FormulaEditor.propTypes = {
  columns: PT.array.isRequired,
  parameterId: PT.string.isRequired,
  calculation: PT.object.isRequired,
  unit: PT.object,
  setCalculation: PT.func.isRequired,
  setUnit: PT.func.isRequired
};

export default FormulaEditor;
