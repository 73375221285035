import styled from 'styled-components';

export default styled.div.attrs({ className: 'notifications' })`
  && {
    .notifications-toggler {
      width: 38px;
      height: 38px;
      color: white;
      padding: 0;
    }
    
    .notifications-badge > span {
      z-index: 3;
    }
  }
`;
