import { useMemo, useReducer, useCallback } from 'react';

import omit from 'lodash/omit';
import without from 'lodash/without';

const actionTypes = {
  REMOVE: 'REMOVE',
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  REORDER: 'REORDER'
};

const createOptionID = () => window.crypto.randomUUID();

const makeNormalizeOptions = options => {
  const result = {
    ids: [],
    byId: {}
  };

  for(const option of options) {
    const id = option.id ?? createOptionID();

    result.ids.push(id);
    result.byId[id] = option;
  }

  return result;
};

const initialOptionsState = items => {
  const options = items.length ? items : [{ title: '' }];
  const normalizedOptions = makeNormalizeOptions(options);

  return normalizedOptions;
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.REMOVE: {
      const { id } = action.payload;

      return {
        ...state,
        ids: without(state.ids, id),
        byId: omit(state.byId, id)
      };
    }

    case actionTypes.ADD: {
      const newNormalizedOptions = makeNormalizeOptions(action.payload);

      return {
        ...state,
        ids: state.ids.concat(newNormalizedOptions.ids),
        byId: {
          ...state.byId,
          ...newNormalizedOptions.byId
        }
      };
    }

    case actionTypes.UPDATE: {
      const { id, title } = action.payload;

      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            title
          }
        }
      };
    }

    case actionTypes.REORDER: {
      const { index, id } = action.payload;

      const { ids } = state;
      const withoutParameter = without(ids, id);

      const newIDs = [
        ...withoutParameter.slice(0, index),
        id,
        ...withoutParameter.slice(index)
      ];

      return {
        ...state,
        ids: newIDs
      };
    }
  }
};

const useEnumOptions = (options) => {
  const [state, dispatch] = useReducer(reducer, options, initialOptionsState);

  const optionsList = useMemo(() => {
    return state.ids.map(id => ({ ...state.byId[id], _id: id }));
  }, [state]);

  const onAdd = useCallback((options) => {
    dispatch({
      type: actionTypes.ADD,
      payload: options
    });
  }, []);

  const onUpdate = useCallback((id, title) => {
    dispatch({
      type: actionTypes.UPDATE,
      payload: { id, title }
    });
  }, []);

  const onReorder = useCallback((id, index) => {
    dispatch({
      type: actionTypes.REORDER,
      payload: { id, index }
    });
  }, []);

  const onRemove = useCallback((id) => {
    dispatch({
      type: actionTypes.REMOVE,
      payload: { id }
    });
  }, []);

  return [
    optionsList,
    {
      onAdd,
      onUpdate,
      onReorder,
      onRemove
    }
  ];
};

export default useEnumOptions;
