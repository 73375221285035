import { combineReducers } from 'redux';

import graphicsConfig from './graphics-config';
import quickCreate from './quick-create';
import objectsOrder from './objects-order';

export default combineReducers({
  graphicsConfig,
  quickCreate,
  objectsOrder
});
