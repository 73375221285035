import styled from 'styled-components';

import Box from '@mui/material/Box';

import { COLOR_PRIMARY } from '../../../../../styles';

export const EditContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  border: 2px solid ${ COLOR_PRIMARY };
  position: relative;
`;

export const ViewContainer = styled(Box)`
  padding: 0 6px;
  width: 100%;
  display: flex;
  height: 100%;
`;
