import { format } from 'd3-format';

class CustomTickFormatter extends window.Bokeh.BasicTickFormatter {
  static type = 'CustomTickFormatter';

  constructor(formatPattern, ...properties) {
    super(...properties);

    this.formatter = format(formatPattern);
  }

  doFormat(ticks) {
    return ticks.map(tick => this.formatter(Number.parseFloat(tick)));
  }
}

export default CustomTickFormatter;
