import React, { useCallback } from 'react';
import PT from 'prop-types';

import InputAdornment from '@mui/material/InputAdornment';

import EditButtons from '../../EditButtons';
import { QUANTITY_VALUE_TYPE } from './constants';
import { interceptKeyboardEvent } from './utils';

import { Container, InputStyled } from './styles';

const QuantityEditValue = ({
  value,
  name,
  disableSubmit,
  loading,
  onChange,
  onCancel,
  onSubmit
}) => {
  const handleSubmit = useCallback(() => {
    const validValue = value || value === 0 ? +value : null;

    onSubmit(QUANTITY_VALUE_TYPE, validValue);
  }, [onSubmit, value]);

  const handleKeyDown = useCallback(event => {
    interceptKeyboardEvent({
      event,
      onEnter() {
        if(!loading && !disableSubmit)
          handleSubmit();
      },
      onEscape() {
        if(!loading)
          onCancel();
      }
    });
  }, [loading, disableSubmit, handleSubmit, onCancel]);

  return (
    <Container>
      <InputStyled
        type="number"
        placeholder="N/A"
        inputProps={{ onKeyDown: handleKeyDown }}
        onChange={onChange}
        value={value}
        autoFocus
        endAdornment={
          <InputAdornment position="end">
            {name}
          </InputAdornment>
        }
      />

      <EditButtons
        disableSubmit={disableSubmit}
        loading={loading}
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
    </Container>
  );
};

QuantityEditValue.propTypes = {
  value: PT.oneOfType([PT.number, PT.string]),
  name: PT.string,
  disableSubmit: PT.bool,
  loading: PT.bool,
  onChange: PT.func.isRequired,
  onCancel: PT.func.isRequired,
  onSubmit: PT.func.isRequired
};

export default QuantityEditValue;
