import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import dateFnsFormat from 'date-fns/format';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import TextEditButtons from '../../../TextEditButtons';

import { ReactComponent as InfoIcon } from '../../images/info.svg';

import UPDATE_TITLE from './services';

import { SingleLine, Text, EditIconStyled } from './styles';
import { isRoleEqualOrHigher, rolesIds } from '../../../../utils/roles';
import { interceptKeyboardEvent } from '../utils';
import searchSplit from '../../../ItemsTable/utils/searchSplit';

const TitleBlock = ({ data, search }) => {

  const titleRef = useRef();
  const buttonsRef = useRef();

  const [updateItem] = useMutation(UPDATE_TITLE);

  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const [titleValue, setTitleValue] = useState(data?.title);

  const isEditable = useMemo(() => {
    return data?.table?.viewerMaxRole ? isRoleEqualOrHigher(data?.table?.viewerMaxRole, rolesIds.WRITE) : false;
  }, [data?.table?.viewerMaxRole]);

  const handleSwitchTitle = useCallback(() => {
    if (!isEditable)
      return;

    setIsTitleEditing(!isTitleEditing);
  }, [isEditable, isTitleEditing]);

  const handleChangeTitle = useCallback((e) => {
    setTitleValue(e.target.value);
  }, []);

  const tooltipText = useMemo(() => {
    const code = `Item Id: ${data?.code}`;
    const table = data?.table ? `Table: ${data?.table.title}` : null;
    const owner = `By: ${data?.creator.name}`;
    const dateFormatting = (data) => dateFnsFormat(data, `dd MMM, yyyy '-' HH:mm`);
    const createdDate = `Created on ${
      data?.dateCreated ?
        dateFormatting(data.dateCreated) :
        null
    }`;
    const updatedDate = data?.dateUpdated ?
      `Updated on ${dateFormatting(data.dateUpdated)}` :
      null;
    const views = `${data?.interactions.views} views`;
    return [code, table, owner, createdDate, updatedDate, views].filter(Boolean).join('\n');
  }, [data]);

  const handleCancelTitleEdit = useCallback(() => {
    setTitleValue(data?.title);
    setIsTitleEditing(false);
  }, [data?.title]);

  const handleSubmitTitleEdit = useCallback(() => {
    setIsTitleEditing(false);

    updateItem({
      variables: {
        updateTableItemId: data?.id,
        data: { title: titleValue }
      }
    });
  }, [data?.id, titleValue, updateItem]);

  const handleClickOutside = useCallback((e) => {
    if (titleRef.current && !titleRef.current.contains(e.target) &&
        buttonsRef.current && !buttonsRef.current.contains(e.target)) {
      handleSubmitTitleEdit();
    }
  }, [handleSubmitTitleEdit]);

  const handleKeyDown = useCallback(event => {
    interceptKeyboardEvent({
      event,
      onEnter() {
        handleSubmitTitleEdit();
      },
      onEscape() {
        handleCancelTitleEdit();
      }
    });
  }, [handleSubmitTitleEdit, handleCancelTitleEdit]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, handleSubmitTitleEdit]);

  const title = useMemo(() => {
    if (!search)
      return (
        <Text
          noWrap
          title={data?.title}
        >
          {data?.title}
        </Text>
      );

    const chunks = searchSplit(data?.title, search);

    return (
      <Text
        noWrap
        title={data?.title}
      >
        {chunks.map(chunk => {
          if (chunk.search){
            return (
              <mark key={chunk.id}>
                {chunk.text}
              </mark>
            );
          }

          return (
            <React.Fragment key={chunk.id}>
              {chunk.text}
            </React.Fragment>
          );
        })}
      </Text>
    );
  }, [data?.title, search]);

  return (
    <Box sx={{
      flexGrow: 1,
      overflow: 'hidden'
    }}
    >
      <SingleLine
        onClick={!isTitleEditing ? handleSwitchTitle : null}
        editable={isEditable ? 'true' : undefined}
      >
        {!isTitleEditing ?
          <>
            {title}

            <Tooltip
              title={
                <div>
                  {tooltipText.length ?
                    <Box
                      sx={{
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {tooltipText}
                    </Box>
                    : null}
                </div>
              }
            >
              <InfoIcon />
            </Tooltip>
          </>
          :
          <TextField
            ref={titleRef}
            value={titleValue}
            onKeyDown={handleKeyDown}
            onChange={handleChangeTitle}
            sx={{
              width: '100%',
              '.MuiInputBase-root': {
                height: '30px'
              }
            }}
            inputProps={{
              maxLength: 200
            }}
          />
        }

        {!isTitleEditing ?
          <EditIconStyled /> :
          <TextEditButtons
            ref={buttonsRef}
            onCancel={handleCancelTitleEdit}
            onSubmit={handleSubmitTitleEdit}
          />
        }
      </SingleLine>
    </Box>
  );
};

TitleBlock.propTypes = {
  data: PropTypes.object,
  search: PropTypes.string,
};

export default TitleBlock;
