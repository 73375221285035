import styled from 'styled-components';

import Box from '@mui/material/Box';

import { COLOR_PRIMARY } from '../../../../styles';

export const ButtonStyled = styled(Box)`
  position: absolute;
  top: 20px;
  left:  ${({ position }) => `${position}px`};
  border-radius: 50%;
  z-index: 4;

  border: 1px solid #D9D9D9;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.08);

  width: 20px;
  min-width: 20px;
  height: 20px;
  
  display: flex;
  align-items: center;
  justify-content: center;

  background: #FFF;

  svg {
    width: 10px;
    margin-left: ${({ open }) => open ? '4px' : 0};
    color: #8C8C8C;
  }
  
  :hover {
    background: #FFF;
    border-color: ${COLOR_PRIMARY};
    cursor: pointer;
    svg {
      color: ${COLOR_PRIMARY};
    }
  }
`;
