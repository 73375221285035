import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import { generatePath, useLocation } from 'react-router-dom';
import urlSlug from 'url-slug';

import AppMenuStyled from './styles';
import MenuItem from './MenuItem';
import { APPLICATIONS, VIEWER } from '../../AppSidebar/services';
import ListItemLink from './ListItemLink';
import Overlay from '../../../../components/Overlay';
import OrganizationNav from '../../AppSidebar/OrganizationNav';
import { routes } from '../../../../services/session/constants';
import VideoTooltip from '../../../../components/VideoTooltip';
import VIDEO_LINKS from '../../../../components/VideoHelperDialog/constants';
import VideoHelperDialog from '../../../../components/VideoHelperDialog';

const AppMainMenu = ({
  isRegularUser,
  mobile,
  onClose,
  open
}) => {
  const [applicationsMenu, setApplicationsMenu] = useState([]);
  const [videoLink, setVideoLink] = useState(null);

  const { data: appsData } = useQuery(APPLICATIONS);
  const viewerResponse = useQuery(VIEWER);

  const location = useLocation();

  const hasApplications = Array.isArray(get(appsData, 'applications')) && Boolean(appsData.applications.length);
  const viewer = viewerResponse?.data?.viewer;

  useEffect(() => {
    if (hasApplications) {
      const applicationsMenu =
        sortBy(
          get(appsData, 'applications', []).filter(({ children, url }) => children.length || url),
          ({ title }) => title.toLowerCase())
          .map(item => ({
            label: item.title,
            link: item.url ? generatePath(routes.APP, {
              title: urlSlug(item.title)
            }) : undefined,
            submenu: item.children?.map(s => ({
              label: s.title,
              link: generatePath(routes.APP, {
                folder: urlSlug(item.title),
                title: urlSlug(s.title)
              }),
            })) ?? null
          }));

      setApplicationsMenu(applicationsMenu);
    }
  }, [appsData, hasApplications]);

  useEffect(() => {
    if(!(location && location.state?.from)) {
      return;
    }

    const isLocationChanged = location.pathname !== location.state?.from;

    if (isLocationChanged) {
      onClose && onClose();
    }
  }, [location, onClose]);

  const handleVideoDialogToggle = useCallback(() => {
    setVideoLink(state => !state ? VIDEO_LINKS.CREATE_TABLE : null);
  }, []);

  return (
    <AppMenuStyled
      className={mobile ? '' : 'app-main-menu'}
      mobile={mobile}
      open={mobile ? open : true}
    >
      {mobile &&
        <>
          {open &&
            <Overlay
              onClick={onClose}
              originId="root"
            />
          }

          {viewer?.organization && <OrganizationNav organization={viewer.organization} />}

          <ListItemLink
            label="Home"
            link={routes.LIMITED_ACCESS_START_PAGE}
          />

          {hasApplications &&
            <ListItemLink
              label="Apps"
              submenu={applicationsMenu}
            />
          }

          {isRegularUser && <ListItemLink
            label="Items"
            link={routes.ALL_ITEMS}
          />}

          {isRegularUser && <ListItemLink
            label="Table Settings"
            link={routes.TABLES}
          />}
        </>
      }

      {!mobile &&
        <>
          <MenuItem
            label="Home"
            link={routes.LIMITED_ACCESS_START_PAGE}
          />

          {hasApplications &&
            <MenuItem
              label="Apps"
              submenu={applicationsMenu}
            />
          }

          {isRegularUser && <MenuItem
            label="Items"
            link={routes.ALL_ITEMS}
          />}

          {isRegularUser &&
            <VideoTooltip
              onClick={handleVideoDialogToggle}
              primaryText="Create and edit tables and folders."
            >
              <span>
                <MenuItem
                  label="Table Settings"
                  link={routes.TABLES}
                />
              </span>
            </VideoTooltip>
          }

          <VideoHelperDialog
            link={videoLink}
            onClose={handleVideoDialogToggle}
          />
        </>
      }
    </AppMenuStyled>
  );
};


AppMainMenu.propTypes = {
  isRegularUser: PropTypes.oneOf([null, true, false]),
  onClose: PropTypes.func.isRequired,
  mobile: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
};

export default AppMainMenu;

