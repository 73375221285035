import styled from 'styled-components';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';

export const ListItemWrapper = styled(Box)`
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  overflow: hidden
`;

export const LabelStyled = styled(FormControlLabel)`
  margin: 0;
  height: 28px;
  overflow: hidden;
  
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 20px;
    padding: 0;
    min-width: 24px;
  }
  
  svg {
    height: 16px;
  }
`;
