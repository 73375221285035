import React from 'react';

import { Outlet, useLocation, Navigate } from 'react-router-dom';

import { useAuth } from '../../../context/auth/auth';

import { routes } from '../../../services/session/constants';

const EmailVerified = () => {
  const { emailVerified } = useAuth();
  const location = useLocation();

  if(!emailVerified) {
    const dest = location.state?.from ?? location.pathname;

    return (
      <Navigate
        to={routes.EMAIL_VERIFICATION}
        replace
        state={{ from: dest }}
      />
    );
  }

  return (
    <Outlet />
  );
};

export default EmailVerified;
