import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { gql, useMutation, useQuery } from '@apollo/client';

import TextEditor from '../TextEditor';
import Avatar from '../Avatar';

import { NewCommentContainer } from './styles.js';

import {
  CREATE_COMMENT
} from './services';
import { componentNames, TRACK_SINGLE_COMMENT } from '../../analytics/constants';
import { useAnalytics } from 'use-analytics';

const GET_VIEWER = gql`
  query NewCommentViewerInfo {
    viewer {
      id
      name
      avatarColor
      picture {
        downloadURL
      }
    }
  }
`;

function NewComment({
  itemId,
  mentionOptionsLoading,
  mentionOptions,
  onError,
}) {
  const [edit, setEdit] = useState(false);

  const { track } = useAnalytics();

  const [addSampleComment] = useMutation(CREATE_COMMENT, {
    onError(data) {
      console.error(data);

      onError();
    }
  });

  const { data: viewerData } = useQuery(GET_VIEWER, {
    fetchPolicy: 'cache-first'
  });

  const { name, avatarColor, picture } = viewerData?.viewer ?? {};

  const handleStartEdit = useCallback(() => {
    setEdit(true);
  }, []);

  const handleSubmit = useCallback(contents => {
    track(TRACK_SINGLE_COMMENT.add, {
      component: componentNames.COMMENTS
    });

    addSampleComment({
      variables: {
        itemId,
        input: {
          contents
        }
      }
    });

    setEdit(false);
  }, [addSampleComment, itemId, track]);

  const handleCancel = React.useCallback(() => {
    setEdit(false);
  }, []);

  return (
    <NewCommentContainer>
      <Avatar
        name={name}
        src={picture?.downloadURL}
        size={38}
        color={avatarColor}
      />

      {edit ?
        <TextEditor
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          mentionOptionsLoading={mentionOptionsLoading}
          mentionOptions={mentionOptions}
        /> :
        <div
          className="comment-placeholder"
          onClick={handleStartEdit}
        >
          Add comment...
        </div>
      }
    </NewCommentContainer>
  );
}

NewComment.propTypes = {
  itemId: PropTypes.string.isRequired,
  username: PropTypes.string,
  avatarColor: PropTypes.string,
  pictureURL: PropTypes.string,
  mentionOptionsLoading: PropTypes.bool.isRequired,
  mentionOptions: PropTypes.array.isRequired,
  onError: PropTypes.func.isRequired,
};

export default NewComment;
