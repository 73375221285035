import styled from 'styled-components';
import TableCellUI from '@mui/material/TableCell/TableCell';
import TableHeadUI from '@mui/material/TableHead/TableHead';
import TableUI from '@mui/material/Table/Table';
import Chip from '@mui/material/Chip';
import {
  COLOR_GREY_DARK,
  COLOR_GREY_LIGHTER,
  COLOR_GREY_SUPER_LIGHT,
  COLOR_WHITE,
  MEDIA_QUERIES_XS
} from '../../styles';

export const OrganizationInfoStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  
  .avatar {
    margin-right: 16px;
  }
  
  .sb-avatar > div {
    border-radius: 5px;
  }
  
  .organization-title {
    margin-bottom: 8px;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  overflow: auto;

  tr {
    height: 52px;
  }

  th,
  td {
    font-size: 0.875rem;
    box-sizing: border-box;
  }
`;

const DATE_COLUMN_WIDTH = 200;
const USERNAME_COLUMN_WIDTH = 200;
const LAST_ACTIVE_COLUMN_WIDTH = 200;
const STATUS_COLUMN_WIDTH = 200;

export const TableCell = styled(TableCellUI)`
  &.table-head-cell {
    background-color: ${COLOR_GREY_SUPER_LIGHT};
    font-size: 14px;
    border-left: 1px solid ${COLOR_GREY_SUPER_LIGHT};
    border-bottom: 1px solid ${COLOR_GREY_SUPER_LIGHT};
    padding: 0 16px;
    text-transform: uppercase;

    &:nth-last-child(2) {
      border-right: none;
    }

    &:first-child,
    &:last-child {
      border-left: 1px solid ${COLOR_GREY_SUPER_LIGHT};
    }
  }

  &:last-child.action {
    padding-right: 0;
  }

  &.members {
    width: calc(100%
      - ${USERNAME_COLUMN_WIDTH}px
      - ${DATE_COLUMN_WIDTH}px
      - ${LAST_ACTIVE_COLUMN_WIDTH}px
      - ${STATUS_COLUMN_WIDTH}px
    );
    min-width: 200px;
  }

  &.date {
    text-align: left;
    width: ${DATE_COLUMN_WIDTH}px;
  }
  
  &.username {
    text-align: left;
    width: ${USERNAME_COLUMN_WIDTH}px;
  }

  &.last-active {
    text-align: left;
    width: ${LAST_ACTIVE_COLUMN_WIDTH}px;
  }
  
  &.status {
    text-align: left;
    width: ${STATUS_COLUMN_WIDTH}px;
  }
`;

export const TableHead = styled(TableHeadUI)`
  background-color: ${COLOR_GREY_SUPER_LIGHT};
`;

export const Table = styled(TableUI)`
  position: relative;

  @media (${MEDIA_QUERIES_XS}) {
    padding: 0 8px;
  }

  overflow-x: auto;
`;

export const AvatarWrapStyled = styled.div`
  display: flex;
  align-items: center;
  
  .avatar {
    margin-right: 16px;
  }
`;

export const PendingChipStyled = styled(Chip)({
  height: 24,
  backgroundColor: COLOR_GREY_DARK,
  color: COLOR_WHITE
});

export const DisabledChipStyled = styled(Chip)({
  height: 24,
  backgroundColor: COLOR_GREY_SUPER_LIGHT,
  color: COLOR_GREY_LIGHTER
});

export const EnabledChipStyled = styled(Chip)({
  height: 24,
});
