import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';

import Box from '@mui/material/Box';

import Search from '../Search';

import { SAVED_VIEWS } from './gql';
import SingleView from './SingleView';
import PropTypes from 'prop-types';

const SavedViews = ({ selectedView, disableSaving, onSelect, onUpdate, onSave, onRename, onDelete, onDiscard }) => {
  const { data } = useQuery(SAVED_VIEWS, {
    fetchPolicy: 'cache-and-network'
  });

  const [search, setSearch] = useState('');

  const filteredData = useMemo(() => {
    return data?.userTableItemQueries.filter(({ title }) => title.toLowerCase().includes(search.toLowerCase()))
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [data?.userTableItemQueries, search]);

  return (
    <Box
      sx={{ margin: '0 10px 8px' }}
    >
      <Search
        onChange={setSearch}
        value={search}
      />

      {filteredData.map((view) => (
        <SingleView
          key={view.id}
          data={view}
          selected={view.id === selectedView?.id}
          changed={view.id === selectedView?.id && selectedView?.changed}
          disableSaving={disableSaving}

          onSelect={onSelect}
          onUpdate={onUpdate}
          onSave={onSave}
          onRename={onRename}
          onDelete={onDelete}
          onDiscard={onDiscard}
        />
      ))}
    </Box>
  );
};

SavedViews.propTypes = {
  selectedView: PropTypes.object,
  disableSaving: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
};

export default SavedViews;
