import React  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AuthPageStyled from '../../components/AuthPageStyled';
import LogoMain from '../../components/Logos/LogoMain';
import { AuthConfirmEmailStyled } from '../../components/AuthPageStyled/AuthConfirmEmail';

import { getAuthUser } from '../../services/session/selectors';
import { sendEmailVerification } from '../../services/session/api';
import { logout } from '../../services/session/actions';
import {
  openAppSnackbarNotification
} from '../../services/snackbar-notifications/actions';

import { routes } from '../../services/session/constants';
import useDocumentTitle from '../../hooks/document-title';

const mapStateToProps = state => ({
  authUser: getAuthUser(state),
});

const mapDispatchToProps = {
  logout,
  openAppSnackbarNotification
};

const EmailVerification = (props) => {

  useDocumentTitle('Email Verification');
  const handleReSendEmailVerification = async () => {
    try {
      await sendEmailVerification({
        handleCodeInApp: true,
        url: `${window.location.origin}${routes.HOME_PAGE}`
      });

      props.openAppSnackbarNotification({
        message: 'A verification email has been sent. Please check your email inbox.',
        variant: 'INFO'
      });
    } catch(e) {
      props.openAppSnackbarNotification({
        message: 'Failed to send a verification email. Please try again later.',
        variant: 'ERROR'
      });
    }
  };

  const handleSignOut = ev => {
    ev.preventDefault();

    props.logout();
  };

  const handleChatInterface = () => {
    window.Intercom('show');
  };

  const { authUser } = props;

  return (
    <AuthPageStyled>
      <div className="header">
        <LogoMain />
      </div>

      <AuthConfirmEmailStyled>
        <p>{`We've sent an email to ${authUser?.email} to verify your email address.`}</p>

        <p>{`Please follow the link inside to complete verification.`}</p>

        <p>
          {`If you did not receive the email, please click `}
          <button className="link-button" onClick={handleReSendEmailVerification}>
            here
          </button>

          {` to resend the email or contact our support team by using our `}

          <button className="link-button" onClick={handleChatInterface}>
            chat interface
          </button>

          {` or sending an email to `}

          <a href="mailto:support@materials.zone">
            support@materials.zone
          </a>

          {`.`}
        </p>

        <p>
          Already have an active Materials.Zone user profile? <a href="/signin" onClick={handleSignOut}>Sign in</a>
        </p>
      </AuthConfirmEmailStyled>
    </AuthPageStyled>
  );
};

EmailVerification.propTypes = {
  authUser: PropTypes.object,
  logout: PropTypes.func.isRequired,
  openAppSnackbarNotification: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);
