import React, { useState, useCallback } from 'react';
import { gql } from '@apollo/client';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';

import Popover from '@mui/material/Popover';

import Avatar from '../../../../components/Avatar';
import UserInfo from '../../UserInfo';

import Box from '@mui/material/Box';

const VIEWER_INFO = gql`
  query UserInfoPopoverViewerInfo {
    viewer {
      id
      userId
      name
      email
      avatarColor
      picture {
        downloadURL
      }
      organization {
        id
        title
        picture {
          downloadURL
          fullPath
        }
        avatarColor
      }
    }
  }
`;

const POPOVER_WIDTH = 250;

function UserInfoPopover() {
  const [open, setOpen] = useState(false);

  const { loading, data } = useQuery(VIEWER_INFO);

  const viewer = data?.viewer || {};

  const handlePopoverOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setOpen(false);
  }, []);

  if(loading || !data)
    return null;

  const anchorPosition = { top: 64, left: window.innerWidth + 20 };

  return (
    <Box
      sx={{
        marginRight: '10px',
        '.avatar': {
          cursor: 'pointer'
        }
      }}
    >
      <Avatar
        name={viewer.name}
        color={viewer.avatarColor}
        src={get(viewer, 'picture.downloadURL')}
        size={40}
        onClick={handlePopoverOpen}
      />

      <Popover
        open={open}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        onClose={handlePopoverClose}
        sx={{
          '.MuiPaper-root': {
            width: POPOVER_WIDTH,
          }
        }}
      >
        <UserInfo
          userId={viewer.userId}
          userName={viewer.name}
          organization={viewer.organization}
          email={viewer.email}
          onClosePopover={handlePopoverClose}
        />
      </Popover>
    </Box>
  );
}

export default UserInfoPopover;
