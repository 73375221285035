import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useAnalytics } from 'use-analytics';
import PropTypes from 'prop-types';

import Avatar from '../../../components/Avatar';
import AvatarShortFormSelect from '../../../components/AvatarShortFormSelect';
import AvatarPictureSelect from '../../../components/AvatarPictureSelect';
import PictureSettingsContainer from './styles';

import { UPDATE_PROFILE, CHANGE_PROFILE_AVATAR_COLOR } from './gql';
import { componentNames, TRACK_PROFILE_SETTINGS } from '../../../analytics/constants';

function ProfilePictureSettigs({
  userId,
  name,
  color,
  url
}) {
  const [updateProfile] = useMutation(UPDATE_PROFILE);

  const [changeProfileAvatarColor] = useMutation(CHANGE_PROFILE_AVATAR_COLOR);

  const { track } = useAnalytics();

  const handleSetShortForm = useCallback(async () => {
    try {
      await updateProfile({
        variables: {
          input: {
            picture: null
          }
        },
      });
    } catch (e) {
      console.error(e);
    }
  }, [updateProfile]);

  const handleProfileAvatarColorChange = useCallback(async ({ hex }) => {
    try {
      await changeProfileAvatarColor({
        variables: {
          input: {
            avatarColor: hex
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateProfile: {
            __typename: 'Viewer',
            id: userId,
            avatarColor: hex
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  }, [userId, changeProfileAvatarColor]);

  const handleSubmitPicture = useCallback(async (blob) => {
    const file = new File([blob], blob.name);

    try {
      await updateProfile({
        variables: {
          input: {
            picture: file
          }
        },
      });

      track(TRACK_PROFILE_SETTINGS.change, {
        component: componentNames.PROFILE_IMAGE
      });
    } catch (e) {
      console.error(e);
    }
  }, [updateProfile, track]);

  return (
    <PictureSettingsContainer>
      <Avatar
        className="user-avatar-wrapper"
        name={name}
        size={130}
        color={color}
        src={url}
      />

      <div className="actions-container">
        <AvatarShortFormSelect
          buttonText="Use My Initials"
          color={color}
          onColorChange={handleProfileAvatarColorChange}
          onSetShortForm={handleSetShortForm}
          disableColorPicker={Boolean(url)}
        />

        <AvatarPictureSelect
          buttonFileText="Choose Picture"
          onSubmit={handleSubmitPicture}
        />
      </div>
    </PictureSettingsContainer>
  );
}

ProfilePictureSettigs.propTypes = {
  userId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  url: PropTypes.string
};

export default ProfilePictureSettigs;
