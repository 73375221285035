import React, { useMemo } from 'react';
import { useAnalytics } from 'use-analytics';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ButtonBase from '@mui/material/ButtonBase';

import TableChartIcon from '@mui/icons-material/TableChart';

import { GroupColumnHeader, GroupHeader } from '../../components/ItemsTable';
import { PrimaryHeader, SecondaryHeader } from '../../components/ItemsTable/GroupColumnHeader';
import { ParameterValue, FormulationValue } from '../../components/ItemsTable/cells';

import { COL_MAX_WIDTH_PX, PARAM_VALUE_COL_MIN_WIDTH } from '../../components/ItemsTable/constants';
import { componentNames, TRACK_FORMULATION } from '../../analytics/constants';

const useColumns = ({ featuresData, defaultColumns, sortable, queryInput }) => {

  const { track } = useAnalytics();

  const columns = useMemo(() => {
    const features = featuresData ?? [];
    const featuresByProtocol = {};

    for(const feature of features) {
      const {
        protocolTitle,
        featureId,
        title,
        unitName,
        titleLinkId,
        titleLinkIsAccessible,
        valueType,
        protocolType,
        protocolGroup
      } = feature;

      if(!featuresByProtocol[protocolGroup.id]) {
        featuresByProtocol[protocolGroup.id] = {
          id: protocolGroup.id,
          title: protocolTitle,
          type: protocolType,
          columns: [],
          includedProtocols: protocolGroup.includedProtocols ?? []
        };
      }

      featuresByProtocol[protocolGroup.id].columns.push({
        id: featureId,
        title,
        unitName,
        titleLinkId,
        titleLinkIsAccessible,
        valueType
      });
    }

    const protocolColumns = Object
      .values(featuresByProtocol)
      .map(({ id, title, type, columns, includedProtocols }) => {
        let formulaViewDefault = false;

        if(
          type === 'FORMULATION' &&
          !columns.some(({ id }) => queryInput.tableHeaderFeatureIDs?.includes(id))
        ) {
          formulaViewDefault = true;
        }

        return {
          id,
          header: ({ header, table }) => {
            let { colSpan, rowSpan, column } = header;

            if(column.isCollapsed()) {
              colSpan = 1;
              rowSpan = table.rows.length + 2;
            }

            const maxWidth = column.getLeafColumns().length * COL_MAX_WIDTH_PX;
            const formulation = type === 'FORMULATION';
            let formulaView = false;

            if(formulation) {
              const visibleColumns = column.getLeafColumns();

              if(visibleColumns.length === 1 && visibleColumns[0].id === title + '-formula-view')
                formulaView = true;
            }

            return (
              <GroupHeader
                colSpan={colSpan}
                rowSpan={rowSpan}
                collapsed={column.isCollapsed()}
                onToggleCollapse={column.toggleCollapse}
                maxWidth={formulation && formulaView ? null : maxWidth}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  gap="6px"
                >
                  <Tooltip title={title}>
                    <Typography variant="body2" fontWeight="bold" noWrap>
                      {title}
                    </Typography>
                  </Tooltip>

                  {formulation && !column.isCollapsed() ?
                    <>
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{ my: '4px' }}
                      />

                      <Tooltip
                        arrow
                        title={formulaView ? 'Show all columns' : 'Show formulation values'}
                        placement="top"
                      >
                        <ButtonBase
                          sx={{
                            p: '2px',
                            borderRadius: '4px',
                            background: formulaView ? 'none' : '#D9D9D9'
                          }}
                          onClick={() => {
                            track(TRACK_FORMULATION.toggle, {
                              component: componentNames.TABLE_STRUCTURE
                            });

                            column.toggleChildrenVisibility();
                          }}
                        >
                          <TableChartIcon
                            sx={{
                              fontSize: '12px',
                              fillOpacity: '.6',
                              opacity: formulaView ? '.6' : '1'
                            }}
                          />
                        </ButtonBase>
                      </Tooltip>
                    </> :
                    null
                  }
                </Box>
              </GroupHeader>
            );
          },
          columns: columns.filter(({ id }) => formulaViewDefault || queryInput.tableHeaderFeatureIDs?.includes(id)).map(({
            id,
            title,
            unitName,
            titleLinkId,
            titleLinkIsAccessible,
            valueType
          }) => {
            return {
              id: id,
              header: ({ table, header }) => {
                const { itemsSorting, onColumnRemove } = table.customState;
                const { state, onSort } = itemsSorting;
                const sorted = state?.featureId === id;
                const order = sorted ? state?.order : null;

                const maxWidth = header.column.getLeafColumns().length * COL_MAX_WIDTH_PX;

                const handleRemove = () => {
                  onColumnRemove({ id, table });
                };

                let calculation;

                if(valueType === 'CALCULATION') {
                  // This is a placeholder for future implementation
                  // of the calculated fields icon behavior
                  calculation = {
                    formula: null,
                    error: null
                  };
                }

                return (
                  <GroupColumnHeader
                    sortable
                    id={id}
                    sorted={sorted}
                    sortParam="FEATURE_ID"
                    sortOrder={order}
                    onSort={onSort}
                    maxWidth={maxWidth}
                    minWidth={PARAM_VALUE_COL_MIN_WIDTH}
                  >
                    <PrimaryHeader
                      id={id}
                      sortable={sortable}
                      sortParam="FEATURE_ID"
                      order={order}
                      onSort={onSort}
                      onRemove={handleRemove}
                      linkId={titleLinkId}
                      noAccess={!titleLinkIsAccessible}
                      onLinkClick={table.customState.handleToggleSidebar}
                      calculation={calculation}
                    >
                      <Tooltip
                        title={(titleLinkId && !titleLinkIsAccessible) ? 'Inaccessible item' : title}
                        placement="top"
                      >
                        <Typography variant="body2" noWrap>
                          {title}
                        </Typography>
                      </Tooltip>
                    </PrimaryHeader>

                    <SecondaryHeader>
                      {unitName ?? '-'}
                    </SecondaryHeader>
                  </GroupColumnHeader>
                );
              },
              accessorFn: ({ values, table }) =>  {
                const value = values?.find(({ featureId }) => featureId === id);
                const tableParameter = table.tableParameters.find(({ featureId }) => featureId === id);

                return { value, tableParameter };
              },
              cell: ParameterValue
            };
          }).concat(type === 'FORMULATION' ?
            {
              id: title + '-formula-view',
              header: () => {
                return (
                  <GroupColumnHeader
                    id={title + '-formula-view'}
                    minWidth={PARAM_VALUE_COL_MIN_WIDTH}
                  >
                    <Typography variant="body2">Formula view</Typography>

                    <SecondaryHeader>-</SecondaryHeader>
                  </GroupColumnHeader>
                );
              },
              accessorFn: ({ values, table }) => {
                const parametersByFeatureId = {};
                const protocolId = includedProtocols.find(({ tableId }) => tableId === table.id)?.id ?? null;

                if (!values.length)
                  return { protocolId, ingredients: [] };

                const parameters = table.tableParameters.filter(({ tableProtocol }) => tableProtocol.id === protocolId);

                for (const parameter of parameters) {
                  parametersByFeatureId[parameter.featureId] = parameter;
                }

                return {
                  protocolId,
                  ingredients: values
                    .filter(({ quantity, featureId }) => {
                      return parametersByFeatureId[featureId] && quantity;
                    })
                    .map(({ quantity, featureId }) => ({
                      quantity,
                      parameter: parametersByFeatureId[featureId]
                    }))
                    .toSorted((a, b) => {
                      // The ingredients must be sorted by quantity.
                      // Sort by ingredient name if the quantity is the same.
                      const diff = b.quantity - a.quantity;

                      if (!diff)
                        return a.parameter.titleTableItem.title.localeCompare(
                          b.parameter.titleTableItem.title
                        );

                      return diff;
                    })
                };
              },
              cell: FormulationValue,
              visible: formulaViewDefault,
              type: 'FORMULA'
            } :
            []
          )
        };
      });

    return [
      ...defaultColumns,
      ...protocolColumns
    ];
  }, [featuresData, defaultColumns, queryInput, sortable, track]);

  return columns;
};

export default useColumns;
