import React from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

const EditableContentGroup = ({
  onEdit,
  children,
  containerStyles = {},
  iconStyles = {}
}) => (
  <Box
    display="flex"
    alignItems="center"
    gap="10px"
    sx={{
      '& .edit-button': {
        display: 'none'
      },
      '&:hover': {
        '.edit-button': {
          display: 'inline-flex'
        }
      },

      ...containerStyles
    }}
  >
    {children}

    <IconButton
      className="edit-button"
      size="small"
      onClick={onEdit}
      sx={iconStyles}
    >
      <EditIcon fontSize="inherit" />
    </IconButton>
  </Box>
);

EditableContentGroup.propTypes = {
  onEdit: PT.func.isRequired,
  children: PT.node.isRequired,
  containerStyles: PT.object,
  iconStyles: PT.object
};

export default EditableContentGroup;
