import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { GithubPicker } from 'react-color';

import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import FormatColorFill from '@mui/icons-material/FormatColorFill';

const useStyles = makeStyles({
  root: {
    height: 42
  }
});

const buttonStyle = {
  paddingRight: '8px'
};

const ColorPicker = ({
  color,
  colors,
  size,
  disabled,
  onColorChange
}) => {
  const [open, setOpen] = useState(false);

  const anchorEl = useRef();

  const classes = useStyles();

  const handleClick = useCallback(() => {
    setOpen(v => !v);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleChangeComplete = useCallback(value => {
    onColorChange?.(value);
    setOpen(false);
  }, [onColorChange]);

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClick}
        color="secondary"
        size={size}
        classes={{ root: size ? void 0 : classes.root }}
        style={buttonStyle}
        disabled={disabled}
        ref={anchorEl}
      >
        <FormatColorFill style={{ fill: color }} />
        <ArrowDropDown />
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <GithubPicker
          width="175px"
          color={color}
          colors={colors}
          onChangeComplete={handleChangeComplete}
        />
      </Popover>
    </>
  );
};

ColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  colors: PropTypes.array,
  onColorChange: PropTypes.func,
  size: PropTypes.string,
  disabled: PropTypes.bool
};

export default ColorPicker;
