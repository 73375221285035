import React, { useCallback, useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import DialogHeader from './DialogHeader';
import DialogFooter from './DialogFooter';
import DocumentContent from './DocumentContent';

const MIN_SCALE = 1;
const MAX_SCALE = 4;

const styles = {
  dialog: {
    position: 'relative',
    width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 0,
    background: 'transparent'
  },
  dialogError: {
    boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)'
  },
  dialogLoading: {
    width: 0,
    height: 0,
    boxShadow: 'none'
  },
  dialogContentLoading: {
    margin: 0,
    padding: 0
  },
  dialogContent: {
    margin: '0 auto',
    padding: '56px 0 32px 0',
    background: 'transparent',
    overflow: 'unset'
  },
  dialogContentNoData: {
    minHeight: '400px',
    background: '#fff',
    textAlign: 'center',
    padding: '40px'
  },
  dialogContentLoadError: {
    minHeight: '400px',
    background: '#fff',
    textAlign: 'center',
    padding: '40px',
  },
  page: {
    marginBottom: '5px'
  }
};

function PdfFullScreenView({ open, data, onClose, classes }) {
  const [numPages, setNumPages] = useState(0);
  const [visiblePages, setVisiblePages] = useState(0);
  const [scale, setScale] = useState(1);
  const [documentLoaded, setDocumentLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  const onDocumentLoadSuccess = useCallback(({ numPages: nextNumPages }) => {
    setDocumentLoaded(true);
    setNumPages(nextNumPages);
  }, []);

  const onDocumentLoadError = useCallback(() => {
    setIsError(true);
  }, []);

  const scaleUp = useCallback(() => {
    setScale(scale => scale >= 4 ? 0.5 : scale + 0.5);
  }, []);

  const scaleDown = useCallback(() => {
    setScale(scale => scale <= 1 ? 1 : scale - 0.5);
  }, []);

  const scaleToMiddle = useCallback(() => {
    setScale(2);
  }, []);

  const scaleToDefault = useCallback(() => {
    setScale(1);
  }, []);

  const fitToScale = useCallback(() => {
    if (scale > MIN_SCALE) {
      scaleToDefault();
    } else {
      scaleToMiddle();
    }
  }, [scale, scaleToDefault, scaleToMiddle]);

  const setPageVisibility = useCallback((pageNumber, isIntersecting) => {
    setVisiblePages((prevVisiblePages) => ({
      ...prevVisiblePages,
      [pageNumber]: isIntersecting
    }));
  }, []);

  const handleClickBackDrop = useCallback((e) => {
    if (e.target.classList.contains('MuiPaper-root')) {
      onClose();
    }
  }, [onClose]);

  const pageNumber = useMemo(() => Object.entries(visiblePages)
    .filter(([, value]) => value)
    .map(([key]) => key)[0] || 1
  , [visiblePages]);

  const fileUrl = data.location?.downloadURL || data.sourceFile?.downloadURL;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="preview-pdf-modal"
      classes={{ paper: documentLoaded ? classes.dialog : isError ? classes.dialogError : classes.dialogLoading }}
      data-testid="preview-pdf-modal"
      onClick={handleClickBackDrop}
    >
      <DialogHeader
        documentLoaded={documentLoaded}
        data={data}
        onClose={onClose}
        fileUrl={fileUrl}
      />

      <DocumentContent
        numPages={numPages}
        scale={scale}
        fileUrl={fileUrl}
        isError={isError}
        documentLoaded={documentLoaded}
        onDocumentLoadError={onDocumentLoadError}
        onDocumentLoadSuccess={onDocumentLoadSuccess}
        setPageVisibility={setPageVisibility}
      />

      {documentLoaded &&
        <DialogFooter
          minScale={MIN_SCALE}
          maxScale={MAX_SCALE}
          numPages={numPages}
          pageNumber={pageNumber}
          fitToScale={fitToScale}
          scaleDown={scaleDown}
          scaleUp={scaleUp}
          scale={scale}
        />
      }
    </Dialog>
  );
}

PdfFullScreenView.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.shape({
    location: PropTypes.object,
    sourceFile: PropTypes.object
  }),
  classes: PropTypes.object,
};

export default withStyles(styles)(PdfFullScreenView);
