import {
  call,
  takeEvery,
  put,
  all,
} from 'redux-saga/effects';

import * as api from './api';
import * as actions from './actions';
import * as constants from './constants';
import * as commonApi from '../common/api';

import { fetchEntity } from '../../sagas';
import {
  getParserDataTypes,
  getParserRuntimes,
} from '../../utils/parsing';
import { withSrcAndFilename } from '../../services/measurements/utils';

const { REQUEST } = constants;

export const listeners = [
  takeEvery(actions.measurementParseByIdTypes[REQUEST], measurementParseById),
  takeEvery(actions.measurementsParseTypes[REQUEST], measurementsParse),
  takeEvery(actions.measurementProcessedDataLoadByIdTypes[REQUEST], onProcessedDataLoadById),
];

const fetchMeasurementParse = fetchEntity.bind(null,
  actions.measurementParseByIdHandlers,
  api.parseMeasurementById
);

const fetchProcessedDataLoadById = fetchEntity.bind(null,
  actions.measurementProcessedDataLoadByIdHandlers,
  commonApi.processedDataLoadById
);

function* measurementParseById({
  id,
  params: {
    filename,
    src = 'ftp',
    parsingConfig
  }
}) {
  const {
    code: parserCode,
    parser: {
      id: parserId,
      filename: parser,
      runtime,
      dataType,
      viewType,
      name: parserName
    }
  } = parsingConfig;

  const params = {
    filename,
    parser,
    id,
    src,
    parserCode,
    parserId,
    viewType,
    parserName,
    runtime: getParserRuntimes()[runtime],
    dataType: getParserDataTypes(dataType),
  };

  yield call(fetchMeasurementParse, params);
}

function* measurementsParse({
  fileIdsToProcess,
  measurements,
  parsingConfig
}) {
  try {
    yield all(
      fileIdsToProcess.map(i => put(
        actions.parseMeasurementById(
          i,
          withSrcAndFilename({
            parsingConfig
          }, measurements[i])
        )
      ))
    );

    yield put(actions.measurementsParseHandlers.success());
  } catch (err) {
    yield put(actions.measurementsParseHandlers.failure(err));
  }
}

function* onProcessedDataLoadById({ id, params }) {
  yield call(fetchProcessedDataLoadById, id, params);
}
