import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

const TextValue = ({ text }) => {
  return (
    <Tooltip title={text}>
      <Typography
        sx={{
          width: 'calc(100% - 20px)'
        }}
        variant="body2"
      >
        {text}
      </Typography>
    </Tooltip>
  );
};

TextValue.propTypes = {
  text: PropTypes.string
};

export default TextValue;
