import React from 'react';
import PT from 'prop-types';

import { components } from 'react-select';

const Input = (props) => (
  <components.Input
    {...props}
    onPaste={props.selectProps.onPaste}
  />
);

Input.propTypes = {
  selectProps: PT.shape({
    onPaste: PT.func
  })
};

export default Input;

