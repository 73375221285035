import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import TextWithLink from '../../../../../TextWithLink';

const LinkedProtocolFromItem = ({ link, onClick, protocol, title }) => {

  const handleOnClick = useCallback(() => {
    onClick(protocol);
  }, [onClick, protocol]);

  return (
    <TextWithLink
      link={link}
      linkProps={{
        onClick: handleOnClick,
      }}
    >
      <Tooltip
        title={title}
        placement="left"
      >
        <Typography
          noWrap
          sx={{
            textOverflow: 'ellipsis',
            maxWidth: '212px'
          }}
        >
          {title}
        </Typography>
      </Tooltip>
    </TextWithLink>
  );
};

LinkedProtocolFromItem.propTypes = {
  link: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  protocol: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

export default LinkedProtocolFromItem;
