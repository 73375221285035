import { useInView } from 'react-intersection-observer';
import React, { useCallback, useEffect, useState } from 'react';
import { Page } from 'react-pdf';
import PropTypes from 'prop-types';

const observerConfig = {
  // How much of the page needs to be visible to consider page visible
  threshold: 0
};

function PageWithObserver({ pageIndex, setPageVisibility, width, scale, className }) {
  const { ref, inView, entry } = useInView(observerConfig);
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    if (isRendered) {
      setPageVisibility(pageIndex + 1, !!entry?.isIntersecting);
    }
    // eslint-disable-next-line
  }, [inView, entry?.isIntersecting, pageIndex, setPageVisibility]);

  const handleRenderSuccess = useCallback(() => {
    setIsRendered(true);
  }, []);

  return <Page
    canvasRef={ref}
    pageIndex={pageIndex}
    width={width}
    scale={scale}
    className={className}
    onRenderSuccess={handleRenderSuccess}
    renderTextLayer={isRendered && inView && !!entry?.isIntersecting}
  />;
}

PageWithObserver.propTypes = {
  pageIndex: PropTypes.number,
  width: PropTypes.number,
  scale: PropTypes.number,
  className: PropTypes.string,
  setPageVisibility: PropTypes.func,
};

export default PageWithObserver;
