import React from 'react';
import PT from 'prop-types';
import dateFnsFormat from 'date-fns/format';

import Typography from '@mui/material/Typography';

import TableCell from '../TableCell/TableCell';

const LastUpdatedCell = ({ getValue }) => {
  const timestamp = getValue();

  return (
    <TableCell>
      {timestamp
        ? (
          <Typography variant="body2">
            {dateFnsFormat(timestamp || Date.now(), 'dd/MM/yyyy')}
          </Typography>
        )
        : ''}
    </TableCell>
  );
};

LastUpdatedCell.propTypes = {
  getValue: PT.func.isRequired
};

export default LastUpdatedCell;
