import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Table from './Table';

import { flexRender } from '../../../hooks/table';

import { StyledRow } from './styles';

const UsersTable = ({ table }) => {
  const colgroups = useMemo(() => {
    return table
      .headerGroups[0]
      ?.headers
      .map(({ id, colSpan, column }) => ({
        id,
        colSpan: column.isCollapsed() ? 1 : colSpan
      })) ?? [];
  }, [table.headerGroups]);

  return (
    <>
      <Table colgroups={colgroups}>
        {table.headerGroups.map(({ id, headers }) => (
          <tr key={id} className="head">
            {headers.map(header => {
              if(header.column.parent?.isCollapsed())
                return null;

              return (
                <React.Fragment key={header.id}>
                  {flexRender(header.column.columnDef.header, { header, table })}
                </React.Fragment>
              );
            })}
          </tr>
        ))}

        {table.rows.map(row => (
          <StyledRow
            key={row.data.userId ?? 'organization'}
          >
            {row.getCells().map(cell => (
              <React.Fragment key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </React.Fragment>
            ))}
          </StyledRow>
        ))}
      </Table>
    </>
  );
};

UsersTable.propTypes = {
  table: PropTypes.object.isRequired,
};

export default UsersTable;
