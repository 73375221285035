import React, { useCallback } from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import InputBase from '@mui/material/InputBase';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import { Container } from '../styles';
import EditButtons from '../../../EditButtons';

import { interceptKeyboardEvent } from '../utils';
import { ENUM_VALUE_TYPE } from '../constants';

const EnumEditValueSingle = ({ value, options, onChange, onCancel, onSubmit }) => {
  const getOptionLabel = useCallback(option => option.title, []);
  const isOptionEqualToValue = useCallback((option, value) => option.id === value?.id, []);

  const handleChange = useCallback((ev, value) => {
    onChange(value && [value]);
  }, [onChange]);

  const handleSubmit = useCallback(() => {
    onSubmit(ENUM_VALUE_TYPE, value && [value.id]);
  }, [value, onSubmit]);

  const handleKeyDown = useCallback((event) => {
    interceptKeyboardEvent({
      event,
      onEnter() {
        onSubmit(ENUM_VALUE_TYPE, value && [value.id]);
      },
      onEscape() {
        onCancel();
      }
    });
  }, [value, onSubmit, onCancel]);

  return (
    <Container onKeyDown={handleKeyDown}>
      <Box width="100%">
        <Autocomplete
          value={value}
          options={options}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          openOnFocus
          selectOnFocus
          renderInput={({ InputProps, inputProps, disabled, fullWidth, id }) => {
            return (
              <InputBase
                id={id}
                {...InputProps}
                inputProps={inputProps}
                disabled={disabled}
                fullWidth={fullWidth}
                autoFocus
                sx={{
                  boxSizing: 'border-box',
                  height: '30px',
                  padding: '4px 8px',
                  border: '1px solid rgba(0,0,0,.23)',
                  borderRadius: '4px',

                  '& .MuiInputBase-input': {
                    padding: '0',
                    fontSize: '14px',
                  }
                }}
              />
            );
          }}
          renderOption={(props, option) => (
            <ListItem
              {...props}
              dense
            >
              <Typography
                variant="body2"
                noWrap
                component="span"
                title={option.title}
              >
                {option.title}
              </Typography>
            </ListItem>
          )}
          slotProps={{
            popper: {
              placement: 'bottom-start',
              sx: {
                width: '260px !important'
              }
            }
          }}
          onChange={handleChange}
        />
      </Box>

      <EditButtons
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
    </Container>
  );
};

EnumEditValueSingle.propTypes = {
  value: PT.shape({
    id: PT.string,
    title: PT.string
  }),
  options: PT.arrayOf(PT.shape({
    id: PT.string,
    title: PT.string
  })),
  onChange: PT.func,
  onCancel: PT.func,
  onSubmit: PT.func
};

export default EnumEditValueSingle;
