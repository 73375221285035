import { useState, useCallback } from 'react';

const useEditMode = ({ defaultValue, onChange }) => {
  const [editMode, setEditMode] = useState(defaultValue);

  const handleEditModeChange = useCallback(mode => {
    setEditMode(mode);
    onChange(mode);
  }, [onChange]);

  return [
    editMode,
    handleEditModeChange,
    onChange
  ];
};

export default useEditMode;
