import React, { memo } from 'react';
// TODO: There was a problem that caused a significant rendering delay
// when many columns displayed in the table.
// The "hotfix" solution is to not render the component when it is not
// in the view. But the real solution should include further investigation
// of the issue and reducing the number of computation in each cell
// (e.g., user permissions, styles, etc.)
import { useInView } from 'react-intersection-observer';

import ParameterValueContent from './ParameterValueContent';
import TableCell from '../TableCell';

import { COL_MAX_WIDTH_PX } from '../../constants';

const ParameterValue = (props) => {
  const { ref, inView } = useInView();

  return (
    <TableCell ref={ref} value maxWidth={COL_MAX_WIDTH_PX}>
      {inView ?
        <ParameterValueContent {...props} />
        : null
      }
    </TableCell>
  );
};

export default memo(ParameterValue);
