import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import DialogActions from '@mui/material/DialogActions';

import CopyClipboard from '../CopyClipboard';
import { MainActionsStyled } from './styles';
import { COLOR_GREY_LIGHT, SM } from '../../styles';

const styles = theme => ({
  actionButton: {
    fontSize: 12
  },
  closeButton: {
    backgroundColor: COLOR_GREY_LIGHT,
  },
  dialogActionsRoot: {
    'justify-content': 'space-between',
    'align-items': 'end'
  },
  copyButton: {
    marginRight: 10
  },
  [theme.breakpoints.down(SM)]: {
    copyButton: {
      marginBottom: 10
    },
  }
});

const MainActions = ({
  itemLink,
  onPrint,
  onClose,
  classes
}) => {
  const [copied, setCopied] = useState(false);

  const handleQRCopy = useCallback(() => {
    setCopied(true);
  }, []);

  useEffect(() => {
    if(!copied) return;

    const timerId = setTimeout(setCopied, 1e3, false);

    return () => {
      clearTimeout(timerId);
    };
  }, [copied]);

  return (
    <MainActionsStyled>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <div className="left-side">
          <CopyClipboard
            tooltip
            tooltipProps={{
              open: copied,
              title: 'Copied',
              placement: 'top',
            }}
            text={itemLink}
            onCopy={handleQRCopy}
          >
            <Button
              className="submit-action"
              variant="contained"
              color="primary"
              classes={{
                root: `${classes.actionButton} ${classes.copyButton}`
              }}
            >
              copy item url
            </Button>
          </CopyClipboard>

          <Button
            className="submit-action"
            color="primary"
            variant="contained"
            onClick={onPrint}
            classes={{ root: `${classes.actionButton} ${classes.printButton}` }}
          >
            print
          </Button>
        </div>

        <div className="right-side">
          <Button
            color="inherit"
            classes={{ root: `${classes.closeButton} ${classes.actionButton}` }}
            onClick={onClose}
          >
            close
          </Button>
        </div>
      </DialogActions>
    </MainActionsStyled>
  );
};

MainActions.propTypes = {
  itemLink: PropTypes.string.isRequired,
  onPrint: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MainActions);
