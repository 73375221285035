import React from 'react';
import PT from 'prop-types';

import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';

const SelectFormControl = ({
  id,
  label,
  value,
  onChange,
  onMenuOpen,
  options,
  renderValue,
  MenuProps,
  multiple,
  defaultValue,
  inputStyles = {}
}) => (
  <FormControl
    sx={{
      ml: 0,
      mt: 0,
      width: '100%'
    }}
  >
    <InputLabel
      id={id}
      shrink
      sx={{
        position: 'static',
        transform: 'none',
        mb: '8px',
        fontSize: '12px',
        lineHeight: 1
      }}
    >
      {label}
    </InputLabel>

    <Select
      labelId={id}
      multiple={multiple}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      onOpen={onMenuOpen}
      input={
        <OutlinedInput
          sx={{
            height: 34,
            padding: 0,

            '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderWidth: '1px'
            },
            '& .MuiOutlinedInput-input': {
              padding: 0,
            },

            ...inputStyles
          }}
        />
      }
      renderValue={renderValue}
      MenuProps={MenuProps}
    >
      {options.map(({ value, label: { primary, secondary } = {}, isDivider }) => (
        isDivider
          ? <Divider key={value} />
          : (
            <MenuItem
              key={value}
              value={value}
            >
              <ListItemText
                primary={primary}
                secondary={secondary}
              />
            </MenuItem>
          )
      ))}
    </Select>
  </FormControl>
);

SelectFormControl.propTypes = {
  id: PT.string.isRequired,
  label: PT.string.isRequired,
  defaultValue: PT.string,
  value: PT.oneOfType([PT.array, PT.string]),
  onChange: PT.func.isRequired,
  onMenuOpen: PT.func,
  options: PT.array.isRequired,
  renderValue: PT.func,
  MenuProps: PT.object,
  multiple: PT.bool,
  inputStyles: PT.object
};

export default SelectFormControl;
