import styled from 'styled-components';

const MentionElement = styled.span`
  padding: 3px 3px 2px;
  margin: 0 1px;
  vertical-align: baseline;
  display: inline-block;
  border-radius: 4px;
  background: #eee;
  font-size: 0.9em;
  boxShadow: ${({ selected, focused }) => selected && focused ? '0 0 0 2px #B4D5FF' : 'none'};
`;

export default MentionElement;
