import React, { useCallback } from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Divider from '@mui/material/Divider';

const SingleTable = ({ table, onDelete }) => {

  const handleClick = useCallback(() => {
    onDelete(table.id);
  }, [onDelete, table.id]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          {[...table.path.map(({ title }) => title), table.title].join(' / ')}
        </Typography>

        <IconButton
          disableRipple
          sx={{
            padding: 0,
          }}
          onClick={handleClick}
        >
          <DeleteOutlinedIcon sx={{ width: '16px' }} />
        </IconButton>
      </Box>

      <Divider />
    </Box>
  );
};

SingleTable.propTypes = {
  table: PT.object.isRequired,
  onDelete: PT.func.isRequired,
};

export default SingleTable;
