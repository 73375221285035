import React, { useState, useCallback, useMemo } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { useMutation, gql } from '@apollo/client';
import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import EditIcon from '@mui/icons-material/Edit';

import TitleInput from './TitleInput';

import { openAppSnackbarNotification } from '../../../services/snackbar-notifications/actions';

const UPDATE_TABLE = gql`
  mutation UpdateTable($id: ID!, $hash: String!, $data: UpdateTableInput!) {
    updateTable(id: $id, hash: $hash, data: $data) {
      id
      title
      description
      hash
    }
  }
`;

const TableTitle = ({ className, table, readOnly }) => {
  const { id, path, title, hash } = table;

  const [editModeOn, setEditModeOn] = useState(false);

  const [updateTable] = useMutation(UPDATE_TABLE);

  const handleToggleEditMode = useCallback(() => {
    setEditModeOn(state => !state);
  }, []);

  const displayTitle = useMemo(() => {
    const folders = path.map(({ title }) => title).join(' / ');

    return `${folders}${folders.length ? ' / ' : ''}${title}`;
  }, [path, title]);

  const dispatch = useDispatch();

  const handleTitleChange = useCallback(async (value) => {
    try {
      await updateTable({
        variables: {
          id,
          data: {
            title: value
          },
          hash
        }
      });
    } catch(e) {
      dispatch(openAppSnackbarNotification({
        message: e.message,
        variant: 'ERROR'
      }));
    }

    setEditModeOn(false);
  }, [updateTable, id, hash, dispatch]);

  if(editModeOn)
    return (
      <TitleInput
        title={title}
        onSubmit={handleTitleChange}
        onCancel={handleToggleEditMode}
      />
    );

  else
    return (
      <Box
        className={className}
        display="flex"
        alignItems="center"
        gap="10px"
      >
        <Typography
          fontSize={24}
          lineHeight="32px"
          fontWeight="bold"
        >
          {displayTitle}
        </Typography>

        {readOnly ?
          null :
          <IconButton
            className="edit-button"
            size="small"
            onClick={handleToggleEditMode}
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
        }
      </Box>
    );
};

TableTitle.propTypes = {
  className: PT.string,
  table: PT.shape({
    id: PT.string.isRequired,
    path: PT.arrayOf(PT.shape({
      title: PT.string.isRequired
    })).isRequired,
    title: PT.string.isRequired,
    hash: PT.string.isRequired
  }).isRequired,
  readOnly: PT.bool
};

export default styled(TableTitle)`
  .edit-button {
    display: none;
  }

  :hover {
    .edit-button {
      display: inline-flex;
    }
  }
`;
