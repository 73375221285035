const refetchWithNewColumns = async ({
  columnToDelete,
  items,
  table,
  queryVariables,
  refetchQuery,
}) => {
  let tableHeaderFeatureIDs = [];
  let tableHeaderProtocolGroupIDs = [];

  const iterableItems = items ?? table.allColumns.reduce((curr, item) => {
    if (item.id === 'name')
      return curr;

    const type = queryVariables.current.input.tableHeaderProtocolGroupIDs?.includes(item.id) ?
      'FORMULATION' :
      'PROTOCOL';

    let parentVisible = item.isVisible();

    const columns = item.columns.map(column => {
      const { id } = column;
      let visible = column.isVisible();

      if(columnToDelete === id) {
        parentVisible = false;
        visible = false;
      }

      return { id, visible };
    });

    return [
      ...curr,
      {
        id: item.id,
        type,
        columns,
        visible: parentVisible,
      }
    ];
  }, []);

  for (const { id: parentId, visible: parentVisible, type, columns } of iterableItems) {
    if (parentId === 'Item info') {
      for (const { id, visible } of columns) {
        const column = table.getColumn(id);

        if (column?.isVisible() !== visible) {
          column.toggleVisibility(visible);
        }
      }
    } else {
      const visibleColumns = columns
        .filter(({ visible }) => visible)
        .map(({ id }) => id);

      if(type === 'FORMULATION' && visibleColumns.length) {
        tableHeaderProtocolGroupIDs.push(parentId);

        if(parentVisible) continue;
      }

      tableHeaderFeatureIDs = tableHeaderFeatureIDs.concat(visibleColumns);
    }
  }

  if(tableHeaderProtocolGroupIDs.length) {
    queryVariables.current.input.tableHeaderProtocolGroupIDs = tableHeaderProtocolGroupIDs;
  } else {
    delete queryVariables.current.input.tableHeaderProtocolGroupIDs;
  }

  if (tableHeaderFeatureIDs.length) {
    queryVariables.current.input.tableHeaderFeatureIDs = tableHeaderFeatureIDs;
  } else {
    delete queryVariables.current.input.tableHeaderFeatureIDs;
  }

  await refetchQuery(queryVariables.current);
};

export default refetchWithNewColumns;
