import styled, { css } from 'styled-components';
import { COLOR_WHITE, COLOR_BLACK, COLOR_GREY } from '../../../styles';
import Link from '@mui/material/Link';

import Button from '../../../components/Button';

export const UserInfoStyled = styled.div.attrs({ className: 'user-info' })`
  padding: 15px 0 0;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  
  .extra-info {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    
    > div {
      display: flex;
      align-items: center;
       padding: 8px 15px;
    }
    
    .avatar {
      margin-right: 8px;
    }
    
    svg {
      margin-right: 12px;
      color: ${COLOR_GREY};
    }
    
    a {
      text-decoration: none;
      color: ${COLOR_BLACK};
    }

    ${props =>
    props.mobile &&
      css`
        svg {
          color: ${COLOR_WHITE};
        }
      `
}

    .email {
      color: ${COLOR_BLACK};
    }
  }
  .extra-info-bottom {
    padding: 8px 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
`;
export const Info = styled.div`
  padding-left: 15px;
  display: flex;
  flex-direction: column;
`;
export const UserBlock = styled.div`
  display: flex;
  padding-bottom: 15px;
`;
export const Text = styled.div`
  color: ${({ color }) => color || COLOR_BLACK};
  font-size: ${({ size }) => size}px;
  margin-bottom: 6px;

  &.username,
  &.userid {
    font-weight: bold;
  }
`;

export const LinkStyled = styled(Link)`
  text-transform: capitalize;
  background-color: ${COLOR_WHITE}
`;

export const ButtonStyled = styled(Button)`
  padding: 5px 0;
  border: none;
  border-radius: 2px;
  font-weight: 500;
  &:hover {
    background-color: initial
  }
`;
