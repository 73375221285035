import React from 'react';

import Divider from '@mui/material/Divider';

const VerticalLine = () => (
  <Divider
    flexItem
    orientation="vertical"
    sx={{
      alignSelf: 'center',
      height: '18px'
    }}
  />
);

export default VerticalLine;
