import styled from 'styled-components';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const Wrapper = styled(Box)`
  display: flex; 
  flex: 1;
  flex-direction: column;
`;

export const Title = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 16px;
`;

export const Subtext = styled(Typography)`
  font-weight: 300;
  font-size: 12px;
`;
