import React, { useEffect, useCallback } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';

import useMediaQuery from '@mui/material/useMediaQuery';

import Main from '../Main';
import AppHeader from '../AppHeader';

import { isMobileView } from '../../../services/session/selectors';
import {
  setMobileView,
  toggleMobileView
} from '../../../services/session/actions';
import { HEADER_BREAKPOINT } from '../../../styles';

import { useAuth } from '../../../context/auth/auth';

import PEROVSKITE_VIEWER from '../AppRouting/gql/perovskite-user';

const mapStateToProps = state => ({
  isMobileView: isMobileView(state)
});

// FIXME: Remove custom screen size measurements

const mapDispatchToProps = {
  setMobileView,
  toggleMobileView
};

const getScreenType = () => {
  return window.innerWidth <= 768 ? 'mobile' : 'desktop';
};

const isMobile = () => {
  return getScreenType() === 'mobile';
};

const useMobileView = (value, onChange) => {
  const handleResize = useCallback(() => {
    if (isMobile() !== value) {
      onChange();
    }
  }, [value, onChange]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
};

const RootLegacy = (props) => {
  const {
    isMobileView,
    setMobileView,
    toggleMobileView,
    children
  } = props;

  useMobileView(isMobileView, toggleMobileView);
  const mobile = useMediaQuery(`(${HEADER_BREAKPOINT})`);

  const { emailVerified } = useAuth();
  const { data } = useQuery(PEROVSKITE_VIEWER, { skip: !emailVerified });

  const viewerExist = Boolean(data?.viewer);
  const perovskiteUser = data?.viewer?.perovskiteUser;

  useEffect(() => {
    setMobileView(isMobile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {viewerExist ?
        <AppHeader
          mobile={mobile}
          isRegularUser={!perovskiteUser}
        /> :
        null
      }

      <Main isFullScreen={!viewerExist}>
        {children}
      </Main>
    </>
  );
};

RootLegacy.propTypes = {
  isMobileView: PT.bool.isRequired,
  setMobileView: PT.func.isRequired,
  toggleMobileView: PT.func.isRequired,
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node
  ])
};

export default connect(mapStateToProps, mapDispatchToProps)(RootLegacy);
