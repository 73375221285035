import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { useLazyQuery, useQuery } from '@apollo/client';

import CircularProgress from '@mui/material/CircularProgress';

import {
  openAppSnackbarNotification,
} from '../../services/snackbar-notifications/actions';

import Comment from './Comment';
import NewComment from './NewComment';

import { GET_COMMENTS, GET_TABLE_USERS, GET_VIEWER } from './services';

import CommentsContainer, {
  CommentsLoadingContainer
} from './styles';
import { useDidUpdateEffect } from '../../utils/hooks';
import useCommentsSubscription from './comments-subscription.hook';

function Comments({
  iam,
  itemId,
  tableId,
  isSectionExpanded,
  onCommentAdded,
  onCommentRemoved,
  borderless
}) {
  const [getComments, { loading, data, updateQuery, error }] = useLazyQuery(GET_COMMENTS, {
    variables: { itemId },
    fetchPolicy: 'cache-and-network'
  });

  const [getUsers, {
    loading: loadingUsers,
    data: usersData,
    refetch: refetchUsers
  }] = useLazyQuery(GET_TABLE_USERS, {
    variables: { tableId },
    fetchPolicy: 'cache-and-network'
  });

  const { data: viewerData } = useQuery(GET_VIEWER, {
    fetchPolicy: 'cache-first'
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSectionExpanded){
      getComments();
      getUsers();
    }
  }, [getComments, getUsers, isSectionExpanded]);

  useCommentsSubscription({
    itemId,
    updateItemsCache: updateQuery,
    onDeleted: onCommentRemoved,
    onAdded: onCommentAdded,
  });

  useEffect(() => {
    if (error) {
      console.error(error);

      dispatch(
        openAppSnackbarNotification({
          message: 'Fetching comments is failed.',
          variant: 'ERROR'
        })
      );
    }
  }, [dispatch, error]); /* eslint-disable-next-line react-hooks/exhaustive-deps */

  useDidUpdateEffect(() => {
    refetchUsers();
  }, [iam, refetchUsers]);

  const handleNewError = useCallback(() => {
    dispatch(
      openAppSnackbarNotification({
        message: 'Adding a new comment is failed.',
        variant: 'ERROR',
      })
    );
  }, [dispatch]);

  const mentionOptions = useMemo(() => {
    return get(usersData, 'tableUsers', [])
      .filter(user => user.id !== viewerData?.viewer.id)
      .map(user => ({
        ...user,
        name: `${user.firstName} ${user.lastName}`,
      }));
  }, [usersData, viewerData?.viewer.id]);

  if(loading)
    return (
      <CommentsLoadingContainer>
        <CircularProgress size={28} />
      </CommentsLoadingContainer>
    );

  return (
    <CommentsContainer
      borderless={borderless}
    >
      {data?.tableItemComments.map(({
        id,
        author,
        contents,
        dateCreated,
        dateUpdated,
        likes
      }) => (
        <Comment
          key={id}
          sampleId={itemId}
          commentId={id}
          author={author}
          contents={contents}
          dateCreated={dateCreated}
          dateUpdated={dateUpdated}
          likes={likes}
          mentionOptionsLoading={loadingUsers}
          mentionOptions={mentionOptions}
          updateItemCache={updateQuery}
          isSectionExpanded={isSectionExpanded}
        />
      ))}

      <NewComment
        itemId={itemId}
        mentionOptionsLoading={loadingUsers}
        mentionOptions={mentionOptions}
        onError={handleNewError}
      />
    </CommentsContainer>
  );
}

Comments.propTypes = {
  iam: PropTypes.object,
  itemId: PropTypes.string.isRequired,
  tableId: PropTypes.string.isRequired,
  isSectionExpanded: PropTypes.bool.isRequired,
  onCommentAdded: PropTypes.func.isRequired,
  onCommentRemoved: PropTypes.func.isRequired,
  borderless: PropTypes.bool,
};

export default Comments;

