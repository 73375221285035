import { createRequestTypes, createHandlers } from '../../actions';
import * as constants from './constants';

export const measurementParseByIdTypes = createRequestTypes(constants.MEASUREMENT_PARSE_BY_ID);
export const measurementsParseTypes = createRequestTypes(constants.MEASUREMENTS_PARSE);
export const measurementProcessedDataLoadByIdTypes = createRequestTypes(constants.MEASUREMENT_PROCESSED_DATA_LOAD_BY_ID);

export const measurementParseByIdHandlers = createHandlers(measurementParseByIdTypes);
export const measurementsParseHandlers = createHandlers(measurementsParseTypes);
export const measurementProcessedDataLoadByIdHandlers = createHandlers(measurementProcessedDataLoadByIdTypes);

export const parseMeasurementById = measurementParseByIdHandlers.request;
