import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual, isUndefined } from 'lodash';

import {
  Checkbox,
  TableCell
} from '../styles';
import TableRowStyled, { TableCellChildren } from './styles';

const mapStateToProps = (state, props) => {
  const { checked, show, expanded, disabled } = props.itemSelector
    ? props.itemSelector(state, props)
    : {
      checked: props.checked,
      show: true,
      expanded: isUndefined(props.expanded) ? true : props.expanded,
      disabled: false
    };

  return {
    checked,
    show,
    expanded,
    disabled,
    checkBoxDisabled: props.checkBoxDisabled,
    indeterminate: props.indeterminateSelector
      ? props.indeterminateSelector(state, props.item.id)
      : false,
    renderChildren: props.renderChildren,
    isCheckedAll: props.isCheckedAllSelector
      ? props.isCheckedAllSelector(state)
      : false,
    childrenData: props.childrenSelector && expanded
      ? props.childrenSelector(state, props.item.id)
      : null
  };
};

class TableRow extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    selectable: PropTypes.bool,
    columnsMap: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }).isRequired).isRequired,
    checked: PropTypes.bool,
    show: PropTypes.bool.isRequired,
    onCheck: PropTypes.func,
    onExpandToggle: PropTypes.func,
    expanded: PropTypes.bool,
    childrenData: PropTypes.any,
    renderChildren: PropTypes.func,
    isCheckedAll: PropTypes.bool.isRequired,
    className: PropTypes.string.isRequired,
    indeterminate: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    mobile: PropTypes.bool.isRequired,
    checkBoxDisabled: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    expanded: false,
    checked: false,
    mobile: false
  };

  state = {
    expanded: isUndefined(this.props.expanded) ? true : this.props.expanded,
    checked: this.props.checked,
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    const {
      expanded,
      checked,
      item,
      onCheck,
      onExpandToggle,
      isCheckedAll,
      indeterminate
    } = newProps;
    const isExpandedChanged = expanded !== this.props.expanded;
    const isCheckedChanged = checked !== this.props.checked;

    if (isExpandedChanged && this.state.expanded !== expanded) {
      onExpandToggle(item.id, expanded);
      this.setState({ expanded });
    }

    const isChildUnchecked = !isCheckedAll && indeterminate;

    if (isCheckedAll !== this.props.isCheckedAll && !isChildUnchecked) {
      if (isCheckedChanged && this.state.checked !== checked) {
        onCheck(item.id, checked);
        this.setState({ checked });
      }
    }
  }

  shouldComponentUpdate(newProps, newState) {
    return !isEqual(newProps, this.props) || !isEqual(newState, this.state);
  }

  handleCheck = e => {
    e && e.stopPropagation();

    const { item, onCheck, checked } = this.props;
    this.setState({ checked: !checked });
    onCheck(item.id, !checked);
  };

  handleExpandToggle = () => {
    const { item, onExpandToggle, expanded } = this.props;

    if (!item.children) { return; }

    this.setState({ expanded: !expanded }, () => {
      onExpandToggle(item.id, !expanded);
    });
  };

  render() {
    const {
      item,
      selectable,
      columnsMap,
      checked,
      show,
      expanded,
      disabled,
      childrenData,
      renderChildren,
      className,
      indeterminate,
      mobile,
      checkBoxDisabled
    } = this.props;

    if (!show) return null;

    const tableCellClassName = c => `table-cell-content ${c.value}${
      c.expandAction ? ' clickable' : ''
    }`;
    const isRenderChildrenExists = childrenData && renderChildren;
    const childrenRendered = isRenderChildrenExists
      ? renderChildren(childrenData, item.id)
      : (typeof item.children === 'function' ? item.children(childrenData, item.id) : null);

    return (
      <TableRowStyled
        key={item.id}
        className={`${className}${expanded ? ' expanded' : ''}`}
      >
        {disabled && <div className="table-cell-disable-banner" />}

        <div className="table-cell-main">
          {selectable &&
            <TableCell
              className="checkbox-cell"
              mobile={mobile}
            >
              {[undefined, true].includes(item.checkbox) &&
                <Checkbox
                  color="primary"
                  checked={checked}
                  disabled={checkBoxDisabled}
                  onChange={this.handleCheck}
                  indeterminate={indeterminate}
                  className={indeterminate ? 'indeterminate' : ''}
                  disableRipple
                />
              }
            </TableCell>
          }

          {columnsMap.map(c =>
            <TableCell
              mobile={mobile}
              className={tableCellClassName(c)}
              key={`${c.value}${item.id}`}
              onClick={(c.expandAction && this.handleExpandToggle) || undefined}
            >
              {c.component && item[c.value] ? item[c.value]() : item[c.value]}
            </TableCell>
          )}
        </div>

        {(renderChildren || typeof item.children === 'function') &&
          <TableCellChildren in={expanded}>
            {childrenRendered}
          </TableCellChildren>
        }
      </TableRowStyled>
    );
  }
}

export default connect(mapStateToProps)(TableRow);
