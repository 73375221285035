import { gql } from '@apollo/client';

export const CLONE_ITEM = gql`
  mutation CloneTableItem($id: ID!, $data: CloneTableItemInput!) {
    cloneTableItem(id: $id, data: $data) {
      id
      code
    }
  }
`;
