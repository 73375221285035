import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';

import ArrowDown from '@mui/icons-material/ArrowDropDown';

import RaisedInput from '../../components/RaisedInput';
import Option from './Option';

import { useCallback } from 'react';

const styles = {
  inputLabel: {
    transform: 'translate(0px, -1px) scale(0.75)'
  },
  formControl: {
    flex: '0 0 80px'
  },
  selectArrowIcon: {
    pointerEvents: 'none'
  },
  inputField: {
    paddingLeft: '3px',
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,0.26)',
    borderRadius: '2px',
    width: 'inherit'
  },
  selectedItem: {
    position: 'absolute',
    bottom: 0,
    top: 16,
    left: 0,
    padding: '5px 24px 5px 8px',
    height: '42px',
    boxSizing: 'border-box',
    lineHeight: '32px',
    pointerEvents: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  dropDownMenu: {
    minWidth: '420px',
    maxHeight: '170px',
  }
};

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left'
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'left'
};

const keys = [' ', 'ArrowUp', 'ArrowDown', 'Enter'];

const SearchableSelectBox = ({
  onSelect,
  classes,
  value,
  label,
  options,
  error,
}) => {
  const [anchorEl, setAnchor] = useState(void 0);
  const [input, setInput] = useState('');

  const inputRef = useRef();

  const handleMenuOpen = useCallback(() => {
    if(!anchorEl)
      setAnchor(inputRef.current);
  }, [anchorEl]);

  const handleMenuClose = useCallback(() => {
    if(anchorEl)
      setAnchor(void 0);

    setInput('');
  }, [anchorEl]);

  const handleKeyDown = useCallback((ev) => {
    const { key } = ev;

    if(keys.includes(key)) {
      ev.preventDefault();

      handleMenuOpen();
    } else if(key === 'Escape') {
      handleMenuClose();
    }
  }, [handleMenuOpen, handleMenuClose]);

  const handleInputChange = useCallback((ev) => {
    const input = ev.target.value;

    if(!anchorEl)
      setAnchor(inputRef.current);

    setInput(input);
  }, [anchorEl]);

  const handleSelect = useCallback((value) => () => {
    setInput('');
    handleMenuClose();
    onSelect(value);
  }, [handleMenuClose, onSelect]);

  const filteredOptions = options.filter(i => i.label.toLowerCase().includes(input.toLowerCase()));
  const inputProps = {
    onKeyDown: handleKeyDown,
    autoComplete: 'off'
  };

  return (
    <React.Fragment>
      <FormControl
        classes={{ root: classes.formControl }}
        aria-owns={anchorEl ? 'items-menu' : undefined}
        aria-haspopup="true"
        onClick={handleMenuOpen}
        error={Boolean(error)}
      >
        <InputLabel
          htmlFor="input-field"
          className={classes.inputLabel}
          shrink
        >
          {label}
        </InputLabel>

        <RaisedInput
          id="input-field"
          name="unit"
          type="text"
          onChange={handleInputChange}
          value={input}
          classes={{ root: classes.inputField }}
          placeholder={value ? '' : 'Start typing...'}
          endAdornment={
            <InputAdornment
              position="end"
              classes={{ root: classes.selectArrowIcon }}
            >
              <ArrowDown />
            </InputAdornment>
          }
          inputRef={inputRef}
          inputProps={inputProps}
        />

        {input ?
          null :
          <div className={classes.selectedItem}>
            {value}
          </div>
        }
      </FormControl>

      <Popover
        id="items-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        disableAutoFocus
        classes={{ paper: classes.dropDownMenu }}
      >
        <MenuList>
          {filteredOptions.length
            ? filteredOptions.map(({
              component: Component = Option,
              ...rest
            }) => <Component {...rest} key={rest.value} onClick={handleSelect} />
            )
            : (
              <MenuItem
                disabled
              >
                No Items
              </MenuItem>
            )
          }
        </MenuList>
      </Popover>
    </React.Fragment>
  );
};

SearchableSelectBox.propTypes = {
  onSelect: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  error: PropTypes.string
};

export default withStyles(styles)(SearchableSelectBox);
