import styled from 'styled-components';

export default styled.ul`
  padding: 0;

  li {
    list-style-type: none;
    padding-left: 10px;
  }
`;
