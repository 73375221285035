import React, {
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useAnalytics } from 'use-analytics';


import { useMutation, gql, useQuery } from '@apollo/client';

import CommentAuthor from './CommentAuthor';
import CommentText from './CommentText';
import TextEditor from '../TextEditor';

import useHashScroll from '../../hooks/hash-scroll';

import {
  CommentContainer,
  CommentTextContainer
} from './styles';

import {
  openAppSnackbarNotification,
} from '../../services/snackbar-notifications/actions';

import {
  UPDATE_COMMENT,
  DELETE_COMMENT,
  TOGGLE_COMMENT_REACTION
} from './services';
import { componentNames, TRACK_SINGLE_COMMENT } from '../../analytics/constants';
import omit from 'lodash/omit';

const GET_VIEWER = gql`
  query SampleCommentViewerInfo {
    viewer {
      id
      name
      avatarColor
      picture {
        downloadURL
      }
    }
  }
`;

function Comment({
  sampleId,
  commentId,
  author,
  contents,
  dateCreated,
  dateUpdated,
  likes,
  mentionOptionsLoading,
  mentionOptions,
  updateItemCache,
  isSectionExpanded,
}) {
  const [edit, setEdit] = useState(false);
  const commentRef = useRef();

  const { data: viewerResponse } = useQuery(GET_VIEWER);

  const userUID = viewerResponse?.viewer?.id;

  const { track } = useAnalytics();

  const dispatch = useDispatch();

  const [updateSampleComment, {
    error: updateCommentError
  }] = useMutation(UPDATE_COMMENT, { errorPolicy: 'all' });
  const [deleteSampleComment, {
    error: deleteCommentError
  }] = useMutation(DELETE_COMMENT, { errorPolicy: 'all' });
  const [toggleCommentReaction, {
    error: reactCommentError
  }] = useMutation(TOGGLE_COMMENT_REACTION, { errorPolicy: 'all' });

  useHashScroll('comment', {
    elementRef: commentRef,
    id: commentId
  });

  useEffect(() => {
    let action;

    if (updateCommentError) {
      action = 'updating';
    } else if (deleteCommentError) {
      action = 'deleting';
    } else if (reactCommentError) {
      action = 'reaction';
    }

    if (action) {
      dispatch(
        openAppSnackbarNotification({
          message: `Comment ${action} is failed.`,
          variant: 'ERROR',
        })
      );
    }
  }, [
    dispatch,
    updateCommentError,
    deleteCommentError,
    reactCommentError,
  ]); /* eslint-disable-next-line react-hooks/exhaustive-deps */

  useEffect(() => {
    if (!isSectionExpanded){
      setEdit(false);
    }
  }, [isSectionExpanded]);

  const handleStartEdit = useCallback(() => {
    setEdit(true);
  }, []);

  const removeTypename = useCallback((content) => {
    if (content.children)
      return omit({
        ...content,
        children: content.children.map(child => removeTypename(child)),
      }, ['__typename']);

    return omit(content, ['__typename']);
  }, []);

  const handleSubmit = useCallback(contents => {
    setEdit(false);
    const typelessContents = contents.map(content => removeTypename(content));

    updateSampleComment({
      variables: {
        id: commentId,
        input: {
          contents: typelessContents
        }
      }
    });
  }, [commentId, removeTypename, updateSampleComment]);

  const handleReaction = useCallback(value => {
    track(TRACK_SINGLE_COMMENT.like, {
      component: componentNames.COMMENTS
    });

    toggleCommentReaction({
      variables: {
        id: commentId
      },
    });

    updateItemCache(s => ({
      ...s,
      tableItemComments: s.tableItemComments.map(i =>
        i.id === commentId
          ? {
            ...i,
            likes: value
              ? likes.concat({
                id: userUID,
                avatarColor: viewerResponse?.viewer?.avatarColor,
                name: viewerResponse?.viewer?.name,
                picture: {
                  ...( viewerResponse?.viewer?.picture || {} ),
                  __typename: 'FileLocationRecord'
                },
                __typename: 'User',
              })
              : likes.filter(i => i.id !== userUID)
          }
          : i
      )
    }));
  }, [
    commentId,
    likes,
    toggleCommentReaction,
    userUID,
    updateItemCache,
    viewerResponse?.viewer,
    track
  ]);

  const handleCancel = useCallback(() => {
    setEdit(false);
  }, []);

  const handleDelete = useCallback(async () => {
    track(TRACK_SINGLE_COMMENT.delete, {
      component: componentNames.COMMENTS
    });

    await deleteSampleComment({
      variables: {
        id: commentId
      }
    });
  }, [track, deleteSampleComment, commentId]);

  return (
    <CommentContainer
      id={commentId}
      ref={commentRef}
    >
      <CommentAuthor
        username={author.name}
        avatarColor={author.avatarColor}
        pictureURL={get(author, 'picture.downloadURL')}
        dateCreated={dateCreated}
        dateUpdated={dateUpdated}
      />

      {edit ?
        <CommentTextContainer>
          <TextEditor
            contents={contents}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            mentionOptionsLoading={mentionOptionsLoading}
            mentionOptions={mentionOptions}
          />
        </CommentTextContainer> :
        <CommentText
          sampleId={sampleId}
          commentId={commentId}
          contents={contents}
          likes={likes}
          onStartEdit={handleStartEdit}
          onDelete={handleDelete}
          onReaction={handleReaction}
          editable={userUID === author.id}
        />
      }
    </CommentContainer>
  );
}

Comment.propTypes = {
  sampleId: PropTypes.string.isRequired,
  commentId: PropTypes.string.isRequired,
  author: PropTypes.object.isRequired,
  contents: PropTypes.array.isRequired,
  dateCreated: PropTypes.number.isRequired,
  dateUpdated: PropTypes.number,
  likes: PropTypes.array.isRequired,
  mentionOptionsLoading: PropTypes.bool.isRequired,
  mentionOptions: PropTypes.array.isRequired,
  updateItemCache: PropTypes.func.isRequired,
  isSectionExpanded: PropTypes.bool.isRequired,
};

export default Comment;
