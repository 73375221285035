import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import StyledGraphicsImage from './styles';

class GraphicsImage extends PureComponent {
  static propTypes = {
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Blob)]).isRequired
  };

  constructor(props) {
    super(props);

    const { src: imageSource } = props;
    let src = null;

    if(typeof imageSource === 'string')
      src = imageSource;
    else if(imageSource instanceof Blob)
      src = window.URL.createObjectURL(imageSource);

    this.state = { src };
  }

  componentWillUnmount() {
    const { src } = this.state;

    if(src) window.URL.revokeObjectURL(src);
  }

  render() {
    const { src } = this.state;

    if(!src) return null;

    return (
      <StyledGraphicsImage src={src} />
    );
  }
}

export default GraphicsImage;
