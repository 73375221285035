import styled from 'styled-components';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const Wrapper = styled(Box)`
  overflow: hidden; 
  max-width: 1400px;
  padding: 0 8px;
`;

export const Title = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 8px;
`;

export const Content = styled(Box)`
  display: flex;
  margin-top: 30px; 
  max-height: 55%;
  height: 55%;
  min-height: 50vh;
  flex-wrap: wrap;
  gap: 16px;
`;
