import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import { ActionIcon, ActionStyled } from './styles';

const SingleAction = ({ icon, title, onClick, color }) => {
  return (
    <ActionStyled variant="outlined" onClick={onClick}>
      <ActionIcon color={color}>
        {icon}
      </ActionIcon>

      <Typography
        noWrap
      >
        {title}
      </Typography>
    </ActionStyled>
  );
};

SingleAction.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SingleAction;
