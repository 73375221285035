import set from 'lodash/set';

import chartSettingsConfig from './chart-settings-config';
import { GRAPHICS_TYPES } from './constants';
import { PARSER_VIEW_TYPES } from '../../utils/parsing';

export const withDefaultsAndAxes = (data, viewType, alter) =>
  (!data || !chartSettingsConfig[viewType])
    ? null
    : chartSettingsConfig[viewType].axes
      ?.reduce((result, { name, defaultValue, transform }) => {
        const value = data[name] ?? defaultValue;

        result[name] = value;

        if(transform !== void 0)
          set(result.axes, name, {
            transform: alter?.find(i => i.target === name)?.transform || transform,
            // TODO: implement it later, no BE support yet
            // warning: get(data, `axes.${name}.warning`, false)
          });

        return result;
      }, { axes: {} });

export const getGraphicsViewType = type => {
  switch(type) {
    case GRAPHICS_TYPES.GRAPHICS_TYPE_IMAGE:
      return PARSER_VIEW_TYPES.VIEW_IMAGE;
    case GRAPHICS_TYPES.GRAPHICS_TYPE_PDF:
      return PARSER_VIEW_TYPES.VIEW_PDF;
    case GRAPHICS_TYPES.GRAPHICS_TYPE_LINE_CHART:
      return PARSER_VIEW_TYPES.VIEW_2D_CUSTOM_AXES;
    case GRAPHICS_TYPES.GRAPHICS_TYPE_SURFACE:
      return PARSER_VIEW_TYPES.VIEW_3D;
    case GRAPHICS_TYPES.GRAPHICS_TYPE_HEATMAP:
      return PARSER_VIEW_TYPES.VIEW_HEATMAP;
    case GRAPHICS_TYPES.GRAPHICS_TYPE_RAW_HTML:
      return PARSER_VIEW_TYPES.VIEW_DOCX;
    case GRAPHICS_TYPES.GRAPHICS_TYPE_SCATTER_PLOT:
      return PARSER_VIEW_TYPES.VIEW_SCATTER_PLOT;
    case GRAPHICS_TYPES.GRAPHICS_TYPE_BOX_PLOT:
      return PARSER_VIEW_TYPES.VIEW_BOX_PLOT;
    case GRAPHICS_TYPES.GRAPHICS_TYPE_HISTOGRAM_SINGLE:
      return PARSER_VIEW_TYPES.VIEW_HISTOGRAM_SINGLE;
    case GRAPHICS_TYPES.GRAPHICS_TYPE_WAFER_MAP:
      return PARSER_VIEW_TYPES.VIEW_WAFER_MAP;
    case GRAPHICS_TYPES.GRAPHICS_TYPE_VECTOR_WAFER_MAP:
      return PARSER_VIEW_TYPES.VIEW_VECTOR_WAFER_MAP;
    case GRAPHICS_TYPES.GRAPHICS_TYPE_CORRELATION_MATRIX:
      return PARSER_VIEW_TYPES.VIEW_CORRELATION_MATRIX;
    default:
      throw `[getGraphicsViewType]: ${type} is unhandled type.`;
  }
};
