/* eslint-disable max-len*/
import styled from 'styled-components';

import ButtonComponent from '@mui/material/Button';

import {
  COLOR_WHITE,
  MEDIA_QUERIES_XS,
  COLOR_GREY_LIGHTER
} from '../../../styles';

import {
  ACTIONS_PANEL_HEIGHT
} from '../../../components/Sample/ActionsPanel';

export default styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  left: 0;
  z-index: 2;
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  padding: 0px 1px;
  background-color: ${COLOR_WHITE};
  margin-bottom: 10px;
  height: ${ACTIONS_PANEL_HEIGHT}px;
  overflow: hidden;

  .action {
    float: left;

    &.download-action {
      min-width: 0;
      margin-right: 10px;

      button {
        min-width: 0;
      }
    }
  }

  @media (${MEDIA_QUERIES_XS}) {
    justify-content: flex-start;
    width: calc(100% - 24px);
    top: -5px;
    padding: 8px;

    .action {
      flex-basis: auto;
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 5px;

      button {
        min-height: 0;
        height: 28px;

        svg {
          width: auto;
          height: 18px;
        }
      }
    }

    .action > button {
      margin: 0;
      min-width: 0;
      width: 100%;
      font-size: 0.8rem;
    }
  }
`;

export const Button = styled(ButtonComponent)`
  && {
    margin-right: 10px;
    min-height: 34px;
    padding: 0 8px;
  }
`;

export const Counter = styled.div`
  margin-left: auto;
  color: ${COLOR_GREY_LIGHTER};
`;
