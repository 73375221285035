import {
  call,
  takeLatest,
} from 'redux-saga/effects';
import { gql } from '@apollo/client';

import * as api from './api';
import * as actions from './actions';
import { REQUEST } from './constants';
import { fetchEntity } from '../../sagas';
import client from '../apollo-client';
import 'firebase/auth';

const GET_VIEWER = gql`
  query ViewerBasicSessionProfile {
    viewer {
      id
      userId
    }
  }
`;

export const listeners = [
  takeLatest(actions.filesListLoadTypes[REQUEST], onFilesListLoadRequest),
];

const fetchFilesListLoad = fetchEntity.bind(null,
  actions.filesListLoadHandlers,
  api.filesListLoad
);

function* onFilesListLoadRequest(params) {
  const { data } = yield client.query({
    query: GET_VIEWER,
    fetchPolicy: 'network-only'
  });

  const userId = data?.viewer?.id;

  yield call(fetchFilesListLoad, {
    ...params,
    userId
  });
}
