import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';

import { LinkEmailProviderModalContainer } from '../styles';
import OutlinedInputWithLabel from '../../../../components/OutlinedInputWithLabel';
import {
  PasswordPolicy,
  isEmailValid,
  isPasswordValid
} from '../../../../components/Auth';
import { DIALOG_BREAKPOINT } from '../../../../styles';

function LinkEmailProviderModal({
  open,
  onClose,
  onExit,
  onSubmit,
}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const isMobileView = useMediaQuery(`(${DIALOG_BREAKPOINT})`);

  const handleEmailChange = useCallback(ev => {
    setEmail(ev.target.value);
  }, []);

  const handlePasswordChange = useCallback(ev => {
    setPassword(ev.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit({
      email,
      password
    });
  }, [email, password, onSubmit]);

  const handleExit = useCallback(() => {
    setEmail('');
    setPassword('');

    if(typeof onExit === 'function')
      onExit();
  }, [onExit]);

  const isInputInalid = !isEmailValid(email)
    || !isPasswordValid(password);

  return (
    <LinkEmailProviderModalContainer
      open={open}
      onClose={onClose}
      onExit={handleExit}
      fullScreen={isMobileView}
      aria-labelledby="link-email-provider-title"
      maxWidth="xs"
    >
      <DialogTitle id="link-email-provider-title">
        Connect email authentication
      </DialogTitle>

      <DialogContent>
        <FormControl required className="input-base-control form-control">
          <OutlinedInputWithLabel
            id="email-auth-login"
            label="Email"
            value={email}
            type="email"
            onChange={handleEmailChange}
            placeholder="example@domain.com"
          />
        </FormControl>

        <FormControl required className="input-base-control form-control">
          <OutlinedInputWithLabel
            id="email-auth-password"
            label="Password"
            value={password}
            type="password"
            onChange={handlePasswordChange}
          />

          <FormHelperText
            className="password-helper-text"
            component="div"
          >
            <PasswordPolicy />
          </FormHelperText>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
        >
          Cancel
        </Button>

        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={isInputInalid}
        >
          Connect
        </Button>
      </DialogActions>
    </LinkEmailProviderModalContainer>
  );
}

LinkEmailProviderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExit: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

export default LinkEmailProviderModal;
