import React from 'react';
import PT from 'prop-types';

import Typography from '@mui/material/Typography';

import TableCell from '../TableCell/TableCell';

const DefaultCell = ({ getValue }) => (
  <TableCell>
    <Typography
      variant="body2"
      noWrap
      sx={{ color: '#666666', fontSize: '14px' }}
    >
      {getValue()}
    </Typography>
  </TableCell>
);

DefaultCell.propTypes = {
  getValue: PT.func.isRequired
};

export default DefaultCell;
