import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Notification } from './styles';
import NotificationsBody from '../../../../components/Notifications/NotificationsBody';
import { generateLink } from '../../../../components/Notifications/utils';
import { useNavigate } from 'react-router-dom';

const SingleNotification = ({ notification }) => {

  const {
    user,
    eventType,
    timestamp,
    item,
    team
  } = notification;

  const navigate = useNavigate();


  const handleClick = useCallback(() => {
    if (notification.item?.archived)
      return;

    const linkTo = generateLink(eventType, notification);

    navigate(linkTo);
  }, [eventType, notification, navigate]);

  return (
    <Notification onClick={handleClick}>
      <NotificationsBody
        archived={item?.archived}
        type={eventType}
        userName={user.name}
        timestamp={timestamp}
        title={item?.title}
        teamTitle={team?.title}
        {...notification}
      />
    </Notification>
  );
};

SingleNotification.propTypes = {
  notification: PropTypes.object.isRequired
};

export default SingleNotification;
