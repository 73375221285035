import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

function KeepSignedIn({
  value,
  onChange
}) {
  const handleKeepSignedinChange = useCallback(ev => {
    onChange(ev.target.checked);
  }, [onChange]);

  return (
    <FormControl>
      <FormControlLabel
        control={
          <Switch
            checked={value}
            onChange={handleKeepSignedinChange}
            value="keep-sign-in"
            color="primary"
            aria-describedby="keep-signed-in-helper-text"
          />
        }

        label={
          <Typography variant="body2">
            Keep me signed in
          </Typography>
        }
      />

      <FormHelperText
        id="keep-signed-in-helper-text"
        className="switch-helper-text"
        sx={{ ml: 0 }}
      >
        This feature gives you access to MaterialsZone without the need to log in again using your username and password. Note: Using this feature is only recommended for private computers.
      </FormHelperText>
    </FormControl>
  );
}

KeepSignedIn.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default KeepSignedIn;
