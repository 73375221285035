import React, { useState, useCallback } from 'react';
import PT from 'prop-types';
import capitalize from 'lodash/capitalize';

import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';

import MenuItem from './MenuItem';
import { rolesIds } from '../../../../../utils/roles';

const permissions = [
  {
    primary: 'Read',
    secondary: 'View table',
    value: rolesIds.READ
  },
  {
    primary: 'Write',
    secondary: 'Edit and add values to table',
    value: rolesIds.WRITE
  },
  {
    primary: 'Admin',
    secondary: 'Edit table structure and manage members',
    value: rolesIds.ADMIN
  }
];

const EditField = ({
  value,
  onRoleChange
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleOpenMenu = useCallback(ev => {
    setMenuAnchorEl(ev.target);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const handleMenuItemClick = useCallback(role => async () => {
    setMenuAnchorEl(null);
    onRoleChange(role);
  }, [onRoleChange]);

  return (
    <Box>
      <ButtonBase
        disableRipple
        onClick={handleOpenMenu}
        title={value}
      >
        <Typography
          variant="body2"
          component="span"
          noWrap
        >
          {capitalize(value)}
        </Typography>

        <KeyboardArrowDownIcon sx={{ fontSize: '14px', mx: '5px' }} />
      </ButtonBase>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'saved-views-button',
          dense: true
        }}
      >
        {permissions.map(i =>
          <MenuItem
            key={i.value}
            onClick={handleMenuItemClick(i.value)}
            sx={{ px: '10px' }}
          >
            <Box sx={{ width: '14px', mr: '6px' }}>
              {i.value === value &&
                <CheckIcon sx={{ fontSize: '14px', alignSelf: 'flex-start' }} />
              }
            </Box>

            <ListItemText
              primary={i.primary}
              secondary={i.secondary}
              primaryTypographyProps={{ sx: { fontWeight: 500 } }}
            />
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

EditField.propTypes = {
  onRoleChange: PT.func.isRequired,
  value: PT.oneOf(
    permissions.map(i => i.value)
  ).isRequired,
};

export default EditField;
