import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import TablesDialog from './TablesDialog';
import UnitsBlock from './UnitsBlock';
import TablesList from './TablesList';

import { UNITS } from './gql';

const Content = ({ selectedTables, selectedUnit, onTablesChange, onUnitChange, onTableRemove, tablesError }) => {
  const { data: unitsData } = useQuery(UNITS);

  const [open, setOpen] = useState(false);

  const handleToggleTableDialog = useCallback(() => {
    setOpen(s => !s);
  }, []);

  const handleAddTables = useCallback((tables) => {
    onTablesChange(tables);
    setOpen(false);
  }, [onTablesChange]);

  const handleAddUnits = useCallback((data) => {
    onUnitChange(data?.unit ?? null);
  }, [onUnitChange]);

  const initialTables = useMemo(() => selectedTables.map(table => table.id), [selectedTables]);

  const unitsOptions = useMemo(() => {
    return [{ name: 'None', id: null }, ...(unitsData?.units || [])];
  }, [unitsData?.units]);

  return (
    <Box>
      <TablesList
        onToggle={handleToggleTableDialog}
        onTableRemove={onTableRemove}
        selectedTables={selectedTables}
        tablesError={tablesError}
      />

      <UnitsBlock
        options={unitsOptions}
        onChange={handleAddUnits}
        value={selectedUnit}
      />

      {open ?
        <TablesDialog
          onCancel={handleToggleTableDialog}
          onSubmit={handleAddTables}
          initialValue={initialTables}
        />
        : null
      }
    </Box>
  );
};

Content.propTypes = {
  selectedTables: PropTypes.array.isRequired,
  selectedUnit: PropTypes.object,
  onTablesChange: PropTypes.func.isRequired,
  onUnitChange: PropTypes.func.isRequired,
  onTableRemove: PropTypes.func.isRequired,
  tablesError: PropTypes.string
};

export default Content;
