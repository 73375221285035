import { useCallback } from 'react';
import { useAnalytics } from 'use-analytics';
import {
  componentNames,
  TRACK_CREATION_DATE_FILTER,
  TRACK_OWNER_FILTER, TRACK_SLICE_BY_FILTER,
  TRACK_TEAM_FILTER
} from '../../../../analytics/constants';

const FILTER_TYPES = {
  TABLE_IDS: 'tableIds',
  CREATOR_IDS: 'creatorIds',
  CREATED_TIMESTAMP: 'createdTimestamp',
  FEATURE_SLICE_BY_LIST: 'featureSliceByList',
};

const useTrack = () => {

  const { track } = useAnalytics();

  return useCallback((type, filterData, data) => {

    switch (type) {
      case FILTER_TYPES.TABLE_IDS:
        return track(TRACK_TEAM_FILTER.update, {
          component: componentNames.DATASET_SELECTION,
          additional_info: {
            ai_num_teams_selected: filterData?.length || null,
            ai_team_names: filterData?.length ?
              data.tables.filter(team => filterData.includes(team.id))
                .map((team) => team.title) : null,
          }
        });
      case FILTER_TYPES.CREATOR_IDS:
        return track(TRACK_OWNER_FILTER.update, {
          component: componentNames.DATASET_SELECTION,
          additional_info: {
            ai_owners_selected: filterData?.length ?
              data.creators.filter(user => filterData.includes(user.userId))
                .map((user) => user.userName) : null,
          }
        });
      case FILTER_TYPES.CREATED_TIMESTAMP:
        return track(TRACK_CREATION_DATE_FILTER.update, {
          component: componentNames.DATASET_SELECTION,
          additional_info: {
            ai_creation_date_from: filterData?.from,
            ai_creation_date_to: filterData?.to,
          }
        });
      case FILTER_TYPES.FEATURE_SLICE_BY_LIST:
        return track(TRACK_SLICE_BY_FILTER.update, {
          component: componentNames.DATASET_SELECTION,
          additional_info: {
            ai_num_parameters: filterData.featureSliceBys.length,
            ai_num_exclude: filterData.featureSliceBys.filter(parameter => !parameter.include).length,
          }
        });
      default:
        return;
    }
  }, [track]);
};

export default useTrack;
