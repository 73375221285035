import React, { useState, useCallback } from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import { COLOR_PRIMARY } from '../../../../styles';

const DescriptionInput = ({
  description,
  onSubmit,
  onCancel
}) => {
  const [value, setValue] = useState(description);

  const handleKeyDown = useCallback(ev => {
    const { shiftKey, key } = ev;

    if (key === 'Enter' && !shiftKey) {
      onSubmit(value);
    } else if (key === 'Escape') {
      onCancel();
    }
  }, [value, onSubmit, onCancel]);

  const handleChange = useCallback(ev => {
    setValue(ev.target.value);
  }, []);

  const handleBlur = useCallback(() => {
    onSubmit(value);
  }, [value, onSubmit]);

  const handleCancel = useCallback(ev => {
    // prevent the input losing the focus
    // intersepting the 'mousedown' event
    // on the 'Cancel' button
    ev.preventDefault();
    onCancel();
  }, [onCancel]);

  return (
    <Box
      display="flex"
      alignItems="center"
      gap="8px"
      sx={{
        maxWidth: '680px',
        minWidth: '50%'
      }}
    >
      <InputBase
        sx={{
          border: '1px solid rgba(0, 0, 0, 0.23)',
          borderRadius: '4px',
          padding: '0 8px',
          width: '100%',
          height: '28px',
          fontSize: '14px',

          '.MuiInputBase-input': {
            padding: 0,
            lineHeight: '26px'
          }
        }}
        value={value}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <IconButton
        sx={{
          '&.MuiIconButton-root': {
            width: '28px',
            height: '28px',
            borderRadius: '4px',
            backgroundColor: COLOR_PRIMARY,
            padding: 0,

            '.MuiSvgIcon-root': {
              color: 'white',
              fontSize: '18px'
            }
          }
        }}
      >
        <CheckIcon />
      </IconButton>

      <IconButton
        onMouseDown={handleCancel}
        sx={{
          '&.MuiIconButton-root': {
            width: '28px',
            height: '28px',
            borderRadius: '4px',
            padding: 0,

            '.MuiSvgIcon-root': {
              fontSize: '18px'
            }
          }
        }}
      >
        <ClearIcon />
      </IconButton>
    </Box>
  );
};

DescriptionInput.propTypes = {
  description: PT.string.isRequired,
  onSubmit: PT.func.isRequired,
  onCancel: PT.func.isRequired
};

export default DescriptionInput;
