import styled from 'styled-components';

import { grey } from '@mui/material/colors';

export const DatePickerInputStyled = styled.div`
  .date-select-button {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 8px;
    box-sizing: border-box;
    border: 1px solid #B9B9B9;
    border-radius: 2px;
    cursor: pointer;
    width: 160px;
    font-size: 14px;
  }
  
  .date-select-button.focused {
    border: 2px solid #10B3CC;
  }
  
  .date-select-button > svg {
    width: 16px;
    height: 16px;
  }
  
  .date-select-label {
    display: inline-block;
    margin-bottom: 4px;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const DatePickerStyled = styled.div`
  flex-direction: row;

  .custom-overlay {
    z-index: 3;
    margin-top: 8px;
    position: fixed;
    right: ${({ label }) => label === 'From' ? '40px' : '45px'};
    width: 260px;
    
    .rdp {
      --rdp-cell-size: 32px;
    }
    
    .rdp-month {
      max-width: 240px;
      margin: 0 auto;
    }
    
    .rdp-caption_label {
      color: ${grey[500]};
      font-size: 1em;
    }
    
    .rdp-button svg {
      width: 13px;
      height: 13px;
    }
    
    .rdp-head_cell {
      color: ${grey[500]};
      font-size: 0.8em;
    }

    .rdp-cell {
      width: 10px;
      height: 10px;
    }
    
    .rdp-day_outside {
      color: ${grey[400]};
    }
  }
  
  .datepicker-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;
  }
  
  .footer-btn {
    border: none;
    padding: 4px;
    color: rgba(0, 0, 0, 0.6);
    text-transform: capitalize;
  }
`;
