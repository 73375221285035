import React, { memo } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import RemoveIcon from '@mui/icons-material/Remove';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import withStyles from '@mui/styles/withStyles';

const styles = {
  actions: {
    position: 'fixed',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(0,0,0,.75)',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    height: '44px',
    fontSize: '13px'
  },
  numbers: {
    padding: '0 16px',
    borderRight: '1px solid #fff'
  },
  actionButtons: {
    padding: 0,
    height: '100%',
    display: 'flex',
  },
  actionButton: {
    padding: '8px',
    margin: 0,
    height: '100%',
    width: '44px',
    border: 'none',
    background: 'transparent',
    color: '#fff',
    cursor: 'pointer',
    '&[disabled]': {
      opacity: 0.6,
      background: 'transparent',
    },
    '&:hover:not([disabled])': {
      backgroundColor: 'rgba(255, 255, 255, 0.25)'
    },
    '& > svg': {
      verticalAlign: 'middle'
    }
  }
};

function DialogFooter({ pageNumber, numPages, scaleDown, fitToScale, scaleUp, scale, minScale, maxScale, classes }) {
  const isScaleDownDisabled = scale <= minScale;
  const isScaleUpDisabled = scale >= maxScale;

  return (
    <div className={classes.actions}>
      <span className={classes.numbers}>
        Page {pageNumber} / {numPages}
      </span>

      <div className={classes.actionButtons}>
        {!isScaleDownDisabled &&
        <Tooltip title="Zoom out">
          <button className={classes.actionButton} onClick={scaleDown} data-testid="zoom-out">
            <RemoveIcon />
          </button>
        </Tooltip>
        }

        {isScaleDownDisabled &&
        <button className={classes.actionButton} onClick={scaleDown} disabled>
          <RemoveIcon />
        </button>
        }

        <Tooltip title={scale > minScale ? 'Fit to Page' : 'Fit to width'}>
          <button className={classes.actionButton} onClick={fitToScale} data-testid="fit-to-page">
            {scale > minScale && <ZoomOutIcon />}
            {scale <= minScale && <ZoomInIcon />}
          </button>
        </Tooltip>

        {!isScaleUpDisabled &&
        <Tooltip title="Zoom in">
          <button className={classes.actionButton} onClick={scaleUp} data-testid="zoom-in">
            <AddIcon />
          </button>
        </Tooltip>
        }

        {isScaleUpDisabled &&
        <button className={classes.actionButton} onClick={scaleUp} disabled>
          <AddIcon />
        </button>
        }
      </div>
    </div>
  );
}

DialogFooter.propTypes = {
  minScale: PropTypes.number,
  maxScale: PropTypes.number,
  scale: PropTypes.number,
  pageNumber: PropTypes.number,
  numPages: PropTypes.number,
  scaleDown: PropTypes.func,
  fitToScale: PropTypes.func,
  scaleUp: PropTypes.func,
  classes: PropTypes.object,
};

export default withStyles(styles)(
  memo(DialogFooter)
);
