import React from 'react';
import { useQuery } from '@apollo/client';

import { Outlet, Navigate } from 'react-router-dom';

import { routes } from '../../../services/session/constants';

import PEROVSKITE_VIEWER from './gql/perovskite-user';

const RegularUser = () => {
  const { data, loading } = useQuery(PEROVSKITE_VIEWER);

  if(loading)
    return null; // TODO: find a better intermediate screen state

  const perovskiteUser = data?.viewer?.perovskiteUser;

  if(perovskiteUser)
    return (
      <Navigate
        to={routes.HOME_PAGE}
        replace
      />
    );

  return (
    <Outlet />
  );
};

export default RegularUser;
