import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';

import AuthPageStyled from '../../components/AuthPageStyled';
import LogoMain from '../../components/Logos/LogoMain';
import AuthContainerStyled from '../../components/AuthPageStyled/AuthContainerStyled';
import RaisedInput from '../../components/RaisedInput';

import { cleanPassResetResponse, resetError, sendPasswordRecoveryEmail } from '../../services/session/actions';
import {
  getResetPasswordResponse
} from '../../services/session/selectors';
import useDocumentTitle from '../../hooks/document-title';

const ForgotPassword = () => {
  const dispatch = useDispatch();

  useDocumentTitle('Forgot Password');

  const resetPasswordResponse = useSelector(state => getResetPasswordResponse(state));

  const [email, setEmail] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(true);

  const showForm = useCallback(() => {
    setIsFormVisible(true);
    setEmail('');
  }, []);

  useEffect(() => {
    return () => {
      dispatch(cleanPassResetResponse());
      dispatch(resetError());
    };
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    dispatch(sendPasswordRecoveryEmail(email));
    if (email){
      setIsFormVisible(false);
    }
  }, [dispatch, email]);

  const handleEmailChange = useCallback(ev => {
    const { value } = ev.target;

    setEmail(value);
  }, []);

  const forgotPasswordForm = (
    <AuthContainerStyled>
      <RaisedInput
        type="email"
        placeholder="Email address"
        onChange={handleEmailChange}
        value={email}
      />

      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Send password recovery email
      </Button>
    </AuthContainerStyled>
  );

  const responseReceived = (
    <AuthContainerStyled>
      <p>{resetPasswordResponse || `Please check your email ${email}.`}</p>

      <Button
        fullWidth
        variant="contained"
        color="primary"
        component={Link}
        to="/signin"
      >
        Sign in with new password
      </Button>

      <p>Didn't receive the email?</p>

      <Button
        fullWidth
        variant="contained"
        onClick={showForm}
      >
        Try again
      </Button>

      <p>If the problem persists, please send an email to <a href="mailto:support@materials.zone">support@materials.zone</a>.</p>
    </AuthContainerStyled>
  );

  return (
    <AuthPageStyled>
      <div className="header">
        <LogoMain />
      </div>

      <div className="auth-forms-container">
        {isFormVisible ? forgotPasswordForm : responseReceived}
      </div>
    </AuthPageStyled>
  );
};

export default ForgotPassword;
