import React from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

const Table = ({ colgroups = [], children, className }) => (
  <table className={className}>
    {colgroups.map(({ id, colSpan }) => (
      <colgroup key={id} span={colSpan}></colgroup>
    ))}

    <tbody>
      {children}
    </tbody>
  </table>
);

Table.propTypes = {
  colgroups: PT.arrayOf(PT.shape({
    id: PT.string,
    colSpan: PT.number
  })),
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node
  ]),
  className: PT.string
};

export default styled(Table)`
  border-collapse: collapse;
  border-width: 0 1px 1px 0;
  border-color: #DCDBDC;
  border-style: solid;
  border-right: 0;

  flex: 1 0 auto;
  align-self: flex-start;

  tr.head {
    height: 1px;
  }

  tr.line {
    &:hover {
      td {
        background-color: #ECF9FB;
        background-clip: padding-box;
      }
    }
  }
`;
