import React from 'react';
import PT from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

const DeleteViewDialog = ({
  open,
  loading,
  title,
  onClose,
  onSubmit
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Delete view</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete "{title}"?
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button
          onClick={onClose}
          color="primary"
        >
          Cancel
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={onSubmit}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DeleteViewDialog.propTypes = {
  open: PT.bool,
  loading: PT.bool,
  title: PT.string,
  onClose: PT.func.isRequired,
  onSubmit: PT.func.isRequired
};

export default DeleteViewDialog;
