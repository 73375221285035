import {
  CLOSE_APP_SNACKBAR_NOTIFICATION,
  DELETE_APP_SNACKBAR_NOTIFICATION,
  OPEN_APP_SNACKBAR_NOTIFICATION
} from './constants';

import { action } from '../../actions';

export const closeAppSnackbarNotification = id =>
  action(CLOSE_APP_SNACKBAR_NOTIFICATION, { id });
export const deleteAppSnackbarNotification = id =>
  action(DELETE_APP_SNACKBAR_NOTIFICATION, { id });
export const openAppSnackbarNotification = ({ message, variant, id }) =>
  action(OPEN_APP_SNACKBAR_NOTIFICATION, { message, variant, id });
