import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import PropTypes from 'prop-types';

import useMediaQuery from '@mui/material/useMediaQuery';
import QRCodeDialog from '../../../QRCodeDialog';
import CloneItemDialog from '../../../Sample/CloneItemDialog';
import DeleteItemSubmitDialog from '../../../DeleteItemSubmitDialog';

import { isRoleEqualOrHigher, rolesIds } from '../../../../utils/roles';
import {
  openAppSnackbarNotification
} from '../../../../services/snackbar-notifications/actions';

import { RESTRICT_LINKED, RESTRICT_PERMISSION, routes } from '../../../../services/session/constants';
import { DELETE_ITEM } from './services';
import FullScreenItemActions from './ItemActions/FullscreenItemActions';
import { DIALOG_BREAKPOINT } from '../../../../styles';
import ShortItemActions from './ItemActions/ShortItemActions';
import { GET_TABLES } from '../../../../scenes/AllItems/services';

const ItemActions = ({
  readOnly,
  fullscreen,
  data,
  onRemove
}) => {

  const [deleteItem] = useMutation(DELETE_ITEM, {
    refetchQueries: [GET_TABLES]
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [isCopied, setIsCopied] = useState(false);

  const [openQRCodeDialog, setOpenQRCodeDialog] = useState(false);
  const [openCloningDialog, setOpenCloneDialog] = useState(false);
  const [openDeletingDialog, setOpenDeleteDialog] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    let timerId;
    if (isCopied){
      timerId = setTimeout(() => setIsCopied(false), 1e3);
    }

    return () => clearTimeout(timerId);
  }, [isCopied]);

  const handleOpen = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleCopyUrl = useCallback(() => {
    handleClose();
    setIsCopied(true);
  }, [handleClose]);

  const handleViewQr = useCallback(() => {
    handleClose();
    setOpenQRCodeDialog(true);
  }, [handleClose]);

  const handleClone = useCallback(() => {
    handleClose();
    setOpenCloneDialog(true);
  }, [handleClose]);

  const handleDelete = useCallback(() => {
    handleClose();
    setOpenDeleteDialog(true);
  }, [handleClose]);

  const handleQRCodeDialogClose = useCallback(() => {
    setOpenQRCodeDialog(!openQRCodeDialog);
  }, [openQRCodeDialog]);

  const handleCloneDialogClose = useCallback(() => {
    setOpenCloneDialog(!openCloningDialog);
  }, [openCloningDialog]);

  const handleDeletionClose = useCallback(() => {
    setOpenDeleteDialog(!openDeletingDialog);
  }, [openDeletingDialog]);

  const handleDeletionSubmit = useCallback(async () => {
    setOpenDeleteDialog(!openDeletingDialog);

    try {
      await deleteItem({
        variables: {
          tableItemIds: [data?.id]
        }
      });

      onRemove(data?.id, data?.table.id);
    } catch (e) {
      dispatch(
        openAppSnackbarNotification({
          message: 'Deleting failed.',
          variant: 'ERROR'
        })
      );
    }
  }, [data?.id, data?.table.id, deleteItem, dispatch, onRemove, openDeletingDialog]);

  const deleteForbidden = useMemo(() => {
    if(data?.isUsedInProtocols)
      return { reason: RESTRICT_LINKED };

    const userRole = data?.table.viewerMaxRole || rolesIds.READ;

    if(!isRoleEqualOrHigher(userRole, rolesIds.ADMIN))
      return { reason: RESTRICT_PERMISSION };
  }, [data]);

  const isEditable = useMemo(() => {
    return isRoleEqualOrHigher(data?.table.viewerMaxRole, rolesIds.WRITE);
  }, [data?.table.viewerMaxRole]);

  const itemUrlPath = generatePath(routes.ITEMS, { sampleId: data?.id || '1' });
  const itemLink = `${window.location.origin}${itemUrlPath}`;
  const isMobileView = useMediaQuery(`(${DIALOG_BREAKPOINT})`);

  return (
    <>
      {!fullscreen || isMobileView ?
        <ShortItemActions
          onOpen={handleOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          isCopied={isCopied}
          itemLink={itemLink}
          onCopyURL={handleCopyUrl}
          onViewQR={handleViewQr}
          onClone={handleClone}
          canEdit={isEditable}
          onDelete={handleDelete}
          readOnly={readOnly}
        />
        : (
          <FullScreenItemActions
            onCopy={handleCopyUrl}
            onViewQR={handleViewQr}
            onClone={handleClone}
            isCopied={isCopied}
            itemLink={itemLink}
            canEdit={isEditable}
            onDelete={handleDelete}
            readOnly={readOnly}
          />
        )
      }

      {
        openQRCodeDialog ?
          <QRCodeDialog
            open={openQRCodeDialog}
            itemId={data?.id}
            primaryTitle={data?.title}
            secondaryTitle={data?.code}
            onClose={handleQRCodeDialogClose}
            itemLink={itemLink}
          /> :
          null
      }

      {openCloningDialog &&
      <CloneItemDialog
        data={data}
        onClose={handleCloneDialogClose}
      />
      }

      <DeleteItemSubmitDialog
        open={openDeletingDialog}
        onClose={handleDeletionClose}
        onSubmit={handleDeletionSubmit}
        itemName={data?.title}
        forbidden={deleteForbidden}
      />
    </>
  );
};

ItemActions.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    owner: PropTypes.object,
    code: PropTypes.string,
    isUsedInProtocols: PropTypes.bool,
    table: PropTypes.object,
  }),
  fullscreen: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
};

export default ItemActions;
