import React, { useCallback } from 'react';
import PT from 'prop-types';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';

import EditableValueContainer from '../../EditableValueContainer';
import ViewEnumValue from './ViewEnumValue';
import EditEnumValue from './EditEnumValue';

import { UPDATE_FEATURE_VALUE } from '../gql';
import { openAppSnackbarNotification } from '../../../../../../services/snackbar-notifications/actions';

const EnumValue = ({ data, itemId, tableParameter, onUpdateValue, ...props }) => {
  const dispatch = useDispatch();
  const [updateFeatureValue] = useMutation(UPDATE_FEATURE_VALUE);

  const handleUpdate = useCallback(async (value) => {
    try {
      const response = await updateFeatureValue({
        variables: {
          input: {
            tableParameterId: tableParameter.id,
            tableItemId: itemId,
            enumValueIds: value?.map(({ id }) => id) ?? null
          }
        },
        optimisticResponse: {
          updateTableValue: {
            tableValue: {
              __typename: 'TableValue',
              type: tableParameter.valueType,
              quantity: null,
              boolean: null,
              linkedTableItem: null,
              calculationError: null,
              text: null,
              enumValues: value,
              featureId: tableParameter.featureId,
              tableItemId: itemId,
              tableParameterId: tableParameter.id
            },
            dependentTableValues: []
          }
        }
      });

      onUpdateValue(response.data.updateTableValue.tableValue);
    } catch(e) {
      console.error(e);
      dispatch(openAppSnackbarNotification({
        variant: 'ERROR',
        message: e.message
      }));
    }
  }, [itemId, tableParameter, updateFeatureValue, dispatch, onUpdateValue]);

  return (
    <EditableValueContainer
      {...props}
      tableParameter={tableParameter}
      defaultValue={data.enumValues ?? []}
      onUpdate={handleUpdate}
      viewComponent={ViewEnumValue}
      editComponent={EditEnumValue}
    />
  );
};

EnumValue.propTypes = {
  data: PT.shape({
    enumValues: PT.arrayOf(PT.shape({
      id: PT.string,
      title: PT.string
    }))
  }),
  tableParameter: PT.shape({
    id: PT.string,
    featureId: PT.string,
    valueType: PT.string
  }),
  itemId: PT.string,
  onUpdateValue: PT.func
};

export default EnumValue;
