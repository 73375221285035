import React from 'react';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import SingleFeature from '../SingleFeature';

const SingleProtocol = ({
  id,
  data,
  readOnly,
  onUpdateParameterValue
}) => {
  return (
    <Table
      sx={{
        tableLayout: 'fixed'
      }}
    >
      <TableBody
        sx={{
          maxWidth: '100%',
          '.MuiTableRow-root:last-child td': {
            borderBottom: 0
          },
        }}
      >
        {data.filteredFeatures?.map(feature => (
          <SingleFeature
            key={feature.id}
            itemId={id}
            data={feature}
            readOnly={readOnly || feature.valueType === 'CALCULATION'}
            onUpdateParameterValue={onUpdateParameterValue}
          />
        ))}
      </TableBody>
    </Table>
  );
};

SingleProtocol.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onUpdateParameterValue: PropTypes.func
};

export default SingleProtocol;

