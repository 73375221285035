import React from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import { generatePath } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';

import Tooltip from '@mui/material/Tooltip';

import { CommentAction } from './styles';

import { routes } from '../../services/session/constants';
import { generateHash } from '../../utils/hash-manager';
import { componentNames, TRACK_SINGLE_COMMENT } from '../../analytics/constants';

function CommentShare({
  sampleId,
  commentId,
}) {
  const [copied, setCopied] = React.useState(false);

  const { track } = useAnalytics();

  const handleShare = React.useCallback(() => {
    const { origin } = window.location;
    const urlWithId = generatePath(routes.ITEMS, { sampleId });
    const hash = `#${generateHash('comment', commentId)}`;

    copy(`${origin}${urlWithId}${hash}`, {
      onCopy: setCopied(true)
    });

    track(TRACK_SINGLE_COMMENT.share, {
      component: componentNames.COMMENTS
    });
  }, [sampleId, commentId, track]);

  const handleCloseTooltip = React.useCallback(() => {
    setCopied(false);
  }, []);

  const tooltipText = copied ?
    'Copied!' :
    'Copy the link to this comment';

  return (
    <Tooltip
      title={tooltipText}
      placement="top"
      onClose={handleCloseTooltip}
      disableFocusListener
    >
      <CommentAction onClick={handleShare}>
        Share
      </CommentAction>
    </Tooltip>
  );
}

CommentShare.propTypes = {
  sampleId: PropTypes.string.isRequired,
  commentId: PropTypes.string.isRequired,
};

export default CommentShare;
