import { gql } from '@apollo/client';

export const GET_FOLDERS = gql`
    query Folders {
      folders {
        id
        title
        viewerMaxRole
      }
    }
`;

export const CREATE_FOLDER = gql`
mutation CreateFolder($data: FolderInput!) {
  createFolder(data: $data) {
    id
    title
    parentId
    childFolders {
      id
      title
      parentId
      description
      dateCreated
      dateUpdated
      viewerRole
      viewerMaxRole
    }
    childTables {
      id
    }
    iam {
      users {
        userId
      }
    }
    dateCreated
    dateUpdated
    viewerRole
    viewerMaxRole
    path {
      id
      title
    }
  }
}
`;
