import { gql } from '@apollo/client';

const CREATE_FORMULATION_PROTOCOL = gql`
  mutation CreateFormulationTableProtocol($tableId: ID!, $data: CreateFormulationTableProtocolInput!) {
    createFormulationTableProtocol(tableId: $tableId, data: $data) {
      id
    }
  }
`;

export default CREATE_FORMULATION_PROTOCOL;
