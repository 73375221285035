import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import { CommentAction } from './styles';

function CommentDelete({
  onDelete
}) {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleDeleteClick = React.useCallback(() => {
    setDialogOpen(true);
  }, []);

  const handleDialogClose = React.useCallback(() => {
    setDialogOpen(false);
  }, []);

  const handleDelete = React.useCallback(() => {
    onDelete();
    setDialogOpen(false);
  }, [onDelete]);

  return (
    <React.Fragment>
      <CommentAction onClick={handleDeleteClick}>
        Delete
      </CommentAction>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete comment
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm you want to delete the comment. This action cannot be reverted.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>
            Cancel
          </Button>

          <Button onClick={handleDelete} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

CommentDelete.propTypes = {
  onDelete: PropTypes.func.isRequired
};

export default CommentDelete;
