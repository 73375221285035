import styled, { css } from 'styled-components';

import { COLOR_GREY, COLOR_GREY_LIGHTER } from '../../../../../styles';

const unreadMixin = css`
  border: 1px solid #E0E0E0;
  background-color: #FAFAFA;
`;

export default styled.div.attrs({ className: 'notifications-item' })`
  position: relative;
  border: 1px solid transparent;
  border-radius: 3px;
  margin: 0 -15px;
  display: flex;
  cursor: pointer;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0px;
  }

  ${({ unread }) => unread && unreadMixin}

  &:hover {
    background-color: #FAFAFA;

    .notification-actions {
      .clear-action {
        opacity: 1;
      }
    }
  }

  a.notification-link {
    display: flex;
    text-decoration: none;
    color: inherit;
  }

  .notification-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 14px;
    position: absolute;
    top: 0;
    right: 0;

    .extras-item {
      display: flex;
      margin-left: 5px;
      cursor: pointer;
      border-radius: 50%;
      width: 16px;
      height: 16px;
    }

    .clear-action {
      opacity: 0;
      background-color: ${COLOR_GREY};

      .clear-icon {
        margin: auto;
        color: white;
        font-size: 14px;
      }
    }

    .read-mark {
      display: flex;
      border: 2px solid #B1E0E7;

      .read-mark-content {
        display: none;
        margin: auto;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background-color: #00BCD4;
      }

      &.unread {
        background-color: #B1E0E7;

        .read-mark-content {
          display: block;
        }
      }
    }

    &.disabled {
      .read-mark {
        border: 2px solid ${COLOR_GREY_LIGHTER};

        .read-mark-content {
          background-color: ${COLOR_GREY_LIGHTER};
        }

        &.unread {
          background-color: ${COLOR_GREY_LIGHTER};
        }
      }
    }
  }

  .notification-body {
    display: flex;
    padding: 10px 14px 5px;
    width: calc(100% - 62px);

    .avatar {
      margin-right: 10px;
    }

    .id-label {
      display: inline-block;
    }
  }
`;
