import React from 'react';
import PT from 'prop-types';

import LinkValue from './LinkValue';

const LinkValueDataWrapper = ({ data }) => {
  return (
    <LinkValue value={data?.link} />
  );
};

LinkValueDataWrapper.propTypes = {
  data: PT.shape({
    link: PT.shape({
      id: PT.string,
      title: PT.string,
      code: PT.string,
      viewerMaxRole: PT.string
    })
  })
};

export default LinkValueDataWrapper;

