import React from 'react';
import { gql, useQuery } from '@apollo/client';

import Actions from './Actions';
import SavedViews from './SavedViews';
import ActiveTables from './ActiveTables';
import ActiveMembers from './ActiveMembers';
import Notifications from './Notifications';
import Page, { PageContent } from '../../../components/Page';

import { Content, Title, Wrapper } from './styles';

const VIEWER_INFO = gql`
  query UserInfoPopoverViewerInfo {
    viewer {
      id
      firstName
    }
  }
`;

const RegularUserLanding = () => {
  const { data } = useQuery(VIEWER_INFO);

  const pageTitle = `Welcome ${data?.viewer.firstName || 'user'}, what do you want to do?`;

  return (
    <Page>
      <PageContent>
        <Wrapper>
          <Title>
            {pageTitle}
          </Title>

          <Actions />

          <SavedViews />

          <Content>
            <ActiveTables />

            <ActiveMembers />

            <Notifications />
          </Content>
        </Wrapper>
      </PageContent>
    </Page>
  );
};

export default RegularUserLanding;
