import React from 'react';
import PropTypes from 'prop-types';

import { MARK_BOLD, MARK_CODE, MARK_ITALIC, MARK_UNDERLINE } from './constants';

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf[MARK_BOLD]) {
    children = <strong>{children}</strong>;
  }

  if (leaf[MARK_CODE]) {
    children = <code>{children}</code>;
  }

  if (leaf[MARK_ITALIC]) {
    children = <em>{children}</em>;
  }

  if (leaf[MARK_UNDERLINE]) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

Leaf.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.any.isRequired,
  leaf: PropTypes.object.isRequired
};

export default Leaf;
