import styled from 'styled-components';

const IdleSignOutContainer = styled.div`
  display: flex;
  align-items: center;

  .control-suffix-text {
    margin-right: 16px;
  }
`;

export default IdleSignOutContainer;
