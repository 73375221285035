export const PARAMETER_METHODS = {
  INCLUDE: 'include',
  EXCLUDE: 'exclude',
};

export const PARAMETER_VALUE_TYPES = {
  QUANTITY: 'QUANTITY',
  TEXT: 'TEXT',
  BOOLEAN: 'BOOLEAN',
  LINK: 'LINK',
  CALCULATION: 'CALCULATION',
  ENUM: 'ENUM',
};
