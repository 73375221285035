import React from 'react';
import PropTypes from 'prop-types';

import { VALUE_TYPE } from '../../../../../../services/samples/constants';

import QuantityValueDataWrapper from './QuantityValueDataWrapper';
import LinkValueDataWrapper from './LinkValueDataWrapper';
import TextValue from './TextValue';
import CalculatedValue from './CalculatedValue';
import EnumValue from './EnumValue';

const booleanOptions = [
  { value: null, label: '' },
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

const FeatureValue = ({ data }) => {
  switch (data.valueType){
    case VALUE_TYPE.LINK: {
      return (
        <LinkValueDataWrapper
          data={data}
        />
      );
    }

    case VALUE_TYPE.QUANTITY: {
      return (
        <QuantityValueDataWrapper
          data={data}
        />
      );
    }

    case VALUE_TYPE.TEXT: {
      return (
        <TextValue
          text={data.text}
        />
      );
    }

    case VALUE_TYPE.BOOLEAN: {
      return booleanOptions.find(({ value }) => data.boolean === value).label;
    }

    case VALUE_TYPE.CALCULATION:
      return (
        <CalculatedValue
          data={data}
        />
      );

    case VALUE_TYPE.ENUM:
      return (
        <EnumValue
          value={data.enumValues}
        />
      );

    default:
      return null;
  }
};

FeatureValue.propTypes = {
  data: PropTypes.object.isRequired
};

export default FeatureValue;
