import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import Box from '@mui/material/Box';

import SearchableSelect from './SearchableSelect';

const getElementId = i => `items-list-select-${i}`;

const styles = {
  selectContainer: {
    width: '100%'
  }
};

const ParametersSelect = ({
  id,
  options,
  onSelect,
  classes,
  value,
  maxVisibleItemsCount = 9,
  placeholder,
  onInputChange,
  visibleAmount,
  loading,
}) => {

  const handleMenuClose = useCallback(searchingMode => {
    if (searchingMode) {
      onInputChange('');
    }
  }, [onInputChange]);

  const handleSelectClear = useCallback(() => {
    onInputChange(null);
  }, [onInputChange]);

  return (
    <Box
      className={classes.formControl}
      flexDirection="row"
      alignItems="flex-end"
      flexWrap="wrap"
      mt="1px"
    >
      <div className={classes.selectContainer}>
        <SearchableSelect
          id={getElementId(id)}
          placeholder={placeholder}
          inputProps={{ autoComplete: 'off' }}
          options={options}
          onSelect={onSelect}
          maxVisibleItemsCount={maxVisibleItemsCount}
          onInputChange={onInputChange}
          value={value}
          onMenuClose={handleMenuClose}
          onClear={handleSelectClear}
          visibleAmount={visibleAmount}
          isLoading={loading}
        />
      </div>
    </Box>
  );
};

ParametersSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      isGroup: PropTypes.bool,
      value: PropTypes.string
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  placeholder: PropTypes.string,
  labelText: PropTypes.string,
  maxVisibleItemsCount: PropTypes.number,
  visibleAmount: PropTypes.number,
  id: PropTypes.number.isRequired,
  loading: PropTypes.bool,
};

export default withStyles(styles)(ParametersSelect);

