import styled, { css } from 'styled-components';

import CheckboxComponent from '@mui/material/Checkbox';

import {
  COLOR_TEXT,
  COLOR_WHITE,
  MEDIA_QUERIES_XS,
  COLOR_PRIMARY,
  COLOR_GREY,
  COLOR_GREY_LIGHT,
  COLOR_PRIMARY_LIGHT,
} from '../../styles';

import { ACTIONS_PANEL_HEIGHT } from '../Sample/ActionsPanel';

const CHECKBOX_COLUMN_WIDTH = 30;
const QR_COLUMN_WIDTH = 55;
const DATA_COLUMN_WIDTH = 140;
const DATA_COLLECTION_COLUMN_WIDTH = 70;
const DATE_CREATED_COLUMN_WIDTH = 110;
const DATE_UPDATED_COLUMN_WIDTH = 110;
const INTERACTION_COLUMN_WIDTH = 93;
const OPTION_COLUMN_WIDTH = 40;
const TITLE_COLUMN_MIN_WIDTH = 150;

const ALL_FIXED_COLUMNS_WIDTH_SUM =
  CHECKBOX_COLUMN_WIDTH +
  DATA_COLUMN_WIDTH +
  DATA_COLLECTION_COLUMN_WIDTH +
  DATE_CREATED_COLUMN_WIDTH +
  DATE_UPDATED_COLUMN_WIDTH +
  INTERACTION_COLUMN_WIDTH +
  OPTION_COLUMN_WIDTH;

const columnWidths = {
  owner: DATA_COLUMN_WIDTH,
  'date-updated': DATE_UPDATED_COLUMN_WIDTH,
  'date-created': DATE_CREATED_COLUMN_WIDTH,
  interaction: INTERACTION_COLUMN_WIDTH,
  action: OPTION_COLUMN_WIDTH
};

export const TableContainer = styled.div.attrs({ className: 'table-container' })`
  position: relative;
  margin-bottom: 20px;

  @media (${MEDIA_QUERIES_XS}) {
    padding: 0 8px;
  }

  .table-cell-main,
  .table-head {
    min-width: ${TITLE_COLUMN_MIN_WIDTH + ALL_FIXED_COLUMNS_WIDTH_SUM}px;
  }

  .table-cell {
    min-height: 52px;
    justify-content: center;
    font-size: 14px;
    padding: 0;

    &.checkbox-cell {
      margin: 0;
      width: ${CHECKBOX_COLUMN_WIDTH}px;
    }

    &.sample-name {
      ${({ excludedColumns }) => excludedColumns
    ? css`
          width: calc(100% - ${ALL_FIXED_COLUMNS_WIDTH_SUM
          - excludedColumns.reduce((acc, i) => acc + columnWidths[i], 0)}px);
        `
    : css`
          width: calc(100% - ${ALL_FIXED_COLUMNS_WIDTH_SUM}px);
        `
}
      min-width: ${TITLE_COLUMN_MIN_WIDTH}px;
      justify-content: flex-start;
      padding-left: 10px;
      position: sticky;
      left: ${CHECKBOX_COLUMN_WIDTH}px;
      z-index: 1;
    }

    &.qr {
      width: ${QR_COLUMN_WIDTH}px;

      img {
        width: 100%;
        height: 100%;
      }
    }
      
    &.trashedTimestamp {
        width: ${DATA_COLUMN_WIDTH}px;
    }

    &.type {
        width: ${DATA_COLUMN_WIDTH}px;
    }

    &.path {
        width: ${DATA_COLUMN_WIDTH}px;
    }

    &.creator {
      width: ${DATA_COLUMN_WIDTH}px;

      .avatar {
        & > div {
          color: ${COLOR_TEXT} !important;
        }
      }
    }

    &.date-updated,
    &.date-created {
      width: ${DATE_UPDATED_COLUMN_WIDTH}px;
    }

    &.data-collection {
      width: ${DATA_COLLECTION_COLUMN_WIDTH}px;
    }

    &.interaction {
      width: ${INTERACTION_COLUMN_WIDTH}px;
    }

    &.action {
      width: ${OPTION_COLUMN_WIDTH}px;
      text-align: center;
      padding: 0;
      justify-content: center;
      position: sticky;
      right: 0;
      z-index: 1;

      .action-container {
         display: flex;

         button {
           width: 100%;
           min-width: 0;
           padding: 0;

          svg {
            margin: auto;
            background-color: transparent;
            color: ${COLOR_TEXT};
            width: 18px;
            height: 18px;
          }
         }
      }
    }
  }

  .table-head {
    svg {
      color: ${COLOR_WHITE};
    }

    &.date {
      padding: 0;
      text-align: center;
    }
  }

  .table-row {
    min-width: auto;

    .table-cell {
      background: #fff;

      &.sample-name {
        padding-left: 10px;
        justify-content: flex-start;
        position: sticky;
        left: ${CHECKBOX_COLUMN_WIDTH}px;
        z-index: 1;
      }

      &.date-updated,
      &.date-created {
        padding-left: 10px;
        justify-content: center;
      }

      &.label {
        flex-direction: column;
        padding: 2px 2px 0;

        .label-item {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 90%;
          color: ${COLOR_WHITE};
          border-radius: 3px;
          padding-left: 3px;
          margin-bottom: 3px;
        }
      }

      &.data-collection {
        flex-direction: column;
        padding: 2px 2px 0;

        .item {
          display: flex;
          width: 100%;
          margin-bottom: 2px;

          .label {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            font-size: 11px;
            color: white;
            border-radius: 50%;
            margin-right: 5px;
            text-align: center;
            background-color: ${COLOR_PRIMARY};
          }
        }
      }

      &.interaction {
        flex-direction: column;
        align-items: flex-end;
        padding-right: 10px;

        & > * {
          display: flex;
          align-items: center;
        }

        .value {
          color: ${COLOR_GREY};
        }

        svg {
          font-size: 18px;
          margin-left: 6px;
        }
      }
    }
  }
`;

export const TableRowStyled = styled.div.attrs({ className: 'table-row' })`
  display: flex;
  flex-direction: column;
  min-width: 550px;
  overflow-wrap: break-word;
  word-break: break-word;
  position: relative;

  .table-cell-disable-banner {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    background: white;
    opacity: 0.6;
  }

  .table-cell-main {
    display: flex;
    overflow-wrap: break-word;
    word-break: break-word;

    .clickable {
      cursor: pointer;
    }

    .checkbox-cell {
      .indeterminate {
        color: ${COLOR_PRIMARY};
      }
    }
  }
`;

export const TableCell = styled.div.attrs({ className: 'table-cell' })`
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-left: ${props => props.mobile
    ? `1px solid ${COLOR_GREY_LIGHT}`
    : `1px solid ${COLOR_WHITE}`
};

  flex-shrink: 0;
  box-sizing: border-box;

  &:nth-last-child(2) {
    border-right: none;
  }

  &:first-child,
  &:last-child {
    border-left: ${props => props.mobile
    ? `1px solid ${COLOR_GREY_LIGHT}`
    : `1px solid ${COLOR_WHITE}`
};
  }

  &.checkbox-cell {
    width: ${CHECKBOX_COLUMN_WIDTH}px;
    background-color: ${COLOR_WHITE};
    margin: auto;
    position: sticky;
    left: 0;
    padding: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
  }

  &.action {
    text-align: center;
    padding: 0;
    justify-content: center;

    .action-container {
       display: flex;

       button {
         width: 100%;
         min-width: 0;
         padding: 0;

        svg {
          margin: auto;
          background-color: transparent;
          color: ${COLOR_TEXT};
          width: 18px;
          height: 18px;
        }
       }
    }
  }
`;

export const HeaderWrapper = styled.div`
  position: -webkit-sticky;
  position: sticky;
  overflow-x: auto;
  top: ${ACTIONS_PANEL_HEIGHT}px;
  z-index: 3;

  @media (${MEDIA_QUERIES_XS}) {
    top: 52px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const BodyWrapper = styled.div`
  overflow-x: auto;
`;

export const TableHead = styled.div.attrs({ className: 'table-head' })`
  display: flex;
  min-width: 550px;
  overflow-wrap: break-word;
  word-break: break-word;

  .table-cell {
    background-color: ${COLOR_PRIMARY};
    color: ${COLOR_WHITE};
    font-size: 14px;
    font-weight: 500;
    border-left: ${props => props.mobile
    ? `1px solid ${COLOR_GREY_LIGHT}`
    : `1px solid ${COLOR_PRIMARY}`
};
    border-bottom: 1px solid ${COLOR_GREY_LIGHT};

    &:nth-last-child(2) {
      border-right: none;
    }

    &:first-child,
    &:last-child {
      border-left: ${props => props.mobile
    ? `1px solid ${COLOR_GREY_LIGHT}`
    : `1px solid ${COLOR_PRIMARY}`
};
    }

    &.sortable:hover {
      background-color: ${COLOR_PRIMARY_LIGHT};
      cursor: pointer;
    }

    &.checkbox-cell {
      display: flex;
      justify-content: center;
      background-color: ${COLOR_PRIMARY};
    }

    .arrow-up,
    .arrow-down {
      width: 16px;
      height: 16px;
    }

    .sort-icon-container {
      display: flex;
      flex-direction: column;
      position: relative;
      height: 26px;
      width: 16px;

      svg {
        position: absolute;
        left: 0;

        &.arrow-up {
          top: 0;
        }

        &.arrow-down {
          bottom: 0;
        }
      }

    }
  }
`;

export const Checkbox = styled(CheckboxComponent)
  .attrs({ className: 'checkbox ' })`
    && {
      width: 100%;
    }
  `;
