import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import get from 'lodash/get';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import NoDataScreen from '../NoDataScreen';
import NotificationsList from './NotificationsList';

import { GET_NOTIFICATIONS } from './services';
import { NOTIFICATION_TYPES } from '../../../../components/Notifications';

const Notifications = () => {

  const { data } = useQuery(GET_NOTIFICATIONS, {
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  });

  const items = useMemo(() => get(data, 'notifications.edges', [])
    .filter(i => NOTIFICATION_TYPES.includes(i.node.eventType))
    .slice(0, 5).map(i => i.node),
  [data]);

  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <Typography sx={{ fontWeight: '600', fontSize: '18px', marginBottom: '16px' }}>
        Notifications
      </Typography>

      {items.length ? <Divider /> : null}

      <Box sx={{ overflow: 'auto', display: 'flex', flex: '1 0' }}>
        {items.length ?
          <NotificationsList items={items} />
          :
          <NoDataScreen
            title="No notifications at the moment"
            text={`You'll see the notifications here when your team members or colleagues start working on things`}
          />
        }
      </Box>
    </Box>
  );
};

export default Notifications;
