import React, { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useAnalytics } from 'use-analytics';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';

import AffiliationInfoContainer from './styles';

import { CHANGE_PROFILE_DETAILS } from './gql';
import { componentNames, TRACK_PROFILE_SETTINGS } from '../../../analytics/constants';

const useStyles = makeStyles({
  inputLabel: {
    transform: 'translate(0px, -1px) scale(0.75)'
  }
});

const AffiliationInfo = ({
  userId,
  info: initialInfo,
  onSave
}) => {
  const [info, setInfo] = useState(initialInfo);

  const { track } = useAnalytics();

  const classes = useStyles();

  const handleInfoChange = useCallback(ev => {
    const { name, value } = ev.target;

    setInfo({
      ...info,
      [name]: value
    });
  }, [info]);

  const handleDismissChanges = useCallback(() => {
    setInfo(initialInfo);
  }, [initialInfo]);

  const [changeProfileDetails, { loading }] = useMutation(CHANGE_PROFILE_DETAILS, {
    onCompleted: () => {
      onSave();
    }
  });

  const handleSaveChanges = useCallback(() => {
    changeProfileDetails({
      variables: {
        input: {
          institution: info.institution,
          researchGroup: info.researchGroup,
          principal: info.principal || '',
          url: info.url || '',
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateProfile: {
          __typename: 'Viewer',
          id: userId,
          institution: info.institution,
          researchGroup: info.researchGroup,
          principal: info.principal,
          url: info.url,
        }
      }
    });

    track(TRACK_PROFILE_SETTINGS.change, {
      component: componentNames.AFFILIATION_INFO
    });
  }, [userId, info, changeProfileDetails, track]);
  const institution = info.institution || '';
  const researchGroup = info.researchGroup || '';

  const hasChanges = initialInfo.institution !== institution
    || initialInfo.researchGroup !== researchGroup
    || initialInfo.principal !== info.principal
    || initialInfo.url !== info.url;

  const isSaveBtnDisabled = !hasChanges || !(institution.trim() && researchGroup.trim()) || loading;

  return (
    <AffiliationInfoContainer>
      <FormControl required className="input-base-control form-control">
        <InputLabel
          shrink
          htmlFor="profile-institution-input"
          className={classes.inputLabel}
        >
          Institution or Organization
        </InputLabel>

        <InputBase
          id="profile-institution-input"
          name="institution"
          value={info.institution ?? ''}
          onChange={handleInfoChange}
        />
      </FormControl>

      <FormControl required className="input-base-control form-control">
        <InputLabel
          shrink
          htmlFor="profile-research-group-input"
          className={classes.inputLabel}
        >
          Research Group Name
        </InputLabel>

        <InputBase
          id="profile-research-group-input"
          name="researchGroup"
          value={info.researchGroup ?? ''}
          onChange={handleInfoChange}
        />
      </FormControl>

      <FormControl className="input-base-control form-control">
        <InputLabel
          shrink
          htmlFor="profile-principal-input"
          className={classes.inputLabel}
        >
          Name of Principal Investigator (PI)
        </InputLabel>

        <InputBase
          id="profile-principal-input"
          name="principal"
          value={info.principal ?? ''}
          onChange={handleInfoChange}
        />
      </FormControl>

      <FormControl className="input-base-control form-control">
        <InputLabel
          shrink
          htmlFor="profile-url-input"
          className={classes.inputLabel}
        >
          Link to Research Page or Website
        </InputLabel>

        <InputBase
          id="profile-url-input"
          type="url"
          name="url"
          pattern="https://.*"
          value={info.url ?? ''}
          onChange={handleInfoChange}
        />
      </FormControl>

      <div className="section-actions">
        <Button
          onClick={handleDismissChanges}
          color="inherit"
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={isSaveBtnDisabled}
          onClick={handleSaveChanges}
        >
          Save
        </Button>
      </div>
    </AffiliationInfoContainer>
  );
};

AffiliationInfo.propTypes = {
  userId: PropTypes.string.isRequired,
  info: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired
};

export default AffiliationInfo;
