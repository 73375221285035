const HASHES = new Map([
  ['comment', 'comment--:id'],
  ['itemObject', 'itemObject--:id']
]);

export const generateHash = (type, id) => {
  if (!HASHES.has(type)) {
    throw new Error('Invalid type');
  }

  return `${type}--${id}`;
};

export const includesHash = (value = '', { type, id }) =>
  value.includes(
    HASHES.get(type).replace(':id', id)
  );
