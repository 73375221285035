import styled from 'styled-components';

const Overlay = styled.div.attrs({ className: 'overlay' })`
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
`;

export default Overlay;
