import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
import withStyles from '@mui/styles/withStyles/withStyles';
import CloseIcon from '@mui/icons-material/Close';

import { COLOR_WHITE, COLOR_BLACK, COLOR_CHECKED } from '../../styles';

const styles = {
  snackbarContentRoot: {
    backgroundColor: COLOR_WHITE,
    color: COLOR_BLACK,
    flexWrap: 'nowrap'
  },
  snackbarMessage: {
    display: 'flex'
  },
  snackbarSuccessIconRoot: {
    fontSize: '20px',
    marginRight: '10px',
    color: COLOR_CHECKED
  }
};

const AppSnackbarContentSuccess = forwardRef(({
  id,
  message,
  classes,
  onClose
}, ref) => (
  <SnackbarContent
    ref={ref}
    classes={{ root: classes.snackbarContentRoot  }}
    aria-describedby={`app-snackbar-notification-${id}`}
    message={
      <div className={classes.snackbarMessage} id={`message-${id}`}>
        {message}
      </div>
    }
    action={[
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={onClose}
        size="large"
      >
        <CloseIcon />
      </IconButton>
    ]}
  />
));

AppSnackbarContentSuccess.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func
};

export default withStyles(styles)(AppSnackbarContentSuccess);
