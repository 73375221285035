import { gql } from '@apollo/client';

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      id
      pictureDownloadURL
      picture {
        downloadURL
        fullPath
      }
    }
  }
`;

export const CHANGE_PROFILE_AVATAR_COLOR = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      id
      avatarColor
    }
  }
`;
