export * from '../../constants';

export const UPDATE_GRAPHICS_CONFIG = 'UPDATE_GRAPHICS_CONFIG';
export const CLEAR_GRAPHICS_CONFIG = 'CLEAR_GRAPHICS_CONFIG';
export const SET_GRAPHICS_CONFIG = 'SET_GRAPHICS_CONFIG';

export const QUICK_CREATE_OPEN = 'QUICK_CREATE_OPEN';
export const QUICK_CREATE_CLOSE = 'QUICK_CREATE_CLOSE';

export const FETCH_OBJECTS_ORDER = 'FETCH_OBJECTS_ORDER';
export const SAVE_OBJECTS_ORDER = 'SAVE_OBJECTS_ORDER';
