import styled from 'styled-components';

import Page from '../../../components/Page';
import { COLOR_PRIMARY, COLOR_GREY_SUPER_LIGHT } from '../../../styles';

export default styled(Page)`
  .page-content {
    padding: 60px;
    line-height: 1.5em;

    .page-title {
      font-weight: 500;
    }

    .primary-color {
      color: ${COLOR_PRIMARY};
    }

    .grid-container {
      display: grid;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      background-color: #fff;
      color: #444;
      margin-top: 30px;

      & > p {
        font-size: 14px;
        line-height: 18px;
        background-color: ${COLOR_GREY_SUPER_LIGHT};
        border-radius: 10px;
        padding: 14px;
        margin: 0;
        min-height: 145px;
        flex-basis: 245px;

        & > a {
          color: ${COLOR_PRIMARY};
          font-size: 15px;
          display: block;
        }
      }
    }
  }
`;
