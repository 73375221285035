import { useCallback, useReducer } from 'react';
import { valueToText } from './utils/valueToText';

const createInitialState = (config) => ({
  description: config?.description || '',
  calculation: valueToText(config?.calculation) || [{
    type: 'paragraph',
    children: [{ text: '' }]
  }],
  unit: config?.unit || null
});

const actions = {
  SET_DESCRIPTION: 'SET_DESCRIPTION',
  SET_CALCULATION: 'SET_CALCULATION',
  SET_UNIT: 'SET_UNIT'
};

const reducer = (state, action) => {
  switch(action.type) {
    case actions.SET_DESCRIPTION:
      return {
        ...state,
        description: action.payload
      };
    case actions.SET_CALCULATION:
      return {
        ...state,
        calculation: action.payload
      };
    case actions.SET_UNIT:
      return {
        ...state,
        unit: action.payload
      };
  }
};

const useCalculationForm = (config) => {
  const [state, dispatch] = useReducer(reducer, config, createInitialState);

  const setDescription = useCallback(value => {
    dispatch({
      type: actions.SET_DESCRIPTION,
      payload: value.target.value
    });
  }, []);

  const setCalculation = useCallback(value => {
    dispatch({
      type: actions.SET_CALCULATION,
      payload: value
    });
  }, []);

  const setUnit = useCallback(value => {
    dispatch({
      type: actions.SET_UNIT,
      payload: value
    });
  }, []);

  return {
    description: state.description,
    calculation: state.calculation,
    unit: state.unit,
    setDescription,
    setCalculation,
    setUnit
  };
};

export default useCalculationForm;
