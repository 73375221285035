import React from 'react';
import PT from 'prop-types';
import { Typography, Box, TableCell } from '@mui/material';

const UserNameCell = ({ getValue }) => (
  <TableCell sx={{ p: '0' }}>
    <Box>
      <Typography sx={{ fontSize: '14px', color: '#666666' }}>
        {getValue()}
      </Typography>
    </Box>
  </TableCell>
);

UserNameCell.propTypes = {
  getValue: PT.func.isRequired
};

export default UserNameCell;
