import React, { useState, useCallback, useEffect } from 'react';
import PT from 'prop-types';
import { useAnalytics } from 'use-analytics';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import LinkIcon from '@mui/icons-material/Link';

import RecipientsSelect from './RecipientsSelect';
import TitleField from './TitleField';
import MessageField from './MessageField';

import { TRACK_SHARE_VIEW, componentNames } from '../../../../../analytics/constants';

const ShareViewDialog = ({
  open,
  onClose,
  onSubmit,
  shareURL,
  queryInput,
  initialTitle
}) => {
  const [recipients, setRecipients] = useState([]);
  const [title, setTitle] = useState(initialTitle);
  const [message, setMessage] = useState('');
  const [error, setError] = useState({ recipients: null, title: null });
  const [isCopied, setIsCopied] = useState(false);

  const { track } = useAnalytics();

  useEffect(() => {
    let timerId;

    if (isCopied){
      timerId = setTimeout(() => setIsCopied(false), 1e3);
    }

    return () => timerId && clearTimeout(timerId);
  }, [isCopied]);

  const handleRecipientsChange = useCallback(value => {
    if(error.recipients)
      setError(state => ({ ...state, recipients: null }));

    setRecipients(value);
  }, [error]);

  const handleTitleChange = useCallback((ev) => {
    if(error.title)
      setError(state => ({ ...state, title: null }));

    setTitle(ev.target.value);
  }, [error]);

  const handleMessageChange = useCallback((ev) => {
    setMessage(ev.target.value);
  }, []);

  const handleCopyURL = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(shareURL);

      track(TRACK_SHARE_VIEW.copyLink, {
        component: componentNames.SHARE_VIEW,
        additional_info: {
          ai_filter_state: JSON.stringify(queryInput),
          ai_copy_source: 'button'
        }
      });

      setIsCopied(true);
    } catch(e) {
      console.log(e);
    }
  }, [shareURL, track, queryInput]);

  const handleShare = useCallback(() => {
    if(!recipients.length) {
      setError(state => ({ ...state, recipients: 'Select at least one recipient.' }));
      return;
    }

    if(!title.trim().length) {
      setError(state => ({ ...state, title: 'Title field is required.' }));
      return;
    }

    onSubmit({ recipients, title, message });
  }, [recipients, title, message, onSubmit]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Share</DialogTitle>

      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px'
        }}
      >
        <RecipientsSelect
          value={recipients}
          onChange={handleRecipientsChange}
          error={error.recipients}
        />

        <TitleField
          value={title}
          onChange={handleTitleChange}
          error={error.title}
        />

        <MessageField
          value={message}
          onChange={handleMessageChange}
        />
      </DialogContent>

      <DialogActions sx={{ px: 3, py: 2 }}>
        <Tooltip open={isCopied} title="Copied" placement="top">
          <Button
            onClick={handleCopyURL}
            variant="text"
            startIcon={<LinkIcon />}
            sx={{
              mr: 'auto',
              color: '#8C8C8C',
              textTransform: 'none'
            }}
          >
            Copy link
          </Button>
        </Tooltip>

        <Button
          variant="contained"
          onClick={handleShare}
        >
          Share
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ShareViewDialog.propTypes = {
  open: PT.bool,
  onClose: PT.func,
  onSubmit: PT.func,
  shareURL: PT.string,
  queryInput: PT.object,
  initialTitle: PT.string
};

export default ShareViewDialog;
