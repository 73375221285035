import React, { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import PT from 'prop-types';
import styled from 'styled-components';

import { COL_MAX_WIDTH_PX } from '../../../../constants';
import EditLinkValue from './EditLinkValue';
import ViewLinkValue from './ViewLinkValue';
import EditableValueContainer from '../../EditableValueContainer';
import { UPDATE_FEATURE_VALUE } from '../gql';
import { openAppSnackbarNotification } from '../../../../../../services/snackbar-notifications/actions';

const LinkValue = ({ data, readOnly, itemId, tableParameter, onUpdateValue, ...props }) => {
  const { linkedTableItem = {} } = data;

  const dispatch = useDispatch();

  const [updateFeatureValue] = useMutation(UPDATE_FEATURE_VALUE);

  const handleUpdate = useCallback(async (value) => {
    const valuesInput = { linkedTableItemId: value?.value ?? null };
    const variablesInput = {
      tableParameterId: tableParameter.id,
      tableItemId: itemId,
      ...valuesInput
    };

    try {
      const response = await updateFeatureValue({
        variables: { input: variablesInput },
        optimisticResponse: {
          updateTableValue: {
            tableValue: {
              __typename: 'TableValue',
              type: tableParameter.valueType,
              boolean: null,
              text: null,
              quantity: null,
              enumValues: null,
              linkedTableItem: value
                ? {
                  __typename: 'TableItemBasic',
                  viewerMaxRole: null,
                  id: value?.value,
                  title: value.label,
                  code: value.code
                }
                : null,
              calculationError: null,
              featureId: tableParameter.featureId,
              ...variablesInput,
            },
            dependentTableValues: []
          }
        }
      });

      onUpdateValue(response.data.updateTableValue.tableValue);
    } catch (e) {
      dispatch(
        openAppSnackbarNotification({
          variant: 'ERROR',
          message: e.message
        })
      );
    }
  }, [tableParameter, itemId, updateFeatureValue, onUpdateValue, dispatch]);

  const { handleToggleSidebar } = props.table.customState;

  const viewComponentProps = useMemo(() => ({
    onLink: handleToggleSidebar,
    readOnly
  }), [handleToggleSidebar, readOnly]);

  return (
    <EditableValueContainer
      {...props}

      defaultValue={linkedTableItem}
      onUpdate={handleUpdate}
      viewComponent={ViewLinkValue}
      viewComponentProps={viewComponentProps}
      editComponent={EditLinkValue}
    />
  );
};

LinkValue.propTypes = {
  readOnly: PT.bool.isRequired,
  itemId: PT.string.isRequired,
  tableParameter: PT.shape({
    id: PT.string,
    featureId: PT.string,
    valueType: PT.string
  }),
  data: PT.shape({
    linkedTableItem: PT.shape({
      id: PT.string.isRequired
    }),
    type: PT.string
  }),
  table: PT.shape({
    customState: PT.shape({
      handleToggleSidebar: PT.func
    })
  }),
  onUpdateValue: PT.func
};

export default styled(LinkValue)`
  max-width: ${COL_MAX_WIDTH_PX}px;
`;
