import React, { memo } from 'react';
import { compose } from 'redux';

import CssBaseline from '@mui/material/CssBaseline';

import withApolloProvider from './apollo-provider.hoc';
import withMuiThemeProvider from './mui-theme-provider.hoc';
import withAnalyticsProvider from './analytics-provider.hoc';

import AppRouting from './AppRouting';
import LoadingBanner from '../../components/LoadingBanner';

import { App as AppStyled } from './styles';
import { GlobalStyle } from '../../styles';
import { RouterContent } from './styles';

import { useAuth } from '../../context/auth/auth';

const { REACT_APP_ENV } = process.env;

const App = () => {
  const { ready } = useAuth();

  if(!ready)
    return (
      <LoadingBanner />
    );

  return (
    <AppStyled className="app" fitScreen>
      <GlobalStyle />
      <CssBaseline />

      <RouterContent>
        <AppRouting />
      </RouterContent>

      {/* fake intercom widget for dev env */}
      {REACT_APP_ENV === 'development' ?
        <div
          style={{
            width: '48px',
            height: '48px',
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            background: '#00bcd4',
            borderRadius: '50%',
            zIndex: '9999'
          }}
        >
          &nbsp;
        </div> :
        null
      }
    </AppStyled>
  );
};

export default compose(
  memo, // in order to reflect to any state/context/props changes keep "memo" first
  withApolloProvider,
  withMuiThemeProvider,
  withAnalyticsProvider
)(App);
