import { useReducer, useCallback } from 'react';

const createInitialState = () => ({
  loading: false,
  data: null,
  error: null
});

const actions = {
  FETCH: 'FETCH',
  ON_DATA: 'ON_DATA',
  ON_ERROR: 'ON_ERROR',
  RESET: 'RESET'
};

const reducer = (state, action) => {
  switch(action.type) {
    case actions.FETCH:
      return {
        ...state,
        loading: true,
        data: null,
        error: null
      };

    case actions.ON_DATA:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null
      };

    case actions.ON_ERROR:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload.error
      };

    case actions.RESET:
      return createInitialState();

    default:
      return state;
  }
};

const useFetchProcessedData = () => {
  const [state, dispatch] = useReducer(reducer, null, createInitialState);

  const fetchData = useCallback(async (url, dataType, filename) => {
    dispatch({ type: actions.FETCH });

    try {
      const response = await fetch(url);
      let data;

      switch(dataType) {
        case 'DATA_FILE': {
          const blob = await response.blob();
          data = new File([blob], filename, {
            type: blob.type
          });
          break;
        }

        case 'DATA_JSON':
          data = await response.json();
          break;

        case 'DATA_COMPLEX': {
          const text = await response.text();
          data = JSON.parse(text);
          break;
        }

        case 'DATA_TEXT':
        default:
          data = await response.text();
      }

      dispatch({
        type: actions.ON_DATA,
        payload: { data }
      });

      return data;
    } catch(e) {
      dispatch({
        type: actions.ON_ERROR,
        payload: { error: 'Failed to fetch processed data.' }
      });
    }
  }, []);

  const reset = useCallback(() => {
    dispatch({ type: actions.RESET });
  }, []);

  return [
    fetchData,
    {
      ...state,
      reset
    }
  ];
};

export default useFetchProcessedData;
