import React from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Typography from '@mui/material/Typography';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import TableCell from '../TableCell/TableCell';

import { routes } from '../../../../services/session/constants';
import { COLOR_PRIMARY } from '../../../../styles';

const ItemsCell = ({ className, getValue, row }) => (
  <TableCell>
    {getValue() ?
      <Typography
        className={className}
        variant="body2"
        noWrap
        component={Link}
        to={`${routes.ALL_ITEMS}?filterTeam=${row.id}`}
        sx={{
          color: '#666666',
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none'
        }}
      >
        {getValue()}
        <OpenInNewIcon
          className="link-icon"
          sx={{
            fontSize: '14px',
            lineHeight: 'inherit',
            ml: '2px',
            display: 'none'
          }}
        />
      </Typography> :
      null
    }
  </TableCell>
);

ItemsCell.propTypes = {
  className: PT.string,
  getValue: PT.func.isRequired,
  row: PT.shape({
    id: PT.string
  })
};

export default styled(ItemsCell)`
  :where(tr:hover &) {
    color: ${COLOR_PRIMARY};
    text-decoration: underline;

    .link-icon {
      display: inline-block;
    }
  }
`;
