import styled from 'styled-components';

import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';

export const FormControlStyled = styled(FormControl)`
  border: 1px solid #D2D2D2;
  border-radius: 6px;
  height: 30px;
  padding: 0 8px;
  margin: 16px 0 ;
  top: 0;
  z-index: 1;
  box-sizing: border-box;
`;

export const InputStyled = styled(InputBase)`
  height: 30px;
  width: 100%;

  input {
    padding: 0;
  }

  svg {
    height: 16px;
    color: #888888;
  }
`;
