import styled from 'styled-components';

import ListItemIcon from '@mui/material/ListItemIcon';
import Box from '@mui/material/Box';
import { COLOR_PRIMARY_CONTRAST } from '../../../../../styles';

export const IconStyled = styled(ListItemIcon)`
  min-width: 0;
  padding-right: 4px;
`;

export const ItemWrapper = styled(Box)`
  display: flex;
  align-items: center;
  height: 28px;
  width: 100%;
  &:hover {
    background-color: ${COLOR_PRIMARY_CONTRAST};
  }
`;
