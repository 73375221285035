import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Link from '@mui/material/Link';

import RawText from '../RawText';
import EmptyValue from '../ParameterValue/EmptyValue';

const ItemLink = ({ className, onClick, text }) => {

  if (!text) {
    return (
      <EmptyValue />
    );
  }

  return (
    <RawText className={className}>
      <Link
        onClick={onClick}
        className="item-link"
      >
        {text}
      </Link>
    </RawText>
  );
};

ItemLink.propTypes = {
  className: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onClick: PropTypes.func.isRequired
};

export default styled(ItemLink)`
  .item-link:hover {
    cursor: pointer;
  }
`;
