import React from 'react';
import { useQuery } from '@apollo/client';
import { useLoaderData, useParams } from 'react-router-dom';

import useDocumentTitle from '../../hooks/document-title';

import ItemViewContainer from '../../components/ItemViewContainer';
import NoContent from '../NoContent';

import { GET_ITEM } from '../../components/ItemView/gql';

import { routes } from '../../services/session/constants';

const ItemView = () => {
  const { sampleId } = useParams();

  const { data, loading, error, refetch } = useQuery(GET_ITEM, {
    variables: {
      itemId: sampleId
    }
  });

  const { title } = useLoaderData();

  useDocumentTitle(title);

  if((!data && !loading) || error)
    return (
      <NoContent
        contentType="item"
        defaultPageName="Items"
        defaultPageURL={routes.ALL_ITEMS}
      />
    );

  return (
    <ItemViewContainer
      id={sampleId}
      data={data}
      refetch={refetch}
    />
  );
};

export default ItemView;
