import styled from 'styled-components';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import { COLOR_BLACK } from '../../../styles';

export const CounterWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Counter = styled('div')`
  min-width: 16px;
  height: 16px;
  padding: 0 4px;
  border-radius: 8px;
  background: #888;
  font-size: 12px;
  line-height: 16px;
  margin-left: 6px;
  box-sizing: border-box;
  color: white;
  text-align: center;
`;


export const ListButton = styled(ListItemButton)`
  display: flex;
  justify-content: space-between;
  height: 32px;
    
  .MuiListItemText-root {
    color: #666;
  }
  .MuiSvgIcon-root {
    color: #666;
  }
    
  :hover {
    background-color: initial;
    .MuiListItemText-root {
      color: ${COLOR_BLACK};
    }
    .MuiSvgIcon-root {
      color: ${COLOR_BLACK};
    }
  }
`;
