import React, { useCallback } from 'react';
import PT from 'prop-types';

import FormControl from '@mui/material/FormControl';

import UnitsSelect from '../../../../../../../../../../../components/UnitsSelect';
import InputLabel from '@mui/material/InputLabel';

const styles = {
  form: {
    mt: '10px',
  },
  label: {
    position: 'relative',
    transform: 'translate(0, 0) scale(1)',
    fontSize: '12px',
    lineHeight: '20px',
    color: 'rgba(0,0,0,.6)',
    '&.Mui-focused': {
      color: 'rgba(0,0,0,.6)',
    }
  },
};

const UnitsBlock = ({ setUnit, unit }) => {
  const selectUnit = useCallback((value) => {
    setUnit(value?.unit ?? null);
  }, [setUnit]);

  return (
    <FormControl
      fullWidth
      sx={styles.form}
    >
      <InputLabel
        shrink
        sx={styles.label}
      >
        Units
      </InputLabel>

      <UnitsSelect
        value={unit}
        onChange={selectUnit}
      />
    </FormControl>
  );
};

UnitsBlock.propTypes = {
  unit: PT.object,
  setUnit: PT.func.isRequired,
};

export default UnitsBlock;
