import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import PageLayout from './styles';

const Page = forwardRef(({ dndEvents, children, ...props }, ref) => (
  <PageLayout
    {...dndEvents}
    {...props}

    ref={ref}
  >
    {children}
  </PageLayout>
));

Page.propTypes = {
  dndEvents: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

Page.defaultProps = {
  dndEvents: {}
};

export default Page;
