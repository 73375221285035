import styled from 'styled-components';

import { COL_HEADER_HEIGHT_PX } from '../constants';

export default styled.span`
  font-size: 12px;
  font-weight: normal;
  line-height: ${COL_HEADER_HEIGHT_PX / 2}px;
  color: #666;
`;
