/* eslint-disable react/no-multi-comp */
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Virtuoso } from 'react-virtuoso';
import { MenuStyled, EmptyPlaceholderStyled } from './styles';
import GroupOption from './GroupOption';

const MenuList = ({
  options,
  selectProps,
  selectOption,
  getValue,
}) => {
  const initialOptionsRef = useRef(null);

  useEffect(() => {
    if (!initialOptionsRef.current && options.length){
      initialOptionsRef.current = options.length;
    }
  }, [options]);

  const selectedItemIndex = useMemo(() =>
    options.findIndex(i =>
      getValue()?.[0]?.value === i.value
    ),
  [options, getValue]
  );

  const handleClick = useCallback((value, data) => {
    selectOption({ value, data });
  }, [selectOption]);

  const {
    menuHeight,
    selected,
  } = selectProps;

  const isSearching = initialOptionsRef.current && options.length !== initialOptionsRef.current;

  return (
    <MenuStyled>
      <Virtuoso
        style={{ height: menuHeight }}
        data={options}
        initialTopMostItemIndex={selectedItemIndex > -1 ? selectedItemIndex : 0}
        itemContent={(index, { label, children }) => {
          return (
            <GroupOption
              data={{ label, children }}
              index={index}
              onClick={handleClick}
              selected={index === selected}
              isSearching={isSearching}
            />
          );
        }}
        components={{ EmptyPlaceholder }}
      />
    </MenuStyled>
  );
};

MenuList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.object,
      value: PropTypes.string,
      label: PropTypes.string.isRequired,
      isGroup: PropTypes.bool
    })
  ).isRequired,
  selectProps: PropTypes.shape({
    menuHeight: PropTypes.number.isRequired,
    selected: PropTypes.number,
  }).isRequired,
  selectOption: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default MenuList;

function EmptyPlaceholder() {
  return (
    <EmptyPlaceholderStyled>
      Nothing found
    </EmptyPlaceholderStyled>
  );
}
