import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import ErrorMessage from './ErrorMessage';

class AppGraphicsErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  componentDidUpdate(prevProps) {
    const { config } = this.props;

    if(!isEqual(config, prevProps.config) && this.state.hasError) {
      this.setState({ hasError: false });
    }
  }

  render() {
    const { preview } = this.props;
    const { hasError } = this.state;

    if(hasError)
      return preview ?
        null :
        <ErrorMessage>
          Unable to display graphics. Please try to change graphics settings.
        </ErrorMessage>;

    return this.props.children;
  }
}

AppGraphicsErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  preview: PropTypes.bool,
  config: PropTypes.object
};

export default AppGraphicsErrorBoundary;
