
import { useSubscription } from '@apollo/client';

import {
  COMMENT_CREATED_SUBSCRIPTION,
  COMMENT_UPDATED_SUBSCRIPTION,
  COMMENT_DELETED_SUBSCRIPTION,
  COMMENT_REACTION_SUBSCRIPTION
} from './services';

function useCommentsSubscription({
  itemId,
  updateItemsCache,
  onDeleted,
  onAdded
}) {
  useSubscription(
    COMMENT_CREATED_SUBSCRIPTION,
    {
      variables: { itemId },

      onData({ data }) {
        const item = data?.data?.tableItemCommentCreated;

        if (item) {
          updateItemsCache(s => {
            const newData = {
              ...s,
              tableItemComments: s.tableItemComments.concat(item)
            };

            onAdded(itemId);

            return newData;
          });
        }
      }
    }
  );

  useSubscription(
    COMMENT_UPDATED_SUBSCRIPTION,
    {
      variables: { itemId },
      onData: ({ data }) => {
        if(!data.data) return;

        const { tableItemCommentUpdated } = data.data;

        updateItemsCache(data => {
          const updateOnIndex = data.tableItemComments.findIndex(({ id }) =>
            id === tableItemCommentUpdated.id
          );

          return Object.assign({}, data, {
            tableItemComments: [
              ...data.tableItemComments.slice(0, updateOnIndex),
              tableItemCommentUpdated,
              ...data.tableItemComments.slice(updateOnIndex + 1)
            ]
          });
        });
      }
    }
  );

  useSubscription(
    COMMENT_DELETED_SUBSCRIPTION,
    {
      variables: { itemId },
      onData: ({ data }) => {
        if(!data.data) return;

        const { tableItemCommentDeleted } = data.data;

        updateItemsCache(data => {
          const deleteOnIndex = data
            .tableItemComments
            .findIndex(({ id }) => id === tableItemCommentDeleted);

          const newData = Object.assign({}, data, {
            tableItemComments: [
              ...data.tableItemComments.slice(0, deleteOnIndex),
              ...data.tableItemComments.slice(deleteOnIndex + 1)
            ]
          });

          onDeleted(itemId);

          return newData;
        });
      }
    }
  );

  useSubscription(
    COMMENT_REACTION_SUBSCRIPTION,
    {
      variables: { itemId },
      onData({ data }) {
        if(!data.data) return;

        const { tableItemCommentReacted: item } = data.data;

        updateItemsCache(data => ({
          ...data,
          tableItemComments: data.tableItemComments.map(i =>
            i.id === item.id ? { ...item } : i
          )
        }));
      }
    }
  );
}

export default useCommentsSubscription;
