import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import withStyles from '@mui/styles/withStyles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import { CustomOptionsStyled } from './styles';
import { COLOR_TEXT, COLOR_GREY } from '../../styles';

const styles = {
  inputLabel: {
    marginRight: 10,
    color: COLOR_TEXT
  },
  selectInput: {
    padding: '5px 35px 5px 18px',
    fontSize: '0.8em'
  },
  checkboxLabel: {
    color: COLOR_GREY,
    fontSize: '0.8rem'
  },
  saveAsDefaultCheckbox: {
    padding: 0,
    marginRight: 7,
    marginLeft: -3
  },
  selectInputRoot: {

  },
  checkboxRoot: {
    margin: 0
  },
  menuItem: {
    padding: '11px 16px'
  }
};

const options = [
  30,
  35,
  40,
  45
];

const CustomOptions = ({
  classes,
  value,
  onChange,
  saveAsDefault,
  onSaveAsDefaultChange
}) => (
  <CustomOptionsStyled>
    <div className="font-size-container">
      <InputLabel
        classes={{ root: classes.inputLabel }}
        htmlFor="font-size-select"
      >
        Text font size
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        input={
          <OutlinedInput
            classes={{
              input: classes.selectInput,
              root: classes.selectInputRoot
            }}
            name="size"
            id="font-size-select"
          />
        }
      >
        {options.map(i => (
          <MenuItem
            key={i}
            className={classes.menuItem}
            value={i}
          >
            {`${i}px`}
          </MenuItem>
        ))}
      </Select>
    </div>

    <FormControlLabel
      control={
        <Checkbox
          checked={saveAsDefault}
          onChange={onSaveAsDefaultChange}
          color="primary"
          classes={{ root: classes.saveAsDefaultCheckbox }}
        />
      }
      classes={{ label: classes.checkboxLabel, root: classes.checkboxRoot }}
      label="Save this size as the default one on my account"
    />
  </CustomOptionsStyled>
);

CustomOptions.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  saveAsDefault: PropTypes.bool.isRequired,
  onSaveAsDefaultChange: PropTypes.func.isRequired
};

export default withStyles(styles)(CustomOptions);
