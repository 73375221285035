import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';

const styles = {
  listHeadActionLabel: {
    textTransform: 'none',

    '&:hover': {
      textDecoration: 'underline'
    }
  }
};

const ListContainerHead = ({
  classes,
  showClearAll,
  onClearAll,
  onReadAll,
  isToday,
  hideAction = false,
  disabled = false
}) => {
  const actionEl = showClearAll
    ? (
      <Button
        classes={{ label: classes.listHeadActionLabel }}
        className="list-head-action"
        onClick={onClearAll}
        disabled={disabled}
      >
        Clear all
      </Button>
    )
    : (
      <Button
        classes={{ label: classes.listHeadActionLabel }}
        className="list-head-action"
        onClick={onReadAll}
        disabled={disabled}
      >
        Mark all as read
      </Button>
    );

  return (
    <div className="list-container-head">
      <span className="list-container-date">
        {isToday ? 'today' : 'older'}
      </span>

      {!hideAction ? actionEl : null}
    </div>
  );
};

ListContainerHead.propTypes = {
  classes: PropTypes.object.isRequired,
  showClearAll: PropTypes.bool,
  onClearAll: PropTypes.func.isRequired,
  onReadAll: PropTypes.func.isRequired,
  isToday: PropTypes.bool,
  hideAction: PropTypes.bool,
  disabled: PropTypes.bool
};

export default withStyles(styles)(ListContainerHead);
