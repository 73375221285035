/* eslint-disable react/no-multi-comp */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import OverlayStyled from './styles';

const Overlay = ({ onClick }) => <OverlayStyled onClick={onClick} />;

const OverlayPortal = props => {
  const { originId, ...rest } = props;
  const actionSheetOrigin = document.getElementById(originId);
  return ReactDOM.createPortal(
    <Overlay {...rest} />,
    actionSheetOrigin
  );
};

Overlay.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default OverlayPortal;
