import React from 'react';

import { Outlet, useLocation, Navigate } from 'react-router-dom';

import { useAuth } from '../../../context/auth/auth';

import { routes } from '../../../services/session/constants';

const Authenticated = () => {
  const { user } = useAuth();
  const location = useLocation();

  if(!user)
    return (
      <Navigate
        to={routes.SIGN_IN}
        replace
        state={{ from: `${location.pathname}${location.search || ''}` }}
      />
    );

  return (
    <Outlet />
  );
};

export default Authenticated;
