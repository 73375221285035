import React, { useMemo, useCallback, startTransition } from 'react';
import { useParams } from 'react-router-dom';
import { useSuspenseQuery } from '@apollo/client';

import Box from '@mui/material/Box';

import TableTitle from './TableTitle';
import TableProperties from './TableProperties';
import TabsBlock from './TabsBlock/TabsBlock';

import { TABLE_SETTINGS } from './gql';
import { hasAdminAccess } from '../../utils/roles';

const TableSettings = () => {
  const { id } = useParams();

  const { data, refetch } = useSuspenseQuery(TABLE_SETTINGS, {
    variables: {
      id
    }
  });

  const readOnly = useMemo(() => {
    return !hasAdminAccess(data.table.viewerMaxRole);
  }, [data.table.viewerMaxRole]);

  const handleRefetch = useCallback(() => {
    startTransition(() => { // prevent re-suspense the page
      refetch();
    });
  }, [refetch]);

  return (
    <Box width="100%" p="24px 24px 72px" overflow="auto">
      <Box
        display="flex"
        flexDirection="column"
        gap="6px"
      >
        <TableTitle
          table={data.table}
          readOnly={readOnly}
        />

        <TableProperties
          table={data.table}
          readOnly={readOnly}
        />

        <TabsBlock
          table={data.table}
          readOnly={readOnly}
          onRefetch={handleRefetch}
        />
      </Box>
    </Box>
  );
};

export default TableSettings;
