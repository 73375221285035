import React from 'react';
import PT from 'prop-types';

import IconButton from '@mui/material/IconButton';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';

import ButtonTooltip from '../../../components/ButtonTooltip/ButtonTooltip';

const SelectProtocolsAction = ({ onClick, disabled }) => (
  <ButtonTooltip
    title="Select protocols and parameters"
    placement="top"
    enterTouchDelay={0}
  >
    <IconButton
      size="medium"
      onClick={onClick}
      disabled={disabled}
    >
      <ViewColumnIcon />
    </IconButton>
  </ButtonTooltip>
);

SelectProtocolsAction.propTypes = {
  onClick: PT.func.isRequired,
  disabled: PT.bool
};

export default SelectProtocolsAction;
