import React from 'react';

import Box from '@mui/material/Box';

import LoadingBanner from '../LoadingBanner';

import { COLOR_WHITE, COLOR_PRIMARY } from '../../styles';

const LoadingScreen = () => {
  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <LoadingBanner
        spinner
        height={40}
        color={COLOR_PRIMARY}
        bcgcolor={COLOR_WHITE}
      />
    </Box>
  );
};

export default LoadingScreen;
