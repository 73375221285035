import client from '../../../../services/apollo-client';
import { GET_ITEM_TITLE } from '../gql/itemView';

export default async function itemTitle ({ params }) {
  const { sampleId } = params;

  let title;

  try {
    const response = await client.query({
      query: GET_ITEM_TITLE,
      variables: {
        id: sampleId
      }
    });

    title = response.data.item.title;

  } catch(e) {
    console.log(e);
  }

  return { title };
}
