import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

const GET_ALL_USERS = gql`
  query AllUsers($statusFilters: [UserStatusFilter!]) {
    users(statusFilters: $statusFilters) {
      id
      name

      ... on CurrentUser {
        email
        userId
      }
    }
  }
`;

const useGetAvailableUsers = ({ skipUsers }) => {
  const { data: usersData } = useQuery(GET_ALL_USERS, {
    variables: {
      statusFilters: 'ACTIVE'
    }
  });

  const availableUsers = useMemo(() => {
    if(!usersData?.users)
      return [];

    return usersData.users.filter(({ id }) => !skipUsers.includes(id));
  }, [usersData, skipUsers]);

  return availableUsers;
};

export default useGetAvailableUsers;
