import React from 'react';
import PT from 'prop-types';
import capitalize from 'lodash/capitalize';

import Typography from '@mui/material/Typography';

const ReadField = ({ value }) => (
  <Typography
    variant="body2"
    component="span"
    noWrap
  >
    {capitalize(value)}
  </Typography>
);

ReadField.propTypes = {
  value: PT.string
};

export default ReadField;
