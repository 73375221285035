import React from 'react';

const logoStyles = `.cls-1{fill:#fff;}`;

const LogoMainMono = () => (
  <svg
    id="app-logo-full"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1376.23 312.36"
  >
    <defs>
      <style>{logoStyles}</style>
    </defs>

    <title>Materials.Zone Logo (Full)</title>

    <path className="cls-1" d="M422-265.34c.17,2.27.28,4.57.54,6.84a60.53,60.53,0,0,1-3.18,25.81,61.87,61.87,0,0,1-23.4,31.49,59.81,59.81,0,0,1-32.26,11.35c-24.54,1.13-43.86-8.51-57.7-28.91a69.9,69.9,0,0,1-10.39-28.85,35.24,35.24,0,0,1,.4-13.65,85.36,85.36,0,0,1,6.58-20.25,62.21,62.21,0,0,1,42.27-33.36,62.07,62.07,0,0,1,44.91,6.07,82.86,82.86,0,0,0,12,5.67,45.08,45.08,0,0,0,57.06-28.44,45.61,45.61,0,0,0,2.18-10.65,30.46,30.46,0,0,1,12.85-22.7,31.49,31.49,0,0,1,44,7,31.48,31.48,0,0,1-7,44,31.36,31.36,0,0,1-13.35,5.57,29.46,29.46,0,0,1-15.12-1.22,44.94,44.94,0,0,0-14.18-2.83,40.88,40.88,0,0,0-17.34,3.09,44.48,44.48,0,0,0-28.59,36.73,48.6,48.6,0,0,0,0,7.24Z" transform="translate(-294.44 436.33)" />

    <path className="cls-1" d="M564.3-237.4a18,18,0,0,0-.14,2.84c1.05,11.35.68,22.69.68,34.24v70.16a4.82,4.82,0,0,1-3.91,4.54,8.86,8.86,0,0,1-2,.22h-13.3a5.13,5.13,0,0,1-5.66-4.55,5.06,5.06,0,0,1,0-.87V-255.41q-.09-1.82,0-3.63a4.77,4.77,0,0,1,4.17-4.77,16.83,16.83,0,0,1,2.84,0h25.82a13.85,13.85,0,0,1,2.41,0,6.39,6.39,0,0,1,5,3.38,27.91,27.91,0,0,1,1.47,3.74q6.84,22.35,13.65,44.71,5.89,19.46,11.74,38.95c1.14,3.72,2.21,7.46,3.35,11.18a3.47,3.47,0,0,0,.4,1.13c.31.4.73.91,1.13.94a1.53,1.53,0,0,0,1.08-.91c1-3.32,2-6.69,3-10q5.37-17.34,10.73-34.64,5.67-18.47,11.35-36.88,2.36-7.71,4.76-15.4a13.71,13.71,0,0,1,.71-1.88,7.33,7.33,0,0,1,7-4.48h27.83a5.31,5.31,0,0,1,5.93,4.59,5.57,5.57,0,0,1,0,1.08V-134.5a28.42,28.42,0,0,1-.17,4.42,4.88,4.88,0,0,1-5.12,4.63h0q-7.26.17-14.52,0a4.82,4.82,0,0,1-5.1-4.53.76.76,0,0,1,0-.15,24.56,24.56,0,0,1,0-3.21v-87.49c0-3.63.4-7.23.59-10.87,0-1.47.17-2.83.2-4.42a6.34,6.34,0,0,0-.39-1.48c-.49.4-1,.6-1.17,1-1,3-2,6.1-3,9.16q-4.35,13.62-8.51,27.26-6.84,21.51-13.71,43-4,12.48-8,24.94c-.25.77-.59,1.5-.88,2.24a6.57,6.57,0,0,1-6.12,4.51q-6.25.26-12.49,0a7.08,7.08,0,0,1-6.72-5.47c-1.59-4.86-3.12-9.73-4.65-14.59L584.5-177.88q-6.33-18.92-12-37.73c-2.1-6.67-4.11-13.33-6.21-20a12.45,12.45,0,0,0-1-2Z" transform="translate(-294.44 436.33)" />

    <path className="cls-1" d="M1333-125.54h-46.35a21.5,21.5,0,0,1-3.21,0,5,5,0,0,1-4.59-5,9.44,9.44,0,0,1,1.56-6.13c3.4-5.33,6.69-10.7,10-16.06l52-82.95c.93-1.47,1.93-2.84,2.84-4.42a1.32,1.32,0,0,0-.29-1.83,1.4,1.4,0,0,0-.74-.25h-55.17a4.78,4.78,0,0,1-4.77-4.81v-11.84a4.83,4.83,0,0,1,4.5-5.13h86.45a22,22,0,0,1,3.63,0,4.82,4.82,0,0,1,4.31,5,8.81,8.81,0,0,1-1.44,5c-2.3,3.63-4.54,7.29-6.84,10.93l-55.43,87.54c-1.14,1.82-2.33,3.6-3.43,5.45a1.16,1.16,0,0,0,.4,1.59,1.11,1.11,0,0,0,.56.17h61.65a27,27,0,0,1,4,0A4.73,4.73,0,0,1,1387-144a24.56,24.56,0,0,1,0,3.21v9.28a5.12,5.12,0,0,1-4.37,5.79,4.72,4.72,0,0,1-1.3,0h-2Z" transform="translate(-294.44 436.33)" />

    <path className="cls-1" d="M758.89-133.37a25.22,25.22,0,0,0,5,5.67,9.3,9.3,0,0,0,8.51,2.53c4.25-1,8.57-2.27,12.76-3.63a5.11,5.11,0,0,0,3.81-5.53v-2.84c0-5.19-.09-5.25-5.05-7.35L782.8-145a8.33,8.33,0,0,1-5-7.29,29.18,29.18,0,0,1,0-3.24v-36.68a79.41,79.41,0,0,0-.49-8.85,35.42,35.42,0,0,0-5.67-16.17,27.24,27.24,0,0,0-16.2-11.83,79,79,0,0,0-15.55-2,112.77,112.77,0,0,0-18.09.91c-4.54.51-9.05,1.24-13.54,2.12-4,.77-5.16,2.36-5.16,6.44v7.27c0,4.71,1.05,5.73,5.67,5.41h.4c7-.48,14-.93,20.91-1.5a54.1,54.1,0,0,1,12.06.57c8.11,1.19,11.51,5,11.85,13.13.17,3.89.17,7.8.17,11.69a3.57,3.57,0,0,1,0,.8.35.35,0,0,1-.06.14,1.16,1.16,0,0,1-1.55.54,4.84,4.84,0,0,1-1-.71,18.84,18.84,0,0,0-14.49-5.88c-3.1,0-6.19-.14-9.28,0a92.93,92.93,0,0,0-10.41.91,24.94,24.94,0,0,0-15,7.92,27.09,27.09,0,0,0-6.27,13.73,57.11,57.11,0,0,0-.76,14.18A43.53,43.53,0,0,0,697.86-140a22.68,22.68,0,0,0,11.75,12.68,28.7,28.7,0,0,0,9.25,2.64,125.52,125.52,0,0,0,23.74,0A14.76,14.76,0,0,0,754-131.27c.82-1.22,1.67-2.38,2.41-3.4C757.81-135,758.32-134.19,758.89-133.37ZM735.37-144.6c-2,0-4,.08-6,.11a20.74,20.74,0,0,1-4.36-.62,7.53,7.53,0,0,1-5.68-5.68,20.18,20.18,0,0,1-.76-3.94,55.75,55.75,0,0,1,0-6.84,17.88,17.88,0,0,1,.93-4.28,6.79,6.79,0,0,1,4.71-4.57,25.82,25.82,0,0,1,5.9-1.11c4-.17,8.06-.19,12.06,0a24.44,24.44,0,0,1,6.27,1.22,7.7,7.7,0,0,1,5.67,7.92v8.85a7.23,7.23,0,0,1-5,7.32,20.57,20.57,0,0,1-5.67,1.45c-2.64.17-5.36,0-8,0v.17Z" transform="translate(-294.44 436.33)" />

    <path className="cls-1" d="M1116.45-132.29a24.61,24.61,0,0,0,5.67,5.67,8,8,0,0,0,5.31,1.51,20.86,20.86,0,0,0,3.6-.46c3.49-.93,7-1.93,10.41-3.09a5.36,5.36,0,0,0,4.23-6,11,11,0,0,1,0-1.62,6,6,0,0,0,.14-.74,6.86,6.86,0,0,0-5.82-7.77,7.41,7.41,0,0,1-5.33-6.9,34.32,34.32,0,0,1-.17-4.42V-194a42.34,42.34,0,0,0-4.11-19.55,27.57,27.57,0,0,0-18.16-15.35,73.41,73.41,0,0,0-15.55-2,143.07,143.07,0,0,0-30.83,2.84,15.8,15.8,0,0,0-3.78,1.3,3.2,3.2,0,0,0-2,2.84v12.48a2.26,2.26,0,0,0,1.9,2.3,16,16,0,0,0,4,.26c7.52-.49,15-1.08,22.52-1.56a46.67,46.67,0,0,1,12,.9,11,11,0,0,1,9.93,10.13,23,23,0,0,1,.37,3.6v10.08a7.88,7.88,0,0,1,0,1.19,1.37,1.37,0,0,1-.17.37,1.25,1.25,0,0,1-1.73.34,3.33,3.33,0,0,1-.63-.49A19.56,19.56,0,0,0,1093-190h-10.07a41.56,41.56,0,0,0-14.19,2.44,23.26,23.26,0,0,0-14,13.65,38.84,38.84,0,0,0-2.52,12.56,58.07,58.07,0,0,0,1.45,18.39,23.62,23.62,0,0,0,9.3,13.7,27.65,27.65,0,0,0,12.77,4.57,123,123,0,0,0,23,.14,15.47,15.47,0,0,0,12.88-7.78,33.35,33.35,0,0,1,2.32-3C1115-133.94,1115.77-133,1116.45-132.29Zm-29.88-12.31a25.49,25.49,0,0,1-4.76-.66,7.63,7.63,0,0,1-6-6.35,37.58,37.58,0,0,1,0-12.43,7.64,7.64,0,0,1,5.68-6.55,22.11,22.11,0,0,1,5.1-.91c4,0,8.06-.17,12.06,0a24.51,24.51,0,0,1,6.3,1.11,7.52,7.52,0,0,1,5.81,6.92,90.9,90.9,0,0,1,0,10.44,7.87,7.87,0,0,1-6.29,7.46,33.19,33.19,0,0,1-6.33,1h-5.68C1090.46-144.6,1088.45-144.52,1086.57-144.6Z" transform="translate(-294.44 436.33)" />

    <path className="cls-1" d="M1481.92-193.11a78.46,78.46,0,0,0-2-13.54,28.34,28.34,0,0,0-11-17.3,38.65,38.65,0,0,0-14-6.19,85.1,85.1,0,0,0-27.66-1.3,47.87,47.87,0,0,0-13.3,3.09,29.8,29.8,0,0,0-19,19.86,71,71,0,0,0-2.38,12.26,173.35,173.35,0,0,0-.45,31.4,77.5,77.5,0,0,0,3.12,17.42A29.12,29.12,0,0,0,1413.1-128a55.93,55.93,0,0,0,15.69,3.49,70.19,70.19,0,0,0,26.75-2,32.45,32.45,0,0,0,20.57-16.31,47.15,47.15,0,0,0,3.88-11.35,128.92,128.92,0,0,0,2.39-27.09C1482.24-184.23,1482.06-188.66,1481.92-193.11Zm-65.22,14.72c0-4.85-.08-9.67,0-14.61a34.16,34.16,0,0,1,1.7-10.7,9.28,9.28,0,0,1,6-6,19.38,19.38,0,0,1,4.31-1,64.15,64.15,0,0,1,18,.51,11,11,0,0,1,9.54,10,67.41,67.41,0,0,1,1,9.61c.19,9.28,0,18.53,0,27.81a49.61,49.61,0,0,1-1,7.57,10.79,10.79,0,0,1-8.82,9.33,28.62,28.62,0,0,1-3.18.57,65.17,65.17,0,0,1-15.29,0h-.4c-8.51-1.53-10.41-4.71-11.34-11.35-.57-4-.69-8-.86-12.05v-9.68h.2Z" transform="translate(-294.44 436.33)" />

    <path className="cls-1" d="M1639.57-167.47h23.77a29.18,29.18,0,0,0,3.24,0,3.65,3.65,0,0,0,.66,0,3.93,3.93,0,0,0,3.39-4.42q-.2-7.86,0-15.72a66.78,66.78,0,0,0-2.64-21.08,29.24,29.24,0,0,0-8.51-13.84,33.29,33.29,0,0,0-17-7.6,86.54,86.54,0,0,0-20.08-.85,48.94,48.94,0,0,0-13.82,2.58,27.62,27.62,0,0,0-17.62,17.53,61.14,61.14,0,0,0-2.83,14.55,172.73,172.73,0,0,0,0,35.8,72.85,72.85,0,0,0,3.18,14.67,26.09,26.09,0,0,0,17.56,18A55.73,55.73,0,0,0,1621-125.4a133.42,133.42,0,0,0,34.5-1.78c2.24-.4,4.62-.94,6.7-1.42s4.17-1.48,4.17-3.8c.19-3.83,0-7.61,0-11.35a3.18,3.18,0,0,0-2.84-2.84,13.48,13.48,0,0,0-2.84,0c-3.37.14-6.72.48-10.07.48-7.66.29-15.29.29-23,.29a34.92,34.92,0,0,1-7.94-1.22,9.52,9.52,0,0,1-7.15-6.92,36.26,36.26,0,0,1-.8-11.61,1.84,1.84,0,0,1,1.82-1.81,26.9,26.9,0,0,1,2.84,0h23.32Zm-22.64-17.62h-2c-2.56-.22-3.18-.65-3.18-3.06v-10.47a28.63,28.63,0,0,1,.77-5.13,7.68,7.68,0,0,1,5.67-5.68A18.44,18.44,0,0,1,1621-210a45.81,45.81,0,0,1,17.58.2,8,8,0,0,1,7.24,7.94,134.31,134.31,0,0,1,.34,14.47c0,1.56-.74,2.16-2.27,2.3H1629Z" transform="translate(-294.44 436.33)" />

    <path className="cls-1" d="M928.81-167.61h3.27a3.57,3.57,0,0,0,3.6-3.55,14.41,14.41,0,0,0,0-1.61v-17.34A79.48,79.48,0,0,0,934-206.53a28.18,28.18,0,0,0-19.85-22.24,71.37,71.37,0,0,0-30-2,53.18,53.18,0,0,0-9.82,2.1,26.73,26.73,0,0,0-17,15.4,59,59,0,0,0-4.2,19.61c-.51,6.15-.51,12.34-.51,18.52a109.72,109.72,0,0,0,1.9,21.25,48.05,48.05,0,0,0,4.77,14.18,23.73,23.73,0,0,0,10.21,10,45.1,45.1,0,0,0,17,4.51A132.31,132.31,0,0,0,927-128.26a16.55,16.55,0,0,0,1.93-.6,3.91,3.91,0,0,0,2.83-4v-10.5a3.16,3.16,0,0,0-3-3.2,12.13,12.13,0,0,0-2.41,0c-3.24.14-6.44.45-9.68.45-7.8.17-15.6.31-23.37.31A38,38,0,0,1,885-147.1a9,9,0,0,1-6.81-6.78,33.08,33.08,0,0,1-1-11.21,1.4,1.4,0,0,1,0-.29,2.14,2.14,0,0,1,2.29-2h26.24Zm-46.46-17.53a26.9,26.9,0,0,1-2.84,0h-.23a2.17,2.17,0,0,1-2-2.35,87.48,87.48,0,0,1,.62-15.26,8.32,8.32,0,0,1,5.42-6.47,12.38,12.38,0,0,1,2.33-.65,45.36,45.36,0,0,1,18.78,0,8.06,8.06,0,0,1,6.89,7.38,51.11,51.11,0,0,1,.4,5.67V-188c0,2.07-.65,2.84-2.84,2.84H882.35Z" transform="translate(-294.44 436.33)" />

    <path className="cls-1" d="M1490.83-175.89v-46.36a6,6,0,0,1,5-6,82.8,82.8,0,0,1,9.5-1.79,222.79,222.79,0,0,1,37.42-1.36,52.71,52.71,0,0,1,15.77,3,28.34,28.34,0,0,1,19,21.22,48.28,48.28,0,0,1,1.28,11.17v66.9a3.39,3.39,0,0,1-3.2,3.55h-16.89c-3.12,0-4.4-1.3-4.59-4.36v-61.59a30.47,30.47,0,0,0-1.45-10.3,11.5,11.5,0,0,0-8.51-7.69,36.22,36.22,0,0,0-7.18-.94c-3.89-.14-7.8-.14-11.69,0a42.72,42.72,0,0,0-6.75,1,3.47,3.47,0,0,0-2.84,3.43v76.6a3.94,3.94,0,0,1-3.9,4h-15.22a15.71,15.71,0,0,1-2.83-.17,3.16,3.16,0,0,1-2.84-3v-3.63Q1490.8-154.13,1490.83-175.89Z" transform="translate(-294.44 436.33)" />

    <path className="cls-1" d="M1217.35-144.92h16.35a38.5,38.5,0,0,0,5.67-.39,8.7,8.7,0,0,0,7.86-7.92,27.86,27.86,0,0,0-.71-11.2,6,6,0,0,0-5-4.71,55,55,0,0,0-6.78-.77h-10.89a37.52,37.52,0,0,1-11.89-1.87,19.51,19.51,0,0,1-13.67-14.58,51.4,51.4,0,0,1-.62-24,23.55,23.55,0,0,1,17-18.27,54.57,54.57,0,0,1,14.18-2.24,164,164,0,0,1,27.75,1.3,30.69,30.69,0,0,1,3.91.88,4.28,4.28,0,0,1,3.46,4.37V-215a3.48,3.48,0,0,1-3.12,3.65,24,24,0,0,1-4.4.32h-25.84a27.35,27.35,0,0,0-4.43.22,6.51,6.51,0,0,0-6.27,6.64,77.39,77.39,0,0,0,0,8.06,6.84,6.84,0,0,0,6.56,6.92,42.93,42.93,0,0,0,5.67.2,81.61,81.61,0,0,1,17.65,1.3,50.15,50.15,0,0,1,7.71,2.27,20.6,20.6,0,0,1,12.15,14.19,46,46,0,0,1,.68,25.53,25.69,25.69,0,0,1-19.64,19.29,82.89,82.89,0,0,1-18.78,2.13,129.23,129.23,0,0,1-26.83-2.55,25.05,25.05,0,0,1-3.89-1,4.22,4.22,0,0,1-3.09-4.25v-9.67a3.38,3.38,0,0,1,3.11-3.63h.41a33.87,33.87,0,0,1,4,0Z" transform="translate(-294.44 436.33)" />

    <path className="cls-1" d="M505-266.87a32,32,0,0,1-3.23,18.75,33.33,33.33,0,0,1-37.11,17.53A32,32,0,0,1,439.85-254a33.53,33.53,0,0,1,22.84-41.56,33.56,33.56,0,0,1,24.53,2.3c1.82.88,3.55,1.9,5.39,2.84a23.56,23.56,0,0,0,30.93-12.36q.24-.57.45-1.14a33,33,0,0,0,1.53-6.64,16.72,16.72,0,0,1,14.19-14.87,16.62,16.62,0,0,1,18.9,14,16.62,16.62,0,0,1-14,18.9,14.6,14.6,0,0,1-1.75.17,25.2,25.2,0,0,1-7.26-1.1A23.75,23.75,0,0,0,506-277.73a25.3,25.3,0,0,0-.68,2.86A59.35,59.35,0,0,0,505-266.87Z" transform="translate(-294.44 436.33)" />

    <path className="cls-1" d="M824.47-178.56v21.76a57.74,57.74,0,0,0,.32,6.44A7.4,7.4,0,0,0,832-143a32.45,32.45,0,0,0,4.83.14c2,0,4-.23,6-.2,2.67,0,3.66,1,3.72,3.69s0,5.9,0,8.88a4,4,0,0,1-3.1,4.17c-2.58.68-5.19,1.33-7.83,1.76a46.12,46.12,0,0,1-14.83,0c-10.76-1.71-17.34-8.2-19.32-19.3a66.23,66.23,0,0,1-.83-11.34V-204c0-4.57-.19-4.77-4.73-4.79h-5.25c-2.58-.17-3.63-1.19-3.72-3.8v-9.68a3.88,3.88,0,0,1,2.84-4,28,28,0,0,1,2.84-.79c1-.26,1.84-.4,2.83-.71,4.31-1.48,5.48-3.12,5.48-7.75v-15.71c0-4.49.74-5.22,5.08-5.25s9.13,0,13.7,0a12.17,12.17,0,0,1,2.41.17,3.06,3.06,0,0,1,2.61,2.83,21.5,21.5,0,0,1,0,3.21v18.13c0,3.37.28,3.63,3.69,3.66h11.69a22.27,22.27,0,0,1,2.83,0,3.17,3.17,0,0,1,2.84,2.83,11.36,11.36,0,0,1,0,2v10.07c0,4-.88,4.82-5.08,4.85H829c-4.34,0-4.51.23-4.54,4.57C824.45-195.5,824.47-187,824.47-178.56Z" transform="translate(-294.44 436.33)" />

    <path className="cls-1" d="M1154.35-206.53v-61.22a28.65,28.65,0,0,1,0-3.21,3.69,3.69,0,0,1,3.66-3.66,14.41,14.41,0,0,1,1.61,0h13.71a13.49,13.49,0,0,1,2,0,3.69,3.69,0,0,1,3.32,3.49,26.9,26.9,0,0,1,0,2.84v117.64a6.7,6.7,0,0,0,5,6.56c1.14.37,2.36.51,3.52.88a4.39,4.39,0,0,1,3.8,4.76v6a4.36,4.36,0,0,1-2.83,4.42,42.69,42.69,0,0,1-20.09,3.26,13.83,13.83,0,0,1-10.69-7.71,26.21,26.21,0,0,1-2.84-11.35v-4Q1154.34-177.12,1154.35-206.53Z" transform="translate(-294.44 436.33)" />

    <path className="cls-1" d="M973.38-167v35a24.56,24.56,0,0,1,0,3.21,3.23,3.23,0,0,1-3.09,3.15q-9,.16-18.13,0c-2,0-3-1.39-3.23-3.46V-196a63.81,63.81,0,0,1,.93-12.42,24.56,24.56,0,0,1,12.32-17,44.19,44.19,0,0,1,22.29-6c4.17,0,8.32,0,12.49.34a28.64,28.64,0,0,1,6.29,1.19,4.89,4.89,0,0,1,4,5.36c0,5.11-.2,10.22-.37,15.29a5.12,5.12,0,0,1-.34,1.56,2,2,0,0,1-2.3,1.22,7.8,7.8,0,0,1-1.5-.51c-.74-.34-1.45-.68-2.16-1a34,34,0,0,0-20-2.83h-.79c-4.14.93-6.24,2.21-6.21,7.4v36.26Z" transform="translate(-294.44 436.33)" />

    <path className="cls-1" d="M1040.59-177.23v45.93c0,4.29-.8,6-5.87,5.68-4.15-.2-8.32,0-12.49,0-.8,0-1.6,0-2.41,0a3.22,3.22,0,0,1-3.18-3.07,22.27,22.27,0,0,1,0-2.83v-91.84a19.1,19.1,0,0,1,0-2.83,3.56,3.56,0,0,1,3.21-3,16.16,16.16,0,0,1,2.41,0h12.88a17,17,0,0,1,2,0,3.42,3.42,0,0,1,3.43,3.27v48.73Z" transform="translate(-294.44 436.33)" />

    <path className="cls-1" d="M573.72-379.58a20.09,20.09,0,0,1-20.07,20.1,20.09,20.09,0,0,1-20.1-20.07v0a20.08,20.08,0,0,1,20.09-20.09A20.08,20.08,0,0,1,573.72-379.58Z" transform="translate(-294.44 436.33)" />

    <path className="cls-1" d="M1028.79-269.14h8c2.7,0,3.83,1.13,3.86,4v14.46c0,2.84-1.19,4.09-4.25,4.15h-15.69c-2.84,0-3.94-1.05-4-3.86v-14.9c0-2.83,1-3.8,3.94-3.88s5.36,0,8.06,0Z" transform="translate(-294.44 436.33)" />

    <path className="cls-1" d="M451.2-361.51a40.64,40.64,0,0,1-4.12,23.86,42.38,42.38,0,0,1-47.32,22.27,40.76,40.76,0,0,1-31.51-29.73A42.55,42.55,0,0,1,396.87-398a42.54,42.54,0,0,1,31.74,3c2.3,1.1,4.52,2.44,6.84,3.46a30,30,0,0,0,39.35-15.7c.19-.43.37-.88.54-1.32a42.28,42.28,0,0,0,1.93-8.51,21.26,21.26,0,0,1,18-18.95,20.78,20.78,0,0,1,22.69,13,20.93,20.93,0,0,1-11.17,27.42,21.15,21.15,0,0,1-7.44,1.63,32,32,0,0,1-9.07-1.31,30.22,30.22,0,0,0-37.79,19.95,30.46,30.46,0,0,0-.88,3.66A75.7,75.7,0,0,0,451.2-361.51Z" transform="translate(-294.44 436.33)" />

    <path className="cls-1" d="M421.78-259.87a61,61,0,0,1-6.16,35.81,63.62,63.62,0,0,1-70.92,33.41c-24.34-5.75-40.77-20.9-47.55-44.62A63.41,63.41,0,0,1,334.2-312.4a63.17,63.17,0,0,1,53.56,2.21c3.46,1.67,6.78,3.66,10.27,5.22,26.78,11.94,53.51-6,59.89-25.76a63.37,63.37,0,0,0,2.84-12.68,31.9,31.9,0,0,1,27.15-28.37,31.2,31.2,0,0,1,34,19.52c9.67,25.1-11.35,44.05-27.92,43.6a47.51,47.51,0,0,1-13.61-2,45.39,45.39,0,0,0-56.66,30.17,43.76,43.76,0,0,0-1.27,5.26A112.11,112.11,0,0,0,421.78-259.87Z" transform="translate(-294.44 436.33)" />
  </svg>
);

export default LogoMainMono;
