import React, { useState, useCallback, useRef } from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';

import ProtocolForm from '../ProtocolForm';
import ConfirmationDialog from './ConfirmationDialog';

const EditProtocolForm = ({ onSubmit, ...formProps }) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const pendingData = useRef();

  const handleSubmit = useCallback((data) => {
    pendingData.current = data;
    setConfirmationDialogOpen(true);
  }, []);

  const handleConfirmationDialogClose = useCallback(() => {
    setConfirmationDialogOpen(false);
  }, []);

  const handleConfirmationDialogSubmit = useCallback(() => {
    onSubmit(pendingData.current);
    pendingData.current = null;
    setConfirmationDialogOpen(false);
  }, [onSubmit]);

  return (
    <Box pl="16px">
      <ProtocolForm
        {...formProps}
        onSubmit={handleSubmit}
      />

      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onSubmit={handleConfirmationDialogSubmit}
      />
    </Box>
  );
};

EditProtocolForm.propTypes = {
  onSubmit: PT.func
};

export default EditProtocolForm;
