const getAllIds = (data) => {
  const ids = [];

  data?.forEach(table => {
    if(!table.children && !table.isFolder){
      ids.push(table.id);
    } else {
      const childIds = getAllIds(table.children);
      ids.push(...childIds);
    }
  });

  return ids;
};

export default getAllIds;
