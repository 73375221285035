import React from 'react';
import PT from 'prop-types';
import TableStyled from './styles';

const Table = ({ colgroups = [], children }) => (
  <TableStyled width="100%" className="table">
    {colgroups.map(({ id, colSpan }) => (
      <colgroup key={id} span={colSpan}></colgroup>
    ))}

    <tbody>
      {children}
    </tbody>
  </TableStyled>
);

Table.propTypes = {
  colgroups: PT.arrayOf(PT.shape({
    id: PT.string,
    colSpan: PT.number
  })),
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node
  ]),
};

export default Table;
