import { Editor } from 'slate';
import { MARK_BOLD, MARK_CODE, MARK_ITALIC, MARK_UNDERLINE } from '../../constants';

export default editor => {
  editor.isMarkActive = format => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };

  editor.toggleMark = format => {
    if (format === 'clear') {
      [
        MARK_BOLD,
        MARK_CODE,
        MARK_ITALIC,
        MARK_UNDERLINE
      ].forEach(i => {
        Editor.removeMark(editor, i);
      });

      return;
    }

    const isActive = editor.isMarkActive(format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  return editor;
};
