import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import makeStyles from '@mui/styles/makeStyles';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles({
  listItemText: {
    margin: 0
  }
});

const SingleValue = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <components.SingleValue {...props}>
      <ListItemText
        key={props.data.label}
        className={classes.listItemText}
        primaryTypographyProps={{
          sx: { fontSize: 14 }
        }}
        primary={children}
      />
    </components.SingleValue>
  );
};

SingleValue.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
  })
};

export default SingleValue;
