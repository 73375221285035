import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import dateFnsFormat from 'date-fns/format';
import { DayPicker, useInput } from 'react-day-picker';

import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles/withStyles';

import 'react-day-picker/dist/style.css';
import { DatePickerStyled, DatePickerInputStyled } from './styles';
import { COLOR_PRIMARY, COLOR_WHITE } from '../../../../../../../styles';
import Button from '../../../../../../../components/Button';

const styles = {
  selected: {
    backgroundColor: COLOR_PRIMARY,
    color: COLOR_WHITE
  }
};

const DatePicker = ({
  label,
  value,
  onChange = () => {},
  classes = {},
  disabled
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [month, setMonth] = useState(value || new Date());
  const currentDate = value || null;

  const handleBlur = useCallback(() => {
    setIsFocused(false);
    if (value) {
      setMonth(value);
    }
  }, [value]);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleMonthChange = useCallback((val) => {
    setMonth(val);
  }, []);

  const handleSelect = useCallback(val => {
    if (!val) return;

    onChange(val);
    setIsFocused(false);
  }, [onChange]);

  const handleClear = useCallback(() => {
    onChange(null);
    setIsFocused(false);
  }, [onChange]);

  const handleToday = useCallback(() => {
    onChange(new Date(new Date().setHours(0, 0, 0, 0)));
    setMonth(new Date());
    setIsFocused(false);
  }, [onChange]);

  const { dayPickerProps } = useInput();

  const displayValue = useMemo(() => value ? dateFnsFormat(value, 'dd/MM/yyyy') : null, [value]);
  const today = new Date();

  return (
    <ClickAwayListener onClickAway={handleBlur}>
      <DatePickerInputStyled>
        <div
          className={`date-select-button ${isFocused ? 'focused' : ''}`}
          onClick={handleFocus}
        >
          {displayValue || label}
          <DateRangeOutlinedIcon />
        </div>
        {isFocused &&
        <DatePickerStyled
          label={label}
        >
          <Paper className="custom-overlay" elevation={2}>
            <DayPicker
              {...dayPickerProps}

              selected={currentDate}
              month={month}
              onMonthChange={handleMonthChange}
              onDayClick={handleSelect}
              initialFocus={false}
              disabled={val => val > today || disabled}
              showOutsideDays
              modifiersClassNames={{
                selected: classes.selected,
              }}
              footer={
                <div className="datepicker-footer">
                  <Button
                    label="Clear"
                    variant="outlined"
                    color="inherit"
                    onClick={handleClear}
                    className="footer-btn"
                    disabled={disabled}
                  />
                  <Button
                    label="Today"
                    variant="outlined"
                    color="inherit"
                    onClick={handleToday}
                    className="footer-btn"
                    disabled={disabled}
                  />
                </div>
              }
            />
          </Paper>
        </DatePickerStyled>
        }
      </DatePickerInputStyled>
    </ClickAwayListener>
  );
};

DatePicker.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

export default withStyles(styles)(DatePicker);
