import React from 'react';
import PropTypes from 'prop-types';
import {
  histogram,
  quantile,
  ascending,
  deviation,
  mean
} from 'd3-array';
import { format } from 'd3-format';
import { zip, min, max } from 'lodash';

import ChartContainer from './ChartContainer';

import mapValidData from '../../../utils/graphics/validate-columns';
import CustomCategoricalTicker from '../../../utils/graphics/custom-categorical-ticker';

class HistogramSingle extends React.Component {
  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate(prevProps) {
    if(this.props.xAxis !== prevProps.xAxis) {
      this.chartContainerRef.innerHTML = '';

      this.renderChart();
    }
  }

  renderChart() {
    const {
      xAxis,
      preview,
      bins
    } = this.props;

    const Bokeh = window.Bokeh;

    const plot_width = preview ? 150 : void 0;
    const plot_height = preview ? 150 : 300;
    const match_aspect = preview ? void 0 : true;
    const sizing_mode = preview ? void 0 : 'stretch_both';
    const toolbar_location = preview ? null : 'right';

    const titles = this.props.data[0];
    const data = mapValidData(titles, this.props.data.slice(1));
    const values = data
      .map(row => row[xAxis])
      .sort(ascending);

    const xMin = values[0];
    const xMax = values[values.length - 1];

    const bin = histogram()
      .domain([xMin, xMax])
      .thresholds(bins);

    const [xValues, yValues] = zip(
      ...bin(values)
        .filter(values => values.length)
        .map(group => [quantile(group, .5), group.length])
    );

    const formatSI = format('.4~s');
    const x = xValues?.map(formatSI) || [];

    let source = new Bokeh.ColumnDataSource({ data: { x, y: yValues } });

    const yMin = min(yValues);
    const yMax = max(yValues);

    const ydr = new Bokeh.Range1d({ start: yMin, end: yMax });

    const xTitle = titles[xAxis];
    const yTitle = '# of occurrences';
    const tooltips = preview ? void 0 : [
      [xTitle, `@{x}`],
      [yTitle, `@{y}`]
    ];

    const hoverTool = new Bokeh.HoverTool({ tooltips });
    const tools = preview ? void 0 : [
      hoverTool,
      new Bokeh.PanTool(),
      new Bokeh.BoxZoomTool(),
      new Bokeh.SaveTool(),
      new Bokeh.ResetTool()
    ];

    const plot = new Bokeh.Plotting.figure({
      x_range: x,
      y_range: ydr,
      // plot sizing config
      width: plot_width,
      height: plot_height,
      match_aspect,
      sizing_mode,

      // toolbar config
      toolbar_location,
      tools
    });

    plot.vbar({ field: 'x' }, 1, { field: 'y' }, yMin, { source });

    let columns;

    if(preview) {
      plot.xaxis[0].visible = false;
      plot.yaxis[0].visible = false;

      columns = plot;
    } else {
      plot.toolbar.logo = null;

      // axes config
      plot.xaxis[0].axis_label = xTitle;
      plot.xaxis[0].ticker = new CustomCategoricalTicker(15);

      plot.yaxis[0].axis_label = yTitle;
      plot.yaxis[0].major_label_orientation = 'vertical';

      // toolbar config
      plot.toolbar.active_inspect = null;
      plot.toolbar.active_scroll = null;
      plot.toolbar.active_tap = null;

      const formatSI10 = format('<10.4s');
      const formatSI5 = format('<5.4s');

      const info = new Bokeh.Row({
        children: [
          new Bokeh.Widgets.PreText({
            text: [
              `MIN: ${formatSI10(xMin)}`,
              `MAX: ${formatSI5(xMax)}`,
              '\n',
              `AVG: ${formatSI10(mean(values))}`,
              `STD: ${formatSI5(deviation(values))}`
            ].join('')
          })
        ],
        height: 48,
        align: 'center'
      });

      columns = new Bokeh.Column({
        children: [
          plot,
          info
        ],
        sizing_mode: 'stretch_width'
      });
    }

    Bokeh.Plotting.show(columns, this.chartContainerRef);

    hoverTool.active = true;
  }

  render() {
    return (
      <ChartContainer
        ref={ref => { this.chartContainerRef = ref; }}
        className={this.props.preview && 'preview'}
      />
    );
  }
}

HistogramSingle.propTypes = {
  data: PropTypes.array.isRequired,
  preview: PropTypes.bool,
  xAxis: PropTypes.number,
  bins: PropTypes.number
};

HistogramSingle.defaultProps = {
  xAxis: 9,
  bins: 50
};

export default HistogramSingle;
