import styled from 'styled-components';

import { COLOR_ERROR } from '../../styles';

export default styled.div`
  color: ${COLOR_ERROR};
  font-size: 14px;
  padding: 14px;
  border: 1px dashed rgba(0, 0, 0, .23);
  border-radius: 2px;
  text-align: center;
  margin: auto;
`;
