import styled from 'styled-components';
import { COLOR_PRIMARY_LIGHT } from '../../../../styles';

export const StyledRow = styled('tr').attrs({ className: 'line' })`
  border-top: 1px solid #DCDBDC;
  ${({ chosen }) => chosen && `
    background-color: rgb(244, 244, 244);
    td:first-child {
      background-color: rgb(244, 244, 244);
      box-shadow: inset 2px 0 ${COLOR_PRIMARY_LIGHT};
    }
  `}
`;
