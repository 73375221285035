import styled from 'styled-components';
import Page from '../../components/Page';
import {
  MEDIA_QUERIES_MD,
  MEDIA_QUERIES_XS
} from '../../styles';

export default styled(Page)`
  .page-subtitle {
    margin-bottom: 24px;
  }

  .title-area {
    width: 250px;
    margin-top: 14px;
  }

  .warning-block {
    margin: 0 0 12px 32px;
    max-width: 510px;

    > * {
      margin-right: 0;
    }
  }

  @media (${MEDIA_QUERIES_MD}) {

  }

  @media (${MEDIA_QUERIES_XS}) {

  }
`;
