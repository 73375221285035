import React, { useState, useCallback } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

import Box from '@mui/material/Box';

import MeasurementView from './MeasurementView';
import EditMeasurement from './EditMeasurement';

const Measurement = ({
  className,
  data,
  allowEdit,
  sidebarState,
  onEdit,
  onMove,
  onDelete,
  loading,
  onOpen,
  isOpen
}) => {
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState(null);

  const handleOpenEdit = useCallback(() => {
    setEdit(true);
  }, []);

  const handleCloseEdit = useCallback(() => {
    setError(null);
    setEdit(false);
  }, []);

  const handleSubmitEdit = useCallback(async (id, payload) => {
    const { error } = await onEdit(id, payload);

    setError(error);

    if (!error)
      setEdit(false);
  }, [onEdit]);

  return (
    <Box className={className}>
      {edit ?
        <EditMeasurement
          className="measurement-edit"
          data={data}
          loading={loading}
          error={error}
          onCancel={handleCloseEdit}
          onSubmit={handleSubmitEdit}
        /> :
        <MeasurementView
          className="measurement-view"
          open={isOpen}
          data={data}
          allowEdit={allowEdit}
          sidebarState={sidebarState}
          onToggleOpen={onOpen}
          onEdit={handleOpenEdit}
          onMove={onMove}
          onDelete={onDelete}
        />
      }
    </Box>
  );
};

Measurement.propTypes = {
  className: PT.string,
  data: PT.object,
  allowEdit: PT.bool,
  sidebarState: PT.string,
  onEdit: PT.func,
  onMove: PT.func,
  onDelete: PT.func,
  loading: PT.bool,
  onOpen: PT.func,
  isOpen: PT.bool
};

export default styled(Measurement)`
  &:first-child .measurement-view {
    border-top: 1px solid #DCDBDC;
  }

  &:has(.measurement-edit) + & .measurement-view {
    border-top: 1px solid #DCDBDC;
  }
`;
