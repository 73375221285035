import React, { useState, useCallback } from 'react';
import toString from 'lodash/toString';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withStyles from '@mui/styles/withStyles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip from '@mui/material/Tooltip';

import { AuthFormStyled } from '../../../components/AuthPageStyled/AuthContainerStyled';
import RaisedInput from '../../../components/RaisedInput';
import { validatePassword, PasswordPolicy } from '../../../components/Auth';
import SignInButton from '../../Login/SignInButton';
import {
  getError,
  getErrorMsg,
  isFetching
} from '../../../services/session/selectors';

const getRules = () => [
  'Must be at least 8 characters long',
  'Must have at least one number',
  'Must have at least one uppercase',
  'Must have at least one lowercase'
];

const styles = {
  inputLabel: {
    transform: 'translate(0, -2px) scale(0.75)'
  }
};

const mapStateToProps = state => ({
  error: getError(state),
  errorMessage: getErrorMsg(state),
  isFetching: isFetching(state)
});

const SignupWithPassword = ({
  error,
  errorMessage,
  isFetching,
  onSubmit,
  classes
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const [isTermsAccepeted, setIsTermsAccepeted] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleEmailChange = useCallback(ev => {
    setEmail(ev.target.value);
  }, []);

  const handlePasswordChange = useCallback(ev => {
    setPassword(
      toString(ev.target.value)
    );

    setErrors(null);
  }, []);

  const handlePassswordVisibilityToggle = useCallback(() => {
    setPasswordIsVisible(s => !s);
  }, []);

  const handleTermsAcceptedChange = useCallback((ev, checked) => {
    setIsTermsAccepeted(checked);
  }, []);

  const handleSubmit = useCallback(ev => {
    ev.preventDefault();

    const errors = validatePassword(password);

    if (!errors.some(Boolean)) {
      onSubmit(email, password);
    } else {
      setErrors(errors);
    }
  }, [onSubmit, email, password]);

  return (
    <AuthFormStyled
      action="/"
      onSubmit={handleSubmit}
    >
      <FormControl
        error={checkError('email', error)}
        disabled={isFetching}
      >
        <InputLabel
          className={classes.inputLabel}
          htmlFor="signup-email"
          shrink
        >
          Email *
        </InputLabel>

        <RaisedInput
          id="signup-email"
          name="signup-email"
          type="email"
          placeholder="example@domain.com"
          onChange={handleEmailChange}
          value={email}
          inputProps={{
            required: true
          }}
        />

        {checkError('email', error) && (
          <FormHelperText id="signup-input-error">
            {errorMessage}
          </FormHelperText>
        )}
      </FormControl>

      <FormControl
        error={errors && !isEmpty(errors.filter(i => i))}
        disabled={isFetching}
      >
        <InputLabel
          className={classes.inputLabel}
          htmlFor="signup-password"
          shrink
        >
          Password *
          <div className="question-icon">
            <Tooltip
              title={
                <pre>
                  <PasswordPolicy />
                </pre>
              }
            >
              <span> ? </span>
            </Tooltip>
          </div>
        </InputLabel>

        <RaisedInput
          id="signup-password"
          name="signup-password"
          type={passwordIsVisible ? 'text' : 'password'}
          placeholder="password"
          onChange={handlePasswordChange}
          value={password}
          inputProps={{
            required: true
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                disableRipple
                onClick={handlePassswordVisibilityToggle}
                size="large"
              >
                {passwordIsVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />

        {errors && (
          <div className="password-rules-block">
            {getRules().map((i, index) => {
              const error = errors[index];

              return (
                <div
                  className={`pass-rule-item${error ? ' error' : ' checked'}`}
                  key={i}
                >
                  {error ? <ClearIcon /> : <CheckIcon />}
                  {i}
                </div>
              );
            })}
          </div>
        )}
      </FormControl>

      <FormControlLabel
        control={<Checkbox disableRipple color="primary" />}
        onChange={handleTermsAcceptedChange}
        label={
          <span>
            I agree to Materials Zone{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.materials.zone/privacy-and-terms"
            >
              Privacy Policy and Terms of Use
            </a>
          </span>
        }
      />

      <SignInButton
        fullWidth
        variant="contained"
        color="primary"
        type="submit"
        disabled={isFetching || !isTermsAccepeted}
      >
        Sign up
      </SignInButton>
    </AuthFormStyled>
  );
};

SignupWithPassword.propTypes = {
  error: PropTypes.object,
  errorMessage: PropTypes.string,
  isFetching: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(
  withStyles(styles)(SignupWithPassword)
);

function checkError(source, error) {
  return error?.source === source;
}
