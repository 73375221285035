import React from 'react';

import { ReactComponent as BellSvg } from '../img/bell.svg';

const EmptyList = () => (
  <div className="empty-list">
    <BellSvg />

    <div className="empty-list-title">
      No notifications at the moment
    </div>

    <div className="empty-list-description">
      You'll see the notifications here when your team members or colleagues start working on things.
    </div>
  </div>
);

export default EmptyList;
