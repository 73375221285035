import styled from 'styled-components';

import { COLOR_GREY_LIGHT } from '../../../../../styles';

export default styled.div.attrs({ className: 'list-container' })`
  padding: 25px 35px 40px;

  .empty-list {
    display: flex;
    flex-direction: column;
    text-align: center;

    svg {
      height: 200px;
      margin: 15px 0;
    }

    .empty-list-title {
      color: #000000;
      margin-bottom: 20px;
      font-weight: bold;
    }

    .empty-list-description {
      color: #757575;
      font-size: 14px;
      max-width: 300px;
      margin: auto;
    }
  }

  .list-container-title {
    border-bottom: 1px solid ${COLOR_GREY_LIGHT};
    font-size: 20px;
    padding-bottom: 15px;
    padding-top: 0;
    margin: 0;
  }

  .error {
    padding: 10px;
    text-align: center;
  }

  .list-container-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 14px;
    margin-bottom: 10px;

    .list-container-date {
      color: #757575;
      text-transform: uppercase;
      font-size: 13px;
    }

    .list-head-action {
      background: transparent;
      color: #a8a8a8;
      font-size: 11px;
      padding: 0;
      min-width: 0;
    }
  }

  .loading-banner {
    padding-top: 25px;
  }
`;
