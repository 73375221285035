import React, { useCallback } from 'react';
import { useAnalytics } from 'use-analytics';
import PT from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';

import useCalculationForm from './reducer';

import {
  componentNames,
  TRACK_CALCULATED_FIELDS,
} from '../../../../../../../../../analytics/constants';
import FormulaEditor from './FormulaEditor';

const styles = {
  dialog: {
    maxHeight: '560px',
  },
  dialogContent: {
    overflow: 'hidden'
  },
  formControl: {
    marginTop: '10px',
  },
  label: {
    transform: 'translate(0px, 0px) scale(1)',
    fontSize: '12px',
    lineHeight: '20px',
    color: 'rgba(0,0,0,.6)',
    '&.Mui-focused': {
      color: 'rgba(0,0,0,.6)',
    }
  },
  input: {
    mt: '22px',
    px: '10px',
    py: '6px',
    background: 'white',
    border: '1px solid #DCDBDC',
    borderRadius: '4px',
    '.MuiInputBase-input': {
      padding: 0
    },
    fontSize: '14px'
  }
};

const FormulationDialog = ({ parameterId, columns, value, onClose, onSubmit }) => {
  const { track } = useAnalytics();

  const {
    description,
    calculation,
    unit,
    setDescription,
    setCalculation,
    setUnit
  } = useCalculationForm(value);

  const handleSubmit = useCallback(async () => {
    track(TRACK_CALCULATED_FIELDS.update, {
      component: componentNames.TABLE_STRUCTURE
    });

    onSubmit({
      description,
      unit,
      calculation: calculation,
    });
  }, [track, onSubmit, description, unit, calculation]);

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      sx={{
        '.MuiDialog-paper': styles.dialog
      }}
    >
      <DialogTitle>
        Set calculated value
      </DialogTitle>

      <DialogContent
        sx={styles.dialogContent}
      >
        <FormControl
          fullWidth
        >
          <InputLabel
            variant="outlined"
            disableAnimation
            shrink
            sx={styles.label}
          >
            Description
          </InputLabel>

          <InputBase
            value={description}
            inputProps={{
              maxLength: 200
            }}
            sx={styles.input}
            onChange={setDescription}
            autoFocus
          />
        </FormControl>

        <FormulaEditor
          calculation={calculation}
          unit={unit}
          setCalculation={setCalculation}
          setUnit={setUnit}
          columns={columns}
          parameterId={parameterId}
        />
      </DialogContent>

      <DialogActions
        sx={{
          p: '16px'
        }}
      >
        <Button
          onClick={onClose}
        >
          cancel
        </Button>

        <Button
          variant="contained"
          onClick={handleSubmit}
        >
          submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FormulationDialog.propTypes = {
  parameterId: PT.string.isRequired,
  columns: PT.array.isRequired,
  value: PT.object,
  onClose: PT.func.isRequired,
  onSubmit: PT.func.isRequired,
};

export default FormulationDialog;
