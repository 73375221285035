import styled from 'styled-components';

const ResearchInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .form-control {
    width: 100%;
    max-width: 420px;
    margin-bottom: 12px;
  }

  .input-base-control {
    label + * {
      margin-top: 18px;
      border: 1px solid rgba(0, 0, 0, 0.26);
      border-radius: 2px;
    }

    input {
      height: 42px;
      box-sizing: border-box;
      padding-left: 6px;
    }
  }

  .section-actions > button:not(:last-child) {
    margin-right: 12px;
  }
`;

export default ResearchInfoContainer;
