import { createRequestTypes, action } from '../../actions';
import * as constants from './constants';

const {
  USER_FORGOT_PASSWORD,
  USER_LOG_OUT,
  REQUEST,
  SUCCESS,
  FAILURE,
  FIREBASE_INIT,
  TOGGLE_MOBILE_VIEW,
  SET_MOBILE_VIEW,
  USER_WATCH,
  PASSWORD_PROVIDER,
  OAUTH_PROVIDER,
  FETCH_SIGN_IN,
  FETCH_SIGN_UP,
  CREATE_USER,
  SAMPLES_SORT_SETTINGS_UPDATE,
  USER_UPDATE,
  USER_FIELD_UPDATE,
  LINK_PROVIDER,
  UNLINK_PROVIDER,
  SAMPLE_ASSETS_SORT_SETTINGS_UPDATE,
  CLEAN_PASSWORD_RESET,
  RESET_ERROR
} = constants;

export const fetchSignUpTypes = createRequestTypes(FETCH_SIGN_UP);
export const fetchCreateUserTypes = createRequestTypes(CREATE_USER);
export const fetchSignInTypes = createRequestTypes(FETCH_SIGN_IN);
export const userForgotPasswordTypes = createRequestTypes(USER_FORGOT_PASSWORD);
export const userLogoutTypes = createRequestTypes(USER_LOG_OUT);
export const firebaseInitTypes = createRequestTypes(FIREBASE_INIT);
export const userWatchTypes = createRequestTypes(USER_WATCH,
  { listenList: true }
);
export const samplesSortSettingsUpdateTypes = createRequestTypes(
  SAMPLES_SORT_SETTINGS_UPDATE
);
export const userUpdateTypes = createRequestTypes(USER_UPDATE);
export const updateUserFieldTypes = createRequestTypes(USER_FIELD_UPDATE);
export const linkProviderTypes = createRequestTypes(LINK_PROVIDER);
export const unlinkProviderTypes = createRequestTypes(UNLINK_PROVIDER);
export const sampleAssetsSortSettingsUpdateTypes = createRequestTypes(
  SAMPLE_ASSETS_SORT_SETTINGS_UPDATE
);

export const resetError = () => ({
  type: RESET_ERROR
});

export const toggleMobileView = () => ({
  type: TOGGLE_MOBILE_VIEW
});

export const setMobileView = value => ({
  type: SET_MOBILE_VIEW,
  value
});

export const firebaseInitHandlers = {
  request: () => action(firebaseInitTypes[REQUEST]),
  success: (response) => action(firebaseInitTypes[SUCCESS], { response }),
  failure: (error) => action(firebaseInitTypes[FAILURE], { error })
};

export const signUpHandlers = {
  request: authProviderType => (...params) => action(fetchSignUpTypes[REQUEST], { authProviderType, params }),
  success: response => action(fetchSignUpTypes[SUCCESS], { response }),
  failure: error => action(fetchSignUpTypes[FAILURE], { error })
};

const createUserHandlers = {
  request: user => action(fetchCreateUserTypes[REQUEST], { user }),
  success: response => action(fetchCreateUserTypes[SUCCESS], { response }),
  failure: error => action(fetchCreateUserTypes[FAILURE], { error })
};

export const signInHandlers = {
  request: authProviderType => (...params) => action(fetchSignInTypes[REQUEST], { authProviderType, params }),
  success: response => action(fetchSignInTypes[SUCCESS], { response }),
  failure: error => action(fetchSignInTypes[FAILURE], { error })
};

export const forgotPasswordHandlers = {
  request: email =>
    action(userForgotPasswordTypes[REQUEST], { email }),
  success: (response) => action(userForgotPasswordTypes[SUCCESS], { response }),
  failure: (error) => action(userForgotPasswordTypes[FAILURE], { error })
};

export const logoutHandlers = {
  request: () => action(userLogoutTypes[REQUEST]),
  success: (response) => action(userLogoutTypes[SUCCESS], { response }),
  failure: (error) => action(userLogoutTypes[FAILURE], { error })
};

export const linkProviderHandlers = {
  request: provider => action(linkProviderTypes[REQUEST], { provider }),
  success: response => action(linkProviderTypes[SUCCESS], { response }),
  failure: error => action(linkProviderTypes[FAILURE], { error: error.message })
};

export const unlinkProviderHandlers = {
  request: (providerId, user) => action(unlinkProviderTypes[REQUEST], { providerId, user }),
  success: response => action(unlinkProviderTypes[SUCCESS], { response }),
  failure: error => action(unlinkProviderTypes[FAILURE], { error: error.message })
};

export const cleanPassResetResponse = () => action(CLEAN_PASSWORD_RESET);

export const signUpWithEmailAndPassword = signUpHandlers.request(PASSWORD_PROVIDER);
export const signUpWithOAuth = signUpHandlers.request(OAUTH_PROVIDER);
export const onCreateUser = createUserHandlers.success;
export const signInWithEmailAndPassword = signInHandlers.request(PASSWORD_PROVIDER);
export const signInWithOAuth = signInHandlers.request(OAUTH_PROVIDER);
export const sendPasswordRecoveryEmail = forgotPasswordHandlers.request;
export const logout = logoutHandlers.request;

export const setCurrentUser = data => action(constants.SET_CURRENT_USER, { data });
