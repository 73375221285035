import firebase from 'firebase/app';
import 'firebase/auth';
import get from 'lodash/get';

import { general } from './configs';

export const HOST = general.cloudappHost;

function buildHeaders(headers = {}) {
  const defaultHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };

  return {
    ...defaultHeaders,
    ...headers
  };
}

export const callApi = async (endpoint, opts = {}, dataType = 'json') => {
  const host = opts.host || HOST;
  const isFullPath = ['http://', 'https://', host].some(i => endpoint.includes(i));
  const fullUrl = isFullPath ? endpoint : host + endpoint;
  const requestURL = new URL(fullUrl);
  const requestSearchParams = new URLSearchParams(requestURL.search);

  requestURL.search = requestSearchParams.toString();

  try {
    const authToken = await firebase.auth().currentUser.getIdToken(false);

    const headers = buildHeaders({
      ...get(opts, 'headers', {}),
      Authorization: authToken,
      'x-mz-api-feature': 'tables'
    });

    const options = {
      ...opts,
      headers
    };

    const response = await fetch(requestURL, options);

    if(!response.ok) {
      throw new Error('Request has failed');
    }

    let result;

    switch(dataType) {
      case 'json':
        result = await response.json();
        break;
      case 'file':
        result = await response.blob();
        break;
      default:
        result = await response.text();
    }

    return {
      response: result,
      headers: opts.headerProps &&
        opts.headerProps.reduce((acc, i) => ({
          ...acc,
          [i]: response.headers.get(i)
        }), {})
    };
  } catch(e) {
    return { error: 'Request has failed' };
  }
};
