import React, { useState, useEffect } from 'react';
import { useAnalytics } from 'use-analytics';
import PT from 'prop-types';

import CopyClipboard from '../../../../../../CopyClipboard';
import { componentNames, TRACK_COPIED_ITEM } from '../../../../../../../analytics/constants';

const CopyItemLink = ({
  text,
  children
}) => {
  const [isCopied, setCopied] = useState(false);

  const { track } = useAnalytics();

  useEffect(() => {
    if(!isCopied) return;

    const timerId = setTimeout(setCopied, 1e3, false);

    return () => {
      clearTimeout(timerId);
    };
  }, [isCopied]);

  const handleCopy = () => {
    setCopied(true);

    track(TRACK_COPIED_ITEM.pressed, {
      component: componentNames.TABLE_STRUCTURE
    });
  };

  return (
    <CopyClipboard
      tooltip
      tooltipProps={{
        title: isCopied ? 'Copied' : 'Copy link',
        placement: 'top',
      }}
      onCopy={handleCopy}
      text={text}
      wrapperStyles={{
        display: 'inline-flex'
      }}
    >
      {children}
    </CopyClipboard>
  );
};

CopyItemLink.propTypes = {
  text: PT.string.isRequired,
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node
  ]),
};

export default CopyItemLink;
