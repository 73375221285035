import React from 'react';
import PT from 'prop-types';

import Typography from '@mui/material/Typography';

const SelectedItemsCounter = ({ value }) => (
  <Typography fontSize={18} fontWeight="bold">
    {value} items selected
  </Typography>
);

SelectedItemsCounter.propTypes = {
  value: PT.number
};

export default SelectedItemsCounter;
