import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Counter, CounterWrapper, ListButton } from './styles';

import Collapse from '@mui/material/Collapse';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const FilterSection = props => {
  const {
    open,
    name,
    title,
    onToggleOpen,
    children,
    badge,
    onClear,
    icon: Icon
  } = props;

  const handleOpenToggle = useCallback(() => {
    onToggleOpen(name);
  }, [onToggleOpen, name]);

  const handleClear = useCallback((e) => {
    e.stopPropagation();
    onClear('tableIds', null);
  }, [onClear]);

  return (
    <>
      <ListButton
        onClick={handleOpenToggle}
      >
        <CounterWrapper>
          <Icon
            sx={{
              width: '16px',
              marginRight: '4px',
            }}
          />

          <ListItemText
            primary={title}
            disableTypography
            sx={{
              fontSize: '14px',
              fontWeight: badge ? 'bold' : null
            }}
          />
          {badge ?
            <Counter>
              {badge}
            </Counter> :
            null
          }
        </CounterWrapper>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {onClear &&
            <Button
              onClick={handleClear}
              sx={{
                padding: 0,
                fontSize: '12px',
                color: '#666',
                ':hover': {
                  backgroundColor: 'initial',
                }
              }}
            >
              reset
            </Button>
          }

          {open ?
            <ExpandLess sx={{ width: '16px' }} /> :
            <ExpandMore sx={{ width: '16px' }} />
          }
        </Box>
      </ListButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
};

FilterSection.propTypes = {
  open: PropTypes.bool,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onToggleOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  icon: PropTypes.object.isRequired,
  badge: PropTypes.number,
  onClear: PropTypes.func
};

export default FilterSection;
