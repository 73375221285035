import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Table from '../../Table';
import ErrorAlert from '../../ErrorAlert';

import TableStyled from './styles';

class TablePresentation extends PureComponent {
  isDataValid = data =>
    Array.isArray(data) && Array.isArray(data[0]);

  mapDataToColumnsMap = data =>
    data[0].map(i => ({
      value: i,
      label: i
    }));

  mapDataToItems = data =>
    data.reduce((p, c, idx, arr) => {
      if (idx === 0) return p;

      const row = c.reduce((prev, curr, idx) => ({
        ...prev,
        [ arr[0][idx] ]: curr
      }), {});

      return [
        ...p,
        row
      ];
    }, []);

  render() {
    const { data } = this.props;

    if (!this.isDataValid(data)) {
      return (
        <ErrorAlert text="Ivalid data" />
      );
    }

    return (
      <TableStyled>
        <Table
          className="table-presentation"
          items={this.mapDataToItems(data)}
          columnsMap={this.mapDataToColumnsMap(data)}
        />
      </TableStyled>
    );
  }
}

TablePresentation.propTypes = {
  data: PropTypes.any.isRequired
};

export default TablePresentation;
