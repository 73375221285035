import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const DeletionSubmitDialog = ({
  onSubmit,
  onClose,
  name,
  type
}) => (
  <Dialog
    open
    onClose={onClose}
  >
    <DialogTitle>
      Confirm the delete action
    </DialogTitle>

    <DialogContent>
      <DialogContentText>
        {`Are you sure you want to delete "${name}" ${type}?`}
      </DialogContentText>
    </DialogContent>

    <DialogActions sx={{ p: 2 }}>
      <Button
        color="primary"
        onClick={onClose}
      >
        cancel
      </Button>

      <Button
        variant="contained"
        color="primary"
        onClick={onSubmit}
      >
        yes, delete
      </Button>
    </DialogActions>
  </Dialog>
);

DeletionSubmitDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default DeletionSubmitDialog;
