import { gql } from '@apollo/client';

export const DASHBOARDS = gql`
  query Dashboards {
    dashboards {
      id
      url
      title
      children {
        id
        url
        title
      }
    }
  }
`;
