import React, { memo, useCallback } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

import { Typography, Box, TableCell } from '@mui/material';

import SortIcon from '../../../SortIcon';

import {
  CONTAINER_BORDER_WIDTH_PX
} from '../../../ItemsTable/constants';
import { MEDIA_QUERIES_MOBILE } from '../../../../styles';

const TOOLBAR_HEIGHT_PX = 60;
const TOOLBAR_MOBILE_VIEW_HEIGHT_PX = 144;

const TableHeader = ({
  header,
  sortedBy,
  onSort,
  text = '',
  width = 300,
  noSorting = false
}) => {
  const { rowSpan, id } = header;
  const isSorted = sortedBy?.param === id;

  const handleSort = useCallback(() => {
    if (noSorting) return;

    onSort(id);
  }, [noSorting, onSort, id]);

  return (
    <TableCell
      rowSpan={rowSpan}
      onClick={handleSort}
      sx={{
        width: `${width}px`,
        padding: 0
      }}
    >
      <Box display="flex" alignItems="center" sx={{ height: '40px' }}>
        <Typography sx={{ fontSize: '14px', color: '#666666' }}>
          {text}
        </Typography>

        {isSorted && <SortIcon order={sortedBy.isAsc ? 'ASC' : 'DESC'} />}
      </Box>
    </TableCell>
  );
};

TableHeader.propTypes = {
  table: PT.object.isRequired,
  sortedBy: PT.shape({
    param: PT.string,
    isAsc: PT.bool
  }),
  onSort: PT.func,
  header: PT.object.isRequired,
  text: PT.string,
  sortable: PT.bool,
  width: PT.number,
  noSorting: PT.bool,
};

export default styled(memo(TableHeader))`
  height: inherit;
  vertical-align: bottom;
  padding: 0;

  position: sticky;
  top: ${TOOLBAR_HEIGHT_PX}px;
  left: ${CONTAINER_BORDER_WIDTH_PX}px;
  z-index: 2;

  @media (${MEDIA_QUERIES_MOBILE}) {
    top: ${TOOLBAR_MOBILE_VIEW_HEIGHT_PX}px;
  }
`;
