import React from 'react';
import PropTypes from 'prop-types';
import zip from 'lodash/zip';
import min from 'lodash/min';
import max from 'lodash/max';
import uniq from 'lodash/uniq';
import { ascending, deviation, mean } from 'd3-array';
import { format } from 'd3-format';

import ChartContainer from './ChartContainer';

import mapValidData from '../../../utils/graphics/validate-columns';
import CustomTickFormatter from '../../../utils/graphics/custom-tick-formatter';
import getUnitsFromColumnTitle from '../../../utils/graphics/get-units-from-column-title';
import formatChartSummaryValues from '../../../utils/graphics/format-chart-summary-values';

const palette = [
  '#0000a4',
  '#0000f6',
  '#0034ff',
  '#007dff',
  '#00c8ff',
  '#23ffd4',
  '#60ff97',
  '#9aff5d',
  '#d4ff23',
  '#ffd600',
  '#ff9400',
  '#ff4e00',
  '#f60b00',
  '#9f0001'
];

const computeZValue = value => Array.isArray(value) ? mean(value) : value;
const computeZTooltip = value => Array.isArray(value) ?
  `<b>min:</b> ${format('.4s')(min(value))}<br>
   <b>max:</b> ${format('.4s')(max(value))}<br>
   <b>avg:</b> ${format('.4s')(mean(value))}<br>
   <b>std:</b> ${format('.4s')(deviation(value))}` :
  `${format('.4s')(value)}`;

class Heatmap extends React.Component {
  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate(prevProps) {
    const { xAxis, yAxis, zAxis } = this.props;

    const conditions = [
      xAxis !== prevProps.xAxis,
      yAxis !== prevProps.yAxis,
      zAxis !== prevProps.zAxis
    ];

    if(conditions.some(Boolean)) {
      this.chartContainerRef.innerHTML = '';

      this.renderChart();
    }
  }

  renderChart() {
    let {
      preview,
      xAxis,
      yAxis,
      zAxis,
      data: chartData
    } = this.props;

    const Bokeh = window.Bokeh;

    const plot_width = preview ? 150 : void 0;
    const plot_height = preview ? 150 : 300;
    const match_aspect = preview ? void 0 : true;
    const sizing_mode = preview ? void 0 : 'stretch_both';
    const toolbar_location = preview ? null : 'right';

    let titles, data;

    if(Array.isArray(chartData)) {
      titles = chartData[0];
      data = mapValidData(titles, chartData.slice(1));
    } else if(chartData && chartData.hasOwnProperty('titles')) {
      titles = chartData.titles;
      data = mapValidData(titles, chartData.values);
    } else {
      return;
    }

    if(titles.length <= zAxis) {
      zAxis = titles.length < 3 ? 0 : 2;
    }

    const tooltips = preview ? void 0 : [
      [titles[xAxis], '@x'],
      [titles[yAxis], '@y'],
      // [titles[zAxis], '@z{0[.]000}']
      [titles[zAxis], '@zTooltip{safe}']
    ];

    const tools = preview ? void 0 : [
      new Bokeh.HoverTool({ tooltips }),
      new Bokeh.PanTool(),
      new Bokeh.BoxZoomTool(),
      new Bokeh.SaveTool(),
      new Bokeh.ResetTool()
    ];

    const coords = [];

    for(let i = 0, l = data.length; i < l; i++) {
      const row = data[i];
      const [xValue, yValue, zValue] = [row[xAxis], row[yAxis], row[zAxis]];
      const duplicateIndex = coords.findIndex(([x, y]) => x === xValue && y === yValue);

      if(duplicateIndex > -1) {
        let duplicateRow = coords[duplicateIndex];
        duplicateRow[2] = [].concat(duplicateRow[2], zValue);
      } else {
        coords.push([xValue, yValue, zValue]);
      }
    }

    let [x, y, z] = zip(...coords);

    let zTooltip = z.map(computeZTooltip);

    z = z.map(computeZValue);

    const color = new Bokeh.LinearColorMapper({
      palette,
      high: max(z),
      low: min(z),
      name: 'z'
    });

    let source = new Bokeh.ColumnDataSource({
      data: {
        x: x.map(String),
        y: y.map(String),
        z,
        zTooltip
      }
    });

    const plot = new Bokeh.Plotting.figure({
      x_range: uniq(x.slice().sort(ascending).map(String)),
      y_range: uniq(y.slice().sort(ascending).map(String)),
      // plot sizing config
      width: plot_width,
      height: plot_height,
      match_aspect,
      sizing_mode,

      // toolbar config
      toolbar_location,
      tools
    });

    const rect = new Bokeh.Rect({
      x: { field: 'x' },
      y: { field: 'y' },
      width: 1,
      height: 1,
      fill_color: {
        field: 'z',
        transform: color
      },
      line_color: 'white'
    });

    plot.add_glyph(rect, source);

    let columns;

    if(preview) {
      plot.xaxis[0].visible = false;
      plot.yaxis[0].visible = false;

      columns = plot;
    } else {
      plot.toolbar.logo = null;

      plot.xaxis[0].axis_label = titles[xAxis];
      plot.yaxis[0].axis_label = titles[yAxis];

      const tickFormatter = new CustomTickFormatter('.4~s');

      plot.xaxis[0].formatter = tickFormatter;
      plot.yaxis[0].formatter = tickFormatter;

      const color_bar = new Bokeh.ColorBar({
        color_mapper: color,
        location: [0, 0],
        ticker: new Bokeh.BasicTicker({ desired_num_ticks: palette.length }),
        label_standoff: 3,
        formatter: tickFormatter,
        major_label_text_align: 'left'
      });

      const units = getUnitsFromColumnTitle(titles[zAxis]);

      const info = new Bokeh.Row({
        children: [
          new Bokeh.Widgets.PreText({
            text: [
              `MIN: ${formatChartSummaryValues(min(z), units, 14)}`,
              `MAX: ${formatChartSummaryValues(max(z), units)}`,
              '\n',
              `AVG: ${formatChartSummaryValues(mean(z), units, 14)}`,
              `STD: ${formatChartSummaryValues(deviation(z), units)}`
            ].join('')
          })
        ],
        height: 48,
        align: 'center'
      });

      plot.add_layout(color_bar, 'right');

      columns = new Bokeh.Column({
        children: [
          plot,
          info
        ],
        sizing_mode: 'stretch_width'
      });
    }

    Bokeh.Plotting.show(columns, this.chartContainerRef);
  }

  render() {
    return (
      <ChartContainer
        ref={ref => { this.chartContainerRef = ref; }}
        className={this.props.preview && 'preview'}
      />
    );
  }
}

Heatmap.propTypes = {
  data: PropTypes.array.isRequired,
  preview: PropTypes.bool,
  xAxis: PropTypes.number,
  yAxis: PropTypes.arrayOf(PropTypes.number),
  zAxis: PropTypes.number
};

Heatmap.defaultProps = {
  xAxis: 0,
  yAxis: [1],
  zAxis: 2
};

export default Heatmap;
