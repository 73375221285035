import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@mui/material/Checkbox';

import { LabelStyled, TypographyStyled, ListItemWrapper } from './styles';

const ListItem = forwardRef(({
  onChange,
  title,
  tooltip,
  number,
  checked,
  intermediate,
  additionalStyles,
  disabled
}, ref) => {

  return (
    <ListItemWrapper>
      <LabelStyled
        ref={ref}
        sx={additionalStyles}
        title={tooltip ?? title}
        control={
          <Checkbox
            onChange={onChange}
            checked={checked}
            indeterminate={intermediate}
            disabled={disabled}
          />
        }
        label={title}
      />
      {number > 0 ?
        <TypographyStyled>
          {` - ${number}`}
        </TypographyStyled>
        : null
      }
    </ListItemWrapper>
  );
});

ListItem.propTypes = {
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  number: PropTypes.number,
  checked: PropTypes.bool.isRequired,
  intermediate: PropTypes.bool,
  additionalStyles: PropTypes.object,
  depth: PropTypes.number,
  disabled: PropTypes.bool,
};

export default ListItem;
