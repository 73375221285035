import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { generatePath, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { gql, useMutation } from '@apollo/client';

import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import DialogComponent from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import MoveObjectDialogContent from './MoveObjectDialogContent';

import { routes } from '../../../../services/session/constants';
import {
  openAppSnackbarNotification
} from '../../../../services/snackbar-notifications/actions';

const MOVE_ITEM_OBJECT = gql`
  mutation UpdateTableItemFile($updateTableItemFileId: ID!, $file: UpdateTableItemFileInput!) {
    updateTableItemFile(id: $updateTableItemFileId, file: $file) {
      id
    }
  }
`;

const useStyles = makeStyles({
  dialogPaper: {
    overflow: 'visible',
  }
});

const MoveObjectDialog = ({
  onClose,
  onSuccess,
  type,
  id,
  itemId,
  objectTitle,
}) => {
  const [item, setItem] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  const [moveObject, { loading: isMoving }] = useMutation(MOVE_ITEM_OBJECT);

  const handleSubmit = useCallback(async () => {
    const { value: selectedItemId } = item;

    try {
      if (itemId !== selectedItemId) {
        await moveObject({
          variables: {
            updateTableItemFileId: id,
            file: {
              tableItemId: selectedItemId
            }
          }
        });
      }

      onSuccess({
        originItemId: itemId,
        targetItemId: selectedItemId,
      });

      dispatch(
        openAppSnackbarNotification({
          message: (
            <>
              {`The ${type.toLowerCase()} was moved to item `}

              <Link to={generatePath(routes.ITEMS, { sampleId: selectedItemId })} style={{ color: 'white' }}>
                {item.code}
              </Link>
            </>
          )
        })
      );
    } catch (error) {
      dispatch(
        openAppSnackbarNotification({
          variant: 'ERROR',
          message: error.message
        })
      );
    }
  }, [
    item,
    moveObject,
    itemId,
    id,
    onSuccess,
    type,
    dispatch,
  ]);

  return (
    <DialogComponent
      open
      onClose={onClose}
      fullWidth
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>
        <div className="title-container">
          <div className="primary-title">
            Move {type.toLowerCase()} "{objectTitle}"
          </div>
        </div>
      </DialogTitle>

      <MoveObjectDialogContent
        classes={classes}
        onItemSelect={setItem}
        item={item}
      />

      <DialogActions sx={{ p: 2 }}>
        <Button
          onClick={onClose}
          color="primary"
          disabled={isMoving}
        >
          cancel
        </Button>

        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={isMoving}
        >
          yes, move
        </Button>
      </DialogActions>
    </DialogComponent>
  );
};

export default MoveObjectDialog;

MoveObjectDialog.propTypes = {
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  id: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  objectTitle: PropTypes.string.isRequired,
};
