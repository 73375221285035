import React from 'react';
import PropTypes from 'prop-types';

import SingleFolder from '../SingleFolder';
import SingleTable from '../SingleTable';

const ExtendedListItem = ({
  isFolder,
  ...props
}) => {
  if (isFolder)
    return <SingleFolder {...props} />;

  return <SingleTable {...props} />;
};

ExtendedListItem.propTypes = {
  isFolder: PropTypes.bool,
};

export default ExtendedListItem;
