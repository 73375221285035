import React, {
  useRef,
  useCallback,
  memo,
  useState,
  useLayoutEffect
} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import MenuList from './MenuList';
import Input from './Input';
import SingleValue from './SingleValue';

import { OPTION_HEIGHT } from './constants';

import SearchableSelectStyled from './styles';

const MENU_MARGIN = 20;
const EMPTY_MENU_HEIGHT = 50;

const SearchableSelect = ({
  options,
  onSelect,
  onInputChange,
  onMenuClose,
  maxVisibleItemsCount,
  placement,
  visibleAmount,
  ...props
}) => {
  const selectRef = useRef();
  const inputRef = useRef();

  const calculateHeight = useCallback(() => {
    if (!options.length)
      return EMPTY_MENU_HEIGHT;

    const bottomPosition = Math.round(inputRef.current?.getBoundingClientRect()?.bottom) || null;
    const bottomMargin = window.innerHeight - bottomPosition;
    const possibleMaxHeight = getMaxItemsCount(visibleAmount, maxVisibleItemsCount) * OPTION_HEIGHT;
    const maxHeight = bottomPosition === null
      ? possibleMaxHeight
      : Math.min(bottomMargin, possibleMaxHeight);

    return maxHeight - MENU_MARGIN;
  }, [options.length, visibleAmount, maxVisibleItemsCount]);

  const [menuHeight, setMenuHeight] = useState(calculateHeight);

  const handleMenuClose = useCallback(() => {
    const isSearchingMode = Boolean(inputRef.current.value);

    onMenuClose(isSearchingMode);
  }, [onMenuClose]);

  useLayoutEffect(() => {
    const newMenuHeight = calculateHeight();

    if (newMenuHeight !== menuHeight) {
      setMenuHeight(newMenuHeight);
    }
  }, [calculateHeight, menuHeight]);

  return (
    <SearchableSelectStyled placement={placement}>
      <Select
        {...props}

        menuPosition="fixed"
        ref={selectRef}
        inputRef={inputRef}
        classNamePrefix="select"
        options={options}
        onChange={onSelect}
        onInputChange={onInputChange}
        styles={{
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 12,
            top: null,
            left: 24,
          })
        }}
        onMenuClose={handleMenuClose}
        menuHeight={menuHeight}
        backspaceRemovesValue
        components={{
          MenuList,
          Input,
          SingleValue
        }}
        menuPlacement="auto"
      />
    </SearchableSelectStyled>
  );
};

SearchableSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.array,
  onInputChange: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  maxVisibleItemsCount: PropTypes.number.isRequired,
  placement: PropTypes.oneOf(['bottom']),
  visibleAmount: PropTypes.number.isRequired,
};

export default memo(SearchableSelect);

function getMaxItemsCount(amount, max) {
  return amount < max
    ? (
      amount
     || 1 /* to keep a place for "No Items" message */
    )
    : max;
}
