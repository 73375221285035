import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';
import withStyles from '@mui/styles/withStyles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useReactToPrint } from 'react-to-print';

import { QR_CODE_TITLE_SIZE } from '../../services/users/constants';
import { browserStorage } from '../../utils/common';
import { useDidUpdateEffect } from '../../utils/hooks';
import CustomOptions from './CustomOptions';
import MainActions from './MainActions';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px'
  },
  closeButton: {
    width: '10px',
    height: '10px',
    marginLeft: '30px',
    color: '#E0E0E0',
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  title: {
    padding: '10px'
  },
  imageContainer: {
    display: 'flex',
    overflowY: 'auto',

    '& > *': {
      margin: 'auto'
    }
  }
};

const CANVAS_WIDTH = 325;

const QRCodeDialog = ({
  open,
  itemLink,
  onClose,
  classes,
  primaryTitle,
  secondaryTitle
}) => {
  const [titleSize, setTitleSize] = useState(
    +browserStorage.get(QR_CODE_TITLE_SIZE) || 30
  );
  const [saveAsDefault, setSaveAsDefault] = useState(
    Boolean( browserStorage.get(QR_CODE_TITLE_SIZE) )
  );
  const [drawedCanvas, setDrawedCanvas] = useState(null);

  const canvasContainerRef = useRef(null);

  useEffect(() => {
    if (drawedCanvas) {
      generateAndDrawQRCode(
        itemLink,
        titleSize,
        drawedCanvas,
        primaryTitle,
        secondaryTitle
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawedCanvas, titleSize]);

  useDidUpdateEffect(() => {
    if (saveAsDefault) {
      browserStorage.set(QR_CODE_TITLE_SIZE, titleSize);
    } else {
      browserStorage.delete(QR_CODE_TITLE_SIZE);
    }
  }, [saveAsDefault, titleSize]);

  const handlePrint = useReactToPrint({
    content: () => canvasContainerRef.current
  });

  const handleFontSizeChange = useCallback(e => {
    setTitleSize(e.target.value);
  }, []);

  const handleSaveAsDefaultChange = useCallback(e => {
    setSaveAsDefault(e.target.checked);
  }, []);

  return (
    <Dialog
      id="qr-code-dialog"
      name="qr-code"
      open={open}
      onClose={onClose}
    >
      <div
        className={classes.root}
      >
        <DialogTitle
          onClose={onClose}
          className={classes.title}
        >
          Quick Response (QR) Code
        </DialogTitle>
      </div>

      <div className={classes.imageContainer}>
        <div ref={canvasContainerRef}>
          <canvas
            width={CANVAS_WIDTH}
            height={CANVAS_WIDTH}
            ref={el => {
              if (el && !drawedCanvas) {
                setDrawedCanvas(el);
              }
            }}
          />
        </div>
      </div>

      <CustomOptions
        value={titleSize}
        onChange={handleFontSizeChange}
        saveAsDefault={saveAsDefault}
        onSaveAsDefaultChange={handleSaveAsDefaultChange}
      />

      <MainActions
        itemLink={itemLink}
        onPrint={handlePrint}
        onClose={onClose}
      />
    </Dialog>
  );
};

QRCodeDialog.propTypes = {
  itemLink: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  primaryTitle: PropTypes.string.isRequired,
  secondaryTitle: PropTypes.string.isRequired,
};

export default withStyles(styles)(QRCodeDialog);

function separateIntoLines(ctx, word, maxWidth) {
  const elementWidth = ctx.measureText(word).width;
  const elementAndLimitDiff = elementWidth - maxWidth;

  if (elementAndLimitDiff > 0) {
    const singleLeterWidth = elementWidth / word.length;
    const lettersCount = Math.ceil(elementAndLimitDiff / singleLeterWidth);
    const thresholdIndex = word.length - lettersCount;

    return [
      word.slice(0, thresholdIndex),
      ...separateIntoLines(ctx, word.slice(thresholdIndex), maxWidth),
    ];
  }

  return [word];
}

function textSplitIntoLines (ctx, value, max) {
  return value.replace(/[\r\n]+/g, ' ')
    .split(' ')
    .reduce((result, word) => {
      if (!result.length) {
        return result.concat(
          separateIntoLines(ctx, word, max)
        );
      }

      const lastLineIdx = result.length - 1;
      const lastLine = result[lastLineIdx] + ` ${word}`;
      const newLines = separateIntoLines(ctx, lastLine, max);

      newLines.forEach((i, idx) => {
        result[lastLineIdx + idx] = i;
      });

      return result;
    }, []);
}

function drawCanvasWithText (imageSrc, titleSize, canvas, title, secondary) {
  const lineHeight = titleSize;
  const topPadding = 15;
  const linesSpaceHeight = 5;
  const bottomPadding = 15;
  const qrCodeMargin = 15;
  const qrCodeDim = 205;
  const maxTextWidth = qrCodeDim - 20;
  const ctx = canvas.getContext('2d');

  ctx.font = `${titleSize}px sans-serif`;

  const wrappedTitle = textSplitIntoLines(ctx, title, maxTextWidth);
  const wrappedLinesNum = wrappedTitle.length;
  const titleHeight = lineHeight * wrappedLinesNum
    + (wrappedLinesNum - 1) * linesSpaceHeight;
  const canvasHeight = topPadding + titleHeight
    + 2 * qrCodeMargin + qrCodeDim + lineHeight + bottomPadding;

  ctx.canvas.height = canvasHeight;

  wrappedTitle.forEach((i, idx) => {
    ctx.font = `bold ${titleSize}px sans-serif`;
    ctx.fillStyle = '#000';
    ctx.textAlign = 'center';
    ctx.fillText(i, CANVAS_WIDTH / 2, topPadding + lineHeight * (idx + 1));
  });

  ctx.font = `${titleSize}px sans-serif`;
  ctx.fillStyle = '#808080';
  ctx.textAlign = 'center';
  ctx.fillText(secondary, CANVAS_WIDTH / 2, canvasHeight - bottomPadding);

  const img = new Image();

  img.onload = () => {
    ctx.drawImage(
      img,
      60,
      60,
      qrCodeDim,
      qrCodeDim,
      (CANVAS_WIDTH / 2) - (qrCodeDim / 2),
      topPadding + titleHeight + qrCodeMargin,
      qrCodeDim,
      qrCodeDim
    );
  };

  img.src = imageSrc;
}

const generateQR = async (text, opts = {}) => {
  try {
    return await QRCode.toDataURL(text, opts);
  } catch (err) {
    console.error(err);
    return null;
  }
};

async function generateAndDrawQRCode(
  link,
  titleSize,
  canvas = {},
  primary,
  secondary
) {
  const qrCodeUrl = await generateQR(link, {
    errorCorrectionLevel: 'Q',
    width: CANVAS_WIDTH,
    margin: 15
  });

  drawCanvasWithText(
    qrCodeUrl,
    titleSize,
    canvas,
    primary,
    secondary
  );
}
