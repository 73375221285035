export const createCell = (table, column, row) => {
  const cell = {
    id: `${column.id}_${row.id}`,
    row,
    column,
    getValue: () => row.getValue(column.id),
    getContext: () => ({
      table,
      row,
      column,
      cell,
      getValue: cell.getValue
    })
  };

  return cell;
};
