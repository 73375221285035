import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import { Container } from './styles';

import { parseToExponential } from '../../../../../../utils';
import Tooltip from '@mui/material/Tooltip';

const QuantityValue = ({ value, unit }) => {
  const shortenedQuantity = useMemo(() => {
    return typeof value === 'number' ?
      parseToExponential(value)  : '';
  }, [value]);

  return (
    <Container>
      <Tooltip title={value}>
        <Typography
          sx={{
            fontSize: '14px'
          }}
        >
          {shortenedQuantity}
        </Typography>
      </Tooltip>

      {typeof value === 'number' ?
        <Typography
          sx={{
            fontSize: '14px'
          }}
        >
          {unit?.name}
        </Typography> :
        null
      }
    </Container>
  );
};

QuantityValue.propTypes = {
  value: PropTypes.number,
  unit: PropTypes.shape({
    name: PropTypes.string
  })
};

export default QuantityValue;
