import React from 'react';
import PT from 'prop-types';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';

const MessageField = ({
  value,
  onChange
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel
        variant="outlined"
        disableAnimation
        shrink
        sx={{
          transform: 'translate(0px, 0px) scale(1)',
          fontSize: '12px',
          lineHeight: '20px',
          color: 'rgba(0,0,0,.6)'
        }}
      >
        Message
      </InputLabel>

      <InputBase
        value={value}
        onChange={onChange}
        multiline
        minRows="2"
        sx={{
          mt: '22px',
          px: '10px',
          py: '6px',
          background: 'white',
          border: '1px solid #DCDBDC',
          borderRadius: '4px',
          '.MuiInputBase-input': {
            padding: 0
          },
          fontSize: '14px'
        }}
      />
    </FormControl>
  );
};

MessageField.propTypes = {
  label: PT.string,
  value: PT.string,
  onChange: PT.func
};

export default MessageField;
