import { gql } from '@apollo/client';

export const APPLICATION = gql`
  query App($id: ID!) {
    application(id: $id) {
      id
      url
      title
      displayLoading
    }
  }
`;

export const VIEWER_KEYS = gql`
  query ViewerKeys {
    viewer {
      id
      apiKey
    }
  }
`;
