import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { ButtonStyled } from './styles';

const POSITION = {
  OPEN_POSITION: 10,
  CLOSE_POSITION: 8,
};

const ToggleButton = ({ open, onToggle, panelWidth }) => {

  const buttonPosition = useMemo(() => {
    return open ? panelWidth - POSITION.OPEN_POSITION : POSITION.CLOSE_POSITION;
  }, [open, panelWidth]);

  return (
    <ButtonStyled
      onClick={onToggle}
      variant="outlined"
      position={buttonPosition}
      open={open}
    >
      {open ?
        <ArrowBackIosIcon /> :
        <ArrowForwardIosIcon />
      }
    </ButtonStyled>
  );
};

ToggleButton.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  panelWidth: PropTypes.number.isRequired,
};

export default ToggleButton;
