import { useReducer, useCallback } from 'react';

import { getDefaultGraphics } from '../../../../ChartSettingsDialog';
import { withDefaultsAndAxes, getGraphicsViewType } from '../../../../../services/graphics/utils';

export const FORM_FIELDS = {
  ID: 'id',
  TITLE: 'title',
  DESCRIPTION: 'description',
  SOURCE_FILE: 'sourceFile',
  PARSING_CONFIG: 'parsingConfig',
  PROCESSED_DATA: 'processedData',
  GRAPHICS: 'graphics'
};

const createInitialState = data => {
  let initGraphics = [];

  if(data) {
    if(data.graphics?.length) {
      initGraphics = data.graphics.map(({
        config,
        graphicsType: type,
        ...rest
      }) => ({
        ...rest,
        type,
        config: withDefaultsAndAxes(
          config,
          getGraphicsViewType(type),
          rest.alter
        )
      }));
    } else if(data.parsingConfig && data.processedFileDownloadURL) {
      initGraphics.push({
        ...getDefaultGraphics(
          data.parsingConfig.parser.viewType
        ).default,
        id: window.crypto.randomUUID()
      });
    }
  }

  return {
    [FORM_FIELDS.ID]: data?.id ?? null,
    [FORM_FIELDS.TITLE]: {
      value: data?.title ?? '',
      error: null
    },
    [FORM_FIELDS.DESCRIPTION]: {
      value: data?.description ?? '',
      error: null
    },
    [FORM_FIELDS.SOURCE_FILE]: {
      value: data?.rawFileDownloadURL ?
        {
          id: data.id,
          downloadURL: data.rawFileDownloadURL,
          filename: data.rawFilename
        } :
        null,
      error: null
    },
    [FORM_FIELDS.PARSING_CONFIG]: {
      value: data?.parsingConfig ?? null,
      error: null
    },
    [FORM_FIELDS.PROCESSED_DATA]: {
      value: null,
      error: null
    },
    [FORM_FIELDS.GRAPHICS]: {
      value: initGraphics,
      error: null
    },
  };
};

const actions = {
  SET_VALUE: 'SET_VALUE',
  SET_ERROR: 'SET_ERROR',
  ADD_GRAPHICS: 'ADD_GRAPHICS',
  SET_GRAPHICS: 'SET_GRAPHICS',
  SET_GRAPHICS_TYPE: 'SET_GRAPHICS_TYPE',
  SET_GRAPHICS_CONFIG: 'SET_GRAPHICS_CONFIG',
  REMOVE_GRAPHICS: 'REMOVE_GRAPHICS',
};

const setValueReducer = (state, action) => {
  const _state = { ...state };

  switch(action.payload.field) {
    case FORM_FIELDS.TITLE:
    case FORM_FIELDS.DESCRIPTION:
    case FORM_FIELDS.PROCESSED_DATA:
      Object.assign(_state, {
        [action.payload.field]: {
          ..._state[action.payload.field],
          value: action.payload.value,
          error: null
        }
      });
      break;

    case FORM_FIELDS.SOURCE_FILE: {
      if(action.payload.value === null) {
        Object.assign(_state, {
          [FORM_FIELDS.SOURCE_FILE]: {
            ..._state[FORM_FIELDS.SOURCE_FILE],
            value: null,
            error: null
          },
          [FORM_FIELDS.PARSING_CONFIG]: {
            ..._state[FORM_FIELDS.PARSING_CONFIG],
            value: null,
            error: null
          },
          [FORM_FIELDS.PROCESSED_DATA]: {
            ..._state[FORM_FIELDS.PROCESSED_DATA],
            value: null,
            error: null
          },
          [FORM_FIELDS.GRAPHICS]: {
            ..._state[FORM_FIELDS.GRAPHICS],
            value: [],
            error: null
          }
        });
      }

      Object.assign(_state, {
        [FORM_FIELDS.SOURCE_FILE]: {
          ..._state[FORM_FIELDS.SOURCE_FILE],
          value: action.payload.value,
          error: null
        }
      });
      break;
    }

    case FORM_FIELDS.PARSING_CONFIG:
      Object.assign(_state, {
        [FORM_FIELDS.PARSING_CONFIG]: {
          ..._state[FORM_FIELDS.PARSING_CONFIG],
          value: action.payload.value,
          error: null
        },
        [FORM_FIELDS.PROCESSED_DATA]: {
          ..._state[FORM_FIELDS.PROCESSED_DATA],
          value: null,
          error: null
        },
        [FORM_FIELDS.GRAPHICS]: {
          ..._state[FORM_FIELDS.GRAPHICS],
          value: [],
          error: null
        }
      });
      break;

    default:
      return state;
  }

  return _state;
};

const addGraphicsReducer = (state, action) => ({
  ...state,
  [FORM_FIELDS.GRAPHICS]: {
    ...state[FORM_FIELDS.GRAPHICS],
    value: [
      ...state[FORM_FIELDS.GRAPHICS].value,
      action.payload.value
    ]
  }
});

const setGraphicsReducer = (state, action) => {
  const idx = state[FORM_FIELDS.GRAPHICS].value.findIndex(
    ({ id }) => id === action.payload.id
  );

  return {
    ...state,
    [FORM_FIELDS.GRAPHICS]: {
      ...state[FORM_FIELDS.GRAPHICS],
      value: [
        ...state[FORM_FIELDS.GRAPHICS].value.slice(0, idx),
        action.payload.value,
        ...state[FORM_FIELDS.GRAPHICS].value.slice(idx + 1),
      ]
    }
  };
};

const setGraphicsConfigReducer = (state, action) => {
  const idx = state[FORM_FIELDS.GRAPHICS].value.findIndex(
    ({ id }) => id === action.payload.id
  );
  const graphics = state[FORM_FIELDS.GRAPHICS].value[idx];

  return {
    ...state,
    [FORM_FIELDS.GRAPHICS]: {
      ...state[FORM_FIELDS.GRAPHICS],
      value: [
        ...state[FORM_FIELDS.GRAPHICS].value.slice(0, idx),
        {
          ...graphics,
          config: action.payload.value
        },
        ...state[FORM_FIELDS.GRAPHICS].value.slice(idx + 1),
      ]
    }
  };
};

const removeGraphicsReducer = (state, action) => {
  const idx = state[FORM_FIELDS.GRAPHICS].value.findIndex(
    ({ id }) => id === action.payload.id
  );

  return {
    ...state,
    [FORM_FIELDS.GRAPHICS]: {
      ...state[FORM_FIELDS.GRAPHICS],
      value: [
        ...state[FORM_FIELDS.GRAPHICS].value.slice(0, idx),
        ...state[FORM_FIELDS.GRAPHICS].value.slice(idx + 1),
      ]
    }
  };
};

const setErrorReducer = (state, action) => {
  return {
    ...state,
    [action.payload.field]: {
      ...state[action.payload.field],
      error: action.payload.error
    }
  };
};

const reducer = (state, action) => {
  switch(action.type) {
    case actions.SET_VALUE:
      return setValueReducer(state, action);

    case actions.ADD_GRAPHICS:
      return addGraphicsReducer(state, action);

    case actions.SET_GRAPHICS:
      return setGraphicsReducer(state, action);

    case actions.SET_GRAPHICS_CONFIG:
      return setGraphicsConfigReducer(state, action);

    case actions.REMOVE_GRAPHICS:
      return removeGraphicsReducer(state, action);

    case actions.SET_ERROR:
      return setErrorReducer(state, action);

    default:
      return state;
  }
};

const useMeasurementForm = (initData) => {
  const [state, dispatch] = useReducer(reducer, initData, createInitialState);

  const setValue = useCallback((field, value) => {
    dispatch({
      type: actions.SET_VALUE,
      payload: {
        field,
        value
      }
    });
  }, []);

  const addGraphics = useCallback(viewType => {
    const { default: value } = getDefaultGraphics(viewType);

    value.id = window.crypto.randomUUID();

    dispatch({
      type: actions.ADD_GRAPHICS,
      payload: { value }
    });
  }, []);

  const setGraphics = useCallback((id, value) => {
    dispatch({
      type: actions.SET_GRAPHICS,
      payload: { id, value }
    });
  }, []);

  const setGraphicsType = useCallback((id, viewType) => {
    const { default: value } = getDefaultGraphics(viewType);

    value.id = id;

    dispatch({
      type: actions.SET_GRAPHICS,
      payload: { id, value }
    });
  }, []);

  const setGraphicsConfig = useCallback((id, value) => {
    dispatch({
      type: actions.SET_GRAPHICS_CONFIG,
      payload: { id, value }
    });
  }, []);

  const removeGraphics = useCallback(id => {
    dispatch({
      type: actions.REMOVE_GRAPHICS,
      payload: { id }
    });
  }, []);

  const validate = useCallback(() => {
    let valid = true;

    if(!state[FORM_FIELDS.TITLE].value.trim().length) {
      dispatch({
        type: actions.SET_ERROR,
        payload: {
          field: FORM_FIELDS.TITLE,
          error: 'The title field is required.'
        }
      });

      valid = false;
    }

    if(!state[FORM_FIELDS.SOURCE_FILE].value) {
      dispatch({
        type: actions.SET_ERROR,
        payload: {
          field: FORM_FIELDS.SOURCE_FILE,
          error: 'Please upload the measurement file.'
        }
      });

      valid = false;
    }

    return valid;
  }, [state]);

  const setError = useCallback((field, error) => {
    dispatch({
      type: actions.SET_ERROR,
      payload: {
        field,
        error
      }
    });
  }, []);

  return {
    state,
    actions: {
      setValue,
      setError,
      addGraphics,
      setGraphics,
      setGraphicsType,
      setGraphicsConfig,
      removeGraphics,
      validate
    }
  };
};

export default useMeasurementForm;
