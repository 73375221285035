import { gql } from '@apollo/client';

export const TABLES = gql`
  query Tables {
    tables {
      id
      title
      hash
      description
      folderId
      dateUpdated
      viewerMaxRole
      path {
        title
        id
      }
      numberOfTableParameters
      numberOfTableItems
      numberOfUsers
    }
  }
`;

export const FOLDERS = gql`
  query AllFolders {
    folders {
      id
      title
      parentId
      viewerMaxRole
      childFolders {
        id
        title
        parentId
        description
        dateCreated
        dateUpdated
        viewerRole
        viewerMaxRole
      }
      iam {
        users {
          userId
        }
      }
      dateCreated
      dateUpdated
      viewerRole
      viewerMaxRole
      path {
        id
        title
      }
    }
  }
`;
