import styled from 'styled-components';

const ProfileDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 420px;

  .full-name {
    display: flex;
    .first-name {
      margin-right: 12px;
    }
  }

  .form-control {
    width: 100%;
    margin-bottom: 12px;
  }

  label ~ .form-control {
    margin-bottom: 0;
  }

  .input-base-control {
    label + * {
      margin-top: 18px;
      border: 1px solid rgba(0, 0, 0, 0.26);
      border-radius: 2px;
    }

    input {
      height: 42px;
      box-sizing: border-box;
      padding-left: 6px;
    }
  }

  .phone-numbers {
    display: flex;

    .phone-number {
      margin-right: 12px;
    }
  }

  .section-actions > button:not(:last-child) {
    margin-right: 12px;
  }
`;

export default ProfileDetailsContainer;
