import React, { useCallback, useEffect, useRef } from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';

import { placeTailCursor } from '../utils';

import { InputStyled } from '../styles';

const EditTextValue = ({ onChange, data, editMode, onCancel }) => {
  const inputRef = useRef();

  useEffect(() => {
    if (editMode === 1) {
      inputRef.current.select();
    } else {
      placeTailCursor(inputRef.current);
    }
  }, [editMode]);

  const handleChange = useCallback((e) => {
    onChange(e.target.value);
  }, [onChange]);

  return (
    <Box>
      <InputStyled
        value={data}
        inputRef={inputRef}
        autoFocus
        onChange={handleChange}
        endAdornment={
          <CloseIcon
            onClick={onCancel}
            size="small"
            sx={{
              color: '#8C8C8C',
              fontSize: 18,
              cursor: 'pointer'
            }}
          />
        }
      />
    </Box>
  );
};

EditTextValue.propTypes = {
  onCancel: PT.func.isRequired,
  onChange: PT.func.isRequired,
  editMode: PT.number.isRequired,
  data: PT.string.isRequired
};

export default EditTextValue;
