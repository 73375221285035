import styled from 'styled-components';

import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { COLOR_GREY_LIGHTER } from '../../../../styles';

export const ListItemWrapper = styled(Box)`
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  overflow: hidden
`;

export const LabelStyled = styled(FormControlLabel)`
  margin: 0;
  height: 28px;
  overflow: hidden;
  
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 20px;
    padding: 0;
    min-width: 24px;
  }
  
  svg {
    height: 16px;
  }
`;

export const TypographyStyled = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  color: ${COLOR_GREY_LIGHTER};
  margin-left: 4px;
  white-space: nowrap
`;
