import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const VideoTooltip = ({
  primaryText,
  onClick,
  onHover,
  disabled,
  children,
  ...props
}) => (
  <Tooltip
    enterDelay={300}
    arrow
    {...props}
    title={
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px'
        }}
      >
        <Typography
          sx={{
            fontSize: '12px'
          }}
        >
          {primaryText}
        </Typography>

        {!disabled ?
          <Typography
            onClick={onClick}
            onMouseOver={onHover}
            color="primary"
            sx={{
              fontSize: '12px',
              textDecoration: 'underline',
              '&:hover': {
                cursor: 'pointer'
              }
            }}
          >
            Learn more
          </Typography>
          : null
        }
      </Box>
    }
  >
    {children}
  </Tooltip>
);

VideoTooltip.propTypes = {
  primaryText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onHover: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default VideoTooltip;
