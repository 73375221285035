import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Avatar from '../../../../components/Avatar';
import { formatDate } from '../../../../services/teams/utils';
import { Subtext } from './styles';

const SingleMember = ({ name = 'name', color, src, lastActivity }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '60px' }}>
      <Avatar
        name={name}
        color={color}
        size={32}
        src={src}
      />

      <Box display="flex" flexDirection="column" ml="8px">
        <Typography>
          {name}
        </Typography>

        <Subtext>
          {formatDate(lastActivity)}
        </Subtext>
      </Box>
    </Box>
  );
};

SingleMember.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  src: PropTypes.string,
  lastActivity: PropTypes.number,
};

export default SingleMember;
