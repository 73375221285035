import styled from 'styled-components';

export default styled.div`
  label + * {
    margin-top: 18px;
    border: 1px solid rgba(0, 0, 0, 0.26);
    border-radius: 2px;
    width: 100%;
    box-sizing: border-box;
  }

  input {
    height: 42px;
    box-sizing: border-box;
    padding-left: 6px;
  }
`;
