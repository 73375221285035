import { useMemo, useRef, useCallback } from 'react';

import {
  createColumn,
  createHeaderGroups,
  createRow
} from './core';
import useTableState from './table-state';

const _isChildrenVisible = (state, children) => {
  if(!children.length)
    return true;

  return children.some(child => {
    return (state[child.id]?.visible ?? true) && _isChildrenVisible(state, child.columns);
  });
};

const _isColumnVisible = (state, column) => {
  if(!column)
    return true;

  return (state[column.id]?.visible ?? true) && _isChildrenVisible(state, column.columns);
};

const _isColumnCollapsed = (state, column) => {
  if(!column)
    return false;

  return state[column.id]?.collapsed || _isColumnCollapsed(state, column.parent);
};

const findMaxDepth = (columns, depth = 0, maxDepth = depth) => {
  for(const column of columns) {
    if(column.columns?.length)
      maxDepth = findMaxDepth(column.columns, depth + 1, maxDepth);
  }

  return Math.max(maxDepth, depth);
};

const useTable = ({ columns: columnDefs, data, customState }) => {

  const table = useRef({});

  const allColumns = useMemo(() => {
    const recurseColumns = (columnDefs, parent, depth = 0) => {
      if(!columnDefs?.length)
        return [];

      return columnDefs.map(columnDef => {
        const column = createColumn(table.current, columnDef, depth, parent);

        column.columns = recurseColumns(columnDef.columns, column, depth + 1);

        return column;
      });
    };

    return recurseColumns(columnDefs);
  }, [columnDefs]);

  const allColumnsById = useMemo(() => {
    const allColumnsFlat = allColumns.flatMap(column => column.getFlatColumns());

    return allColumnsFlat.reduce((acc, column) => {
      acc[column.id] = column;

      return acc;
    }, {});
  }, [allColumns]);

  const [
    tableState,
    {
      toggleVisibility,
      toggleCollapse,
      toggleChildrenVisibility
    }
  ] = useTableState({ columnsById: allColumnsById });

  const getColumn = useCallback(columnId => allColumnsById[columnId], [allColumnsById]);

  const isColumnVisible = useCallback(id => {
    return _isColumnVisible(tableState, getColumn(id));
  }, [tableState, getColumn]);

  const isColumnCollapsed = useCallback(id => {
    return _isColumnCollapsed(tableState, getColumn(id));
  }, [tableState, getColumn]);

  const visibleColumns = useMemo(() => {
    const recurseColumns = (columnDefs, parent, depth = 0) => {
      const result = [];

      if(!columnDefs?.length)
        return result;

      for(const columnDef of columnDefs) {
        const column = createColumn(table.current, columnDef, depth, parent);

        column.columns = recurseColumns(columnDef.columns, column, depth + 1);

        if(isColumnVisible(column.id))
          result.push(column);
      }

      return result;
    };

    return recurseColumns(columnDefs);
  }, [columnDefs, isColumnVisible]);

  const headerGroups = useMemo(() => {
    const maxDepth = findMaxDepth(visibleColumns);

    return createHeaderGroups(table.current, visibleColumns, maxDepth);
  }, [visibleColumns]);

  const leafColumns = useMemo(() => {
    return visibleColumns.flatMap(column => column.getLeafColumns());
  }, [visibleColumns]);

  const rows = useMemo(() => {
    const makeCreateRow = data => {
      const children = data.children;
      const opts = {};

      if (children) {
        opts.children = children.map(makeCreateRow);
      }

      return createRow(table.current, data.id, data, leafColumns, opts);
    };

    return data.map(makeCreateRow);
  }, [leafColumns, data]);

  Object.assign(table.current, {
    allColumns,
    visibleColumns,
    headerGroups,
    rows,
    getColumn,
    toggleColumnVisibility: toggleVisibility,
    toggleColumnCollapse: toggleCollapse,
    toggleColumnChildrenVisibility: toggleChildrenVisibility,
    isColumnVisible,
    isColumnCollapsed,
    customState
  });

  return table.current;
};

export default useTable;
