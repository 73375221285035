import { createSelector } from 'reselect';
import { get } from 'lodash';

// session
export const currentUser = state => state.session.currentUser;
export const getAuthUser = state => state.session.authUser;
export const getEmail = state => get(getAuthUser(state), 'email');
export const getCurrentUser = createSelector(
  [currentUser, getEmail],
  (user, email) => user ? { ...user, email } : null
);
export const getDefaultAccountId = ({ accounts: accountsCollection = {} }) => {
  let accounts = Object.keys(accountsCollection).map(id => ({
    ...accountsCollection[id],
    id
  }));

  let userDefaultAccount = accounts.find(({ isDefault }) => !!isDefault);

  return userDefaultAccount ? userDefaultAccount.id : null;
};
export const getUserDefaultAccountId = createSelector(currentUser, getDefaultAccountId);

