import React  from 'react';
import PropTypes from 'prop-types';
import ReactAvatar from 'react-avatar';
import { words } from 'lodash';
import AvatarStyled from './styles';
import { COLOR_PRIMARY } from '../../styles';

const Avatar = (props) => {

  const {
    name,
    size,
    onClick,
    round,
    color,
    src,
    textSizeRatio,
  } = props;

  const first2words = words(name).slice(0, 2).join(' ');

  return (
    <AvatarStyled
      {...props}
      onClick={onClick}
    >
      {src &&
        <div title={name}>
          <img
            src={src}
            alt=""
            style={{
              width: `${size}px`,
              height: `${size}px`
            }}
          />
        </div>
      }

      {!src && <ReactAvatar
        name={first2words}
        round={round}
        size={size}
        color={color || COLOR_PRIMARY}
        maxInitials={2}
        textSizeRatio={textSizeRatio}
        title={name}
      />}
    </AvatarStyled>
  );
};

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  onClick: PropTypes.func,
  round: PropTypes.bool,
  color: PropTypes.string,
  src: PropTypes.string,
  textSizeRatio: PropTypes.number
};

Avatar.defaultProps = {
  size: 75,
  round: true,
  name: 'none'
};

export default Avatar;
