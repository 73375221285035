import React, { useCallback } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { Link, generatePath } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';

import TableCell from '../TableCell/TableCell';

import { COLOR_PRIMARY } from '../../../../styles';
import { routes } from '../../../../services/session/constants';
import { TABS } from '../../../TableSettings/constants';

const iconStyles = {
  width: '100%',
  height: 'auto',
  color: '#8C8C8C'
};

const NameCell = ({
  className,
  row,
  table: {
    customState: {
      collapsedRows
    }
  }
}) => {
  const handleClick = useCallback(() => {
    collapsedRows.toggle(row.id);
  }, [collapsedRows, row.id]);

  const isCollapsed = Boolean(collapsedRows.byId[row.id] ?? true);
  const iconComponent = isCollapsed
    ? <ChevronRight sx={iconStyles} />
    : <ExpandMore sx={iconStyles} />;

  return (
    <TableCell className="name-cell" value>
      <Box
        onClick={handleClick}
        display="flex"
        alignItems="center"
        gap="5px"
        ml={`${row.data.path?.length * 15}px`}
      >
        <Box
          sx={{
            width: '16px',
            height: '16px',
          }}
        >
          {row.children?.length ? iconComponent : null}
        </Box>

        <Typography
          className={row.data.isFolder ? null : className}
          sx={{
            color: '#000000',
            textDecoration: 'none'
          }}
          variant="body2"
          fontWeight={row.data.isFolder ? '700' : '400'}
          component={row.data.isFolder ? 'p' : Link}
          to={row.data.isFolder ? null : generatePath(routes.TABLE_SETTINGS, { id: row.id })}
          state={row.data.isFolder ? null : { tab: TABS.STRUCTURE.value }}
          noWrap
        >
          {row.data.title}
        </Typography>
      </Box>
    </TableCell>
  );
};

NameCell.propTypes = {
  className: PT.string,
  row: PT.shape({
    id: PT.string.isRequired,
    data: PT.object.isRequired,
    children: PT.array
  }),
  table: PT.shape({
    customState: PT.shape({
      collapsedRows: PT.shape({
        byId: PT.object,
        toggle: PT.func.isRequired
      })
    })
  })
};

export default styled(NameCell)`
  :where(tr:hover &) {
    color: ${COLOR_PRIMARY};
    text-decoration: underline;
  }
`;
