class CustomLogTickFormatter extends window.Bokeh.LogTickFormatter {
  static type = 'LogTickFormatter';

  doFormat(ticks) {
    return ticks.map(tick => {
      const power = Math.round(Math.log(tick) / Math.log(10));
      return (power < -3 || power > 3) ? `10^${power}` : String(tick);
    });
  }
}

export default CustomLogTickFormatter;
