import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import TableCell from '../TableCell';
import EmptyValue from '../ParameterValue/EmptyValue';

import searchSplit from '../../utils/searchSplit';

const ItemDescription = ({ className, text, search }) => {

  const searchedText = useMemo(() => {
    if (!search || !text)
      return (
        <Typography
          variant="body2"
          noWrap
        >
          {text}
        </Typography>
      );

    const chunks = searchSplit(text, search);

    return (
      <>
        <Typography
          variant="body2"
          display="inline"
          noWrap
        >
          {chunks.map((chunk) => {
            if (chunk.search){
              return (
                <mark key={chunk.id}>
                  {chunk.text}
                </mark>
              );
            }

            return (
              <React.Fragment key={chunk.id}>
                {chunk.text}
              </React.Fragment>
            );
          })}
        </Typography>
      </>
    );
  }, [search, text]);

  if (!text) {
    return (
      <EmptyValue />
    );
  }

  return (
    <TableCell className={className}>
      <Tooltip title={text}>
        {searchedText}
      </Tooltip>
    </TableCell>
  );
};

ItemDescription.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  search: PropTypes.string
};

export default styled(memo(ItemDescription))`
  max-width: 1500px;
`;
