import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import SearchIcon from '@mui/icons-material/Search';

import { FormControlStyled, InputStyled } from './styles';

const Search = ({ onChange, value }) => {

  const handleSearch = useCallback((e) => onChange(e.target.value),
    [onChange]
  );

  return (
    <FormControlStyled
      fullWidth
    >
      <InputStyled
        placeholder="Search"
        onChange={handleSearch}
        value={value}
        endAdornment={
          <SearchIcon />
        }
      />
    </FormControlStyled>
  );
};

Search.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default Search;
