import React from 'react';
import PT from 'prop-types';

import MUIAvatar from '@mui/material/Avatar';

const Avatar = React.forwardRef(({
  name,
  src,
  size = 24,
  onClick,
  color,
  className,
  ...restProps
}, ref) => {
  if(src)
    return (
      <MUIAvatar
        ref={ref}
        className={className}
        src={src}
        alt={name}
        sx={{ width: size, height: size }}
        onClick={onClick}
        {...restProps}
      />
    );

  const [fname, lname] = name.split(' ');
  const initials = `${fname.charAt(0)}${lname.charAt(0)}`.toUpperCase();

  return (
    <MUIAvatar
      ref={ref}
      className={className}
      sx={{
        width: size,
        height: size,
        bgcolor: color,
        fontSize: '.75em'
      }}
      onClick={onClick}
      {...restProps}
    >
      {initials}
    </MUIAvatar>
  );
});

Avatar.propTypes = {
  name: PT.string.isRequired,
  src: PT.string,
  size: PT.number,
  onClick: PT.func,
  color: PT.string,
  className: PT.string
};

export default Avatar;
