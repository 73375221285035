import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';

import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

import { PasswordPolicy } from '../../../components/Auth';
import LoadingBanner from '../../../components/LoadingBanner';

import { routes } from '../../../services/session/constants';
import { resetPassword, verifyPasswordResetCode } from '../../../services';

import { COLOR_PRIMARY, COLOR_WHITE } from '../../../styles';

const ResetPassword = ({ oobCode, continueUrl }) => {

  const [error, setError] = useState(false);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleVerifyPasswordResetCode = useCallback(async () => {
    setLoading(true);

    const email = await verifyPasswordResetCode(oobCode);

    if (!email){
      setError(true);
    } else {
      setEmail(email);
    }

    setLoading(false);
  }, [oobCode]);

  useEffect(() => {
    handleVerifyPasswordResetCode();
  }, [handleVerifyPasswordResetCode]);

  const handleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleChangePassword = useCallback((e) => {
    setPassword(e.target.value);
  }, []);

  const handleSetNewPassword = useCallback(() => {

    const resp = resetPassword(email, oobCode, password, continueUrl);

    if (!resp){
      setError(true);
    } else {
      setSubmitted(true);
    }
  }, [continueUrl, email, oobCode, password]);

  if (loading)
    return (
      <LoadingBanner
        spinner
        centered
        color={COLOR_PRIMARY}
        bcgcolor={COLOR_WHITE}
      />);

  if (error)
    return (
      <CardContent>
        <Typography
          variant="h6"
          gutterBottom
        >
          Try resetting your password again
        </Typography>
        <Typography>
          Your request to reset password expired or the link has already been used
        </Typography>
      </CardContent>
    );

  if (submitted){
    return (
      <>
        <CardContent>
          <Typography
            variant="h6"
            gutterBottom
          >
            Password changed
          </Typography>
          <Typography>
            You can now sign in with your new password
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            component={Link}
            to={continueUrl || routes.SIGN_IN}
            variant="contained"
          >
            Continue
          </Button>
        </CardActions>
      </>
    );
  }

  return (
    <>
      <CardContent>
        <Typography
          variant="h6"
          gutterBottom
        >
          Reset your password
        </Typography>
        <FormControl
          variant="standard"
        >
          <InputLabel htmlFor="password">New password</InputLabel>
          <Input
            id="password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={handleChangePassword}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleShowPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText
            className="new-password-helper-text"
            component="div"
          >
            <PasswordPolicy />
          </FormHelperText>
        </FormControl>
      </CardContent>
      <CardActions>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleSetNewPassword}
        >
          Submit
        </Button>
      </CardActions>
    </>
  );
};

ResetPassword.propTypes = {
  oobCode: PropTypes.string.isRequired,
  continueUrl: PropTypes.string
};

export default ResetPassword;
