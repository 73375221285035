import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const styles = {
  button: {
    padding: 0,
    textTransform: 'none',
  }
};

const SuccessCreateMessage = ({ title, type, link }) => {
  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(`${window.location.origin}${link}`);
  }, [link]);

  return (
    <Box>
      <Typography variant="body2">
        {title} {type} was created.
      </Typography>

      <Box display="flex" pt="4px">
        <Button
          to={link}
          sx={styles.button}
          component={Link}
          target="_blank"
        >
          View {type}
        </Button>

        <Box mr="8px" ml="8px">
          <Typography color="primary">
            •
          </Typography>
        </Box>

        <Button
          sx={styles.button}
          onClick={handleCopyLink}
        >
          Copy link
        </Button>
      </Box>
    </Box>
  );
};

SuccessCreateMessage.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default SuccessCreateMessage;
