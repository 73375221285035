import { gql } from '@apollo/client/core';

export const tableParameterFragment = gql`
  fragment TableParameterFragment on TableParameter {
    id
    featureId
    title
    valueType
    titleTableItem {
      code
      id
      title
      viewerMaxRole
    }
    unit {
      id
      name
    }
    tableProtocol {
      id
      type
      tableId

      ...on TableProtocolFormulation {
        unit {
          id
          name
        }
      }
    }

    ... on TableParameterCalculation {
      calculation {
        contents {
        type
          children {
            id
            text
            type
            children {
              text
            }
          }
        }
      }
    }

    ... on TableParameterEnum {
      enumValueOptions {
        id
        title
      }
    }
  }
`;

export const tableValueFragment = gql`
  fragment TableValueFragment on TableValue {
    featureId
    tableParameterId
    tableItemId
    type

    ... on TableValueQuantity {
      quantity
    }

    ... on TableValueCalculation {
      quantity
      calculationError
    }

    ... on TableValueText {
      text
    }

    ... on TableValueBoolean {
      boolean
    }

    ... on TableValueLink {
      linkedTableItem {
        id
        code
        title
        viewerMaxRole
      }
    }

    ... on TableValueEnum {
      enumValues {
        id
        title
      }
    }
  }
`;

const GET_ITEM = gql`
  ${tableParameterFragment}
  ${tableValueFragment}
  query TableItem($itemId: ID!) {
    tableItem(id: $itemId) {
      id
      title
      code
      dateCreated
      dateUpdated
      dateFabricated
      description
      isUsedInProtocols
      numberOfTableItemFiles
      interactions {
        comments
        views
      }
      archived
      creator {
        id
        name
      }
      values {
        ...TableValueFragment
      }
      table {
        tableProtocols {
          id
          type
          title
          description
          dateUpdated
          dateCreated
          creator {
            id
            name
          }
          tableParameters {
            ...TableParameterFragment
          }

          ... on TableProtocolFormulation {
            titleTables {
              id
            }
            unit {
              id
              name
            }
          }
        }
        id
        title
        viewerMaxRole
        numberOfTableProtocols
      }
      files {
        id
        title
        description
        dateUpdated
        dateCreated
        creator {
          id
          name
        }
        processedFilename
        rawFileDownloadURL
        rawFilename
        processedFileDownloadURL
        parsingConfig {
          id
          code
          dateUsed
          measurement {
            id
            code
            name
          }
          instrument {
            id
            code
            model
            manufacturer {
              id
              code
              name
            }
          }
          parser {
            id
            code
            name
            runtime
            viewType
            dataType
          }
        }
        graphics {
          id
          mode
          graphicsType
          config {
            columns
            x1Index
            xAxis
            xIndex
            xOffsetIndex
            y1Index
            yAxis
            yAxisRight
            yIndex
            yOffsetIndex
            zAxis
          }
          alter {
            target
            transform
          }
        }
      }
    }
  }
`;

export default GET_ITEM;
