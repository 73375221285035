import React from 'react';
import PropTypes from 'prop-types';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import Typography from '@mui/material/Typography';

const NotificationsTimestamp = ({ timestamp }) => {
  return (
    <Typography sx={{ marginTop: '7px', fontSize: '13px', color: '#a8a8a8' }}>
      {`${formatDistanceToNow(timestamp)} ago`}
    </Typography>
  );
};

NotificationsTimestamp.propTypes = {
  timestamp: PropTypes.number.isRequired
};

export default NotificationsTimestamp;
