import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';

import { PARAMETER_METHODS } from './constants';

const METHOD_KEYS = Object.keys(PARAMETER_METHODS);

const useStyles = makeStyles({
  root: {
    height: 32,

    '& .MuiSelect-select': {
      fontSize: 14,
      paddingLeft: 7,
      paddingRight: 28,
      width: 52
    },

    '& .MuiSelect-icon': {
      width: '18px',
      height: '18px',
      top: 'calc(50% - 9px)'
    }
  }
});

const METHOD_LABELS = {
  [PARAMETER_METHODS.INCLUDE]: 'Include',
  [PARAMETER_METHODS.EXCLUDE]: 'Exclude'
};

const MethodsSelect = ({ value, onChange, disabled }) => {
  const classes = useStyles();

  const handleChange = useCallback(e => {
    onChange(e.target.value);
  }, [onChange]);

  return (
    <Box flexShrink={0}>
      <Box mt="0">
        <Select
          value={value}
          defaultValue={METHOD_KEYS[0]}
          onChange={handleChange}
          className={classes.root}
          disabled={disabled}
        >
          {METHOD_KEYS.map(i =>
            <MenuItem value={i} key={i}>
              {METHOD_LABELS[PARAMETER_METHODS[i]]}
            </MenuItem>
          )}
        </Select>
      </Box>
    </Box>
  );
};

MethodsSelect.propTypes = {
  value: PropTypes.oneOf(['INCLUDE', 'EXCLUDE']),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default MethodsSelect;
