import { gql } from '@apollo/client';

export const SAVED_VIEWS = gql`
  query UserTableItemQueries {
    userTableItemQueries {
      id
      tableItemQueryId
      dashboardId
      dashboardStateId
      title
    }
  }
`;
