import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

export const ActionStyled = styled(Paper)`
  padding: 12px 16px;
  margin-right: 8px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0.5px solid #DCDBDC;
  border-radius: 4px;
  cursor: pointer;
  gap: 8px;
  
  &:hover {
    background: linear-gradient(0deg, #DCDBDC, #DCDBDC),
    linear-gradient(0deg, #F5F5F5, #F5F5F5);
    box-shadow: 0px 3px 6px 0px #0000001A;
  }
`;

export const ActionIcon = styled(Box)`
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.color};
  border-radius: 50%;
`;
