import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import PT from 'prop-types';

import {
  useQuery
} from '@apollo/client';

import withStyles from '@mui/styles/withStyles';

import Page, { PageContent, PageSection } from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import ProfilePictureSettigs from './ProfilePictureSettings';
import ProfileDetails from './ProfileDetails';
import AuthSettings from './AuthSettings';
import ApiKeySettings from './ApiKeySettings';
import AffiliationInfo from './AffiliationInfo/AffiliationInfo';
import ResearchInfo from './ResearchInfo/ResearchInfo';

import {
  VIEWER_PROFILE
} from './services';
import { COUNTRY_LIST_GET } from './services';

import { openAppSnackbarNotification } from '../../services/snackbar-notifications/actions';
import useDocumentTitle from '../../hooks/document-title';

const styles = {
  userIdContainer: {
    fontSize: 14,
    marginBottom: 2,
    marginRight: 4,
    color: 'rgb(117,117,117)'
  }
};

const mapDispatchToProps = {
  openAppSnackbarNotification
};

function UserProfileSettings({ classes, openAppSnackbarNotification }) {

  useDocumentTitle('Profile Settings');

  const { loading, data, refetch } = useQuery(VIEWER_PROFILE);

  const { data: countriesResponse } = useQuery(COUNTRY_LIST_GET);

  const refetchViewer = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleSave = useCallback(() => {
    openAppSnackbarNotification({
      message: 'The information has been saved.',
      variant: 'INFO'
    });
  }, [openAppSnackbarNotification]);

  if(loading)
    return (
      <Page>
        <PageContent loading={loading}>
          <PageTitle>Profile Settings</PageTitle>
        </PageContent>
      </Page>
    );

  const { viewer } = data;
  const personalInfo = {
    firstName: viewer.firstName,
    lastName: viewer.lastName,
    position: viewer.position,
    country: viewer.country,
    phone: viewer.phone,
    mobile: viewer.mobile
  };
  const affiliationInfo = {
    institution: viewer.institution,
    researchGroup: viewer.researchGroup,
    principal: viewer.principal,
    url: viewer.url
  };
  const researchInfo = {
    researchAreas: viewer.researchAreas || '',
    materialsClass: viewer.materialsClass || '',
  };
  const userId = viewer.userId;

  return (
    <Page>
      <PageContent>
        <PageTitle>Profile Settings</PageTitle>

        <PageSection
          title="Profile Image"
        >
          <ProfilePictureSettigs
            userId={viewer.id}
            name={viewer.name}
            color={viewer.avatarColor}
            url={get(viewer, 'picture.downloadURL')}
          />
        </PageSection>

        <PageSection
          title="Personal Info"
        >
          <div className={classes.userIdContainer}>
            {`User ID: ${userId}`}
          </div>

          <ProfileDetails
            userId={viewer.id}
            info={personalInfo}
            countries={countriesResponse && countriesResponse.countries}
            onSave={handleSave}
          />
        </PageSection>

        <PageSection
          title="Affiliation Info"
        >
          <AffiliationInfo
            userId={viewer.id}
            info={affiliationInfo}
            onSave={handleSave}
          />
        </PageSection>

        <PageSection
          title="Research Info"
        >
          <ResearchInfo
            userId={viewer.id}
            info={researchInfo}
            onSave={handleSave}
          />
        </PageSection>

        <PageSection
          title="Authentication"
        >
          <AuthSettings
            userId={viewer.id}
            providers={viewer.authProviders}
            onProvidersChanged={refetchViewer}
            idleSignOut={viewer.idleSignOut}
          />
        </PageSection>

        <PageSection
          title="Keys"
        >
          <ApiKeySettings />
        </PageSection>
      </PageContent>
    </Page>
  );
}

UserProfileSettings.propTypes = {
  classes: PT.object.isRequired,
  openAppSnackbarNotification: PT.func.isRequired
};

export default withStyles(styles)(
  connect(null, mapDispatchToProps)(UserProfileSettings)
);
