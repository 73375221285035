import styled from 'styled-components';

const AvatarShortFormSelectContainer = styled.div`
  button {
    background: white;
  }

  button:not(:last-child) {
    margin-right: 12px;
  }
`;

export default AvatarShortFormSelectContainer;
