import React  from 'react';

import { ApiKeySettingsContainer, Link } from './styles';

const ApiKeySettings = () => {

  const handleChatInterface = () => {
    window.Intercom('show');
  };

  return (
    <ApiKeySettingsContainer>
      Materials Zone offers an API for developers. If you would like to use this API, please contact customer support by clicking the <Link onClick={handleChatInterface}>intercom button</Link> or by sending an email to <Link href="mailto:support@materials.zone">support@materials.zone</Link>.
    </ApiKeySettingsContainer>
  );
};

export default ApiKeySettings;
