import { useEffect } from 'react';

const useDocumentTitle = (title) => {
  const envName = process.env.REACT_APP_ENV === 'staging' ? ' - staging' : '';

  useEffect(() => {
    const companyName = 'Materials.Zone';
    document.title = title ?
      `${title}${envName} - ${companyName}` :
      companyName;
  }, [envName, title]);

};

export default useDocumentTitle;
