import React from 'react';
import RaisedInputStyled from './RaisedInput';

const RaisedInputContainer = (props) => {
  return (
    <RaisedInputStyled
      disableUnderline
      fullWidth
      {...props}
    />
  );
};

export default RaisedInputContainer;
