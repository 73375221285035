import { gql } from '@apollo/client';

export const VIEWER_KEYS = gql`
  query ViewerKeys {
    viewer {
      id
      apiKey
    }
  }
`;
