import { call } from 'redux-saga/effects';

import {
  callApi,
  HOST
} from '../../api';
import { APIError } from '../../utils';
import { assetGraphicsDataLoad } from '../samples/api';

export function* processedDataLoadById(id, params) {
  const {
    sampleId,
    assetId,
    dataType,
    url,
    attachmentId,
    isComplex,
    parserId,
    parserViewType
  } = params;

  const body = {
    sampleId,
    assetId,
    responseType: dataType || 'text',
    url,
    attachmentId,
    isComplex
  };

  const { response, error } = yield call(assetGraphicsDataLoad, body);

  return {
    response: {
      ...response,
      fileId: id,
      parserId,
      viewType: parserViewType
    },
    error
  };
}

export async function filesListLoad({ items, title, userId }) {
  const errorText = 'Failed to download resource';
  const params = new URLSearchParams();

  params.set('archive', title);
  params.set('userId', userId);

  items.forEach(i => {
    params.append('items', i);
  });

  try {
    const link = `${HOST}/web/v1/download/pending-files?${params.toString()}`;
    const { error, response } = await callApi(link, { host: link }, 'file');

    if (error) {
      return { error: new APIError('download-resource', errorText) };
    }

    const objectURL = window.URL.createObjectURL(response);
    const a = document.createElement('a');

    a.setAttribute('style', 'display: none');
    a.setAttribute('href', objectURL);
    a.setAttribute('download', `${title}.zip`);

    document.body.appendChild(a);

    a.click();

    document.body.removeChild(a);

    return { response: 'ok' };
  } catch (error) {
    console.error(error);

    return { error: new APIError('download-resource', errorText) };
  }
}

export async function downloadResource({ url, filename }) {
  try {
    const response = await fetch(url);

    let result;

    if(response.status !== 200) {
      result = await response.json();

      throw new Error(JSON.stringify(result));
    }

    result = await response.blob();

    const a = document.createElement('a');
    const objectURL = window.URL.createObjectURL(result);

    if(!filename) {
      const decodedURL = window.decodeURIComponent(url);
      const _filename = decodedURL.match(/filename="(.+)"/);

      filename = _filename?.[1] ?? '';
    }

    a.setAttribute('download', filename);
    a.setAttribute('href', objectURL);
    a.style.display = 'none';

    document.body.appendChild(a);

    a.click();

    document.body.removeChild(a);
    window.URL.revokeObjectURL(objectURL);
  } catch(e) {
    console.error(e);

    return { error: new APIError('download-resource', 'Failed to download resource') };
  }

  return { response: 'ok' };
}
