import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

import App from './scenes/App';

import './styles.js';
import 'react-image-crop/dist/ReactCrop.css';

import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import reducers from './reducers';
import sagas from './sagas';
import { isProdEnv } from './utils/common';
import registerServiceWorker from './registerServiceWorker';
import { UserProvider } from './context/auth/auth';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  : compose;

const loggerMiddleware = createLogger({ level: 'info', collapsed: true });

const middlewares = isProdEnv() ?
  [sagaMiddleware] :
  [sagaMiddleware, loggerMiddleware];

const createStoreWithMiddleware = composeEnhancers(
  applyMiddleware(...middlewares)
)(createStore);

export const store = createStoreWithMiddleware(reducers);

sagaMiddleware.run(sagas);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <UserProvider>
      <App />
    </UserProvider>
  </Provider>
);

registerServiceWorker();
