import styled from 'styled-components';
import { COLOR_GREY_LIGHT } from '../../styles';

export const CustomOptionsStyled = styled.div.attrs({
  className: 'custom-options'
})`
  border: 1px solid ${COLOR_GREY_LIGHT};
  border-left: 0;
  border-right: 0;
  padding: 21px 21px;

  .font-size-container {
    margin-bottom: 7px;
    display: flex;
    align-items: center;
  }
`;

export const MainActionsStyled = styled.div.attrs({
  className: 'actions'
})`
  padding: 14px 10px;
`;
