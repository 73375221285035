import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';

import Page, { PageContent } from '../../components/Page';
import Avatar from '../../components/Avatar';
import { GET_VIEWER_ORGANIZATION } from './services';
import {
  OrganizationInfoStyled,
  Table,
  TableCell,
  TableHead,
  TableContainer
} from './styles';
import TableItem from './OrganizationMembersTableItem';
import { mapMembersToItems, mapUsersToItems } from './utils';
import { DELETED_USER } from './constants';
import NotFound from '../NotFound';
import useDocumentTitle from '../../hooks/document-title';

function Organization() {
  useDocumentTitle('Organization');

  const { data, loading, called } = useQuery(GET_VIEWER_ORGANIZATION);

  const organization = data?.viewer?.organization;
  const members = useMemo(() => [
    ...mapUsersToItems(organization?.members)
      .filter(u => u.status && u.status !== DELETED_USER),
    ...mapMembersToItems(organization?.pendingMembers)
  ], [organization]);

  if (!loading && called && !organization) {
    return <NotFound />;
  }

  let orgURL = organization?.url;

  if(orgURL && !orgURL.startsWith('http'))
    orgURL = `//${orgURL}`;

  return (
    <Page>
      <PageContent>
        {organization &&
          <OrganizationInfoStyled>
            <Avatar
              name={organization.title}
              src={organization.picture.downloadURL}
              size={80}
              color={organization.avatarColor}
              round={false}
            />
            <div>
              <div className="organization-title">
                {organization.title}
              </div>
              {organization.url &&
                <a
                  href={orgURL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {organization.url}
                </a>
              }
            </div>
          </OrganizationInfoStyled>
        }

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  className="members table-head-cell"
                >
                  Members
                </TableCell>

                <TableCell
                  align="left"
                  className="username table-head-cell"
                >
                  User Name
                </TableCell>

                <TableCell
                  align="center"
                  className="date table-head-cell"
                >
                  Date Joined/Invited
                </TableCell>

                <TableCell
                  align="center"
                  className="last-active table-head-cell"
                >
                  Last Active
                </TableCell>
                <TableCell
                  align="center"
                  className="status table-head-cell"
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members?.map(i =>
                <TableItem
                  key={i.id}
                  id={i.id}
                  userId={i.userId}
                  email={i.email}
                  joined={i.joined}
                  lastActive={i.lastActive}
                  status={i.status}
                  username={i.username}
                  avatarUrl={i.avatarUrl}
                  avatarColor={i.avatarColor}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </PageContent>
    </Page>
  );
}

export default Organization;
