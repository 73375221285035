import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import LoadingBanner from '../../../components/LoadingBanner';

import { routes } from '../../../services/session/constants';
import { verifyEmail } from '../../../services';

import { COLOR_PRIMARY, COLOR_WHITE } from '../../../styles';
import { useAuth } from '../../../context/auth/auth';

const VerifyEmail = ({ continueUrl, oobCode }) => {

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { refresh, user } = useAuth();

  const dispatch = useDispatch();

  const handleVerifyEmail = useCallback(async() => {
    setLoading(true);

    const resp = await verifyEmail(oobCode);

    if (!resp){
      setError(true);
    }

    setLoading(false);
    refresh();
  }, [oobCode, refresh]);

  useEffect(() => {
    handleVerifyEmail();
  }, [dispatch, handleVerifyEmail]);

  if (loading)
    return (
      <LoadingBanner
        spinner
        centered
        color={COLOR_PRIMARY}
        bcgcolor={COLOR_WHITE}
      />);

  if (error)
    return (
      <>
        <CardContent>
          <Typography
            variant="h6"
            gutterBottom
          >
            Not possible to verify email
          </Typography>
          <Typography>
            An error has happened. Please try again
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to={continueUrl || routes.SIGN_IN}
          >
            Go to the home page
          </Button>
        </CardActions>
      </>
    );

  return (
    <>
      <CardContent>
        <Typography
          variant="h6"
          gutterBottom
        >
          Your email has been verified
        </Typography>
        {!user &&
          <Typography>
            You can now sign in with new email
          </Typography>
        }
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          component={Link}
          to={continueUrl || routes.SIGN_IN}
          variant="contained"
        >
          Continue
        </Button>
      </CardActions>
    </>
  );
};

VerifyEmail.propTypes = {
  continueUrl: PropTypes.string,
  oobCode: PropTypes.string,
};

export default VerifyEmail;
