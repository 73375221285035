import { gql } from '@apollo/client/core';

const UPDATE_FOLDER = gql`
  mutation UpdateFolder($updateFolderId: ID!, $data: UpdateFolderInput!) {
    updateFolder(id: $updateFolderId, data: $data) {
      id
      title
    }
  }
`;

export default UPDATE_FOLDER;
