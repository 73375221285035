import React from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import { ViewContainer } from '../../EditableValueContainer/styles';
import RawText from '../../../RawText';
import CalculationIcon from '../../../../../Icons/CalculationIcon';

const CalculationValue = ({ data, table, tableParameter }) => {
  const formula = table.customState.parametersById[tableParameter.id]?.calculationFormula ?? null;

  return (
    <ViewContainer>
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          '& .calculated-value-icon': {
            visibility: 'hidden'
          },
          '&:hover .calculated-value-icon': {
            visibility: 'visible'
          }
        }}
      >
        <RawText
          typographyProps={{
            color: data?.calculationError ? '#9E9E9E' : null
          }}
        >
          {data.calculationError ?
            <Tooltip
              title={data?.calculationError}
              placement="top"
              arrow
            >
              <span>#ERROR</span>
            </Tooltip> :
            data?.quantity
          }
        </RawText>

        <Tooltip
          title={formula}
          placement="top"
          arrow
        >
          <CalculationIcon
            className="calculated-value-icon"
            type={data?.calculationError ? 'ERROR' : 'NORMAL'}
            sx={{
              fontSize: '14px',
              color: 'rgba(0,0,0,.6)'
            }}
          />
        </Tooltip>
      </Box>
    </ViewContainer>
  );
};

CalculationValue.propTypes = {
  data: PT.shape({
    type: PT.string.isRequired,
    calculationError: PT.string,
    quantity: PT.number
  }),
  table: PT.shape({
    customState: PT.shape({
      parametersById: PT.object
    })
  }),
  tableParameter: PT.shape({
    id: PT.string
  })
};

export default CalculationValue;

