import React, { useState, useCallback } from 'react';
import PT from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

const EditViewDialog = ({
  open,
  loading,
  currentTitle,
  onClose,
  onSubmit
}) => {
  const [title, setTitle] = useState(currentTitle ?? '');
  const [error, setError] = useState('');

  const handleTitleChange = useCallback(ev => {
    if(error)
      setError(null);

    setTitle(ev.target.value);
  }, [error]);

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    if(!title.trim().length) {
      setError('View name is required.');
      return;
    }

    onSubmit({ title });
  }, [title, onSubmit]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Rename view</DialogTitle>

      <DialogContent>
        <FormControl
          fullWidth
          required
          error={Boolean(error)}
        >
          <InputLabel
            variant="outlined"
            disableAnimation
            shrink
            sx={{
              transform: 'translate(0px, 0px) scale(1)',
              fontSize: '12px',
              lineHeight: '20px',
              color: 'rgba(0,0,0,.6)'
            }}
          >
            Name
          </InputLabel>

          <InputBase
            value={title}
            onChange={handleTitleChange}
            onKeyDown={
              // prevent the menu to intercept the event
              // and move the focus from the input to the
              // menu item, that matches the input value
              ev => ev.stopPropagation()
            }
            sx={{
              mt: '22px',
              px: '10px',
              py: '6px',
              background: 'white',
              border: '1px solid #DCDBDC',
              borderRadius: '4px',
              '.MuiInputBase-input': {
                padding: 0
              },
              fontSize: '14px'
            }}
          />

          {error ?
            <FormHelperText sx={{ ml: 0 }}>
              {error}
            </FormHelperText> :
            null
          }
        </FormControl>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button
          onClick={handleCancel}
          color="primary"
        >
          Cancel
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={handleSubmit}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

EditViewDialog.propTypes = {
  open: PT.bool,
  loading: PT.bool,
  currentTitle: PT.string,
  onClose: PT.func.isRequired,
  onSubmit: PT.func.isRequired
};

export default EditViewDialog;
