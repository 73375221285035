import identity from 'lodash/identity';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import omit from 'lodash/omit';

import { GRAPHICS_TYPES } from '../../services/graphics/constants';
import chartSettingsConfig from '../../services/graphics/chart-settings-config';
import { withDefaultsAndAxes, getGraphicsViewType } from '../../services/graphics/utils';
import { SYMBOL_GRAPHICS_NEW } from './constants';
import { PARSER_VIEW_TYPES } from '../../utils/parsing';
import {
  getGraphicsTypeByViewType
} from '../../utils/graphics/get-data-by-type';

export const getChartSettingsConfigByType = value => chartSettingsConfig[value];

export const getChartTypeOptions = () => ([
  { name: 'Line chart', value: GRAPHICS_TYPES.GRAPHICS_TYPE_LINE_CHART },
  { name: 'Heatmap', value: GRAPHICS_TYPES.GRAPHICS_TYPE_HEATMAP },
  { name: 'Wafer Map', value: GRAPHICS_TYPES.GRAPHICS_TYPE_WAFER_MAP },
  { name: 'Scatter plot', value: GRAPHICS_TYPES.GRAPHICS_TYPE_SCATTER_PLOT },
  { name: 'Box plot', value: GRAPHICS_TYPES.GRAPHICS_TYPE_BOX_PLOT },
  { name: 'Histogram (one column)', value: GRAPHICS_TYPES.GRAPHICS_TYPE_HISTOGRAM_SINGLE },
  { name: 'Vector Wafer Map', value: GRAPHICS_TYPES.GRAPHICS_TYPE_VECTOR_WAFER_MAP },
  { name: 'Correlation Matrix', value: GRAPHICS_TYPES.GRAPHICS_TYPE_CORRELATION_MATRIX }
]);

export const isGraphicsItemNew = data =>
  Object.getOwnPropertySymbols(data)
    .some(i => Symbol.keyFor(i) === SYMBOL_GRAPHICS_NEW);

export const isChartEditable = parserViewType =>
  [
    PARSER_VIEW_TYPES.VIEW_2D_CUSTOM_AXES,
    PARSER_VIEW_TYPES.VIEW_HEATMAP,
    PARSER_VIEW_TYPES.VIEW_SCATTER_PLOT,
    PARSER_VIEW_TYPES.VIEW_BOX_PLOT,
    PARSER_VIEW_TYPES.VIEW_HISTOGRAM_SINGLE,
    PARSER_VIEW_TYPES.VIEW_VECTOR_WAFER_MAP,
    PARSER_VIEW_TYPES.VIEW_WAFER_MAP,
    PARSER_VIEW_TYPES.VIEW_CORRELATION_MATRIX,
  ].some(i => i === parserViewType);

export const mapDataByType = (data, type, { graphAxes, axisValues: axisValuesRaw }) => {
  const axisValues = axisValuesRaw || withDefaultsAndAxes(graphAxes, type);
  const mapData = get(chartSettingsConfig, `${type}.mapData`, identity);

  return mapData(data, axisValues);
};

export const getDefaultGraphics = viewType => ({
  default: {
    [Symbol.for(SYMBOL_GRAPHICS_NEW)]: true,
    type: getGraphicsTypeByViewType(viewType),
    config: cloneDeep(
      withDefaultsAndAxes({}, viewType)
    ),
  }
});

export const transformInitToStateGraphics = items =>
  items.reduce((acc, { id, config, graphicsType, alter }) => ({
    ...acc,
    [id]: {
      config: withDefaultsAndAxes(
        config,
        getGraphicsViewType(graphicsType),
        alter
      ),
      type: graphicsType,
    }
  }), {});

export const validateGraphics = (graphics) => {
  const areValid = Object.values(graphics)
    .map(({ type, config }) => {
      const isValid = (getChartSettingsConfigByType(type)?.axes || [])
        .every(({ name, required, multiple }) => {
          if (!required) return true;

          const _value = config[name];

          return multiple ?
            _value.length :
            _value != null && _value !== '';
        });

      return isValid;
    });

  return !areValid.every(Boolean);
};

export const mapGraphicsStateToArray = data =>
  map(data, ({ type, config, ...rest }, k) => ({
    ...rest,
    id: k,
    graphicsType: type,
    config: omit(config, 'axes'),
    alter: map(config?.axes ?? [], (v, k) => ({
      target: k,
      transform: v.transform
    }))
  }));
