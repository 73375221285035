import Analytics from 'analytics';
import mixpanel from './plugins/analytics-plugin-mixpanel';
import ga4 from './plugins/analytics-plugin-ga4';
import activityLog from './plugins/analytics-plugin-activity-log';
import fullstory from './plugins/analytics-plugin-fullstory';
import { IS_E2E_TEST_USER } from '../constants';

const {
  REACT_APP_ENV,
  REACT_APP_ANALYTICS_MIXPANEL,
  REACT_APP_ANALYTICS_GA4,
  REACT_APP_LOGGING_SERVICE_PATH
} = process.env;

const debugMode = REACT_APP_ENV !== 'production';
const devMode = REACT_APP_ENV === 'development';
const stageMode = REACT_APP_ENV === 'staging';

const analytics = Analytics({
  app: 'app-name',
  plugins: [
    mixpanel({
      token: REACT_APP_ANALYTICS_MIXPANEL,
      debug: debugMode,
      enabled: !IS_E2E_TEST_USER && !devMode
    }),
    ga4({
      token: REACT_APP_ANALYTICS_GA4,
      debug: debugMode,
      enabled: !IS_E2E_TEST_USER && !devMode
    }),
    activityLog({
      path: REACT_APP_LOGGING_SERVICE_PATH,
      enabled: !IS_E2E_TEST_USER && !devMode
    }),
    fullstory({
      enabled: !IS_E2E_TEST_USER && stageMode
    })
  ],
  debug: debugMode
});

export default analytics;
