import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  signInWithEmailAndPassword,
  signInWithOAuth
} from '../../services/session/actions';
import { getErrorMsg } from '../../services/session/selectors';

import SignInEmailPassword from './SignInEmailPassword';
import SignInSocialProvider from './SignInSocialProvider';
import AuthPageStyled from '../../components/AuthPageStyled';
import LogoMain from '../../components/Logos/LogoMain';
import useDocumentTitle from '../../hooks/document-title';

const mapStateToProps = (state) => ({
  error: getErrorMsg(state),
});

const mapDispatchToProps = {
  signInWithEmailAndPassword,
  signInWithOAuth,
};

const Login = ({
  error,
  signInWithEmailAndPassword,
  signInWithOAuth,
}) => {

  useDocumentTitle('Login');

  const handleSignInSocialProviderSubmit = useCallback((...args) => {
    signInWithOAuth.apply(null, [...args]);
  }, [signInWithOAuth]);

  const handleSignInWithCredsSubmit = useCallback((...args) => {
    signInWithEmailAndPassword.apply(null, [...args]);
  }, [signInWithEmailAndPassword]);

  return (
    <AuthPageStyled>
      <div className="header">
        <LogoMain />
      </div>
      <h1>Please Sign In</h1>
      {error && <div className="auth-error-container">{error}</div>}
      <div className="auth-forms-container">
        <SignInSocialProvider onSubmit={handleSignInSocialProviderSubmit} />
        <div className="vertical-division">or</div>
        <SignInEmailPassword onSubmit={handleSignInWithCredsSubmit} />
      </div>
      <p className="change-auth-flow">
        New to Materials.Zone? <Link to="/signup">Sign up</Link>
      </p>
    </AuthPageStyled>
  );
};

Login.propTypes = {
  signInWithEmailAndPassword: PropTypes.func.isRequired,
  signInWithOAuth: PropTypes.func.isRequired,
  error: PropTypes.string,
  currentUser: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
