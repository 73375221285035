import styled from 'styled-components';

import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';


export const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  flex: 1 0;
`;

export const IconStyled = styled(Box)`
    display:flex;
    flex-shrink:0;
    width:26px;
    height:26px;
    align-items:center;
    justify-content:center;
    border-radius:50%;
    background-color: rgba(0, 188, 212, 0.04)
`;

export const Title = styled(Typography)`
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 16px;
`;

export const TeamStyled = styled(Box)`
  padding: 8px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: initial;
  gap: 8px;
  
  &:hover {
    cursor: pointer;
    background-color: #FAFAFA;
  }
`;

export const Subtext = styled(Typography)`
  font-weight: 300;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.60);
`;
