import React, { useCallback } from 'react';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import PT from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import HelpIcon from '@mui/icons-material/Help';

import { COLOR_GREY_LIGHTER } from '../../../../../../../../../../../../styles';

const SingleFormula = ({ formula, editor }) => {
  const handleClick = useCallback(() => {
    if (!editor.selection) return;

    editor.insertText(`${formula.functionName}()`);

    Transforms.move(editor, {
      unit: 'offset',
      distance: 1,
      reverse: true
    });
    ReactEditor.focus(editor);
  }, [editor, formula.functionName]);

  return (
    <Box
      key={formula.id}
      sx={{
        display: 'flex',
        gap: '6px',
        alignItems: 'center',
        p: '2px 4px'
      }}
    >
      <Typography
        onClick={handleClick}
        sx={{
          color: 'rgba(23, 114, 220, 1)',
          textDecoration: 'none',
          lineHeight: '18px',
          '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
          }
        }}
      >
        {formula.functionName}
      </Typography>

      <Tooltip
        arrow
        title={formula.description}
      >
        <HelpIcon
          sx={{
            color: COLOR_GREY_LIGHTER,
            width: '14px',
            height: '14px',
          }}
          size="small"
        />
      </Tooltip>
    </Box>
  );
};

SingleFormula.propTypes = {
  formula: PT.object.isRequired,
  editor: PT.object.isRequired,
};

export default SingleFormula;
