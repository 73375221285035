import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import Notification from '../Notification';

const List = ({
  list,
  onClear,
  onRead,
  loading,
  ...props
}) => {
  const [disabledItemIds, setDisabledItemIds] = useState({});

  const handleItemClear = useCallback((id, value) => {
    setDisabledItemIds(s => ({
      ...s,
      [id]: true
    }));

    onClear(id, value);

    setDisabledItemIds(s => ({
      ...s,
      [id]: false
    }));
  }, [onClear]);

  const handleItemRead = useCallback((id, value) => {
    setDisabledItemIds(s => ({
      ...s,
      [id]: true
    }));

    onRead(id, value);

    setDisabledItemIds(s => ({
      ...s,
      [id]: false
    }));
  }, [onRead]);

  return (
    <Box display="flex" flexDirection="column">
      {list.map(({ eventType, ...i }) => (
        <Notification
          key={i.id}
          type={eventType}
          onClear={handleItemClear}
          onRead={handleItemRead}
          isDisabled={Boolean(loading || disabledItemIds[i.id])}

          {...props}
          {...i}
        />
      ))}
    </Box>
  );
};

List.propTypes = {
  list: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onClear: PropTypes.func.isRequired,
  onRead: PropTypes.func.isRequired,
};

export default List;
