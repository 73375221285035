import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import dateFnsFormat from 'date-fns/format';

import Tooltip from '@mui/material/Tooltip';

import Avatar from '../Avatar';

import {
  CommentAuthorContainer
} from './styles';

const interval = 60 * 1e3;

function CommentAuthor({
  username,
  avatarColor,
  pictureURL,
  dateCreated,
  dateUpdated
}) {
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    setCurrentDate(new Date());

    const intervalId = window.setInterval(() => {
      setCurrentDate(new Date());
    }, interval);

    return () => window.clearInterval(intervalId);
  }, [dateUpdated]);

  return (
    <CommentAuthorContainer>
      <Avatar
        name={username}
        src={pictureURL}
        size={38}
        color={avatarColor}
      />

      <div className="username">
        {username}
      </div>

      <Tooltip
        title={
          dateFnsFormat(new Date(dateCreated), 'MMMM dd, yyyy, h:mm a')
        }
        placement="top"
      >
        <div>
          {formatDistanceStrict(new Date(dateCreated), currentDate, {
            addSuffix: true
          })}
        </div>
      </Tooltip>

      {dateUpdated !== dateCreated ?
        <Tooltip
          title={formatDistanceStrict(new Date(dateUpdated), currentDate, {
            addSuffix: true
          })}
          placement="top"
        >
          <div className="date-updated">Edited</div>
        </Tooltip> :
        null
      }
    </CommentAuthorContainer>
  );
}

CommentAuthor.propTypes = {
  username: PropTypes.string.isRequired,
  avatarColor: PropTypes.string,
  pictureURL: PropTypes.string,
  dateCreated: PropTypes.number.isRequired,
  dateUpdated: PropTypes.number
};

export default CommentAuthor;
