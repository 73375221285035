import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {
  AuthFormStyled
} from '../../../components/AuthPageStyled/AuthContainerStyled';
import RaisedInput from '../../../components/RaisedInput';
import { browserStorage } from '../../../utils';
import SignInButton from '../SignInButton';
import StaySignedIn from '../StaySignedIn';

class SignInEmailPassword extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired
  };

  state = {
    email: '',
    password: '',
    staySignedIn: [undefined, true].includes(browserStorage.get('keepMeLoggedIn')),
    passwordIsVisible: false
  };

  handleEmailChange = ev => {
    const { value } = ev.target;

    this.setState({
      email: value
    });
  };

  handlePasswordChange = ev => {
    const { value } = ev.target;

    this.setState({
      password: value
    });
  };

  handlePassswordVisibilityToggle = () => {
    this.setState({ passwordIsVisible: !this.state.passwordIsVisible });
  };

  handleStaySignedIn = ev => {
    const staySignedIn = ev.target.checked;
    this.setState({ staySignedIn });
    browserStorage.set('keepMeLoggedIn', staySignedIn);
  };

  handleSubmit = ev => {
    const { email, password, staySignedIn } = this.state;

    ev.preventDefault();

    this.setState({ passwordIsVisible: false });
    this.props.onSubmit(email, password, staySignedIn);
  };

  render() {
    const { email, password, passwordIsVisible, staySignedIn } = this.state;

    return (
      <AuthFormStyled
        action="/"
        onSubmit={this.handleSubmit}
      >

        <RaisedInput
          type="email"
          placeholder="email"
          onChange={this.handleEmailChange}
          value={email}
        />

        <RaisedInput
          type={passwordIsVisible ? 'text' : 'password'}
          placeholder="password"
          onChange={this.handlePasswordChange}
          value={password}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                disableRipple
                onClick={this.handlePassswordVisibilityToggle}
                size="large"
              >
                {passwordIsVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />

        <div className="form-group-inline">
          <StaySignedIn
            checked={staySignedIn}
            onChange={this.handleStaySignedIn}
          />
          <Link to="/forgot-password">Forgot my password</Link>
        </div>

        <SignInButton
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
        >
          Sign in
        </SignInButton>
      </AuthFormStyled>
    );
  }
}

export default SignInEmailPassword;
