import styled, { css } from 'styled-components';

export const Common = styled.div`
  ${props => props.fitScreen && css`
      width: 100%;
      height: 100%;
    `
}
`;

export const App = styled(Common)`
  display: flex;
`;

export const RouterContent = styled(Common).attrs({
  className: 'router-content'
})`
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 0;
`;
