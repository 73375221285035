import React from 'react';
import PropTypes from 'prop-types';
import PageTitleStyled from './styles';

const PageTitle = ({ value, children, className }) => (
  <PageTitleStyled className={className}>
    {value}
    {children}
  </PageTitleStyled>
);

PageTitle.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default PageTitle;
