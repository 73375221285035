import React from 'react';

import { components } from 'react-select';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon
        sx={{
          color: 'rgba(0, 0, 0, .54)'
        }}
      />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
