import React from 'react';
import PT from 'prop-types';

import Typography from '@mui/material/Typography';

import TableCell from '../TableCell';
import { formatDate } from './utils';

const DateTime = ({ timestamp }) => (
  <TableCell>
    <Typography variant="body2">
      {formatDate(timestamp)}
    </Typography>
  </TableCell>
);

DateTime.propTypes = {
  timestamp: PT.number
};

export default DateTime;

