import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';

import { Outlet } from 'react-router-dom';

import AppSnackbar from '../../../components/AppSnackbar';
import RootLegacy from './RootLegacy';

import { logout } from '../../../services/session/actions';

import useAutoLogout from '../../../hooks/autoLogout';

import VIEWER_ID from './gql/viewer-id';

const Root = () => {
  const location = useLocation();
  const { page } = useAnalytics();
  const { data } = useQuery(VIEWER_ID);
  // const initialLoad = useRef(true);
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useAutoLogout({ onIdle: handleLogout, isUserExist: Boolean(data?.viewer) });

  useEffect(() => {
    // call analytics tracker with delay
    // to have a correct page title on load
    let timerId = setTimeout(() => { page(); }, 1000);

    return () => {
      if(timerId)
        clearTimeout(timerId);
    };
  }, [location, page]);

  return (
    <>
      <AppSnackbar key="app-snackbar" />

      <RootLegacy key="legacy-root">
        <Outlet />
      </RootLegacy>
    </>
  );
};

export default Root;
