import styled from 'styled-components';

import IconButton from '@mui/material/IconButton';

import { COLOR_PRIMARY, COLOR_PRIMARY_LIGHT, COLOR_WHITE } from '../../styles';

const ButtonStyled = styled(IconButton)`
  height: 30px;
  width: 30px;
  border-radius: 4px;
  border: ${props => props.contained ? '1px solid #D9D9D9' : null};
  background-color: ${props => props.contained ? COLOR_PRIMARY : COLOR_WHITE};;

  svg {
    color: ${props => props.contained ? COLOR_WHITE : 'rgba(140, 140, 140)'} !important;
    height: 18px;
    width: 18px !important;
  }
  
  :hover {
    background-color: ${props => props.contained && COLOR_PRIMARY_LIGHT};
  }
`;

export default ButtonStyled;
