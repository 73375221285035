import { gql } from '@apollo/client/core';

export default gql`
  query ViewerBasic {
    viewer {
      id
      perovskiteUser
    }
  }
`;
