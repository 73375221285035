import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import omit from 'lodash/omit';

class Scroll extends Component {
  constructor(props) {
    super(props);
    this.scrollListener = this.scrollListener.bind(this);
    this._loading = false;
  }

  getScrollElement() {
    const { useWindow, basedElement } = this.props;

    return useWindow
      ? window
      : (basedElement || ReactDOM.findDOMNode(this).parentNode);
  }

  // Set a defaut loader for all your `InfiniteScroll` components
  setDefaultLoader(loader) {
    this._defaultLoader = loader;
  }

  attachScrollListener() {
    if (!this.props.hasMore || !this.props.shouldAttachScroll) {
      return;
    }

    const scrollEl = this.getScrollElement();

    scrollEl.addEventListener('scroll', this.scrollListener);
    scrollEl.addEventListener('resize', this.scrollListener);

    if (this.props.initialLoad) {
      this.scrollListener();
    }
  }

  detachScrollListener() {
    const scrollEl = this.getScrollElement();

    scrollEl.removeEventListener('scroll', this.scrollListener);
    scrollEl.removeEventListener('resize', this.scrollListener);
  }

  scrollListener() {
    const el = this.props.basedElement || ReactDOM.findDOMNode(this);
    const scrollEl = window;

    let offset;

    if (this.props.useWindow) {
      const scrollTop =
      scrollEl.pageYOffset !== undefined
        ? scrollEl.pageYOffset
        : (
          document.documentElement ||
        document.body.parentNode ||
        document.body
        ).scrollTop;

      if (this.props.isReverse) {
        offset = scrollTop;
      } else {
        offset = this.calculateTopPosition(el) + el.offsetHeight - scrollTop - window.innerHeight;
      }
    } else {
      if (this.props.isReverse) {
        offset = el.scrollTop;
      } else {
        offset = el.scrollHeight - el.scrollTop - el.clientHeight;
      }
    }

    if (offset < Number(this.props.threshold)) {
      this._loading = true;

      this.detachScrollListener();
      // Call loadMore after detachScrollListener to allow for non-async loadMore functions
      if (typeof this.props.loadMore === 'function') {
        this.props.loadMore((this.pageLoaded += 1));
      }
    }
  }

  calculateTopPosition(el) {
    return el ? el.offsetTop + this.calculateTopPosition(el.offsetParent) : null;
  }

  componentDidMount() {
    this.attachScrollListener();
  }

  componentDidUpdate() {
    const { loading } = this.props;

    if (this._loading) {
      if (!loading) {
        this.attachScrollListener();
      }
    } else {
      this.attachScrollListener();
    }
  }

  componentWillUnmount() {
    this.detachScrollListener();
  }

  render() {
    const { children, element, hasMore, loader, ...props } = this.props;

    return React.createElement(
      element,
      omit(props, [
        'initialLoad',
        'loadMore',
        'pageStart',
        'threshold',
        'useWindow',
        'isReverse',
        'shouldAttachScroll',
        'basedElement',
        'loading',
      ]),
      children,
      hasMore && (loader || this._defaultLoader)
    );
  }
}

Scroll.propTypes = {
  element: PropTypes.string,
  hasMore: PropTypes.bool,
  initialLoad: PropTypes.bool,
  loadMore: PropTypes.func.isRequired,
  loader: PropTypes.object,
  pageStart: PropTypes.number,
  threshold: PropTypes.number,
  useWindow: PropTypes.bool,
  isReverse: PropTypes.bool,
  shouldAttachScroll: PropTypes.bool,
  children: PropTypes.array.isRequired,
  basedElement: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

Scroll.defaultProps = {
  element: 'div',
  hasMore: true,
  initialLoad: false,
  pageStart: 1,
  threshold: 300,
  useWindow: true,
  isReverse: false,
  shouldAttachScroll: true,
  basedElement: null
};

export default Scroll;
