import React, { useCallback } from 'react';
import PT from 'prop-types';
import without from 'lodash/without';
import noop from 'lodash/noop';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';

import SelectFormControl from './SelectFormControl';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const SELECT_USERS_LABEL = 'select-users-label';
const INPUT_LABEL = 'Select Users';

const MultipleSelectChip = ({ options = [], value, onChange }) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    onChange(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleRemove = useCallback(id => ev => {
    // prevent opening select's options
    // when "delete" button is clicked
    ev.stopPropagation();

    onChange(without(value, id));
  }, [value, onChange]);

  return (
    <SelectFormControl
      multiple
      id={SELECT_USERS_LABEL}
      label={INPUT_LABEL}
      value={value}
      onChange={handleChange}
      options={options}
      MenuProps={MenuProps}
      inputStyles={{
        height: 'auto',
        minHeight: 34,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '4px',
        paddingBottom: '4px',
        '& .MuiSelect-select': {
          pr: 0
        }
      }}
      renderValue={(selected) => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '8px',
            pl: '22px',
          }}
        >
          {selected.map((value) => (
            <Chip
              data-role="selected-user-chip"
              key={value}
              label={options.find(i => i.value === value).label.primary}
              onDelete={noop}
              deleteIcon={
                <CloseIcon onMouseDown={handleRemove(value)} />
              }
              sx={{ backgroundColor: '#E0F7FA', borderRadius: '6px', height: '26px' }}
            />
          ))}
        </Box>
      )}
    />
  );
};

MultipleSelectChip.propTypes = {
  options: PT.arrayOf(
    PT.shape({
      value: PT.string.isRequired,
      label: PT.shape({
        primary: PT.string.isRequired,
        secondary: PT.string,
      })
    })
  ).isRequired,
  value: PT.array.isRequired,
  onChange: PT.func.isRequired
};

export default MultipleSelectChip;

