import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PageSection = props => (
  <div className={props.className}>
    {props.title
      ? (
        <div className="title-area">
          <h3>{props.title}</h3>

          {props.subtitle &&
            <div className="subtitle">
              {props.subtitle}
            </div>
          }
        </div>
      )
      : null
    }
    {props.titleAreaContent &&
      <div className="title-area">
        {props.titleAreaContent}
      </div>
    }
    <div className="content-area">
      {props.children}
    </div>
  </div>
);

PageSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  titleAreaContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string
};

export default styled(PageSection)`
  border-top: 1px solid #e0e0e0;
  display: flex;
  padding-top: 16px;
  margin-top: 16px;

  .title-area {
    width: 170px;
    flex-shrink: 0;
    padding-right: 10px;
    box-sizing: border-box;
  }

  h3 {
    font-size: 16px;
    margin: 0 0 1em;
  }

  .subtitle {
    font-size: 12px;
  }

  .content-area {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  @media (min-width: 768px) {
    .content-area:not(:first-child) {
      max-width: calc(100% - 170px);
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
