import { useReducer, useCallback, useMemo } from 'react';
import omit from 'lodash/omit';

const createInitialState = ({ allChecked, displaySelected }) => ({
  allChecked: allChecked ?? false,
  displaySelected: displaySelected ?? false,
  byId: {}
});

const actions = {
  SET_IDS: 'SET_IDS',
  REMOVE_IDS: 'REMOVE_IDS',

  CHECK_ALL_CHANGE: 'CHECK_ALL_CHANGE',
  CHECK_CHANGE: 'CHECK_CHANGE',

  TOGGLE_DISPLAY_SELECTED: 'TOGGLE_DISPLAY_SELECTED'
};

const setIdsReducer = (state, action) => {
  const ids = action.payload;
  const newState = createInitialState(state);

  for(const id of ids) {
    newState.byId[id] = state.byId[id] ?? state.allChecked;
  }

  const hasSelection = Object.values(newState.byId).some(Boolean);

  if(!hasSelection)
    newState.displaySelected = false;

  return newState;
};

const removeIdsReducer = (state, action) => {
  const byId = omit(state.byId, action.payload);

  return {
    ...state,
    allChecked: state.allChecked,
    byId
  };
};

const checkAllChangeReducer = (state, action) => {
  const checked = action.payload;
  const byId = {};
  const ids = Object.keys(state.byId);

  for(const id of ids) {
    byId[id] = checked;
  }

  return {
    ...state,
    allChecked: checked,
    displaySelected: checked && state.displaySelected,
    byId
  };
};

const checkChangeReducer = (state, action) => {
  const { id, checked } = action.payload;
  const allChecked = state.allChecked && checked;
  const byId = {
    ...state.byId,
    [id]: checked
  };
  const hasSelection = Object.values(byId).some(Boolean);
  const displaySelected = state.displaySelected && hasSelection;

  return {
    ...state,
    allChecked,
    displaySelected,
    byId
  };
};

const reducer = (state, action) => {
  switch(action.type) {
    case actions.SET_IDS:
      return setIdsReducer(state, action);

    case actions.REMOVE_IDS:
      return removeIdsReducer(state, action);

    case actions.CHECK_ALL_CHANGE:
      return checkAllChangeReducer(state, action);

    case actions.CHECK_CHANGE:
      return checkChangeReducer(state, action);

    case actions.TOGGLE_DISPLAY_SELECTED: {
      const displaySelected = action.payload ?? !state.displaySelected;

      return {
        ...state,
        displaySelected
      };
    }

    default:
      return state;
  }
};

const useSelectedItems = () => {
  const [state, dispatch] = useReducer(
    reducer,
    { allChecked: false, displaySelected: false },
    createInitialState
  );

  const selectedItemIds = useMemo(() => {
    return Object
      .entries(state.byId)
      .filter(([, checked]) => checked)
      .map(([id]) => id);
  }, [state.byId]);

  const setIds = useCallback((ids) => {
    dispatch({
      type: actions.SET_IDS,
      payload: ids
    });
  }, []);

  const removeIds = useCallback((ids) => {
    dispatch({
      type: actions.REMOVE_IDS,
      payload: ids
    });
  }, []);

  const checkAllChange = useCallback(checked => {
    dispatch({
      type: actions.CHECK_ALL_CHANGE,
      payload: checked
    });
  }, []);

  const checkChange = useCallback((id, checked) => {
    dispatch({
      type: actions.CHECK_CHANGE,
      payload: { id, checked }
    });
  }, []);

  const toggleDisplaySelected = useCallback(value => {
    dispatch({
      type: actions.TOGGLE_DISPLAY_SELECTED,
      payload: value
    });
  }, []);

  return {
    state,
    selectedItemIds,
    // methods
    setIds,
    removeIds,
    checkAllChange,
    checkChange,
    toggleDisplaySelected
  };
};

export default useSelectedItems;
