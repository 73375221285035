import React from 'react';
import PropTypes from 'prop-types';

import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';

const NotificationActions = ({
  isDisabled,
  onClearItem,
  onMarkRead,
  isRead
}) => (
  <div className={`notification-actions${isDisabled ? ' disabled' : ''}`}>
    <Tooltip title="Clear notification">
      <div
        className="clear-action extras-item"
        onClick={onClearItem}
      >
        <ClearIcon className="clear-icon" />
      </div>
    </Tooltip>

    <Tooltip title={`Mark as ${isRead ? 'un' : ''}read`}>
      <div
        className={`read-mark extras-item${isRead ? '' : ' unread'}`}
        onClick={onMarkRead}
      >
        <div className="read-mark-content" />
      </div>
    </Tooltip>
  </div>
);

NotificationActions.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  isRead: PropTypes.bool.isRequired,
  onClearItem: PropTypes.func.isRequired,
  onMarkRead: PropTypes.func.isRequired,
};

export default NotificationActions;
