import React from 'react';
import PT from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import FullscreenExit from '@mui/icons-material/FullscreenExit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ActionDivider from './ActionDivider';

const FullGeneralActions = ({ onShortScreen, onClose  }) => {
  return (
    <>
      <ActionDivider />

      <Tooltip title="Exit fullscreen">
        <IconButton onClick={onShortScreen}>
          <FullscreenExit fontSize="small" />
        </IconButton>
      </Tooltip>

      <ActionDivider />

      <Tooltip title="Close">
        <IconButton onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
};

FullGeneralActions.propTypes = {
  onShortScreen: PT.func.isRequired,
  onClose: PT.func.isRequired
};

export default FullGeneralActions;
