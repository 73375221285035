import dateFnsFormat from 'date-fns/format';

export const getTooltipText = (data) => {
  const owner = `By: ${data?.username}`;
  const dateFormatting = (data) => dateFnsFormat(data, `dd MMM, yyyy '-' HH:mm`);
  const createdDate = `Created on ${
    data?.dateCreated ?
      dateFormatting(data.dateCreated) :
      null
  }`;
  const updatedDate = data?.dateUpdated ?
    `Updated on ${dateFormatting(data.dateUpdated)}` :
    null;
  return [owner, createdDate, updatedDate].filter(Boolean).join('\n');
};
