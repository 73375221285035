import { gql } from '@apollo/client';

export const APPLICATIONS = gql`
  query ViewerApplications {
    applications {
      id
      url
      title
      children {
        id
        url
        title
      }
    }
  }
`;

export const VIEWER = gql`
  query AppSidebarViewer {
    viewer {
      userId
      name
      email
      picture {
        downloadURL
      }
      organization {
        id
        title
        picture {
          downloadURL
          fullPath
        }
        avatarColor
      }
    }
  }
`;
