import React from 'react';
import PropTypes from 'prop-types';

import AppGraphicsErrorBoundary from '../AppGraphicsErrorBoundary';
import GraphicsImage from './GraphicsImage';
import GraphicsPdf from './GraphicsPdf';
import GraphicsSurfaceChart from './GraphicsSurfaceChart';
import TablePresentation from './TablePresentation';
import RawHTML from './RawHTML';
import ScatterPlot from './ScatterPlot';
import BoxPlot from './BoxPlot';
import HistogramSingle from './HistogramSingle';
import VectorWaferMap from './VectorWaferMap';
import CorrelationMatrix from './CorrelationMatrix';
import Heatmap from './Heatmap';
import WaferMap from './WaferMap';
import Linechart from './Linechart';

import { GRAPHICS_TYPES, VIEW_MODES } from '../../services/graphics/constants';

const AppGraphics = ({
  type,
  paperWidth,
  paperHeight,
  mode,
  config = {},
  data: graphicsData,
  ...graphicsProps
}) => {
  if(!graphicsData) return null;

  let data = null;

  try {
    data = JSON.parse(graphicsData);
  } catch (e) {
    // You can read e for more info
    // Let's assume the error is that we already have parsed the payload
    // So just return that
    data = graphicsData;
  }

  if(!data)
    return null;

  if (mode === VIEW_MODES.VIEW_MODE_TABLE) {
    return (
      <TablePresentation data={data} />
    );
  }

  switch(type) {
    case GRAPHICS_TYPES.GRAPHICS_TYPE_PDF:
      return (
        <GraphicsPdf
          src={
            data instanceof Blob ?
              data :
              data?.location?.downloadURL
          }
          data={data}
          {...config}
          {...graphicsProps}
        />
      );
    case GRAPHICS_TYPES.GRAPHICS_TYPE_IMAGE:
      return (
        <GraphicsImage
          src={data}
          {...config}
          {...graphicsProps}
        />
      );
    case GRAPHICS_TYPES.GRAPHICS_TYPE_LINE_CHART:
      return (
        <AppGraphicsErrorBoundary
          preview={graphicsProps.preview}
          config={config}
        >
          <Linechart
            data={data}
            paperWidth={paperWidth}
            paperHeight={paperHeight}
            {...config}
            {...graphicsProps}
          />
        </AppGraphicsErrorBoundary>
      );
    case GRAPHICS_TYPES.GRAPHICS_TYPE_SURFACE:
      return (
        <AppGraphicsErrorBoundary
          preview={graphicsProps.preview}
          config={config}
        >
          <GraphicsSurfaceChart
            data={data}
            paperWidth={paperWidth}
            paperHeight={paperHeight}
            {...config}
            {...graphicsProps}
          />
        </AppGraphicsErrorBoundary>
      );
    case GRAPHICS_TYPES.GRAPHICS_TYPE_HEATMAP:
      return (
        <AppGraphicsErrorBoundary preview={graphicsProps.preview} config={config}>
          <Heatmap
            data={data}
            paperWidth={paperWidth}
            paperHeight={paperHeight}
            {...config}
            {...graphicsProps}
          />
        </AppGraphicsErrorBoundary>
      );
    case GRAPHICS_TYPES.GRAPHICS_TYPE_TABLE:
      return (
        <TablePresentation data={data} />
      );

    case GRAPHICS_TYPES.GRAPHICS_TYPE_RAW_HTML:
      return (
        <RawHTML data={data} />
      );

    case GRAPHICS_TYPES.GRAPHICS_TYPE_SCATTER_PLOT:
      return (
        <AppGraphicsErrorBoundary preview={graphicsProps.preview} config={config}>
          <ScatterPlot
            data={data}
            {...config}
            {...graphicsProps}
          />
        </AppGraphicsErrorBoundary>
      );

    case GRAPHICS_TYPES.GRAPHICS_TYPE_BOX_PLOT:
      return (
        <AppGraphicsErrorBoundary
          preview={graphicsProps.preview}
          config={config}
        >
          <BoxPlot
            data={data}
            {...config}
            {...graphicsProps}
          />
        </AppGraphicsErrorBoundary>
      );

    case GRAPHICS_TYPES.GRAPHICS_TYPE_HISTOGRAM_SINGLE:
      return (
        <AppGraphicsErrorBoundary
          preview={graphicsProps.preview}
          config={config}
        >
          <HistogramSingle
            data={data}
            {...config}
            {...graphicsProps}
          />
        </AppGraphicsErrorBoundary>
      );

    case GRAPHICS_TYPES.GRAPHICS_TYPE_VECTOR_WAFER_MAP:
      return (
        <AppGraphicsErrorBoundary
          preview={graphicsProps.preview}
          config={config}
        >
          <VectorWaferMap
            data={data}
            {...config}
            {...graphicsProps}
          />
        </AppGraphicsErrorBoundary>
      );

    case GRAPHICS_TYPES.GRAPHICS_TYPE_WAFER_MAP:
      return (
        <AppGraphicsErrorBoundary
          preview={graphicsProps.preview}
          config={config}
        >
          <WaferMap
            data={data}
            paperWidth={paperWidth}
            paperHeight={paperHeight}
            {...config}
            {...graphicsProps}
          />
        </AppGraphicsErrorBoundary>
      );

    case GRAPHICS_TYPES.GRAPHICS_TYPE_CORRELATION_MATRIX:
      return (
        <AppGraphicsErrorBoundary
          preview={graphicsProps.preview}
          config={config}
        >
          <CorrelationMatrix
            data={data}
            {...config}
            {...graphicsProps}
          />
        </AppGraphicsErrorBoundary>
      );
    default:
      return null;
  }
};

AppGraphics.propTypes = {
  type: PropTypes.oneOf( Object.keys(GRAPHICS_TYPES) ).isRequired,
  mode: PropTypes.oneOf( Object.keys(VIEW_MODES) ),
  data: PropTypes.any,
  paperWidth: PropTypes.number,
  paperHeight: PropTypes.number,
  config: PropTypes.object
};

export default AppGraphics;
