import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './styles';

import { iconMap } from '../utils';

const NotificationTypeIcon = ({ type, icon }) => {

  const Icon = iconMap(icon ?? type);

  return (
    <Wrapper>
      <Icon />
    </Wrapper>
  );
};

NotificationTypeIcon.propTypes = {
  type: PropTypes.string.isRequired,
  icon: PropTypes.string,
};
export default NotificationTypeIcon;
