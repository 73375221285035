const ENTER_KEY = 'Enter';
const ESCAPE_KEY = 'Escape';

export const interceptKeyboardEvent = ({
  event,
  onEnter,
  onEscape
}) => {
  const { shiftKey, key } = event;

  if (key === ENTER_KEY && !shiftKey) {
    onEnter?.();
  } else if (key === ESCAPE_KEY) {
    onEscape?.();
  }
};
