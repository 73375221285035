import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PT from 'prop-types';
import debounce from 'lodash/debounce';
import { isEqual } from 'lodash';

import { ClickAwayListener } from '@mui/base/ClickAwayListener';

import useEditMode from './editMode';

import { EditContainer, ViewContainer } from './styles';

const EditableValueContainer = ({
  onUpdate,
  readOnly,
  defaultValue,
  viewComponentProps = {},
  tableParameter,
  viewComponent: ViewComponent,
  editComponent: EditComponent,
  onEditModeChange
}) => {
  const [editMode, setEditMode] = useEditMode({
    defaultValue: false,
    onChange: onEditModeChange
  });
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    /* defaultValue & internal state sync */
    setValue(defaultValue);
  }, [editMode]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCancel = useCallback(() => {
    setValue(defaultValue);
    setEditMode(null);
  }, [defaultValue, setEditMode]);

  const handleChange = useCallback((newValue, options) => {
    setValue(newValue);

    if(options?.withSubmit) {
      setEditMode(null);
      onUpdate(newValue);
    }
  }, [setEditMode, onUpdate]);

  const handleSubmit = useCallback(() => {
    setEditMode(null);

    if (!isEqual(value, defaultValue)) {
      onUpdate(value);
    }
  }, [onUpdate, value, defaultValue, setEditMode]);

  const debounceSetEditMode = useMemo(
    () =>
      debounce(value => {
        setEditMode(value);
      }, 200),
    [setEditMode]
  );

  const handleEdit = useCallback((e) => {
    if (!readOnly) {
      /* "debounce" is needed here for double-click detecting (e.detail "2" goes right after "1") */
      debounceSetEditMode(e.detail);
    }
  }, [readOnly, debounceSetEditMode]);

  const handleClickAway = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const handleKeyDown = useCallback(e => {
    if (['Enter', 'Tab'].includes(e.key)) {
      e.stopPropagation();
      e.preventDefault();

      handleSubmit();
    } else if (e.key === 'Escape') {
      e.stopPropagation();
      e.preventDefault();

      handleCancel();
    }
  }, [handleSubmit, handleCancel]);

  return editMode
    ? (
      /*
        "ClickAwayListener" is used over native "onBlur" to deal
        with MUI-select component opening: it fires multiple "onBlur" events on opening
      */
      <ClickAwayListener onClickAway={handleClickAway}>
        <EditContainer onKeyDown={handleKeyDown}>
          <EditComponent
            onCancel={handleCancel}
            onChange={handleChange}
            onSubmit={handleSubmit}
            data={value}
            editMode={editMode}
            tableParameter={tableParameter}
          />
        </EditContainer>
      </ClickAwayListener>
    ) : (
      <ViewContainer>
        <ViewComponent
          readOnly={readOnly}
          onClick={handleEdit}
          data={defaultValue}

          {...viewComponentProps}
        />
      </ViewContainer>
    );

};

EditableValueContainer.propTypes = {
  data: PT.object,
  readOnly: PT.bool,
  tableParameter: PT.shape({
    enumValueOptions: PT.arrayOf(PT.shape({
      id: PT.string,
      title: PT.string
    }))
  }),
  onUpdate: PT.func,
  viewComponent: PT.elementType.isRequired,
  editComponent: PT.elementType.isRequired,
  viewComponentProps: PT.object,
  defaultValue: PT.any,
  onEditModeChange: PT.func.isRequired
};

export default EditableValueContainer;
