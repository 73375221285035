export const PARSER_DATA_TYPES = {
  DATA_FILE: 'DATA_FILE',
  DATA_JSON: 'DATA_JSON',
  DATA_TEXT: 'DATA_TEXT',
  DATA_COMPLEX: 'DATA_COMPLEX',
};

export const DB_PARSER_DATA_TYPES = new Map([
  ['DATA_FILE', 'DATA_FILE'],
  ['DATA_JSON', 'DATA_JSON'],
  ['DATA_TEXT', 'DATA_TEXT'],
  ['DATA_COMPLEX', 'DATA_COMPLEX']
]);

const PARSER_RUNTIME = {
  PYTHON: 'python3',
  PYTHON2: 'python',
  NODEJS: 'node',
  SERVICE: 'service'
};

export const PARSER_VIEW_TYPES = Object.freeze({
  VIEW_GRAPHICS: 'VIEW_GRAPHICS',
  VIEW_IMAGE: 'VIEW_IMAGE',
  VIEW_2D: 'VIEW_2D',
  VIEW_2D_CUSTOM_AXES: 'VIEW_2D_CUSTOM_AXES',
  VIEW_3D: 'VIEW_3D',
  VIEW_HEATMAP: 'VIEW_HEATMAP',
  VIEW_DOCX: 'VIEW_DOCX',
  VIEW_SCATTER_PLOT: 'VIEW_SCATTER_PLOT',
  VIEW_BOX_PLOT: 'VIEW_BOX_PLOT',
  VIEW_HISTOGRAM_SINGLE: 'VIEW_HISTOGRAM_SINGLE',
  VIEW_WAFER_MAP: 'VIEW_WAFER_MAP',
  VIEW_VECTOR_WAFER_MAP: 'VIEW_VECTOR_WAFER_MAP',
  VIEW_CORRELATION_MATRIX: 'VIEW_CORRELATION_MATRIX',
  VIEW_PDF: 'VIEW_PDF'
});

export const getParserDataTypes = (...args) =>
  args.length ? PARSER_DATA_TYPES[ args[0] ] : PARSER_DATA_TYPES;

export const getParserRuntimes = () => PARSER_RUNTIME;
