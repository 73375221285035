import React from 'react';
import dateFnsFormat from 'date-fns/format';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import TableDescription from './TableDescription';

import TableItemsNumber from './TableItemsNumber';

const TableProperties = ({ table, readOnly }) => {
  const { id, description, hash, numberOfTableItems, dateUpdated } = table;

  return (
    <Box>
      <Box
        height="16px"
        display="flex"
        alignItems="center"
        gap="10px"
        mb="8px"
      >
        <TableItemsNumber
          id={id}
          number={numberOfTableItems}
        />

        <Divider
          orientation="vertical"
        />

        <Typography
          variant="body2"
          color="rgba(0, 0, 0, 0.60)"
        >
          Updated: {dateFnsFormat(dateUpdated, `dd/MM/yyyy`)}
        </Typography>
      </Box>

      <TableDescription
        id={id}
        description={description}
        hash={hash}
        readOnly={readOnly}
      />
    </Box>
  );
};

TableProperties.propTypes = {
  table: PT.shape({
    id: PT.string.isRequired,
    description: PT.string,
    hash: PT.string.isRequired,
    numberOfTableItems: PT.number.isRequired,
    dateUpdated: PT.number.isRequired,
  }).isRequired,
  readOnly: PT.bool
};

export default TableProperties;
