import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const AppSnackbarContentInfo = forwardRef(({
  id,
  message,
  onClose
}, ref) => (
  <SnackbarContent
    ref={ref}
    aria-describedby={`app-snackbar-notification-${id}`}
    message={
      <span id={`message-${id}`}>
        {message}
      </span>
    }
    action={[
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={onClose}
        size="large"
      >
        <CloseIcon />
      </IconButton>
    ]}
  />
));

AppSnackbarContentInfo.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClose: PropTypes.func
};

export default AppSnackbarContentInfo;
