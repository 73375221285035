import styled from 'styled-components';

import Box from '@mui/material/Box';

export const CellStyled = styled(Box)`
  gap: 4px;
  display: flex;
  align-items: ${props => props.isprotocol ? 'left' : 'center'};
  flex-direction: ${props => props.isprotocol ? 'column' : 'row'};
  width: 212px;
  
  .MuiTypography-root {
    font-size: 14px;
  }
`;
