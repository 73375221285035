const INPUT_TEXT_TYPE = 'text';

export const placeTailCursor = (element) => {
  const end = element.value.length;
  const isNotText = element.type !== INPUT_TEXT_TYPE;

  let inputType = element.type;

  if (isNotText) {
    /* NOTE: temporary changing input type to be able to select content */
    element.type = INPUT_TEXT_TYPE;
  }

  element.setSelectionRange(end, end);

  if (isNotText) {
    element.type = inputType;
  }

  element.focus();
};
