import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import ListItem from '../ListItem';

import { ItemWrapper } from './styles';

const depthParams = {
  DEEP_TABLE: 26,
  SINGLE_TABLE: 0
};

const styles = {
  emptyTable: {
    paddingLeft: '24px'
  },
};

const SingleTable = ({
  table,
  value,
  depth = 1,
  onChange,
}) => {

  const ref = useRef();

  const isChecked = useMemo(() => {
    return value.includes(table.id);
  }, [table.id, value]);

  const handleChange = useCallback((e) => {
    onChange(e, [table.id]);
  }, [onChange, table.id]);

  const paddingDepth = useMemo(() => {
    if (depth > 1)
      return depthParams.DEEP_TABLE;
    else return depthParams.SINGLE_TABLE;
  }, [depth]);

  return (
    <Box sx={{ paddingLeft: `${paddingDepth}px`, maxWidth: '100%' }}>
      <ItemWrapper>

        <ListItem
          key={table.id}
          ref={ref}
          onChange={handleChange}
          title={table.title}
          number={table.number}
          checked={isChecked}
          additionalStyles={styles.emptyTable}
        />
      </ItemWrapper>
    </Box>
  );
};

SingleTable.propTypes = {
  table: PropTypes.object.isRequired,
  value: PropTypes.array.isRequired,
  depth: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

export default SingleTable;
