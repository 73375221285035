import React, { useState, useCallback, useRef } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import PT from 'prop-types';
import { useAnalytics } from 'use-analytics';

import Button from '@mui/material/Button';
import { ButtonGroup } from '@mui/material';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import LoadingButton from '@mui/lab/LoadingButton';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import VideoTooltip from '../../../../components/VideoTooltip';
import CreateItemDialog from '../../../../components/CreateItemDialog';
import VideoHelperDialog from '../../../../components/VideoHelperDialog';

import { openAppSnackbarNotification } from '../../../../services/snackbar-notifications/actions';
import client from '../../../../services/apollo-client';
import { GET_ITEMS, GET_TABLES } from '../../services';

import { SNACKBAR_TYPES } from '../../../../components/AppSnackbar';
import { CreateItemSuccessMessage } from '../../../../components/Sample/CreateItemMessages';
import { Container, StyledButton, StyledInput } from './styles';

import { componentNames, TRACK_HELP_VIDEO } from '../../../../analytics/constants';
import VIDEO_LINKS from '../../../../components/VideoHelperDialog/constants';

const CREATE_ITEMS = gql`
  mutation CreateDummyTableItems($tableId: ID!, $count: Int!) {
     createDummyTableItems(tableId: $tableId, count: $count) {
        item {
          id
          code
          title
          description
          dateCreated
          dateUpdated
          dateFabricated
          interactions {
            comments
          }
          creator {
            id
            name
            ... on CurrentUser {
              avatarColor
              pictureDownloadURL
              userId
            }
          }
          table {
            id
            title
            viewerMaxRole
            tableParameters {
              id
              featureId
              titleTableItem {
                id
                title
              }
              valueType
              tableProtocol {
                id
                type

                ...on TableProtocolFormulation {
                  unit {
                    id
                    name
                  }
                }
              }
            }
          }
          numberOfTableItemFiles
          isUsedInProtocols
        }
        values {
          featureId
          tableItemId
          tableParameterId
          type

          ... on TableValueQuantity {
            quantity
          }

          ... on TableValueCalculation {
            quantity
            calculationError
          }

          ... on TableValueText {
            text
          }

          ... on TableValueBoolean {
            boolean
          }

          ... on TableValueLink {
            linkedTableItem {
              id
              code
              title
              viewerMaxRole
            }
          }
        }
     }
  }
`;

const CreateItem = ({ disabled, table, buttonTooltip, queryVariables, handleToggleSidebar }) => {
  const [createItems, { loading }] = useMutation(CREATE_ITEMS, {
    onCompleted: ({ createDummyTableItems }) => {
      const oldItemsData = client.readQuery({
        query: GET_ITEMS,
        variables: queryVariables
      });

      client.writeQuery({
        query: GET_ITEMS,
        variables: queryVariables,
        data: {
          ...oldItemsData,
          tableItems: {
            ...oldItemsData.tableItems,
            itemsTable: {
              ...oldItemsData.tableItems.itemsTable,
              filterInfo: {
                ...oldItemsData.tableItems.itemsTable.filterInfo,
                totalNumberOfFilteredItems: oldItemsData.tableItems.itemsTable.filterInfo.totalNumberOfFilteredItems
                  + createDummyTableItems.length
              },
              edges: [
                ...createDummyTableItems.map((item) => ({
                  __typename: 'TableItemsEdge',
                  cursor: null,
                  node: {
                    ...item,
                  }
                })),
                ...oldItemsData.tableItems.itemsTable.edges
              ]
            }
          }
        }
      });
    },
    refetchQueries: [GET_TABLES]
  });

  const [createItemDialogOpen, setCreateItemDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(2);
  const [videoLink, setVideoLink] = useState(null);

  const anchorRef = useRef(null);

  const { track } = useAnalytics();

  const dispatch = useDispatch();

  const handleCreateItemDialogOpen = useCallback(() => {
    setCreateItemDialogOpen(true);
  }, []);

  const handleCreateItemDialogClose = useCallback(() => {
    setCreateItemDialogOpen(false);
  }, []);

  const handleCreateItemDialogSuccess = useCallback((data) => {
    const oldItemsData = client.readQuery({
      query: GET_ITEMS,
      variables: queryVariables
    });

    client.writeQuery({
      query: GET_ITEMS,
      variables: queryVariables,
      data: {
        ...oldItemsData,
        tableItems: {
          ...oldItemsData.tableItems,
          itemsTable: {
            ...oldItemsData.tableItems.itemsTable,
            filterInfo: {
              ...oldItemsData.tableItems.itemsTable.filterInfo,
              totalNumberOfFilteredItems: oldItemsData.tableItems.itemsTable.filterInfo.totalNumberOfFilteredItems + 1
            },
            edges: [
              {
                __typename: 'TableItemsEdge',
                cursor: null,
                node: {
                  item: data,
                  values: data.values,
                  __typename: 'TableItemRow',
                }
              },
              ...oldItemsData.tableItems.itemsTable.edges
            ]
          }
        }
      }
    });

    dispatch(
      openAppSnackbarNotification({
        variant: SNACKBAR_TYPES.SUCCESS,
        message: (
          <CreateItemSuccessMessage
            id={data.id}
            title={data.title}
            code={data.code}
            onClick={() => handleToggleSidebar({ id: data.id })}
          />
        )
      })
    );

    setCreateItemDialogOpen(false);
  }, [dispatch, handleToggleSidebar, queryVariables]);

  const handleVideoDialogToggle = useCallback(() => {
    setVideoLink(state => !state ? VIDEO_LINKS.CREATE_ITEM : null);
  }, []);

  const handleHoverLink = useCallback(() => {
    track(TRACK_HELP_VIDEO.hover, {
      component: componentNames.HELP,
      additional_info: {
        hover_object: 'create_item'
      }
    });
  }, [track]);

  const handleToggle = useCallback(() => {
    setOpen(state => !state);
  }, []);

  const handleInputChange = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  const handleSubtract = useCallback(() => {
    setInputValue(state => state - 1);
  }, []);

  const handleAdd = useCallback(() => {
    setInputValue(state => parseInt(state) + 1);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      await createItems({
        variables: {
          tableId: table.id,
          count: parseFloat(inputValue)
        }
      });

      dispatch(openAppSnackbarNotification({
        message: 'Items were created',
        variant: SNACKBAR_TYPES.SUCCESS
      }));
    } catch (e) {
      console.error(e);

      dispatch(openAppSnackbarNotification({
        message: e.message,
        variant: SNACKBAR_TYPES.ERROR,
      }));
    }

    handleToggle();
    setInputValue(2);
  }, [createItems, dispatch, handleToggle, inputValue, table?.id]);

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        sx={{
          height: '28px',
          borderRadius: '4px'
        }}
      >
        <VideoTooltip
          primaryText={buttonTooltip}
          onClick={handleVideoDialogToggle}
          onHover={handleHoverLink}
          disabled={disabled}
          disableHoverListener={open}
        >
          <span>
            <Button
              disabled={disabled}
              onClick={handleCreateItemDialogOpen}
              sx={{
                width: '34px',
                minWidth: 'auto',
                padding: '5px 8px',
                fontSize: '18px',
                borderRadius: '4px',
                height: '28px'
              }}
            >
              <AddIcon
                fontSize="small"
              />
            </Button>
          </span>
        </VideoTooltip>

        <Button
          disabled={disabled}
          size="small"
          onClick={handleToggle}
          sx={{
            width: '18px',
            borderRadius: '4px',
            '&.MuiButtonBase-root': {
              minWidth: 'auto',
              padding: '0',
            }
          }}
        >
          <ArrowDropDownIcon
            fontSize="small"
          />
        </Button>
      </ButtonGroup>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
      >
        <ClickAwayListener onClickAway={handleToggle}>
          <Container>
            <Typography>
              Number of items
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row nowrap',
                margin: '4px 0 16px'
              }}
            >
              <StyledButton
                variant="outlined"
                position="left"
                onClick={handleSubtract}
              >
                <RemoveIcon fontSize="small" />
              </StyledButton>

              <StyledInput
                type="number"
                value={inputValue}
                onChange={handleInputChange}
              />

              <StyledButton
                variant="outlined"
                onClick={handleAdd}
              >
                <AddIcon fontSize="small" />
              </StyledButton>
            </Box>

            <LoadingButton
              variant="contained"
              onClick={handleSubmit}
              sx={{
                borderRadius: '4px',
                alignSelf: 'flex-end',
              }}
              loading={loading}
            >
              Add
            </LoadingButton>
          </Container>
        </ClickAwayListener>
      </Popper>


      {createItemDialogOpen ?
        <CreateItemDialog
          initialTable={table}
          onClose={handleCreateItemDialogClose}
          onSuccess={handleCreateItemDialogSuccess}
        /> :
        null
      }

      <VideoHelperDialog
        link={videoLink}
        onClose={handleVideoDialogToggle}
      />
    </>
  );
};

CreateItem.propTypes = {
  className: PT.string,
  buttonTooltip: PT.string,
  disabled: PT.bool,
  queryVariables: PT.object,
  table: PT.shape({
    id: PT.string,
    title: PT.string,
    viewerMaxRole: PT.string
  }),
  handleToggleSidebar: PT.func.isRequired,
};

export default CreateItem;
