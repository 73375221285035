import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import ParserPreviewStyled from '../../ParserPreview';

const RawHTML = ({
  className,
  data
}) => (
  <div
    className={className}
    dangerouslySetInnerHTML={{
      __html: data
    }}
  />
);

RawHTML.propTypes = {
  data: PropTypes.string,
  className: PropTypes.string.isRequired
};

export default styled(RawHTML)`
  max-height: 300px;
  height: 100%;
  overflow-y: auto;

  img {
    max-width: 100%;
    height: auto !important;
  }

  ${ParserPreviewStyled} & {
    height: 56px;
    overflow: hidden;
  }
`;
