import React, { useMemo } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import sortBy from 'lodash/sortBy';

import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import Chip from '@mui/material/Chip';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FormHelperText from '@mui/material/FormHelperText';
import useAutocomplete from '@mui/material/useAutocomplete';

import { VIEWER_ID } from '../../../services';

const USERS_QUERY = gql`
  query Users($statusFilters: [UserStatusFilter!]) {
    users(statusFilters: $statusFilters) {
      id
      name

      ...on CurrentUser {
        userId
        email
      }
    }
  }
`;

const Input = styled(InputBase)`
  width: 100%;
  height: 100%;
  flex-wrap: wrap;

  .MuiInputBase-input {
    height: 24px;
    padding: 0;

    :focus {
      outline: none;
    }
  }

  &.MuiInputBase-adornedStart {
    gap: 5px;
  }
`;

const PopperRoot = styled.div`
  z-index: 1301;
  position: relative;
  width: ${({ width }) => width + 'px'}
`;

const ListBox = styled(Paper)`
  max-height: 200px;
  overflow-y: auto;
  margin: 2px 0;
  padding: 0;
  border: 1px solid #DCDBDC;
  border-radius: 4px;
  list-style: none;

  li.Mui-focused {
    background: #eee;
    cursor: pointer;
  },
`;

const RecipientsSelect = ({ value, error, onChange }) => {
  const { loading, data } = useQuery(USERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      statusFilters: 'ACTIVE'
    }
  });

  const client = useApolloClient();

  const options = useMemo(() => {
    const { viewer } = client.readQuery({
      query: VIEWER_ID,
    });

    return sortBy(data?.users.filter(user => user.id !== viewer.id), [({ name }) => name.toLowerCase()]);
  }, [client, data?.users]);

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    setAnchorEl,
    anchorEl,
    focused,
    popupOpen
  } = useAutocomplete({
    id: 'recipients-list',
    multiple: true,
    options,
    filterSelectedOptions: true,
    getOptionLabel: (option) => {
      if(!option) return;

      const { name, userId } = option;

      return [
        name,
        userId
      ].join(' ');
    },
    onChange: (ev, value) => {
      onChange(value);
    },
    value,
    openOnFocus: true
  });

  return (
    <FormControl fullWidth error={Boolean(error)} required>
      <InputLabel
        variant="outlined"
        disableAnimation
        shrink
        sx={{
          transform: 'translate(0px, 0px) scale(1)',
          fontSize: '12px',
          lineHeight: '20px',
          color: 'rgba(0,0,0,.6)'
        }}
        {...getInputLabelProps()}
      >
        Recipients
      </InputLabel>

      <Box
        sx={{
          minHeight: '34px',
          mt: '22px',
          px: '12px',
          py: '4px',
          background: 'white',
          border: '1px solid #DCDBDC',
          borderRadius: '4px'
        }}
        ref={setAnchorEl}
        {...getRootProps()}
      >
        <Input
          startAdornment={value.length ?
            value.map(({ id, name }, index) => (
              <Chip
                key={id}
                label={name}
                size="small"
                {...getTagProps({ index })}
              />
            )) :
            null
          }
          sx={{
            '.MuiInputBase-input': {
              width: focused ? 'auto' : '1px'
            }
          }}
          inputProps={getInputProps()}
        />
      </Box>

      {anchorEl ?
        <Popper
          open={popupOpen}
          anchorEl={anchorEl}
          slots={{ root: PopperRoot }}
          slotProps={{ root: { width: anchorEl.clientWidth } }}
        >
          <ListBox
            component="ul"
            elevation={3}
            {...getListboxProps()}
          >
            {loading ?
              'Loading...' :
              null
            }

            {groupedOptions.length ?
              groupedOptions.map((option, index) => (
                                // the `key` prop is returned by the `getOptionProps` function
                                // eslint-disable-next-line react/jsx-key
                <ListItem dense {...getOptionProps({ option, index })}>
                  <ListItemText primary={option.name} secondary={option.userId} />
                </ListItem>
              )) :
              <ListItem dense>
                <ListItemText primary="Nothing fonud" />
              </ListItem>
            }
          </ListBox>
        </Popper> :
        null
      }

      {error ?
        <FormHelperText sx={{ ml: 0 }}>
          {error}
        </FormHelperText> :
        null
      }
    </FormControl>
  );
};

RecipientsSelect.propTypes = {
  value: PT.array,
  error: PT.string,
  onChange: PT.func
};

export default RecipientsSelect;
