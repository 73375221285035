import React, { useState, useCallback } from 'react';
import PT from 'prop-types';

import {
  TableCell,
  Box,
  Menu,
  MenuItem,
  IconButton,
  ListItemText
} from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import RemoveUserDialog from './RemoveUserDialog';

const ActionsCell = ({
  getValue,
  row: { data },
  table: {
    customState: {
      viewerId,
      readOnly,
      tableTitle,
      onRemoveUser
    } = {}
  },
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  const handleOpenMenu = useCallback(ev => {
    setMenuAnchorEl(ev.target);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const handleClose = useCallback(() => {
    setShowDialog(false);
  }, []);

  const handleRemove = useCallback(async () => {
    setShowDialog(true);
    setMenuAnchorEl(null);
  }, []);

  const handleSubmit = useCallback(async () => {
    const params = [];

    if (data.isOrganization) {
      params[1] = true;
    } else {
      params[0] = getValue();
    }

    await onRemoveUser(...params);

    setShowDialog(false);
  }, [getValue, data.isOrganization, onRemoveUser]);

  const showButton = !readOnly && (data.userId !== viewerId);

  return (
    <TableCell sx={{ p: '0 0 0 5px' }}>
      <Box>
        {showButton &&
          <IconButton
            disableRipple
            onClick={handleOpenMenu}
          >
            <MoreVertIcon sx={{ fontSize: '14px', mx: '5px' }} />
          </IconButton>
        }

        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleCloseMenu}
          MenuListProps={{
            'aria-labelledby': 'saved-views-button',
            dense: true
          }}
        >
          <MenuItem
            onClick={handleRemove}
            sx={{ px: '10px' }}
          >
            <ListItemText
              primary={`Remove ${data.isOrganization ? 'Organization' : 'User'}`}
            />
          </MenuItem>
        </Menu>
      </Box>

      {showDialog &&
        <RemoveUserDialog
          subjectName={data.fullName}
          tableName={tableTitle}
          onSubmit={handleSubmit}
          onClose={handleClose}
        />
      }
    </TableCell>
  );
};

ActionsCell.propTypes = {
  getValue: PT.func.isRequired,
  row: PT.shape({
    data: PT.shape({
      isOrganization: PT.bool,
      fullName: PT.string,
      userId: PT.string
    })
  }).isRequired,
  table: PT.shape({
    customState: PT.shape({
      tableTitle: PT.string,
      onRemoveUser: PT.func
    })
  }),
};

export default ActionsCell;
