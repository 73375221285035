import styled from 'styled-components';

import {
  COLOR_PRIMARY_CONTRAST,
  COLOR_PRIMARY_DARKER,
  COLOR_ACCENT
} from '../../styles';

const WarningBlockStyled = styled.div.attrs({ className: 'warning-block' })`
  padding: 16px;
  background: ${COLOR_PRIMARY_CONTRAST};
  color: ${COLOR_PRIMARY_DARKER};
  font-size: 12px;
  line-height: 2em;
  display: flex;

  & > *:first-child {
    margin-right: 16px;
    fill: ${COLOR_ACCENT}
  }
`;

export default WarningBlockStyled;
