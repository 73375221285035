export const firebase = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DB_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

export const general = {
  cloudappHost: process.env.REACT_APP_REST_API_HOST,
  wordpressHost: process.env.REACT_APP_WEBSITE_HOST,
  GQL_HTTP_PATH: process.env.REACT_APP_GQL_HTTP_PATH,
  GQL_WS_PATH: process.env.REACT_APP_GQL_WS_PATH,
};
