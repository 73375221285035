import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import { COLOR_PRIMARY } from '../../../../styles';
import Container from './styles';

const NoDataScreen = ({ title, text, link, linkText, onClick, onHover }) => {
  return (
    <Container>
      <Typography sx={{ fontWeight: 'bold', fontSize: '14px', color: '#000000' }}>
        {title}
      </Typography>

      <Typography sx={{ textAlign: 'center', color: '#888888' }}>
        {text}
      </Typography>

      {linkText &&
        <Typography
          component={Link}
          to={link}
          onClick={onClick}
          onMouseOver={onHover}
          style={{ color: COLOR_PRIMARY }}
        >
          <Typography>
            {linkText}
          </Typography>
        </Typography>
      }
    </Container>
  );
};

NoDataScreen.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
};

export default NoDataScreen;
