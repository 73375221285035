export const TABS = {
  STRUCTURE: {
    value: 'STRUCTURE',
    label: 'Structure'
  },
  MEMBERS: {
    value: 'MEMBERS',
    label: 'Members'
  }
};
