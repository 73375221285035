import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import get from 'lodash/get';

import withStyles from '@mui/styles/withStyles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import CoreBadge from '@mui/material/Badge';

import Tooltip from '../../../../HOC/Tooltip';
import ListContainer from './ListContainer';
import { GET_UNREAD_NOTIFICATIONS_COUNT } from './services';

import { COLOR_ERROR } from '../../../../styles';
import NotificationsStyled from './styles';

const Badge = Tooltip(CoreBadge);

const styles = {
  popoverPaper: {
    top: 64,
    right: 0,
    width: 500,
    maxHeight: 448
  },

  badge: {
    background: COLOR_ERROR,
    height: 14,
    minWidth: 14,
    top: 11,
    right: 11,
    cursor: 'pointer',
  }
};

const Notifications = ({ classes }) => {
  const [open, setOpen] = useState(false);

  const { data, updateQuery } = useQuery(GET_UNREAD_NOTIFICATIONS_COUNT, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 60 * 1e3,
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: 'cache-first'
  });

  const handleMenuToggle = useCallback(() => {
    setOpen(val => !val);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleItemRemove = useCallback((count = 1) => {
    updateQuery(cache => {
      const result = cache.unreadNotifications - count;

      return {
        ...cache,
        unreadNotifications: result < 0 ? 0 : result
      };
    });
  }, [updateQuery]);

  const handleItemRead = useCallback(read => {
    updateQuery(cache => {
      let result = cache.unreadNotifications;

      if (read) {
        --result;
      } else {
        ++result;
      }

      return {
        ...cache,
        unreadNotifications: result < 0 ? 0 : result
      };
    });
  }, [updateQuery]);

  const handleReadAll = useCallback(() => {
    updateQuery(cache => ({
      ...cache,
      unreadNotifications: 0
    }));
  }, [updateQuery]);

  const handleRemoveAll = useCallback(() => {
    updateQuery(cache => ({
      ...cache,
      unreadNotifications: 0
    }));
  }, [updateQuery]);

  return (
    <NotificationsStyled>
      <Badge
        overlap="circular"
        title="List"
        badgeContent={get(data, 'unreadNotifications', 0)}
        className="notifications-badge"
        classes={{ badge: classes.badge }}
        onClick={handleMenuToggle}
      >
        <IconButton
          className="notifications-toggler"
          color="inherit"
          aria-label="Menu"
          size="large"
        >
          <NotificationsIcon />
        </IconButton>
      </Badge>

      <Popover
        open={open}
        anchorReference="none"
        onClose={handlePopoverClose}
        classes={{ paper: classes.popoverPaper }}
      >
        <ListContainer
          onItemRemove={handleItemRemove}
          onItemRead={handleItemRead}
          onReadAll={handleReadAll}
          onRemoveAll={handleRemoveAll}
        />
      </Popover>
    </NotificationsStyled>
  );
};

Notifications.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Notifications);
