import { gql } from '@apollo/client';

export const GET_VIEWER = gql`
  query ViewerBasicSessionProfile {
    viewer {
      id
    }
  }
`;

export const UPDATE_TABLE = gql`
  mutation UpdateTable($id: ID!, $hash: String!, $data: UpdateTableInput!) {
    updateTable(id: $id, hash: $hash, data: $data) {
      id
      hash
      iam {
        organization
        users {
          userId
          name
          role
        }
      }
    }
  }
`;
