import { PARAMETER_VALUE_TYPES } from '../../../ParametersFilter/constants';
import { getParameterInitValueMap } from '../../../ParametersFilter/utils';
import { filterTypes } from '../index';

const createParameter = (data = {}, filterType) => {
  switch (filterType){
    case filterTypes.CREATOR_IDS:
    case filterTypes.CREATED_DATE:
    case filterTypes.ENUM: {
      return {
        filterType,
        value: data
      };
    }
    case filterTypes.PARAMETER:
    default:
    {
      const type = data?.valueType ?? PARAMETER_VALUE_TYPES.QUANTITY;

      return {
        type,
        featureId: data?.id,
        method: 'INCLUDE',
        value: getParameterInitValueMap({ type })
      };
    }
  }
};

export default createParameter;
