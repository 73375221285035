import React from 'react';
import PT from 'prop-types';

import InputBase from '@mui/material/InputBase';
import FormHelperText from '@mui/material/FormHelperText';

import ItemSelect from '../../../../../../../components/ItemSelect';

const ContentItemTitle = ({ data, disabled, error, onChange }) => {
  switch(data.type) {
    case 'LINK':
      return (
        <ItemSelect
          value={data.value}
          disabled={disabled}
          error={error}
          onChange={onChange}
        />
      );

    case 'TEXT':
    default:
      return (
        <>
          <InputBase
            value={data.value ?? ''}
            placeholder="Parameter title"
            onChange={ev => onChange(ev.target.value)}
            fullWidth
            sx={{
              height: '28px',
              px: '8px',
              py: '2px',
              background: 'white',
              border: '1px solid #DCDBDC',
              borderRadius: '4px',
              '.MuiInputBase-input': {
                padding: 0
              },
              fontSize: '14px',
              lineHeight: '18px'
            }}
            disabled={disabled}
          />

          {error ?
            <FormHelperText sx={{ ml: 0 }} error>
              {error.message}
            </FormHelperText> :
            null
          }
        </>
      );
  }
};

ContentItemTitle.propTypes = {
  data: PT.shape({
    type: PT.oneOf(['TEXT', 'LINK']),
    value: PT.oneOfType([PT.string, PT.object])
  }),
  disabled: PT.bool,
  error: PT.object,
  onChange: PT.func
};

export default ContentItemTitle;
