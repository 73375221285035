import { useEffect } from 'react';

const useFeaturesSync = ({
  onUpdate,
  allFeatures,
  selectedFeatures,
  disableParamsFilterSync
}) => {
  useEffect(() => {
    if(disableParamsFilterSync || !allFeatures) return;

    const featuresToUnselect = selectedFeatures?.reduce(
      (acc, v) => allFeatures[v.featureId] ? acc : acc.concat(v.featureId),
      []
    );

    if (featuresToUnselect?.length) {
      const filteredSliceBys = selectedFeatures.filter(i =>
        !featuresToUnselect.includes(i.featureId)
      );

      onUpdate(filteredSliceBys);
    }
  }, [onUpdate, allFeatures, selectedFeatures, disableParamsFilterSync]);
};

export default useFeaturesSync;
