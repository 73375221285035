import React from 'react';
import PropTypes from 'prop-types';
import zip from 'lodash/zip';
import uniq from 'lodash/uniq';
import xor from 'lodash/xor';

import ChartContainer from './ChartContainer';

import pearsonCorrelation from '../../../utils/graphics/pearson-correlation';
import mapValidData from '../../../utils/graphics/validate-columns';

const palette = [
  // cold
  '#084594',
  '#2171b5',
  '#4292c6',
  '#6baed6',
  '#9ecae1',
  '#c6dbef',
  '#eff3ff',

  // hot
  '#fef0d9',
  '#fdd49e',
  '#fdbb84',
  '#fc8d59',
  '#ef6548',
  '#d7301f',
  '#990000'
];

class CorrelationMatrix extends React.Component {
  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate(prevProps) {
    const prevCols = prevProps.columns;
    const currCols = this.props.columns;

    if(xor(prevCols, currCols).length) {
      this.chartContainerRef.innerHTML = '';

      this.renderChart();
    }
  }

  renderChart() {
    const { preview, columns } = this.props;

    const Bokeh = window.Bokeh;

    const plot_width = preview ? 150 : void 0;
    const plot_height = preview ? 150 : 400;
    const match_aspect = preview ? void 0 : true;
    const sizing_mode = preview ? void 0 : 'stretch_both';
    const toolbar_location = preview ? null : 'right';

    const tooltips = preview ? void 0 : [
      // ['Correlation items', '@x / @y'],
      ['Correlation value', `@corr{0[.]000}`]
    ];

    const tools = preview ? void 0 : [
      new Bokeh.SaveTool(),
      new Bokeh.HoverTool({ tooltips }),
      new Bokeh.PanTool(),
      new Bokeh.BoxZoomTool(),
      new Bokeh.ResetTool()
    ];

    const allTitles = this.props.data[0];
    const allData = mapValidData(allTitles, this.props.data.slice(1));

    const cols = columns.map(value => {
      const title = allTitles[value];
      const values = allData.map(row => row[value]);

      return { title, values };
    });

    const l = cols.length;
    const result = [];

    for(let xColIndex = 0; xColIndex < l; xColIndex++) {
      for(let yColIndex = 0; yColIndex < l; yColIndex++) {
        const xCol = cols[xColIndex];
        const yCol = cols[yColIndex];

        result.push([
          xCol.title,
          yCol.title,
          pearsonCorrelation([xCol.values, yCol.values], 0, 1)
        ]);
      }
    }

    const [x, y, corr] = zip(...result);
    const range = uniq(x);

    let source = new Bokeh.ColumnDataSource({
      data: { x, y, corr }
    });

    const plot = new Bokeh.Plotting.figure({
      x_range: range,
      y_range: range.slice().reverse(),
      // plot sizing config
      width: plot_width,
      height: plot_height,
      match_aspect,
      sizing_mode,

      // toolbar config
      toolbar_location,
      tools,

      x_axis_location: 'above'
    });

    const color = new Bokeh.LinearColorMapper({
      palette,
      high: 1,
      low: -1,
      name: 'corr'
    });

    const rect = new Bokeh.Rect({
      x: { field: 'x' },
      y: { field: 'y' },
      width: 1,
      height: 1,
      fill_color: {
        field: 'corr',
        transform: color
      },
      line_color: 'white'
    });

    plot.add_glyph(rect, source);

    if(preview) {
      plot.xaxis[0].visible = false;
      plot.yaxis[0].visible = false;
    } else {
      plot.toolbar.logo = null;

      plot.yaxis[0].axis_line_color = null;
      plot.yaxis[0].major_tick_line_color = null;
      plot.yaxis[0].major_label_text_font_size = '8px';
      plot.yaxis[0].major_label_standoff = 0;

      plot.xaxis[0].axis_line_color = null;
      plot.xaxis[0].major_tick_line_color = null;
      plot.xaxis[0].major_label_text_font_size = '8px';
      plot.xaxis[0].major_label_standoff = 0;
      plot.xaxis[0].major_label_orientation = Math.PI / 6;

      const color_bar = new Bokeh.ColorBar({
        color_mapper: color,
        location: [0, 0],
        ticker: new Bokeh.BasicTicker({ desired_num_ticks: palette.length })
      });

      plot.add_layout(color_bar, 'right');
    }

    Bokeh.Plotting.show(plot, this.chartContainerRef);
  }

  render() {
    return (
      <ChartContainer
        ref={ref => { this.chartContainerRef = ref; }}
        className={this.props.preview && 'preview'}
      />
    );
  }
}

CorrelationMatrix.propTypes = {
  data: PropTypes.array.isRequired,
  preview: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.number)
};

CorrelationMatrix.defaultProps = {
  columns: [0, 1]
};

export default CorrelationMatrix;
