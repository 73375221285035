import { init as initFullStory } from '@fullstory/browser';

export default function fullstory(userConfig) {

  return {
    name: 'fullstory',
    enabled: userConfig.enabled,
    initialize: () => {
      initFullStory({ orgId: 'o-1ZGMWQ-na1' });
    }
  };
}
