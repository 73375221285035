import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import DescriptionInput from './DescriptionInput';

import { openAppSnackbarNotification } from '../../../../services/snackbar-notifications/actions';
import UPDATE_TABLE from './gql';

const TableDescription = ({ id, description, hash, readOnly }) => {

  const [descriptionEdit, setDescriptionEdit] = useState(false);

  const [updateTable] = useMutation(UPDATE_TABLE);

  const dispatch = useDispatch();

  const handleToggleEditMode = useCallback(() => {
    setDescriptionEdit(state => !state);
  }, []);

  const handleDescriptionChange = useCallback(async (value) => {
    try {
      await updateTable({
        variables: {
          id,
          data: {
            description: value
          },
          hash
        }
      });
    } catch(e) {
      dispatch(openAppSnackbarNotification({
        message: e.message,
        variant: 'ERROR'
      }));
    }

    setDescriptionEdit(false);
  }, [updateTable, id, hash, dispatch]);

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      gap="10px"
    >
      {description ?
        <Typography
          variant="body2"
          color="rgba(0, 0, 0, 0.60)"
          lineHeight="24px"
        >
          Description:
        </Typography>
        : null
      }

      {descriptionEdit ?
        <DescriptionInput
          description={description ?? ''}
          onSubmit={handleDescriptionChange}
          onCancel={handleToggleEditMode}
        /> :
        <Box
          display="flex"
          alignItems="center"
          gap="10px"
          className="description"
          sx={{
            width: '100%',
            ':hover': {
              '.edit-button': {
                display: 'inline-flex'
              }
            }
          }}
        >
          {description ?
            <Typography
              variant="body2"
              color="rgba(0, 0, 0, 0.60)"
              lineHeight="24px"
              sx={{
                maxWidth: 'calc(100% - 34px)'
              }}
            >
              {description}
            </Typography>
            : null
          }

          {!description && !readOnly ?
            <Typography
              variant="body2"
              color="rgba(152, 152, 152, 0.6);"
            >
              Describe the table here
            </Typography>
            : null
          }

          {readOnly ?
            null :
            <IconButton
              className="edit-button"
              size="small"
              onClick={handleToggleEditMode}
              sx={{
                fontSize: '14px',
                display: 'none'
              }}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          }
        </Box>
      }
    </Box>
  );
};

TableDescription.propTypes = {
  id: PT.string.isRequired,
  description: PT.string,
  hash: PT.string.isRequired,
  readOnly: PT.bool
};

export default TableDescription;
