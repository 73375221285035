import React, { useMemo } from 'react';
import PT from 'prop-types';

import Table from './Table';
import { flexRender } from '../../hooks/table';
import { StyledRow } from './styles';

const TableContainer = ({ table }) => {
  const colgroups = useMemo(() => {
    return table
      .headerGroups[0]
      ?.headers
      .map(({ id, colSpan, column }) => ({
        id,
        colSpan: column.isCollapsed() ? 1 : colSpan
      })) ?? [];
  }, [table.headerGroups]);

  return (
    <Table colgroups={colgroups} noBorders>
      {table.headerGroups.map(({ id, headers }) => (
        <tr key={id} className="head">
          {headers.map(header => {
            if (header.column.parent?.isCollapsed())
              return null;

            return (
              <React.Fragment key={header.id}>
                {flexRender(header.column.columnDef.header, { header, table })}
              </React.Fragment>
            );
          })}
        </tr>
      ))}

      {table.rows.map(i => renderRow(i, table.customState.collapsedRows.byId))}
    </Table>
  );
};

TableContainer.propTypes = {
  table: PT.object.isRequired
};

export default TableContainer;

function renderRow(row, collapsedRows) {
  const rowElement = (
    <StyledRow key={row.id}>
      {row.getCells().map(cell => {
        return (
          <React.Fragment key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </React.Fragment>
        );
      })}
    </StyledRow>
  );

  const items = [rowElement];
  const collapsed = collapsedRows[row.id] ?? true;

  if (!collapsed && row.children) {
    items.push(
      row.children.map(i => renderRow(i, collapsedRows))
    );
  }

  return items;
}
