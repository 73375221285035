import React from 'react';
import PT from 'prop-types';
import { Box } from '@mui/material';

import RawText from '../RawText';

const ViewTextValue = ({ data, onClick }) => (
  <Box
    onClick={onClick}
    height="100%"
    width="100%"
    display="flex"
    alignItems="center"
  >
    <RawText title={data}>
      {data}
    </RawText>
  </Box>
);

ViewTextValue.propTypes = {
  onClick: PT.func,
  data: PT.string
};

export default ViewTextValue;
