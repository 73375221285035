const updateParameters = (items) => {
  return items.map(item => {
    const children = item.children
      .map((feature) => {
        return {
          data: feature,
          label: feature.title,
          value: feature.id
        };
      });

    return {
      label: item.title,
      children
    };
  }, []);
};

export default updateParameters;
