import React, { useCallback, useState } from 'react';
import PT from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { useAnalytics } from 'use-analytics';

import Box from '@mui/material/Box';

import SavedView from './SavedView';
import RestViews from './RestViews';
import NoDataScreen from '../NoDataScreen';

import VideoHelperDialog from '../../../../components/VideoHelperDialog';

import { Content, Title } from './styles';
import VIDEO_LINKS from '../../../../components/VideoHelperDialog/constants';
import { componentNames, TRACK_HELP_VIDEO } from '../../../../analytics/constants';

const SAVED_VIEWS = gql`
  query UserTableItemQueries {
    userTableItemQueries {
      id
      title
      dashboardId
    }
  }
`;

const SavedViews = () => {
  const { track } = useAnalytics();

  const { data } = useQuery(SAVED_VIEWS, {
    fetchPolicy: 'cache-and-network'
  });

  const [videoLink, setVideoLink] = useState(null);

  const handleVideoDialogToggle = useCallback(() => {
    setVideoLink(state => !state ? VIDEO_LINKS.SAVED_VIEW : null);
  }, []);

  const handleHoverLink = useCallback(() => {
    track(TRACK_HELP_VIDEO.hover, {
      component: componentNames.HELP,
      additional_info: {
        hover_object: 'saved_views'
      }
    });
  }, [track]);

  const savedViews = data?.userTableItemQueries ?? [];
  const displayedViews = savedViews.slice(0, 6);
  const restViews = savedViews.slice(6);

  return (
    <Box
      mt="16px"
      display="flex"
      flexDirection="column"
    >
      <Title>
        My recent views
      </Title>

      <Content>
        {displayedViews.map(({ id, title, dashboardId }) => (
          <SavedView
            key={id}
            id={id}
            title={title}
            dashboardId={dashboardId}
          />
        ))
        }

        {!displayedViews.length ?
          <NoDataScreen
            title="There are no saved views"
            text="Save views on the items page to easily access your data"
            linkText="Learn more"
            onClick={handleVideoDialogToggle}
            onHover={handleHoverLink}
          />
          : null
        }

        {restViews.length ?
          <RestViews views={restViews} /> :
          null
        }

        <VideoHelperDialog
          link={videoLink}
          onClose={handleVideoDialogToggle}
        />
      </Content>
    </Box>
  );
};

SavedViews.propTypes = {
  className: PT.string
};

export default SavedViews;
