import styled from 'styled-components';

import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';

import { COLOR_GREY_LIGHTER } from '../../../../styles';

export const Container = styled(Paper)`
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin-top: 8px;
    width: 250px;
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.14);
    border: 1px solid #D9D9D9;
    border-radius: 4px;
`;

export const StyledInput = styled(OutlinedInput)`
    height: 28px;
    div {
        border: 1px solid #D9D9D9;
    }
    .MuiInputBase-input {
        text-align: center;
    }
    .Mui-focused {
      border: 1px solid #D9D9D9;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #EEE !important;
      border-radius: 0;
      border-right: none !important;
      border-left: none !important;
    }
    &:hover, &:focus {
      .MuiOutlinedInput-notchedOutline{
        border: 1px solid #EEE;
        border-right: none;
        border-left: none;
      }
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

export const StyledButton = styled(Button)`
    border: 1px solid #EEE;
    border-radius: ${({ position }) => position === 'left' ? '4px 0 0 4px' : '0 4px 4px 0'};
    width: 28px;
    min-width: 28px;
    height: 28px;
    padding: 0;
    svg {
      color: ${COLOR_GREY_LIGHTER};
    }
`;
