import React, { memo, useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import withStyles from '@mui/styles/withStyles';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PropTypes from 'prop-types';

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'fixed',
    zIndex: '100',
    right: 0,
    left: 0,
    top: 0,
    background: 'linear-gradient(to bottom,rgba(0,0,0,.65) 0%,transparent 100%)',
    color: '#fff',
    height: '48px',
    paddingRight: '12px',
    paddingLeft: '12px',
    paddingTop: '8px'
  },
  headerLoading: {
    width: 0,
    height: 0
  },
  infoSection: {
    display: 'flex',
    alignItems: 'center',
  },
  closeButton: {
    color: '#fff',
    borderRadius: 'unset',

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.25)'
    }
  },
  documentIcon: {
    color: '#fff',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  downloadButton: {
    color: '#fff',
    marginRight: '16px',
    borderRadius: 'unset',

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.25)'
    }
  }
};

function DialogHeader({ documentLoaded, fileUrl, onClose, data, classes }) {
  const onDownload = useCallback((uri) => {
    let link = document.createElement('a');

    link.setAttribute('download', name);
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }, []);

  return (
    <div className={documentLoaded ? classes.header : classes.headerLoading}>
      <div className={classes.infoSection}>
        <IconButton
          className={classes.closeButton}
          onClick={() => onClose()}
          data-testid="close-btn"
        >
          <ArrowBackIcon />
        </IconButton>
        <InsertDriveFileOutlinedIcon className={classes.documentIcon} />
        {data.filename}
      </div>
      <div>
        <IconButton
          className={classes.downloadButton}
          onClick={() => onDownload(fileUrl, data.filename)}
        >
          <FileDownloadOutlinedIcon />
        </IconButton>
      </div>
    </div>
  );
}

DialogHeader.propTypes = {
  documentLoaded: PropTypes.bool,
  fileUrl: PropTypes.string,
  data: PropTypes.shape({
    filename: PropTypes.string.isRequired,
    location: PropTypes.object,
    sourceFile: PropTypes.object
  }),
  onClose: PropTypes.func,
  classes: PropTypes.object,
};

export default withStyles(styles)(
  memo(DialogHeader)
);
