import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';

const DescriptionFormControl = ({
  disabled,
  classes,
  elementId,
  placeholder,
  onChange,
  description
}) => (
  <FormControl
    disabled={disabled}
    fullWidth
    classes={{ root: classes.formControlRoot }}
  >
    <InputLabel
      className={classes.inputLabel}
      htmlFor={elementId}
      shrink
    >
      Description
    </InputLabel>

    <InputBase
      id={elementId}
      name={elementId}
      placeholder={placeholder}
      onChange={onChange}
      value={description}
      multiline
      rows={3}
    />
  </FormControl>
);

DescriptionFormControl.propTypes = {
  classes: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  elementId: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DescriptionFormControl;
