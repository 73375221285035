import React from 'react';
import PropTypes from 'prop-types';

import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';

const TitleFormControl = ({
  classes,
  disabled,
  elementId,
  title,
  onChange,
  placeholder
}) => (
  <FormControl
    disabled={disabled}
    fullWidth
    required
    classes={{ root: classes.formControlRoot }}
  >
    <InputLabel
      className={classes.inputLabel}
      htmlFor={elementId}
      shrink
    >
      Title
    </InputLabel>

    <InputBase
      id={elementId}
      name={elementId}
      placeholder={placeholder}
      onChange={onChange}
      value={title}
      inputProps={{
        maxLength: 200
      }}
    />

    <FormHelperText className={classes.helperText}>
      Maximum 200 symbols
    </FormHelperText>
  </FormControl>
);

TitleFormControl.propTypes = {
  classes: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  elementId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default TitleFormControl;
