import React from 'react';
import PT from 'prop-types';

import Fullscreen from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import ActionDivider from './ActionDivider';

const ShortGeneralActions = ({ isMobileView, onFullScreen, onClose }) => (
  <>
    {!isMobileView ?
      <>
        <ActionDivider />

        <Tooltip title="Open fullscreen">
          <IconButton onClick={onFullScreen}>
            <Fullscreen fontSize="small" />
          </IconButton>
        </Tooltip>
      </>
      : null
    }

    <ActionDivider />

    <Tooltip title="Close">
      <IconButton onClick={onClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  </>
);

ShortGeneralActions.propTypes = {
  isMobileView: PT.bool,
  onFullScreen: PT.func.isRequired,
  onClose: PT.func.isRequired
};

export default ShortGeneralActions;
