import React from 'react';
import { useQuery } from '@apollo/client';

import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import SingleMember from './SingleMember';
import NoDataScreen from '../NoDataScreen';

import { FETCH_TOP_USERS } from '../../../../services/users/gql';

import { Title, Wrapper } from './styles';

const ActiveMembers = () => {

  const { data } = useQuery(FETCH_TOP_USERS);

  const users = data?.platformStats.topUsers;

  return (
    <Wrapper>
      <Title>
        Active members
      </Title>

      {users?.length ? <Divider /> : null}

      <Box
        mt="8px"
        sx={{ display: 'flex', flex: '1 0', flexDirection: 'column' }}
      >
        {users?.length ?
          users.map(user => (
            <SingleMember
              key={user.id}
              name={user.name}
              color={user.avatarColor}
              src={user.picture.downloadURL}
              lastActivity={user.lastActive}
            />
          ))
          :
          <NoDataScreen
            title="You dont have any members yet..."
          />
        }
      </Box>
    </Wrapper>
  );
};

export default ActiveMembers;
