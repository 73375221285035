import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import PT from 'prop-types';

import EditBooleanValue from './EditBooleanValue';
import ViewBooleanValue from './ViewBooleanValue';
import EditableValueContainer from '../../EditableValueContainer';

import { UPDATE_FEATURE_VALUE } from '../gql';
import { openAppSnackbarNotification } from '../../../../../../services/snackbar-notifications/actions';

const BooleanValue = ({ data, itemId, tableParameter, readOnly, onUpdateValue, ...props }) => {
  const dispatch = useDispatch();

  const {
    boolean = null,
  } = data;

  const [updateFeatureValue] = useMutation(UPDATE_FEATURE_VALUE);

  const handleUpdate = useCallback(async (newValue) => {
    const valuesInput = { boolean: newValue };
    const variablesInput = {
      tableParameterId: tableParameter.id,
      tableItemId: itemId,
      ...valuesInput
    };

    try {
      const response = await updateFeatureValue({
        variables: { input: variablesInput },
        optimisticResponse: {
          updateTableValue: {
            tableValue: {
              __typename: 'TableValue',
              type: tableParameter.valueType,
              quantity: null,
              text: null,
              linkedTableItem: null,
              calculationError: null,
              enumValues: null,
              featureId: tableParameter.featureId,
              ...variablesInput,
            },
            dependentTableValues: []
          }
        }
      });

      onUpdateValue(response.data.updateTableValue.tableValue);
    } catch (e) {
      console.error(e);

      dispatch(
        openAppSnackbarNotification({
          variant: 'ERROR',
          message: e.message
        })
      );
    }
  }, [dispatch, itemId, tableParameter, onUpdateValue, updateFeatureValue]);

  return (
    <EditableValueContainer
      {...props}

      readOnly={readOnly}
      defaultValue={boolean}
      onUpdate={handleUpdate}
      viewComponent={ViewBooleanValue}
      editComponent={EditBooleanValue}
    />
  );
};

BooleanValue.propTypes = {
  readOnly: PT.any,
  itemId: PT.string.isRequired,
  tableParameter: PT.shape({
    id: PT.string,
    featureId: PT.string,
    valueType: PT.string
  }),
  data: PT.shape({
    boolean: PT.bool,
    type: PT.string
  }).isRequired,
  onUpdateValue: PT.func
};

export default BooleanValue;
