import { put, call, all } from 'redux-saga/effects';
import { isUndefined, isPlainObject, isNil } from 'lodash';
import { errorFactory } from './utils';

import { listeners as sessionListeners } from './services/session/sagas';
import { listeners as commonListeners } from './services/common/sagas';
import { listeners as measurementsListeners } from './services/measurements/sagas';

export default function* root() {
  yield all([
    ...sessionListeners,
    ...measurementsListeners,
    ...commonListeners
  ]);
}

export function* fetchEntity(entity, opts, ...params) {
  let apiFn;
  let requiredFields;

  if (typeof opts === 'function') {
    apiFn = opts;
  } else if (isPlainObject(opts)) {
    apiFn = opts.apiFn;
    requiredFields = opts.requiredFields;

    if (requiredFields && !Array.isArray(requiredFields)) {
      throw new Error('[fetchEntity] requiredFields opt should be an array');
    }
  } else {
    throw new Error('[fetchEntity] Incorrect opts param');
  }

  if (requiredFields) {
    let body;

    if (isPlainObject(params[0])) {
      body = params[0];
    } else if (isPlainObject(params[1])) {
      body = params[1];
    } else {
      throw new Error('[fetchEntity] Incorrect opts param: body object is not exist');
    }

    if (!requiredFields.every(i => !isNil(body[i]))) {
      yield put( entity.failure(
        errorFactory('fetchEntity', 'Missed required fields')
      ));
    }
  }

  const { response, error } = yield call(apiFn, ...params);

  if(!isUndefined(response) && isNil(error)) {
    yield put( entity.success(response) );
  } else {
    yield put( entity.failure(error) );
  }
}
