import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import isGreater from './isGreater';

const TOO_BIG_ERROR = 'The min value cannot be larger than the max value';

const minValueValidator = ({ parameter, values }) => value => {
  const isValueExist = !isEmpty(value);
  const isMaxLimitExist = !isNil(parameter?.quantityMax);

  if (isValueExist) {
    const shouldReturnError =
      (isMaxLimitExist && isGreater(value, parameter.quantityMax))
      || (!isEmpty(values.max) && isGreater(value, values.max));

    if (shouldReturnError) {
      return TOO_BIG_ERROR;
    }
  }


};

export default minValueValidator;
