import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import difference from 'lodash/difference';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

import ExpandMore from '@mui/icons-material/ExpandMore';

import ListItem from '../ListItem';
import ExtendedListItem from '../ExtendedListItem';

import getAllIds from '../utils';

import { IconStyled, ItemWrapper } from './styles';

const depthParams = {
  DEEP_TABLE: 26,
  SINGLE_TABLE: 0
};

const styles = {
  expandMore: {
    width: '20px'
  },
  expandLess: {
    transform: 'rotate(270deg)',
    width: '20px'
  },
  folder: {
    '.MuiTypography-root': {
      fontWeight: '500',
    }
  },
  emptyTable: {
    paddingLeft: '24px'
  },
  collapse: {
    maxWidth: '100%'
  },
};

const SingleFolder = ({
  table,
  expandedList,
  value,
  depth = 1,
  onToggle,
  onChange,
  isParent,
  isFiltered,
}) => {
  const ref = useRef();

  const allChildren = useMemo(() => getAllIds(table.children), [table]);

  const isChecked = useMemo(() => {
    return isParent && !!allChildren.length && difference(allChildren, value).length === 0;
  }, [allChildren, isParent, value]);

  const isIntermediate = useMemo(() => isParent &&
      difference(value, allChildren).length < value.length && difference(allChildren, value).length > 0,
  [allChildren, isParent, value]);

  const handleChange = useCallback((e) => {
    onChange(e, allChildren);
  }, [allChildren, onChange]);

  const handleToggle = useCallback((e) => {
    const checker = ref.current && ref.current.contains(e.target);
    if (checker && e.target.type === 'checkbox') return;

    onToggle(table.id);
  }, [onToggle, table.id]);

  const paddingDepth = useMemo(() => {
    if (depth > 1)
      return depthParams.DEEP_TABLE;
    else return depthParams.SINGLE_TABLE;
  }, [depth]);

  const isExpanded = expandedList[table.id] || isFiltered;
  const isDisabled = !table.children || allChildren.length === 0;

  return (
    <Box sx={{ paddingLeft: `${paddingDepth}px`, maxWidth: '100%' }}>
      <ItemWrapper onClick={handleToggle}>
        {isParent ?
          <IconStyled>
            {isExpanded ?
              <ExpandMore sx={styles.expandMore} /> :
              <ExpandMore sx={styles.expandLess} />
            }
          </IconStyled> :
          null
        }

        <ListItem
          key={table.id}
          ref={ref}
          onChange={handleChange}
          title={table.title}
          number={table.number}
          checked={isChecked}
          intermediate={isIntermediate}
          disabled={isDisabled}
          tooltip={isDisabled ? `Empty folders cannot be selected` : null}
          additionalStyles={{
            ...styles.folder,
            ...(!isParent ? styles.emptyTable : null)
          }}
        />
      </ItemWrapper>

      {isParent ?
        <Collapse sx={styles.collapse} in={isExpanded} timeout="auto" unmountOnExit>
          {table.children.map((table) => (
            <ExtendedListItem
              key={table.id}
              isFolder={table.isFolder}
              onToggle={onToggle}
              depth={depth + 1}
              onChange={onChange}
              value={value}
              table={table}
              checked={value.includes(table.id)}
              isParent={Boolean(table.children?.length)}
              isFiltered={isFiltered}
              expandedList={expandedList}
            />
          ))}
        </Collapse> :
        null
      }
    </Box>
  );
};

SingleFolder.propTypes = {
  table: PropTypes.object.isRequired,
  expandedList: PropTypes.object.isRequired,
  value: PropTypes.array.isRequired,
  depth: PropTypes.number,
  onToggle: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isParent: PropTypes.bool,
  isFiltered: PropTypes.bool,
};

export default SingleFolder;
