const ENTER_KEY = 'Enter';
const ESCAPE_KEY = 'Escape';

export const interceptKeyboardEvent = ({ event, onEnter, onEscape }) => {
  if (event.key === ENTER_KEY) {
    event.stopPropagation();
    event.preventDefault();

    onEnter();
  } else if (event.key === ESCAPE_KEY) {
    event.stopPropagation();
    event.preventDefault();

    onEscape();
  }
};
