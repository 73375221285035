import React  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import AuthPageStyled from '../../components/AuthPageStyled';
import LogoMain from '../../components/Logos/LogoMain';
import SignUpSocialProvider from './SignUpSocialProvider';
import SignupWithPassword from './SignupWithPassword';
import {
  signUpWithEmailAndPassword,
  signUpWithOAuth
} from '../../services/session/actions';
import {
  getError,
  getErrorMsg
} from '../../services/session/selectors';
import useDocumentTitle from '../../hooks/document-title';

const mapStateToProps = state => ({
  error: getError(state),
  errorMessage: getErrorMsg(state)
});

const mapDispatchToProps = {
  signUpWithEmailAndPassword,
  signUpWithOAuth
};

const Signup = (props) => {

  useDocumentTitle('Sign Up');

  const checkError = source => {
    const { error } = props;

    return error && error.source === source;
  };

  const {
    errorMessage,
    signUpWithOAuth,
    signUpWithEmailAndPassword
  } = props;

  return (
    <AuthPageStyled>
      <div className="header">
        <LogoMain />
      </div>

      {checkError('general') ||
          (checkError('email') && (
            <div className="auth-error-container">{errorMessage}</div>
          ))
      }
      <h1>Let's get started</h1>
      <p className="contact-text">
        Materials Zone is accessible by <b>invitation only</b>. If you received an email with an invitation, you can sign up on this page.
        Otherwise, please <a href="mailto:contact@materials.zone">contact our sales team</a> for more information on how to join the Materials Zone platform.
      </p>

      <div className="auth-forms-container">
        <SignUpSocialProvider onSubmit={signUpWithOAuth} />

        <div className="vertical-division">or</div>

        <SignupWithPassword onSubmit={signUpWithEmailAndPassword} />
      </div>

      <p className="change-auth-flow">
        Already have a team in Materials.Zone?{' '}
        <Link to="/signin">Sign in</Link>
      </p>
    </AuthPageStyled>
  );
};

Signup.propTypes = {
  error: PropTypes.object,
  errorMessage: PropTypes.string,
  signUpWithEmailAndPassword: PropTypes.func.isRequired,
  signUpWithOAuth: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Signup);
