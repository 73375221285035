import { useReducer, useCallback } from 'react';

import { getParserRuntimes } from '../../../../../utils/parsing';
import { callApi } from '../../../../../api';
import { getFileFromUrl } from '../../../../../utils';

const createInitialState = () => ({
  loading: false,
  data: null,
  error: null
});

const actions = {
  PARSE: 'PARSE',
  ON_DATA: 'ON_DATA',
  ON_ERROR: 'ON_ERROR',
  RESET: 'RESET'
};

const reducer = (state, action) => {
  switch(action.type) {
    case actions.PARSE:
      return {
        ...state,
        loading: true,
        data: null,
        error: null
      };

    case actions.ON_DATA:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null
      };

    case actions.ON_ERROR:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload.error
      };

    case actions.RESET:
      return createInitialState();

    default:
      return state;
  }
};

const RESPONSE_TYPES = {
  DATA_FILE: 'file',
  DATA_JSON: 'json',
  DATA_TEXT: 'text',
  DATA_COMPLEX: 'complex'
};

const useParseFile = () => {
  const [state, dispatch] = useReducer(reducer, null, createInitialState);

  const parse = useCallback(async (sourceFile, parsingConfig) => {
    dispatch({ type: actions.PARSE });

    const { code, parser } = parsingConfig;

    if(parsingConfig.sharingSettings === 'PUBLIC_SKIP_PARSING') {
      try {
        const data = await getFileFromUrl(
          sourceFile.downloadURL,
          sourceFile.filename
        );

        dispatch({
          type: actions.ON_DATA,
          payload: { data }
        });

        return data;
      } catch(e) {
        dispatch({
          type: actions.ON_ERROR,
          payload: { error: 'Failed to fetch file' }
        });


      }
    } else {
      const queryParams = [
        `id=${sourceFile.id}`,
        `parser=${parser.filename}`,
        `runtime=${getParserRuntimes()[parser.runtime]}`,
        `mtype=${parser.dataType === 'DATA_FILE'}`,
        `src=cloud`,
        `parserCode=${code}`,
        `complex=${parser.dataType === 'DATA_COMPLEX'}`
      ];

      const { response, error } = await callApi(
        `/web/v1/parse?${queryParams.join('&')}`,
        {},
        parser.dataType === 'DATA_COMPLEX' ?
          RESPONSE_TYPES.DATA_JSON :
          RESPONSE_TYPES[parser.dataType]
      );

      if(error) {
        dispatch({
          type: actions.ON_ERROR,
          payload: { error }
        });

        return;
      }

      dispatch({
        type: actions.ON_DATA,
        payload: {
          data: response
        }
      });

      return response;
    }
  }, []);

  const reset = useCallback(() => {
    dispatch({ type: actions.RESET });
  }, []);

  return [
    parse,
    {
      ...state,
      reset
    }
  ];
};

export default useParseFile;
