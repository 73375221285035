export const PLOT_MIN_WIDTH = 100;
export const PLOT_MIN_HEIGHT = 100;
export const DEFAULT_MARGINS = {
  top: 10,
  right: 10,
  bottom: 10,
  left: 10
};
export const PAPER_MIN_WIDTH = PLOT_MIN_WIDTH + DEFAULT_MARGINS.right + DEFAULT_MARGINS.left;
export const PAPER_MIN_HEIGHT = PLOT_MIN_HEIGHT + DEFAULT_MARGINS.top + DEFAULT_MARGINS.bottom;
export const LEGEND_ITEM_HEIGHT = 20;
export const X_AXIS_SIZE = 40;
export const Y_AXIS_SIZE = 60;

// Surface graphs
export const PLAYGROUND_SIZE = 1000;
export const CAMERA_FOV = 45;
export const CAMERA_NEAR = 1;
export const CAMERA_FAR = PLAYGROUND_SIZE * 10;
export const TICKS_COUNT_X = 10;
export const TICKS_COUNT_Y = 10;
export const TICKS_COUNT_Z = 10;
export const PLOT_COLORS = {
  positive: [0x8bc34a, 0xa5b522, 0xbfa400, 0xd79000, 0xed7700, 0xff5722],
  negative: [0x00bcd4, 0x00aad6, 0x0096d6, 0x0081d2, 0x006bc7, 0x3f51b5]
};
export const GRAPH_DIMENSIONS = {
  W: PLAYGROUND_SIZE,
  H: PLAYGROUND_SIZE,
  D: PLAYGROUND_SIZE
};

export const GRAPHICS_TYPES_OLD = { // to keep supporting logs with DEPRECATED graphics-type
  GRAPHICS_TYPE_IMAGE: 'image',
  GRAPHICS_TYPE_LINE_CHART: 'linechart',
  GRAPHICS_TYPE_SURFACE: 'surface',
  GRAPHICS_TYPE_HEATMAP: 'Heatmap',
  GRAPHICS_TYPE_TABLE: 'table',
  GRAPHICS_TYPE_RAW_HTML: 'text',
  GRAPHICS_TYPE_SCATTER_PLOT: 'SCATTER_PLOT',
  GRAPHICS_TYPE_BOX_PLOT: 'BOX_PLOT',
  GRAPHICS_TYPE_HISTOGRAM_SINGLE: 'HISTOGRAM_SINGLE',
  GRAPHICS_TYPE_VECTOR_WAFER_MAP: 'VECTOR_WAFER_MAP',
  GRAPHICS_TYPE_WAFER_MAP: 'WAFER_MAP',
  GRAPHICS_TYPE_CORRELATION_MATRIX: 'CORRELATION_MATRIX',
  GRAPHICS_TYPE_COMPLEX: 'COMPLEX'
};

export const BACKEND_GRAPHICS_TYPES = Object.freeze({
  GRAPHICS_TYPE_IMAGE: 'GRAPHICS_TYPE_IMAGE',
  GRAPHICS_TYPE_LINE_CHART: 'GRAPHICS_TYPE_LINE_CHART',
  GRAPHICS_TYPE_SURFACE: 'GRAPHICS_TYPE_SURFACE',
  GRAPHICS_TYPE_HEATMAP: 'GRAPHICS_TYPE_HEATMAP',
  GRAPHICS_TYPE_TABLE: 'GRAPHICS_TYPE_TABLE',
  GRAPHICS_TYPE_RAW_HTML: 'GRAPHICS_TYPE_RAW_HTML',
  GRAPHICS_TYPE_SCATTER_PLOT: 'GRAPHICS_TYPE_SCATTER_PLOT',
  GRAPHICS_TYPE_BOX_PLOT: 'GRAPHICS_TYPE_BOX_PLOT',
  GRAPHICS_TYPE_HISTOGRAM_SINGLE: 'GRAPHICS_TYPE_HISTOGRAM_SINGLE',
  GRAPHICS_TYPE_VECTOR_WAFER_MAP: 'GRAPHICS_TYPE_VECTOR_WAFER_MAP',
  GRAPHICS_TYPE_WAFER_MAP: 'GRAPHICS_TYPE_WAFER_MAP',
  GRAPHICS_TYPE_CORRELATION_MATRIX: 'GRAPHICS_TYPE_CORRELATION_MATRIX',
  GRAPHICS_TYPE_COMPLEX: 'GRAPHICS_TYPE_COMPLEX',
});

export const GRAPHICS_TYPES = Object.freeze({
  ...BACKEND_GRAPHICS_TYPES,
  GRAPHICS_TYPE_PDF: 'GRAPHICS_TYPE_PDF',
});

const VIEW_MODE_GRAPH = 'VIEW_MODE_GRAPH';
const VIEW_MODE_TABLE = 'VIEW_MODE_TABLE';

export const VIEW_MODES = Object.freeze({
  VIEW_MODE_GRAPH,
  VIEW_MODE_TABLE
});

export const GRAPHICS_MODES = Object.freeze({
  VIEW_MODE_GRAPH: 'VIEW_MODE_GRAPH',
  VIEW_MODE_TABLE: 'VIEW_MODE_TABLE'
});
