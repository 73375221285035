import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import { TableContainer } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import LinkedItem from './LinkedItem';
import { HeaderContent, ToggleButtonStyled } from './styles';

import { TABS } from './constants';

const LinkedItemsTab = ({ data, onProtocolClick }) => {

  const itemsFrom = data?.from;
  const itemsTo = data?.to;

  const [activeTab, setActiveTab] = useState(itemsTo?.length ? TABS.TO : TABS.FROM);

  const handleClick = useCallback((e) => {
    if (e.target.value)
      setActiveTab(e.target.value);
  }, []);

  const items = useMemo(() => activeTab === TABS.TO ? itemsTo : itemsFrom,
    [activeTab, itemsFrom, itemsTo]);

  useEffect(() => {
    if (activeTab === TABS.TO && itemsTo && !itemsTo?.length){
      setActiveTab(TABS.FROM);
    }

    if (activeTab === TABS.FROM && itemsFrom && !itemsFrom?.length){
      setActiveTab(TABS.TO);
    }
  }, [activeTab, itemsFrom, itemsFrom?.length, itemsTo, itemsTo?.length]);

  return (
    <Box >
      <ToggleButtonGroup
        value={activeTab}
        exclusive
        onClick={handleClick}
        sx={{
          paddingBottom: '16px'
        }}
      >
        <ToggleButtonStyled
          value={TABS.TO}
          active={activeTab === TABS.TO ? 'true' : undefined}
          disabled={!itemsTo?.length}
          selected={activeTab === TABS.TO}
        >
          {`Linked to this item (${itemsTo?.length || 0})`}
        </ToggleButtonStyled>

        <ToggleButtonStyled
          value={TABS.FROM}
          active={activeTab === TABS.FROM ? 'true' : undefined}
          disabled={!itemsFrom?.length}
        >
          {`Linked from this item (${itemsFrom?.length || 0})`}
        </ToggleButtonStyled>
      </ToggleButtonGroup>

      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <HeaderContent>
                <TableCell
                  align="left"
                >
                  Item
                </TableCell>
                <TableCell
                  align="left"
                >
                  Protocol
                </TableCell>
                <TableCell align="left" />
                <TableCell align="left" />
              </HeaderContent>
            </TableHead>
            <TableBody>
              {items?.map((rowData) => (
                <LinkedItem
                  key={rowData.id}
                  data={rowData}
                  activeTab={activeTab}
                  onProtocolClick={onProtocolClick}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

LinkedItemsTab.propTypes = {
  data: PropTypes.object,
  onProtocolClick: PropTypes.func.isRequired,
};

export default LinkedItemsTab;
