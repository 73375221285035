import styled from 'styled-components';

import ToggleButton from '@mui/material/ToggleButton';

import { COLOR_WHITE } from '../../../../styles';
import TableRow from '@mui/material/TableRow';

export const ToggleButtonStyled = styled(ToggleButton)`
  font-size: 14px;
  text-transform: capitalize; 
  height: 26px;
  padding: 5px 16px;
  border-radius: 4px !important;
  background-color: rgba(136, 136, 136, 0.08);

  &.MuiButtonBase-root {
    border: none;
  }
  
  :hover {
    background-color: rgba(0, 0, 0, 0.12);
  }

  &.Mui-selected {
    background-color: rgba(136, 136, 136, 1);
    color: ${COLOR_WHITE};
    
    :hover {
      color: rgba(0, 0, 0, 0.54)
    }
  }
`;

export const HeaderContent = styled(TableRow)`
  .MuiTableCell-root {
    padding: 0 16px 6px;
    line-height: normal;
    font-size: 12px;
    color: rgba(117, 117, 117, 1);
  }

  .MuiTableCell-root:first-child {
    padding-left: 2px;
  }
`;
