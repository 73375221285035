import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import LinkEditValue from './LinkEditValue';
import QuantityEditValue from './QuantityEditValue';
import TextEditValue from './TextEditValue';
import BooleanEditValue from './BooleanEditValue';
import EnumEditValue from './EnumEditValue';

import { VALUE_TYPE } from '../../../../../../services/samples/constants';

const BOOLEAN_SELECT_VALUES = {
  TRUE: true,
  FALSE: false,
  NULL: null,
};

const getDefaultBooleanValue = value => {
  return Object.values(BOOLEAN_SELECT_VALUES)
    .includes(value) ? value : BOOLEAN_SELECT_VALUES.NULL;
};

const toValue = data => ({
  id: data?.id,
  label: data?.title,
  code: data?.code
});

const EditableValue = ({ data, onSubmit, onCancel }) => {
  const [selectedItem, setSelectedItem] = useState(() => toValue(data.link));
  const [quantity, setQuantity] = useState(
    typeof data.quantity === 'number' ? data.quantity : ''
  );
  const [text, setText] = useState(data.text || '');
  const [boolean, setBoolean] = useState(() => getDefaultBooleanValue(data.boolean));
  const [enumValue, setEnumValue] = useState(data.enumValues);

  const handleQuantityChange = useCallback((e) => {
    const { value } = e.target;
    const validValue = value ? Number(value) : value;

    setQuantity(validValue);
  }, []);

  const handleTextChange = useCallback((e) => {
    setText(e.target.value);
  }, []);

  const handleBooleanChange = useCallback((value) => {
    setBoolean(value);
  }, []);

  const handleSubmit = useCallback((type, value) => {
    onSubmit(type, value);
  }, [onSubmit]);

  switch (data.valueType){
    case VALUE_TYPE.LINK: {
      return (
        <LinkEditValue
          value={selectedItem}
          onChange={setSelectedItem}
          onSubmit={handleSubmit}
          onCancel={onCancel}
        />
      );
    }
    case VALUE_TYPE.QUANTITY: {
      return (
        <QuantityEditValue
          value={quantity}
          name={data.unit?.name}
          onChange={handleQuantityChange}
          onSubmit={handleSubmit}
          onCancel={onCancel}
        />
      );
    }
    case VALUE_TYPE.TEXT: {
      return (
        <TextEditValue
          value={text}
          onSubmit={handleSubmit}
          onCancel={onCancel}
          onChange={handleTextChange}
        />
      );
    }
    case VALUE_TYPE.BOOLEAN: {
      return (
        <BooleanEditValue
          value={boolean}
          onSubmit={handleSubmit}
          onCancel={onCancel}
          onChange={handleBooleanChange}
        />
      );
    }

    case VALUE_TYPE.ENUM:
      return (
        <EnumEditValue
          values={enumValue}
          options={data.enumValueOptions}
          onChange={setEnumValue}
          onCancel={onCancel}
          onSubmit={handleSubmit}
        />
      );

    default:
      return null;
  }
};

EditableValue.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditableValue;
