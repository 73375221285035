import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useAnalytics } from 'use-analytics';

import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import DialogComponent from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import TitleFormControl from './TitleFormControl';
import DescriptionFormControl from './DescriptionFormControl';
import LoadingBanner from '../../LoadingBanner';

import {
  COLOR_WHITE,
  COLOR_PRIMARY,
  COLOR_GREY_LIGHT,
  COLOR_TEXT
} from '../../../styles';

import { openAppSnackbarNotification } from '../../../services/snackbar-notifications/actions';

import { CLONE_ITEM } from './gql';
import { componentNames, TRACK_SINGLE_ITEM } from '../../../analytics/constants';
import { GET_ITEMS, GET_TABLES } from '../../../scenes/AllItems/services';

import { SNACKBAR_TYPES } from '../../AppSnackbar';
import { CreateItemSuccessMessage } from '../CreateItemMessages';

const styles = {
  actions: {
    padding: 12,

    '& .extra-actions-container': {
      flexGrow: 1,
    }
  },
  inputLabel: {
    transform: 'translate(0, 0) scale(0.75)'
  },
  dialogContent: {
    paddingTop: 10
  },
  formControlRoot: {
    marginBottom: 10,
    marginTop: 7,

    '& label + *': {
      marginTop: 18,
      border: '1px solid rgba(0, 0, 0, 0.26)',
      borderRadius: 2
    },

    '& input': {
      height: 42,
      boxSizing: 'border-box',
      paddingLeft: 6
    },

    '& textarea': {
      height: 42,
      boxSizing: 'border-box',
      paddingLeft: 6
    }
  },
  helperText: {
    marginLeft: 0,
    marginRight: 0,
  },
  cancelButton: {
    backgroundColor: COLOR_GREY_LIGHT,
    color: COLOR_TEXT,

    '&:hover': {
      backgroundColor: COLOR_GREY_LIGHT,
      color: COLOR_TEXT,
    }
  },
};

const CloneItemDialog = ({
  onClose,
  data,
  classes
}) => {
  const [title, setTitle] = useState(data.title);
  const [description, setDescription] = useState(data.description ?? '');
  const [error, setError] = useState('');
  const [cloneValues, setCloneValues] = useState(true);

  const [cloneItem, { loading: isFetching }] = useMutation(CLONE_ITEM, {
    refetchQueries: [GET_ITEMS, GET_TABLES]
  });

  const dispatch = useDispatch();

  const { track } = useAnalytics();

  const handleProtocolsCheckboxChange = useCallback(() => {
    setError('');
    setCloneValues(state => !state);
  }, []);

  const handleTitleChange = useCallback(e => {
    setError('');
    setTitle(e.target.value);
  }, []);

  const handleDescriptionChange = useCallback(e => {
    setError('');
    setDescription(e.target.value);
  }, []);

  const handleError = useCallback(error => {
    setError(error.message ?? 'Something went wrong');
  }, []);

  const handleSubmit = useCallback(async () => {

    track(TRACK_SINGLE_ITEM.clone, {
      component: componentNames.ITEM_ACTIONS,
    });

    try {
      const response = await cloneItem({
        variables: {
          id: data.id,
          data: {
            title,
            description,
            includeValues: cloneValues
          }
        }
      });

      if (!response.data?.cloneTableItem) {
        handleError(response.error);

        return;
      }

      onClose();

      dispatch(
        openAppSnackbarNotification({
          variant: SNACKBAR_TYPES.SUCCESS,
          message: (
            <CreateItemSuccessMessage
              id={response.data.cloneItem.id}
              title={title}
              code={response.data.cloneItem.code}
            />
          )
        })
      );
    } catch (error) {
      handleError(error);
    }
  }, [track, cloneItem, data.id, title, description, cloneValues, onClose, dispatch, handleError]);

  return (
    <DialogComponent
      open
      onClose={onClose}
      fullWidth
    >
      <DialogTitle>
        Duplicate item: {data.code}
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <TitleFormControl
          classes={classes}
          elementId="item-title"
          title={title}
          disabled={isFetching}
          onChange={handleTitleChange}
          placeholder="My new item"
        />

        <DescriptionFormControl
          disabled={isFetching}
          classes={classes}
          elementId="item-description"
          placeholder="Description of the item"
          onChange={handleDescriptionChange}
          description={description}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={cloneValues}
              onChange={handleProtocolsCheckboxChange}
              color="primary"
            />
          }
          label="Copy all protocol values from source item"
        />

        <FormHelperText error>
          {error}
        </FormHelperText>
      </DialogContent>

      <DialogActions
        classes={{ root: classes.actions }}
        className="dialog-actions"
      >
        {isFetching &&
          <LoadingBanner
            spinner={true}
            bcgcolor={COLOR_WHITE}
            color={COLOR_PRIMARY}
            height={30}
          />
        }

        <Button
          className="submit-action"
          label="submit"
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={isFetching}
        >
          Duplicate
        </Button>

        <Button
          onClick={onClose}
          variant="contained"
          className={classes.cancelButton}
        >
          cancel
        </Button>
      </DialogActions>
    </DialogComponent>
  );
};

CloneItemDialog.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  openAppSnackbarNotification: PropTypes.func,
};

export default withStyles(styles)(CloneItemDialog);


