import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import isLess from './isLess';

const TOO_SMALL_ERROR = 'The max value cannot be less than the min value';

const maxValueValidator = ({ parameter, values }) => value => {
  const isValueExist = !isEmpty(value);
  const isMinLimitExist = !isNil(parameter?.quantityMin);

  if (isValueExist) {
    const shouldReturnError =
      (isMinLimitExist && isLess(value, parameter.quantityMin))
      || (!isEmpty(value.min) && isLess(value, values.min));

    if (shouldReturnError) {
      return TOO_SMALL_ERROR;
    }
  }


};

export default maxValueValidator;
