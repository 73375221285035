import React, { useState, useCallback, memo } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import TableCell from '../TableCell';
import Avatar from './Avatar';

const popoverTransformOrigin = {
  vertical: 'top',
  horizontal: 'center'
};

const popoverBoxStyles = { p: 1 };

const ItemOwner = ({ className, owner }) => {
  const {
    name,
    userId,
    avatarColor,
    pictureDownloadURL
  } = owner;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleAvatarClick = useCallback(ev => {
    setAnchorEl(ev.currentTarget);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <TableCell>
      <div className={className}>
        <Tooltip title={name}>
          <Avatar
            name={name}
            src={pictureDownloadURL}
            size={24}
            onClick={handleAvatarClick}
            className="owner-avatar"
            color={avatarColor}
          />
        </Tooltip>

        <Popover
          key="popover"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          transformOrigin={popoverTransformOrigin}
        >
          <Box sx={popoverBoxStyles}>
            <Typography variant="body1">Name: {name}</Typography>

            <Typography
              variant="body2"
              color="#757575"
            >
              User: {userId}
            </Typography>
          </Box>
        </Popover>
      </div>
    </TableCell>
  );
};

ItemOwner.propTypes = {
  className: PT.string,
  owner: PT.shape({
    name: PT.string,
    userId: PT.string,
    avatarColor: PT.string,
    pictureDownloadURL: PT.string
  })
};

export default styled(memo(ItemOwner))`
  display: flex;
  justify-content: center;

  .owner-avatar {
    cursor: pointer;
  }
`;
