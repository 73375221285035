import styled from 'styled-components';

import Box from '@mui/material/Box';
import { COLOR_PRIMARY_CONTRAST } from '../../../styles';

export const ItemWrapper = styled(Box)`
  display: flex;
  align-items: center;
  height: 28px;
  width: 100%;
  &:hover {
    background-color: ${COLOR_PRIMARY_CONTRAST};
  }
`;
