import React, { useCallback } from 'react';
import PT from 'prop-types';

import DatePicker from './DatePicker';
import Box from '@mui/material/Box';

const DateInput = ({ value, onChange, disabled }) => {
  const handleChangeDateFrom = useCallback((val) => {
    const newDate = val ? new Date(val).setHours(0, 0, 0, 0) : null;

    let newData = {
      ...value,
      from: newDate
    };

    if (!newData.to && !newData.from)
      newData = null;

    onChange(newData);
  }, [value, onChange]);

  const handleChangeDateTo = useCallback((val) => {
    const newDate = val ? new Date(val).setHours(23, 59, 59, 999) : null;

    let newData = {
      ...value,
      to: newDate
    };

    if (!newData.to && !newData.from)
      newData = null;

    onChange(newData);
  }, [value, onChange]);

  const dateFrom = value?.from ? new Date(value.from) : null;
  const dateTo = value?.to ? new Date(value.to) : null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <DatePicker
        onChange={handleChangeDateFrom}
        value={dateFrom}
        label="From"
        disabled={disabled}
      />
      <DatePicker
        onChange={handleChangeDateTo}
        value={dateTo}
        label="To"
        disabled={disabled}
      />
    </Box>
  );
};

DateInput.propTypes = {
  value: PT.object,
  onChange: PT.func.isRequired,
  disabled: PT.bool,
};

export default DateInput;
