import styled from 'styled-components';
import Box from '@mui/material/Box';

const Container = styled(Box)`
  background: #F5F5F5; 
  border-radius: 8px; 
  display: flex; 
  align-items: center; 
  width: 100%;
  justify-content: center; 
  flex-direction: column;
  height: 100%;
  padding: 25px 0;
`;

export default Container;
