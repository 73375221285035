import React, { useCallback, useState } from 'react';
import PT from 'prop-types';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import MultipleSelect from './MultipleSelect';
import PermissionSelect from './PermissionSelect';

import { IAM_ROLES } from '../../../../services/samples/constants';
import useGetAvailableUsers from './useGetAvailableUsers.hook';

const StyledDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    maxWidth: 640,
    width: '100%'
  }
});

const ALL_USERS_OPTION_VALUE = 'all-users-option';

const userToOption = ({ id, name, userId }) => ({
  value: id,
  label: {
    primary: name,
    secondary: userId
  }
});

const labelPrimaryCompare = (a, b) =>
  a.label.primary.localeCompare(b.label.primary);

const AddUsersDialog = ({
  defaultUserIds,
  onSubmit,
  onClose,
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [role, setRole] = useState(IAM_ROLES.get('READ'));

  const availableUsers = useGetAvailableUsers({ skipUsers: defaultUserIds });

  const handleAgree = useCallback(() => {
    const { users, organization } = selectedUsers.reduce((acc, i) => {
      if (i === ALL_USERS_OPTION_VALUE) {
        acc.organization = role;
      } else {
        if (!acc.users) {
          acc.users = [];
        }

        acc.users.push({
          role,
          id: i
        });
      }

      return acc;
    }, { users: undefined, organization: undefined });

    onSubmit(users, organization);
  }, [onSubmit, role, selectedUsers]);

  const userOptions = [
    {
      value: ALL_USERS_OPTION_VALUE,
      label: {
        primary: 'All users in organization'
      }
    },
    {
      value: 'all-users-option-divider',
      isDivider: true
    },
    ...availableUsers
      .map(userToOption)
      .sort(labelPrimaryCompare)
  ];

  return (
    <StyledDialog
      open
      onClose={onClose}
      aria-labelledby="add-users-dialog-title"
    >
      <DialogTitle
        id="add-users-dialog-title"
        sx={{
          fontWeight: 'bold',
          lineHeight: 1,
          paddingBottom: '16px'
        }}
      >
        Add Users
      </DialogTitle>

      <DialogContent
        sx={{
          maxWidth: 640,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          gap: '16px'
        }}
      >
        <Box flexGrow="1">
          <MultipleSelect
            value={selectedUsers}
            onChange={setSelectedUsers}
            options={userOptions}
          />
        </Box>

        <Box width="130px">
          <PermissionSelect
            value={role}
            onChange={setRole}
          />
        </Box>
      </DialogContent>

      <DialogActions sx={{ pr: '24px', pb: '16px' }}>
        <Button
          onClick={onClose}
          sx={{
            textTransform: 'capitalize',
            color: '#666666'
          }}
        >
          cancel
        </Button>

        <Button
          onClick={handleAgree}
          disabled={!selectedUsers.length}
          sx={{ textTransform: 'capitalize' }}
          variant="contained"
          autoFocus
        >
          add
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

AddUsersDialog.propTypes = {
  defaultUserIds: PT.array.isRequired,
  onSubmit: PT.func.isRequired,
  onClose: PT.func.isRequired,
};

export default AddUsersDialog;
