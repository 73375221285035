import React from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

import IconButton from '@mui/material/IconButton';

import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import {
  TOOLBAR_HEIGHT_PX,
  FILTER_BAR_HEIGHT,
  TOOLBAR_MOBILE_VIEW_HEIGHT_PX
} from '../../../scenes/AllItems/constants';
import { COLGROUP_HEADER_HEIGHT_PX } from '../constants';
import { MEDIA_QUERIES_MOBILE } from '../../../styles';

const GroupHeader = ({
  children,
  className,
  colSpan = 1,
  rowSpan = 1,
  collapsed,
  onToggleCollapse
}) => {
  return (
    <th
      className={className}
      colSpan={colSpan}
      rowSpan={rowSpan}
    >
      <div className="header-container">
        <div className="controls-container">
          <div className="sticky-wrapper">
            <IconButton
              aria-label={collapsed ? 'Expand' : 'Collapse'}
              disableRipple
              sx={{
                '&.MuiButtonBase-root': {
                  p: 0,
                  height: `${COLGROUP_HEADER_HEIGHT_PX - 2}px`,
                  width: `${COLGROUP_HEADER_HEIGHT_PX - 2}px`
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 16,
                  transform: 'rotate(90deg)'
                }
              }}
              onClick={() => onToggleCollapse()}
            >
              {collapsed ?
                <UnfoldMoreIcon /> :
                <UnfoldLessIcon />
              }
            </IconButton>

            <span className="column-title">{children}</span>
          </div>
        </div>
      </div>
    </th>
  );
};

GroupHeader.propTypes = {
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node
  ]),
  className: PT.string,
  colSpan: PT.number,
  rowSpan: PT.number,
  collapsed: PT.bool,
  onToggleCollapse: PT.func
};

export default styled(GroupHeader)`
  height: inherit;
  vertical-align: bottom;
  padding: 0;

  position: sticky;
  top: ${TOOLBAR_HEIGHT_PX + FILTER_BAR_HEIGHT}px;
  z-index: 1;
  text-align: left;

  @media (${MEDIA_QUERIES_MOBILE}) {
    top: ${TOOLBAR_MOBILE_VIEW_HEIGHT_PX + FILTER_BAR_HEIGHT}px;
  }

  .header-container {
    height: ${({ collapsed }) => collapsed ?
    '100%' :
    COLGROUP_HEADER_HEIGHT_PX + 'px'};
    width: ${({ collapsed }) => collapsed ?
    COLGROUP_HEADER_HEIGHT_PX + 'px' :
    '100%'};
    max-width: ${({ maxWidth }) => maxWidth ? maxWidth + 'px' : 'auto'};
    box-sizing: border-box;
    background: #F5F5F5;
    display: inline-table;
    vertical-align: top;
  }

  .controls-container {
    height: ${({ collapsed }) => collapsed ? 'auto' : `${COLGROUP_HEADER_HEIGHT_PX}px`};
    padding-right: ${({ collapsed }) => collapsed ? '0' : '6px'};
    border-top: 2px solid #DCDBDC;
    box-sizing: border-box;
    display: flex;
    align-items: ${({ collapsed }) => collapsed ? 'flex-start' : 'center'};
    ${({ collapsed }) => collapsed ?
    `
    position: sticky;
    top: ${TOOLBAR_HEIGHT_PX + FILTER_BAR_HEIGHT}px;

    @media (${MEDIA_QUERIES_MOBILE}) {
      top: ${TOOLBAR_MOBILE_VIEW_HEIGHT_PX + FILTER_BAR_HEIGHT}px;
    }` :
    ''}
  }

  .sticky-wrapper {
    display: flex;
    align-items: center;
    ${({ collapsed }) => collapsed ?
    `
    flex-direction: column;
    ` :
    `
    position: sticky;
    left: 268px;
    `}
  }

  .column-title {
    font-size: 14px;
    ${({ collapsed }) => collapsed ? 'writing-mode: vertical-lr;' : ''}
  }
`;
