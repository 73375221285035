import { useContext } from 'react';
import PT from 'prop-types';

import { TabsContext } from './TabsProvider';

const TabContent = ({ value, children }) => {
  const _value = useContext(TabsContext);

  return _value === value ? children : null;
};

TabContent.propTypes = {
  value: PT.any,
  children: PT.any
};

export default TabContent;
