import styled from 'styled-components';

const AvatarPictureSelectContainer = styled.div`
  button,
  [role=button] {
    background: white;
  }

  .avatar-picture-helper {
    margin-top: 12px;
    color: rgba(0, 0, 0, 0.54);
  }
`;

export default AvatarPictureSelectContainer;
