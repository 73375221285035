import React, { createContext } from 'react';
import PT from 'prop-types';

export const TabsContext = createContext(null);

const TabsProvider = ({ children, value }) => {
  return (
    <TabsContext.Provider value={value}>
      {children}
    </TabsContext.Provider>
  );
};

TabsProvider.propTypes = {
  value: PT.any,
  children: PT.any
};

export default TabsProvider;
