import styled from 'styled-components';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';

export const EditableBox = styled(Box)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  cursor: ${props => props.readOnly ? 'initial' : 'pointer'};
  border-radius: 4px;
  padding: 4px 8px;
  position: relative;
  font-size: 14px;
  min-height: ${props => !props.readOnly && '28px'};
  
  &:hover {
    background-color: ${props => props.readOnly ? 'initial' : 'var(--serface-2, rgba(0, 0, 0, 0.04))'};
      
    .edit {
      display: ${props => props.readOnly ? 'none' : 'flex'};
    }
  }
`;

export const EditIconStyled = styled(EditIcon).attrs({ className: 'edit' })`
  display: none;
  height: 18px;
  position: absolute;
  right: 0px;
`;
