import React, { useCallback } from 'react';
import PT from 'prop-types';
import { Link } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import FileCopyIcon from '@mui/icons-material/FileCopy';

import CopyItemLink from './CopyItemLink';

import { COLOR_PRIMARY } from '../../../../../../../styles';

const NO_ACCESS_ITEM_TEXT = 'Inaccessible item';

const DesktopLink = ({
  onLink,
  linkTitle,
  onEdit,
  noAccess,
  canEdit,
  url
}) => {
  const handleLinkClick = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    onLink();
  }, [onLink]);

  const tooltipTitle = noAccess ? NO_ACCESS_ITEM_TEXT : linkTitle;

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <Typography
          variant="body2"
          noWrap
          color={noAccess ? '#9e9e9e' : 'inherit'}
          sx={{ textDecoration: noAccess ? 'underline' : 'none' }}
        >
          {noAccess
            ? linkTitle
            : (
              <Typography
                variant="body2"
                color={COLOR_PRIMARY}
                noWrap
                component={Link}
                target="_blank"
                to={url}
                onClick={handleLinkClick}
              >
                {linkTitle}
              </Typography>
            )
          }
        </Typography>
      </Tooltip>

      {noAccess || !url ?
        null :
        <CopyItemLink text={window.location.origin + url}>
          <FileCopyIcon
            className="copy-link-icon"
            sx={{
              color: 'rgba(0,0,0,.54)',
              fontSize: '14px',
              cursor: 'pointer'
            }}
          />
        </CopyItemLink>
      }

      {canEdit &&
        <EditIcon
          className="edit-icon"
          size="small"
          color="primary"
          onClick={onEdit}
        />
      }
    </>
  );
};

DesktopLink.propTypes = {
  onLink: PT.func.isRequired,
  linkTitle: PT.string,
  onEdit: PT.func.isRequired,
  noAccess: PT.bool,
  canEdit: PT.bool,
  url: PT.string
};

export default DesktopLink;
