import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

// TODO: Replace explicit firebase auth with auth api wrapper
import firebase from 'firebase/app';
import 'firebase/auth';

import Button from '@mui/material/Button';

import { AuthProviderContainer } from '../styles';

import {
  openAppSnackbarNotification
} from '../../../../services/snackbar-notifications/actions';

const mapDispatchToProps = {
  openAppSnackbarNotification
};

function GoogleProvider({
  data,
  allowUnlink,
  onProvidersChanged,
  openAppSnackbarNotification
}) {
  const handleConnectGoogleProvider = useCallback(async () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    provider.addScope('email');
    provider.addScope('profile');

    try {
      await firebase.auth().currentUser.linkWithPopup(provider);

      onProvidersChanged();
    } catch(e) {
      console.log(e);

      openAppSnackbarNotification({
        message: e.message,
        variant: 'ERROR'
      });
    }
  }, [
    onProvidersChanged,
    openAppSnackbarNotification
  ]);

  const handleDisonnectGoogleProvider = useCallback(async () => {
    try {
      await firebase.auth().currentUser.unlink(firebase.auth.GoogleAuthProvider.PROVIDER_ID);

      onProvidersChanged();
    } catch(e) {
      console.log(e);

      openAppSnackbarNotification({
        message: e.message,
        variant: 'ERROR'
      });
    }
  }, [
    onProvidersChanged,
    openAppSnackbarNotification
  ]);

  return (
    <AuthProviderContainer>
      <div className="auth-provider">
        <img
          className="auth-provider-icon"
          src={`${process.env.PUBLIC_URL}/img/google-logo.png`}
          alt="Auth provider logo"
        />

        <div className="auth-provider-name">Google</div>

        {data ?
          <div className="auth-provider-email">{data.email}</div> :
          null
        }

        {!data ?
          <Button
            color="inherit"
            variant="contained"
            size="small"
            onClick={handleConnectGoogleProvider}
          >
            Connect
          </Button> :
          allowUnlink ?
            <Button
              color="inherit"
              size="small"
              onClick={handleDisonnectGoogleProvider}
            >
              Disconnect
            </Button> :
            null
        }
      </div>
    </AuthProviderContainer>
  );
}

GoogleProvider.propTypes = {
  data: PropTypes.object,
  allowUnlink: PropTypes.bool,
  onProvidersChanged: PropTypes.func.isRequired,
  openAppSnackbarNotification: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(GoogleProvider);
