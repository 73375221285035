import { isKeyHotkey } from 'is-hotkey';
import { IS_APPLE } from '../../utils/environment';

const HOTKEYS = {
  bold: 'mod+b',
  italic: 'mod+i',
  underline: 'mod+u',
  clear: 'mod+\\'
};

const predicate = key => {
  const hotkey = HOTKEYS[key];
  const matches = hotkey && isKeyHotkey(hotkey);

  return event => Boolean(matches && matches(event));
};

export const hotkeys = {
  isBold: predicate('bold'),
  isItalic: predicate('italic'),
  isUnderline: predicate('underline'),
  isClear: predicate('clear'),
};

export const hotkeysBasicKey = IS_APPLE ? '⌘' : 'Ctrl+';
