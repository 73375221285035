import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';

const Option = ({ value, label, onClick }) => (
  <MenuItem
    key={value}
    onClick={onClick(value)}
  >
    {label}
  </MenuItem>
);

Option.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Option;
