import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import ButtonFile from '../ButtonFile';
import AvatarPictureSelectDialog from './AvatarPictureSelectDialog';
import AvatarPictureSelectContainer from './styles';

const inputFileProps = {
  accept: 'image/*'
};

function AvatarPictureSelect({
  buttonFileText,
  onSubmit
}) {
  const [openDialog, setOpenDialog] = useState(false);
  const [filename, setFilename] = useState();
  const [pictureSrc, setPictureSrc] = useState();

  const fileInputRef = useRef();

  useEffect(() => {
    if (!openDialog) {
      if (fileInputRef.current) {
        /*
          reset input's value (representing the path to the selected file)
          to allow "onchange" event be emitted on same file selection
        */
        fileInputRef.current.value = '';
      }

      setFilename(null);
      setPictureSrc(null);
    }
  }, [openDialog]);

  const handleSelectFile = useCallback((ev, ref) => {
    fileInputRef.current = ref.current;

    if (ev.target.files && ev.target.files.length > 0) {
      const reader = new FileReader();
      const onLoadPicture = () => {
        setPictureSrc(reader.result);
        setOpenDialog(true);

        reader.removeEventListener('load', onLoadPicture);
      };

      reader.addEventListener('load', onLoadPicture);

      const file = ev.target.files[0];

      setFilename(file.name);
      reader.readAsDataURL(file);
    }
  }, []);

  const handleClose = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const handleSubmit = useCallback(data => {
    onSubmit(data);
    handleClose();
  }, [onSubmit, handleClose]);

  return (
    <>
      <AvatarPictureSelectContainer>
        <ButtonFile
          variant="contained"
          color="inherit"
          onChange={handleSelectFile}
          inputFileProps={inputFileProps}
        >
          {buttonFileText}
        </ButtonFile>

        <Typography
          className="avatar-picture-helper"
          variant="caption"
          display="block"
        >
          Maximum image size - 700kB
        </Typography>
      </AvatarPictureSelectContainer>

      {openDialog &&
        <AvatarPictureSelectDialog
          filename={filename}
          pictureSrc={pictureSrc}
          onSubmit={handleSubmit}
          onClose={handleClose}
        />
      }
    </>
  );
}

AvatarPictureSelect.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  buttonFileText: PropTypes.string
};

AvatarPictureSelect.defaultProps = {
  buttonFileText: 'Change picture'
};

export default AvatarPictureSelect;
