export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const CACHED_SUCCESS = 'CACHED_SUCCESS';
export const FAILURE = 'FAILURE';
export const FIREBASE_INIT = 'FIREBASE_INIT';
export const CHILD_CHANGED = 'CHILD_CHANGED';
export const CHILD_ADDED = 'CHILD_ADDED';
export const CHILD_REMOVED = 'CHILD_REMOVED';
export const CANCEL = 'CANCEL';
export const NEXT = 'NEXT';
export const PAUSE = 'PAUSE';
export const RESUME = 'RESUME';

// Arhive and Delete operations' restrictions
export const RESTRICT_PERMISSION = 'RESTRICT_PERMISSION';
export const RESTRICT_LINKED = 'RESTRICT_LINKED';
export const RESTRICT_NOT_ARCHIVED = 'RESTRICT_NOT_ARCHIVED';

export const IS_E2E_TEST_USER = navigator.userAgent.toLowerCase().includes('selenium');

