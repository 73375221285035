import React from 'react';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';

import { AvatarWrapStyled, TableCell } from './styles';
import Avatar from '../../components/Avatar';
import StatusCell from './StatusCell';

const TableItem = ({
  userId,
  username,
  avatarUrl,
  avatarColor,
  email,
  joined,
  lastActive,
  status
}) => {
  return (
    <TableRow>
      <TableCell align="left" className="members">
        {username ?
          <AvatarWrapStyled>
            <Avatar
              name={username}
              src={avatarUrl}
              size={40}
              color={avatarColor}
            />
            {username}
          </AvatarWrapStyled>
          : email
        }
      </TableCell>

      <TableCell align="left" className="username">
        {userId}
      </TableCell>

      <TableCell align="left" className="date">
        {joined}
      </TableCell>

      <TableCell align="left" className="last-active">
        {lastActive}
      </TableCell>
      <TableCell align="left" className="status">
        <StatusCell status={status} />
      </TableCell>
    </TableRow>
  );
};

TableItem.propTypes = {
  userId: PropTypes.string,
  username: PropTypes.string,
  avatarUrl: PropTypes.string,
  avatarColor: PropTypes.string,
  email: PropTypes.string,
  joined: PropTypes.string,
  lastActive: PropTypes.string,
  status: PropTypes.string,
};

export default TableItem;
