import React from 'react';
import PT from 'prop-types';

import QuantityValue from './QuantityValue';

const QuantityValueDataWrapper = ({ data }) => (
  <QuantityValue value={data.quantity} unit={data.unit} />
);

QuantityValueDataWrapper.propTypes = {
  data: PT.shape({
    quantity: PT.number,
    unit: PT.shape({
      name: PT.string
    })
  })
};

export default QuantityValueDataWrapper;

