export const rolesIds = {
  NO_ACCESS: 'NO_ACCESS',
  READ: 'READ',
  WRITE: 'WRITE',
  ADMIN: 'ADMIN',
  OWNER: 'OWNER',
};

export const rolesList = [
  {
    id: rolesIds.NO_ACCESS,
    title: 'No Access',
    value: Number.parseInt('0000', 2)
  },
  {
    id: rolesIds.READ,
    title: 'Read',
    value: Number.parseInt('0001', 2)
  },
  {
    id: rolesIds.WRITE,
    title: 'Write',
    value: Number.parseInt('0011', 2)
  },
  {
    id: rolesIds.ADMIN,
    title: 'Admin',
    value: Number.parseInt('0111', 2)
  },
  {
    id: rolesIds.OWNER,
    title: 'Owner',
    value: Number.parseInt('1111', 2)
  }
];

export const roles = new Map(rolesList.map(({ id, title, value }) => [id, { id, title, value }]));
export const isRoleEqualOrHigher = (base, target) => {
  const baseValue = roles.get(base)?.value ?? 0;
  const targetValue = roles.get(target)?.value ?? 0;

  return baseValue >= targetValue;
};

export const hasAdminAccess = role => {
  const adminValue = roles.get('ADMIN').value;

  if (typeof role === 'number') {
    return role >= adminValue;
  }

  return Boolean(role) && roles.get(role)?.value >= adminValue;
};

export const hasWriteAccess = role =>
  roles.get(role).value >= roles.get('WRITE').value;

export const hasAccess = role => role !== rolesIds.NO_ACCESS;
