import omit from 'lodash/omit';

import * as actions from '../actions';
import * as constants from '../constants';
import { updateObject } from '../../../reducers';

const {
  REQUEST,
  SUCCESS,
  FAILURE,
} = constants;

const getParsedDataInitialState = () => ({
  error: null,
  data: null,
  parserId: null
});

const initialState = {
  init: true,
  error: null,
  parsedDataByMeasurementId: {},
  isGeneralParsedDataFetching: false,
  isParsedDataByMeasuremnetIdFetching: {},
};

export default function general(state = initialState, action = {}) {
  switch (action.type) {
    case actions.measurementParseByIdTypes[REQUEST]:
      return onMeasurementParseByIdRequest(state, action);
    case actions.measurementParseByIdTypes[SUCCESS]:
      return onMeasurementParseByIdSuccess(state, action);
    case actions.measurementParseByIdTypes[FAILURE]:
      return onMeasurementParseByIdFailure(state, action);
    case constants.MEASUREMENT_PARSED_DATA_BY_ID_REMOVE:
      return onMeasurementParsedDataByIdRemove(state, action);
    case constants.MEASUREMENT_PROCESSED_DATA_ADD:
      return onMeasurementParsedDataAdd(state, action);
    case actions.measurementProcessedDataLoadByIdTypes[REQUEST]:
      return onMeasurementProcessedDataLoadByIdRequest(state, action);
    case actions.measurementProcessedDataLoadByIdTypes[SUCCESS]:
      return onMeasurementProcessedDataLoadByIdSuccess(state, action);
    case actions.measurementProcessedDataLoadByIdTypes[FAILURE]:
      return onMeasurementProcessedDataLoadByIdFailure(state, action);
    case constants.MEASUREMENT_PROCESSED_DATA_RESET_BY_ID:
      return onMeasurementProcessedDataResetById(state, action);
    case constants.PARSED_DATA_RESET_BY_ID:
      return onParsedDataResetById(state, action);
    case actions.measurementsParseTypes[REQUEST]:
      return onMeasurementsParseRequest(state, action);
    case actions.measurementsParseTypes[SUCCESS]:
      return onMeasurementsParseSuccess(state, action);
    case actions.measurementsParseTypes[FAILURE]:
      return onMeasurementsParseFailure(state, action);
    default:
      return state;
  }
}

function onMeasurementParseByIdRequest(state, action) {
  return {
    ...state,
    isParsedDataByMeasuremnetIdFetching: {
      ...state.isParsedDataByMeasuremnetIdFetching,
      [action.id]: true
    }
  };
}

function onMeasurementParseByIdSuccess(state, action) {
  const {
    measurementId,
    processedData,
    parserId,
    isComplex,
    viewType,
    parserName
  } = action.response;

  return {
    ...state,
    parsedDataByMeasurementId: {
      ...state.parsedDataByMeasurementId,
      [measurementId]: {
        parserId,
        isComplex,
        viewType,
        parserName,
        data: processedData,
        error: null
      }
    },
    isParsedDataByMeasuremnetIdFetching: {
      ...state.isParsedDataByMeasuremnetIdFetching,
      [measurementId]: false
    }
  };
}

function onMeasurementParseByIdFailure(state, action) {
  const {
    message,
    parserName,
    parserId,
    assetId: measurementId,
  } = action.error;

  return {
    ...state,
    isParsedDataByMeasuremnetIdFetching: {
      ...state.isParsedDataByMeasuremnetIdFetching,
      [measurementId]: false
    },
    parsedDataByMeasurementId: {
      ...state.parsedDataByMeasurementId,
      [measurementId]: {
        parserName,
        parserId,
        data: null,
        error: message
      }
    }
  };
}

function onMeasurementParsedDataByIdRemove(state, { id }) {
  return {
    ...state,
    parsedDataByMeasurementId: omit(state.parsedDataByMeasurementId, id)
  };
}

function onMeasurementParsedDataAdd(state, { id, data }) {
  return {
    ...state,
    parsedDataByMeasurementId: {
      ...state.parsedDataByMeasurementId,
      [id]: data
    }
  };
}

function onMeasurementProcessedDataLoadByIdRequest(state, action) {
  const { id, params } = action;

  return updateObject(state, `parsedDataByMeasurementId[${id}]`, {
    ...params,
    isFetching: true
  });
}

function onMeasurementProcessedDataLoadByIdSuccess(state, action) {
  const { assetId, graphicsData, ...rest } = action.response;

  return updateObject(state, `parsedDataByMeasurementId[${assetId}]`, {
    ...rest,
    data: graphicsData,
    isFetching: false
  }, false);
}

function onMeasurementProcessedDataLoadByIdFailure(state, action) {
  const { assetId, message } = action.error;

  return updateObject(state, `parsedDataByMeasurementId[${assetId}]`, {
    error: message,
    isFetching: false
  }, false);
}

function onMeasurementProcessedDataResetById(state, action) {
  const { assetId } = action;
  return updateObject(state, `parsedDataByMeasurementId[${assetId}]`, null);
}

function onParsedDataResetById(state, { id }) {
  if (!state.parsedDataByMeasurementId[id]) return state;

  return {
    ...state,
    parsedDataByMeasurementId: {
      ...state.parsedDataByMeasurementId,
      [id]: getParsedDataInitialState()
    }
  };
}

function onMeasurementsParseRequest(state) {
  return {
    ...state,
    isGeneralParsedDataFetching: true
  };
}

function onMeasurementsParseSuccess(state) {
  return {
    ...state,
    isGeneralParsedDataFetching: false
  };
}

function onMeasurementsParseFailure(state) {
  return {
    ...state,
    isGeneralParsedDataFetching: false
  };
}
