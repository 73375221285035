const mapValidData = (titles, data) => {
  const commonValidationIndex = titles.findIndex(item => item === 'VALID');
  const xValidationIndex = titles.findIndex(item => item === 'VALID_X');
  const yValidationIndex = titles.findIndex(item => item === 'VALID_Y');

  return [commonValidationIndex, xValidationIndex, yValidationIndex].reduce((accum, curr) => {
    if(curr === -1)
      return accum;

    return accum.filter(row => row[curr] === 'True');
  }, data);
};

export default mapValidData;
