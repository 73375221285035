import { useState, useCallback } from 'react';

const useItemsSorting = (queryInput) => {
  let initValue = queryInput ?
    (queryInput.sort ?? { param: 'DATE_UPDATED', order: 'DESC' }) :
    { param: 'DATE_UPDATED', order: 'DESC' };

  const [sorting, setSorting] = useState(initValue);

  const sortItems = useCallback(
    /**
     * Apply sorting
     * @param  {object} sort
     * @param  {?'TITLE'|'DATE_CREATED'|'DATE_UPDATED'|'FEATURE_ID'} sort.param
     * @param  {?'ASC'|'DESC'} sort.order
     * @param  {string} sort.featureId
     * @param  {function} cb
     * @return {object}
     */
    ({ param, order = 'ASC', featureId }, cb) => {
      const payload = {
        param,
        order
      };

      if(param === 'FEATURE_ID')
        payload.featureId = featureId;

      setSorting(payload);
      cb?.(payload);

      return payload;
    },
    []
  );

  return {
    state: sorting,
    sortItems
  };
};

export default useItemsSorting;
