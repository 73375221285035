import styled from 'styled-components';
import Box from '@mui/material/Box';

export const Wrapper = styled(Box)`
  width: 30px;
  height: 30px;
  min-width: 30px;
  background: rgb(16, 179, 204, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    width: 18px;
    height: 18px;
  }
`;
