import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';

import { SidebarListItem } from './styles';

const ListItemLink = ({ label, link, submenu, onItemClick }) => {
  const [open, setOpen] = useState(true);

  const location = useLocation();

  const handleClick = useCallback((event) => {
    event?.stopPropagation();
    setOpen(!open);
    onItemClick?.();
  }, [open, onItemClick]);

  return (
    <SidebarListItem button state={{ from: location.pathname }} component={link ? Link : ListItemText} href={link} to={link}>
      {!submenu && (
        <ListItemText className="list-item-text" primary={label} onClick={onItemClick} />
      )}

      {submenu && (
        <ListItemButton className="list-item-button" onClick={handleClick}>
          <ListItemText primary={label} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}

      {submenu && (
        <Collapse className="submenu-list-wrap" in={open} timeout="auto" unmountOnExit>
          <List className="submenu-list">
            {submenu?.map((key) => (
              <ListItemLink
                key={key.label + key.link}
                label={key.label}
                link={key.link}
                submenu={key.submenu}
                onItemClick={key.onItemClick}
              />)
            )}
          </List>
        </Collapse>
      )}
    </SidebarListItem>
  );
};

ListItemLink.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  submenu: PropTypes.array,
  onItemClick: PropTypes.func,
};

export default ListItemLink;
