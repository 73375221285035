import {
  usersListLoadTypes,
  userByIdGetTypes,
  allUsersListGetTypes,
  avatarsListLoadTypes
} from './actions';
import { uniq, get } from 'lodash';
import {
  REQUEST,
  SUCCESS,
  FAILURE,
  USERS_ADD
} from './constants';
import { extendNormalizedStructureByItems } from '../../reducers';

const initialState = {
  list: {
    result: [],
    entities: {
      users: {}
    }
  },
  avatarsList: {},
  isAvatarsFetching: false,
  query: null,
  error: null,
  init: true,
  isFetching: false,
  fetchingUserIds: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case usersListLoadTypes[REQUEST]: {
      return {
        ...state,
        isFetching: true
      };
    }

    case usersListLoadTypes[SUCCESS]: {
      return {
        ...state,
        isFetching: false,
        init: false,
        list: action.response
      };
    }

    case usersListLoadTypes[FAILURE]: {
      return {
        ...state,
        isFetching: false,
        init: false,
        error: action.error
      };
    }

    case userByIdGetTypes[REQUEST]: {
      return {
        ...state,
        isFetching: true,
        fetchingUserIds: [
          ...state.fetchingUserIds,
          action.id
        ],
        query: {
          userId: action.id
        }
      };
    }

    case userByIdGetTypes[SUCCESS]: {
      const { response } = action;
      const newList = { ...state.list };
      const id = (response && response.id) || state.query.userId;

      if (response) {
        Object.assign(newList, {
          result: uniq([
            ...newList.result,
            id
          ]),
          entities: {
            ...newList.entities,
            users: {
              ...newList.entities.users,
              [id]: response
            }
          }
        });
      }

      return {
        ...state,
        isFetching: false,
        fetchingUserIds: [
          ...state.fetchingUserIds.filter(i => i !== id),
        ],
        list: newList
      };
    }

    case allUsersListGetTypes[REQUEST]: {
      return {
        ...state,
        isFetching: true
      };
    }

    case allUsersListGetTypes[SUCCESS]: {
      return {
        ...state,
        isFetching: false,
        init: false,
        list: action.response
      };
    }

    case USERS_ADD: {
      return {
        ...state,
        list: extendNormalizedStructureByItems(...state.list, 'users', action.list)
      };
    }

    case avatarsListLoadTypes[REQUEST]: {
      return onAvatarsListRequest(state);
    }

    case avatarsListLoadTypes[SUCCESS]: {
      return onAvatarsListSuccess(state, action);
    }

    default:
      return state;
  }
}

function onAvatarsListRequest(state) {
  return {
    ...state,
    isAvatarsFetching: true
  };
}

function onAvatarsListSuccess(state, action) {

  const { response } = action;

  return {
    ...state,
    isAvatarsFetching: false,
    avatarsList: {
      ...state.avatarsList,
      ...response.filter(Boolean).reduce((acc, curr) => ({
        ...acc,
        [curr.id]: get(curr, 'value.downloadURL')
      }), {})
    }
  };
}
