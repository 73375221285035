import styled from 'styled-components';

export const AuthConfirmEmailStyled = styled.div`
  border: solid 1px #ddd;
  border-radius: 2px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;

  button.link-button {
    padding: 0;
    margin: 0;
    background: none;
    text-decoration: underline;
    color: blue;
    cursor: pointer;
    border: none;
    font-size: 1em;
  }

  > *:not(:last-child) {
    margin-bottom: 10px;
  }

  > .form-group-inline {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 768px) {
    border: none;
  }
`;
