import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import Search from '../Search';
import InitialList from './InitialList';
import CreateTableButton from './CreateTableButton';

const TablesFilter = ({
  onFilterUpdate,
  value = [],
  onFilterDelete,
  mapTablesTree
}) => {
  const [filter, setFilter] = useState('');

  const filteredList = useMemo(() => mapTablesTree(filter), [mapTablesTree, filter]);

  const handleCheck = useCallback((ids, checked) => {
    let newList;

    if (checked){
      newList = [...new Set([...value, ...ids])];
    } else {
      newList = value.filter(id => !ids.includes(id));
    }

    onFilterUpdate('tableIds', newList.length ? newList : null);
  }, [onFilterUpdate, value]);

  return (
    <Box sx={{ margin: '0 10px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '4px',
          alignItems: 'center',
        }}
      >
        <Search
          onChange={setFilter}
          value={filter}
        />

        <CreateTableButton />
      </Box>

      <InitialList
        onFilterUpdate={handleCheck}
        data={filteredList}
        value={value}
        isFiltered={Boolean(filter)}
        onFilterDelete={onFilterDelete}
      />
    </Box>
  );
};

TablesFilter.propTypes = {
  data: PropTypes.array,
  onFilterUpdate: PropTypes.func.isRequired,
  value: PropTypes.array,
  onFilterDelete: PropTypes.func,
  mapTablesTree: PropTypes.func
};

export default TablesFilter;
