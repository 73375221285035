import React, { useCallback } from 'react';
import PT from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

import ItemSelect from '../../../../../ItemSelect';

const toValue = data => ({
  id: data?.id,
  label: data?.title ?? data?.label,
  code: data?.code
});

const styles = {
  container: {
    gap: 8
  },
  icon: {
    fontSize: 14,
    marginRight: 6
  }
};

const EditLinkValue = ({ onChange, data, onCancel }) => {

  const handleChange = useCallback((value) => {
    onChange(value, { withSubmit: true });
  }, [onChange]);

  return (
    <Box
      display="flex"
      gap={styles.container.gap + 'px'}
      alignItems="center"
      flexGrow="1"
      width="100%"
    >
      <ItemSelect
        value={toValue(data)}
        onChange={handleChange}
        autoFocus
        styles={{
          placeholder: {
            height: '30px',
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            lineHeight: '30px',
            color: 'rgb(170, 170, 170)'
          },
          control: { border: 'none !important' },
          indicators: { display: 'none' },
          menu: { width: '235px' },
          root: { width: `calc(100% - ${styles.container.gap + styles.icon.fontSize + styles.icon.marginRight}px)` }
        }}
      />

      <CloseIcon
        onClick={onCancel}
        size="small"
        sx={{
          color: '#8C8C8C',
          fontSize: styles.icon.fontSize + 'px',
          marginRight: styles.icon.marginRight + 'px',
          cursor: 'pointer'
        }}
      />
    </Box>
  );
};

EditLinkValue.propTypes = {
  onCancel: PT.func.isRequired,
  onChange: PT.func.isRequired,
  data: PT.oneOfType([
    PT.string,
    PT.shape({
      id: PT.string,
      label: PT.string,
      title: PT.string,
      code: PT.string
    }).isRequired,
  ])
};

export default EditLinkValue;
