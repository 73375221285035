import React, { useCallback, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import PT from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import FormulationIngredient from '../FormulationIngredient';

import useIngredients from './useIngredients';

import { componentNames, TRACK_FORMULATION } from '../../../../../analytics/constants';
import { routes } from '../../../../../services/session/constants';

const FormulationProtocol = ({
  itemId,
  data,
  readOnly,
  onAddFormulationValue,
  onUpdateFormulationValue
}) => {
  const { track } = useAnalytics();

  const location = useLocation();

  const [ingredients, {
    total,
    usedItemIds,
    allIngredientsByItemId,
    onAdd,
    onEdit,
    onCancel,
    onChangeTitle,
    onChangeValue,
    onSubmit
  }] = useIngredients(data);

  const tableIds = useMemo(() => {
    return data.titleTables.map(({ id }) => id);
  }, [data.titleTables]);

  const handleAdd = useCallback(() => {
    onAdd();

    track(TRACK_FORMULATION.add, {
      component: matchPath(routes.ITEMS, location.pathname) ? componentNames.SIDEBAR : componentNames.ITEM_VIEW,
    });
  }, [location.pathname, onAdd, track]);

  return (
    <>
      <Table
        sx={{
          tableLayout: 'fixed'
        }}
      >
        <TableBody
          sx={{
            maxWidth: '100%',
            '.MuiTableRow-root:last-child td': {
              borderBottom: 0
            },
          }}
        >
          {ingredients.map(feature => (
            <FormulationIngredient
              key={feature.key}
              itemId={itemId}
              protocolId={data.id}
              id={feature.id}
              stateKey={feature.key}
              title={feature.item}
              value={feature.quantity}
              unit={data.unit}
              edit={feature.edit}
              tableIds={tableIds}
              usedItemIds={usedItemIds}
              allIngredientsByItemId={allIngredientsByItemId}
              readOnly={readOnly}
              onEdit={onEdit}
              onCancel={onCancel}
              onChangeTitle={onChangeTitle}
              onChangeValue={onChangeValue}
              onSubmit={onSubmit}
              onAddFormulationValue={onAddFormulationValue}
              onUpdateFormulationValue={onUpdateFormulationValue}
            />
          ))}

          <TableRow sx={{ maxWidth: '786px' }}>
            <TableCell
              align="left"
              sx={{ padding: '2px 0', width: '50%' }}
            >
              <Typography
                variant="body2"
                color="rgba(0,0,0,.6)"
                p="4px 12px"
              >
                Total
              </Typography>
            </TableCell>

            <TableCell
              align="left"
              sx={{ padding: '2px 0', width: '50%' }}
            >
              <Typography
                variant="body2"
                color="rgba(0,0,0,.6)"
                p="4px 8px"
              >
                {total ? `${total} ${data.unit?.name ?? '' }` : '-'}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Box>
        <Box
          px="8px"
          py="4px"
        >
          <Button
            variant="text"
            size="small"
            startIcon={<AddIcon />}
            onClick={handleAdd}
          >
            Add material
          </Button>
        </Box>
      </Box>
    </>
  );
};

FormulationProtocol.propTypes = {
  itemId: PT.string,
  data: PT.shape({
    id: PT.string,
    title: PT.string,
    features: PT.arrayOf(PT.shape({
      id: PT.string
    })),
    filteredFeatures: PT.arrayOf(PT.shape({
      id: PT.string,
      item: PT.shape({
        id: PT.string,
        title: PT.string,
        code: PT.string,
        viewerMaxRole: PT.string
      }),
      quantity: PT.number
    })),
    titleTables: PT.arrayOf(PT.shape({ id: PT.string })),
    unit: PT.shape({
      name: PT.string
    })
  }),
  readOnly: PT.bool,
  onAddFormulationValue: PT.func,
  onUpdateFormulationValue: PT.func
};

export default FormulationProtocol;

