import React, { useState, useCallback, useEffect } from 'react';
import PT from 'prop-types';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useAnalytics } from 'use-analytics';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import ShareIcon from '@mui/icons-material/Share';

import ShareViewDialog from './ShareViewDialog';

import { TABLE_VIEW } from '../../constants';
import { openAppSnackbarNotification } from '../../../../services/snackbar-notifications/actions';
import { SNACKBAR_TYPES } from '../../../../components/AppSnackbar';
import { TRACK_SHARE_VIEW, componentNames } from '../../../../analytics/constants';

const VIEWER_NAME = gql`
  query ViewerName {
    viewer {
      id
      name
    }
  }
`;

const CREATE_DASHBOARD_STATE = gql`
  mutation CreateDashboardState($data: String!) {
    createDashboardState(data: $data)
  }
`;

const SHARE_ITEM_QUERY_BY_EMAIL = gql`
  mutation ShareTableItemQueryByEmail($recipientIDs: [ID!]!, $url: String!, $title: String!, $message: String) {
    shareTableItemQueryByEmail(recipientIDs: $recipientIDs, url: $url, title: $title, message: $message)
  }
`;

const ShareView = ({
  displayView,
  itemQueryId,
  dashboardRef,
  dashboardData,
  queryInput,
  disabled
}) => {
  const [shareViewDialogKey, setShareViewDialogKey] = useState(null);
  const [dashboardState, setDashboardState] = useState(null);
  const [shareURL, setShareURL] = useState(null);

  const { data } = useQuery(VIEWER_NAME);

  const [createDashboardState] = useMutation(CREATE_DASHBOARD_STATE);
  const [shareItemQueryByEmail] = useMutation(SHARE_ITEM_QUERY_BY_EMAIL);

  const dispatch = useDispatch();
  const { track } = useAnalytics();

  const handleShareViewDialogOpen = useCallback(async () => {
    setShareViewDialogKey(window.crypto.randomUUID());

    const url = new URL('/items', window.location.origin);

    url.searchParams.append('itemQueryId', itemQueryId);

    if(displayView !== TABLE_VIEW) {
      url.searchParams.append('dashboardId', displayView);

      try {
        const { data } = await createDashboardState({ variables: { data: dashboardState } });

        if(data?.createDashboardState)
          url.searchParams.append('dashboardStateId', data.createDashboardState);
      } catch(e) {
        console.log(e);
      }
    }

    setShareURL(url.href);
  }, [createDashboardState, dashboardState, itemQueryId, displayView]);

  const handleShareViewDialogClose = useCallback(() => {
    setShareViewDialogKey(null);
    setShareURL(null);
  }, []);

  const handleShareViewDialogSubmit = useCallback(async ({ recipients, title, message }) => {
    const recipientUserIDs = recipients.map(({ id }) => id);

    if(displayView === TABLE_VIEW) {
      try {
        await shareItemQueryByEmail({
          variables: {
            recipientIDs: recipientUserIDs,
            url: shareURL,
            title,
            message: message || null
          }
        });

        dispatch(openAppSnackbarNotification({
          message: 'Email sent successfully',
          variant: SNACKBAR_TYPES.INFO
        }));
      } catch(e) {
        dispatch(openAppSnackbarNotification({
          message: 'Failed to share.',
          variant: SNACKBAR_TYPES.ERROR
        }));

        console.log(e);
      }
    } else {
      dashboardRef
        .current
        ?.contentWindow
        .postMessage(
          {
            event: 'SHARE_VIEW',
            payload: {
              recipient_ids: recipientUserIDs,
              title,
              message,
              url: shareURL
            }
          },
          dashboardData?.url
        );
    }

    track(TRACK_SHARE_VIEW.share, {
      component: componentNames.SHARE_VIEW,
      additional_info: {
        ai_shared_with: recipients.map(({ userId }) => userId),
        ai_filter_state: JSON.stringify(queryInput)
      }
    });

    handleShareViewDialogClose();
  }, [
    displayView,
    shareURL,
    shareItemQueryByEmail,
    dashboardRef,
    dashboardData,
    handleShareViewDialogClose,
    dispatch,
    track,
    queryInput
  ]);

  const handleDashboardMessage = useCallback(ev => {
    if(ev.source !== dashboardRef.current?.contentWindow)
      return;

    const message = JSON.parse(ev.data);

    if(message.hasOwnProperty('event') && message.event === 'DASHBOARD_STATE') {
      setDashboardState(message.payload);
    }
  }, [dashboardRef]);

  useEffect(() => {
    if(displayView !== TABLE_VIEW) {
      window.addEventListener('message', handleDashboardMessage);
    }

    return () => {
      window.removeEventListener('message', handleDashboardMessage);
    };
  }, [displayView, handleDashboardMessage]);

  const initialTitle = `${data?.viewer.name ?? 'Sender'} shared a view with you`;

  return (
    <>
      <Tooltip title="Share view">
        <span>
          <IconButton
            size="medium"
            onClick={handleShareViewDialogOpen}
            disabled={disabled}
          >
            <ShareIcon />
          </IconButton>
        </span>
      </Tooltip>

      <ShareViewDialog
        key={shareViewDialogKey}
        open={Boolean(shareViewDialogKey)}
        onClose={handleShareViewDialogClose}
        onSubmit={handleShareViewDialogSubmit}
        initialTitle={initialTitle}
        shareURL={shareURL}
        queryInput={queryInput}
      />
    </>
  );
};

ShareView.propTypes = {
  displayView: PT.string.isRequired,
  itemQueryId: PT.string,
  dashboardStateId: PT.string,
  dashboardRef: PT.object,
  dashboardData: PT.object,
  queryInput: PT.object,
  disabled: PT.bool,
};

export default ShareView;
