import cloneDeep from 'lodash/cloneDeep';

const PLOT_COLORS = ['steelblue', '#8bc34a', '#ff9800', '#f44336', '#607d8b', '#673ab7', '#00bcd4', '#ff5722', '#e91e63', '#795548', '#4caf50', '#2196f3', '#9c27b0', '#ffc107', '#03a9f4', '#3f51b5', '#cddc39', '#009688'];

const validateLineChartData = (input, index) => {
  if(!input) return null;

  const {
    graphValues,
    xAxes,
    yAxes,
    index: key = index,
    id = key
  } = input;

  if(!graphValues || !xAxes || !yAxes) return null;

  return {
    id,
    data: {
      xAxes: cloneDeep(xAxes),
      yAxes: cloneDeep(yAxes),
      graphValues: cloneDeep(graphValues).map((graphValue, i) => ({ ...graphValue, id: i }))
    }
  };
};

const computeMultipleLinesChartData = (input, settings = {}) => {
  const {
    xAxis = 0,
    yAxis = [1],
    yAxisRight = []
  } = settings;

  if(!yAxis.length)
    return [{ id: 0, data: {} }];

  const head = input[0];
  const coordinates = input.slice(1);
  const xAxes = [{ id: 0, title: head[xAxis] }];
  const yAxes = [];

  yAxes.push({ id: 0, title: head[yAxis[0]], position: 'left' });

  if(yAxisRight.length)
    yAxes.push({ id: 1, title: head[yAxisRight[0]], position: 'right' });

  let axisId = 0;

  const leftAxisPlots = yAxis.map((num, i) => ({
    id: axisId++,
    color: PLOT_COLORS[i % PLOT_COLORS.length],
    title: head[num],
    xAxisId: 0,
    yAxisId: 0,
    coord: coordinates
      .map(coords => [
        coords[xAxis],
        coords[num]
      ])
      .filter((coords) => coords.every(num => typeof num === 'number' && !Number.isNaN(num)))
  }));
  const rightAxisPlots = yAxisRight.map((num, i) => ({
    id: axisId++,
    color: PLOT_COLORS[(yAxis.length + i) % PLOT_COLORS.length],
    title: head[num],
    xAxisId: 0,
    yAxisId: 1,
    coord: coordinates
      .map(coords => [
        coords[xAxis],
        coords[num]
      ])
      .filter((coords) => coords.every(num => typeof num === 'number' && !Number.isNaN(num)))
  }));
  const graphValues = leftAxisPlots.concat(rightAxisPlots);

  return [{ id: 0, data: { xAxes, yAxes, graphValues } }];
};

const computeSingleLineChartData = input => {
  return [{
    id: 0,
    data: {
      xAxes: [{ id: 0, title: input[0][0] }],
      yAxes: [{ id: 0, title: input[0][1] }],
      graphValues: [
        { id: 0, xAxisId: 0, yAxisId: 0, title: '', color: 'steelblue', coord: input.slice(1) }
      ]
    }
  }];
};

/**
 * Transform the data received from storage
 * to match linechart settings
 *
 * @param  {(SingleLineCoords[]|MultipleLinesCoords[]|lineChartData[])} input - received data
 * @return {(lineChartData[] | null)}
 */
const mapLinechartData = (input, settings) => {
  if (input) return input;

  const isAlreadyMapped = Boolean(input.graphValues);

  if (isAlreadyMapped) {
    return [{
      id: 0,
      data: {
        ...input,
        graphValues: [{
          ...input.graphValues[0],
          id: 0
        }]
      }
    }];
  }

  if(!Array.isArray(input)) return null;

  const head = input[0];

  if(!Array.isArray(head)) {
    let result = input.map(validateLineChartData).filter(Boolean);

    return result.length ? result : null;
  }

  if(head.length < 2) return null;

  if(head.length > 2)
    return computeMultipleLinesChartData(input, settings);

  return computeSingleLineChartData(input);
};

export default mapLinechartData;
export {
  validateLineChartData,
  computeMultipleLinesChartData,
  computeSingleLineChartData
};
