import styled from 'styled-components';

import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';

export const SingleLine = styled(Box)`
  display: flex;
  flex-direction: row;
  align-Items: center;
  gap: 4px;
  cursor: pointer;
  margin-bottom: 12px;
  text-overflow: ellipsis;
  width: 100%;
  
  svg {
    width: 14px;
    color: #00000099;
  }
  
  &:hover {
    .edit {
      display: ${props => props.editable ? 'initial' : 'none'};;      
    }
  }
`;

export const Text = styled(Typography)`
  font-size: 18px;
  color: #000000DE;
  font-weight: 500;
  line-height: 1;
  max-width: calc(100% - 40px);
`;

export const EditIconStyled = styled(EditIcon).attrs({ className: 'edit' })`
  display: none;
  height: 14px;
`;
