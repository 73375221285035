import styled from 'styled-components';

import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';

export const WrapperStyled = styled(Box)`
  border-right: 1px solid;
  border-left: 1px solid;
  border-bottom: none;
  border-color: ${props => props.isopen ? 'rgba(220, 219, 220, 1)' : 'transparent'};
  
  .MuiListItemButton-root {
    height: 38px;
    display: flex;
    justify-content: space-between;
    background-color: ${props => props.isopen && 'rgba(33, 33, 33, 0.04)'};
    
    :hover {
      background-color: ${props => !props.active && 'white'};
      cursor: ${props => !props.active && 'initial'};
    }
  }
`;

export const IconStyled = styled(ListItemIcon)`
  svg path {
    fill: rgba(0, 0, 0, 0.60);
  }
`;
