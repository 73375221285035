import React, { useCallback } from 'react';
import PT from 'prop-types';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

const DeletingConfirmationDialog = ({ onClose, onSubmit, itemsCount, optionId }) => {
  const handleSubmit = useCallback(() => {
    onSubmit(optionId);
  }, [onSubmit, optionId]);

  return (
    <Dialog
      open
      onClose={onClose}
    >
      <DialogTitle sx={{ width: '670px' }}>
        Delete option
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          This option is used in {itemsCount} items. Are you sure you want to delete it? This action is irreversible.
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button
          color="primary"
          onClick={onClose}
        >
          cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          confirm changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeletingConfirmationDialog.propTypes = {
  onClose: PT.func.isRequired,
  onSubmit: PT.func.isRequired,
  optionId: PT.string.isRequired,
  itemsCount: PT.number.isRequired
};

export default DeletingConfirmationDialog;
