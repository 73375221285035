import React from 'react';

import PasswordPolicyStyled from './styles';

const PasswordPolicy = () =>
  <PasswordPolicyStyled>
    Password should:
    <li>
      - Must be at least 8 characters long
    </li>
    <li>
      - Must have at least one number
    </li>
    <li>
      - Must have at least one uppercase
    </li>
    <li>
      - Must have at least one lowercase
    </li>
  </PasswordPolicyStyled>;

export default PasswordPolicy;
