import React from 'react';
import PT from 'prop-types';

import EnumEditValueSingle from './EnumEditValueSingle';

const EnumEditValue = ({ values, options, onChange, onCancel, onSubmit }) => {
  // Currently, we support only a single enum value
  // set for parameter. It is planned to allow selecting
  // multiple values in the future.
  return (
    <EnumEditValueSingle
      value={values?.[0] ?? null}
      options={options}
      onChange={onChange}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
};

EnumEditValue.propTypes = {
  values: PT.arrayOf(PT.shape({
    id: PT.string,
    title: PT.string
  })),
  options: PT.arrayOf(PT.shape({
    id: PT.string,
    title: PT.string
  })),
  onChange: PT.func,
  onCancel: PT.func,
  onSubmit: PT.func
};

export default EnumEditValue;
