import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <Box key={props.data.value}>
        <Typography
          variant="body2"
        >
          {children}
          {' '}

          {props.data.code &&
            <Typography
              variant="caption"
              sx={{
                color: '#666'
              }}
            >
              ({props.data.code})
            </Typography>
          }
        </Typography>
      </Box>
    </components.SingleValue>
  );
};

SingleValue.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({
    value: PropTypes.string,
    code: PropTypes.string,
  })
};

export default SingleValue;
