import React, { useCallback, useState } from 'react';

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import VideoTooltip from '../../../../../components/VideoTooltip';
import AddTableDialog from '../../../../Tables/AddTableDialog';
import AddFolderDialog from '../../../../Tables/AddFolderDialog';
import VideoHelperDialog from '../../../../../components/VideoHelperDialog';

import { GET_FOLDERS, GET_ITEMS, GET_TABLES } from '../../../services';
import { COLOR_PRIMARY } from '../../../../../styles';

import VIDEO_LINKS from '../../../../../components/VideoHelperDialog/constants';

const CreateTableButton = () => {
  const [menuAnchorEl, setMenuAnchorElement] = useState(null);
  const [tableDialogOpen, setTableDialogOpen] = useState(false);
  const [folderDialogOpen, setFolderDialogOpen] = useState(false);
  const [videoLink, setVideoLink] = useState(null);

  const handleOpenMenu = useCallback(e => {
    setMenuAnchorElement(e.target);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setMenuAnchorElement(null);
  }, []);

  const handleToggleTableDialog = useCallback(() => {
    setMenuAnchorElement(null);
    setTableDialogOpen(state => !state);
  }, []);

  const handleToggleFolderDialog = useCallback(() => {
    setMenuAnchorElement(null);
    setFolderDialogOpen(state => !state);
  }, []);

  const handleVideoDialogToggle = useCallback(() => {
    setVideoLink(state => !state ? VIDEO_LINKS.CREATE_TABLE : null);
  }, []);

  return (
    <>
      <VideoTooltip
        primaryText="Add new folder or table"
        placement="top"
        arrow
        onClick={handleVideoDialogToggle}
      >
        <IconButton
          disableRipple
          sx={{
            height: '30px',
            width: '30px',
            ':hover': {
              color: COLOR_PRIMARY
            }
          }}
          onClick={handleOpenMenu}
        >
          <AddIcon />
        </IconButton>
      </VideoTooltip>

      <Menu
        anchorEl={menuAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={handleToggleTableDialog}
        >
          Add Table
        </MenuItem>

        <MenuItem
          onClick={handleToggleFolderDialog}
        >
          Add Folder
        </MenuItem>
      </Menu>

      {tableDialogOpen ?
        <AddTableDialog
          onClose={handleToggleTableDialog}
          refetchList={[GET_TABLES, GET_ITEMS]}
        /> : null
      }

      {folderDialogOpen ?
        <AddFolderDialog
          onClose={handleToggleFolderDialog}
          refetchList={[GET_FOLDERS, GET_ITEMS]}
        />
        : null
      }

      <VideoHelperDialog
        link={videoLink}
        onClose={handleVideoDialogToggle}
      />
    </>
  );
};

export default CreateTableButton;
