import React, { useState, useCallback, useEffect } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const inputStyles = {
  p: 0
};

const SearchInput  = ({
  initialValue,
  className,
  onSearch,
  disabled
}) => {
  const [value, setValue] = useState(initialValue ?? '');

  useEffect(() => {
    setValue(initialValue ?? '');
  }, [initialValue]);

  useEffect(() => {
    if (disabled)
      setValue('');
  }, [disabled]);

  const handleSearchChange = useCallback((ev) => {
    setValue(ev.target.value);
  }, []);

  const handleSearchClear = useCallback(() => {
    if (initialValue)
      onSearch('');

    setValue('');
  }, [initialValue, onSearch]);

  const handleSearchClick = useCallback(() => {
    onSearch(value);
  }, [onSearch, value]);

  const handleKeyDown = useCallback((ev) => {
    switch(ev.key) {
      case 'Enter': {
        onSearch(value);
        break;
      }

      case 'Escape': {
        handleSearchClear();
        break;
      }
    }
  }, [onSearch, value, handleSearchClear]);

  return (
    <FormControl
      className={className}
      fullWidth
    >
      <InputBase
        value={value}
        onChange={handleSearchChange}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              size="small"
              edge="end"
              onClick={handleSearchClear}
              disabled={!value}
              sx={{
                visibility: value ? 'visible' : 'hidden'
              }}
            >
              <ClearIcon fontSize="16px" />
            </IconButton>

            <IconButton
              size="small"
              edge="end"
              onClick={handleSearchClick}
              disabled={disabled}
            >
              <SearchIcon fontSize="16px" />
            </IconButton>
          </InputAdornment>
        }
        placeholder="Search"
        sx={inputStyles}
      />
    </FormControl>
  );
};

SearchInput.propTypes = {
  initialValue: PT.string,
  className: PT.string,
  onSearch: PT.func,
  disabled: PT.bool,
};

export default styled(SearchInput)`
  border: 1px solid #D2D2D2;
  border-radius: 6px;
  padding: 4px 8px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  box-sizing: border-box;

  .MuiInputBase-root {
    font-size: 14px;
  }

  .MuiInputBase-input {
    height: 24px;
  }

  input {
    padding: 0;
  }
`;
