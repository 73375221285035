import styled from 'styled-components';

import Box from '@mui/material/Box';

export const Header = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 14px;
`;
