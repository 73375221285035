import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import intervalToDuration from 'date-fns/intervalToDuration';
import formatDuration from 'date-fns/formatDuration';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import IdleSignOutContainer from './styles';
import {
  OutlinedInput
} from '../../styles';

const useStyles = makeStyles(t => ({
  menuItem: {
    [t.breakpoints.up('md')]: {
      minHeight: 46
    }
  }
}));

const HOUR = 60;
const WEEK = 7 * 24 * HOUR;

function IdleSignOut({
  idleSignOutValues = [],
  period,
  onChange
}) {
  const classes = useStyles();

  const handlePeriodChange = useCallback(ev => {
    onChange(ev.target.value);
  }, [onChange]);

  const getIntervalToDuration = (countOfMinutes) => {
    if (countOfMinutes >= WEEK) {
      return {
        weeks: Math.floor(countOfMinutes / WEEK)
      };
    }

    return intervalToDuration({ start: 0, end: countOfMinutes * 60 * 1000 });
  };

  const selectItems = idleSignOutValues.map(value => ({
    value: value,
    label: formatDuration(getIntervalToDuration(value), { format: ['hours', 'days', 'weeks'] })
  }));

  const DEFAULT_OPTION_VALUE = selectItems?.length ? selectItems[1].value : null;

  return (
    <IdleSignOutContainer>
      <FormControl>
        <Typography
          className="control-suffix-text"
          variant="body2"
        >
          Sign me out when inactive for:
        </Typography>
      </FormControl>

      <FormControl>
        <Select
          value={period ?? DEFAULT_OPTION_VALUE}
          onChange={handlePeriodChange}
          input={<OutlinedInput />}
        >
          {selectItems.map(({ value, label }) =>
            <MenuItem
              key={value}
              value={value}
              className={classes.menuItem}
            >
              {label}
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </IdleSignOutContainer>
  );
}

IdleSignOut.propTypes = {
  period: PropTypes.number.isRequired,
  idleSignOutValues: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func.isRequired
};

export default IdleSignOut;
