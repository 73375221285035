import React from 'react';
import PropTypes from 'prop-types';

import Chip from '@mui/material/Chip';

import { useDidUpdateEffect } from '../../utils/hooks';

import EnabledFiltersListStyled from './styles';

const style = {
  marginBottom: '10px'
};

const EnabledFiltersList = ({
  isAnyItemSelected,
  isSearchTextExist,
  isSelectedIdsActive,
  onSelectedItemsRemove,
  onSearchRemove
}) => {
  useDidUpdateEffect(() => {
    if (!isAnyItemSelected && isSelectedIdsActive) {
      onSelectedItemsRemove();
    }
  }, [isAnyItemSelected]);

  if(!isSearchTextExist && !isSelectedIdsActive) {
    return null;
  }

  return (
    <EnabledFiltersListStyled style={style}>
      {isSearchTextExist &&
        <Chip
          className="chip"
          color="primary"
          onDelete={onSearchRemove}
          variant="outlined"
          label="Filter: Search"
        />
      }

      {isSelectedIdsActive &&
        <Chip
          color="primary"
          className="chip"
          onDelete={onSelectedItemsRemove}
          variant="outlined"
          label="Filter: Selected items"
        />
      }
    </EnabledFiltersListStyled>
  );
};


EnabledFiltersList.propTypes = {
  isSearchTextExist: PropTypes.bool.isRequired,
  isSelectedIdsActive: PropTypes.bool.isRequired,
  isAnyItemSelected: PropTypes.bool.isRequired,
  onSearchRemove: PropTypes.func.isRequired,
  onSelectedItemsRemove: PropTypes.func.isRequired,
};

export default EnabledFiltersList;
