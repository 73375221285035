import React from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';

import SearchIcon from '@mui/icons-material/Search';

const ColumnOptionsSearch = ({ className, value, onChange }) => (
  <FormControl
    className={className}
    fullWidth
  >
    <InputBase
      value={value}
      onChange={onChange}
      endAdornment={<SearchIcon fontSize="16px" />}
      placeholder="Search"
      sx={{
        p: 0
      }}
    />
  </FormControl>
);

ColumnOptionsSearch.propTypes = {
  className: PT.string,
  value: PT.string,
  onChange: PT.func
};

export default styled(ColumnOptionsSearch)`
  border: 1px solid #D2D2D2;
  border-radius: 6px;
  padding: 4px 8px;
  margin-bottom: 16px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  box-sizing: border-box;

  input {
    padding: 0;
  }
`;
