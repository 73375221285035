import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { NOTIFICATION_TYPES } from '../../../../components/Notifications/constants';

import NotificationsBody from '../../../../components/Notifications/NotificationsBody';
import RootNotification from './RootNotification';
import { generateLink } from '../../../../components/Notifications/utils';

const Notification = ({ type, isDisabled, ...props }) => {
  const { user, team, item } = props;

  const linkTo = generateLink(type, props);

  return (
    <RootNotification
      user={user}
      linkTo={linkTo}
      isDisabled={isDisabled}

      {...props}
    >
      <NotificationsBody
        archived={item?.archived}
        userName={user.name}
        teamTitle={team?.title}
        title={item?.title}
        type={type}
        {...props}
      />
    </RootNotification>
  );
};

Notification.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    archived: PropTypes.bool,
    title: PropTypes.string
  }),
  team: PropTypes.object,
  id: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.oneOf(NOTIFICATION_TYPES).isRequired
};

export default memo(Notification);
