class CustomCategoricalTicker extends window.Bokeh.CategoricalTicker {
  static type = 'CustomCategoricalTicker';

  constructor(ticksNum) {
    super();

    this.ticksNum = ticksNum;
  }

  get_ticks(...params) {
    const ticks = super.get_ticks(...params);
    const interval = Math.ceil(ticks.major.length / this.ticksNum);

    ticks.major = ticks.major.filter((el, i) => i % interval === 0);

    return ticks;
  }
}

export default CustomCategoricalTicker;
