import styled from 'styled-components';

import Page from '../../components/Page';

export default styled(Page)`
  .iframe-container iframe {
    border: none;
    width: 100%;
  }

  /* all styling related to the loading spinner */
  #loading-spinner-container {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999;
    align-content: center;
    width: 100%;
    margin-top: -50px;
    margin-left: 100px;
    visibility: ${props => props.isDashboardLoading ? 'visible' : 'hidden'};
    max-width: 1400px;
  }

  #loading-spinner {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #4AC5DB;
    width: 46px;
    height: 46px;
    z-index: 1;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
