import React, { useCallback, useState } from 'react';
import PT from 'prop-types';
import { useAnalytics } from 'use-analytics';
import styled from 'styled-components';

import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import VideoTooltip from '../../../../components/VideoTooltip';
import SaveNewViewDialog from './dialogs/SaveNewViewDialog';
import VideoHelperDialog from '../../../../components/VideoHelperDialog';
import EditViewDialog from './dialogs/EditViewDialog';
import DeleteViewDialog from './dialogs/DeleteViewDialog';

import VIDEO_LINKS from '../../../../components/VideoHelperDialog/constants';
import { componentNames, TRACK_HELP_VIDEO } from '../../../../analytics/constants';
import { COLOR_PRIMARY_CONTRAST, COLOR_PRIMARY_DARK } from '../../../../styles';

const SavedViews = ({
  className,
  disableSaving,

  onDiscard,
  onUpdate,
  onDelete,
  onRename,
  onSave,

  selectedView,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  //Dialogs state
  const [videoLink, setVideoLink] = useState(null);
  const [newViewDialogOpen, setNewViewDialogOpen] = useState(false);
  const [editViewDialogOpen, setEditViewDialogOpen] = useState(false);
  const [deleteViewDialogOpen, setDeleteViewDialogOpen] = useState(false);

  const { track } = useAnalytics();

  const handleOpenMenu = useCallback(ev => {
    setMenuAnchorEl(ev.target);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const handleDeleteView = useCallback(async () => {
    onDelete(selectedView?.id);
    setDeleteViewDialogOpen(false);
  }, [onDelete, selectedView?.id]);

  const handleOpenNewViewDialog = useCallback(() => {
    setNewViewDialogOpen(true);
    setMenuAnchorEl(null);
  }, []);

  const handleCloseNewViewDialog = useCallback(() => {
    setNewViewDialogOpen(false);
  }, []);

  const handleSaveNewViewDialog = useCallback((title) => {
    setNewViewDialogOpen(false);

    onSave(title);
  }, [onSave]);

  const handleEditView = useCallback(async ({ title }) => {
    setEditViewDialogOpen(false);

    onRename(selectedView.id, { title });
  }, [onRename, selectedView?.id]);

  const handleVideoDialogToggle = useCallback(() => {
    handleCloseMenu();
    setVideoLink(state => !state ? VIDEO_LINKS.SAVED_VIEW : null);
  }, [handleCloseMenu]);

  const handleHoverLink = useCallback(() => {
    track(TRACK_HELP_VIDEO.hover, {
      component: componentNames.HELP,
      additional_info: {
        hover_object: 'saved_views'
      }
    });
  }, [track]);

  const handleOpenEditViewDialog = useCallback(() => {
    setEditViewDialogOpen(true);
    setMenuAnchorEl(null);
  }, []);

  const handleCloseEditViewDialog = useCallback(() => {
    setEditViewDialogOpen(null);
  }, []);

  const handleOpenDeleteViewDialog = useCallback(() => {
    setDeleteViewDialogOpen(true);
    setMenuAnchorEl(null);
  }, []);

  const handleCloseDeleteViewDialog = useCallback(() => {
    setDeleteViewDialogOpen(false);
  }, []);

  const handleUpdate = useCallback(() => {
    setMenuAnchorEl(null);
    onUpdate();
  }, [onUpdate]);

  const handleDiscard = useCallback(() => {
    setMenuAnchorEl(null);
    onDiscard();
  }, [onDiscard]);

  const menuList = [
    selectedView?.changed ? {
      title: `Update ${selectedView.title}`,
      onClick: handleUpdate,
    } : null,
    selectedView?.changed ? {
      title: 'Discard changes',
      onClick: handleDiscard,
    } : null,
    {
      title: 'Save as new view',
      onClick: handleOpenNewViewDialog,
      disabled: disableSaving,
    },
    selectedView ? {
      title: 'Rename',
      onClick: handleOpenEditViewDialog,
    } : null,
    selectedView ? {
      title: 'Delete',
      onClick: handleOpenDeleteViewDialog,
    } : null,
    {
      title: 'Learn more',
      onClick: handleVideoDialogToggle,
    }
  ].filter(Boolean);

  return (
    <Box className={className}>
      <VideoTooltip
        primaryText="Saved views allow you to easily get back to interesting data"
        onClick={handleVideoDialogToggle}
        onHover={handleHoverLink}
      >
        <ButtonBase
          id="saved-views-button"
          disableRipple
          onClick={handleOpenMenu}
          sx={{
            border: selectedView ? `1px solid ${COLOR_PRIMARY_CONTRAST}` : 'inherit',
            borderRadius: '4px',
            height: '22px',
            p: '4px',
            gap: '4px'
          }}
        >
          <BookmarkBorderIcon
            sx={{
              fontSize: '14px',
              color: selectedView ? COLOR_PRIMARY_DARK : 'inherit'
            }}
          />

          {selectedView?.changed ?
            <Typography
              color={COLOR_PRIMARY_DARK}
              sx={{
                fontSize: '10px',
              }}
            >
              Changed
            </Typography>
            : null
          }

          <KeyboardArrowDownIcon sx={{ fontSize: '14px' }} />
        </ButtonBase>
      </VideoTooltip>

      <Menu
        id="saved-views-menu"
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'saved-views-button',
          dense: true
        }}
        sx={{
          '.MuiMenu-paper': {
            'maxWidth': '300px'
          }
        }}
      >
        {menuList.map(menuItem => (
          <MenuItem
            key={menuItem.title}
            onClick={menuItem.onClick}
            disabled={menuItem.disabled}
          >
            {menuItem.title}
          </MenuItem>
        ))}
      </Menu>

      <SaveNewViewDialog
        open={newViewDialogOpen}
        onClose={handleCloseNewViewDialog}
        onSubmit={handleSaveNewViewDialog}
      />

      {editViewDialogOpen ?
        <EditViewDialog
          open={editViewDialogOpen}
          currentTitle={selectedView?.title}
          onClose={handleCloseEditViewDialog}
          onSubmit={handleEditView}
        />
        : null
      }

      <DeleteViewDialog
        open={deleteViewDialogOpen}
        title={selectedView?.title}
        onClose={handleCloseDeleteViewDialog}
        onSubmit={handleDeleteView}
      />

      <VideoHelperDialog
        link={videoLink}
        onClose={handleVideoDialogToggle}
      />
    </Box>
  );
};

SavedViews.propTypes = {
  className: PT.string,
  disableSaving: PT.bool,
  onDiscard: PT.func,
  onUpdate: PT.func,
  onDelete: PT.func,
  onRename: PT.func,
  onSave: PT.func,
  selectedView: PT.object,
  viewChanged: PT.bool
};

export default styled(SavedViews)`
  display: flex;
  align-items: center;
  gap: 4px;

  text-wrap: nowrap;
`;
