const isAtLeastOneNumber = val => /[0-9]/g.test(val.trim());

const isAtLeastOneUppercase = val => /[A-Z]/g.test(val.trim());

const isAtLeast8symbols = val => val.trim().length > 7;

const isAtLeastOneLowercase = val => /[a-z]/g.test(val.trim());

export const validatePassword = value =>
  [
    isAtLeast8symbols,
    isAtLeastOneNumber,
    isAtLeastOneUppercase,
    isAtLeastOneLowercase
  ].map(f => f(value) ? null : true);

export const isPasswordValid = v => !validatePassword(v).some(Boolean);

export const isEmailValid = v => v.trim().length > 4;
