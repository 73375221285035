import React from 'react';
import PT from 'prop-types';

import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

const EnumValue = ({ value }) => {
  const displayText = value?.map(({ title }) => title).join(', ') ?? '';

  return (
    <Tooltip title={displayText}>
      <Typography
        sx={{
          width: 'calc(100% - 20px)',
          overflowWrap: 'break-word'
        }}
        variant="body2"
      >
        {displayText}
      </Typography>
    </Tooltip>
  );
};

EnumValue.propTypes = {
  value: PT.arrayOf(PT.shape({
    title: PT.string
  }))
};

export default EnumValue;
