import groupBy from 'lodash/groupBy';

const mapTables = (tables, searchValue) => {
  return tables
    .filter(({ title }) => !searchValue || title.toLowerCase().includes(searchValue.toLowerCase()))
    .sort((a, b) => a.title.localeCompare(b.title));
};

const mapFolders = (folders, foldersByParentId, tablesByParentId, searchValue) => {
  const result = [];

  for(const folder of folders) {
    const matchSearch = !searchValue || folder.title.toLowerCase().includes(searchValue.toLowerCase());
    const _searchValue = matchSearch ? '' : searchValue;

    const childTables = mapTables(tablesByParentId[folder.id] ?? [], _searchValue);

    const _folder = {
      ...folder,
      isFolder: true,
      children: mapFolders(foldersByParentId[folder.id] ?? [], foldersByParentId, tablesByParentId, _searchValue)
        .concat(childTables)
    };

    if(matchSearch || _folder.children.length)
      result.push(_folder);
  }

  return result.sort((a, b) => a.title.localeCompare(b.title));
};

const mapFoldersAndTablesTree = (folders = [], tables = [], searchValue = '') => {
  const allFolders = new Map(
    folders.map(folder => [folder.id, folder])
  );

  for(const { path } of tables) {
    for(const [i, folder] of path.entries()) {
      if(!allFolders.has(folder.id)) {
        allFolders.set(
          folder.id,
          {
            ...folder,
            parentId: i === 0 ? null : path[i - 1].id,
            path: path.slice(0, i)
          }
        );
      }
    }
  }

  const foldersByParentId = groupBy(Array.from(allFolders.values()), 'parentId');
  const tablesByParentId = groupBy(tables, 'folderId');
  const rootFolders = mapFolders(foldersByParentId.null ?? [], foldersByParentId, tablesByParentId, searchValue);
  const rootTables = mapTables(tablesByParentId.null ?? [], searchValue);

  return rootFolders.concat(rootTables);
};

export default mapFoldersAndTablesTree;
