import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';

import ParametersSelect from './ParametersSelect';
import MethodsSelect from './MethodsSelect';
import ValuesGroup from './ValuesGroup';

import { PARAMETER_VALUE_TYPES } from './constants';
import { updateParameters } from './utils';
import { filterTypes } from '../FiltersPopper/utils';

const ROOT_PADDING_TOP_WITHOUT_LABEL = 0;
const PARAMETER_ID_FIELD = 'featureId';
const METHOD_FIELD = 'method';
const VALUE_FIELD = 'value';
const PADDINGS_VALUE = '40px';

const ParameterLine = ({
  index,
  protocols: items,
  featuresById,
  onRemove,
  data,
  onChange,
  loading,
  disabledOptions
}) => {
  const [input, setInput] = useState('');
  const [error, setError] = useState('');

  const filteredProtocol = useMemo(() => {
    const itemInfo = {
      title: 'Item info',
      children: items.find(protocol => protocol.title === 'Item info').children
        .filter(child => !disabledOptions.includes(child.id) || data.filterType === child.id)
    };

    const otherItems = [...items].slice(1, items.length);

    return [itemInfo, ...otherItems];
  }, [data.filterType, disabledOptions, items]);

  const handleError = useCallback(value => {
    setError(value);
  }, []);

  const handleSearch = useCallback(value => {
    setInput(value);
  }, []);

  const handleChange = useCallback((type) => value => {
    onChange(type, value);
  }, [onChange]);

  const restructuredOptions = useMemo(() => {
    return updateParameters(filteredProtocol);
  }, [filteredProtocol]);

  const mappedOptions = useMemo(() => {
    const list = {};

    restructuredOptions.forEach((option) => {
      list[option.label] = option;

      option.children.forEach(feature => {
        list[feature.value] = feature;
      });
    }, {});

    return list;
  }, [restructuredOptions]);

  const currentParameterValue = useMemo(() => mappedOptions[data.filterType] || mappedOptions[data[PARAMETER_ID_FIELD]],
    [mappedOptions, data]
  );

  const filteredOptions = useMemo(() => {
    const filteredOptionsList = [];
    let visibleAmount = 0;

    restructuredOptions.forEach((option) => {
      if (option.label.toLowerCase().includes(input.toLowerCase())){
        visibleAmount += option.children.length + 1;
        return filteredOptionsList.push(option);
      }

      const children = option.children.filter(feature =>
        feature.label.toLowerCase()
          .includes(input.toLowerCase())
      );

      if (children.length){
        visibleAmount += children.length + 1;
        return filteredOptionsList.push({ ...option, children });
      }


    }, []);

    return { filteredOptionsList, visibleAmount };
  }, [input, restructuredOptions]);

  const rootPaddingTop = ROOT_PADDING_TOP_WITHOUT_LABEL;
  const parameter = featuresById[data.featureId] || featuresById[data.filterType];
  const parameterType = parameter?.valueType ?? PARAMETER_VALUE_TYPES.QUANTITY;

  const { filteredOptionsList, visibleAmount } = filteredOptions;

  const showMethod = data.filterType !== filterTypes.CREATOR_IDS && data.filterType !== filterTypes.CREATED_DATE;

  return (
    <Box
      minWidth={500}
      display="flex"
      mb="8px"
      mr="-13px"
      pt={`${rootPaddingTop}px`}
      position="relative"
      flexDirection="column"
    >
      <Box
        display="flex"
        alignItems="flex-start"
      >
        <Box mr="8px" width="100%" mt="0px" minWidth="40%" maxWidth="40%">
          <ParametersSelect
            loading={loading}
            options={filteredOptionsList}
            visibleAmount={visibleAmount}
            onInputChange={handleSearch}
            onSelect={handleChange(PARAMETER_ID_FIELD)}
            value={currentParameterValue}
            id={index}
          />
        </Box>

        {showMethod ?
          <Box mr="8px">
            <MethodsSelect
              disabled={!parameter}
              value={data[METHOD_FIELD]}
              onChange={handleChange(METHOD_FIELD)}
            />
          </Box>
          : null
        }

        <Box width="100%" mt="0px" maxWidth={showMethod ? '37%' : `calc(60% - ${PADDINGS_VALUE})`}>
          <ValuesGroup
            disabled={!parameter}
            parameter={parameter}
            filterType={data.filterType}
            type={parameterType}
            value={data[VALUE_FIELD]}
            onChange={handleChange(VALUE_FIELD)}
            onError={handleError}
          />
        </Box>

        <IconButton
          color="neutral"
          onClick={onRemove}
        >
          <DeleteOutlineIcon
            sx={{
              width: '16px',
              height: '16px'
            }}
          />
        </IconButton>
      </Box>

      {error &&
        <Box pr="36px" bottom="-20px" left="0px" position="absolute" width="100%">
          <FormHelperText error sx={{ textAlign: 'end' }}>
            {error}
          </FormHelperText>
        </Box>
      }
    </Box>
  );
};

const protocolsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.string.isRequired,
    parameters: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
      })
    )
  })
);

const dataPropType = PropTypes.shape({
  filterType: PropTypes.string,
  featureId: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ])
});

ParameterLine.propTypes = {
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  protocols: protocolsPropType,
  featuresById: PropTypes.object,
  data: dataPropType,
  index: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  disabledOptions: PropTypes.array,
};

export default ParameterLine;
