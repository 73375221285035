import styled from 'styled-components';

import Menu from '@mui/material/Menu';

export const ItemActionsMenu = styled(Menu).attrs({
  className: 'item-actions-menu'
})`
  ul > li, ul > span {
    svg {
      margin-right: 10px;
    }
  }

  .empty-list-banner {
    width: 100px;
    text-align: center;
    outline: none;
  }
`;
