import React, { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useAnalytics } from 'use-analytics';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';

import ResearchInfoContainer from './styles';

import { CHANGE_PROFILE_DETAILS } from './gql';
import { componentNames, TRACK_PROFILE_SETTINGS } from '../../../analytics/constants';

const useStyles = makeStyles({
  inputLabel: {
    transform: 'translate(0px, -1px) scale(0.75)'
  }
});

function ResearchInfo({
  userId,
  info: initialInfo,
  onSave
}) {
  const [info, setInfo] = useState(initialInfo);

  const { track } = useAnalytics();

  const classes = useStyles();

  const handleInfoChange = useCallback(ev => {
    const { name, value } = ev.target;

    setInfo({
      ...info,
      [name]: value
    });
  }, [info]);

  const handleDismissChanges = useCallback(() => {
    setInfo(initialInfo);
  }, [initialInfo]);

  const [changeProfileDetails, { loading }] = useMutation(CHANGE_PROFILE_DETAILS, {
    onCompleted: () => {
      onSave();
    }
  });

  const handleSaveChanges = useCallback(() => {
    changeProfileDetails({
      variables: {
        input: {
          researchAreas: info.researchAreas,
          materialsClass: info.materialsClass
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateProfile: {
          __typename: 'Viewer',
          id: userId,
          researchAreas: info.researchAreas,
          materialsClass: info.materialsClass
        }
      }
    });

    track(TRACK_PROFILE_SETTINGS.change, {
      component: componentNames.RESEARCH_INFO
    });
  }, [userId, info, changeProfileDetails, track]);

  const hasChanges = initialInfo.researchAreas !== info.researchAreas
    || initialInfo.materialsClass !== info.materialsClass;

  const isSaveBtnDisabled = !hasChanges || !info.researchAreas.trim() || loading;

  return (
    <ResearchInfoContainer>
      <FormControl required className="input-base-control form-control first-name">
        <InputLabel
          shrink
          htmlFor="profile-research-areas-input"
          className={classes.inputLabel}
        >
          Area(s) of Discipline
        </InputLabel>

        <InputBase
          id="profile-research-areas-input"
          name="researchAreas"
          value={info.researchAreas ?? ''}
          onChange={handleInfoChange}
        />
      </FormControl>

      <FormControl className="input-base-control form-control last-name">
        <InputLabel
          shrink
          htmlFor="profile-materials-class-input"
          className={classes.inputLabel}
        >
          Class of Material(s) Involved
        </InputLabel>

        <InputBase
          id="profile-materials-class-input"
          name="materialsClass"
          value={info.materialsClass ?? ''}
          onChange={handleInfoChange}
        />
      </FormControl>

      <div className="section-actions">
        <Button
          onClick={handleDismissChanges}
          color="inherit"
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={isSaveBtnDisabled}
          onClick={handleSaveChanges}
        >
          Save
        </Button>
      </div>
    </ResearchInfoContainer>
  );
}

ResearchInfo.propTypes = {
  userId: PropTypes.string.isRequired,
  info: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired
};

export default ResearchInfo;
