import { useReducer, useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { TABLE_VIEW } from '../../constants';
/*
 - filters state
 - item opened in the sidebar
 - columns visibility
 - display view
 - saved view
 - selected items
 - items sorting
 */

const computeDashboardKey = displayView =>
  displayView === TABLE_VIEW ? null : window.crypto.randomUUID();

const createInitialState = ({
  initialSavedView,
  initialDisplayView,
  initialDashboardStateId
}) => {
  let savedView = null;

  if(initialSavedView) {
    const { id, title, dashboardId, queryInput, dashboardStateId, dashboardState, tableItemQueryId } = initialSavedView;

    savedView = {
      id,
      displayView: dashboardId ?? TABLE_VIEW,
      queryInput: cloneDeep(queryInput),
      dashboardStateId,
      dashboardState,
      tableItemQueryId,
      title
    };
  }

  const displayView = initialDisplayView;
  const dashboardKey = computeDashboardKey(displayView);

  return {
    activeItem: null,
    savedView,
    displayView,
    dashboardStateId: initialDashboardStateId ?? null,
    dashboardKey,
    selectedItems: [], // TODO: implement the selected items reducer
    sorting: null // TODO: implement the table sorting reducer
  };
};

const actions = {
  SET_ACTIVE_ITEM: 'SET_ACTIVE_ITEM',
  SET_SAVED_VIEW: 'SET_SAVED_VIEW',
  UPDATE_SAVED_VIEW: 'UPDATE_SAVED_VIEW',
  RESET_DASHBOARD_STATE: 'RESET_DASHBOARD_STATE',
  SET_DISPLAY_VIEW: 'SET_DISPLAY_VIEW'
};

const setSavedViewReducer = (state, action) => {
  if(!action.payload) {
    return {
      ...state,
      savedView: null
    };
  }

  return {
    ...state,
    savedView: action.payload,
    displayView: action.payload.displayView,
    dashboardStateId: action.payload.dashboardStateId,
    dashboardKey: action.config.preventDashboardReload ?
      state.dashboardKey :
      computeDashboardKey(action.payload.displayView)
  };
};

const setDisplayViewReducer = (state, action) => {
  return {
    ...state,
    savedView: null,
    displayView: action.payload,
    dashboardStateId: null,
    dashboardKey: computeDashboardKey(action.payload)
  };
};

const reducer = (state, action) => {
  switch(action.type) {
    case actions.SET_ACTIVE_ITEM:
      return {
        ...state,
        activeItem: action.payload
      };

    case actions.SET_SAVED_VIEW:
      return setSavedViewReducer(state, action);

    case actions.UPDATE_SAVED_VIEW:
      return {
        ...state,
        savedView: {
          ...state.savedView,
          ...action.payload
        },
        dashboardStateId: action.payload.dahsboardStateId
      };

    case actions.RESET_DASHBOARD_STATE:
      return {
        ...state,
        dashboardStateId: null,
        dashboardKey: computeDashboardKey(state.displayView)
      };

    case actions.SET_DISPLAY_VIEW:
      return setDisplayViewReducer(state, action);

    default:
      return state;
  }
};

const useStateControl = (config) => {
  const [state, dispatch] = useReducer(reducer, config, createInitialState);

  const setActiveItem = useCallback(
    /**
     * Sets active item
     * @param {?{
     *          id: string,
     *          tab: ?string,
     *          source: ?string
     *        }} data
     */
    data => {
      dispatch({ type: actions.SET_ACTIVE_ITEM, payload: data });
    },
    []
  );

  const setSavedView = useCallback(
    /**
     * Sets saved view
     * @param  {?{
     *           id: string,
     *           displayView: string|'TABLE_VIEW',
     *           queryInput: object,
     *           dashboardStateId: ?string,
     *           dashboardState: ?string
     *         }} data
     * @param {boolean=} preventDashboardReload
     */
    (data, preventDashboardReload) => {
      dispatch({
        type: actions.SET_SAVED_VIEW,
        payload: data,
        config: { preventDashboardReload }
      });
    },
    []
  );

  const updateSavedView = useCallback(
    /**
     * Updates saved view
     * @param  {?{
     *           queryInput: object,
     *           dashboardStateId: ?string,
     *           dashboardState: ?string
     *         }} data
     */
    data => {
      dispatch({ type: actions.UPDATE_SAVED_VIEW, payload: data });
    },
    []
  );

  const resetDashboardState = useCallback(() => {
    dispatch({ type: actions.RESET_DASHBOARD_STATE });
  }, []);

  const setDisplayView = useCallback(
    /**
     * Sets display view
     * @param  {string|'TABLE_VIEW'} value
     */
    value => {
      dispatch({ type: actions.SET_DISPLAY_VIEW, payload: value });
    },
    []
  );

  return {
    state,
    actions: {
      setActiveItem,
      setSavedView,
      updateSavedView,
      resetDashboardState,
      setDisplayView
    }
  };
};

export default useStateControl;
