import React from 'react';
import PropTypes from 'prop-types';
import min from 'lodash/min';
import max from 'lodash/max';

import ChartContainer from './ChartContainer';

import mapValidData from '../../../utils/graphics/validate-columns';

class ScatterPlot extends React.Component {
  componentDidMount() {
    const {
      preview,
      xAxis,
      yAxis
    } = this.props;
    const Bokeh = window.Bokeh;
    const plot_width = preview ? 150 : void 0;
    const plot_height = preview ? 150 : 300;
    const match_aspect = preview ? void 0 : true;
    const sizing_mode = preview ? void 0 : 'stretch_both';
    const toolbar_location = preview ? null : 'right';

    const titles = this.props.data[0];
    const data = mapValidData(titles, this.props.data.slice(1));

    const xTitle = titles[xAxis];
    const yTitle = titles[yAxis];
    const xValues = data.map(row => row[xAxis]);
    const yValues = data.map(row => row[yAxis]);

    let source = new Bokeh.ColumnDataSource({
      data: {
        x: xValues,
        y: yValues
      }
    });

    const xMin = min(xValues);
    const xMax = max(xValues);
    const yMin = min(yValues);
    const yMax = max(yValues);

    const tooltips = preview ? void 0 : [
      [xTitle, `@{x}`],
      [yTitle, `@{y}`]
    ];

    this.hoverTool = new Bokeh.HoverTool({ tooltips });

    const tools = preview ? void 0 : [
      new Bokeh.PanTool(),
      new Bokeh.BoxZoomTool(),
      this.hoverTool,
      new Bokeh.SaveTool(),
      new Bokeh.ResetTool(),
    ];

    // create some ranges for the plot
    let xdr = new Bokeh.Range1d({ start: xMin - Math.abs(xMin * .05), end: xMax + Math.abs(xMax * .05) });
    let ydr = new Bokeh.Range1d({ start: yMin - Math.abs(yMin * .05), end: yMax + Math.abs(yMax * .05) });

    // make the plot
    // let plot = new Bokeh.Plot({
    //   title: 'BokehJS Plot',
    //   x_range: xdr,
    //   y_range: ydr,
    //   match_aspect: true,
    //   sizing_mode: 'stretch_both',
    //   // output_backend: "webgl",
    //   background_fill_color: '#F2F2F7',
    //   toolbar_location: null,
    //   plot_height: 300
    // });

    let plot = Bokeh.Plotting.figure({
      // title: 'BokehJS Plot',
      x_range: xdr,
      y_range: ydr,

      // output_backend: 'webgl',
      // background_fill_color: '#F2F2F7',

      // plot size config
      width: plot_width,
      height: plot_height,

      // plot sizing config
      match_aspect,
      sizing_mode,

      // toolbar config
      toolbar_location,
      tools
    });

    plot.circle(
      { field: 'x' },
      { field: 'y' },
      {
        source,
        fill_alpha: 0.1,
        fill_color: 'white',
        size: 8
      }
    // {
    //   x: { field: xTitle },
    //   y: { field: yTitle },
    //   size: 5,
    //   color: 'slategray'
    // }
    );

    // add axes to the plot
    // let xaxis = new Bokeh.LinearAxis({ axis_line_color: null });
    // let yaxis = new Bokeh.LinearAxis({ axis_line_color: null });
    // plot.add_layout(xaxis, 'below');
    // plot.add_layout(yaxis, 'left');

    // add grids to the plot
    // let xgrid = new Bokeh.Grid({ ticker: xaxis.ticker, dimension: 0 });
    // let ygrid = new Bokeh.Grid({ ticker: yaxis.ticker, dimension: 1 });
    // plot.add_layout(xgrid);
    // plot.add_layout(ygrid);

    // add a Line glyph
    // let line = new Bokeh.Line({
    //     x: { field: xTitle },
    //     y: { field: yTitle },
    //     line_color: '#666699',
    //     line_width: 2
    // });
    // plot.add_glyph(line, source);

    if(preview) {
      plot.xaxis[0].visible = false;
      plot.yaxis[0].visible = false;
    } else {
      plot.toolbar.logo = null;

      // axes config
      plot.xaxis[0].axis_label = xTitle;
      plot.yaxis[0].axis_label = yTitle;
      plot.yaxis[0].major_label_orientation = 'vertical';

      // toolbar config
      plot.toolbar.active_inspect = null;
      plot.toolbar.active_scroll = null;
      plot.toolbar.active_tap = null;
    }

    this.plot = plot;
    this.source = source;

    // plot.toolbar.tools[0].active = true;

    Bokeh.Plotting.show(plot, this.chartContainerRef);

    this.hoverTool.active = true;
  }

  componentDidUpdate({
    xAxis: prevXAxis,
    yAxis: prevYAxis
  }) {
    const { xAxis, yAxis } = this.props;

    if(xAxis !== prevXAxis || yAxis !== prevYAxis) {
      const { plot, source } = this;
      const { x, y } = source.data;

      const titles = this.props.data[0];
      const data = mapValidData(titles, this.props.data.slice(1));

      // const [ titles, values ] = [ data[0], data.slice(1) ];

      for(let i = 0, l = x.length; i < l; i++) {
        const row = data[i];

        x[i] = row[xAxis];
        y[i] = row[yAxis];
      }

      const xMin = min(x);
      const xMax = max(x);
      const yMin = min(y);
      const yMax = max(y);

      plot.x_range.start = xMin - Math.abs(xMin * .05);
      plot.x_range.end = xMax + Math.abs(xMax * .05);
      plot.y_range.start = yMin - Math.abs(yMin * .05);
      plot.y_range.end = yMax + Math.abs(yMax * .05);

      if(!this.props.preview) {
        const xTitle = titles[xAxis];
        const yTitle = titles[yAxis];

        plot.xaxis[0].axis_label = xTitle;
        plot.yaxis[0].axis_label = yTitle;

        this.hoverTool.tooltips[0][0] = xTitle;
        this.hoverTool.tooltips[1][0] = yTitle;
      }

      source.change.emit();
    }
  }

  render() {
    return (
      <ChartContainer
        ref={ref => { this.chartContainerRef = ref; }}
        className={this.props.preview && 'preview'}
      />
    );
  }
}

ScatterPlot.propTypes = {
  data: PropTypes.array.isRequired,
  preview: PropTypes.bool,
  xAxis: PropTypes.number,
  yAxis: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number
  ])
};

ScatterPlot.defaultProps = {
  xAxis: 0,
  yAxis: [1]
};

export default ScatterPlot;
