import styled from 'styled-components';

export default styled.div`
  max-width: 640px;

  &.preview .bk {
    width: 50px !important;
    height: 50px !important;
  }
`;
