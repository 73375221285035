import get from 'lodash/get';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';

import {
  firebaseInitTypes,
  fetchSignUpTypes,
  fetchSignInTypes,
  userLogoutTypes,
  userWatchTypes,
  userForgotPasswordTypes,
  fetchCreateUserTypes,
  samplesSortSettingsUpdateTypes,
  userUpdateTypes,
  updateUserFieldTypes,
  sampleAssetsSortSettingsUpdateTypes,
} from './actions';
import * as constants from './constants';
import { browserStorage } from '../../utils';

const {
  REQUEST,
  SUCCESS,
  FAILURE,
  CLOSE_SIDEBAR,
  OPEN_SIDEBAR,
  TOGGLE_SIDEBAR,
  TOGGLE_MOBILE_VIEW,
  SET_MOBILE_VIEW,
  BROWSER_STORAGE_SIDEBAR_OPEN,
  ROUTE_NAME_UPDATE,
  SIGN_IN,
  SIGN_OUT,
  CHILD_ADDED,
  LAST_VISITED_PAGE,
  ADD_USER_ACCOUNT,
  SAMPLES_ARCHIVE_SORT_SETTINGS_UPDATE,
  RESET_ERROR,
  CLEAN_PASSWORD_RESET,
  routes
} = constants;

if (browserStorage.get(LAST_VISITED_PAGE) === routes.ROOT_PAGE) {
  browserStorage.set(LAST_VISITED_PAGE, routes.HOME_PAGE);
}

if (isNil(browserStorage.get(BROWSER_STORAGE_SIDEBAR_OPEN))) {
  browserStorage.set(BROWSER_STORAGE_SIDEBAR_OPEN, true);
}

const sidebarOpenVal = browserStorage.get(BROWSER_STORAGE_SIDEBAR_OPEN);
const initialState = {
  sidebarOpen: sidebarOpenVal === true ? true : false,
  isMobileView: false,
  isNewUser: false,
  currentUser: null,
  currentUserInit: true,
  isCurrentUserWatchFetching: false,
  currentUserWatchInit: true,
  isUserFetching: false,
  db: null,
  firebaseSocket: null,
  firebaseInit: true,
  firebaseFetching: false,
  isFetching: false,
  error: null,
  resetPasswordResponse: null,
  authUser: null,
  samplesSortSettings: {
    sortBy: 'title',
    sortAsc: true
  },
  samplesArchiveSortSettings: {
    sortBy: 'last_activity',
    sortAsc: true
  },
  addField: null,
  updateFieldData: null,
  userUpdateData: null,
  autoLogoutChecking: true,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case firebaseInitTypes[REQUEST]: {
      return {
        ...state,
        firebaseFetching: true,
        firebaseInit: false
      };
    }

    case firebaseInitTypes[SUCCESS]: {
      const { db, socket } = action.response;

      return {
        ...state,
        db,
        firebaseFetching: false,
        firebaseSocket: socket
      };
    }

    case CLOSE_SIDEBAR:
      browserStorage.set(BROWSER_STORAGE_SIDEBAR_OPEN, false);

      return {
        ...state,
        sidebarOpen: false
      };

    case OPEN_SIDEBAR:
      browserStorage.set(BROWSER_STORAGE_SIDEBAR_OPEN, true);

      return {
        ...state,
        sidebarOpen: true
      };

    case TOGGLE_SIDEBAR:
      browserStorage.set(BROWSER_STORAGE_SIDEBAR_OPEN, !state.sidebarOpen);

      return {
        ...state,
        sidebarOpen: !state.sidebarOpen
      };

    case TOGGLE_MOBILE_VIEW:
      return {
        ...state,
        isMobileView: !state.isMobileView
      };

    case SET_MOBILE_VIEW:
      return {
        ...state,
        isMobileView: action.value
      };

    case fetchSignUpTypes[REQUEST]:
      return {
        ...state,
        isFetching: true,
        error: null
      };

    case fetchSignUpTypes[SUCCESS]:
      return {
        ...state,
        isFetching: false
      };

    case fetchSignUpTypes[FAILURE]:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };

    case fetchSignInTypes[REQUEST]: {
      return {
        ...state,
        isFetching: true,
        currentUserInit: false
      };
    }

    case fetchSignInTypes[SUCCESS]: {
      return {
        ...state,
        isFetching: false,
        currentUser: null,
        autoLogoutChecking: false,
        samplesSortSettings: initialState.samplesSortSettings,
        isNewUser: action.response.isNewUser
      };
    }

    case fetchSignInTypes[FAILURE]: {
      return {
        ...state,
        isFetching: false,
        autoLogoutChecking: true,
        error: action.error
      };
    }

    case userLogoutTypes[REQUEST]: {
      return {
        ...state,
        currentUserInit: false
      };
    }

    case userLogoutTypes[SUCCESS]:
      return {
        ...state,
        ...omit(initialState, ['db', 'firebaseSocket', 'firebaseInit', 'currentUserInit'])
      };

    case userLogoutTypes[FAILURE]: {
      return {
        ...state,
        error: action.error
      };
    }

    case userWatchTypes[REQUEST]: {
      return {
        ...state,
        isCurrentUserWatchFetching: true
      };
    }

    case userWatchTypes[SUCCESS]: {
      return {
        ...state,
        isCurrentUserWatchFetching: false,
        currentUserWatchInit: false,
      };
    }

    case userWatchTypes[CHILD_ADDED]:
      return {
        ...state,
        currentUser: {
          ...get(state, 'currentUser', {}),
          [action.response.key]: action.response.data
        }
      };

    case userForgotPasswordTypes[REQUEST]: {
      return {
        ...state,
        isUserFetching: true,
        resetPasswordResponse: null,
        error: null
      };
    }

    case userForgotPasswordTypes[SUCCESS]: {
      return {
        ...state,
        isUserFetching: false,
        resetPasswordResponse: action.response
      };
    }

    case userForgotPasswordTypes[FAILURE]: {
      return {
        ...state,
        isUserFetching: false,
        error: action.error
      };
    }

    case CLEAN_PASSWORD_RESET: {
      return {
        ...state,
        resetPasswordResponse: null
      };
    }

    case ROUTE_NAME_UPDATE: {
      return {
        ...state,
        routeName: action.name
      };
    }

    case SIGN_IN:
      return {
        ...state,
        currentUserInit: false,
        authUser: action.authUser,
        currentUser: action.user
      };

    case SIGN_OUT:
      return {
        ...state,
        currentUserInit: false
      };

    case fetchCreateUserTypes[REQUEST]:
      return {
        ...state,
        isFetching: true,
        error: null
      };

    case fetchCreateUserTypes[SUCCESS]:
      return {
        ...state,
        isFetching: false
      };

    case fetchCreateUserTypes[FAILURE]:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };

    case samplesSortSettingsUpdateTypes[REQUEST]: {
      const { sortBy, sortAsc } = action.params;

      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          sort_settings: {
            samples_sort_order: {
              sortBy,
              direction: sortAsc
            }
          }
        }
      };
    }

    case userUpdateTypes[REQUEST]: {
      return {
        ...state,
        userUpdateData: action.data.fbApi
      };
    }

    case userUpdateTypes[SUCCESS]: {
      const newCurrentUser = {
        ...state.currentUser,
        ...state.userUpdateData
      };

      if (action.response && action.response.injectData) {
        Object.assign(newCurrentUser, action.response.data);
      }

      return {
        ...state,
        userUpdateData: null,
        currentUser: newCurrentUser
      };
    }

    case userUpdateTypes[FAILURE]: {
      return {
        ...state,
        userUpdateData: null,
        error: action.error
      };
    }

    case updateUserFieldTypes[REQUEST]: {
      return {
        ...state,
        updateFieldData: action.data
      };
    }

    case updateUserFieldTypes[SUCCESS]: {
      const { name, value, user } = state.updateFieldData;

      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...user,
          [name]: value
        }
      };
    }

    case updateUserFieldTypes[FAILURE]: {
      return {
        ...state,
        error: action.error
      };
    }

    case sampleAssetsSortSettingsUpdateTypes[REQUEST]: {
      const { currentUser } = state;
      const { sortBy, sampleId, sortAsc } = action.data;

      return {
        ...state,
        currentUser: {
          ...currentUser,
          sort_settings: {
            ...get(currentUser, 'sort_settings', {}),
            samples_actions_sort: {
              ...get(currentUser, 'sort_settings.samples_actions_sort', {}),
              [sampleId]: {
                sortBy,
                direction: sortAsc
              }
            }
          }
        }
      };
    }
    case ADD_USER_ACCOUNT: {
      const { id, role } = action.data;

      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          accounts: {
            ...state.currentUser.accounts,
            [id]: { role }
          }
        }
      };
    }

    case constants.REMOVE_USER_ACCOUNT: {
      return onRemoveUserAccount(state, action);
    }

    case SAMPLES_ARCHIVE_SORT_SETTINGS_UPDATE: {
      return {
        ...state,
        samplesArchiveSortSettings: action.options
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null
      };
    }

    case constants.SET_CURRENT_USER: {
      return onSetCurrentUser(state, action);
    }

    default:
      return state;
  }
}

function onRemoveUserAccount(state, { id }) {
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      accounts: omit(state.currentUser.accounts, id)
    }
  };
}

function onSetCurrentUser(state, action) {
  return {
    ...state,
    currentUser: action.data
  };
}
