import styled from 'styled-components';
import {
  COLOR_BLUE,
  COLOR_ERROR_LIGHT,
  COLOR_CHECKED,
  COLOR_WHITE
} from '../../styles';

const AuthContainerStyled = styled.div`
  border: solid 1px #ddd;
  border-radius: 2px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  > *:not(:last-child) {
    margin-bottom: 10px;
  }

  > .form-group-inline {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .question-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17px;
    height: 17px;
    background-color: ${COLOR_BLUE};
    color: white;
    font-weight: bold;
    border-radius: 20px;
    font-size: 1rem;
    position: absolute;
    right: -25px;
    top: -2px;

    span {
      cursor: pointer;
    }
  }

  .password-rules-block {
    padding: 15px;

    .pass-rule-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &.error {
        color: ${COLOR_ERROR_LIGHT};

        & > svg {
          background-color: ${COLOR_ERROR_LIGHT};
        }
      }

      &.checked {
        color: ${COLOR_CHECKED};

        & > svg {
          background-color: ${COLOR_CHECKED};
        }
      }

      & > svg {
        color: ${COLOR_WHITE};
        margin-right: 10px;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        color: white;
      }
    }
  }

  @media (max-width: 768px) {
    border: none;
  }
`;

export default AuthContainerStyled;
export const AuthFormStyled = AuthContainerStyled.withComponent('form');
