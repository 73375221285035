import { call } from 'redux-saga/effects';
import { isPlainObject } from 'lodash';

import { callApi } from '../../api';
import { getParserDataTypes } from '../../utils/parsing';

const MAPPED_DATA_TYPES = {
  DATA_FILE: 'file',
  DATA_JSON: 'json',
  DATA_TEXT: 'text',
  DATA_COMPLEX: 'complex'
};

export function* parseMeasurementById(params) {
  const {
    parser,
    runtime,
    dataType,
    id,
    parserId,
    src,
    parserCode,
    viewType,
    parserName
  } = params;

  const isComplex = dataType === getParserDataTypes('DATA_COMPLEX');
  const mappedDataType = MAPPED_DATA_TYPES[dataType];
  const query = [
    `id=${id}`,
    `parser=${parser}`,
    `runtime=${runtime}`,
    `mtype=${dataType === 'DATA_FILE'}`,
    `src=${src}`,
    `parserCode=${parserCode}`,
    `complex=${isComplex}`
  ];
  const { response, error } = yield call(
    callApi,
    `/web/v1/parse?${query.join('&')}`,
    {},
    isComplex ? 'json' : mappedDataType
  );

  if(error) {
    const errorMessage = isPlainObject(error) ? error.message : error;

    return {
      error: {
        parserId,
        viewType,
        parserName,
        assetId: id,
        message: typeof errorMessage === 'string' ? errorMessage : 'Failed to process attachment'
      }
    };
  }

  return {
    response: {
      parserId,
      isComplex,
      viewType,
      parserName,
      processedData: response,
      measurementId: id
    }
  };
}
