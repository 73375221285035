import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  useSelected,
  useFocused
} from 'slate-react';

import MentionElement from './styles';


function Mention({ attributes, children, element }) {
  const selected = useSelected();
  const focused = useFocused();

  // `children` itself contains a `text` field with user name,
  // so we don't want it to duplicate the `element.name`.
  // The alternative solution could be to set the empty string
  // to a `text` field of the children, but it will affect the
  // serialization of the content and must be fixed together
  // with the API updates.
  const userName = useMemo(() => {
    return Array.isArray(children) ? null : element.name;
  }, [children, element]);

  return (
    <MentionElement
      {...attributes}
      contentEditable={false}
      selected={selected}
      focused={focused}
    >
      @{userName}{children}
    </MentionElement>
  );
}

Mention.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  element: PropTypes.object.isRequired
};

export default Mention;
