import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import PT from 'prop-types';
import { useAnalytics } from 'use-analytics';

import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import SavedViewIcon from '../../../../components/SavedViews/SavedViewIcon';

import { generatePathWithSearchParams } from '../../../../utils';

import { COLOR_PRIMARY } from '../../../../styles';
import { ViewStyled } from './styles';

import { routes } from '../../../../services/session/constants';
import { componentNames, TRACK_SAVED_VIEW } from '../../../../analytics/constants';

const SavedView = ({ id, title, dashboardId }) => {
  const { track } = useAnalytics();

  const url = generatePathWithSearchParams(routes.ALL_ITEMS, {
    savedView: id
  });

  const handleClick = useCallback(() => {
    track(TRACK_SAVED_VIEW.change, {
      component: componentNames.DATA_VIEW,
      additional_info: {
        ai_saved_view_name: title
      }
    });
  }, [title, track]);

  const tooltipTitle = `Saved view: ${title}`;

  return (
    <Tooltip title={tooltipTitle} placement="top">
      <ViewStyled
        component={Link}
        to={url}
        onClick={handleClick}
      >
        <Box
          display="flex"
          alignItems="flex-start"
          gap="4px"
        >
          <Box
            display="flex"
            flexShrink="0"
            width="26px"
            height="26px"
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            sx={{
              backgroundColor: 'rgba(0, 188, 212, 0.04)'
            }}
          >
            <SavedViewIcon
              dashboardId={dashboardId}
              styles={{
                mr: 0,
                fontSize: '16px',
                color: COLOR_PRIMARY
              }}
            />
          </Box>


          <Typography
            variant="body2"
            sx={{
              paddingTop: '4px',
              maxWidth: 160,
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
            }}
          >
            {title}
          </Typography>
        </Box>
      </ViewStyled>
    </Tooltip>
  );
};

SavedView.propTypes = {
  id: PT.string.isRequired,
  title: PT.string.isRequired,
  dashboardId: PT.string,
};

export default SavedView;
