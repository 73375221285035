import React from 'react';
import PT from 'prop-types';

import Tooltip from '@mui/material/Tooltip';

const ButtonTooltip = ({ children, wrapperProps, ...props }) => (
  <Tooltip {...props}>
    <span {...wrapperProps}>
      {children}
    </span>
  </Tooltip>
);

ButtonTooltip.propTypes = {
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node
  ]),
  wrapperProps: PT.object
};

export default ButtonTooltip;
