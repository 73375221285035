import React from 'react';
import { Link } from 'react-router-dom';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

import { COLOR_BLACK } from '../../../../styles';
import { routes } from '../../../../services/session/constants';

const ZoneButton = () => {
  return (
    <ListItemButton
      component={Link}
      to={routes.HOME_PAGE}
      sx={{
        height: '32px',
        ':hover': {
          backgroundColor: 'initial',
          '.MuiListItemText-root': {
            color: COLOR_BLACK
          },
          '.MuiSvgIcon-root': {
            color: COLOR_BLACK
          },
        }
      }}
    >
      <HomeOutlinedIcon
        sx={{
          width: '16px',
          color: '#666',
          marginRight: '4px'
        }}
      />

      <ListItemText
        disableTypography
        sx={{
          fontSize: '14px',
          color: '#666'
        }}
      >
        My Zone
      </ListItemText>
    </ListItemButton>
  );
};

export default ZoneButton;
