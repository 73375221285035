import { useCallback, useMemo, useState } from 'react';

const useExpandList = (objectsList, activeId) => {

  const [expandedList, setExpandedList] = useState(activeId ? [activeId] : []);

  const isAllExpanded = useMemo(() => {
    return objectsList.length === expandedList.length;
  }, [expandedList.length, objectsList.length]);

  const handleToggleExpand = useCallback(() => {
    if (!isAllExpanded){
      const list = objectsList.map(object => object.id);
      setExpandedList(list);
    } else {
      setExpandedList([]);
    }
  }, [isAllExpanded, objectsList]);

  const handleToggleSingleObject = useCallback((id) => {
    if (expandedList.includes(id)){
      setExpandedList(state => state.filter(protocol => protocol !== id));
    } else {
      setExpandedList(state => [...state, id]);
    }
  }, [expandedList]);

  return {
    isAllExpanded,
    handleToggleExpand,
    handleToggleSingleObject,
    expandedList
  };
};

export default useExpandList;
