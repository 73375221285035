import React, { useCallback, useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import ActionsBlock from '../ItemView/Header/ActionsBlock';
import ItemView from '../ItemView';

import { UPDATE_ITEM_VIEWS_COUNT } from './gql';
import { routes } from '../../services/session/constants';
import { OPEN_STATE } from '../ItemView/constants';
import { TABS_VALUES } from '../../scenes/AllItems/constants';

const ItemViewContainer = ({ id, data, refetch }) => {

  const [sampleViewsUpdate] = useMutation(UPDATE_ITEM_VIEWS_COUNT);

  const itemData = data?.tableItem;

  const navigate = useNavigate();

  const { hash } = useLocation();

  const updateCount = useCallback(async () => {
    try {
      await sampleViewsUpdate({
        variables: {
          id: id
        }
      });
    } catch (e) {
      console.error(e);
    }
  }, [id, sampleViewsUpdate]);

  useEffect(() => {
    updateCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleItemRefetchRequest = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleRemove = useCallback(() => {
    navigate(routes.ALL_ITEMS);
  }, [navigate]);

  const defaultTab = useMemo(() => {
    if (hash.startsWith('#comment'))
      return TABS_VALUES.COMMENTS;
  }, [hash]);

  const actions = () => <ActionsBlock
    data={itemData}
    onRemove={handleRemove}
    hideSidebarItems
    isFullscreen
  />;

  return (
    <ItemView
      id={id}
      itemData={itemData}
      openState={OPEN_STATE.FULLSCREEN}
      onRemove={handleRemove}
      onItemRefetchRequest={handleItemRefetchRequest}
      actions={actions}
      defaultTab={defaultTab}
    />
  );
};

ItemViewContainer.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object,
  updateQuery: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired
};

export default ItemViewContainer;
