import { createParameter, filterTypes } from '../index';

const updateParameter = (data, index, updatedId, name, value) => {
  if (index !== updatedId) return data;

  let newParameterState = { ...data };

  if (name === 'featureId') {
    if(value.value === filterTypes.CREATOR_IDS || value.value === filterTypes.CREATED_DATE){
      newParameterState = createParameter(null, value.value);
    } else {
      const parameter = value.data;
      newParameterState = createParameter(parameter);
    }
  } else {
    newParameterState[name] = value;
  }
  return newParameterState;
};

export default updateParameter;
