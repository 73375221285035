import React from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

import Typography from '@mui/material/Typography';

import AddCircleIcon from '@mui/icons-material/AddCircle';

import { TOOLBAR_HEIGHT_PX, FILTER_BAR_HEIGHT } from '../../../scenes/AllItems/constants';
import { COLGROUP_HEADER_HEIGHT_PX } from '../constants';

const ColumnSettingsBlock = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <div className="block-content">
        <AddCircleIcon />

        <Typography variant="body2">
          Select protocols <br /> and parameters
        </Typography>
      </div>
    </div>
  );
};

ColumnSettingsBlock.propTypes = {
  className: PT.string,
  onClick: PT.func
};

export default styled(ColumnSettingsBlock)`
  margin-left: 10px;
  flex: 1 0 250px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  padding: ${COLGROUP_HEADER_HEIGHT_PX}px 0;
  color: grey;

  border-radius: 7px;
  background: rgba(33, 33, 33, 0.08);
  cursor: pointer;

  &:hover {
    color: rgba(0, 0, 0, 0.87);
  }

  .block-content {
    text-align: center;
    position: sticky;
    top: ${TOOLBAR_HEIGHT_PX + FILTER_BAR_HEIGHT + COLGROUP_HEADER_HEIGHT_PX}px;
  }
`;
