import React from 'react';
import PropTypes from 'prop-types';

import ErrorAlertStyled from './styles';

const ErrorAlertError = ({ text }) =>
  <ErrorAlertStyled>
    {text}
  </ErrorAlertStyled>;

ErrorAlertError.propTypes = {
  text: PropTypes.string
};

export default ErrorAlertError;
