import React from 'react';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import SingleTable from './SingleTable';
import NoDataScreen from '../NoDataScreen';

import { FETCH_TOP_TABLES } from '../../../../services/teams/gql';

import { Content, Title } from './styles';

const ActiveTables = () => {
  const { data } = useQuery(FETCH_TOP_TABLES);

  const tables = get(data, 'platformStats.topTables', []).slice(0, 5);

  return (
    <Box
      flex="1"
      display="flex"
      flexDirection="column"
    >
      <Title>
        Active tables
      </Title>

      {tables.length ? <Divider /> : null}

      <Content>
        {tables?.length ?
          tables.map(table => (
            <SingleTable
              key={table.id}
              id={table.id}
              title={table.title}
              lastActive={table.lastActive}
              itemsNumber={table.numberOfTableItems}
              newItemsNumber={table.numberOfNewTableItems}
            />
          )) :
          <NoDataScreen
            title="You dont have any tables yet..."
          />
        }
      </Content>
    </Box>
  );
};

export default ActiveTables;
