import { gql } from '@apollo/client/core';

export const GET_FOLDERS = gql`
  query Folders {
    folders {
      id
      title
      viewerMaxRole
    }
  }
`;

export const MOVE_TABLE = gql`
  mutation UpdateTable($updateTableId: ID!, $hash: String!, $data: UpdateTableInput!) {
    updateTable(id: $updateTableId, hash: $hash, data: $data) {
      id
      folderId
      hash
      path {
        id
      }
    }
  }
`;
