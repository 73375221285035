import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';

import ListItem from '../../ListItem';
import TableMenu from './TableMenu';

import { hasAccess } from '../../../../../utils/roles';

import { ItemWrapper } from './styles';

const depthParams = {
  DEEP_TABLE: 26,
  SINGLE_TABLE: 0
};

const styles = {
  emptyTable: {
    paddingLeft: '24px'
  },
};

const SingleTable = ({
  table,
  value,
  depth = 1,
  onChange,
  onFilterDelete
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const ref = useRef();

  const isChecked = useMemo(() => {
    return value.includes(table.id);
  }, [table.id, value]);

  const handleChange = useCallback((e) => {
    onChange(e, table.id);
  }, [onChange, table.id]);

  const handleMenuOpen = useCallback((ev) => {
    ev?.stopPropagation();
    setAnchorEl(ev.currentTarget);
  }, []);

  const handleMenuClose = useCallback((ev) => {
    ev?.stopPropagation();
    setAnchorEl(null);
  }, []);

  const paddingDepth = useMemo(() => {
    if (depth > 1)
      return depthParams.DEEP_TABLE;
    else return depthParams.SINGLE_TABLE;
  }, [depth]);

  const isRead = hasAccess(table.viewerMaxRole);

  return (
    <Box sx={{ paddingLeft: `${paddingDepth}px`, maxWidth: '100%' }}>
      <ItemWrapper>

        <ListItem
          key={table.id}
          ref={ref}
          onChange={handleChange}
          title={table.title}
          number={table.numberOfTableItems}
          checked={isChecked}
          additionalStyles={styles.emptyTable}
        />

        {isRead ?
          <IconButton
            onClick={handleMenuOpen}
            sx={{
              '&.MuiButtonBase-root': {
                p: 0,
                height: `18px`,
                width: `18px`,
                mr: '2px'
              },
              '& .MuiSvgIcon-root': {
                fontSize: 18
              }
            }}
          >
            <MoreVertIcon />
          </IconButton> :
          null
        }
      </ItemWrapper>

      <TableMenu
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        data={table}
        onFilterDelete={onFilterDelete}
      />
    </Box>
  );
};

SingleTable.propTypes = {
  table: PropTypes.object.isRequired,
  value: PropTypes.array.isRequired,
  depth: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onFilterDelete: PropTypes.func.isRequired
};

export default SingleTable;
