import React, { useCallback, useState } from 'react';
import PT from 'prop-types';
import { generatePath, Link } from 'react-router-dom';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

import MoveFolderDialog from './MoveFolderDialog';
import AddTableDialog from '../../AddTableDialog';
import RenameFolderDialog from './RenameFolderDialog';
import AddFolderDialog from '../../AddFolderDialog';
import DeleteFolderDialog from './DeleteFolderDialog';

import { routes } from '../../../../services/session/constants';

const FolderActionsMenu = ({ anchorEl, onClose, rowData }) => {
  const [addTableDialogOpen, setAddTableDialogOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openMoveDialog, setOpenMoveDialog] = useState(false);
  const [openRenameDialog, setOpenRenameDialog] = useState(false);
  const [addFolderDialogOpen, setAddFolderDialogOpen] = React.useState(false);

  const handleAddFolder = useCallback(() => {
    setAddFolderDialogOpen(state => !state);
    onClose();
  }, [onClose]);

  const handleAddTable = useCallback(() => {
    setAddTableDialogOpen(state => !state);
    onClose();
  }, [onClose]);

  const handleMoveFolder = useCallback(() => {
    setOpenMoveDialog(state => !state);
    onClose();
  }, [onClose]);

  const handleRename = useCallback(() => {
    setOpenRenameDialog(state => !state);
    onClose();
  }, [onClose]);

  const handleDelete = useCallback(() => {
    setOpenDeleteDialog(state => !state);
    onClose();
  }, [onClose]);

  const actions = [
    {
      label: 'add folder',
      actionFn: handleAddFolder
    },
    {
      label: 'add table',
      actionFn: handleAddTable
    },
    {
      label: 'move folder',
      actionFn: handleMoveFolder
    },
    {
      label: 'manage members',
      link: generatePath(routes.FOLDER_SETTINGS, {
        id: rowData.id,
      })
    },
    {
      label: 'rename',
      actionFn: handleRename
    },
    {
      label: 'delete',
      actionFn: handleDelete
    }
  ];

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        MenuListProps={{ dense: true }}
      >
        {actions.map(({ label, actionFn, link }) => (
          <MenuItem
            key={label}
            onClick={actionFn}
            component={link && Link}
            to={link}
          >
            <ListItemText sx={{ textTransform: 'capitalize' }}>
              {label}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>

      {openMoveDialog ?
        <MoveFolderDialog
          onClose={handleMoveFolder}
          id={rowData.id}
          defaultFolder={rowData.parentId}
        /> :
        null
      }

      {addFolderDialogOpen ?
        <AddFolderDialog
          onClose={handleAddFolder}
          defaultFolder={rowData.id}
        /> :
        null
      }

      {addTableDialogOpen ?
        <AddTableDialog
          onClose={handleAddTable}
          defaultFolder={rowData.id}
        /> : null
      }

      {openDeleteDialog ?
        <DeleteFolderDialog
          id={rowData.id}
          onClose={handleDelete}
        />
        : null
      }

      {openRenameDialog ?
        <RenameFolderDialog
          id={rowData.id}
          onClose={handleRename}
          defaultName={rowData.title}
        />
        : null
      }
    </>
  );
};

FolderActionsMenu.propTypes = {
  anchorEl: PT.instanceOf(Element),
  rowData: PT.object.isRequired,
  onClose: PT.func.isRequired
};

export default FolderActionsMenu;
