export const COMMENT_ADDED = 'COMMENT_ADDED';
export const COMMENT_MENTION = 'COMMENT_MENTION';
export const SHARE_VIEW = 'SHARE_VIEW';
export const SHARE_FOLDER = 'SHARE_FOLDER';
export const SHARE_TABLE = 'SHARE_TABLE';
export const NOTIFICATION_TYPES = [
  COMMENT_ADDED,
  COMMENT_MENTION,
  SHARE_VIEW,
  SHARE_FOLDER,
  SHARE_TABLE
];
