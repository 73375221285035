import { gql } from '@apollo/client';

export const CHANGE_PROFILE_DETAILS = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      id
      firstName
      lastName
      position
      country {
        alpha2
        en
      }
      phone
      mobile
    }
  }
`;
