import React from 'react';
import PropTypes from 'prop-types';

import PasswordProvider from './PasswordProvider';
import GoogleProvider from './GoogleProvider';

function AuthProvider({
  provider,
  data,
  allowUnlink,
  onProvidersChanged
}) {
  switch(provider) {
    case 'PASSWORD':
      return (
        <PasswordProvider
          data={data}
          allowUnlink={allowUnlink}
          onProvidersChanged={onProvidersChanged}
        />
      );

    case 'GOOGLE':
      return (
        <GoogleProvider
          data={data}
          allowUnlink={allowUnlink}
          onProvidersChanged={onProvidersChanged}
        />
      );

    default:
      return null;
  }
}

AuthProvider.propTypes = {
  provider: PropTypes.string.isRequired,
  data: PropTypes.object,
  allowUnlink: PropTypes.bool,
  onProvidersChanged: PropTypes.func.isRequired
};

export default AuthProvider;
