import React from 'react';
import PropTypes from 'prop-types';

import { ACTIVE_USER, ENABLED_USER, PENDING_USER } from './constants';
import {
  DisabledChipStyled,
  EnabledChipStyled,
  PendingChipStyled
} from './styles';

const StatusCell = ({ status }) => {
  switch (status) {
    case PENDING_USER:
      return <PendingChipStyled label="Pending" />;
    case ENABLED_USER:
    case ACTIVE_USER:
      return <EnabledChipStyled label="Active" />;
    default:
      return <DisabledChipStyled label="Disabled" />;
  }
};

StatusCell.propTypes = {
  status: PropTypes.string,
};

export default StatusCell;
