import { call } from 'redux-saga/effects';
import identity from 'lodash/identity';

import { getFileData } from '../../utils/common';
import {
  PARSER_DATA_TYPE_FILE,
  PARSER_DATA_TYPE_JSON,
  PARSER_DATA_TYPE_TEXT,
  PARSER_DATA_TYPE_COMPLEX
} from '../../utils/graphics/constants';
import { getBaseUrl } from './utils';
import { DB_PARSER_DATA_TYPES } from '../../utils/parsing';

export function* assetGraphicsDataLoad(loadSettings, mapData = identity) {
  let {
    sampleId,
    assetId,
    url,
    responseType: defaultResponseType = 'text',
    graphicsSettings,
    attachmentId,
    isComplex
  } = loadSettings;

  try {
    const shouldReturnUrl = [
      'downloadURL',
      PARSER_DATA_TYPE_FILE,
      DB_PARSER_DATA_TYPES.get('DATA_FILE')
    ].includes(defaultResponseType);

    if (shouldReturnUrl) {
      return {
        response: {
          graphicsData: typeof mapData === 'function' ? mapData(url) : url,
          sampleId,
          assetId,
          attachmentId,
          rawGraphicsData: url
        }
      };
    }

    let responseType = defaultResponseType;

    if (isComplex) {
      responseType = PARSER_DATA_TYPE_COMPLEX;
    } else {
      const rawUrl = getBaseUrl(url);
      const isJsonExt = getFileData(rawUrl)[1] === PARSER_DATA_TYPE_JSON;

      if(isJsonExt) {
        responseType = PARSER_DATA_TYPE_JSON;
      }
    }


    const body = yield call(fetch, url);
    const parser = async res => {
      switch(responseType) {
        case 'blob':
          return res.blob();
        case DB_PARSER_DATA_TYPES.get('DATA_JSON'):
        case PARSER_DATA_TYPE_JSON:
        case 'json':
          return res.json();
        case PARSER_DATA_TYPE_COMPLEX: {
          const response = await res.text();
          return JSON.parse(response);
        }
        case PARSER_DATA_TYPE_TEXT:
        case 'text':
        case DB_PARSER_DATA_TYPES.get('DATA_TEXT'):
        default:
          return res.text();
      }
    };
    const res = yield call(parser, body);

    return {
      response: {
        sampleId,
        assetId,
        attachmentId,
        graphicsData: mapData(res, graphicsSettings),
        rawGraphicsData: res
      }
    };
  } catch (error) {
    return {
      error: {
        message: error.message || error,
        sampleId,
        assetId
      }
    };
  }
}


