import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';

import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';

import { COLOR_ERROR } from '../../styles';

const styles = {
  snackbarContentRoot: {
    backgroundColor: COLOR_ERROR,
    flexWrap: 'nowrap'
  },
  snackbarMessage: {
    display: 'flex',
    alignItems: 'center'
  },
  snackbarErrorIconRoot: {
    fontSize: '20px',
    marginRight: '10px'
  }
};

const AppSnackbarContentError = ({
  id,
  message,
  onClose,
  classes
}) => (
  <SnackbarContent
    classes={{ root: classes.snackbarContentRoot }}
    aria-describedby={`app-snackbar-notification-${id}`}
    message={
      <span id="message-id" className={classes.snackbarMessage}>
        <ErrorIcon classes={{ root: classes.snackbarErrorIconRoot }} />
        {message}
      </span>
    }
    action={[
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={onClose}
        size="large"
      >
        <CloseIcon />
      </IconButton>
    ]}
  />
);

AppSnackbarContentError.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AppSnackbarContentError);
