import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import ButtonComponent from '@mui/material/Button';
import Fab from '@mui/material/Fab';

const styles = {
  iconContainer: {
    display: 'flex',
    marginRight: '5px'
  }
};

const Button = forwardRef(({
  classes: localClasses,
  styles: { root, label: labelStyles, iconContainer },
  onClick,
  label,
  children,
  circle,
  icon,
  ...rest
}, ref) => {
  const TagName = circle ? Fab : ButtonComponent;

  return (
    <TagName
      classes={{
        root: `${root} ${localClasses.root}`,
        label: `${labelStyles} ${localClasses.labelStyles}`,
      }}
      className={`${rest.disabled ? 'disabled ' : ''}${rest.className || ''}`}
      onClick={onClick}
      ref={ref}

      {...rest}
    >
      {icon &&
        <div className={`${iconContainer} ${localClasses.iconContainer}`}>
          {icon}
        </div>
      }

      {label || children}
    </TagName>
  );
});

Button.propTypes = {
  styles: PropTypes.shape({
    root: PropTypes.string,
    iconContainer: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  label: PropTypes.any,
  children: PropTypes.node,
  circle: PropTypes.bool,
  icon: PropTypes.node,
  classes: PropTypes.object
};

Button.defaultProps = {
  classes: {},
  styles: {},
};

export default withStyles(styles)(Button);
