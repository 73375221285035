import React from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
    fallback: PropTypes.node.isRequired,
    hasError: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { hasError, fallback, children } = this.props;

    if (hasError || this.state.hasError) {
      return fallback;
    }

    return children;
  }
}

export default ErrorBoundary;
