import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import ItemSelect from '../ItemSelect';
import { Typography } from '@mui/material';

const ItemsSelectSection = ({
  onItemChange,
  value,
  error
}) => (
  <>
    <FormControl variant="standard" required>
      <InputLabel
        htmlFor="item-select"
        sx={{ top: '-40px', display: 'flex' }}
      >
        <Typography variant="body2">
          Select Item
        </Typography>
      </InputLabel>

      <ItemSelect
        inputProps={{ id: 'item-select' }}
        value={value}
        onChange={onItemChange}
        error={error}
        styles={{
          control: { height: '45px' },
          root: { width: '450px' }
        }}
      />
    </FormControl>
  </>
);

ItemsSelectSection.propTypes = {
  onItemChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
};

export default ItemsSelectSection;

