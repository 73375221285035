import React, { memo } from 'react';
import { Document } from 'react-pdf';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import DialogContent from '@mui/material/DialogContent/DialogContent';

import PageWithObserver from './PageWithObserver';

const styles = {
  dialogContentLoading: {
    margin: 0,
    padding: 0
  },
  dialogContent: {
    margin: '0 auto',
    padding: '56px 0 32px 0',
    background: 'transparent',
    overflow: 'unset'
  },
  dialogContentNoData: {
    minHeight: '400px',
    background: '#fff',
    textAlign: 'center',
    padding: '40px'
  },
  dialogContentLoadError: {
    minHeight: '400px',
    background: '#fff',
    textAlign: 'center',
    padding: '40px',
  },
  page: {
    marginBottom: '5px'
  }
};

function DocumentContent({
  documentLoaded,
  fileUrl,
  scale,
  numPages,
  isError,
  onDocumentLoadSuccess,
  onDocumentLoadError,
  setPageVisibility,
  classes
}) {
  return (
    <DialogContent
      className={
        `${documentLoaded ? classes.dialogContent : classes.dialogContentLoading} ${isError ? classes.dialogContentLoadError : ''}`
      }
    >
      <Document
        loading={<div></div>}
        file={fileUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
        externalLinkTarget="_blank"
        data-testid="preview-pdf-document"
        noData={
          <div className={classes.dialogContentNoData}>
            No PDF file specified.
          </div>
        }
      >
        {documentLoaded && numPages && Array.from(new Array(numPages), (el, index) => (
          <div key={`page_${index + 1}`} data-testid="preview-pdf-page">
            <PageWithObserver
              className={classes.page}
              pageIndex={index}
              width={794}
              scale={scale}
              setPageVisibility={setPageVisibility}
            />
          </div>
        ))}
      </Document>
    </DialogContent>
  );
}

DocumentContent.propTypes = {
  fileUrl: PropTypes.string,
  documentLoaded: PropTypes.bool,
  numPages: PropTypes.number,
  scale: PropTypes.number,
  isError: PropTypes.bool,
  setPageVisibility: PropTypes.func,
  onDocumentLoadSuccess: PropTypes.func,
  onDocumentLoadError: PropTypes.func,
  classes: PropTypes.object,
};

export default withStyles(styles)(
  memo(DocumentContent)
);
