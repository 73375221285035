import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';

import TableIcon from '../../../../components/Icons/TableIcon';

import { formatDate } from '../../../../services/teams/utils';
import { generatePathWithSearchParams } from '../../../../utils';

import { TeamStyled, Subtext, IconStyled } from './styles';

import { routes } from '../../../../services/session/constants';
import { componentNames, TRACK_TEAM_FILTER } from '../../../../analytics/constants';

const SingleTable = ({
  id,
  title,
  itemsNumber,
  newItemsNumber,
  lastActive,
}) => {
  const { track } = useAnalytics();

  const url = generatePathWithSearchParams(routes.ALL_ITEMS, {
    filterTeam: id
  });

  const handleClick = useCallback(() => {
    track(TRACK_TEAM_FILTER.update, {
      component: componentNames.DATASET_SELECTION,
      additional_info: {
        ai_num_teams_selected: 1,
        ai_team_names: [title],
      }
    });
  }, [title, track]);

  return (
    <Tooltip title={title}>
      <TeamStyled
        to={url}
        component={Link}
        onClick={handleClick}
      >
        <IconStyled>
          <TableIcon sx={{ height: '16px' }} />
        </IconStyled>

        <Box>
          <Typography
            noWrap
          >
            {title}
          </Typography>

          <Box
            display="flex"
            flexDirection="row"
            gap="4px"
          >
            <Subtext>
              {itemsNumber} items
            </Subtext>

            <Divider
              flexItem
              orientation="vertical"
            />

            <Subtext>
              {newItemsNumber} new items
            </Subtext>

            <Divider
              flexItem
              orientation="vertical"
            />

            <Subtext>
              {formatDate(lastActive)}
            </Subtext>
          </Box>
        </Box>
      </TeamStyled>
    </Tooltip>
  );
};

SingleTable.propTypes = {
  id: PropTypes.string.isRequired,
  teamPath: PropTypes.string,
  title: PropTypes.string.isRequired,
  itemsNumber: PropTypes.number.isRequired,
  newItemsNumber: PropTypes.number.isRequired,
  lastActive: PropTypes.number.isRequired,
};

export default SingleTable;
