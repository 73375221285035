import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import dateFnsFormat from 'date-fns/format';

import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import TextEditButtons from '../../../TextEditButtons';

import UPDATE_INFO from './services';

import { SingleLine, EditIconStyled } from './styles';
import { isRoleEqualOrHigher, rolesIds } from '../../../../utils/roles';
import { interceptKeyboardEvent } from '../utils';
import searchSplit from '../../../ItemsTable/utils/searchSplit';
import { MEDIA_QUERIES_DESKTOP } from '../../../../styles';

const KEY_DOWN_EVENT = 'keydown';

const descriptionPlaceholder = 'Describe the item here';

const InfoBlock = ({ id, timestamp, description, viewerMaxRole, search }) => {

  const [timestampValue, setTimestampValue] = useState(null);
  const [descriptionValue, setDescriptionValue] = useState('');
  const [timeElement, setTimeElement] = useState(null);

  const [isTimestampEditing, setIsTimestampEditing] = useState(false);
  const [isDescriptionEditing, setIsDescriptionEditing] = useState(false);

  const [updateInfo] = useMutation(UPDATE_INFO);

  const descriptionContainerRef = useRef();
  const descriptionRef = useRef();
  const descriptionButtonsRef = useRef();

  useEffect(() => {
    if (typeof timestamp === 'number'){
      setTimestampValue(new Date(timestamp));
    } else {
      setTimestampValue(null);
    }

    if (description){
      setDescriptionValue(description);
    } else {
      setDescriptionValue('');
    }
  }, [timestamp, description]);

  const isEditable = useMemo(() => {
    return viewerMaxRole ? isRoleEqualOrHigher(viewerMaxRole, rolesIds.WRITE) : false;
  }, [viewerMaxRole]);

  const handleSwitchTimestamp = useCallback(() => {
    if (!isEditable)
      return;

    setIsTimestampEditing(!isTimestampEditing);
  }, [isEditable, isTimestampEditing]);

  const handleSwitchDescription = useCallback(() => {
    if (!isEditable)
      return;

    setIsDescriptionEditing(!isDescriptionEditing);
  }, [isDescriptionEditing, isEditable]);

  const handleChangeTimestamp = useCallback((value) => {
    setTimestampValue(value);
  }, []);

  const handleChangeDescription = useCallback((e) => {
    setDescriptionValue(e.target.value);
  }, []);

  const timeToDisplay = useMemo(() => {
    if (typeof timestamp === 'number'){
      return dateFnsFormat(timestamp, `'datetime' dd MMM, yyyy '-' HH:mm`);
    }

    return null;
  }, [timestamp]);

  const handleSubmitTimestampEdit = useCallback(async () => {
    setIsTimestampEditing(false);

    const newValue = isNaN(new Date(timestampValue).getTime()) ?
      null :
      new Date(timestampValue).getTime();

    try {
      await updateInfo({
        variables: {
          updateTableItemId: id,
          data: {
            dateFabricated: newValue
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  }, [id, timestampValue, updateInfo]);

  const handleCancelTimestampEdit = useCallback(() => {
    setIsTimestampEditing(false);
    setTimestampValue(timestamp);
  }, [timestamp]);

  const handleSubmitDescriptionEdit = useCallback(async () => {
    setIsDescriptionEditing(false);
    try {
      await updateInfo({
        variables: {
          updateTableItemId: id,
          data: {
            description: descriptionValue,
          }
        }
      });
    }catch (e) {
      console.error(e);
    }
  }, [descriptionValue, id, updateInfo]);

  const handleCancelDescriptionEdit = useCallback(() => {
    setIsDescriptionEditing(false);
    setDescriptionValue(description);
  }, [description]);

  const handleClickOutside = useCallback((e) => {
    if (descriptionRef.current && !descriptionRef.current.contains(e.target) &&
        descriptionButtonsRef.current && !descriptionButtonsRef.current.contains(e.target)) {
      handleSubmitDescriptionEdit();
    }
  }, [handleSubmitDescriptionEdit]);

  const handleDescriptionKeyDown = useCallback(event => {
    interceptKeyboardEvent({
      event,
      onEnter() {
        handleSubmitDescriptionEdit();
      },
      onEscape() {
        handleCancelDescriptionEdit();
      }
    });
  }, [handleSubmitDescriptionEdit, handleCancelDescriptionEdit]);

  const handleTimeKeyDown = useCallback(event => {
    interceptKeyboardEvent({
      event,
      onEnter() {
        handleSubmitTimestampEdit();
      },
      onEscape() {
        handleCancelTimestampEdit();
      }
    });
  }, [handleSubmitTimestampEdit, handleCancelTimestampEdit]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [
    handleClickOutside,
    handleSubmitDescriptionEdit,
    isDescriptionEditing
  ]);

  useEffect(() => {
    timeElement?.addEventListener(KEY_DOWN_EVENT, handleTimeKeyDown);

    return () => {
      timeElement?.removeEventListener(KEY_DOWN_EVENT, handleTimeKeyDown);
    };
  }, [timeElement, handleTimeKeyDown]);

  const descriptionSearchedText = useMemo(() => {
    if (!search || !description)
      return (
        <Typography
          ref={descriptionContainerRef}
          sx={{
            fontSize: '14px',
          }}
          color={description ? 'initial' : 'rgba(152, 152, 152, 0.6)'}
        >
          {description ?? descriptionPlaceholder}
        </Typography>
      );

    const chunks = searchSplit(description, search);

    return (
      <Typography
        ref={descriptionContainerRef}
        sx={{
          fontSize: '14px',
        }}
        color={description ? 'initial' : 'rgba(152, 152, 152, 0.6)'}
      >
        {chunks.map(chunk => {
          if (chunk.search){
            return (
              <mark key={chunk.id}>
                {chunk.text}
              </mark>
            );
          }

          return (
            <React.Fragment key={chunk.id}>
              {chunk.text}
            </React.Fragment>
          );
        })}
      </Typography>
    );
  }, [description, search]);

  return (
    <Box>
      <SingleLine
        onClick={!isDescriptionEditing ? handleSwitchDescription : null}
        description={isDescriptionEditing ? 'true' : undefined}
        editable={isEditable && !isDescriptionEditing ? 'true' : undefined}
      >
        {!isDescriptionEditing ?
          descriptionSearchedText
          :
          <InputBase
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
              padding: '6px',
              width: '100%',
              fontSize: '12px'
            }}
            value={descriptionValue}
            onKeyDown={handleDescriptionKeyDown}
            onChange={handleChangeDescription}
            multiline
            rows={3}
            ref={descriptionRef}
            placeholder={descriptionPlaceholder}
          />
        }

        {!isDescriptionEditing ?
          <EditIconStyled />
          :
          <TextEditButtons
            ref={descriptionButtonsRef}
            onCancel={handleCancelDescriptionEdit}
            onSubmit={handleSubmitDescriptionEdit}
          />
        }
      </SingleLine>

      <SingleLine
        onClick={!isTimestampEditing ? handleSwitchTimestamp : null}
        editable={isEditable && !isTimestampEditing ? 'true' : undefined}
      >
        {!isTimestampEditing ?
          <Typography
            sx={{
              fontSize: '14px',
            }}
            color={timeToDisplay ? '#00000099' : 'rgba(152, 152, 152, 0.6)'}
          >
            {timeToDisplay}
          </Typography>
          :
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
          >
            <DateTimePicker
              value={timestampValue}
              onChange={handleChangeTimestamp}
              ampm={false}
              className="picker"
              format="dd/MM/yyyy HH:mm"
              closeOnSelect={false}
              slotProps={{
                actionBar: {
                  actions: null
                }
              }}
              ref={el => {
                el && setTimeElement(el);
              }}
              desktopModeMediaQuery={`(${MEDIA_QUERIES_DESKTOP})`}
            />
          </LocalizationProvider>
        }

        {!isTimestampEditing ?
          <EditIconStyled />
          :
          <TextEditButtons
            onCancel={handleCancelTimestampEdit}
            onSubmit={handleSubmitTimestampEdit}
          />
        }
      </SingleLine>
    </Box>
  );
};

InfoBlock.propTypes = {
  id: PropTypes.string,
  timestamp: PropTypes.number,
  description: PropTypes.string,
  viewerMaxRole: PropTypes.string,
  search: PropTypes.string,
};
export default InfoBlock;
