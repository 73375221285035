import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';

import useMediaQuery from '@mui/material/useMediaQuery';
import DeleteIcon from '@mui/icons-material/Delete';

import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import TableViewIcon from '@mui/icons-material/TableView';

import TablesFilter from './TablesFilter';
import FilterSection from '../FilterSection/FilterSection';
import PanelResizeControl from '../../../components/PanelResizeControl';
import ToggleButton from './ToggleButton';
import SavedViews from './SavedViews';
import ZoneButton from './ZoneButton';

import { DrawerStyled } from './styles';

import { COLOR_BLACK, COLOR_GREY_DARK, MEDIA_QUERIES_MOBILE } from '../../../styles';
import { routes } from '../../../services/session/constants';
import useTrack from './hooks/useTrack';

import { FILTER_PANEL_DEFAULT_WIDTH_PX, FILTER_PANEL_WIDTH } from '../../../components/PanelResizeControl/constants';

const filterNames = {
  CREATE_DATE: 'CREATE_DATE',
  CREATORS: 'CREATORS',
  PARAMETERS: 'PARAMETERS',
  TABLES: 'TABLES',
  SAVED_VIEWS: 'SAVED_VIEWS',
};

const PANEL_CLOSED_WIDTH_PX = 18;

const Filters = ({
  data,
  activeFilters,
  disableSaving,

  onFilterUpdate,
  onFilterDelete,
  savedViewsProps,
  mapTablesTree
}) => {
  const [expanded, setExpanded] = useState(() => {
    const result = {};

    if (savedViewsProps.selectedView){
      result[filterNames.SAVED_VIEWS] = true;
    }

    if (activeFilters?.tableIds?.length) {
      result[filterNames.TABLES] = true;
    }

    if (activeFilters?.creatorIds?.length) {
      result[filterNames.CREATORS] = true;
    }

    return result;
  });

  const [openFilters, setOpenFilters] = useState(true);

  const [panelWidth, setPanelWidth] = useState(() => {
    const savedPanelWidth = Number.parseInt(window.localStorage.getItem('FILTER_PANEL_WIDTH'));

    return Number.isNaN(savedPanelWidth) ? FILTER_PANEL_DEFAULT_WIDTH_PX : savedPanelWidth;
  });

  const track = useTrack();

  const mobileView = useMediaQuery(`(${MEDIA_QUERIES_MOBILE})`);

  const handleToggleFilters = useCallback(() => {
    setOpenFilters(state => !state);
  }, []);

  const handleToggleOpen = useCallback((filterName) => {
    setExpanded(state => ({
      ...state,
      [filterName]: !state[filterName]
    }));
  }, []);

  const handleFilter = useCallback((type, filterData) => {
    track(type, filterData, data);
    onFilterUpdate(type, filterData);
  }, [track, data, onFilterUpdate]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = useCallback(
    throttle(newValue => {
      if(newValue < window.innerWidth / 2 && newValue >= FILTER_PANEL_DEFAULT_WIDTH_PX)
        setPanelWidth(newValue);
    }, 20),
    []
  );

  const tablesFilterData = data?.tables;

  const tablesFiltersLength = useMemo(() => activeFilters?.tableIds ?
    activeFilters?.tableIds.length : null,
  [activeFilters?.tableIds]);

  const drawerWidth = useMemo(() => {
    if (openFilters){
      return mobileView ? FILTER_PANEL_DEFAULT_WIDTH_PX : panelWidth;
    } else {
      return PANEL_CLOSED_WIDTH_PX;
    }
  }, [mobileView, openFilters, panelWidth]);

  return (
    <>
      <DrawerStyled
        variant="permanent"
        panelWidth={drawerWidth}
      >
        {openFilters &&
        <Box p="0 24px">
          <ZoneButton />

          <Divider />

          <FilterSection
            open={expanded[filterNames.SAVED_VIEWS]}
            name={filterNames.SAVED_VIEWS}
            title="My saved views"
            onToggleOpen={handleToggleOpen}
            icon={BookmarkBorderIcon}
          >
            <SavedViews
              disableSaving={disableSaving}
              {...savedViewsProps}
            />
          </FilterSection>

          <Divider />

          <FilterSection
            open={expanded[filterNames.TABLES]}
            name={filterNames.TABLES}
            title="Tables"
            badge={tablesFiltersLength}
            onToggleOpen={handleToggleOpen}
            onClear={handleFilter}
            icon={TableViewIcon}
          >
            <TablesFilter
              data={tablesFilterData}
              value={activeFilters?.tableIds}
              mapTablesTree={mapTablesTree}
              onFilterUpdate={handleFilter}
              onFilterDelete={onFilterDelete}
            />
          </FilterSection>
        </Box>
        }
      </DrawerStyled>

      <ToggleButton
        open={openFilters}
        onToggle={handleToggleFilters}
        panelWidth={drawerWidth}
      />

      {mobileView || !openFilters ?
        null :
        <PanelResizeControl
          panelWidth={panelWidth}
          onResize={handleResize}
          itemType={FILTER_PANEL_WIDTH}
        />
      }

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          display: drawerWidth !== PANEL_CLOSED_WIDTH_PX ? 'flex' : 'none',
          flexDirection: 'column',
          height: '32px',
          zIndex: 1,
          width: '280px',
          pl: '24px',
          backgroundColor: 'rgb(249, 249, 249)'
        }}
      >
        <Divider />

        <ListItemButton
          sx={{
            display: 'flex',
            gap: '6px',
            height: '32px',
            '&:hover': {
              backgroundColor: 'initial',
              '.MuiTypography-root': {
                color: COLOR_BLACK,
              },
              '.MuiSvgIcon-root': {
                color: COLOR_BLACK
              }
            }
          }}
          component={Link}
          to={routes.ITEMS_DELETED}
        >
          <DeleteIcon
            sx={{
              color: COLOR_GREY_DARK,
              width: '16px'
            }}
          />

          <Typography
            color={COLOR_GREY_DARK}
            sx={{
              fontSize: '14px'
            }}
          >
            Trash
          </Typography>
        </ListItemButton>
      </Box>
    </>
  );
};

Filters.propTypes = {
  data: PropTypes.object,
  selectedView: PropTypes.string,
  activeFilters: PropTypes.object.isRequired,
  disableSaving: PropTypes.bool,
  onFilterUpdate: PropTypes.func.isRequired,
  onFilterDelete: PropTypes.func.isRequired,
  mapTablesTree: PropTypes.func.isRequired,
  savedViewsProps: PropTypes.shape({
    onSelect: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onRename: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDiscard: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    selectedView: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      dashboardId: PropTypes.string,
      dashboardStateId: PropTypes.string,
      tableItemQueryId: PropTypes.string.isRequired,
      changed: PropTypes.bool.isRequired,
    }),
  }),
};
export default Filters;
