import urlSlug from 'url-slug';

import client from '../../../../services/apollo-client';
import APP_TITLES from '../gql/apps';

export default async function app ({ params }) {
  const { folder, title } = params;
  let id = title; // fallback to somewhat support legacy behavior
  let appTitle;

  try {
    const response = await client.query({
      query: APP_TITLES
    });

    let apps = response.data?.applications ?? [];

    if(parent) {
      const parent = apps.find(({ title }) => urlSlug(title) === folder);

      if(parent)
        apps = parent.children;
    }

    const app = apps.find(app => urlSlug(app.title) === title);
    appTitle = app.title;

    if(app)
      id = app.id;
  } catch(e) {
    console.log(e);
  }

  return { id, appTitle };
}
