import { createRequestTypes } from '../../actions';
import * as constants from './constants';

const {
  USERS_LIST_GET,
  USER_BY_ID_GET,
  ALL_USERS_LIST_GET,
} = constants;

export const usersListLoadTypes = createRequestTypes(USERS_LIST_GET);
export const userByIdGetTypes = createRequestTypes(USER_BY_ID_GET);
export const allUsersListGetTypes = createRequestTypes(ALL_USERS_LIST_GET);
export const avatarsListLoadTypes = createRequestTypes(constants.AVATARS_LIST_GET);

