import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';

import AddFolderDialog from '../AddFolderDialog';
import AddTableDialog from '../AddTableDialog';
import Search from './Search';

const TablesTitle = ({ onSearchChange, searchValue }) => {

  const [addFolderDialogOpen, setAddFolderDialogOpen] = useState(false);
  const [addTableDialogOpen, setAddTableDialogOpen] = useState(false);

  const handleToggleFolderDialog = useCallback(() => {
    setAddFolderDialogOpen(state => !state);
  }, []);

  const handleToggleTableDialog = useCallback(() => {
    setAddTableDialogOpen(state => !state);
  }, []);

  return (
    <Box display="flex" justifyContent="space-between">
      <Typography fontSize={24} fontWeight="bold">
        Table Settings
      </Typography>

      <Box display="flex" gap="24px">
        <ButtonBase
          title={'Add folder'}
          onClick={handleToggleFolderDialog}
        >
          <FolderOutlinedIcon sx={{ fontSize: '18px', mx: '5px' }} />

          <Typography
            variant="body2"
            component="span"
            noWrap
          >
            Add Folder
          </Typography>
        </ButtonBase>

        <ButtonBase
          title={'Add Table'}
          onClick={handleToggleTableDialog}
        >
          <TableChartOutlinedIcon sx={{ fontSize: '18px', mx: '5px' }} />

          <Typography
            variant="body2"
            component="span"
            noWrap
          >
            Add Table
          </Typography>
        </ButtonBase>

        <Search
          onChange={onSearchChange}
          value={searchValue}
        />
      </Box>

      {addFolderDialogOpen ?
        <AddFolderDialog
          onClose={handleToggleFolderDialog}
        />
        : null
      }

      {addTableDialogOpen ?
        <AddTableDialog
          onClose={handleToggleTableDialog}
        /> : null
      }
    </Box>
  );
};

TablesTitle.propTypes = {
  searchValue: PropTypes.string,
  onSearchChange: PropTypes.func.isRequired,
};

export default TablesTitle;
