import * as actions from './actions';
import { userLogoutTypes } from '../session/actions';
import * as constants from './constants';

const {
  currentUserAccountsLoadTypes,
} = actions;

const {
  REQUEST,
  SUCCESS,
  SET_RELATED_ACCOUNTS,
} = constants;

const initialState = {
  isFetchingUserAccounts: false,
  isFetchingCurrentUserAccounts: false,
  currentUserAccountsInit: true,
  query: {},
  accounts: [],
  relatedAccounts: [],
  currentUserAccounts: [],
  fetchingAccountIds: [],
  loadedAccount: null,
};

function reducer(state = initialState, action = {}) {
  switch(action.type) {
    case currentUserAccountsLoadTypes[REQUEST]: {
      return {
        ...state,
        currentUserAccountsInit: false,
        isFetchingUserAccounts: true,
        isFetchingCurrentUserAccounts: true,
      };
    }

    case currentUserAccountsLoadTypes[SUCCESS]: {
      return {
        ...state,
        isFetchingUserAccounts: false,
        isFetchingCurrentUserAccounts: false,
        currentUserAccounts: action.response
      };
    }

    case SET_RELATED_ACCOUNTS:
      return onSetRelatedAccounts(state, action);

    case userLogoutTypes[SUCCESS]:
      return initialState;

    default:
      return state;
  }
}

function onSetRelatedAccounts(state, action) {
  return {
    ...state,
    relatedAccounts: action.data
  };
}

export default reducer;
