import React, { useState, useCallback, useRef } from 'react';
import PT from 'prop-types';
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import DeletingConfirmationDialog from './DeletingConfirmationDialog';
import EnumDialogOption from './EnumDialogOption';
import useEnumOptions from './useEnumOptions.hook';
import { REGEXP_OPTION_TITLES_SEPARATOR } from './constants';
import { Typography } from '@mui/material';

const id = 'enum-dialog';

const EnumDialog = ({ onSubmit, onClose, parameterTitle, items }) => {
  const [options, {
    onAdd,
    onUpdate,
    onReorder,
    onRemove
  }] = useEnumOptions(items);

  const [isDeleteParameterDialogOpen, setDeleteParameterDialogOpen] = useState(false);
  const [errors, setErrors] = useState({});

  const optionToDelete = useRef();
  const addButtonRef = useRef();

  const handleDragEnd = useCallback((ev) => {
    const { draggableId, destination } = ev;

    if (!destination) return;

    onReorder(draggableId, destination.index);
  }, [onReorder]);

  const handleRemove = useCallback(id => {
    const option = options.find(({ _id }) => _id === id);
    const shouldConfirm = option.usedInNumberOfItems > 0;

    if (shouldConfirm) {
      optionToDelete.current = option;
      setDeleteParameterDialogOpen(true);

      return;
    }

    onRemove(id);
  }, [options, onRemove]);

  const handleAdd = useCallback(() => {
    onAdd([{ title: '' }]);

    setTimeout(() => {
      addButtonRef.current.scrollIntoView(false);
    });
  }, [onAdd]);

  const handleNewOptions = useCallback((items) => {
    onAdd(items);
  }, [onAdd]);

  const handleConfirmationDialogClose = useCallback(() => {
    optionToDelete.current = null;
    setDeleteParameterDialogOpen(false);
  }, []);

  const handleDeletionSubmit = useCallback((id) => {
    onRemove(id);

    handleConfirmationDialogClose();
  }, [onRemove, handleConfirmationDialogClose]);

  const handleSubmit = useCallback(() => {
    const titlesUsed = new Set();
    const errors = options.reduce((acc, { _id, title }) => {
      if(!title.trim())
        acc[_id] = 'Empty fields are not allowed';
      else if (title.match(REGEXP_OPTION_TITLES_SEPARATOR))
        acc[_id] = 'Comma and line-break symbols are not allowed';
      else if (titlesUsed.has(title))
        acc[_id] = 'The option name must be unique';

      titlesUsed.add(title);

      return acc;
    }, {});

    setErrors(errors);

    if (isEmpty(errors))
      onSubmit(options);
  }, [options, onSubmit]);

  const handleOptionChange = useCallback((id, value) => {
    onUpdate(id, value);

    setErrors(state => omit(state, id));
  }, [onUpdate]);

  const title = `Options ${parameterTitle ? `for ${parameterTitle}` : ''}`;

  return (
    <Dialog
      open
      onClose={onClose}
    >
      <DialogTitle>
        {title}
      </DialogTitle>

      <DialogContent>
        <DialogContentText mb="16px">
          <Typography
            variant="body2"
            fontWeight="500"
            mb="6px"
            color="black"
          >
            Define the options for this Choice protocol in the cells below.
          </Typography>

          <Typography variant="body2">
            Copy/Paste a list of items (for example a, b, c, d…) into a cell to quickly generate a list of options.
          </Typography>
        </DialogContentText>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={id}>
            {provided => (
              <Box
                ref={provided.innerRef}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px'
                }}
                {...provided.droppableProps}
              >
                {options.map((i, idx) => (
                  <Draggable
                    key={i._id}
                    draggableId={i._id}
                    index={idx}
                  >
                    {provided => (
                      <EnumDialogOption
                        provider={provided}
                        optionId={i._id}
                        value={i.title}
                        error={errors[i._id]}
                        onRemove={handleRemove}
                        onNewOptions={handleNewOptions}
                        onChange={handleOptionChange}
                      />
                    )}
                  </Draggable>
                ))}

                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>

        <Button
          ref={addButtonRef}
          sx={{ marginTop: '8px' }}
          startIcon={<AddCircleOutlineIcon />}
          onClick={handleAdd}
        >
          Add
        </Button>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button
          color="primary"
          onClick={onClose}
        >
          cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          update
        </Button>
      </DialogActions>

      {isDeleteParameterDialogOpen &&
        <DeletingConfirmationDialog
          itemsCount={optionToDelete.current.usedInNumberOfItems}
          optionId={optionToDelete.current.id}
          onClose={handleConfirmationDialogClose}
          onSubmit={handleDeletionSubmit}
        />
      }
    </Dialog>
  );
};

EnumDialog.propTypes = {
  onSubmit: PT.func.isRequired,
  onClose: PT.func.isRequired,
  parameterTitle: PT.string.isRequired,
  items: PT.arrayOf(
    PT.shape({
      title: PT.string
    })
  )
};

export default EnumDialog;
