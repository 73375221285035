import React from 'react';
import PT from 'prop-types';

import RawText from '../../RawText';

const ViewEnumValueSingle = ({ data }) => {
  const title = data?.title ?? '';

  return (
    <RawText title={title}>
      {title}
    </RawText>
  );
};

ViewEnumValueSingle.propTypes = {
  data: PT.shape({
    title: PT.string
  })
};

export default ViewEnumValueSingle;
