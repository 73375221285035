import React, { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';

import Comments from '../../../Comments/Comments';

import { GET_ITEM } from '../../gql';

const CommentsTab = ({ data }) => {

  const client = useApolloClient();

  const handleCommentAdded = useCallback((itemId) => {
    const itemData = client.readQuery({
      query: GET_ITEM,
      variables: { itemId }
    });

    client.writeQuery({
      query: GET_ITEM,
      variables: { itemId },
      data: {
        ...itemData,
        tableItem: {
          ...itemData.tableItem,
          interactions: {
            ...itemData.tableItem.interactions,
            comments: itemData.tableItem.interactions.comments + 1
          }
        }
      }
    });
  }, [client]);

  const handleCommentRemoved = useCallback((itemId) => {
    const itemData = client.readQuery({
      query: GET_ITEM,
      variables: { itemId }
    });

    client.writeQuery({
      query: GET_ITEM,
      variables: { itemId },
      data: {
        ...itemData,
        tableItem: {
          ...itemData.tableItem,
          interactions: {
            ...itemData.tableItem.interactions,
            comments: itemData.tableItem.interactions.comments - 1
          }
        }
      }
    });
  }, [client]);

  if (data?.id)
    return (
      <Comments
        iam={data?.iam}
        itemId={data?.id}
        tableId={data?.table.id}
        isSectionExpanded={true}
        onCommentAdded={handleCommentAdded}
        onCommentRemoved={handleCommentRemoved}
        borderless
      />
    );

  return null;
};

CommentsTab.propTypes = {
  data: PropTypes.object
};

export default CommentsTab;
