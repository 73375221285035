import React from 'react';
import PropTypes from 'prop-types';

export { default as FileDropAreaTarget } from './FileDropAreaTarget';

export default function fileDropArea(Component) {
  class FileDropArea extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        className: ''
      };

      this.dragEnterCounter = 0;
    }

    onDragOver = ev => {
      ev.preventDefault();
    };

    onDragEnter = ev => {
      ev.preventDefault();

      this.dragEnterCounter += 1;

      this.setState({ className: 'on-drag-enter' });
    };

    onDragLeave = ev => {
      ev.preventDefault();

      this.dragEnterCounter -= 1;

      if(this.dragEnterCounter === 0)
        this.setState({ className: '' });
    };

    onDragExit = ev => {
      ev.preventDefault();

      this.dragEnterCounter = 0;

      this.setState({ className: '' });
    };

    onDrop = ev => {
      ev.preventDefault();

      this.dragEnterCounter = 0;

      this.setState({ className: '' });

      const { onFilesAdded } = this.props;

      if(typeof onFilesAdded === 'function')
        onFilesAdded(Array.from(ev.dataTransfer.files));
    };

    render() {
      const {
        onDragOver,
        onDragEnter,
        onDragLeave,
        onDragExit,
        onDrop,
        state,
        props: {
          children,
          targetProps,
          disabled
        }
      } = this;

      const {
        className
      } = state;

      const dndEvents = {
        onDragOver,
        onDragEnter,
        onDragLeave,
        onDragExit,
        onDrop,
      };

      return (
        <Component
          dndEvents={disabled ? null : dndEvents}
          className={className}
          {...targetProps}
        >
          {children}
        </Component>
      );
    }
  }

  FileDropArea.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]),
    targetProps: PropTypes.object,
    onFilesAdded: PropTypes.func,
    disabled: PropTypes.bool
  };

  FileDropArea.defaultProps = {
    targetProps: {}
  };

  return FileDropArea;
}
