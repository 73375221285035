import { mapStateToInputParameters } from '../../../ParametersFilter/utils';

import { filterTypes } from '../index';

const createFiltersToQuery = data => {
  const filters = {
    featureSliceByList: {
      featureSliceBys: []
    }
  };

  data.forEach(filter => {
    switch (filter.filterType) {
      case filterTypes.CREATOR_IDS: {
        filters.creatorIds = filter.value;
        break;
      }
      case filterTypes.CREATED_DATE: {
        filters.createdTimestamp = filter.value;
        break;
      }
      default: {
        filters.featureSliceByList.featureSliceBys = [
          ...filters.featureSliceByList.featureSliceBys,
          filter
        ];
        break;
      }
    }
  });

  filters.featureSliceByList.featureSliceBys = filters.featureSliceByList.featureSliceBys
    .map(mapStateToInputParameters)
    .filter(Boolean);

  return filters;
};

export default createFiltersToQuery;
