import React, { useState, useCallback, useMemo } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import dateFnsFormat from 'date-fns/format';

import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DownloadIcon from '@mui/icons-material/Download';

import Graphics from './Graphics';

import { getGraphicsTypeByViewType } from '../../../../../../utils/graphics';
import { downloadResource } from '../../../../../../services/common/api';

const getTooltipText = (data) => {
  const filename = `Filename: ${data.rawFilename}`;
  const creator = `By: ${data.creator.name}`;
  const dateCreated = `Created on: ${
    dateFnsFormat(data.dateCreated, `dd/MM/yyyy 'at' HH:mm`)
  }`;
  const dateUpdated = data.dateUpdated !== data.dateCreated ?
    `Updated on: ${
      dateFnsFormat(data.dateUpdated, `dd/MM/yyyy 'at' HH:mm`)
    }` :
    null;

  return [
    filename,
    creator,
    dateCreated,
    dateUpdated
  ].filter(Boolean).join('\n');
};

const MeasurementView = ({
  className,
  open,
  data,
  allowEdit,
  sidebarState,
  onToggleOpen,
  onEdit,
  onMove,
  onDelete
}) => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [downloadMenuAnchor, setDownloadMenuAnchor] = useState(null);

  const handleMenuOpen = useCallback(ev => {
    ev.stopPropagation(); // prevent toggle list item button
    setMenuAnchor(ev.currentTarget);
  }, []);

  const handleMenuClose = useCallback(ev => {
    ev.stopPropagation(); // prevent toggle list item button
    setMenuAnchor(null);
  }, []);

  const handleEdit = useCallback(ev => {
    ev.stopPropagation(); // prevent toggle list item button
    setMenuAnchor(null);

    onEdit();
  }, [onEdit]);

  const handleMove = useCallback(ev => {
    ev.stopPropagation(); // prevent toggle list item button
    setMenuAnchor(null);

    onMove(data);
  }, [data, onMove]);

  const handleDelete = useCallback(ev => {
    ev.stopPropagation(); // prevent toggle list item button
    setMenuAnchor(null);

    onDelete(data.id);
  }, [data.id, onDelete]);

  const handleOpenDownloadMenu = useCallback(ev => {
    setDownloadMenuAnchor(ev.currentTarget);
  }, []);

  const handleCloseDownloadMenu = useCallback(() => {
    setDownloadMenuAnchor(null);
  }, []);

  const handleDownloadSourceFile = useCallback(() => {
    downloadResource({
      url: data.rawFileDownloadURL,
      filename: data.rawFilename
    });

    setDownloadMenuAnchor(null);
  }, [data]);

  const handleDownloadProcessedFile = useCallback(() => {
    downloadResource({ url: data.processedFileDownloadURL });

    setDownloadMenuAnchor(null);
  }, [data]);

  const displayGraphics = useMemo(() => {
    if(!data.parsingConfig)
      return null;

    if(data.graphics?.length)
      return data.graphics;

    return data.processedFileDownloadURL ?
      [{
        id: window.crypto.randomUUID(),
        graphicsType: getGraphicsTypeByViewType(
          data.parsingConfig.parser.viewType
        ),
        mode: 'VIEW_MODE_GRAPH'
      }] :
      null;
  }, [data]);

  const handleToggleOpen = useCallback(() => {
    onToggleOpen(data.id);
  }, [data.id, onToggleOpen]);

  return (
    <Box className={className}>
      <ListItemButton
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '10px',
          px: '8px',
          py: '11px',
          background: open ? 'rgba(33, 33, 33, 0.04)' : 'none',
          borderBottom: open ? '1px solid #DCDBDC' : 'none'
        }}
        onClick={handleToggleOpen}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '6px',
            overflow: 'hidden'
          }}
        >
          <Typography
            variant="body2"
            fontWeight="500"
            lineHeight="16px"
            color="rgba(0,0,0,.6)"
            noWrap
          >
            {data.title}
          </Typography>

          <Tooltip
            title={
              <Box sx={{ whiteSpace: 'pre-line' }}>
                {getTooltipText(data)}
              </Box>
            }
            placement="top"
          >
            <InfoOutlinedIcon
              sx={{
                fontSize: '14px',
                color: 'rgba(0,0,0,.6)'
              }}
            />
          </Tooltip>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          {allowEdit ?
            <IconButton
              size="small"
              onClick={handleMenuOpen}
              sx={{ p: 0 }}
            >
              <MoreHorizIcon sx={{ fontSize: '16px' }} />
            </IconButton> :
            null
          }

          <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={handleMenuClose}
            MenuListProps={{
              dense: true
            }}
          >
            <MenuItem
              onClick={handleEdit}
            >
              Edit
            </MenuItem>

            <MenuItem
              onClick={handleMove}
            >
              Move
            </MenuItem>

            <MenuItem
              onClick={handleDelete}
            >
              Delete
            </MenuItem>
          </Menu>

          <ExpandMoreIcon
            sx={{
              fontSize: '16px',
              transform: open ? 'rotate(180deg)' : 'none'
            }}
          />
        </Box>
      </ListItemButton>

      <Collapse in={open}>
        <Box p="8px">
          {data.description ?
            <Typography
              fontSize="12px"
              color="rgba(0,0,0,.6)"
              pb="8px"
            >
              {data.description}
            </Typography> :
            null
          }

          {open ?
            <Graphics
              key={sidebarState}
              dataURL={data.processedFileDownloadURL}
              dataType={data.parsingConfig?.parser.dataType}
              graphics={displayGraphics}
              filename={data.rawFilename}
            /> :
            null
          }
        </Box>

        <Box
          sx={{
            p: '8px',
            display: 'flex',
            justifyContent: 'flex-end',
            borderTop: '1px solid #DCDBDC'
          }}
        >
          <Button
            variant="text"
            size="small"
            startIcon={<DownloadIcon />}
            onClick={handleOpenDownloadMenu}
          >
            Download
          </Button>

          <Menu
            anchorEl={downloadMenuAnchor}
            open={Boolean(downloadMenuAnchor)}
            onClose={handleCloseDownloadMenu}
            MenuListProps={{
              dense: true
            }}
          >
            <MenuItem
              onClick={handleDownloadSourceFile}
            >
              Download source file
            </MenuItem>

            {data.processedFileDownloadURL ?
              <MenuItem
                onClick={handleDownloadProcessedFile}
              >
                Download processed file
              </MenuItem> :
              null
            }
          </Menu>
        </Box>
      </Collapse>
    </Box>
  );
};

MeasurementView.propTypes = {
  className: PT.string,
  open: PT.bool,
  data: PT.shape({
    id: PT.string.isRequired,
    title: PT.string.isRequired,
    description: PT.string,
    rawFilename: PT.string.isRequired,
    rawFileDownloadURL: PT.string.isRequired,
    processedFileDownloadURL: PT.string,
    graphics: PT.array,
    dateCreated: PT.number.isRequired,
    dateUpdated: PT.number,
    creator: PT.shape({
      name: PT.string.isRequired
    }).isRequired,
    parsingConfig: PT.shape({
      parser: PT.shape({
        dataType: PT.string,
        viewType: PT.string
      })
    })
  }).isRequired,
  allowEdit: PT.bool,
  sidebarState: PT.string,
  onToggleOpen: PT.func,
  onEdit: PT.func,
  onMove: PT.func,
  onDelete: PT.func
};

export default styled(MeasurementView)`
  border-width: 1px;
  border-style: solid;
  border-bottom-color: #DCDBDC;
  border-left-color: ${({ open }) => open ? '#DCDBDC' : 'transparent'};
  border-right-color: ${({ open }) => open ? '#DCDBDC' : 'transparent'};
  border-top: none;
`;
