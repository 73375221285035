import React from 'react';
import { useQuery } from '@apollo/client';

import { Outlet, useLocation, Navigate } from 'react-router-dom';

import { useAuth } from '../../../context/auth/auth';

import { routes } from '../../../services/session/constants';

import VIEWER_ID from './gql/viewer-id';

const NoViewerRecordExist = () => {
  const { emailVerified } = useAuth();
  const location = useLocation();
  const { data, loading } = useQuery(VIEWER_ID, { skip: !emailVerified });

  if(loading)
    return null; // TODO: find a better intermediate screen state

  if(data?.viewer) {
    const dest = location.state?.from ?? routes.HOME_PAGE;

    return (
      <Navigate
        to={dest}
        replace
      />
    );
  }

  return (
    <Outlet />
  );
};

export default NoViewerRecordExist;
