import styled from 'styled-components';
import { PageContent } from '../../components/Page';

export const PageContentStyled = styled(PageContent)`
  display: flex;
  align-items: center;

  .image-container {
    height: 100%;
    width: 40%;
    max-width: 400px;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    > img {
      width: 70%;
      height: auto;

      object-fit: contain;
    }

    @media (max-width: 1024px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .page-title {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .page-text-content {
    color: rgba(0, 0, 0, 0.54);
  }

  .action-button {
    margin-top: 40px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    text-align: center;
  }
`;
