import styled from 'styled-components';

import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Create';

export const SingleLine = styled(Box)`
  display: flex;
  flex-direction: ${props => props.description ? 'column' : 'row'};
  align-Items: flex-end;
  gap: 4px;
  margin-bottom: 8px;
  cursor: ${props => props.editable ? 'pointer' : 'initial'};
  
  .MuiStack-root {
    padding: 0;
  }

  .picker > :first-child {
    height: 30px;
    font-size: 12px;
  }

  svg {
    width: 14px;
    color: #00000099;
  }
  
  &:hover {
    .edit {
      display: ${props => props.editable ? 'initial' : 'none'};      
    }
  }
`;

export const EditIconStyled = styled(EditIcon).attrs({ className: 'edit' })`
  display: none;
  height: 18px;
`;
