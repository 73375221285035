import React from 'react';

import ListContainerStyled from './styles';

const ErrorMessage = () => (
  <ListContainerStyled>
    <h3 className="list-container-title">
      Notifications
    </h3>

    <div className="error">
      Something went wrong
    </div>
  </ListContainerStyled>
);

export default ErrorMessage;
