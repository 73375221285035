import React from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import TagIcon from '@mui/icons-material/Tag';

import SingleProtocol from './SingleProtocol';

import { COLOR_GREY_LIGHTER } from '../../../../../../../../../../../styles';

const ColumnsList = ({ protocols, editor, open }) => {
  return (
    <Box
      mb="8px"
      flex="0 0 calc(50% - 4px)"
    >
      <Box
        sx={{
          display: 'flex',
          gap: '4px',
          alignItems: 'center',
          mb: '4px'
        }}
      >
        <TagIcon
          sx={{
            color: COLOR_GREY_LIGHTER,
            fontSize: '14px'
          }}
        />

        <Typography
          sx={{
            color: COLOR_GREY_LIGHTER,
            textTransform: 'uppercase',
            fontSize: '10px',
            fontWeight: '600',
          }}
        >
          columns
        </Typography>
      </Box>

      <Box
        sx={{
          maxHeight: '150px',
          overflowY: 'auto',
        }}
      >
        {protocols.map((protocol) => (
          <SingleProtocol
            key={protocol.id}
            data={protocol}
            editor={editor}
            open={open}
          />
        ))}
      </Box>
    </Box>
  );
};

ColumnsList.propTypes = {
  protocols: PT.array.isRequired,
  editor: PT.object.isRequired,
  open: PT.bool.isRequired,
};

export default ColumnsList;
