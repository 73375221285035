import styled from 'styled-components';
import { Common } from '../styles';

export default styled(Common)`
  flex-grow: 1;
  display: flex;
  width: 100%;
  position: absolute;
  top: ${props => props.isFullScreen ? '0' : '64px'};
  bottom: 0;
  left: 0;
  overflow: hidden;
  -webkit-overflow-scrolling:touch
`;
