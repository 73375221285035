import React, { useEffect } from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import AppGraphics from '../../../../../AppGraphics';
import ComplexProcessedData from '../../../../../Assets/ComplexProcessedData';

import useFetchProcessedData from '../../services/use-fetch-processed-data';
import { withDefaultsAndAxes, getGraphicsViewType } from '../../../../../../services/graphics/utils';

const Graphics = ({ dataURL, dataType, graphics, filename }) => {
  const [fetchData, {
    loading,
    data,
    error
  }] = useFetchProcessedData();

  useEffect(() => {
    if(dataURL && dataType)
      fetchData(dataURL, dataType, filename);
  }, [dataURL, dataType, fetchData, filename]);

  if(loading)
    return (
      <Box>
        <Typography
          variant="body2"
        >
          Loading...
        </Typography>
      </Box>
    );

  if(!graphics?.length)
    return (
      <Box
        sx={{
          py: '30px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px'
        }}
      >
        <WarningAmberIcon sx={{ color: 'rgba(0,0,0,.6)' }} />

        <Typography variant="body2" color="rgba(0,0,0,.6)">
          The measurement was not processed.
        </Typography>

        <Typography variant="body2" color="rgba(0,0,0,.6)">
          Please edit the measurement and select configuration.
        </Typography>
      </Box>
    );

  if(error)
    return (
      <Box>
        <Typography
          variant="body2"
          color="#d32f2f"
        >
          {error}
        </Typography>
      </Box>
    );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
      }}
    >
      {dataType === 'DATA_COMPLEX' ?
        <ComplexProcessedData data={data} /> :
        graphics.map(({ id, graphicsType, config, alter }) => (
          <AppGraphics
            key={id}
            type={graphicsType}
            data={data}
            config={withDefaultsAndAxes(
              config,
              getGraphicsViewType(graphicsType),
              alter
            )}
          />
        ))
      }
    </Box>
  );
};

Graphics.propTypes = {
  dataURL: PT.string,
  dataType: PT.string,
  graphics: PT.array,
  filename: PT.string
};

export default Graphics;
