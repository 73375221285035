import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { textMap } from './utils';

import { COLOR_GREY_LIGHTER } from '../../styles';
import PropTypes from 'prop-types';

const Text = ({ type, archived, ...props }) => {
  return (
    <Box>
      {archived &&
      <Typography display="inline" sx={{ color: COLOR_GREY_LIGHTER, fontSize: '14px', lineHeight: '18px' }}>
        {'[Archived] '}
      </Typography>
      }

      <Typography display="inline" sx={{ fontSize: '14px', lineHeight: '18px' }}>
        {textMap(type, props)}
      </Typography>
    </Box>
  );
};

Text.propTypes = {
  type: PropTypes.string.isRequired,
  archived: PropTypes.bool,
};

export default Text;
