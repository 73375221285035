import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import PT from 'prop-types';
import isNumber from 'lodash/isNumber';

import EditQuantityValue from './EditQuantityValue';
import ViewQuantityValue from './ViewQuantityValue';
import EditableValueContainer from '../../EditableValueContainer';

import { UPDATE_FEATURE_VALUE } from '../gql';
import { UPDATE_FORMULATION_VALUE } from '../../../../../../services/formulations/gql';
import { openAppSnackbarNotification } from '../../../../../../services/snackbar-notifications/actions';

const QuantityValue = ({ data, itemId, tableParameter, onUpdateValue, ...props }) => {
  const dispatch = useDispatch();

  const { quantity } = data;

  const [updateFeatureValue] = useMutation(UPDATE_FEATURE_VALUE);
  const [updateFormulationValue] = useMutation(UPDATE_FORMULATION_VALUE);

  const handleUpdate = useCallback(async (value) => {
    const validValue = value ? parseFloat(value) : null;
    const valuesInput = { quantity: validValue };
    const variablesInput = {
      tableParameterId: tableParameter.id,
      tableItemId: itemId,
      ...valuesInput
    };

    try {
      let tableValue;

      if(tableParameter.tableProtocol.type === 'FORMULATION') {
        const response = await updateFormulationValue({
          variables: {
            parameterId: tableParameter.id,
            itemId,
            value: validValue
          },
          optimisticResponse: {
            updateFormulationValue: {
              tableValue: {
                __typename: 'TableValue',
                type: tableParameter.valueType,
                quantity: validValue,
                boolean: null,
                text: null,
                linkedTableItem: null,
                calculationError: null,
                enumValues: null,
                featureId: tableParameter.featureId,
                tableParameterId: tableParameter.id,
                tableItemId: itemId
              },
              dependentTableValues: [],
              tableParameter: {}
            }
          }
        });

        tableValue = response.data.updateFormulationValue.tableValue;
      } else {
        const response = await updateFeatureValue({
          variables: { input: variablesInput },
          optimisticResponse: {
            updateTableValue: {
              tableValue: {
                __typename: 'TableValue',
                type: tableParameter.valueType,
                boolean: null,
                text: null,
                linkedTableItem: null,
                calculationError: null,
                enumValues: null,
                featureId: tableParameter.featureId,
                ...variablesInput,
              },
              dependentTableValues: []
            }
          }
        });

        tableValue = response.data.updateTableValue.tableValue;
      }

      onUpdateValue(tableValue);
    } catch (e) {
      console.error(e);

      dispatch(
        openAppSnackbarNotification({
          variant: 'ERROR',
          message: e.message
        })
      );
    }
  }, [
    tableParameter,
    itemId,
    updateFeatureValue,
    updateFormulationValue,
    onUpdateValue,
    dispatch
  ]);

  return (
    <EditableValueContainer
      {...props}

      defaultValue={isNumber(quantity) ? quantity : ''}
      onUpdate={handleUpdate}
      viewComponent={ViewQuantityValue}
      editComponent={EditQuantityValue}
    />
  );
};

QuantityValue.propTypes = {
  itemId: PT.string.isRequired,
  tableParameter: PT.shape({
    id: PT.string,
    featureId: PT.string,
    valueType: PT.string,
    tableProtocol: PT.shape({
      type: PT.string
    })
  }),
  data: PT.shape({
    quantity: PT.number,
    type: PT.string
  }),
  onUpdateValue: PT.func
};

export default QuantityValue;
