import {
  REQUEST,
  SUCCESS,
  FAILURE,
  CLEAR_GRAPHICS_CONFIG
} from '../constants';

import * as actions from '../actions';

const initialState = {
  fetching: false,
  response: null,
  error: null
};

export default function graphicsConfig(state = initialState, action) {
  switch(action.type) {
    case actions.updateGraphicsConfigTypes[REQUEST]:
    case actions.setGraphicsConfigTypes[REQUEST]:
      return {
        ...state,
        fetching: true
      };

    case actions.updateGraphicsConfigTypes[SUCCESS]:
    case actions.setGraphicsConfigTypes[SUCCESS]:
      return {
        ...state,
        fetching: false,
        response: action.response
      };

    case actions.updateGraphicsConfigTypes[FAILURE]:
    case actions.setGraphicsConfigTypes[FAILURE]:
      return {
        ...state,
        fetching: false,
        // error: action.error
      };

    case CLEAR_GRAPHICS_CONFIG:
      return initialState;

    default:
      return state;
  }
}
