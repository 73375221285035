import React, { useState, useCallback } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import TabsProvider, { TabContent } from '../../../context/tabs';
import UsersTab from './UsersTab';

const TABS = {
  MEMBERS: {
    value: 'MEMBERS',
    label: 'Members'
  }
};

const TabsBlock = ({ className, table, readOnly }) => {
  const [activeTab, setActiveTab] = useState(TABS.MEMBERS.value);

  const handleTabChange = useCallback((ev, value) => {
    setActiveTab(value);
  }, []);

  return (
    <Box className={className}>
      <TabsProvider value={activeTab}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab value={TABS.MEMBERS.value} label={TABS.MEMBERS.label} />
        </Tabs>

        <TabContent value={TABS.MEMBERS.value}>
          <UsersTab
            folderSettings={table}
            readOnly={readOnly}
          />
        </TabContent>
      </TabsProvider>
    </Box>
  );
};

TabsBlock.propTypes = {
  className: PT.string,
  table: PT.object,
  readOnly: PT.bool
};

export default styled(TabsBlock)`
  .MuiTabs-root {
    min-height: 42px;
  }

  .MuiTab-root {
    min-height: 42px;
    padding: 8px;
    text-transform: capitalize;
  }
`;
