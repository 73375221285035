import React, { useCallback } from 'react';
import PT from 'prop-types';

import Autocomplete from '@mui/material/Autocomplete';
import InputBase from '@mui/material/InputBase';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

const EditEnumValueSingle = ({ value, options, onChange }) => {
  const getOptionLabel = useCallback(option => option.title, []);
  const isOptionEqualToValue = useCallback((option, value) => option.id === value?.id, []);

  const handleChange = useCallback((ev, value) => {
    onChange(value, { withSubmit: true });
  }, [onChange]);

  return (
    <Autocomplete
      value={value}
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      openOnFocus
      selectOnFocus
      renderInput={({ InputProps, inputProps, disabled, fullWidth, id }) => {
        return (
          <InputBase
            id={id}
            {...InputProps}
            inputProps={inputProps}
            disabled={disabled}
            fullWidth={fullWidth}
            autoFocus
            sx={{
              padding: '4px 8px',
              '.MuiInputBase-input': {
                fontSize: '14px'
              }
            }}
          />
        );
      }}
      renderOption={(props, option) => (
        <ListItem
          {...props}
          dense
        >
          <Typography
            variant="body2"
            noWrap
            component="span"
            title={option.title}
          >
            {option.title}
          </Typography>
        </ListItem>
      )}
      slotProps={{
        popper: {
          placement: 'bottom-start',
          sx: {
            width: '260px !important'
          }
        }
      }}
      onChange={handleChange}
    />
  );
};

EditEnumValueSingle.propTypes = {
  value: PT.shape({
    id: PT.string,
    title: PT.string
  }),
  options: PT.arrayOf(PT.shape({
    id: PT.string,
    title: PT.string
  })),
  onChange: PT.func
};

export default EditEnumValueSingle;
