/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LoadingBannerStyle from './styles';
import LogoMainMono from '../Logos/LogoMainMono';

import { isSidebarOpen } from '../../services/session/selectors';

const mapStateToProps = (state, props) => {
  const { fitScreen } = props;
  const data = {};

  if (fitScreen) {
    data.isSidebarOpen = isSidebarOpen(state);
  }

  return data;
};

const renderSpinner = () => (
  <div className="sk-circle">
    <div className="sk-circle1 sk-child"></div>
    <div className="sk-circle2 sk-child"></div>
    <div className="sk-circle3 sk-child"></div>
    <div className="sk-circle4 sk-child"></div>
    <div className="sk-circle5 sk-child"></div>
    <div className="sk-circle6 sk-child"></div>
    <div className="sk-circle7 sk-child"></div>
    <div className="sk-circle8 sk-child"></div>
    <div className="sk-circle9 sk-child"></div>
    <div className="sk-circle10 sk-child"></div>
    <div className="sk-circle11 sk-child"></div>
    <div className="sk-circle12 sk-child"></div>
  </div>
);

const LoadingBanner = ({
  spinner,
  height,
  color,
  bcgcolor,
  overlay,
  fitScreen,
  isSidebarOpen,
  opaque,
  centered
}) => (
  <LoadingBannerStyle
    overlay={overlay}
    height={height}
    spinner={spinner}
    bcgcolor={bcgcolor}
    color={color}
    fitscreen={fitScreen}
    sidebaropen={isSidebarOpen}
    opaque={opaque}
    centered={centered}
  >
    {spinner
      ? renderSpinner()
      : <LogoMainMono />
    }
  </LoadingBannerStyle>
);

LoadingBanner.propTypes = {
  spinner: PropTypes.bool,
  height: PropTypes.number,
  bcgcolor: PropTypes.string,
  color: PropTypes.string,
  overlay: PropTypes.bool,
  fitScreen: PropTypes.bool,
  isSidebarOpen: PropTypes.bool,
  opaque: PropTypes.bool,
  centered: PropTypes.bool
};

export default connect(mapStateToProps)(LoadingBanner);
