import React from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import SingleFormula from './SingleFormula';

import CalculationIcon from '../../../../../../../../../../../components/Icons/CalculationIcon';
import { COLOR_GREY_LIGHTER } from '../../../../../../../../../../../styles';

const FormulasList = ({ formulas, editor }) => {
  return (
    <Box
      mb="8px"
      flex="0 0 calc(50% - 4px)"
    >
      <Box
        sx={{
          display: 'flex',
          gap: '4px',
          alignItems: 'center',
          mb: '4px'
        }}
      >
        <CalculationIcon
          sx={{
            color: COLOR_GREY_LIGHTER,
            fontSize: '14px'
          }}
        />

        <Typography
          sx={{
            color: COLOR_GREY_LIGHTER,
            textTransform: 'uppercase',
            fontSize: '10px',
            fontWeight: '600',
          }}
        >
          functions
        </Typography>
      </Box>

      <Box
        sx={{
          maxHeight: '150px',
          overflowY: 'auto',
        }}
      >
        {formulas.map((formula) => (
          <SingleFormula
            key={formula.functionName}
            formula={formula}
            editor={editor}
          />
        ))}
      </Box>
    </Box>
  );
};

FormulasList.propTypes = {
  formulas: PT.array.isRequired,
  editor: PT.object.isRequired,
};

export default FormulasList;
