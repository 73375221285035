import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { COLOR_PRIMARY } from '../../../styles';

export const OrganizationNavStyled = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  text-decoration: none;
  color: #000;
  position: relative;
  
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  
  &.active {
    color: ${ ({ color }) => color || COLOR_PRIMARY };
    text-decoration: underline;
  }
  
  .avatar {
    margin-right: 16px;
  }
  
  .sb-avatar > div {
    border-radius: 5px;
  }
`;
