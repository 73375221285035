import styled, { css } from 'styled-components';
import ListItemComponent from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import { OPENED_NAV_WIDTH } from '../../../../styles';
import { Link as LinkStyled } from 'react-router-dom';

const transitionDuration = 0.4;

function injectCss({ mobile, open }) {
  if (mobile) {
    return css`
      height: 100%;
      position: absolute;
      top: 0;
      margin-left: ${open ? 0 : -OPENED_NAV_WIDTH}px;
      transition: margin-left ${transitionDuration}s;
      padding-bottom: 60px;
      box-sizing: border-box;
      width: ${OPENED_NAV_WIDTH}px;
      z-index: 12;
      background-color: white;
      overflow-x: hidden;
      -ms-overflow-style: none;  // IE 10+
      overflow: -moz-scrollbars-none;
    `;
  }

  return css`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  `;
}

export default styled.div`
  ${injectCss}
  z-index: 12;
`;


export const ListItem = styled(ListItemComponent).attrs({
  className: 'list-item'
})`
  width: auto;
  flex-grow: 0;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  
  .list-item-text {
    cursor: pointer;
  }
  
  &.active {
     border-bottom: 2px solid #ECD95A;
     color: #fff;
     flex-grow: 0;
     cursor: pointer;
  }
`;

export const Link = styled(LinkStyled)`
  color: inherit;
  text-decoration: none;
`;

export const SubMenuListItem = styled(MenuItem).attrs({
  className: 'sub-menu-item'
})`
  font-size: 16px;
  max-width: 300px;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  
  .menu-item-text > span {
    display: flex;
    justify-content: space-between;
    
     > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const SidebarListItem = styled(ListItemComponent).attrs({
  className: 'list-item'
})`
  flex-direction: column;
  color: rgba(0, 0, 0, 0.87);
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  
  > span {
    width: 100%
  }
    
  .list-item-button {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    height: 64px;
    align-items: center;
  }
  
  .list-item-text {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding-left: 24px;
    padding-right: 24px;
    height: 64px;
    display: flex;
    align-items: center;
  }
  
  .submenu-list-wrap {
    width: 100%;
    
    .submenu-list {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 30px;
      max-width: 100%;
      
      .list-item {
        max-width: 100%;
      
        .list-item-text,
        .list-item-button {
          height: 28px;
          margin-bottom: 8px;
          margin-top: 8px;
          
          
          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
`;
