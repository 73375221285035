import styled from 'styled-components';

export default styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li & {
    padding-left: 40px;
  }
`;
