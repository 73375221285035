import React, { useCallback, useEffect, useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import { useQuery } from '@apollo/client';

import useTable from '../../hooks/table';
import useColumns from '../../hooks/table/columns';

import TableContainer from './TableContainer';
import TablesTitle from './TablesTitle';

import { TABLES, FOLDERS } from './gql';

import mapFoldersAndTablesTree from '../../utils/mapFoldersAndTablesTree';
import useDocumentTitle from '../../hooks/document-title';
import defaultColumns from './columns';
import LoadingScreen from '../../components/LoadingScreen';

const featuresData = [];

const Tables = () => {
  useDocumentTitle('Table Settings');

  const { data: tablesRes, loading: loadingTables } = useQuery(TABLES, {
    fetchPolicy: 'cache-and-network'
  });
  const { data: foldersRes, loading: loadingFolders } = useQuery(FOLDERS, {
    fetchPolicy: 'cache-and-network'
  });

  const [collapsedRows, setCollapsedRows] = useState({});
  const [searchValue, setSearchValue] = useState('');

  const { folders } = foldersRes ?? {};
  const { tables } = tablesRes ?? {};

  const data = useMemo(() => {
    return mapFoldersAndTablesTree(folders, tables, searchValue);
  }, [folders, tables, searchValue]);

  const columns = useColumns({
    featuresData,
    defaultColumns
  });

  const handleCollapsedRows = useCallback(id => {
    setCollapsedRows(s => ({
      ...s,
      [id]: !(s[id] ?? true)
    }));
  }, []);

  useEffect(() => {
    if (folders) {
      setCollapsedRows(() => folders.reduce((acc, folder) => ({ ...acc, [folder.id]: true }), {}));
    }
  }, [folders]);

  useEffect(() => {
    if (searchValue) {
      setCollapsedRows(s => {
        return Object.keys(s).reduce((acc, id) => ({ ...acc, [id]: false }), {});
      });
    } else {
      setCollapsedRows(s => {
        return Object.keys(s).reduce((acc, id) => ({ ...acc, [id]: true }), {});
      });
    }
  }, [folders, searchValue]);

  const table = useTable({
    columns,
    data,
    customState: {
      collapsedRows: {
        byId: collapsedRows,
        toggle: handleCollapsedRows,
        isCollapsed: !searchValue
      }
    }
  });

  const containerStyles = {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    boxSizing: 'border-box',
    padding: '24px 24px 72px',
    borderLeft: '2px solid rgb(220, 219, 220)'
  };

  if (loadingTables || loadingFolders)
    return <LoadingScreen />;

  return (
    <Box sx={containerStyles}>
      <TablesTitle
        onSearchChange={setSearchValue}
        searchValue={searchValue}
      />

      <TableContainer
        table={table}
        selectedItems={{ state: {} }}
      />
    </Box>
  );
};

export default Tables;
