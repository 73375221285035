import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import Warning from '@mui/icons-material/Warning';

import WarningBlockStyled from './styles';

const WarningBlock = ({
  children,
  className,
  sx,
  icon = <Warning />
}) => (
  <Box sx={sx}>
    <WarningBlockStyled className={className}>
      {icon}

      <div>
        {children}
      </div>
    </WarningBlockStyled>
  </Box>
);

WarningBlock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  icon: PropTypes.element,
  className: PropTypes.string,
  sx: PropTypes.object
};

export default WarningBlock;
