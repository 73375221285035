import React, { memo, useCallback, useRef } from 'react';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';

import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import makeStyles from '@mui/styles/makeStyles';

import { ListItem, Link } from './styles';
import SubMenuItem from './SubMenuItem';

const useStyles = makeStyles({
  popOverRoot: {
    pointerEvents: 'none'
  }
});

const AppMenuItem = ({
  label,
  link,
  submenu
}) => {
  const location = useLocation();
  const styles = useStyles();

  const links = submenu?.flatMap(s =>
    s.submenu ? [s.link, ...s.submenu.map(i => i.link)] : s.link
  );

  const isActive = [
    links && links.some(i => i === location.pathname),
    link === location.pathname
  ].some(Boolean);

  const currentlyHovering = useRef(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event) => {
    if (!link) {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    }
  }, [link]);

  const handleHover = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback((event) => {
    event?.stopPropagation();
    setAnchorEl(null);
  }, []);

  const handleMenuHover = useCallback(() => {
    currentlyHovering.current = true;
  }, []);

  const handleCloseHover = useCallback(() => {
    currentlyHovering.current = false;
    setTimeout(() => {
      if (!currentlyHovering.current) {
        handleClose();
      }
    }, 50);
  }, [handleClose]);

  return (
    <ListItem
      active={`${isActive}`}
      className={`${isActive ? ' active' : ''}`}
      data-title={label}
      component={link ? Link : ListItemText}
      href={link}
      to={link}
      onClick={handleClick}
      onMouseEnter={handleHover}
      onMouseLeave={handleCloseHover}
      sx={{
        height: '100%',
      }}
    >
      {submenu ? (
        <>
          <ListItemText
            primary={label}
            className="list-item-text"
          />
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            MenuListProps={{
              onMouseEnter: handleMenuHover,
              onMouseLeave: handleClose,
              style: { pointerEvents: 'auto' }
            }}
            PopoverClasses={{
              root: styles.popOverRoot
            }}
          >
            {submenu.map((item) => (
              <SubMenuItem
                key={item.label + item.link}
                label={item.label}
                link={item.link}
                submenu={item.submenu}
                onClick={handleClose}
                parentOpen={open}
                onItemClick={item.onItemClick}
              />
            ))}
          </Menu>
        </>
      ) : (
        <ListItemText
          primary={label}
          className="list-item-text"
        />
      )}
    </ListItem>
  );
};

AppMenuItem.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
  submenu: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    link: PropTypes.string,
  })),
  onItemClick: PropTypes.func,
};

export default memo(AppMenuItem);
