import {
  CanvasTexture,
  SpriteMaterial,
  Sprite,
  LineBasicMaterial,
  BufferGeometry,
  BufferAttribute,
  LineSegments,
  Group,
  Color,
  PointsMaterial,
  DoubleSide,
  VertexColors,
  Points
} from 'three';

import {
  TICKS_COUNT_X,
  TICKS_COUNT_Y,
  TICKS_COUNT_Z,
  PLOT_COLORS
} from '../../services/graphics/constants';

const calcAxisLabel = value => {
  let num = Number.parseFloat(value);

  if(Number.isNaN(num)) return '0';

  if(num === 0) return '0';

  if(Math.abs(num) > 9999 || Math.abs(num) < .9999)
    return num.toExponential(2);

  return Number.parseFloat(num.toPrecision(4)).toString();
};

const createLabelTextSprite = (text, axis) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = 128;
  canvas.height = 64;

  const fontsize = 30;
  const fontface = 'sans-serif';
  const borderThickness = 0;
  const axesLabelsOptions = {
    x: { textAlign: 'center', textBaseline: 'top', xCoord: canvas.width / 2 },
    y: { textAlign: 'left', textBaseline: 'middle', xCoord: 0 },
    z: { textAlign: 'center', textBaseline: 'top', xCoord: canvas.width / 2 }
  };

  ctx.font = `${fontsize}px ${fontface}`;
  ctx.textAlign = axesLabelsOptions[axis].textAlign;
  ctx.textBaseline = axesLabelsOptions[axis].textBaseline;
  ctx.fillText(text, axesLabelsOptions[axis].xCoord, fontsize + borderThickness);

  const texture = new CanvasTexture(canvas);
  const spriteMaterial = new SpriteMaterial({ map: texture });
  const sprite = new Sprite(spriteMaterial);

  sprite.scale.set(50, 25, 1);

  return sprite;
};

const createGrid = ({
  height = 500,
  width = 500,
  linesIntervalVertical = 10,
  linesIntervalHorizontal = 10,
  color = 0xdd006c
} = {}) => {
  const material = new LineBasicMaterial({ color, opacity: .2 });
  const geometry = new BufferGeometry();
  const stepw = 2 * width / linesIntervalHorizontal;
  const steph = 2 * height / linesIntervalVertical;
  const verticesArr = [];

  for(let i = -width; i <= width; i += stepw) {
    verticesArr.push(-height, i, 0, height, i, 0);
  }

  for(let i = -height; i <= height; i += steph) {
    verticesArr.push(i, -width, 0, i, width, 0);
  }

  const vertices = new Float32Array(verticesArr);

  geometry.addAttribute('position', new BufferAttribute(vertices, 3));

  const axisGrid = new LineSegments(geometry, material);

  return axisGrid;
};

const computeLabelsForAxis = (labelsNum, { delta, min }) => {
  const step = delta / labelsNum;
  const labelsValues = [min];

  for (let i = 0; i !== labelsNum; i++) {
    const prev = labelsValues[labelsValues.length - 1];

    labelsValues.push(prev + step);
  }

  return { labelsValues, step };
};

const createLabel = ({ x, y, z }, axis, value) => {
  const labelText = calcAxisLabel(value);
  const label = createLabelTextSprite(labelText, axis);

  label.position.set(x, y, z);

  return label;
};

const initGrid = (GRAPH_DIMENSIONS, AXES_VALUES) => {
  const grid = new Group();
  const width = GRAPH_DIMENSIONS.W / 2;
  const depth = GRAPH_DIMENSIONS.D / 2;
  const height = GRAPH_DIMENSIONS.H / 2;

  // Set XY grid
  const xyGrid = createGrid({
    width,
    height,
    linesWidth: TICKS_COUNT_X,
    linesHeight: TICKS_COUNT_Y,
    color: 0xcccccc
    // color: 0xff0000 //red
  });

  xyGrid.position.z = -depth;

  // set XZ grid
  const xzGrid = createGrid({
    width,
    height: depth,
    linesWidth: TICKS_COUNT_X,
    linesHeight: TICKS_COUNT_Z,
    color: 0xcccccc
    // color: 0x00ff00 // green
  });

  xzGrid.rotation.x = Math.PI / 2;
  xzGrid.position.y = -height;

  // Set YZ grid
  const yzGrid = createGrid({
    width: depth,
    height: height,
    linesWidth: TICKS_COUNT_Z,
    linesHeight: TICKS_COUNT_Y,
    color: 0xcccccc
    // color: 0x0000ff // blue
  });

  yzGrid.rotation.y = Math.PI / 2;
  yzGrid.position.x = -width;

  // Set grid labels
  const xLabelsData = computeLabelsForAxis(TICKS_COUNT_X, AXES_VALUES[0]);
  const xLabels = xLabelsData.labelsValues.map((value, index) => createLabel({
    x: -width + index * (2 * width / TICKS_COUNT_X),
    y: -height - 15,
    z: depth
  }, 'x', value));

  const yLabelsData = computeLabelsForAxis(TICKS_COUNT_Y, AXES_VALUES[1]);
  const yLabels = yLabelsData.labelsValues.map((value, index) => createLabel({
    x: -width - 30,
    y: -height + index * (2 * height / TICKS_COUNT_Y),
    z: depth
  }, 'y', value));

  const zLabelsData = computeLabelsForAxis(TICKS_COUNT_Y, AXES_VALUES[2]);
  const zLabels = zLabelsData.labelsValues.map((value, index) => createLabel({
    x: -width - 30,
    y: -height,
    z: -depth + index * (2 * depth / TICKS_COUNT_Z)
  }, 'z', value));

  grid.add(xyGrid);
  grid.add(xzGrid);
  grid.add(yzGrid);

  [
    ...xLabels,
    ...yLabels,
    ...zLabels
  ].forEach(label => grid.add(label));

  return grid;
};

const computeVertexColor = (value, min, max) => {
  let _value, colors, delta;

  if(value >= 0) {
    colors = PLOT_COLORS.positive;
    delta = min > 0 ? (max - min) : max;
    _value = min > 0 ? (value - min) : value;
  } else {
    colors = PLOT_COLORS.negative;
    delta = max < 0 ? (Math.abs(min) - Math.abs(max)) : Math.abs(min);
    _value = max < 0 ? (Math.abs(value) - Math.abs(max)) : Math.abs(value);
  }

  const _colorsInterval = delta / colors.length;
  const colorHEX = _value === 0 ? colors[0] : colors[Math.ceil(_value / _colorsInterval) - 1];
  const { r, g, b } = new Color(colorHEX);

  return [r, g, b];
};

const initPlot = (worldData, AXES_VALUES) => {
  const geometry = new BufferGeometry();
  const material = new PointsMaterial({
    side: DoubleSide,
    vertexColors: VertexColors,
    size: 100.0
  });
  const vertices = [];
  const colors = [];
  const { min, max } = AXES_VALUES[1];

  for(let i = worldData.length; i--;) {
    vertices.push(...worldData[i].worldValue);
    colors.push(...computeVertexColor(worldData[i].originalValue[1], min, max));
  }

  geometry.addAttribute('position', new BufferAttribute(new Float32Array(vertices), 3));
  geometry.addAttribute('color', new BufferAttribute(new Float32Array(colors), 3));

  return new Points(geometry, material);
};

const initChart = (GRAPH_DIMENSIONS, worldData, AXES_VALUES) => {
  const chartGroup = new Group();
  const grid = initGrid(GRAPH_DIMENSIONS, AXES_VALUES);
  const plot = initPlot(worldData, AXES_VALUES);

  chartGroup.add(grid);
  chartGroup.add(plot);

  return chartGroup;
};

export default initChart;
export {
  initGrid,
  initPlot
};
