import React from 'react';
import PT from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const ConfirmationDialog = ({ open, onSubmit, onClose }) => {
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      onClose={onClose}
    >
      <DialogTitle>
        Changes made to the table structure
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Please note that modifying the table structure may impact existing data.
          All data corresponding to parameters that were removed will be deleted.
        </DialogContentText>
      </DialogContent>

      <DialogActions
        sx={{
          p: '16px'
        }}
      >
        <Button
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PT.bool,
  onSubmit: PT.func,
  onClose: PT.func
};

export default ConfirmationDialog;
