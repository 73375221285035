import styled from 'styled-components';

import Dialog from '@mui/material/Dialog';

const AuthSettingsContainer = styled.div`
  > *:not(:last-child) {
    margin-bottom: 18px;
  }

  .switch-helper-text {
    margin-top: 0px;
    line-height: 1.5em;
    letter-spacing: 0.025em;
  }
`;

export const AuthProviderContainer = styled.div`
  .auth-provider {
    display: flex;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 12px;
    }
  }

  img.auth-provider-icon {
    height: 24px;
  }

  .auth-provider-name {
    min-width: 100px;
  }

  .provider-actions {
    margin-top: 8px;

    > button:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

export const LinkEmailProviderModalContainer = styled(Dialog)`
  .form-control {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  .password-helper-text {
    line-height: 1.5em;
    margin-left: 0;
  }
`;

export const ChangeEmailModalContainer = styled(Dialog)`
  .form-control {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

export const ChangePasswordModalContainer = styled(Dialog)`
  .form-control {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  .new-password-helper-text {
    line-height: 1.5em;
    margin-left: 0;
  }
`;

export default AuthSettingsContainer;
