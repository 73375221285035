import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  WebGLRenderer,
  Scene,
  PerspectiveCamera
} from 'three';
// import OrbitControls from 'three-orbitcontrols';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import _unzip from 'lodash/unzip';
import _max from 'lodash/max';
import _min from 'lodash/min';
import ResizeObserverPolyfill from 'resize-observer-polyfill';

import {
  PLAYGROUND_SIZE,
  CAMERA_FOV,
  CAMERA_NEAR,
  CAMERA_FAR,
  GRAPH_DIMENSIONS
} from '../../../services/graphics/constants';
import initChart from '../../../utils/graphics/surface-chart';

const canvasStyles = {
  height: '100%',
  width: '100%',
  maxWidth: '100%',
  minWidth: '100%'
};

const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

class GraphicsSurfaceChart extends Component {
  constructor(props) {
    super(props);

    this._ref = React.createRef();
  }

  animateChart = () => {
    this.renderer.render(this.scene, this.camera);
    this.controls.update();

    this.frameId = window.requestAnimationFrame(this.animateChart);
  };

  componentDidMount() {
    const canvas = this._ref.current;

    this.ro = new ResizeObserver(this.handleResize);

    this.renderGraph(canvas);
    this.ro.observe(canvas);
  }

  componentDidUpdate() {
    this.renderer.clear();
    this.renderGraph(this._ref.current);
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.frameId);
    this.renderer.clear();
    this.ro.disconnect();
  }

  handleResize = entries => {
    const { width, height } = entries[0].contentRect;

    this.renderer.setSize(width, height);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  };

  renderGraph(canvas) {
    const { offsetWidth, offsetHeight } = canvas;
    const CAMERA_ASPECT_RATIO = offsetWidth / offsetHeight;
    let { data } = this.props;
    let titles = data.slice(0, 1);
    let dataCoords = _unzip(data.slice(1));

    const computeAxesValues = (axisValues, index) => {
      const max = _max(axisValues);
      const min = _min(axisValues);
      const delta = max - min;

      return { max, min, delta, axis: titles[index] };
    };
    const AXES_VALUES = dataCoords.map(computeAxesValues);
    const mapWorldData = point => {
      const [xValues, yValues, zValues] = AXES_VALUES;
      const [x, y, z] = point;

      return {
        originalValue: [x, y, z],
        worldValue: [
          (x - xValues.min) / xValues.delta * GRAPH_DIMENSIONS.W - GRAPH_DIMENSIONS.W / 2,
          (y - yValues.min) / yValues.delta * GRAPH_DIMENSIONS.H - GRAPH_DIMENSIONS.H / 2,
          (z - zValues.min) / zValues.delta * GRAPH_DIMENSIONS.D - GRAPH_DIMENSIONS.D / 2
        ]
      };
    };
    const worldData = data.slice(1).map(mapWorldData);
    const chart = initChart(GRAPH_DIMENSIONS, worldData, AXES_VALUES);

    this.scene = new Scene();
    this.camera = new PerspectiveCamera(CAMERA_FOV, CAMERA_ASPECT_RATIO, CAMERA_NEAR, CAMERA_FAR);
    this.controls = new OrbitControls(this.camera, canvas);

    this.scene.add(chart);
    this.camera.position.set(0, PLAYGROUND_SIZE / 4, 2 * PLAYGROUND_SIZE);
    this.controls.maxDistance = PLAYGROUND_SIZE * 8;

    if(!this.renderer)
      this.renderer = new WebGLRenderer({ canvas });

    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(offsetWidth, offsetHeight);
    this.renderer.setClearColor(0xffffff);

    // Render chart
    if(typeof this.frameId === 'undefined')
      this.frameId = window.requestAnimationFrame(this.animateChart);
  }

  render() {
    return (
      <canvas style={canvasStyles} ref={this._ref} />
    );
  }
}

GraphicsSurfaceChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
};

export default GraphicsSurfaceChart;
