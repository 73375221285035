import React from 'react';
import { Link, generatePath } from 'react-router-dom';
import urlSlug from 'url-slug';

import { PageContent } from '../../../components/Page';
import PageStyled from './styles';
import { routes } from '../../../services/session/constants';

const PEROVSKITE_DB_PROJECT = 'Perovskite Database Project';
const GENERAL_DEVELOPMENT_DASHBOARD = 'General Development';
const RECORD_EVOLUTION_DASHBOARD = 'Record Evolution';
const BANDGAP_ANALYSIS_DASHBOARD = 'Bandgap Analysis';
const SCALING_DASHBOARD = 'Scaling';
const MODULES_DASHBOARD = 'Modules';
const STABILITY_DASHBOARD = 'Stability';
const OUTDOOR_TESTING_DASHBOARD = 'Outdoor Testing';
const DOWNLOAD_DATA_DASHBOARD = 'Download Data';
const UPLOAD_DATA_DASHBOARD = 'Upload Data';

const PerovskiteLanding = () => {
  return (
    <PageStyled>
      <PageContent>
        <h2 className="page-title">
          Welcome to Materials Zone!
        </h2>

        <p>
          You are now logged in to the free section that pertains to the Perovskite Database (
          <a
            className="primary-color"
            href="https://www.perovskitedatabase.com/"
            target="_blank"
            rel="noreferrer"
          >
            details here
          </a>
          ).
          <br />


          For premium features and more data insights please&nbsp;
          <a
            className="primary-color"
            href="https://www.materials.zone/get-started-button"
            target="_blank"
            rel="noreferrer"
          >
            register here to meet sales
          </a>.
        </p>

        Here are some of the dashboards accessible to you with your free subscription:

        <div className="grid-container">
          <p>
            <Link
              to={generatePath(routes.APP, {
                folder: urlSlug(PEROVSKITE_DB_PROJECT),
                title: urlSlug(GENERAL_DEVELOPMENT_DASHBOARD)
              })}
            >
              <b> General development </b>
            </Link>

            <br />

            Focuses on the development of general device performance and enables filtering within the entire dataset.
          </p>

          <p>
            <Link
              to={generatePath(routes.APP, {
                folder: urlSlug(PEROVSKITE_DB_PROJECT),
                title: urlSlug(RECORD_EVOLUTION_DASHBOARD)
              })}
            >
              <b> Record evolution </b>
            </Link>

            <br />

            Sorts out the record cells given any number of specified constraints, e.g. for transparent cells, or inverted cells, or cells based on CsPbI3, or cells fulfilling any combination of constraints.
          </p>

          <p>
            <Link
              to={generatePath(routes.APP, {
                folder: urlSlug(PEROVSKITE_DB_PROJECT),
                title: urlSlug(BANDGAP_ANALYSIS_DASHBOARD)
              })}
            >
              <b> Band gap analysis </b>
            </Link>

            <br />

            Focusing on comparing device metrics as a function of perovskite band gap.
          </p>

          <p>
            <Link
              to={generatePath(routes.APP, {
                folder: urlSlug(PEROVSKITE_DB_PROJECT),
                title: urlSlug(SCALING_DASHBOARD)
              })}
            >
              <b>Scaling</b>
            </Link>

            <br />

            Specifically focusing on illustrating the development in device scaling.
          </p>

          <p>
            <Link
              to={generatePath(routes.APP, {
                folder: urlSlug(PEROVSKITE_DB_PROJECT),
                title: urlSlug(MODULES_DASHBOARD)
              })}
            >
              <b>Modules</b>
            </Link>

            <br />

            Focusing on perovskite modules.
          </p>

          <p>
            <Link
              to={generatePath(routes.APP, {
                folder: urlSlug(PEROVSKITE_DB_PROJECT),
                title: urlSlug(STABILITY_DASHBOARD)
              })}
            >
              <b>Stability</b>
            </Link>

            <br />

            Enables filtering and visualization of all devices with reported stability data.
          </p>

          <p>
            <Link
              to={generatePath(routes.APP, {
                folder: urlSlug(PEROVSKITE_DB_PROJECT),
                title: urlSlug(OUTDOOR_TESTING_DASHBOARD)
              })}
            >
              <b> Outdoor testing </b>
            </Link>

            <br />

            Enables filtering and visualization of all devices with data from outdoor measurements. This is still a rather limited dataset, but we expect more of this in the future.
          </p>

          <p>
            <Link
              to={generatePath(routes.APP, {
                folder: urlSlug(PEROVSKITE_DB_PROJECT),
                title: urlSlug(DOWNLOAD_DATA_DASHBOARD)
              })}
            >
              <b> Downloading data </b>
            </Link>

            <br />

            Enables downloading the entire dataset or any possible subset thereof.
          </p>

          <p>
            <Link
              to={generatePath(routes.APP, {
                folder: urlSlug(PEROVSKITE_DB_PROJECT),
                title: urlSlug(UPLOAD_DATA_DASHBOARD)
              })}
            >
              <b> Uploading data </b>
            </Link>

            <br />

            For uploading new data to the database.
          </p>
        </div>

        <br />

        For more information on the project, visit the&nbsp;
        <a
          className="primary-color"
          href="http://www.perovskitedatabase.com"
          target="_blank"
          rel="noreferrer"
        >
          project’s homepage
        </a>.
      </PageContent>
    </PageStyled>
  );
};

export default PerovskiteLanding;
