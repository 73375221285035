import React from 'react';
import { useQuery } from '@apollo/client';

import { Outlet, useLocation, Navigate } from 'react-router-dom';

import { routes } from '../../../services/session/constants';

import VIEWER_ID from './gql/viewer-id';

const ViewerRecordExist = () => {
  const location = useLocation();
  const { data, loading } = useQuery(VIEWER_ID);

  if(loading && !data)
    return null; // TODO: find a better intermediate screen state

  if(!data?.viewer)
    return (
      <Navigate
        to={routes.REGISTRATION_DETAILS}
        replace
        state={{ from: location.pathname }}
      />
    );

  return (
    <Outlet />
  );
};

export default ViewerRecordExist;
