import React, { useState, useCallback } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Tooltip from '@mui/material/Tooltip';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const OptionContent = ({ className, value, onCopy }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyCode = useCallback(ev => {
    // Prevent the option selection
    // when the 'Copy code' is clicked
    ev.preventDefault();
    ev.stopPropagation();

    onCopy();
    copy(value.code);
    setCopied(true);
  }, [value, onCopy]);

  const handleCloseTooltip = useCallback(() => {
    setCopied(false);
  }, []);

  if(!value)
    return null;

  const tooltipText = copied ? 'Copied' : 'Copy code to clipboard';

  return (
    <Box className={className}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography
          variant="body2"
        >
          {value.measurement.name}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '7px'
          }}
        >
          <Typography
            fontSize="12px"
            color="rgba(0,0,0,.6)"
          >
            #{value.code}
          </Typography>

          {onCopy ?
            <Tooltip
              title={tooltipText}
              placement="top"
              onClose={handleCloseTooltip}
              disableFocusListener
            >
              <ButtonBase onClick={handleCopyCode}>
                <ContentCopyIcon fontSize="12px" />
              </ButtonBase>
            </Tooltip> :
            null
          }
        </Box>
      </Box>

      <Typography
        fontSize="12px"
        lineHeight="16px"
        color="rgba(0,0,0,.6)"
      >
        {value.instrument.manufacturer.name}
        {' '}
        {value.instrument.model}
        {' | '}
        {value.parser.name}
      </Typography>
    </Box>
  );
};

OptionContent.propTypes = {
  className: PT.string,
  value: PT.object,
  onCopy: PT.func
};

export default styled(OptionContent)`
  flex-grow: 1;
`;
