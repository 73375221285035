import React, {
  useCallback
} from 'react';

import { SlateEditable } from './styles';

import renderElement from './renderElement';

function Editable({
  ...props
}) {
  const RenderElement = useCallback(renderElement, []);

  return (
    <SlateEditable
      renderElement={RenderElement}
      {...props}
    />
  );
}

export default Editable;
