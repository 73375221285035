const createInitialOptions = data => {
  const itemInfo = {
    title: 'Item info',
    children: []
  };

  let parameters = [];

  Object.keys(data).forEach((key) => {
    switch (key) {
      case 'creators': {
        itemInfo.children.push({
          ...data[key],
          title: 'Owner',
          id: 'creatorIds'
        });
        break;
      }
      case 'createdTimestamp': {
        itemInfo.children.push({
          ...data[key],
          title: 'Creation Date',
          id: 'createdTimestamp'
        });
        break;
      }
      case 'parameters': {
        parameters = data[key]?.map(protocol => ({
          title: protocol.title,
          children: protocol.features
        })) || [];
        break;
      }
      default:
        return;
    }
  });

  return [
    itemInfo,
    ...parameters
  ];
};

export default createInitialOptions;
