import styled from 'styled-components';

import Button from '@mui/material/Button';
import { COLOR_PRIMARY_CONTRAST, COLOR_PRIMARY_DARK } from '../../../../../styles';

export const ButtonStyled = styled(Button)`
  text-transform: none;
  border: ${({ active }) => active ? `1px solid ${COLOR_PRIMARY_DARK}` : 'none'};
  border-radius: 4px;
  color: ${({ active }) => active ? COLOR_PRIMARY_DARK : 'initial'};
  background-color: ${({ active }) => active ? COLOR_PRIMARY_CONTRAST : 'initial'};
  height: 24px;
  max-width: 100%;
`;
