import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import ReactCrop, {  makeAspectCrop }  from 'react-image-crop';

import { DIALOG_BREAKPOINT } from '../../styles';

const initCrop = {
  unit: '%',
  width: 50,
  aspect: 1
};

function AvatarPictureSelectDialog({
  onSubmit,
  onClose,
  filename,
  pictureSrc
}) {
  const [imgEl, setImgEl] = useState();
  const [crop, setCrop] = useState(initCrop);

  const imgRef = useRef();

  const isMobileView = useMediaQuery(`(${DIALOG_BREAKPOINT})`);

  const handleImageLoaded = useCallback(() => {
    const { width, height } = imgRef.current;
    setImgEl(imgRef.current);
    setCrop(
      makeAspectCrop(
        {
          unit: 'px',
          width: width / 2,
        },
        1,
        width,
        height,
      ),
    );
  }, []);

  const handleCropChange = useCallback(c => {
    setCrop(c);
  }, []);

  const handleSubmit = useCallback(() => {
    const canvas = document.createElement('canvas');
    const scaleX = imgEl.naturalWidth / imgEl.width;
    const scaleY = imgEl.naturalHeight / imgEl.height;

    canvas.width = crop.width;
    canvas.height = crop.height;

    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      imgEl,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob(blob => {
      blob.name = filename;

      onSubmit(blob);
    }, 'image/png');
  }, [imgEl, filename, crop, onSubmit]);

  const isCroped = crop.width && crop.height;

  return (
    <Dialog
      open
      onClose={onClose}
      fullScreen={isMobileView}
      aria-labelledby="crop-image-dialog-title"
      TransitionProps={{ onExit: onClose }}
    >
      <DialogTitle id="crop-image-dialog-title">
        Crop image
      </DialogTitle>

      <DialogContent>
        <ReactCrop
          minWidth={36}
          minHeight={36}
          crop={crop}
          onChange={handleCropChange}
          aspect={1}
        >
          <img
            ref={imgRef}
            src={pictureSrc}
            onLoad={handleImageLoaded}
            alt="Crop"
          />
        </ReactCrop>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
        >
          Cancel
        </Button>

        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={!isCroped}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AvatarPictureSelectDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  filename: PropTypes.string.isRequired,
  pictureSrc: PropTypes.string.isRequired,
};

export default AvatarPictureSelectDialog;
