import React from 'react';
import PropTypes from 'prop-types';
import zip from 'lodash/zip';
import min from 'lodash/min';
import max from 'lodash/max';
import {
  deviation,
  mean
} from 'd3-array';

import ChartContainer from './ChartContainer';

import mapValidData from '../../../utils/graphics/validate-columns';
import getUnitsFromColumnTitle from '../../../utils/graphics/get-units-from-column-title';
import formatChartSummaryValues from '../../../utils/graphics/format-chart-summary-values';

const palette = [
  '#0000a4',
  '#0000f6',
  '#0034ff',
  '#007dff',
  '#00c8ff',
  '#23ffd4',
  '#60ff97',
  '#9aff5d',
  '#d4ff23',
  '#ffd600',
  '#ff9400',
  '#ff4e00',
  '#f60b00',
  '#9f0001'
];

const computeSummary = data => ({
  avg: mean(data),
  max: max(data),
  std: deviation(data)
});

const computeAxisValue = offsetIndex =>
  typeof offsetIndex !== 'number' || Number.isNaN(offsetIndex) ?
    (row, index) => row[index] :
    (row, index) => row[index] + row[offsetIndex];

class VectorWaferMap extends React.Component {
  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate(prevProps) {
    const {
      xIndex,
      yIndex,
      xOffsetIndex,
      yOffsetIndex,
      x1Index,
      y1Index
    } = this.props;

    const conditions = [
      xIndex !== prevProps.xIndex,
      yIndex !== prevProps.yIndex,
      xOffsetIndex !== prevProps.xOffsetIndex,
      yOffsetIndex !== prevProps.yOffsetIndex,
      x1Index !== prevProps.x1Index,
      y1Index !== prevProps.y1Index
    ];

    if(conditions.some(Boolean)) {
      this.chartContainerRef.innerHTML = '';

      this.renderChart();
    }
  }

  renderChart() {
    const {
      preview,
      xIndex,
      yIndex,
      xOffsetIndex,
      yOffsetIndex,
      x1Index,
      y1Index
    } = this.props;

    const Bokeh = window.Bokeh;

    const plot_width = preview ? 150 : void 0;
    const plot_height = preview ? 150 : 400;
    const match_aspect = preview ? void 0 : true;
    const sizing_mode = preview ? void 0 : 'stretch_both';
    const toolbar_location = preview ? null : 'right';
    const tools = preview ? void 0 : [
      new Bokeh.PanTool(),
      new Bokeh.BoxZoomTool(),
      new Bokeh.SaveTool(),
      new Bokeh.ResetTool()
    ];

    const titles = this.props.data[0];
    const data = mapValidData(titles, this.props.data.slice(1));

    const computeXAxis = computeAxisValue(xOffsetIndex);
    const computeYAxis = computeAxisValue(yOffsetIndex);

    const [x, y, mag, x1, y1, arrowAngle, ovx, ovy] = zip(...data.map(row => {
      const x = computeXAxis(row, xIndex);
      const y = computeYAxis(row, yIndex);
      const ovx = row[x1Index];
      const ovy = row[y1Index];

      const x1 = x + ovx;
      const y1 = y + ovy;

      const mag = Math.sqrt(Math.pow(ovx, 2) + Math.pow(ovy, 2));
      const angle = Math.PI / 2 - Math.atan2(ovx / mag, ovy / mag);
      const arrowAngle = 3 * Math.PI / 2 + angle;

      return [x, y, mag, x1, y1, arrowAngle, ovx, ovy];
    }));

    let source = new Bokeh.ColumnDataSource({ data: { x, y, mag, x1, y1, arrowAngle } });

    const plot = new Bokeh.Plotting.figure({
      // plot sizing config
      width: plot_width,
      height: plot_height,
      match_aspect,
      sizing_mode,

      // toolbar config
      toolbar_location,
      tools
    });

    const color = new Bokeh.LinearColorMapper({
      palette,
      high: max(mag),
      low: min(mag),
      name: 'mag'
    });

    const segment = new Bokeh.Segment({
      x0: { field: 'x' },
      y0: { field: 'y' },
      x1: { field: 'x1' },
      y1: { field: 'y1' },
      line_color: {
        field: 'mag',
        transform: color
      },
      line_alpha: .6
    });

    plot.add_glyph(segment, source);

    let columns;

    if(preview) {
      plot.xaxis[0].visible = false;
      plot.yaxis[0].visible = false;

      columns = plot;
    } else {
      plot.toolbar.logo = null;

      plot.xaxis[0].axis_label = titles[xIndex];
      plot.yaxis[0].axis_label = titles[yIndex];

      const color_bar = new Bokeh.ColorBar({
        color_mapper: color,
        location: [0, 0],
        ticker: new Bokeh.BasicTicker({ desired_num_ticks: palette.length })
      });

      const triangle = new Bokeh.Scatter({
        marker: 'triangle',
        angle: { field: 'arrowAngle' },
        x: { field: 'x1' },
        y: { field: 'y1' },
        size: 4,
        line_width: 0,
        fill_color: {
          field: 'mag',
          transform: color
        },
        fill_alpha: .6
      });

      plot.add_glyph(triangle, source);
      plot.add_layout(color_bar, 'right');

      const OVX = computeSummary(ovx);
      const OVY = computeSummary(ovy);

      const xAxisUnits = getUnitsFromColumnTitle(titles[xIndex]);
      const yAxisUnits = getUnitsFromColumnTitle(titles[yIndex]);

      const info = new Bokeh.Row({
        children: [
          new Bokeh.Widgets.PreText({
            text: [
              `OVX: AVG=${formatChartSummaryValues(OVX.avg, xAxisUnits)}, MAX=${formatChartSummaryValues(OVX.max, xAxisUnits)}, STD=${formatChartSummaryValues(OVX.std, xAxisUnits)}`,
              '\n',
              `OVY: AVG=${formatChartSummaryValues(OVY.avg, yAxisUnits)}, MAX=${formatChartSummaryValues(OVY.max, yAxisUnits)}, STD=${formatChartSummaryValues(OVY.std, yAxisUnits)}`
            ].join('')
          })
        ],
        height: 48,
        align: 'center'
      });

      columns = new Bokeh.Column({
        children: [
          plot,
          info
        ],
        sizing_mode: 'stretch_width'
      });
    }

    Bokeh.Plotting.show(columns, this.chartContainerRef);
  }

  render() {
    return (
      <ChartContainer
        ref={ref => { this.chartContainerRef = ref; }}
        className={this.props.preview && 'preview'}
      />
    );
  }
}

VectorWaferMap.propTypes = {
  data: PropTypes.array.isRequired,
  preview: PropTypes.bool,
  xIndex: PropTypes.number,
  yIndex: PropTypes.number,
  xOffsetIndex: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  yOffsetIndex: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  x1Index: PropTypes.number,
  y1Index: PropTypes.number
};

VectorWaferMap.defaultProps = {
  xIndex: 0,
  yIndex: 1,
  xOffsetIndex: 5,
  yOffsetIndex: 6,
  x1Index: 15,
  y1Index: 16
};

export default VectorWaferMap;
