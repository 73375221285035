import { gql } from '@apollo/client/core';

export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      name
    }
  }
`;

export const GET_INVITE = gql`
  query Invite {
    invite {
      id
    }
  }
`;
