import React from 'react';
import PT from 'prop-types';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import {
  RESTRICT_PERMISSION,
  RESTRICT_LINKED,
  RESTRICT_NOT_ARCHIVED
} from '../../constants';

function warningContent(strings, itemName, reason) {
  let str1 = strings[1];

  switch(reason) {
    case RESTRICT_PERMISSION:
      str1 += 'because you don’t have the necessary permissions';
      break;

    case RESTRICT_LINKED:
      str1 += 'because there are other items linked to it';
      break;

    case RESTRICT_NOT_ARCHIVED:
      str1 += 'because the item itself is not archived, only some of their protocols and measurements are archived';
      break;

    default:
      str1 = '';
  }

  return `"${itemName}" ${str1}`;
}

const WarningContent = ({
  itemName,
  reason,
  onClose
}) => (
  <>
    <DialogTitle>Cannot delete the item</DialogTitle>

    <DialogContent>
      <DialogContentText>
        {warningContent`${itemName} cannot be deleted ${reason}`}
      </DialogContentText>
    </DialogContent>

    <DialogActions sx={{ p: 2 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={onClose}
      >
        ok
      </Button>
    </DialogActions>
  </>
);

WarningContent.propTypes = {
  itemName: PT.string.isRequired,
  reason: PT.string.isRequired,
  onClose: PT.func.isRequired
};

export default WarningContent;
