import React from 'react';
import PropTypes from 'prop-types';

import RawText from '../RawText';

import searchSplit from '../../utils/searchSplit';

const ItemCode = ({ code, search }) => {

  if (!search || !code.toLowerCase().includes(search.toLowerCase()))
    return (
      <RawText>
        {code}
      </RawText>
    );

  const chunks = searchSplit(code, search);

  return (
    <>
      <RawText>
        {chunks.map((chunk) => {
          if (chunk.search){
            return (
              <mark key={chunk.id}>
                {chunk.text}
              </mark>
            );
          }

          return (
            <React.Fragment key={chunk.id}>
              {chunk.text}
            </React.Fragment>
          );
        })}
      </RawText>
    </>
  );
};

ItemCode.propTypes = {
  code: PropTypes.string.isRequired,
  search: PropTypes.string,
};

export default ItemCode;
