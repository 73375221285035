import React from 'react';
import PT from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';

const RemoveUserDialog = ({ onClose, onSubmit, subjectName, tableName }) => (
  <Dialog
    open
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
  >
    <DialogTitle
      id="alert-dialog-title"
      sx={{
        fontWeight: 'bold',
        lineHeight: 1,
        paddingBottom: '16px'
      }}
    >
      Confirm Remove
    </DialogTitle>

    <DialogContent>
      <DialogContentText sx={{ fontSize: 14, lineHeight: 1 }}>
        {`Are you sure you want to remove ${subjectName} from ${tableName}?`}
      </DialogContentText>
    </DialogContent>

    <DialogActions sx={{ pr: '24px', pb: '16px' }}>
      <Button
        sx={{
          textTransform: 'capitalize',
          color: '#666666'
        }}
        onClick={onClose}
      >
        cancel
      </Button>

      <Button
        autoFocus
        sx={{ textTransform: 'capitalize' }}
        onClick={onSubmit}
        variant="contained"
      >
        remove
      </Button>
    </DialogActions>
  </Dialog>
);

RemoveUserDialog.propTypes = {
  onClose: PT.func.isRequired,
  onSubmit: PT.func.isRequired,
  subjectName: PT.string.isRequired,
  tableName: PT.string.isRequired,
};

export default RemoveUserDialog;
