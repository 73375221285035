import React from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import DeleteIcon from '@mui/icons-material/Delete';

import { EditableBox, EditIconStyled } from '../SingleFeature/styles';
import QuantityValue from '../SingleFeature/FeatureValue/QuantityValue';
import QuantityEditValue from '../SingleFeature/EditableValue/QuantityEditValue';

const IngredientValue = ({
  edit,
  readOnly,
  value,
  unit,
  disableSubmit,
  loading,
  onEdit,
  onCancel,
  onChange,
  onSubmit,
  onDelete
}) => {
  if(edit)
    return (
      <QuantityEditValue
        value={value ?? ''}
        name={unit?.name}
        disableSubmit={disableSubmit}
        loading={loading}
        onCancel={onCancel}
        onChange={onChange}
        onSubmit={onSubmit}
      />
    );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        '.delete-button': {
          display: 'none'
        },
        '&:hover .delete-button': {
          display: 'inline-flex'
        }
      }}
    >
      <EditableBox
        readOnly={readOnly}
        onClick={readOnly ? null : onEdit}
      >
        <QuantityValue
          value={value}
          unit={unit}
        />

        <EditIconStyled />
      </EditableBox>

      {readOnly ?
        null :
        <IconButton
          size="small"
          className="delete-button"
          onClick={onDelete}
        >
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      }
    </Box>
  );
};

IngredientValue.propTypes = {
  edit: PT.bool,
  readOnly: PT.bool,
  value: PT.number,
  unit: PT.shape({
    name: PT.string
  }),
  disableSubmit: PT.bool,
  loading: PT.bool,
  onEdit: PT.func,
  onCancel: PT.func,
  onChange: PT.func,
  onSubmit: PT.func,
  onDelete: PT.func
};

export default IngredientValue;
