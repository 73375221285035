import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';

import LoadingBanner from '../../../components/LoadingBanner';

import { recoverEmail, sendPasswordReset } from '../../../services';

import { COLOR_PRIMARY, COLOR_WHITE } from '../../../styles';

const ResetEmail = ({ oobCode }) => {

  const [changePassword, setChangePassword] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleRecoverEmail = useCallback(async() => {
    setLoading(true);

    const email = await recoverEmail(oobCode);

    if (!email){
      setError(true);
    } else {
      setEmail(email);
    }

    setLoading(false);
  }, [oobCode]);

  useEffect(() => {
    handleRecoverEmail();
  }, [handleRecoverEmail]);

  const handleResetPassword = useCallback(async () => {
    const resp = await sendPasswordReset(email);

    if (!resp){
      setError(true);
    } else {
      setChangePassword(true);
    }
  }, [email, setError]);

  if (loading)
    return (
      <LoadingBanner
        spinner
        centered
        color={COLOR_PRIMARY}
        bcgcolor={COLOR_WHITE}
      />);

  if (error && changePassword)
    return (
      <CardContent>
        <Typography
          variant="h6"
          gutterBottom
        >
          Unable to reset password
        </Typography>
        <Typography>
          Please try again through our platform
        </Typography>
      </CardContent>
    );

  if (error)
    return (
      <CardContent>
        <Typography
          variant="h6"
          gutterBottom
        >
          Unable to update your email address
        </Typography>
        <Typography
          gutterBottom
        >
          There was a problem changing your email back
        </Typography>
        <Typography>
          If you try again and still can't reset your email,
          try asking your administrator for help
        </Typography>
      </CardContent>
    );

  if (changePassword)
    return (
      <CardContent>
        <Typography
          variant="h6"
          gutterBottom
        >
          Check your email
        </Typography>
        <Typography>
          Follow the instructions to recover your password
        </Typography>
      </CardContent>
    );

  return (
    <CardContent>
      <Typography
        variant="h6"
        gutterBottom
      >
        Updated email address
      </Typography>

      <Typography
        gutterBottom
      >
        Your email was changed
      </Typography>

      <Typography>
        If you didn't change your email it is possible someone is trying to access you account and you should
      </Typography>
      <Typography>
        <Link
          onClick={handleResetPassword}
          color="primary"
        >
          change your password right away
        </Link>
      </Typography>
    </CardContent>
  );
};

ResetEmail.propTypes = {
  oobCode: PropTypes.string
};

export default ResetEmail;
