import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';

import Button from '@mui/material/Button';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import Tooltip from '@mui/material/Tooltip';

const getIconTag = icon => {
  switch (icon) {
    case 'format_bold':
      return FormatBoldIcon;

    case 'format_italic':
      return FormatItalicIcon;

    case 'format_underlined':
      return FormatUnderlinedIcon;

    case 'format_clear':
      return FormatClearIcon;

    default:
      return null;
  }
};

const MarkButton = ({ format, icon, tooltip }) => {
  const editor = useSlate();
  const IconTagName = getIconTag(icon);

  const handleMouseDown = useCallback(event => {
    event.preventDefault();

    editor.toggleMark(format);
  }, [editor, format]);

  return (
    <Tooltip title={tooltip} placement="top">
      <Button
        className={`mark-button${editor.isMarkActive(format) ? ' active' : ''}`}
        onMouseDown={handleMouseDown}
        color="inherit"
      >
        <IconTagName />
      </Button>
    </Tooltip>
  );
};

MarkButton.propTypes = {
  format: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
};

export default MarkButton;
