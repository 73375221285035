/* eslint-disable react/no-multi-comp */
import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Virtuoso } from 'react-virtuoso';

import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import AddIcon from '@mui/icons-material/Add';

import Option from './Option';

const MenuList = ({
  options,
  selectProps,
  selectOption,
  getValue,
  focusedOption,
}) => {
  const selectedItemIndex = useMemo(() =>
    options.findIndex(i =>
      getValue()?.[0]?.value === i.value
    ),
  [options, getValue]
  );

  const {
    searchValue,
    onCreateItem,
    menuHeight,
    selected,
    onVisibilityChange,
    loading
  } = selectProps;

  const handleSelectPinned = useCallback(() =>
    onCreateItem(),
  [onCreateItem]);

  return (
    <Box>
      {loading.refetching ?
        <MenuItem sx={{ height: 48 }}>
          <CircularProgress disableShrink size={32} />
        </MenuItem> :
        <Virtuoso
          style={{ height: menuHeight }}
          data={options}
          increaseViewportBy={100000}
          initialTopMostItemIndex={selectedItemIndex > -1 ? selectedItemIndex : 0}
          itemContent={(index, props) => (
            <Option
              data={props}
              index={index}
              onClick={() => {
                selectOption(props);
              }}
              focused={focusedOption?.value === props.value}
              selected={index === selected}
              onVisibilityChange={onVisibilityChange}
            />
          )}
          components={{
            Footer: renderMenuFooter(loading.loading)
          }}
        />
      }

      <MenuItem
        onClick={handleSelectPinned}
        sx={{ height: 48 }}
      >
        <AddIcon color="primary" />

        <Typography color="primary" variant="body1" mr={1}>
          Create item
        </Typography>

        {searchValue ?
          <Typography noWrap>
            "{searchValue}"
          </Typography> :
          null
        }
      </MenuItem>
    </Box>
  );
};

MenuList.propTypes = {
  options: PropTypes.array.isRequired,
  focusedOption: PropTypes.shape({
    value: PropTypes.string.isRequired
  }),
  selectProps: PropTypes.shape({
    menuHeight: PropTypes.number.isRequired,
    onVisibilityChange: PropTypes.func.isRequired,
    selected: PropTypes.number,
    searchValue: PropTypes.string,
    onCreateItem: PropTypes.func,
    loading: PropTypes.shape({
      loading: PropTypes.bool,
      loadingMore: PropTypes.bool,
      refetching: PropTypes.bool
    })
  }).isRequired,
  selectOption: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default MenuList;

function renderMenuFooter(loading) {
  return () => loading
    ? (
      <MenuItem sx={{ height: 48 }}>
        <CircularProgress disableShrink size={32} />
      </MenuItem>
    )
    : null;
}
