import { gql } from '@apollo/client';

export const GET_FOLDERS = gql`
  query Folders {
    folders {
      id
      title
      viewerMaxRole
    }
  }
`;

export const CREATE_TABLE = gql`
  mutation CreateTable($data: TableInput!, $folderId: ID) {
    createTable(data: $data, folderId: $folderId) {
      id
      title
      description
      folderId
      dateUpdated
      viewerMaxRole
      hash
      path {
        title
        id
      }
      tableParameters {
        id
      }
      tableItems {
        id
      }
      iam {
        users {
          userId
        }
      }
      numberOfTableItems
    }
  }
`;
