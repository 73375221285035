import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { includesHash } from '../utils/hash-manager';

function useHashScroll(type, { elementRef, id }) {
  const location = useLocation();
  const { hash } = location;


  useEffect(() => {
    if (includesHash(hash, { type, id })) {
      elementRef.current?.scrollIntoView(false);
    }
  }, [hash, elementRef, id, type]);
}

export default useHashScroll;
