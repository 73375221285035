import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';

const styles = {
  root: {
    height: '42px'
  }
};

export default withStyles(styles)(Button);
