import React from 'react';
import PT from 'prop-types';

import UnitsSelect from '../../../../../../../../components/UnitsSelect';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const UnitsBlock = ({ value, onChange }) => {
  return (
    <FormControl
      width="200px"
      sx={{
        marginTop: '32px',
      }}
    >
      <InputLabel
        variant="outlined"
        disableAnimation
        shrink
        sx={{
          transform: 'translate(0px, -20px) scale(1)',
          fontSize: '12px',
        }}
      >
        Unit of measure
      </InputLabel>

      <UnitsSelect
        value={value}
        onChange={onChange}
        placeholder="Select"
      />
    </FormControl>
  );
};

UnitsBlock.propTypes = {
  options: PT.array.isRequired,
  value: PT.object,
  onChange: PT.func.isRequired,
};

export default UnitsBlock;
