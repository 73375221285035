import styled from 'styled-components';

import Box from '@mui/material/Box';

const ContainerStyled = styled(Box)`
  .singleProtocol:first-of-type {
    border-top: 1px solid transparent;
  }
`;

export default ContainerStyled;
