import { gql } from '@apollo/client';

const fragments = {
  comment: gql`
    fragment CommentData on TableItemComment {
      id
      contents {
        children {
          text
          name
          type
          italic
          bold
          underline
          children {
            text
          }
        }
        type
      }
      dateCreated
      dateUpdated
      likes {
        id
        name
        ... on CurrentUser {
          avatarColor
          picture {
            downloadURL
          }
        }
      }
      author {
        id
        name
        
        ... on CurrentUser {
          avatarColor
          picture {
            downloadURL
          }
        }
      }
    }
  `
};

export const GET_COMMENTS = gql`
  query Comments($itemId: ID!) {
    tableItemComments(tableItemId: $itemId) {
      ...CommentData
    }
  }
  ${fragments.comment}
`;

export const COMMENT_CREATED_SUBSCRIPTION = gql`
  subscription onCommentCreated($itemId: ID!) {
    tableItemCommentCreated(tableItemId: $itemId) {
      ...CommentData
    }
  }
  ${fragments.comment}
`;

export const COMMENT_UPDATED_SUBSCRIPTION = gql`
  subscription onCommentUpdated($itemId: ID!) {
    tableItemCommentUpdated(tableItemId: $itemId) {
      ...CommentData
    }
  }
  ${fragments.comment}
`;

export const COMMENT_DELETED_SUBSCRIPTION = gql`
  subscription onCommentDeleted($itemId: ID!) {
    tableItemCommentDeleted(tableItemId: $itemId)
  }
`;

export const CREATE_COMMENT = gql`
  mutation CreateComment($itemId: ID!, $input: TableItemCommentInput!) {
    addTableItemComment(tableItemId: $itemId, input: $input) {
      id
    }
  }
`;

export const UPDATE_COMMENT = gql`
  mutation UpdateComment($id: ID!, $input: TableItemCommentInput!) {
    updateTableItemComment(id: $id, input: $input) {
      id
      contents  {
        children {
          text
          name
          type
          italic
          bold
          underline
          children {
            text
          }
        }
        type
      }
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation DeleteTableItemComment($id: ID!) {
    deleteTableItemComment(id: $id)
  }
`;

export const TOGGLE_COMMENT_REACTION = gql`
  mutation ToggleCommentReaction($id: ID!) {
    toggleTableItemCommentReaction(id: $id) {
      id
    }
  }
`;

export const COMMENT_REACTION_SUBSCRIPTION = gql`
  subscription onCommentReaction($itemId: ID!) {
    tableItemCommentReacted(tableItemId: $itemId) {
      ...CommentData
    }
  }
  ${fragments.comment}
`;

export const GET_TABLE_USERS = gql`
  query TableUsers($tableId: ID!) {
    tableUsers(id: $tableId) {
      userId
      pictureDownloadURL
      avatarColor
      firstName
      lastName
    }
  }
`;

export const GET_VIEWER = gql`
  query NewCommentViewerInfo {
    viewer {
      id
    }
  }
`;
