import React from 'react';
import PT from 'prop-types';

import Typography from '@mui/material/Typography';

import { COLOR_GREY } from '../../styles';

const ColumnHeader = ({ text, width = '180px'  }) => {
  return (
    <th width={width}>
      <Typography
        sx={{
          fontSize: '14px',
          color: COLOR_GREY,
          textAlign: 'start',
          textTransform: 'capitalize',
          marginTop: '12px',
          marginBottom: '10px',
          lineHeight: '1'
        }}
      >
        {text}
      </Typography>
    </th>
  );
};

ColumnHeader.propTypes = {
  text: PT.string,
  width: PT.string
};

export default ColumnHeader;
