import styled from 'styled-components';

import Box from '@mui/material/Box';

export const Container = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  max-width: 100%;
`;
