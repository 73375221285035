import React, { useMemo } from 'react';

import ResetPassword from './ResetPassword';
import ResetEmail from './ResetEmail';
import VerifyEmail from './VerifyEmail';

import { CardComponent, Main } from './styles';

const EmailsActions = () => {

  const params = useMemo(() =>  new URLSearchParams(window.location.search), []);

  const mode = params.get('mode');
  const oobCode = params.get('oobCode');
  const continueUrlParam = params.get('continueUrl');

  const continueUrl = useMemo(() => {
    try {
      if (continueUrlParam)
        return new URL(continueUrlParam).pathname;

      return null;
    } catch (e) {
      return null;
    }

  }, [continueUrlParam]);

  return (
    <Main>
      <CardComponent>
        {mode === 'resetPassword' &&
          <ResetPassword
            oobCode={oobCode}
            continueUrl={continueUrl}
          />
        }

        {mode === 'recoverEmail' &&
          <ResetEmail
            oobCode={oobCode}
          />
        }

        {mode === 'verifyEmail' &&
          <VerifyEmail
            oobCode={oobCode}
            continueUrl={continueUrl}
          />
        }
      </CardComponent>
    </Main>
  );
};

export default EmailsActions;
