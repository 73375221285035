import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { routes } from '../../../../../../services/session/constants';
import { rolesIds } from '../../../../../../utils/roles';

import { COLOR_PRIMARY } from '../../../../../../styles';

const FeatureTitle = ({ data }) => {
  if (data.item) {
    const { id, title, code, viewerMaxRole, archived } = data.item;
    const noAccess = viewerMaxRole === rolesIds.NO_ACCESS || archived;
    const titleProps = noAccess ?
      {
        color: '#9e9e9e',
        sx: { textDecoration: 'underline' }
      } :
      {
        color: COLOR_PRIMARY,
        component: Link,
        to: generatePath(routes.ITEMS, { sampleId: id }),
        target: '_blank',
        onClick: ev => ev.stopPropagation()
      };

    return (
      <Box
        display="flex"
        alignItems="center"
        gap="4px"
        overflow="hidden"
      >
        {noAccess ?
          null :
          <OpenInNewIcon
            color="primary"
            sx={{
              height: '18px'
            }}
          />
        }

        <Tooltip
          title={noAccess ? 'Inaccessible item' : title}
          placement="top"
        >
          <Typography
            variant="body2"
            {...titleProps}
          >
            {title}
          </Typography>
        </Tooltip>

        <Typography
          variant="body2"
          minWidth="fit-content"
        >
          ({code})
        </Typography>
      </Box>
    );
  }

  return (
    <Tooltip title={data.value}>
      <Typography
        sx={{
          fontSize: '14px',
          textOverflow: 'ellipsis',
          wordBreak: 'break-word'
        }}
      >
        {data.value}
      </Typography>
    </Tooltip>
  );
};

FeatureTitle.propTypes = {
  data: PropTypes.object.isRequired
};

export default FeatureTitle;
