import styled from 'styled-components';

import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';

export const InputStyled = styled(OutlinedInput)`
  height: 38px;
  width: 100%;
  border-radius: 4px;
  padding-top: 0;
  padding-bottom: 0;
  background: white;
  font-size: 14px;

  fieldset {
    border: none; 
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none; 
  }

  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    display: none;
  };
`;

export const SelectStyled = styled(TextField)`
  height: 36px;
  width: 100%;
  border-radius: 4px;
  border-color: transparent;
  display: flex;
  align-items: center;
  flex-direction: row;

  .MuiInputBase-root {
    width: 100%;
    height: 100%;
  }

  .MuiSelect-select {
    font-size: 14px;
  }

  fieldset {
    border: none; 
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none; 
  }

  :hover {      
    fieldset {      
      border-color: transparent !important; 
    }
  }
`;
