import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Checkbox,
  TableCell
} from '../styles';

const mapStateToProps = (state, props) => ({
  checked: props.checkedSelector
    ? props.checkedSelector(state, props)
    : props.checked
});

class TableCheckboxCell extends PureComponent {
  static propTypes = {
    checked: PropTypes.bool.isRequired,
    onCheck: PropTypes.func.isRequired,
    indeterminate: PropTypes.bool
  };

  handleCheck = () => {
    const { onCheck, checked } = this.props;
    onCheck(!checked);
  };

  render() {
    const { checked, indeterminate } = this.props;

    return (
      <TableCell className="checkbox-cell">
        <Checkbox
          checked={checked}
          onChange={this.handleCheck}
          indeterminate={indeterminate}
          disableRipple
        />
      </TableCell>
    );
  }
}

export default connect(mapStateToProps)(TableCheckboxCell);
