import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import identity from 'lodash/identity';

import Box from '@mui/material/Box';

import ParameterLine from './ParameterLine';

const ParametersContainer = ({
  protocols,
  data,
  onChange,
  onRemove,
  featuresById,
  loading
}) => {
  const handleParameterChange = useCallback((idx) => (type, value) => {
    onChange(idx, { type, value });
  }, [onChange]);

  const handleRemove = useCallback(idx => () => {
    onRemove(idx);
  }, [onRemove]);

  const disabledOptions = useMemo(() => {
    return data.map((i) => i.filterType).filter(Boolean);
  }, [data]);

  return (
    <Box>
      {data.map((i, idx) => (
        <ParameterLine
          loading={loading}
          key={`${identity(idx)}-${i.parameterId}`}
          index={idx}
          featuresById={featuresById}
          protocols={protocols}
          onRemove={handleRemove(idx)}
          onChange={handleParameterChange(idx)}
          data={i}
          disabledOptions={disabledOptions}
        />
      ))}
    </Box>
  );
};

ParametersContainer.propTypes = {
  protocols: PropTypes.array,
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  featuresById: PropTypes.object,
  loading: PropTypes.bool
};

export default ParametersContainer;
