import { gql } from '@apollo/client/core';

export const FOLDER_SETTINGS = gql`
  query FolderSettings($id: ID!) {
    folder(id: $id) {
      id
      title
      viewerMaxRole
      dateUpdated
      path {
        title
      }
      iam {
        organization
        users {
          userId
          name
          firstName
          lastName
          avatarColor
          pictureDownloadURL
          role
          dateJoined
        }
      }
    }
  }
`;

export const UPDATE_FOLDER = gql`
  mutation UpdateFolder($id: ID!, $data: UpdateFolderInput!) {
    updateFolder(id: $id, data: $data) {
      id
      title
      dateUpdated
    }
  }
`;
