import React from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';

import RawText from '../RawText';
import { parseToExponential } from '../../../../../../utils';

const ViewQuantityValue = ({ data, onClick }) => {
  const shortenedValue = data !== null ? parseToExponential(data) : data;

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      onClick={onClick}
    >
      <RawText title={data}>
        {shortenedValue}
      </RawText>
    </Box>
  );
};

ViewQuantityValue.propTypes = {
  onClick: PT.func,
  data: PT.oneOfType([PT.string, PT.number])
};

export default ViewQuantityValue;
