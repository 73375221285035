import React from 'react';

const isExoticComponent = component => {
  return (
    typeof component === 'object' &&
    typeof component.$$typeof === 'symbol' &&
    ['react.memo', 'react.forward_ref'].includes(component.$$typeof.description)
  );
};

const isClassComponent = component => {
  return (
    typeof component === 'function' &&
    (() => {
      const proto = Object.getPrototypeOf(component);

      return proto.prototype && proto.prototype.isReactComponent;
    })()
  );
};

const isReactComponent = (component) => {
  return (
    isClassComponent(component) ||
    typeof component === 'function' ||
    isExoticComponent(component)
  );
};

export default function flexRender(Comp, props) {
  if(!Comp)
    return null;

  return isReactComponent(Comp) ?
    (<Comp {...props} />) :
    Comp;
}
