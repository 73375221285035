import React, { useState, useCallback } from 'react';
import PT from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import ColumnOptionsSearch from './ColumnOptionsSearch';
import ColumnOptions from './ColumnOptions';
import ColumnOption from './ColumnOption';

import useVisibleColumnsState from './visibleColumnsState.hook';

const TableColumnsDialog = ({
  open,
  loading,
  onClose,
  onSubmit,
  options
}) => {
  const [search, setSearch] = useState('');
  const [visibilityOptions, toggleVisibility] = useVisibleColumnsState({
    options
  });

  const handleSearchChange = useCallback(ev => {
    setSearch(ev.target.value);
  }, []);

  const handleClose = useCallback(() => {
    setSearch('');
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    onSubmit(visibilityOptions);
  }, [visibilityOptions, onSubmit]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Select parameters</DialogTitle>

      <DialogContent>
        <ColumnOptionsSearch
          value={search}
          onChange={handleSearchChange}
        />

        <ColumnOptions>
          {visibilityOptions.map(({ id, title, visible, columns, notActive }) => (
            <ColumnOption
              key={id}
              id={id}
              title={title}
              visible={visible}
              columns={columns}
              search={search}
              onChange={toggleVisibility}
              notActive={notActive}
            >
              {title}
            </ColumnOption>
          ))}
        </ColumnOptions>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button
          onClick={handleClose}
          color="primary"
        >
          Cancel
        </Button>

        <LoadingButton
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          loading={loading}
        >
          Apply
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

TableColumnsDialog.propTypes = {
  open: PT.bool,
  loading: PT.bool,
  onClose: PT.func,
  onSubmit: PT.func,
  options: PT.arrayOf(PT.shape({
    id: PT.string,
    visible: PT.bool,
    columns: PT.arrayOf(PT.shape({
      id: PT.string,
      visible: PT.bool
    }))
  }))
};

export default TableColumnsDialog;
