import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';

import { routes } from '../../../services/session/constants';

import UserInfoPopover from './UserInfoPopover';
import Notifications from './Notifications';

import AppBar, {
  Logo,
  ToolbarStyled
} from './styles';
import AppMainMenu from './AppMainMenu';

function AppHeader({
  mobile,
  isRegularUser,
}) {
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const handleCloseSideBar = useCallback(() => {
    setSideBarOpen(false);
  }, []);

  const handleOpenSideBar = useCallback(() => {
    setSideBarOpen(true);
  }, []);

  return (
    <AppBar
      position="static"
      mobile={`${mobile}`}
      sidebaropened={`${sideBarOpen}`}
    >
      <ToolbarStyled>
        <div className="left-block">
          {mobile &&
            <IconButton
              className="toggler"
              color="inherit"
              aria-label="Menu"
              onClick={handleOpenSideBar}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          }

          <Link to={routes.HOME_PAGE}>
            <Logo />
          </Link>
        </div>

        <AppMainMenu
          mobile={mobile}
          isRegularUser={isRegularUser}
          onClose={handleCloseSideBar}
          open={sideBarOpen}
        />

        <div className="right-block">
          <Notifications />

          {isRegularUser &&
          <Tooltip title="Help & Tutorials">
            <Fab
              color="primary"
              className="help-link"
            >
              <a
                href="http://help.materials.zone/en"
                target="_blank"
                rel="noopener noreferrer"
                className="help-link"
              >
                <HelpIcon className="help-icon" />
              </a>
            </Fab>
          </Tooltip>
          }

          <UserInfoPopover />
        </div>
      </ToolbarStyled>
    </AppBar>
  );
}

AppHeader.propTypes = {
  mobile: PropTypes.bool.isRequired,
  isRegularUser: PropTypes.bool.isRequired,
};

export default AppHeader;
