import { gql } from '@apollo/client';

export const CHANGE_IDLE_SIGN_OUT = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      id
      idleSignOut
    }
  }
`;

export const IDLE_SIGN_OUT_VALUES = gql`
  query idleSignOutValues {
    idleSignOutValues
  }
`;
