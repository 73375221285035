import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const StaySignedIn = props => (
  <FormControlLabel
    control={
      <Checkbox
        disableRipple
        color="primary"
        {...props}
      />
    }
    label="Stay signed in"
  />
);

export default StaySignedIn;
