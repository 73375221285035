import React, { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useAnalytics } from 'use-analytics';
import PropTypes from 'prop-types';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';

import SearchableSelectBox from '../../../components/SearchableSelectBox';

import ProfileDetailsContainer from './styles';

import { CHANGE_PROFILE_DETAILS } from './gql';
import { componentNames, TRACK_PROFILE_SETTINGS } from '../../../analytics/constants';

const useStyles = makeStyles({
  inputLabel: {
    transform: 'translate(0px, -1px) scale(0.75)'
  }
});

const ProfileDetails = ({
  userId,
  info: initialInfo,
  countries,
  onSave
}) => {
  const [changeProfileDetails, { loading }] = useMutation(CHANGE_PROFILE_DETAILS, {
    onCompleted: () => {
      onSave();
    }
  });

  const [info, setInfo] = useState(initialInfo);

  const { track } = useAnalytics();

  const classes = useStyles();

  const handleCountrySelect = useCallback((value) => {
    const country = countries.find(c => c.alpha2 === value);

    setInfo({
      ...info,
      country: {
        alpha2: country.alpha2,
        en: country.en
      }
    });
  }, [info, countries]);

  const handleInfoChange = useCallback(ev => {
    const { name, value } = ev.target;

    setInfo({
      ...info,
      [name]: value
    });
  }, [info]);

  const handleDismissChanges = useCallback(() => {
    setInfo(initialInfo);
  }, [initialInfo]);

  const handleSaveChanges = useCallback(() => {
    changeProfileDetails({
      variables: {
        input: {
          firstName: info.firstName,
          lastName: info.lastName,
          position: info.position || '',
          country: info.country.alpha2,
          phone: info.phone || '',
          mobile: info.mobile || '',
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateProfile: {
          __typename: 'Viewer',
          id: userId,
          firstName: info.firstName,
          lastName: info.lastName,
          position: info.position,
          country: {
            __typename: 'Country',
            alpha2: info.country.alpha2,
            en: info.country.en
          },
          phone: info.phone,
          mobile: info.mobile
        }
      }
    });

    track(TRACK_PROFILE_SETTINGS.change, {
      component: componentNames.PERSONAL_INFO
    });
  }, [userId, info, changeProfileDetails, track]);

  const country = info.country ? info.country.en : '';
  const hasChanges = initialInfo.firstName !== info.firstName
    || initialInfo.lastName !== info.lastName
    || initialInfo.position !== info.position
    || initialInfo.country?.en !== country
    || initialInfo.phone !== info.phone
    || initialInfo.mobile !== info.mobile;
  const isSaveBtnDisabled = !hasChanges || !(info.firstName.trim() && info.lastName.trim() && country) || loading;
  const countryOptions = countries.map(i => ({
    label: i.en,
    value: i.alpha2
  }));

  return (
    <ProfileDetailsContainer>
      <div className="full-name">
        <FormControl required className="input-base-control form-control first-name">
          <InputLabel
            shrink
            className={classes.inputLabel}
            htmlFor="profile-first-name-input"
          >
            First Name
          </InputLabel>

          <InputBase
            id="profile-first-name-input"
            name="firstName"
            value={info.firstName ?? ''}
            onChange={handleInfoChange}
          />
        </FormControl>

        <FormControl required className="input-base-control form-control last-name">
          <InputLabel
            shrink
            className={classes.inputLabel}
            htmlFor="profile-last-name-input"
          >
            Last Name
          </InputLabel>

          <InputBase
            id="profile-last-name-input"
            name="lastName"
            value={info.lastName ?? ''}
            onChange={handleInfoChange}
          />
        </FormControl>
      </div>

      <FormControl className="input-base-control form-control">
        <InputLabel
          shrink
          className={classes.inputLabel}
          htmlFor="profile-position-input"
        >
          Position
        </InputLabel>

        <InputBase
          id="profile-position-input"
          name="position"
          value={info.position ?? ''}
          onChange={handleInfoChange}
        />
      </FormControl>

      <SearchableSelectBox
        value={country}
        label="Country *"
        onSelect={handleCountrySelect}
        options={countryOptions}
      />

      <div className="phone-numbers">
        <FormControl className="input-base-control form-control phone-number">
          <InputLabel
            shrink
            htmlFor="profile-phone-number-input"
            className={classes.inputLabel}
          >
            Phone Number
          </InputLabel>

          <InputBase
            id="profile-phone-number-input"
            name="phone"
            value={info.phone ?? ''}
            onChange={handleInfoChange}
          />
        </FormControl>

        <FormControl className="input-base-control form-control">
          <InputLabel
            shrink
            htmlFor="profile-mobile-phone-number-input"
            className={classes.inputLabel}
          >
            Mobile Phone Number
          </InputLabel>

          <InputBase
            id="profile-mobile-phone-number-input"
            name="mobile"
            value={info.mobile ?? ''}
            onChange={handleInfoChange}
          />
        </FormControl>
      </div>

      <div className="section-actions">
        <Button
          onClick={handleDismissChanges}
          color="inherit"
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={isSaveBtnDisabled}
          onClick={handleSaveChanges}
        >
          Save
        </Button>
      </div>
    </ProfileDetailsContainer>
  );
};

ProfileDetails.propTypes = {
  userId: PropTypes.string.isRequired,
  countries: PropTypes.array,
  info: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
};

ProfileDetails.defaultProps = {
  countries: []
};

export default ProfileDetails;
