import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import firebase from 'firebase/app';
import 'firebase/auth';

import { general } from '../../configs';

const HOST = general.GQL_WS_PATH;

const wsClient = createClient({
  url: HOST,
  connectionParams: async () => {
    try {
      const token = await firebase.auth().currentUser.getIdToken(false);

      return {
        authorization: token,
        'x-mz-api-feature': 'tables'
      };
    } catch(e) {
      return {};
    }
  }
});

const wsLink = new GraphQLWsLink(wsClient);

export { wsLink as default };
