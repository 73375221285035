import styled from 'styled-components';

import ButtonBase from '@mui/material/ButtonBase';

import { COLOR_PRIMARY, COLOR_GREY_LIGHT } from '../../styles';

const CommentsContainer = styled.div`
  padding: ${props => props.borderless ? '16px 0 0 0' : '16px'};
  border: ${props => props.borderless ? null : `1px solid ${COLOR_GREY_LIGHT}`};
  border-top: none;
`;

const CommentsLoadingContainer = styled.div`
  margin: 16px;
  display: flex;
  justify-content: center;
`;

const NewCommentContainer = styled.div`
  display: flex;
  align-items: flex-start;

  > *:not(:last-child) {
    margin-right: 12px;
  }

  .comment-placeholder {
    width: 100%;
    box-sizing: border-box;
    height: 38px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #e0e0e0;
    padding-left: 18px;
    max-width: 520px;
    border-radius: 2px;
    font-size: 14px;
    color: #757575;
  }
`;

const CommentActionsContainer = styled.div.attrs({ className: 'sample-comment-actions-container' })`
  margin: 12px -8px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #424242;

  > * {
    margin: 0 8px !important;
  }
`;

const CommentAction = styled(ButtonBase)`
  height: 22px;
  font-size: 14px;
  font-weight: bold;

  &:hover {
    color: ${COLOR_PRIMARY}
  }

  > svg {
    font-size: 18px;
  }

  .likes-count {
    margin-left: 4px;
    font-weight: normal;
  }
`;

const CommentContainer = styled.div`
  font-size: 14px;
  margin-bottom: 24px;
`;

const CommentAuthorContainer = styled.div`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 12px;
  }

  .username {
    font-weight: bold;
  }

  .date-updated {
    color: #757575;
  }
`;

const CommentTextContainer = styled.div`
  margin-left: 50px;
`;

export default CommentsContainer;

export {
  CommentsLoadingContainer,
  NewCommentContainer,
  CommentActionsContainer,
  CommentAction,
  CommentContainer,
  CommentAuthorContainer,
  CommentTextContainer
};
