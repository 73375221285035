import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';

import List from '@mui/material/List';

import UsersListItem from './UsersListItem';

const styles = {
  listRoot: {
    minWidth: 240
  }
};

function UsersList({
  focusIndex,
  onSelectUser,
  users,
  classes
}) {
  return (
    <List
      role="listbox"
      aria-label="users suggestion"
      className={classes.listRoot}
      dense
    >
      {users.map(({
        userId,
        name,
        pictureDownloadURL,
        avatarColor
      }, index) => (
        <UsersListItem
          key={userId}
          id={userId}
          name={name}
          picture={pictureDownloadURL}
          avatarColor={avatarColor}
          selected={focusIndex === index}
          onSelectUser={onSelectUser}
        />
      ))}
    </List>
  );
}

UsersList.propTypes = {
  focusIndex: PropTypes.number.isRequired,
  onSelectUser: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired
};

UsersList.defaultProps = {
  users: []
};

export default withStyles(styles)(React.memo(UsersList));
