import React from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';

import ViewEnumValueSingle from './ViewEnumValueSingle';

const ViewEnumValue = ({ data, onClick }) => {
  // Currently, it is possible to set just one enum value,
  // but it is planned to support a multiple selection.
  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
      onClick={onClick}
    >
      <ViewEnumValueSingle data={data?.[0]} />
    </Box>
  );
};

ViewEnumValue.propTypes = {
  data: PT.arrayOf(PT.shape({
    id: PT.string,
    title: PT.string
  })),
  onClick: PT.func
};

export default ViewEnumValue;
