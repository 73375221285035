import React from 'react';
import PropTypes from 'prop-types';
import Input from '@mui/material/Input';
import withStyles from '@mui/styles/withStyles';

const rootStyles = {
  borderRadius: '2px',
  boxShadow: `
    0 2px 2px 0 rgba(0,0,0,0.16),
    0 0 0 1px rgba(0,0,0,0.08)
  `,
  alignItems: 'center'
};

const inputStyles = {
  height: '42px',
  padding: '5px 0 5px 5px',
  boxSizing: 'border-box',
  backgroundColor: 'white'
};

const inputDenseStyles = Object.assign({}, inputStyles, {
  height: '32px'
});

const inputMultilineStyles = Object.assign({}, inputStyles, {
  height: 'auto'
});

const multilineStyles = {
  padding: 0
};

const styles = {
  root: rootStyles,
  input: inputStyles,
  inputDense: inputDenseStyles,
  inputMultiline: inputMultilineStyles,
  multiline: multilineStyles
};


const RaisedInputStyled = props => {
  var input;
  const {
    dense,
    classes,
    customClasses: { root, ...resCustomClass },
    ...inputProps } = props;

  if(props.multiline)
    input = classes.inputMultiline;
  else if(dense)
    input = classes.inputDense;
  else
    input = classes.input;

  return (
    <Input
      {...inputProps}

      classes={{
        root: `${classes.root} ${root || ''}`,
        input,
        multiline: classes.multiline,
        ...resCustomClass
      }}
    />
  );
};

RaisedInputStyled.propTypes = {
  dense: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  multiline: PropTypes.bool,
  customClasses: PropTypes.object.isRequired
};

RaisedInputStyled.defaultProps = {
  customClasses: {}
};

export default withStyles(styles)(RaisedInputStyled);
