import { PENDING_USER } from './constants';
import { formatDate } from '../../services/teams/utils';

export function mapUsersToItems(items = []) {
  return items.map(i => ({
    id: i.id,
    userId: i.userId,
    username: i.name,
    email: i.email,
    joined: formatDate(i.dateJoined),
    lastActive: formatDate(i.dateLastActivity),
    status: i.status,
    avatarColor: i.avatarColor,
    avatarUrl: i.picture?.downloadURL,
  }));
}

export function mapMembersToItems(items = []) {
  return items.map(i => ({
    id: i.id,
    email: i.email,
    joined: formatDate(i.dateCreated),
    status: PENDING_USER
  }));
}
