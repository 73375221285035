import styled from 'styled-components';

import { MEDIA_QUERIES_XS } from '../../styles';

export default styled.h2.attrs({ className: 'page-title' })`
  font-weight: bold;
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  @media(${MEDIA_QUERIES_XS}) {
    padding: 8px;
  }
`;
