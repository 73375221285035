import React from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';

import { computeFilesize } from '../../../../../utils';

const FileInfo = ({
  loading,
  filename,
  size,
  onRemoveFile
}) => {
  const formattedSize = computeFilesize(size);
  const displayDelete = !loading && onRemoveFile;

  return (
    <Box
      sx={{
        py: '7px',
        display: 'flex',
        alignItems: 'center',
        gap: '6px'
      }}
    >
      {loading ?
        <CircularProgress size={16} /> :
        <UploadFileIcon fontSize="16px" sx={{ color: '#888888' }} />
      }

      <Typography variant="body2">
        {filename}
      </Typography>

      <Typography variant="body2">
        {formattedSize}
      </Typography>

      {displayDelete ?
        <>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              px: '2px',
              my: '6px'
            }}
          />

          <IconButton size="small" onClick={onRemoveFile}>
            <DeleteIcon sx={{ fontSize: '16px' }} />
          </IconButton>
        </> :
        null
      }
    </Box>
  );
};

FileInfo.propTypes = {
  loading: PT.bool,
  filename: PT.string,
  size: PT.number,
  onRemoveFile: PT.func
};

export default FileInfo;
