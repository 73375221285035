import styled from 'styled-components';

export default styled.div.attrs({ className: 'table-presentation-container' })`
  && {
    .table-presentation {
      overflow: auto;
      max-height: 500px;

      .table-cell-main,
      .table-cell {
        width: 140px;
        min-height: 25px;
      }
    }
  }
`;
