import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import OrganizationNav from '../AppSidebar/OrganizationNav';

import { logout } from '../../../services/session/actions';

import { routes } from '../../../services/session/constants';

import {
  UserInfoStyled,
  Text,
  UserBlock,
  Info,
  LinkStyled, ButtonStyled
} from './styles';
import {
  COLOR_GREY
} from '../../../styles';

const UserInfo = ({
  userName,
  userId,
  mobile,
  onClosePopover,
  organization,
}) => {

  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <UserInfoStyled mobile={mobile}>
      <UserBlock>
        <Info>
          <Text
            size={16}
            className="username"
          >
            {userName}
          </Text>

          <Text
            size={14}
            color={COLOR_GREY}
            className="userid"
          >
            {userId}
          </Text>
        </Info>
      </UserBlock>

      <div>
        {organization &&
          <OrganizationNav
            organization={organization}
            isSidebarOpen={false}
          />
        }
      </div>

      {!mobile &&
        <>
          <div className="extra-info">
            <div>
              <AccountCircleIcon />

              <LinkStyled
                to={routes.PROFILE_SETTINGS}
                onClick={onClosePopover}
                component={Link}
              >
                user profile
              </LinkStyled>
            </div>
            <div>
              <ToggleOnOutlinedIcon />

              <LinkStyled
                to={routes.NOTIFICATION_SETTINGS}
                onClick={onClosePopover}
                component={Link}
              >
                Notification Settings
              </LinkStyled>
            </div>
          </div>

          <div className="extra-info-bottom">
            <ButtonStyled
              label="sign out"
              variant="outlined"
              color="inherit"
              onClick={handleLogout}
            />
          </div>
        </>
      }
    </UserInfoStyled>
  );
};

UserInfo.propTypes = {
  userName: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  mobile: PropTypes.bool,
  organization: PropTypes.object,
  onClosePopover: PropTypes.func
};

export default UserInfo;
