import { scaleLinear } from 'd3-scale';
import { format } from 'd3-format';
import { axisLeft, axisRight, axisBottom } from 'd3-axis';
import { minBy, maxBy } from 'lodash';

import {
  PLOT_MIN_WIDTH,
  PLOT_MIN_HEIGHT,
  DEFAULT_MARGINS,
  PAPER_MIN_WIDTH,
  PAPER_MIN_HEIGHT,
  X_AXIS_SIZE,
  Y_AXIS_SIZE,
  LEGEND_ITEM_HEIGHT
} from '../../services/graphics/constants';

export const calcLegendSizes = ({
  isHidden = true,
  // position = { x: 'left', y: 'bottom' },
  // axisPosition = 'x',
  // orientation = 'vertical',
  location = 'outside',
  itemsCount = 0
} = {}) => {
  if(isHidden || location === 'inside') return { legendHeight: 0 };

  return {
    legendHeight: itemsCount * LEGEND_ITEM_HEIGHT
  };
};

export const calcAxesSizes = ({
  xAxesCount = 0,
  yAxesCount = 0
}) => ({
  xAxesSize: xAxesCount * X_AXIS_SIZE,
  yAxesSize: yAxesCount * Y_AXIS_SIZE
});

export const calcPlotSizes = ({
  paperWidth = PAPER_MIN_WIDTH,
  paperHeight = PAPER_MIN_HEIGHT,
  xAxesSize = 0,
  yAxesSize = 0,
  legendHeight = 0
} = {}) => {
  const calcPlotWidth = paperWidth - DEFAULT_MARGINS.right - DEFAULT_MARGINS.left - yAxesSize;
  const calcPlotHeight = paperHeight - DEFAULT_MARGINS.top - DEFAULT_MARGINS.bottom - xAxesSize - legendHeight;
  const plotWidth = calcPlotWidth > PLOT_MIN_WIDTH ? calcPlotWidth : PLOT_MIN_WIDTH;
  const plotHeight = calcPlotHeight > PLOT_MIN_HEIGHT ? calcPlotHeight : PLOT_MIN_HEIGHT;

  return { plotWidth, plotHeight };
};

export const calcPaperViewBox = ({
  contentWidth,
  contentHeight
} = {}) => {
  const viewBoxWidth = contentWidth + DEFAULT_MARGINS.right + DEFAULT_MARGINS.left;
  const viewBoxHeight = contentHeight + DEFAULT_MARGINS.top + DEFAULT_MARGINS.bottom;

  return `0 0 ${viewBoxWidth} ${viewBoxHeight}`;
};

export const calcScaleRanges = (plotSizes, data) => {
  const { plotWidth, plotHeight } = plotSizes;
  const { xAxes, yAxes, graphValues } = data;
  const filterPlotById = (axisIdProp, id) => plotData => plotData[axisIdProp] === id;
  const collectCoords = (accum, { coord }) => accum.concat(coord);

  const mapScaleRanges = (rangeValues, axisIdProp, coordIndex) => ({ id, title }) => {
    const axisRange = scaleLinear().range(rangeValues);
    const collectedAxisCoords = graphValues
      .filter(filterPlotById(axisIdProp, id))
      .reduce(collectCoords, []);

    try {
      const isAxisCoordsExist = Boolean(collectedAxisCoords.length);
      const minCoord = isAxisCoordsExist ? minBy(collectedAxisCoords, v => +v[coordIndex])[coordIndex] : 0;
      const maxCoord = isAxisCoordsExist ? maxBy(collectedAxisCoords, v => +v[coordIndex])[coordIndex] : 0;

      axisRange.domain([minCoord, maxCoord]);

      const position = axisIdProp === 'xAxisId' ?
        'bottom' :
        id === 0 ?
          'left' :
          'right';

      return { range: axisRange, title, id, position };
    } catch (err) {
      console.error(err);
      throw new Error('[calcScaleRanges] Invalid data');
    }
  };
  return {
    x: xAxes.map(mapScaleRanges([0, plotWidth], 'xAxisId', 0)),
    y: yAxes.map(mapScaleRanges([plotHeight, 0], 'yAxisId', 1))
  };
};

export const generateAxis = axisGenerator =>
  ({ range, title, id, position }) => {
    let axis;

    switch(position) {
      case 'bottom':
        axis = axisBottom(range);
        break;
      case 'left':
        axis = axisLeft(range);
        break;
      case 'right':
        axis = axisRight(range);
        break;
      default:
        axis = axisGenerator(range);
    }

    return { title, id, axis: axis.tickFormat(format('.3s')) };
  };
