import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router-dom';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { routes } from '../../../services/session/constants';

const styles = {
  button: {
    padding: 0,
    textTransform: 'none',
  }
};

const CreateItemSuccessMessage = ({ id, title, code, onClick }) => {
  const link = generatePath(routes.ITEMS, { sampleId: id });

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(`${window.location.origin}${link}`);
  }, [link]);

  const buttonProps = onClick ? { onClick } : {
    component: Link,
    target: '_blank',
    link
  };

  return (
    <Box>
      <Typography variant="body2">
        The "{title}" {code} item was created.
      </Typography>

      <Box display="flex" pt="4px">
        <Button
          sx={styles.button}
          {...buttonProps}
        >
          View item
        </Button>

        <Box mr="8px" ml="8px">
          <Typography color="primary">
            •
          </Typography>
        </Box>

        <Button
          sx={styles.button}
          onClick={handleCopyLink}
        >
          Copy link
        </Button>
      </Box>
    </Box>
  );
};

CreateItemSuccessMessage.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  code: PropTypes.string,
  onClick: PropTypes.func,
};

export default CreateItemSuccessMessage;
