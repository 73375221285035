import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';

import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import Avatar from '../../../Avatar';

const styles = {
  listItemAvatar: {
    height: '24px',
    width: '24px',
    minWidth: 0,
    lineHeight: '24px'
  },
  listItemText: {
    paddingLeft: 8
  }
};

function UsersListItem({
  id,
  name,
  picture,
  avatarColor,
  selected,
  onSelectUser,
  classes
}) {
  const handleItemClick = React.useCallback(() => {
    onSelectUser({ id, name });
  }, [id, name, onSelectUser]);

  const ref = React.useRef();

  React.useEffect(() => {
    if(selected)
      ref.current.scrollIntoView(false);
  }, [selected]);

  return (
    <ListItem
      selected={selected}
      onClick={handleItemClick}
      ref={ref}
      button
    >
      <ListItemAvatar className={classes.listItemAvatar}>
        <Avatar
          name={name}
          src={picture}
          size={24}
          color={avatarColor}
          textSizeRatio={2}
        />
      </ListItemAvatar>

      <ListItemText
        primary={name}
        className={classes.listItemText}
      />
    </ListItem>
  );
}

UsersListItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  picture: PropTypes.string,
  avatarColor: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  onSelectUser: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(React.memo(UsersListItem));
