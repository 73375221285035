import styled from 'styled-components';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export const MobileMenuStyled = styled(Menu)`
  .MuiPaper-root {
    width: 100%;
    max-width: 241px;
  }  
`;

export const MobileMenuItemStyled = styled(MenuItem)`
  padding: 6px 8px;
`;
