import CheckboxComponent from '@mui/material/Checkbox';

import styled from 'styled-components';

import {
  COLOR_PRIMARY,
  COLOR_WHITE,
  COLOR_GREY_LIGHT,
  COLOR_PRIMARY_LIGHT,
  COLOR_TEXT
} from '../../styles';

const CHECKBOX_COLUMN_WIDTH = 48;

export const Checkbox = styled(CheckboxComponent)
  .attrs({ className: 'checkbox ' })`
  && {
    width: 100%;
  }
`;

export const TableCell = styled.div.attrs({ className: 'table-cell' })`
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-left: ${props => props.mobile
    ? `1px solid ${COLOR_GREY_LIGHT}`
    : `1px solid ${COLOR_WHITE}`
};
  border-bottom: 1px solid ${COLOR_GREY_LIGHT};
  flex-shrink: 0;
  box-sizing: border-box;

  &:nth-last-child(2) {
    border-right: none;
  }

  &:first-child,
  &:last-child {
    border-left: ${props => props.mobile
    ? `1px solid ${COLOR_GREY_LIGHT}`
    : `1px solid ${COLOR_WHITE}`
};
  }

  &.checkbox-cell {
    width: ${CHECKBOX_COLUMN_WIDTH}px;
    background-color: ${COLOR_WHITE};
    margin: auto;
    position: sticky;
    left: 0;
    padding: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
  }

  &.action {
    text-align: center;
    padding: 0;
    justify-content: center;

    .action-container {
      display: flex;

      button {
        width: 100%;
        min-width: 0px;
        padding: 0;

        svg {
          margin: auto;
          background-color: transparent;
          color: ${COLOR_TEXT};
          padding: 8px;
          width: 18px;
          height: 18px;
        }
      }
    }
  }
`;

export default styled.div.attrs({ className: 'table' })`
  overflow-x: auto;
  overflow-y: hidden;
`;

export const TableHead = styled.div.attrs({ className: 'table-head' })`
  display: flex;
  min-width: 550px;
  overflow-wrap: break-word;
  word-break: break-word;

  .table-cell {
    background-color: ${COLOR_PRIMARY};
    color: ${COLOR_WHITE};
    font-size: 14px;
    font-weight: 500;
    border-left: ${props => props.mobile
    ? `1px solid ${COLOR_GREY_LIGHT}`
    : `1px solid ${COLOR_PRIMARY}`
};
    border-bottom: 1px solid ${COLOR_GREY_LIGHT};

    &:nth-last-child(2) {
      border-right: none;
    }

    &:first-child,
    &:last-child {
      border-left: ${props => props.mobile
    ? `1px solid ${COLOR_GREY_LIGHT}`
    : `1px solid ${COLOR_PRIMARY}`
};
    }

    &.sortable:hover {
      background-color: ${COLOR_PRIMARY_LIGHT};
      cursor: pointer;
    }

    &.checkbox-cell {
      display: flex;
      justify-content: center;
      background-color: ${COLOR_PRIMARY};
    }

    .arrow-up,
    .arrow-down {
      width: 16px;
      height: 16px;
    }

    .sort-icon-container {
      display: flex;
      flex-direction: column;
      position: relative;
      height: 26px;
      width: 16px;

      svg {
        position: absolute;
        left: 0;

        &.arrow-up {
          top: 0;
        }

        &.arrow-down {
          bottom: 0;
        }
      }

    }
  }
`;
