import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual, omit } from 'lodash';

import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import TableCheckboxCell from './TableCheckboxCell';
import TableRow from './TableRow';
import TableStyled, {
  TableHead,
  TableCell
} from './styles';

import { isMobileView } from '../../services/session/selectors';

const mapStateToProps = state => ({
  mobile: isMobileView(state),
});

class Table extends Component {
  isChecked = id => this.props.checkedList.indexOf(id) > -1;
  isCheckboxDisabled = id => this.props.checkboxDisabledList.includes(id);

  isCheckedAll = () => {
    const { items, checkedList, checkboxDisabledList } = this.props;
    const filteredItems = items.filter(i =>
      !checkboxDisabledList.includes(i.id)
    );
    const filteredCheckedList = checkedList.filter(i =>
      !checkboxDisabledList.includes(i.id)
    );

    return filteredItems.length === filteredCheckedList.length;
  };

  shouldComponentUpdate(newProps) {
    return !isEqual(this.props, newProps);
  }

  componentDidCatch(err, info) {
    console.error(err, info);
  }

  handleCheck = (id, value) => {
    const { onCheck, optimized } = this.props;

    if (optimized) {
      onCheck(id, value);
    } else {
      const { checkedList, onCheck } = this.props;

      let result;

      if (checkedList.indexOf(id) > -1) {
        result = checkedList.filter(i => i !== id);
      } else {
        result = [...checkedList, id];
      }

      return onCheck(result, value);
    }
  };

  handleExpandToggle = (id, value) => {
    this.props.onExpandToggle(id, value);
  };

  handleCheckAll = value => {
    const {
      items,
      optimized,
      onCheckAll,
      onCheck,
      checkboxDisabledList
    } = this.props;

    if (optimized) {
      onCheckAll(value);
    } else {
      let result;

      if (this.isCheckedAll()) {
        result = [];
      } else {
        result = items
          .map(i => i.id)
          .filter(i => !checkboxDisabledList.includes(i));
      }

      onCheck(result, value);
    }
  };

  handleSorting = field => {
    const { sortAsc, onSort } = this.props;
    const newSortAsc = !sortAsc;

    onSort({
      sortAsc: newSortAsc,
      sortBy: field
    });
  };

  render() {
    const {
      items,
      selectable,
      columnsMap,
      generalCheckboxSelector,
      itemSelector,
      optimized,
      sortedField,
      sortAsc,
      childrenSelector,
      renderChildren,
      isGeneralCheckboxIndeterminate,
      className,
      indeterminateSelector,
      mobile
    } = this.props;

    return (
      <TableStyled className={className}>
        <TableHead mobile={mobile}>
          {selectable &&
            <TableCheckboxCell
              checkedSelector={optimized && generalCheckboxSelector}
              onCheck={this.handleCheckAll}
              checked={!optimized && this.isCheckedAll()}
              indeterminate={Boolean(isGeneralCheckboxIndeterminate)}
            />
          }
          {columnsMap.map(i =>
            <TableCell
              mobile={mobile}
              className={`${i.value}${i.sortable ? ' sortable' : ''}`}
              key={i.value}
              onClick={i.sortable && this.handleSorting.bind(null, i.sortProp)}
            >
              {i.sortable && i.sortProp !== sortedField &&
                <div className="sort-icon-container">
                  <ArrowUpIcon className="arrow-up" />
                  <ArrowDownIcon className="arrow-down" />
                </div>
              }

              {i.sortable && i.sortProp === sortedField && !sortAsc &&
                <ArrowUpIcon className="arrow-up" />
              }

              {i.sortable && i.sortProp === sortedField && sortAsc &&
                <ArrowDownIcon className="arrow-down" />
              }
              {i.label}
            </TableCell>
          )}
        </TableHead>

        {items.map((i, idx) =>
          <TableRow
            className={idx % 2 ? 'odd' : 'even'}
            key={i.id || idx}
            mobile={mobile}
            item={omit(i, 'expanded')}
            selectable={selectable}
            columnsMap={columnsMap}
            checked={(!optimized && selectable) && this.isChecked(i.id)}
            checkBoxDisabled={(!optimized && selectable) && this.isCheckboxDisabled(i.id)}
            expanded={i.expanded}
            onCheck={selectable && this.handleCheck}
            onExpandToggle={this.handleExpandToggle}
            itemSelector={optimized && itemSelector}
            childrenSelector={childrenSelector}
            renderChildren={renderChildren}
            isCheckedAllSelector={generalCheckboxSelector}
            indeterminateSelector={indeterminateSelector}
            isGeneralCheckboxIndeterminate={isGeneralCheckboxIndeterminate}
          />
        )}
      </TableStyled>
    );
  }
}

Table.propTypes = {
  items: PropTypes.array.isRequired,
  columnsMap: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired).isRequired,
  onCheck: PropTypes.func,
  selectable: PropTypes.bool,
  generalCheckboxSelector: PropTypes.func,
  onCheckAll: PropTypes.func,
  itemSelector: PropTypes.func,
  checkedList: PropTypes.array,
  checkboxDisabledList: PropTypes.array,
  optimized: PropTypes.bool,
  onExpandToggle: PropTypes.func,
  sortedField: PropTypes.string,
  sortAsc: PropTypes.bool,
  onSort: PropTypes.func,
  childrenSelector: PropTypes.func,
  renderChildren: PropTypes.func,
  indeterminateSelector: PropTypes.func,
  isGeneralCheckboxIndeterminate: PropTypes.bool,
  className: PropTypes.string,
  mobile: PropTypes.bool.isRequired
};

Table.defaultProps = {
  checkboxDisabledList: []
};

export default connect(mapStateToProps)(Table);
