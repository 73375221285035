import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';

const styles = {
  root: {
    position: 'relative',
    borderColor: grey[400]
  },
  input: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'block',
    opacity: 0
  }
};

const ButtonFile = ({
  children,
  classes,
  onChange,
  inputFileProps = {},
  variant,
  ...props
}) => {
  const inputRef = React.useRef();

  const handleChange = useCallback(e => {
    onChange(e, inputRef);
  }, [onChange]);

  return (
    <Button
      variant={variant}
      component="label"
      classes={{ root: classes.root }}
      {...props}
    >
      <input
        type="file"
        ref={inputRef}
        className={classes.input}
        onChange={handleChange}
        {...inputFileProps}
      />

      {children}
    </Button>
  );
};

ButtonFile.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  classes: PropTypes.object,
  onChange: PropTypes.func,
  variant: PropTypes.string,
  inputFileProps: PropTypes.object
};

ButtonFile.defaultProps = {
  variant: 'outlined'
};

export default withStyles(styles)(ButtonFile);
