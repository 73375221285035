import React from 'react';

import { Link } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Page from '../../components/Page';
import { PageContentStyled } from './styles';
import useDocumentTitle from '../../hooks/document-title';
import { routes } from '../../services/session/constants';

const NotFound = () => {

  useDocumentTitle('Not Found');

  return (
    <Page>
      <PageContentStyled>
        <div className="image-container">
          <img src="/img/404.png" alt="Page not found" />
        </div>

        <div>
          <Typography variant="h1" className="page-title">
            Page not found!
          </Typography>

          <Typography variant="body1" className="page-text-content">
            Sorry, it looks like this page is missing. The page may have been removed,
            or the link you clicked may be broken.
          </Typography>

          <Button
            component={Link}
            to={routes.HOME_PAGE}
            variant="contained"
            color="primary"
            className="action-button"
          >
            Go back to the home page
          </Button>
        </div>
      </PageContentStyled>
    </Page>
  );};

export default NotFound;
