import { Transforms } from 'slate';

export default function withMention(editor) {
  const { isInline, isVoid } = editor;

  editor.isInline = element => {
    return element.type === 'mention' ? true : isInline(element);
  };

  editor.isVoid = element => {
    return element.type === 'mention' ? true : isVoid(element);
  };

  editor.insertMention = ({ name, id }) => {
    const mention = {
      uid: id,
      name,
      type: 'mention',
      children: [{ text: name }]
    };

    Transforms.insertNodes(editor, mention);
    Transforms.move(editor);
  };

  return editor;
}
