import React, { useCallback } from 'react';
import { Transforms, Editor, Range } from 'slate';
import { ReactEditor } from 'slate-react';
import PT from 'prop-types';

import Typography from '@mui/material/Typography';

const SingleColumn = ({ data, editor }) => {
  const handleClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!editor.selection) return;

    const title = data.title ?? data.titleTableItem.title;

    const column = {
      id: data.id,
      type: 'column',
      children: [{ text: title }],
    };

    const [start] = Range.edges(editor.selection);
    const wordBefore = Editor.before(editor, start, { unit: 'word' });
    const before = wordBefore && Editor.before(editor, wordBefore);
    const beforeRange = before && Editor.range(editor, before, start);
    const beforeText = beforeRange && Editor.string(editor, beforeRange);
    const beforeMatch = beforeText && beforeText.match(/^#/);

    if(beforeMatch) {
      Transforms.select(editor, beforeRange);
    }

    editor.insertNode(column);
    Transforms.move(editor);
    ReactEditor.focus(editor);
  }, [data.id, data?.title, data.titleTableItem?.title, editor]);

  return (
    <Typography
      onClick={handleClick}
      sx={{
        fontSize: '12px',
        color: 'rgba(0, 0, 0, 0.6)',
        p: '2px 0',
        '&:hover': {
          textDecoration: 'underline',
          cursor: 'pointer',
        }
      }}
    >
      {data.title ?? data.titleTableItem.title}
    </Typography>
  );
};

SingleColumn.propTypes = {
  data: PT.object.isRequired,
  editor: PT.object.isRequired,
  selection: PT.object,
};

export default SingleColumn;
