import React, { useCallback } from 'react';
import PT from 'prop-types';

import { IAM_ROLES } from '../../../../services/samples/constants';

import SelectFormControl from './SelectFormControl';

const INPUT_ID = 'premission-select-input';
const options = [
  {
    value: IAM_ROLES.get('READ'),
    label: { primary: 'Read' }
  },
  {
    value: IAM_ROLES.get('WRITE'),
    label: { primary: 'Write' }
  },
  {
    value: IAM_ROLES.get('ADMIN'),
    label: { primary: 'Admin' }
  }
];

const PermissionSelect = ({ value, onChange }) => {
  const handleChangePermission = useCallback((e) => {
    onChange(e.target.value);
  }, [onChange]);

  return (
    <SelectFormControl
      id={INPUT_ID}
      label="Permission"
      value={value}
      onChange={handleChangePermission}
      options={options}
      inputStyles={{
        pl: '8px'
      }}
    />
  );
};

PermissionSelect.propTypes = {
  value: PT.oneOf([
    IAM_ROLES.get('READ'),
    IAM_ROLES.get('WRITE'),
    IAM_ROLES.get('ADMIN'),
  ]),
  onChange: PT.func.isRequired
};

export default PermissionSelect;
