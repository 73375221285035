import { gql } from '@apollo/client';

export const ADD_MEASUREMENT = gql`
  mutation UpdateTableItemFile($updateTableItemFileId: ID!, $file: UpdateTableItemFileInput!) {
    updateTableItemFile(id: $updateTableItemFileId, file: $file) {
      id
    }
  }
`;

export const DELETE_MEASUREMENTS = gql`
  mutation DeleteTableItemFile($deleteTableItemFileId: ID!) {
    deleteTableItemFile(id: $deleteTableItemFileId)
  }
`;

export const UPDATE_MEASUREMENT = gql`
  mutation UpdateTableItemFile($updateTableItemFileId: ID!, $file: UpdateTableItemFileInput!) {
    updateTableItemFile(id: $updateTableItemFileId, file: $file) {
      id
      title
      description
      dateCreated
      dateUpdated
      creator {
        id
        name
      }
      rawFileDownloadURL
      processedFileDownloadURL
      parsingConfig {
        id
        code
        dateUsed
        measurement {
          id
          code
          name
        }
        instrument {
          id
          code
          model
          manufacturer {
            id
            code
            name
          }
        }
        parser {
          id
          code
          name
          runtime
          viewType
          dataType
        }
      }

      graphics {
        id
        mode
        graphicsType
        config {
          columns
          x1Index
          xAxis
          xIndex
          xOffsetIndex
          y1Index
          yAxis
          yAxisRight
          yIndex
          yOffsetIndex
          zAxis
        }
        alter {
          target
          transform
        }
      }
    }
  }
`;
