import React from 'react';
import PropTypes from 'prop-types';

import MainStyled from './styles';

const Main = ({ children, ...restProps }) =>
  <MainStyled as="main" {...restProps} id="main">
    {children}
  </MainStyled>;

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
