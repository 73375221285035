import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';

import { DIALOG_BREAKPOINT } from '../../../../styles';
import { ChangeEmailModalContainer } from '../styles';
import OutlinedInputWithLabel from '../../../../components/OutlinedInputWithLabel';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
  inputLabel: {
    transform: 'translate(0px, -2px) scale(0.75)'
  },
  formHelperText: {
    lineHeight: '1.5em',
    marginTop: 8,
    marginLeft: 0
  }
});

function ChangeEmailModal({
  open,
  onClose,
  onExit,
  onSubmit,
  oldEmail
}) {
  const [email, setEmail] = useState('');

  const styles = useStyles();
  const isMobileView = useMediaQuery(`(${DIALOG_BREAKPOINT})`);

  const handleEmailChange = useCallback(ev => {
    setEmail(ev.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit({ email });
  }, [email, onSubmit]);

  const handleExit = useCallback(() => {
    setEmail('');

    if(typeof onExit === 'function')
      onExit();
  }, [onExit]);

  const isInputInvalid = [
    email.trim().length < 5
  ].some(Boolean);

  const isEmailIdentical = oldEmail === email;

  return (
    <ChangeEmailModalContainer
      open={open}
      onClose={onClose}
      TransitionProps={{ onExit: handleExit }}
      fullScreen={isMobileView}
      aria-labelledby="change-email-title"
      maxWidth="xs"
    >
      <DialogTitle id="change-email-title">
        Change email
      </DialogTitle>

      <DialogContent>
        <FormControl required className="input-base-control form-control">
          <OutlinedInputWithLabel
            id="email-auth-login"
            label="Email"
            value={email}
            type="email"
            onChange={handleEmailChange}
            placeholder="example@domain.com"
            inputLabelProps={{ className: styles.inputLabel }}
          />

          <FormHelperText className={styles.formHelperText}>
            You will receive an email on your current address that allows to revoke the email address change, in order to protect them from account hijacking.
          </FormHelperText>
        </FormControl>

        {isEmailIdentical &&
          <Typography color="error">
            This email is the same as the old one
          </Typography>
        }
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
        >
          Cancel
        </Button>

        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={isInputInvalid || isEmailIdentical}
        >
          Save
        </Button>
      </DialogActions>
    </ChangeEmailModalContainer>
  );
}

ChangeEmailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExit: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  oldEmail: PropTypes.string.isRequired
};

export default ChangeEmailModal;
