import React, {
  useMemo,
  useState,
  useCallback,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import pick from 'lodash/pick';

import {
  createEditor,
  Editor,
  Range,
  Transforms
} from 'slate';
import {
  Slate,
  withReact,
  ReactEditor
} from 'slate-react';
import { withHistory } from 'slate-history';

import withMention from './plugins/Mention/with-mention';
import withMarksUtils from './plugins/Marks/with-marks-utils';
import { hotkeys, hotkeysBasicKey } from './utils';

import withStyles from '@mui/styles/withStyles';

import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';

import Editable from './Editable';
import UsersList from './plugins/Mention/UsersList';
import MarkButton from './MarkButton';
import Leaf from './Leaf';

import TextEditorContainer, {
  EditorWrapper,
  EditableWrapper
} from './styles';

const initialAnchorPosition = {
  top: -9999,
  left: -9999
};

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

const styles = {
  popoverPaper: {
    maxHeight: '200px',
    transform: 'translate3d(0, 0, 0)'
  }
};

const searchUsers = (mentionOptions = [], search = '') =>
  mentionOptions.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()));

const editorHotkeys = new Set([
  { format: 'bold', predicate: hotkeys.isBold },
  { format: 'italic', predicate: hotkeys.isItalic },
  { format: 'underline', predicate: hotkeys.isUnderline },
  { format: 'clear', predicate: hotkeys.isClear },
]);

function TextEditor({
  contents = [{
    type: 'paragraph',
    children: [{ text: '' }]
  }],
  mentionOptions,
  onSubmit,
  onCancel,
  classes
}) {
  const editor = useMemo(() =>
    compose(
      withMarksUtils,
      withMention,
      withHistory,
      withReact,
    )(createEditor()), []);

  const [value, setValue] = useState(contents);
  const [target, setTarget] = useState();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [anchorPosition, setAnchorPosition] = useState(initialAnchorPosition);

  const users = useMemo(() => searchUsers(mentionOptions, search), [mentionOptions, search]);

  const handleSelectUser = useCallback(params => {
    if(!target) return;

    Transforms.select(editor, target);
    editor.insertMention(params);
    Transforms.insertText(editor, ' ');
    setTarget(null);
    setSearch('');
  }, [editor, target]);

  const handleSave = useCallback(() => {
    onSubmit(value);
  }, [value, onSubmit]);

  const handleChange = useCallback(value => {
    setValue(value);

    if(mentionOptions.length < 1) return;

    const { selection } = editor;

    if(selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection);
      const charBefore = Editor.before(editor, start, { unit: 'character' });
      const charBeforeRange = charBefore && Editor.range(editor, charBefore, start);
      const char = charBeforeRange && Editor.string(editor, charBeforeRange);
      let beforeRange;

      if(char === '@') {
        beforeRange = charBeforeRange;
      } else {
        const wordBefore = Editor.before(editor, start, { unit: 'word' });
        let before = wordBefore && Editor.before(editor, wordBefore);
        beforeRange = before && Editor.range(editor, before, start);
      }

      const beforeText = beforeRange && Editor.string(editor, beforeRange);
      const beforeMatch = beforeText && beforeText.match(/^@(\w+)?$|\s@$/);
      const after = Editor.after(editor, start);
      const afterRange = Editor.range(editor, start, after);
      const afterText = Editor.string(editor, afterRange);
      const afterMatch = afterText.match(/^(\s|$)/);

      if(beforeMatch && afterMatch) {
        setTarget(beforeRange);
        setSearch(beforeMatch[1] || '');
        setIndex(0);
        return;
      }
    }

    setTarget(null);
  }, [editor, mentionOptions.length]);

  const onKeyDown = useCallback(event => {
    for (let { predicate, format } of editorHotkeys) {
      if (predicate(event)) {
        event.preventDefault();

        editor.toggleMark(format);
        return;
      }
    }

    if (target) {
      switch (event.key) {
        case 'ArrowDown': {
          event.preventDefault();
          const prevIndex = index >= users.length - 1 ? 0 : index + 1;

          setIndex(prevIndex);
          break;
        }

        case 'ArrowUp': {
          event.preventDefault();
          const nextIndex = index <= 0 ? users.length - 1 : index - 1;

          setIndex(nextIndex);
          break;
        }

        case 'Tab':
        case 'Enter':
          event.preventDefault();
          handleSelectUser(pick(users[index], ['id', 'name']));
          break;

        case 'Escape':
          event.preventDefault();

          setTarget(null);
          setSearch('');
          break;

        default:
          return;
      }
    }
  }, [index, target, handleSelectUser, users, editor]);

  useEffect(() => {
    if (target && users.length > 0) {
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();

      setAnchorPosition({
        top: rect.top + window.pageYOffset + 24,
        left: rect.left + window.pageXOffset
      });

      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [editor, index, search, target, users.length]);

  useEffect(() => {
    const { selection } = editor;
    if (ReactEditor.isFocused(editor) && !selection) {
      Transforms.select(editor, {
        anchor: { path: [0, 0], offset: 0 },
        focus: { path: [0, 0], offset: 0 }
      });
    }
  }, [editor]);

  const renderLeaf = useCallback(props => <Leaf {...props} />, []);

  return (
    <TextEditorContainer>
      <EditorWrapper>
        <Slate
          editor={editor}
          value={value}
          onChange={handleChange}
        >
          <EditableWrapper>
            <Editable
              placeholder="Add comment..."
              autoFocus
              onKeyDown={onKeyDown}
              renderLeaf={renderLeaf}
            />

            <div className="toolbar">
              <MarkButton
                format="bold"
                icon="format_bold"
                tooltip={`Bold ${hotkeysBasicKey}B`}
              />

              <MarkButton
                format="italic"
                icon="format_italic"
                tooltip={`Italic ${hotkeysBasicKey}I`}
              />

              <MarkButton
                format="underline"
                icon="format_underlined"
                tooltip={`Underline ${hotkeysBasicKey}U`}
              />

              <MarkButton
                format="clear"
                icon="format_clear"
                tooltip={`Clear ${hotkeysBasicKey}${'\\'}`}
              />
            </div>
          </EditableWrapper>
        </Slate>
      </EditorWrapper>

      <div className="editor-actions">
        <Button
          color="primary"
          disableRipple
          size="small"
          variant="contained"
          onClick={handleSave}
        >
          Save
        </Button>

        <Button
          disableRipple
          size="small"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>

      {Boolean(users.length) &&
        <Popover
          className={classes.popoverPaper}
          open={open}
          anchorReference="anchorPosition"
          anchorPosition={anchorPosition}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          hideBackdrop
          disableAutoFocus
          disableEnforceFocus
        >
          <UsersList
            onSelectUser={handleSelectUser}
            focusIndex={index}
            users={users}
          />
        </Popover>
      }
    </TextEditorContainer>
  );
}

TextEditor.propTypes = {
  contents: PropTypes.array,
  mentionOptions: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TextEditor);
