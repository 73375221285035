import React, { useState, useCallback, useRef, useEffect } from 'react';
import PT from 'prop-types';
import { gql, useMutation } from '@apollo/client';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import FileInfo from './FileInfo';
import DragAndDropArea from './DragAndDropArea';

const ADD_PENDING_FILES = gql`
  mutation AddTableItemFile($file: AddTableItemFileInput!) {
    addTableItemFile(file: $file) {
      id
      title
      processedFileDownloadURL
      rawFileDownloadURL
      rawFilename
    }
  }
`;

const MeasurementFile = ({
  fileInfo,
  error,
  onAddFile,
  onRemoveFile,
  defaultTitle,
  defaultDescription,
}) => {
  const [droppedFile, setDroppedFile] = useState();
  const [uploadFile, { loading }] = useMutation(ADD_PENDING_FILES);

  const abortControllerRef = useRef();

  useEffect(() => () => {
    abortControllerRef.current?.abort();
  }, []);

  const handleAddFile = useCallback(async (file) => {
    setDroppedFile(({ filename: file.name, size: file.size }));

    try {
      abortControllerRef.current = new AbortController();

      const { data } = await uploadFile({
        variables: {
          file: {
            title: defaultTitle || file.name,
            description: defaultDescription,
            rawFile: file,
          }
        },
        context: {
          fetchOptions: {
            signal: abortControllerRef.current.signal
          }
        }
      });

      abortControllerRef.current = null;

      onAddFile(data.addTableItemFile);
    } catch(e) {
      console.log(e);
    } finally {
      setDroppedFile(null);
    }
  }, [uploadFile, defaultTitle, defaultDescription, onAddFile]);

  const measurementFile = fileInfo ?? droppedFile;

  return (
    <Box>
      <Typography
        variant="caption"
        component="p"
        color={error ? '#d32f2f' : 'rgba(0,0,0,.6)'}
        mb="2px"
      >
        Measurement file
      </Typography>

      {measurementFile ?
        <FileInfo
          loading={loading}
          filename={measurementFile?.filename ?? measurementFile?.rawFilename}
          size={measurementFile?.size}
          onRemoveFile={onRemoveFile}
        /> :
        <DragAndDropArea onAddFile={handleAddFile} />
      }

      {error ?
        <Typography
          variant="caption"
          component="p"
          color="#d32f2f"
          mt="3px"
        >
          {error}
        </Typography> :
        null
      }
    </Box>
  );
};

MeasurementFile.propTypes = {
  fileInfo: PT.object,
  error: PT.string,
  defaultTitle: PT.string,
  defaultDescription: PT.string,
  onAddFile: PT.func,
  onRemoveFile: PT.func
};

export default MeasurementFile;
