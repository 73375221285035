import React from 'react';
import PropTypes from 'prop-types';

import AuthContainerStyled from '../../../components/AuthPageStyled/AuthContainerStyled';
import GoogleLogo from '../../../components/AuthPageStyled/GoogleLogo';
import SignInButton from '../../Login/SignInButton';

const SignInSocialProvider = props => {
  const handleSubmit = (providerName, config) => () => {
    props.onSubmit(providerName, config);
  };

  return (
    <AuthContainerStyled>
      <SignInButton
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleSubmit('google.com', { scope: ['email', 'profile'] })}
      >
        Sign up with Google
        <GoogleLogo width="20px" height="20px" />
      </SignInButton>
    </AuthContainerStyled>
  );
};

SignInSocialProvider.propTypes = {
  onSubmit: PropTypes.func.isRequired
};


export default SignInSocialProvider;
