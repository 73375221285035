import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { COLOR_PRIMARY } from '../../styles';

const TextWithLink = ({ children, link, linkProps = {} }) => {
  if (link)
    return (
      <Link
        style={{ color: COLOR_PRIMARY }}
        to={link}
        {...linkProps}
      >
        {children}
      </Link>
    );

  return (
    <>
      {children}
    </>
  );
};

TextWithLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  link: PropTypes.string,
  linkProps: PropTypes.object
};

export default TextWithLink;
