import styled from 'styled-components';

import ButtonBase from '@mui/material/ButtonBase';

import { COLOR_PRIMARY_DARK } from '../../../../styles';

export const IconButtonStyled = styled(ButtonBase).withConfig({
  shouldForwardProp: prop => !['active'].includes(prop)
})`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: ${({ active }) => active ? `1px solid ${COLOR_PRIMARY_DARK}` : 'none'};
  background-color: ${({ active }) => active ? `#E0F7FA` : 'white'};
  padding: 4px;
    
  svg {
    width: 16px;
    height: 16px;
    margin: 0;
    ${({ active }) => active && `
      color: ${COLOR_PRIMARY_DARK};
    `}

    ${({ disabled }) => disabled && `
      color: rgba(0, 0, 0, .38);
    `}
  }
`;
