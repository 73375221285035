import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

const RegularOption = ({
  data: { label, value, data },
  onClick
}) => {
  const handleClick = useCallback(() => {
    onClick(value, data);
  }, [data, onClick, value]);

  return (
    <MenuItem onClick={handleClick}>
      <ListItemText
        inset
        primaryTypographyProps={{
          noWrap: true,
          style: {
            fontSize: '14px',
            fontWeight: '400',
          }
        }}
      >
        {label}
      </ListItemText>
    </MenuItem>
  );
};

RegularOption.propTypes = {
  onClick: PropTypes.func.isRequired,
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
  })
};

export default RegularOption;
