import styled from 'styled-components';

const TableStyled = styled.table`
  border-collapse: collapse;
  flex: 1 0 auto;
  align-self: flex-start;
  border: none;
`;

export default TableStyled;
