import React from 'react';
import { useQuery } from '@apollo/client';

import PEROVSKITE_VIEWER from '../App/AppRouting/gql/perovskite-user';

import PerovskiteLanding from './PerovskiteLanding';
import RegularUserLanding from './RegularUserLanding';

import useDocumentTitle from '../../hooks/document-title';

const Home = () => {
  useDocumentTitle('Homepage');

  const { data, loading } = useQuery(PEROVSKITE_VIEWER);

  if(loading)
    return null; // TODO: find a better intermediate screen state

  const perovskiteUser = data?.viewer?.perovskiteUser;

  if (perovskiteUser)
    return (
      <PerovskiteLanding />
    );

  return (
    <RegularUserLanding />
  );
};

export default Home;
