import styled from 'styled-components';

import { COLOR_PRIMARY } from '../../../styles';

export default styled.div`
  .message {
    padding: 10px;
    border: 2px solid ${COLOR_PRIMARY};
  }
`;
