import React from 'react';

import TableHeader from './TableHeader';
import NameCell from './NameCell';
import UserNameCell from './UserNameCell';
import PermissionCell from './PermissionCell';
import ActionsCell from './ActionsCell';

export const columnIdsToAccessKeysMap = {
  name: 'fullName',
  username: 'name',
  permission: 'role',
  id: 'userId'
};

const columns = [
  {
    id: 'name',
    accessorKey: [columnIdsToAccessKeysMap.name],
    header: props => (
      <TableHeader
        {...props}
        text="Name"
        sortedBy={props.table.customState.sorting.state}
        onSort={props.table.customState.sorting.onSort}
      />
    ),
    cell: NameCell
  },
  {
    id: 'username',
    accessorKey: [columnIdsToAccessKeysMap.username],
    header: props =>
      <TableHeader
        {...props}
        text="User Name"
        sortedBy={props.table.customState.sorting.state}
        onSort={props.table.customState.sorting.onSort}
      />,
    cell: UserNameCell
  },
  {
    id: 'permission',
    accessorKey: [columnIdsToAccessKeysMap.permission],
    header: props =>
      <TableHeader
        {...props}
        text="Permission Level"
        sortedBy={props.table.customState.sorting.state}
        onSort={props.table.customState.sorting.onSort}
      />,
    cell: PermissionCell
  },
  {
    id: 'actions',
    accessorKey: [columnIdsToAccessKeysMap.id],
    header: props => <TableHeader {...props} width={18} noSorting />,
    cell: ActionsCell
  },
];

export default columns;
