import React, { useEffect, useCallback, useMemo } from 'react';
import PT from 'prop-types';
import omit from 'lodash/omit';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import LoadingButton from '@mui/lab/LoadingButton';

import Input from '../../FormElements/Input';
import Textarea from '../../FormElements/Textarea';
import MeasurementFile from '../../FormElements/MeasurementFile';
import ParsingConfigSelector from '../../FormElements/ParsingConfigSelector';
import Graphics from '../../FormElements/Graphics';

import useMeasurementForm, { FORM_FIELDS } from '../../hooks/use-measurement-form';
import useFetchProcessedData from '../../services/use-fetch-processed-data';
import useParseFile from '../../services/use-parse-file';
import { getGraphicsTypeByViewType } from '../../../../../../utils/graphics';
import { createBlob } from '../../../../../../utils';
import { BACKEND_GRAPHICS_TYPES } from '../../../../../../services/graphics/constants';

const EditMeasurement = ({
  className,
  data,
  loading,
  error,
  onCancel,
  onSubmit
}) => {
  const {
    state,
    actions: {
      setValue,
      setError,
      addGraphics,
      setGraphicsType,
      setGraphicsConfig,
      removeGraphics,
      validate
    }
  } = useMeasurementForm(data);
  const [fetchData] = useFetchProcessedData();
  const [parse, { loading: parsingLoading }] = useParseFile();

  useEffect(() => {
    (async (data) => {
      if(data.processedFileDownloadURL && data.parsingConfig?.parser.dataType) {
        const parsedData = await fetchData(
          data.processedFileDownloadURL,
          data.parsingConfig.parser.dataType,
          data.rawFilename
        );

        if(parsedData)
          setValue(FORM_FIELDS.PROCESSED_DATA, parsedData);
      }
    })(data);
  }, [data, fetchData, setValue]);

  const sourceFile = state[FORM_FIELDS.SOURCE_FILE].value;
  const parser = state[FORM_FIELDS.PARSING_CONFIG].value?.parser;
  const graphics = state[FORM_FIELDS.GRAPHICS].value;

  const handleTitleChange = useCallback(ev => {
    setValue(FORM_FIELDS.TITLE, ev.target.value);
  }, [setValue]);

  const handleDescriptionChange = useCallback(ev => {
    setValue(FORM_FIELDS.DESCRIPTION, ev.target.value);
  }, [setValue]);

  const handleAddChart = useCallback(viewType => {
    addGraphics(viewType);
  }, [addGraphics]);

  const handleParsingConfigSelect = useCallback(async (value) => {
    setValue(FORM_FIELDS.PARSING_CONFIG, value);

    const parsedData = await parse(sourceFile, value);

    if(parsedData) {
      setValue(FORM_FIELDS.PROCESSED_DATA, parsedData);
      handleAddChart(value.parser.viewType);
    } else {
      setError(FORM_FIELDS.PROCESSED_DATA, 'Failed to process the file');
    }
  }, [setValue, setError, handleAddChart, sourceFile, parse]);

  const handleGrahpicsTypeChange = useCallback((id, value) => {
    setGraphicsType(id, value);
  }, [setGraphicsType]);

  const handleGraphicsConfigChange = useCallback((id, value) => {
    setGraphicsConfig(id, value);
  }, [setGraphicsConfig]);

  const handleRemoveGraphics = useCallback(id => {
    removeGraphics(id);
  }, [removeGraphics]);

  const handleEditMeasurement = useCallback(() => {
    const isValid = validate();

    if(!isValid) return;

    // const sourceFile = state[FORM_FIELDS.SOURCE_FILE].value;
    const parsingConfig = state[FORM_FIELDS.PARSING_CONFIG].value;
    const processedData = state[FORM_FIELDS.PROCESSED_DATA].value;
    const graphics = state[FORM_FIELDS.GRAPHICS].value;

    const file = {
      title: state[FORM_FIELDS.TITLE].value,
      description: state[FORM_FIELDS.DESCRIPTION].value,
      parserConfigurationId: parsingConfig?.id,
      parserConfigurationCode: parsingConfig?.code,
      processedFile: processedData && createBlob(processedData),
      graphics: graphics
        .filter(
          ({ type }) => Object.hasOwn(BACKEND_GRAPHICS_TYPES, type)
        )
        .map(({ id, type, config }) => ({
          id,
          graphicsType: type,
          config: omit(config, 'axes'),
          mode: 'VIEW_MODE_GRAPH',
          alter: config?.axes ?
            Object
              .entries(config.axes)
              .map(([name, value]) => ({
                target: name,
                transform: value.transform
              }))
              .filter(({ transform }) => transform) :
            null
        }))
    };

    onSubmit(data.id, file);
  }, [data.id, state, validate, onSubmit]);

  const displayGraphics = useMemo(() => {
    return graphics?.length ?
      graphics :
      data.processedFileDownloadURL ?
        [{
          id: window.crypto.randomUUID(),
          type: getGraphicsTypeByViewType(
            data.parsingConfig.parser.viewType
          ),
          mode: 'VIEW_MODE_GRAPH'
        }] :
        null;
  }, [graphics, data]);

  return (
    <Box
      className={className}
      sx={{
        px: '16px',
        py: '12px',
        my: '16px',
        background: 'rgba(33, 33, 33, 0.05)',
        borderRadius: '7px',
        border: '1px solid #DCDBDC'
      }}
    >
      <Typography
        variant="body2"
        fontWeight="bold"
        mb="16px"
      >
        Edit measurement
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px'
        }}
      >
        <Input
          label="Title"
          value={state[FORM_FIELDS.TITLE].value}
          onChange={handleTitleChange}
          inputProps={{
            maxLength: 200
          }}
          required
          error={state[FORM_FIELDS.TITLE].error}
        />

        <Textarea
          label="Description"
          value={state[FORM_FIELDS.DESCRIPTION].value}
          onChange={handleDescriptionChange}
        />

        <MeasurementFile
          fileInfo={sourceFile}
        />

        <ParsingConfigSelector
          value={state[FORM_FIELDS.PARSING_CONFIG].value}
          filename={sourceFile.filename}
          onSelect={handleParsingConfigSelect}
        />

        {parsingLoading ?
          <LinearProgress /> :
          null
        }

        {state[FORM_FIELDS.PROCESSED_DATA].error ?
          <Typography
            variant="body2"
            my="8px"
            color="#d32f2f"
          >
            {state[FORM_FIELDS.PROCESSED_DATA].error}
          </Typography> :
          null
        }

        {(state[FORM_FIELDS.PROCESSED_DATA].value && displayGraphics.length) ?
          <Graphics
            viewType={parser.viewType}
            isComplex={parser.dataType === 'DATA_COMPLEX'}
            graphics={displayGraphics}
            data={state[FORM_FIELDS.PROCESSED_DATA].value}
            onGraphicsConfigChange={handleGraphicsConfigChange}
            onGraphicsTypeChange={handleGrahpicsTypeChange}
            onAddGraphics={handleAddChart}
            onRemoveGraphics={handleRemoveGraphics}
          /> :
          null
        }
      </Box>

      <Divider sx={{ my: '16px' }} />

      {error ?
        <Typography
          variant="body2"
          color="#d32f2f"
          mb="16px"
        >
          Failed to update the measurement.
        </Typography> :
        null
      }

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '16px'
        }}
      >
        <Button
          size="small"
          disabled={loading}
          onClick={onCancel}
        >
          Close
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          size="small"
          onClick={handleEditMeasurement}
        >
          Save measurement
        </LoadingButton>
      </Box>
    </Box>
  );
};

EditMeasurement.propTypes = {
  className: PT.string,
  data: PT.object,
  loading: PT.bool,
  error: PT.any,
  onCancel: PT.func,
  onSubmit: PT.func
};

export default EditMeasurement;
