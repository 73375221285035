/* eslint-disable */
import React, { useCallback } from 'react';
import PT from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { SelectStyled } from '../styles';

const BLANK_VALUE = '';

const options = [
  { value: BLANK_VALUE, label: 'Empty' },
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

const EditBooleanValue = ({ onChange, data }) => {
  const handleChange = useCallback((e) => {
    const { value } = e.target;

    onChange(value === BLANK_VALUE ? null : value, { withSubmit: true });
  }, [onChange]);

  return (
    <Box width="100%">
      <SelectStyled
        select
        value={data ?? BLANK_VALUE}
        autoFocus
        onChange={handleChange}
        SelectProps={{
          displayEmpty: true,
          defaultOpen: true,
          MenuProps: { disablePortal: true }
        }}
      >
        {options.map(i => (
          <MenuItem
            key={i.value}
            value={i.value}
          >
            <Typography variant="body2">
              {i.label}
            </Typography>
          </MenuItem>
        ))}
      </SelectStyled>
    </Box>
  );
};

EditBooleanValue.propTypes = {
  onChange: PT.func.isRequired,
  data: PT.bool
};

export default EditBooleanValue;
