import React, { useCallback, useState } from 'react';
import { useAnalytics } from 'use-analytics';

import Typography from '@mui/material/Typography';

import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';

import VideoHelperDialog from '../../../components/VideoHelperDialog';

import Container from './styles';

import VIDEO_LINKS from '../../../components/VideoHelperDialog/constants';
import { componentNames, TRACK_HELP_VIDEO } from '../../../analytics/constants';

const NoDataScreen = () => {

  const { track } = useAnalytics();

  const [videoLink, setVideoLink] = useState(null);

  const handleVideoDialogToggle = useCallback(() => {
    setVideoLink(state => !state ? VIDEO_LINKS.CREATE_TABLE : null);
  }, [setVideoLink]);

  const handleHoverLink = useCallback(() => {
    track(TRACK_HELP_VIDEO.hover, {
      component: componentNames.HELP,
      additional_info: {
        hover_object: 'table'
      }
    });
  }, [track]);

  return (
    <Container>
      <TableChartOutlinedIcon />

      <Typography>
        Select tables to view data
      </Typography>

      <Typography
        color="primary"
        sx={{
          textDecoration: 'underline',
          '&:hover': {
            cursor: 'pointer'
          }
        }}
        onClick={handleVideoDialogToggle}
        onMouseOver={handleHoverLink}
      >
        Learn more
      </Typography>

      <VideoHelperDialog
        link={videoLink}
        onClose={handleVideoDialogToggle}
      />
    </Container>
  );
};

export default NoDataScreen;
