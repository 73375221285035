import { gql } from '@apollo/client';

export const VIEWER_ID = gql`
  query ViewerOwner {
    viewer {
      id
    }
  }
`;

export const GET_ITEMS = gql`
  query TabularItems(
    $input: TableItemsQueryInput,
    $after: String,
    $first: Int,
  ) {
    tableItems(
      input: $input
      after: $after,
      first: $first
    ) {
      columnOptions {
        featureId
        notInCurrentFilterResult
        protocolTitle
        protocolType
        title
        protocolGroup {
          id
        }
      }
      itemsTable {
        edges {
          cursor
          node {
            item {
              id
              code
              title
              description
              dateCreated
              dateUpdated
              dateFabricated
              interactions {
                comments
              }
              creator {
                id
                name
                ... on CurrentUser {
                  userId
                  avatarColor
                  pictureDownloadURL
                }
              }
              table {
                id
              }
              numberOfTableItemFiles
              isUsedInProtocols
            },
            values {
              featureId
              tableItemId
              tableParameterId
              type

              ... on TableValueQuantity {
                quantity
              }

              ... on TableValueCalculation {
                quantity
                calculationError
              }

              ... on TableValueText {
                text
              }

              ... on TableValueBoolean {
                boolean
              }

              ... on TableValueLink {
                linkedTableItem {
                  id
                  code
                  title
                  viewerMaxRole
                }
              }

              ... on TableValueEnum {
                enumValues {
                  id
                  title
                }
              }
            }
          }
        }
        columns {
          featureId
          protocolTitle
          protocolType
          protocolGroup {
            id

            ...on ProtocolGroupFormulation {
              includedProtocols {
                id
                tableId
              }
            }
          }
          title
          valueType
          titleLinkId
          unitName
          titleLinkIsAccessible
          notInCurrentFilterResult
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
        }
        filterInfo {
          totalNumberOfFilteredItems
          totalNumberOfItems
        }
      }
      filterOptions {
        createdTimestamp {
          from
          to
        }
        creators {
          number
          userId
          userName
          firstName
          lastName
        }
        tables {
          id
          title
          number
          parentFolderId
          isFolder
        }
      }
      tableItemQueryId
    }
  }
`;

export const GET_TABLES = gql`
  query Tables {
    tables {
      id
      hash
      title
      description
      viewerMaxRole
      tableProtocols {
        id
      }
      folderId
      path {
        id
        title
      }
      tableProtocols {
        id
        type

        ... on TableProtocolFormulation {
          unit {
            id
            name
          }
        }
        tableParameters {
          id
          featureId
          titleTableItem {
            id
            title
          }
          valueType

          ... on TableParameterCalculation {
            calculation {
              contents {
                type
                children {
                  id
                  text
                  type
                  children {
                    text
                  }
                }
              }
            }
          }

          ... on TableParameterEnum {
            enumValueOptions {
              id
              title
            }
          }
        }
      }
      numberOfTableItems
    }
  }
`;

export const GET_FOLDERS = gql`
  query Folders {
    folders {
      id
      title
      viewerMaxRole
      parentId
      childFolders {
        id
      }
      childTables {
        id
      }
    }
  }
`;
