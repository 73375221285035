import {
  CLOSE_APP_SNACKBAR_NOTIFICATION,
  DELETE_APP_SNACKBAR_NOTIFICATION,
  OPEN_APP_SNACKBAR_NOTIFICATION
} from './constants';

const initialState = {
  snackbars: []
};

let snackbarId = 0;

export default function snackbarNotificationsReducer(state = initialState, action) {
  switch(action.type) {
    case OPEN_APP_SNACKBAR_NOTIFICATION:
      return createSnackbar(state, action, action.variant);

    case CLOSE_APP_SNACKBAR_NOTIFICATION:
      return closeAppSnackbarNotification(state, action);

    case DELETE_APP_SNACKBAR_NOTIFICATION:
      return deleteAppSnacbarNotification(state, action);

    default:
      return state;
  }
}

function closeAppSnackbarNotification(state, action) {
  const { snackbars } = state;
  const snackbarIndex = snackbars.findIndex(({ id }) => id === action.id);

  if(snackbarIndex === -1)
    return state;

  const newSnackbarState = {
    ...snackbars[snackbarIndex],
    open: false
  };

  return {
    ...state,
    snackbars: [
      ...snackbars.slice(0, snackbarIndex),
      newSnackbarState,
      ...snackbars.slice(snackbarIndex + 1)
    ]
  };
}

function deleteAppSnacbarNotification(state, action) {
  const { snackbars } = state;
  const snackbarIndex = snackbars.findIndex(({ id }) => id === action.id);

  if(snackbarIndex === -1)
    return state;

  return {
    ...state,
    snackbars: [
      ...snackbars.slice(0, snackbarIndex),
      ...snackbars.slice(snackbarIndex + 1)
    ]
  };
}

function createSnackbar(state, action, type = 'INFO') {
  const id = action.id || snackbarId++;

  return {
    ...state,
    snackbars: state.snackbars.concat({
      id,
      type,
      open: true,
      message: action.message,
      origin: action.origin
    })
  };
}
