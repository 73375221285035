import React from 'react';
import PT from 'prop-types';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';

import SingleTable from './SingleTable';

const TablesList = ({ selectedTables, tablesError, onTableRemove, onToggle }) => {

  return (
    <Box>
      <Typography
        variant="body2"
        fontSize="12px"
        color={tablesError ? 'error' : 'rgba(0,0,0,.6)'}
      >
        Select components from:
      </Typography>

      {selectedTables.map(table => (
        <SingleTable
          key={table.id}
          table={table}
          onDelete={onTableRemove}
        />
      ))}

      <Button
        startIcon={<AddCircleOutlineIcon />}
        onClick={onToggle}
      >
        <Typography
          sx={{
            textTransform: 'none',
          }}
        >
          Add/remove tables
        </Typography>
      </Button>

      {tablesError ?
        <FormHelperText sx={{ ml: 0 }} error>
          {tablesError}
        </FormHelperText> :
        null
      }
    </Box>
  );
};

TablesList.propTypes = {
  selectedTables: PT.array,
  tablesError: PT.string,
  onTableRemove: PT.func.isRequired,
  onToggle: PT.func.isRequired,
};

export default TablesList;
