import React, { useState, useCallback, useMemo } from 'react';
import PT from 'prop-types';
import { gql, useQuery, useMutation } from '@apollo/client';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import { hasWriteAccess } from '../../utils/roles';
import { GET_TABLES } from '../../scenes/AllItems/services';

const TABLES_QUERY = gql`
  query Tables {
    tables {
      id
      title
      viewerMaxRole
    }
  }
`;

const CREATE_TABLE_ITEM = gql`
  mutation CreateTableItem($tableId: ID!, $data: TableItemInput!) {
    createTableItem(tableId: $tableId, data: $data) {
      id
      code
      title
      description
      dateCreated
      dateUpdated
      dateFabricated
      interactions {
        comments
      }
      creator {
        id
        name
        ... on CurrentUser {
          avatarColor
          pictureDownloadURL
          userId
        }
      }
      table {
        id
        title
        viewerMaxRole
        tableParameters {
          id
          featureId
          valueType
        }
      }
      numberOfTableItemFiles
      isUsedInProtocols
      values {
        featureId
        tableItemId
        tableParameterId
        type

        ... on TableValueQuantity {
          quantity
        }

        ... on TableValueCalculation {
          quantity
          calculationError
        }

        ... on TableValueText {
          text
        }

        ... on TableValueBoolean {
          boolean
        }

        ... on TableValueLink {
          linkedTableItem {
            id
            code
            title
            viewerMaxRole
          }
        }
      }
    }
  }
`;

const sx = {
  label: {
    transform: 'translate(0px, 0px) scale(1)',
    fontSize: '12px',
    lineHeight: '20px',
    color: 'rgba(0,0,0,.6)'
  },
  input: {
    mt: '22px',
    px: '10px',
    py: '6px',
    background: 'white',
    border: '1px solid #DCDBDC',
    borderRadius: '4px',
    '.MuiInputBase-input': {
      padding: 0
    },
    fontSize: '14px'
  },
  textarea: {
    mt: '22px',
    px: '10px',
    py: '6px',
    background: 'white',
    border: '1px solid #DCDBDC',
    borderRadius: '4px',
    '.MuiInputBase-input': {
      padding: 0
    },
    fontSize: '14px'
  }
};

const CreateItemDialog = ({
  initialTitle,
  initialTable,
  tableIds,
  onClose,
  onSuccess
}) => {
  const [title, setTitle] = useState(initialTitle ?? '');
  const [description, setDescription] = useState('');
  const [table, setTable] = useState(initialTable ?? null);
  const [error, setError] = useState({ field: null, message: null });

  const { data } = useQuery(TABLES_QUERY, {
    fetchPolicy: 'cache-and-network'
  });

  const [createItem, { loading }] = useMutation(CREATE_TABLE_ITEM, {
    refetchQueries: [GET_TABLES]
  });

  const tableOptions = useMemo(() => {
    if(!data?.tables)
      return [];

    return data.tables
      .filter(({ id, viewerMaxRole }) => {
        if(Array.isArray(tableIds) && !tableIds.includes(id))
          return false;

        return hasWriteAccess(viewerMaxRole);
      })
      .map(({ id, title, ...rest }) => ({ id, label: title, ...rest }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [data, tableIds]);

  const dialogTitle = useMemo(() => {
    if(initialTable)
      return `Add item to ${initialTable.title}`;

    return 'Create new item';
  }, [initialTable]);

  const handleTitleChange = useCallback(ev => {
    setTitle(ev.target.value);
    setError({ field: null, message: null });
  }, []);

  const handleDescriptionChange = useCallback(ev => {
    setDescription(ev.target.value);
  }, []);

  const handleSelectTeam = useCallback((ev, value, reason) => {
    if(reason === 'selectOption')
      setTable(value);

    setError({ field: null, message: null });
  }, []);

  const handleSubmit = useCallback(async () => {
    if(!title.trim()) {
      setError({ field: 'TITLE' });
      return;
    }

    if(!table) {
      setError({ field: 'TABLE' });
      return;
    }

    try {
      const response = await createItem({
        variables: {
          tableId: table.id,
          data: {
            title,
            description
          }
        }
      });

      onSuccess(response.data.createTableItem);
    } catch(e) {
      setError({ field: 'GENERAL', message: e.message });
    }
  }, [title, description, table, createItem, onSuccess]);

  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
      onClose={onClose}
    >
      <DialogTitle>
        {dialogTitle}
      </DialogTitle>

      <DialogContent
        sx={{
          '&.MuiDialogContent-root': {
            pt: '10px'
          }
        }}
      >
        <FormControl
          fullWidth
          required
          error={error.field === 'TITLE'}
        >
          <InputLabel
            variant="outlined"
            disableAnimation
            shrink
            sx={sx.label}
          >
            Title
          </InputLabel>

          <InputBase
            value={title}
            inputProps={{
              maxLength: 200
            }}
            sx={sx.input}
            onChange={handleTitleChange}
            autoFocus
          />

          {error.field === 'TITLE' ?
            <FormHelperText sx={{ mx: 0 }}>
              The "Title" field is required
            </FormHelperText> :
            null
          }
        </FormControl>

        <FormControl
          fullWidth
          error={error.field === 'DESCRIPTION'}
          sx={{
            mt: '16px'
          }}
        >
          <InputLabel
            variant="outlined"
            disableAnimation
            shrink
            sx={sx.label}
          >
            Description
          </InputLabel>

          <InputBase
            value={description}
            onChange={handleDescriptionChange}
            multiline
            minRows="2"
            sx={sx.textarea}
          />
        </FormControl>

        {initialTable ?
          null :
          <Autocomplete
            value={table}
            options={tableOptions}
            renderInput={params => (
              <FormControl
                fullWidth
                required
                error={error.field === 'TABLE'}
                sx={{
                  mt: '16px'
                }}
              >
                <InputLabel
                  {...params.InputLabelProps}
                  variant="outlined"
                  disableAnimation
                  shrink
                  sx={sx.label}
                >
                  Table
                </InputLabel>

                <InputBase
                  {...params.InputProps}
                  fullWidth
                  sx={sx.input}
                  inputProps={{
                    ...params.inputProps
                  }}
                />

                {error.field === 'TABLE' ?
                  <FormHelperText sx={{ mx: 0 }}>
                    The "Table" field is required
                  </FormHelperText> :
                  null
                }
              </FormControl>
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
              <ListItem
                {...props}
                key={option.id}
                dense
              >
                <ListItemText>{option.label}</ListItemText>
              </ListItem>
            )}
            onChange={handleSelectTeam}
          />
        }

        {error.field === 'GENERAL' ?
          <FormHelperText sx={{ mx: 0, mt: '8px' }} error>
            {error.message}
          </FormHelperText> :
          null
        }
      </DialogContent>

      <DialogActions
        sx={{
          p: '16px'
        }}
      >
        <Button
          onClick={onClose}
        >
          Cancel
        </Button>

        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          loading={loading}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

CreateItemDialog.propTypes = {
  initialTitle: PT.string,
  initialTable: PT.shape({
    id: PT.string.isRequired,
    title: PT.string
  }),
  tableIds: PT.arrayOf(PT.string),
  onClose: PT.func,
  onSuccess: PT.func
};

export default CreateItemDialog;
