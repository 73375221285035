import { isObject, isPlainObject } from 'lodash';
import {
  REQUEST,
  SUCCESS,
  CACHED_SUCCESS,
  FAILURE,
  CHILD_ADDED,
  CHILD_REMOVED,
  CHILD_CHANGED,
  CANCEL,
  NEXT,
  PAUSE,
  RESUME
} from './constants';

export function createRequestTypes(base, opts = {}) {
  let types = [REQUEST, SUCCESS, FAILURE];

  if (opts.listenList) {
    types = [...types, CHILD_ADDED, CHILD_REMOVED, CHILD_CHANGED, CANCEL];
  }

  if (opts.upload) {
    types = [...types, NEXT, CANCEL, PAUSE, RESUME];
  }

  if (opts.useCache) {
    types = [...types, CACHED_SUCCESS];
  }

  return types.reduce((result, type) => {
    result[type] = `${base}_${type}`;
    return result;
  }, {});
}

export function action(type, payload = {}) {
  if (!isObject(payload)) {
    throw new Error('Payload as object is required');
  }

  return { type, ...payload };
}

export const createHandlers = (types, opts = {}) => {
  const result = {
    request: (id, params, rest) => {
      if (rest !== undefined) {
        throw new Error('createHandlers: action should apply only 2 arguments');
      }

      if (params && !isPlainObject(params)) {
        throw new Error('createHandlers: action should apply only object as 2d argument');
      }

      let data = {};

      if (typeof id === 'string' || Array.isArray(id)) {
        data.id = id;

        if (params) {
          data.params = params;
        }
      } else if (isObject(id)) {
        if (params) {
          throw new Error('createHandlers: action should apply only one params object');
        }

        data = { ...id };
      }

      return action(types[REQUEST], data);
    },
    success: response => action(types[SUCCESS], { response }),
    failure: error => action(types[FAILURE], { error })
  };

  if (opts.upload) {
    Object.assign(result, {
      next: data => action(types[NEXT], { data }),
      cancel: data => action(types[CANCEL], { data }),
    });
  }

  return result;
};
