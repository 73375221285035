import React from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

const Option = ({
  className,
  children,
  ...optionProps
}) => {
  return (
    <li className={className} {...optionProps}>
      {children}
    </li>
  );
};

Option.propTypes = {
  className: PT.string,
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node
  ])
};

export default styled(Option)`
  padding: 6px;
`;
