import styled from 'styled-components';
import Box from '@mui/material/Box';

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const Content = styled(Box)`
  display: flex;
  flex-direction: column;
`;
