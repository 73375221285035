import get from 'lodash/get';
import {
  REQUEST,
  SUCCESS,
  FAILURE
} from '../constants';

import * as actions from '../actions';

const initialState = {};

export default function objectsOrder(state = initialState, action) {
  switch(action.type) {
    case actions.fetchSampleObjectsOrderTypes[SUCCESS]:
      return {
        [action.response.sampleId]: {
          present: action.response.objectsOrder
        }
      };

    case actions.saveObjectsOrderTypes[REQUEST]:
      return saveObjectsOrderRequest(state, action);

    case actions.saveObjectsOrderTypes[SUCCESS]:
      return saveObjectsOrderSuccess(state, action);

    case actions.saveObjectsOrderTypes[FAILURE]:
      return saveObjectsOrderFailure(state, action);

    default:
      return state;
  }
}

function saveObjectsOrderRequest(state, action) {
  const past = get(state, `${action.data.sampleId}.present`, []);

  return {
    ...state,
    [action.data.sampleId]: {
      present: action.data.objectsOrder,
      past
    }
  };
}

function saveObjectsOrderSuccess(state, action) {
  return {
    ...state,
    [action.response.sampleId]: {
      ...state[action.response.sampleId],
      past: []
    }
  };
}

function saveObjectsOrderFailure(state, action) {
  const present = get(state, `${action.error.sampleId}.past`, []);

  return {
    ...state,
    [action.error.sampleId]: {
      present,
      past: []
    }
  };
}
