import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import zip from 'lodash/zip';
import min from 'lodash/min';
import max from 'lodash/max';
import isEqual from 'lodash/isEqual';
import flatten from 'lodash/flatten';

import ChartContainer from './ChartContainer';
import ErrorMessage from './ErrorMessage';

import mapValidData from '../../../utils/graphics/validate-columns';
import CustomLogTickFormatter from '../../../utils/graphics/custom-log-tick-formatter';

const COLORS = ['steelblue', '#8bc34a', '#ff9800', '#f44336', '#607d8b', '#673ab7', '#00bcd4', '#ff5722', '#e91e63', '#795548', '#4caf50', '#2196f3', '#9c27b0', '#ffc107', '#03a9f4', '#3f51b5', '#cddc39', '#009688'];
const setComputeColor = (count = 0) => () => COLORS[(count++) % COLORS.length];

class Linechart extends React.Component {
  constructor(props) {
    super(props);

    this.state = { error: false };
  }

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate(prevProps) {
    const { xAxis, yAxis, yAxisRight, axes } = this.props;
    const conditions = [
      !isEqual(xAxis, prevProps.xAxis),
      !isEqual(yAxis, prevProps.yAxis),
      !isEqual(yAxisRight, prevProps.yAxisRight),
      !isEqual(axes, prevProps.axes)
    ];

    if(conditions.some(Boolean)) {
      this.chartContainerRef.replaceChildren();

      this.renderChart();
    }
  }

  renderChart() {
    if(!Array.isArray(this.props.data) || !Array.isArray(this.props.data[0])) {
      console.warn('INVALID INPUT DATA TYPE FOR LINECHART:', this.props.data);

      this.setState({ error: true });

      return;
    }

    let {
      preview,
      xAxis,
      yAxis,
      axes
    } = this.props;

    let yAxisRight = this.props.yAxisRight || [];

    const Bokeh = window.Bokeh;

    const plot_width = preview ? 150 : void 0;
    const plot_height = preview ? 150 : 354 + (yAxis.length + yAxisRight.length) * 17;
    const match_aspect = preview ? void 0 : true;
    const sizing_mode = preview ? void 0 : 'stretch_both';
    const toolbar_location = preview ? null : 'right';

    const titles = this.props.data[0];
    const data = mapValidData(titles, this.props.data.slice(1));

    const tooltips = preview ? void 0 : `
      <div>
        <div><span style="color: #26aae1; font-size: 10px;">${titles[xAxis]}:</span> @x</div>
        <div><span style="color: #26aae1; font-size: 10px;">$name:</span> @$name</div>
      </div>
    `;

    const code = `
      const tooltips = document.querySelectorAll(".bk-tooltip-custom")
      let index = 0

      //Hiding extra tooltips that show a lot of data
      for(let i = 0; i < tooltips.length; i++){
        if(index % 2 !== 0){
          tooltips[index].style['display'] = 'none'
        }
        index++
      }
    `;
    const callback = preview ? void 0 : new Bokeh.CustomJS({ code });

    const hoverTool = new Bokeh.HoverTool({ tooltips, callback });

    const tools = preview ? void 0 : [
      hoverTool,
      new Bokeh.PanTool(),
      new Bokeh.BoxZoomTool(),
      new Bokeh.SaveTool(),
      new Bokeh.ResetTool()
    ];

    let x;
    if(get(axes, 'xAxis.transform') === 'log'){
      x = data.map(row => Number.parseFloat(row[xAxis]) > 0 ? Number.parseFloat(row[xAxis]) : Number.NaN);
    } else {
      x = data.map(row => Number.parseFloat(row[xAxis]));
    }

    let ys, ysRight;
    if(get(axes, 'yAxis.transform') === 'log'){
      ys = zip(...data.map(row => yAxis.map(index => Number.parseFloat(row[index]) > 0 ? Number.parseFloat(row[index]) : Number.NaN)));
      ysRight = zip(...data.map(row => yAxisRight.map(index => Number.parseFloat(row[index]) > 0 ? Number.parseFloat(row[index]) : Number.NaN)));
    } else {
      ys = zip(...data.map(row => yAxis.map(index => Number.parseFloat(row[index]))));
      ysRight = zip(...data.map(row => yAxisRight.map(index => Number.parseFloat(row[index]))));
    }

    const ysTitles = yAxis.map(index => titles[index]);
    const ysRightTitles = yAxisRight.map(index => titles[index]);

    const x_range = new Bokeh.Range1d({ start: min(x), end: max(x) });
    const y_range = new Bokeh.Range1d({ start: min(flatten(ys)), end: max(flatten(ys)) });

    const dataCDS = { x };

    ys.forEach((coords, i) => {
      dataCDS[ysTitles[i]] = coords;
    });

    ysRight.forEach((coords, i) => {
      dataCDS[ysRightTitles[i]] = coords;
    });

    let source = new Bokeh.ColumnDataSource({
      data: dataCDS
    });

    let axesTypes = {};

    if(get(axes, 'xAxis.transform') === 'log')
      axesTypes.x_axis_type = 'log';
    if(get(axes, 'yAxis.transform') === 'log')
      axesTypes.y_axis_type = 'log';

    const plot = new Bokeh.Plotting.figure({
      x_range,
      y_range,
      // plot sizing config
      width: plot_width,
      height: plot_height,
      match_aspect,
      sizing_mode,

      // axis type
      ...axesTypes,

      // toolbar config
      toolbar_location,
      tools,

      output_backend: 'webgl'
    });

    plot.toolbar.active_inspect = null;
    plot.toolbar.active_scroll = null;
    plot.toolbar.active_tap = null;
    plot.toolbar.active_drag = null;

    const computeColor = setComputeColor();
    ys.forEach((coords, i) => {
      const color = computeColor();

      const lineGlyph = plot.line(
        { field: 'x' },
        { field: ysTitles[i] },
        {
          source,
          line_color: color,
          line_width: 1,
          legend: ` ${ysTitles[i]}`
        }
      );

      const circleGlyph = plot.circle(
        { field: 'x' },
        { field: ysTitles[i] },
        {
          source,
          size: 4,
          line_color: color,
          line_width: 1,
          fill_color: 'white',
          legend: ` ${ysTitles[i]}`
        }
      );

      lineGlyph.name = ysTitles[i];
      circleGlyph.name = ysTitles[i];
    });

    if(ysRight.length) {
      plot.extra_y_ranges.yAxisRight = new Bokeh.Range1d({
        start: min(flatten(ysRight)),
        end: max(flatten(ysRight))
      });

      ysRight.forEach((coords, i) => {
        const color = computeColor();

        const lineGlyph = plot.line(
          { field: 'x' },
          { field: ysRightTitles[i] },
          {
            source,
            line_color: color,
            line_width: 1,
            legend: ` ${ysRightTitles[i]}`
          }
        );

        const circleGlyph = plot.circle(
          { field: 'x' },
          { field: ysRightTitles[i] },
          {
            source,
            size: 4,
            line_color: color,
            line_width: 1,
            fill_color: 'white',
            legend: ` ${ysRightTitles[i]}`
          }
        );

        lineGlyph.y_range_name = 'yAxisRight';
        lineGlyph.name = ysRightTitles[i];

        circleGlyph.y_range_name = 'yAxisRight';
        circleGlyph.name = ysRightTitles[i];
      });

      if(get(axes, 'yAxis.transform') === 'log')
        plot.add_layout(new Bokeh.LogAxis({ y_range_name: 'yAxisRight' }), 'right');
      else
        plot.add_layout(new Bokeh.LinearAxis({ y_range_name: 'yAxisRight' }), 'right');
    }

    plot.legend.location = 'center';
    plot.legend.background_fill_alpha = .6;
    plot.legend.label_text_font_size = '10px';
    plot.legend.label_height = 12;
    plot.legend.glyph_height = 12;

    plot.add_layout(plot.legend, 'below');

    if(preview) {
      plot.xaxis[0].visible = false;
      plot.yaxis.forEach(axis => {
        axis.visible = false;
      });

      plot.legend.visible = false;
    } else {
      plot.toolbar.logo = null;

      plot.xaxis[0].axis_label = titles[xAxis];
      plot.yaxis[0].axis_label = titles[yAxis[0]];

      if(get(axes, 'xAxis.transform') === 'log') {
        plot.xaxis[0].formatter = new CustomLogTickFormatter();
      } else if(get(axes, 'yAxis.transform') === 'log') {
        plot.yaxis[0].formatter = new CustomLogTickFormatter();

        if(yAxisRight.length)
          plot.yaxis[1].formatter = new CustomLogTickFormatter();
      }

      if(yAxisRight.length) {
        plot.yaxis[1].axis_label = titles[yAxisRight[0]];
      }
    }

    Bokeh.Plotting.show(plot, this.chartContainerRef);

    hoverTool.active = true;
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorMessage>
          Unable to display graphics. Please try to change graphics settings.
        </ErrorMessage>
      );
    }

    return (
      <ChartContainer
        ref={ref => { this.chartContainerRef = ref; }}
        className={this.props.preview && 'preview'}
      />
    );
  }
}

Linechart.propTypes = {
  data: PropTypes.array.isRequired,
  preview: PropTypes.bool,
  xAxis: PropTypes.number,
  yAxis: PropTypes.arrayOf(PropTypes.number),
  yAxisRight: PropTypes.arrayOf(PropTypes.number),
  axes: PropTypes.object
};

Linechart.defaultProps = {
  xAxis: 0,
  yAxis: [1],
  yAxisRight: []
};

export default Linechart;
