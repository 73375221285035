import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { COLOR_PRIMARY } from '../../../../../../styles';
import { Container } from './styles';

import { routes } from '../../../../../../services/session/constants';
import { rolesIds } from '../../../../../../utils/roles';


const LinkValue = ({ value }) => {
  const noAccess = value?.viewerMaxRole === rolesIds.NO_ACCESS;

  const titleProps = noAccess ?
    {
      color: '#9e9e9e',
      sx: { textDecoration: 'underline' }
    } :
    {
      color: COLOR_PRIMARY,
      component: Link,
      to: value?.id ?
        generatePath(routes.ITEMS, { sampleId: value?.id })
        : null,
      target: '_blank',
      onClick: ev => ev.stopPropagation()
    };

  return (
    <Container>
      {(value?.id && !noAccess) ?
        <OpenInNewIcon
          color="primary"
          sx={{
            height: '18px'
          }}
        /> :
        null
      }

      <Tooltip
        title={noAccess ? 'Inaccessible item' : value?.title}
        placement="top"
      >
        <Typography
          variant="body2"
          {...titleProps}
        >
          {value?.title}
        </Typography>
      </Tooltip>

      {value?.code ?
        <Typography
          minWidth="fit-content"
          variant="body2"
        >
          ({value?.code})
        </Typography> :
        null
      }
    </Container>
  );
};

LinkValue.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    code: PropTypes.string,
    viewerMaxRole: PropTypes.string
  })
};

export default LinkValue;
