import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import mapValues from 'lodash/mapValues';

import makeStyles from '@mui/styles/makeStyles';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import { minValueValidator, maxValueValidator } from './utils';

const spinButton = {
  display: 'none',
  '-webkit-appearance': 'none',
  margin: 0
};

const useStyles = makeStyles({
  textField: {
    width: 107,

    '& .MuiInputBase-input': {
      height: 32,
      padding: 0,
      fontSize: 14,
      textAlign: 'center'
    },

    '& .MuiInputBase-root': {
      paddingLeft: 7
    },

    '& .MuiInputBase-input::-webkit-outer-spin-button': spinButton,
    '& .MuiInputBase-input::-webkit-inner-spin-button': spinButton,

    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    }
  },

  startAdornmentTypography: {
    fontSize: 14
  }
});

const MIN_FIELD_TYPE = 'min';
const MAX_FIELD_TYPE = 'max';

const VALIDATORS_BY_TYPE_MAP = {
  [MIN_FIELD_TYPE]: minValueValidator,
  [MAX_FIELD_TYPE]: maxValueValidator
};

const RangeInput = ({ value: valueProp, onChange, parameter, onError }) => {
  const classes = useStyles();
  const lastTouchRef = useRef();

  const validatorsByType = mapValues(
    VALIDATORS_BY_TYPE_MAP,
    v => v({ parameter, values: valueProp })
  );

  const handleQuantityInputChange = useCallback(type => e => {
    lastTouchRef.current = type;

    const { value } = e.target;

    onChange({
      ...valueProp,
      [type]: value
    });
  }, [onChange, valueProp]);

  const minValueValidator = validatorsByType[MIN_FIELD_TYPE];
  const maxValueValidator = validatorsByType[MAX_FIELD_TYPE];
  const minValueValidation = minValueValidator(valueProp.min);
  const maxValueValidation = maxValueValidator(valueProp.max);
  const minValueError = minValueValidation ?? '';
  const maxValueError = maxValueValidation ?? '';

  const errorMessage = lastTouchRef.current === MIN_FIELD_TYPE
    ? (minValueError || maxValueError)
    : (maxValueError || minValueError);

  useEffect(() => {
    onError(errorMessage);
  }, [errorMessage, onError]);

  return (
    <Box position="relative">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <TextField
            placeholder={parameter?.quantityMin?.toString() ?? null}
            error={Boolean(minValueError)}
            disabled={!parameter}
            className={classes.textField}
            value={valueProp[MIN_FIELD_TYPE]}
            type="number"
            onChange={handleQuantityInputChange(MIN_FIELD_TYPE)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography className={classes.startAdornmentTypography}>
                    Min:
                  </Typography>
                </InputAdornment>
              )
            }}
          />
        </Box>

        <TextField
          placeholder={parameter?.quantityMax?.toString() ?? null}
          error={Boolean(maxValueError)}
          value={valueProp[MAX_FIELD_TYPE]}
          disabled={!parameter}
          className={classes.textField}
          type="number"
          onChange={handleQuantityInputChange(MAX_FIELD_TYPE)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography className={classes.startAdornmentTypography}>
                  Max:
                </Typography>
              </InputAdornment>
            )
          }}
        />
      </Box>
    </Box>
  );
};

RangeInput.propTypes = {
  onError: PropTypes.func.isRequired,
  parameter: PropTypes.object,
  value: PropTypes.shape({
    min: PropTypes.string,
    max: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default RangeInput;
