const toParametersByIds = (items) =>
  items?.reduce((acc, i) => ({
    ...acc,
    ...i.features.reduce((fAcc, f) => ({
      ...fAcc,
      [f.id]: f
    }), {})
  }), {});

const toValuesByIds = (options) => {
  return Object.keys(options).reduce((acc, i) => {
    switch (i) {
      case 'creators': {
        return {
          ...acc,
          'creatorIds': options[i]
        };
      }
      case 'createdTimestamp': {
        return {
          ...acc,
          'createdTimestamp': options[i]
        };
      }
      case 'parameters': {
        return {
          ...acc,
          ...toParametersByIds(options[i])
        };
      }
      default:
        return acc;
    }
  }, {});
};

export default toValuesByIds;
