import { gql } from '@apollo/client';

const fragments = {
  emailNotificationSettings: gql`
    fragment EmailNotificationSettings on Viewer {
      id
      emailNotificationSettings {
        tableSharing
        itemComments {
          general
        }
      }
    }
  `
};

export const GET_NOTIFICATION_PREFERENCES = gql`
  query GetNotificationPreferences {
    viewer {
      ...EmailNotificationSettings
    }
  }
  ${fragments.emailNotificationSettings}
`;

export const CHANGE_NOTIFICATION_SETTINGS = gql`
  mutation ChangeNotificationSettings($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      ...EmailNotificationSettings
    }
  }
  ${fragments.emailNotificationSettings}
`;
