import React, {
  useCallback,
  useMemo,
  useRef,
  useEffect,
  memo,
} from 'react';
import { useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isToday from 'date-fns/isToday';

import LoadingBanner from '../../../../../components/LoadingBanner';
import Scroll from '../../../../../components/Scroll';
import { NOTIFICATION_TYPES } from '../../../../../components/Notifications';
import List from './List';
import EmptyList from './EmptyList';
import ErrorMessage from './ErrorMessage';
import ListContainerHead from './ListContainerHead';

import {
  GET_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  SET_READ_NOTIFICATION,
  DELETE_ALL_NOTIFICATIONS,
  SET_READ_ALL_NOTIFICATIONS
} from '../services';
import { COLOR_PRIMARY, COLOR_WHITE } from '../../../../../styles';
import ListContainerStyled from './styles';

const ListContainer = ({
  onItemRemove,
  onItemRead,
  onRemoveAll,
  onReadAll,
}) => {
  const rootElementRef = useRef(null);

  const [deleteNotification, {
    error: deleteItemError,
  }] = useMutation(DELETE_NOTIFICATION);

  const [markItemRead, {
    error: markItemReadError,
  }] = useMutation(SET_READ_NOTIFICATION);

  const [deleteAll, {
    error: deleteAllError,
    loading: deletingAllItems
  }] = useMutation(DELETE_ALL_NOTIFICATIONS);

  const [readAll, {
    error: readAllError,
    loading: readingAllItems
  }] = useMutation(SET_READ_ALL_NOTIFICATIONS);

  const {
    loading,
    data,
    fetchMore,
    updateQuery,
    error: fetchNotificationsError,
    refetch
  } = useQuery(GET_NOTIFICATIONS, {
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const items = get(data, 'notifications.edges', [])
    .filter(i => NOTIFICATION_TYPES.includes(i.node.eventType))
    .map(i => i.node);

  const isAllItemsRead = useMemo(() =>
    !items.some(i => !i.read),
  [items]
  );

  const handleItemClear = useCallback(async (id, read) => {
    await deleteNotification({
      variables: { id }
    });

    updateQuery(state => ({
      ...state,
      notifications: {
        ...state.notifications,
        edges: state.notifications.edges.filter(i => i.cursor !== id)
      }
    }));

    if (read === false) {
      onItemRemove();
    }
  }, [onItemRemove, deleteNotification, updateQuery]);

  const handleItemRead = useCallback(async (id, value) => {
    await markItemRead({
      variables: {
        id,
        read: value
      }
    });

    updateQuery(state => ({
      ...state,
      notifications: {
        ...state.notifications,
        edges: state.notifications.edges.map(i => {
          if (i.cursor === id) {
            return {
              ...i,
              node: {
                ...i.node,
                read: value
              }
            };
          }

          return i;
        })
      }
    }));

    onItemRead(value);
  }, [onItemRead, updateQuery, markItemRead]);

  const handleClearAll = useCallback(async () => {
    await deleteAll();

    updateQuery(state => ({
      ...state,
      notifications: {
        ...state.notifications,
        edges: []
      }
    }));

    onRemoveAll();
  }, [onRemoveAll, deleteAll, updateQuery]);

  const handleReadAll = useCallback(async () => {
    await readAll();

    updateQuery(state => ({
      ...state,
      notifications: {
        ...state.notifications,
        edges: state.notifications.edges.map(i => ({
          ...i,
          node: {
            ...i.node,
            read: true
          }
        }))
      }
    }));

    onReadAll();
  }, [onReadAll, readAll, updateQuery]);

  const onLoadMore = useCallback(() => {
    const { endCursor, hasNextPage } = get(data, 'notifications.pageInfo', {});

    if (!hasNextPage) { return; }

    fetchMore({
      variables: {
        after: endCursor
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const { edges: newEdges, pageInfo } = fetchMoreResult.notifications;

        return previousResult.notifications.pageInfo.hasNextPage
          ? {
            notifications: {
              __typename: previousResult.notifications.__typename,
              edges: [...previousResult.notifications.edges, ...newEdges],
              pageInfo
            }
          }
          : previousResult;
      }
    });
  }, [data, fetchMore]);

  const error = [
    fetchNotificationsError,
    deleteItemError,
    markItemReadError,
    deleteAllError,
    readAllError
  ].some(Boolean);

  if (error) return <ErrorMessage />;

  const isListEmpty = !loading && !items.length;
  const todayItems = items.filter(i => isToday(i.timestamp));
  const latestItems = items.filter(i => !isToday(i.timestamp));
  const hasMore = get(data, 'notifications.pageInfo.hasNextPage');
  const isListInProcess = deletingAllItems || readingAllItems;

  return (
    <ListContainerStyled ref={rootElementRef}>
      <Scroll
        hasMore={hasMore}
        useWindow={false}
        loadMore={onLoadMore}
        threshold={100}
        basedElement={get(rootElementRef, 'current.parentNode')}
        loading={loading}
      >
        <h3 className="list-container-title">
          Notifications
        </h3>

        {isListEmpty && <EmptyList />}

        {todayItems.length
          ? (
            <ListContainerHead
              showClearAll={isAllItemsRead}
              onClearAll={handleClearAll}
              onReadAll={handleReadAll}
              disabled={isListInProcess}
              isToday
            />
          )
          : null
        }

        <List
          list={todayItems}
          onClear={handleItemClear}
          onRead={handleItemRead}
          loading={isListInProcess}
        />

        {latestItems.length
          ? (
            <ListContainerHead
              hideAction={Boolean(todayItems.length)}
              showClearAll={isAllItemsRead}
              onClearAll={handleClearAll}
              onReadAll={handleReadAll}
            />
          )
          : null
        }

        <List
          list={latestItems}
          onClear={handleItemClear}
          onRead={handleItemRead}
          loading={isListInProcess}
        />

        {loading &&
          <LoadingBanner
            spinner
            color={COLOR_PRIMARY}
            bcgcolor={COLOR_WHITE}
          />
        }
      </Scroll>
    </ListContainerStyled>
  );
};

ListContainer.propTypes = {
  onItemRemove: PropTypes.func.isRequired,
  onItemRead: PropTypes.func.isRequired,
  onRemoveAll: PropTypes.func.isRequired,
  onReadAll: PropTypes.func.isRequired,
};

export default memo(ListContainer);
