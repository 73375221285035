import styled from 'styled-components';

import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';

const TabStyled = styled(Tab)`
  color: black;
  text-transform: capitalize;

  &.Mui-selected {
    color: black;
  }
`;

export const TabPanelStyled = styled(TabPanel)`
padding: 24px 0 64px;
`;

export default TabStyled;
