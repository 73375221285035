import { gql } from '@apollo/client';

export default gql`
  query AppTitles {
    applications {
      id
      title
      children {
        id
        title
      }
    }
  }
`;
