import { gql } from '@apollo/client';

const UPDATE_TITLE = gql`
  mutation UpdateTableItem($updateTableItemId: ID!, $data: UpdateTableItemInput!) {
    updateTableItem(id: $updateTableItemId, data: $data) {
      id
      title
    }
  }
`;

export default UPDATE_TITLE;
