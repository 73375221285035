import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { Document, Page, pdfjs } from 'react-pdf';
import { compose } from 'redux';
import withStyles from '@mui/styles/withStyles';
import PdfFullScreenView from '../PdfFullScreenView/PdfFullScreenView';

//Use external CDN to parse pdf files
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const styles = {
  pdfPreviewWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 12px',
    boxSizing: 'border-box',
    position: 'relative',
    width: 'fit-content',
    '&:hover': {
      '& > $pdfPreviewOverlay': {
        visibility: 'visible'
      }
    },
  },
  pdfPreviewOverlay: {
    position: 'absolute',
    visibility: 'hidden',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    opacity: 0.5,
    backgroundColor: '#000',
    margin: '8px 12px',
    cursor: 'pointer'
  },
  pdfPreviewLabel: {
    position: 'absolute',
    border: '1px solid #fff',
    padding: '8px',
    color: '#fff',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
};

function GraphicsPdf({ src: imageSource, data, classes }) {
  const [src, setSrc] = useState('');
  const [isPreviewError, setIsPreviewError] = useState(false);
  const [isFullScreenVisible, setIsFullScreenVisible] = useState(false);

  const _data = useRef(data);

  useEffect(() => {
    let srcStr = null;
    let isObjectURL = false;

    if (typeof imageSource === 'string')
      srcStr = imageSource;
    else if (imageSource instanceof Blob) {
      srcStr = window.URL.createObjectURL(imageSource);
      _data.current = {
        filename: imageSource.name,
        location: {
          downloadURL: srcStr
        }
      };
      isObjectURL = true;
    }

    setSrc(srcStr);

    return () => {
      if (isObjectURL) window.URL.revokeObjectURL(srcStr);
    };
  }, [imageSource]);

  const handleLoadError = useCallback(() => {
    setIsPreviewError(true);
  }, []);

  const handleOpenPdfFullScreenDialog = useCallback(() => {
    setIsFullScreenVisible(true);
  }, []);

  const handleFullScreenClose = useCallback(() => {
    setIsFullScreenVisible(false);
  }, []);

  if (!src) return null;

  return (
    <div className={classes.pdfPreviewWrap}>
      {isFullScreenVisible && <PdfFullScreenView open={isFullScreenVisible} data={_data.current} onClose={handleFullScreenClose} />}

      <Document file={src} onLoadError={handleLoadError}>
        <Page
          pageNumber={1}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          width={300}
        />
      </Document>
      {!isPreviewError &&
        <div className={classes.pdfPreviewOverlay} onClick={handleOpenPdfFullScreenDialog}>
          <div className={classes.pdfPreviewLabel}>
            View fullscreen
          </div>
        </div>
      }
    </div>
  );
}

GraphicsPdf.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Blob)]).isRequired,
  data: PropTypes.object,
  classes: PropTypes.object,
};

export default compose(
  withStyles(styles)
)(GraphicsPdf);
