import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import AvatarShartFormSelectContainer from './styles';
import ColorPicker from '../../components/ColorPicker';
import { COLOR_PRIMARY } from '../../styles';

const colors = ['#e57373', '#ef5350', '#f44336', '#e53935', '#d32f2f', '#c62828', '#b71c1c', '#f06292', '#ec407a', '#e91e63', '#d81b60', '#c2185b', '#ad1457', '#880e4f', '#ba68c8', '#ab47bc', '#9c27b0', '#8e24aa', '#7b1fa2', '#6a1b9a', '#4a148c', '#9575cd', '#7e57c2', '#673ab7', '#5e35b1', '#512da8', '#4527a0', '#311b92', '#7986cb', '#5c6bc0', '#3f51b5', '#3949ab', '#303f9f', '#283593', '#1a237e', '#64b5f6', '#42a5f5', '#2196f3', '#1e88e5', '#1976d2', '#1565c0', '#0d47a1', '#4fc3f7', '#29b6f6', '#03a9f4', '#039be5', '#0288d1', '#0277bd', '#01579b', '#4dd0e1', '#26c6da', '#00bcd4', '#00acc1', '#0097a7', '#00838f', '#006064', '#4db6ac', '#26a69a', '#009688', '#00897b', '#00796b', '#00695c', '#004d40', '#81c784', '#66bb6a', '#4caf50', '#43a047', '#388e3c', '#2e7d32', '#1b5e20', '#aed581', '#9ccc65', '#8bc34a', '#7cb342', '#689f38', '#558b2f', '#33691e', '#dce775', '#d4e157', '#cddc39', '#c0ca33', '#afb42b', '#9e9d24', '#827717', '#fff176', '#ffee58', '#ffeb3b', '#fdd835', '#fbc02d', '#f9a825', '#f57f17', '#ffd54f', '#ffca28', '#ffc107', '#ffb300', '#ffa000', '#ff8f00', '#ff6f00', '#ffb74d', '#ffa726', '#ff9800', '#fb8c00', '#f57c00', '#ef6c00', '#e65100', '#ff8a65', '#ff7043', '#ff5722', '#f4511e', '#e64a19', '#d84315', '#bf360c', '#a1887f', '#8d6e63', '#795548', '#6d4c41', '#5d4037', '#4e342e', '#3e2723'];

function AvatarShortFormSelect({
  buttonText,
  color,
  onColorChange,
  onSetShortForm,
  disableColorPicker
}) {
  return (
    <AvatarShartFormSelectContainer>
      <Button
        variant="contained"
        color="inherit"
        onClick={onSetShortForm}
      >
        {buttonText}
      </Button>

      <ColorPicker
        size="medium"
        color={color || COLOR_PRIMARY}
        colors={colors}
        onColorChange={onColorChange}
        disabled={disableColorPicker}
      />
    </AvatarShartFormSelectContainer>
  );
}

AvatarShortFormSelect.propTypes = {
  buttonText: PropTypes.string,
  color: PropTypes.string,
  onColorChange: PropTypes.func.isRequired,
  onSetShortForm: PropTypes.func.isRequired,
  disableColorPicker: PropTypes.bool.isRequired
};

AvatarShortFormSelect.defaultProps = {
  buttonText: 'Remove Picture'
};

export default AvatarShortFormSelect;
