import styled from 'styled-components';
import {
  Editable
} from 'slate-react';

import { COLOR_GREY_LIGHT } from '../../styles';

const TextEditorContainer = styled.div`
  width: 100%;

  .editor-actions {
    margin-top: 16px;
    display: flex;

    > *:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

const EditorWrapper = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 2px;

  .toolbar {
    margin: -1px -19px;
    border: 1px solid #e0e0e0;

    .mark-button {
      min-width: 0;
      padding: 6px;

      svg {
        font-size: 20px;
      }

      &.active {
        background-color: ${COLOR_GREY_LIGHT}
      }
    }
  }
`;

const SlateEditable = styled(Editable)`
  p {
    margin: 0;

    &:not(:first-child) {
      margin-top: 1.2em;
    }
  }
`;

const EditableWrapper = styled.div`
  font-size: 14px;
  padding: 18px;
  padding-bottom: 0;

  > *:first-child {
    min-height: 110px !important;
  }
`;

export default TextEditorContainer;

export {
  EditorWrapper,
  EditableWrapper,
  SlateEditable
};
