import React, { useState, useCallback, useMemo, useRef } from 'react';
import PT from 'prop-types';
import { matchPath } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';

import Select from 'react-select';

import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';

import SingleValue from './SingleValue';
import MenuList from './MenuList';
import Input from './Input';
import DropdownIndicator from './DropdownIndicator';
import CreateItemDialog from '../CreateItemDialog';

import useItemsList from './hooks/items-list.hook';
import { routes } from '../../services/session/constants';
import { openAppSnackbarNotification } from '../../services/snackbar-notifications/actions';

const TABLE_ITEM_OPTION = gql`
  query TableItemOption($id: ID!) {
    tableItem(id: $id) {
      id
      title
      code
    }
  }
`;

const BACKSPACE_KEY_EVENT = 'Backspace';

const ItemSelect = ({
  value,
  disabled,
  error,
  onChange,
  placeholder = 'Start typing to search',
  inputProps = {},
  styles = {
    placeholder: {},
    control: {},
    indicators: {},
    root: {}
  },
  tableIds,
  excludeIds,
  autoFocus
}) => {
  const [getItemOption] = useLazyQuery(TABLE_ITEM_OPTION, {
    fetchPolicy: 'cache-first'
  });
  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [createItemDialogOpen, setCreateItemDialogOpen] = useState(false);

  const timerIdRef = useRef(null);

  const dispatch = useDispatch();

  const {
    items,
    loadMore,
    search,
    networkStatus
  } = useItemsList(tableIds, excludeIds);

  const isLoading = false;

  const options = useMemo(() => {
    return items.map(({ id, title, code }) => ({
      value: id,
      label: title,
      code
    }));
  }, [items]);

  const menuHeight = useMemo(() => {
    const max = 3,
      l = options.length,
      num = l < max ? l : max;

    return num * 48;
  }, [options]);

  const handleChange = useCallback((data, options) => {
    onChange(data, options);
  }, [onChange]);

  const handleInputChange = useCallback((value) => {
    setInputValue(value);

    clearTimeout(timerIdRef.current);

    timerIdRef.current = setTimeout((value) => {
      const searchText = value.trim();

      setSearchValue(searchText);
      search(searchText || null);
    }, 300, value);
  }, [search]);

  const handlePaste = useCallback(async (ev) => {
    const text = ev.clipboardData.getData('text');

    try {
      const url = new URL(text);
      const pathMatch = matchPath(routes.ITEMS, url.pathname);

      if(pathMatch && pathMatch.params?.sampleId) {
        ev.preventDefault();

        const { data, error } = await getItemOption({
          variables: {
            id: pathMatch.params.sampleId
          }
        });

        if(data?.tableItem) {
          const { id, title, code } = data.tableItem;

          handleChange({ value: id, label: title, code }, { withSubmit: true });
        } else {
          handleInputChange(text);
          dispatch(
            openAppSnackbarNotification({
              variant: 'ERROR',
              message: error?.message ?? 'Failed to find the table item.'
            })
          );
        }
      }
    } catch(e) {
      return;
    }
  }, [getItemOption, handleChange, handleInputChange, dispatch]);

  const handleVisibilityChange = useCallback((i, value) => {
    if (value && options.length - i === 1) {
      loadMore();
    }
  }, [options, loadMore]);

  const handleOpenCreateItemDialog = useCallback(() => {
    setCreateItemDialogOpen(true);
  }, []);

  const handleCloseCreateItemDialog = useCallback(() => {
    setCreateItemDialogOpen(false);
  }, []);

  const handleCreateItemDialogSuccess = useCallback(({ id, title, code, table }) => {
    if (!tableIds?.length || tableIds.includes(table.id)) {
      onChange({ value: id, label: title, code });
    }

    handleCloseCreateItemDialog();
  }, [tableIds, onChange, handleCloseCreateItemDialog]);

  const handleKeyDown = useCallback(e => {
    if (e.key === BACKSPACE_KEY_EVENT) {
      onChange(null);
    }
  }, [onChange]);

  return (
    <Box sx={styles.root}>
      <Select
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        inputProps={{ autoComplete: 'off', ...inputProps }}
        isDisabled={disabled}
        isLoading={isLoading}
        options={options}
        value={value}
        inputValue={inputValue}
        onChange={handleChange}
        onPaste={handlePaste}
        menuHeight={menuHeight}
        styles={{
          menu: (baseStyles) => ({
            ...baseStyles,
            ...styles.menu
          }),
          option: (baseStyles) => ({
            ...baseStyles,
            height: '48px'
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            boxShadow: 'none',
            borderColor: '#DCDBDC',
            minHeight: '28px',
            ':hover': {
              borderColor: '#DCDBDC'
            },
            ...styles.control
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            margin: 0,
            padding: 0,
            fontSize: '14px',
            outline: 'none'
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            margin: 0,
            fontSize: '14px',
            ...styles.placeholder
          }),
          indicatorsContainer: (baseStyles) => ({
            ...baseStyles,
            '.select__dropdown-indicator': {
              padding: '0 8px 0 0'
            },
            '.select__indicator-separator': {
              display: 'none'
            },
            ...styles.indicators
          })
        }}
        components={{
          SingleValue,
          MenuList,
          DropdownIndicator,
          Input
        }}
        searchValue={searchValue}
        onVisibilityChange={handleVisibilityChange}
        onInputChange={handleInputChange}
        onCreateItem={handleOpenCreateItemDialog}
        classNamePrefix="select"
        menuPlacement="auto"
        loading={networkStatus}
        autoFocus={autoFocus}
      />

      {error ?
        <FormHelperText sx={{ ml: 0 }} error>
          {error.message}
        </FormHelperText> :
        null
      }

      {createItemDialogOpen ?
        <CreateItemDialog
          initialTitle={searchValue}
          tableIds={tableIds}
          onClose={handleCloseCreateItemDialog}
          onSuccess={handleCreateItemDialogSuccess}
        /> :
        null
      }
    </Box>
  );
};

ItemSelect.propTypes = {
  className: PT.string,
  value: PT.object,
  disabled: PT.bool,
  error: PT.object,
  onChange: PT.func.isRequired,
  placeholder: PT.string,
  styles: PT.shape({
    placeholder: PT.string
  }),
  inputProps: PT.object,
  tableIds: PT.arrayOf(PT.string),
  excludeIds: PT.instanceOf(Set),
  autoFocus: PT.bool
};

export default ItemSelect;
