import React from 'react';

import Divider from '@mui/material/Divider';

const ActionDivider = () =>
  <Divider
    sx={{ height: '18px' }}
    orientation="vertical"
    variant="middle"
    flexItem
  />;

export default ActionDivider;
