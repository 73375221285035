import React from 'react';
import PropTypes from 'prop-types';

import { Mention } from './plugins/Mention';

function renderElement(props) {
  const {
    attributes,
    children,
    element
  } = props;

  switch (element.type) {
    case 'mention':
      return (<Mention {...props} />);
    default:
      return (<p {...attributes}>{children}</p>);
  }
}

renderElement.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  element: PropTypes.object.isRequired
};

export default renderElement;
