import React, { useCallback, useMemo, useState } from 'react';
import PT from 'prop-types';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import pickBy from 'lodash/pickBy';
import sortBy from 'lodash/sortBy';
import identity from 'lodash/identity';

import Box from '@mui/material/Box';

import UsersTable from '../../../../components/TablesAndFoldersSettings/UsersTable';
import usersTableColumns from '../../../../components/TablesAndFoldersSettings/UsersTable/columns';
import AddUsers from '../../../../components/TablesAndFoldersSettings/AddUsers';

import useTable from '../../../../hooks/table';
import { hasAccess, rolesIds } from '../../../../utils/roles';
import {
  openAppSnackbarNotification
} from '../../../../services/snackbar-notifications/actions';
import { GET_VIEWER, UPDATE_TABLE } from './gql';

const columnIdsToAccessKeysMap = {
  name: 'fullName',
  username: 'name',
  permission: 'role',
  id: 'userId'
};

const withFullName = data => ({
  ...data,
  fullName: `${data.firstName} ${data.lastName}`,
});

const UsersTab = ({
  tableSettings = {},
  readOnly
}) => {
  const [sort, setSort] = useState({
    param: 'name',
    isAsc: true
  });

  const [updateTable] = useMutation(UPDATE_TABLE);
  const { data: { viewer } } = useQuery(GET_VIEWER);

  const dispatch = useDispatch();

  const { id: tableId, hash: tableHash } = tableSettings;
  const organizationRole = tableSettings.iam?.organization;

  const sortedUsers = useMemo(() => {
    const users = tableSettings.iam?.users ?? [];
    const result = sortBy(
      users.map(withFullName),
      columnIdsToAccessKeysMap[sort.param]
    );

    return sort.isAsc ? result.reverse() : result;
  }, [sort, tableSettings.iam?.users]);

  const handleSort = useCallback(columnId => {
    setSort(s => ({
      param: columnId,
      isAsc: columnId === s.param ? !s.isAsc : true
    }));
  }, []);

  const handleAddUserSubmit = useCallback(async (users, organization) => {
    try {
      const data = {
        iam: pickBy({
          organization,
          users
        }, identity)
      };

      await updateTable({
        variables: {
          id: tableId,
          hash: tableHash,
          data
        }
      });

      if (users?.length) {
        dispatch(
          openAppSnackbarNotification({
            message: `${users.length} users added successfully`,
          })
        );
      }

      if (organization) {
        dispatch(
          openAppSnackbarNotification({
            message: `Organization added successfully`,
          })
        );
      }
    } catch(e) {
      dispatch(openAppSnackbarNotification({
        message: e.message,
        variant: 'ERROR'
      }));
    }
  }, [dispatch, tableHash, tableId, updateTable]);

  const handleRoleChange = useCallback(async (subjectId, role) => {
    const data = { iam: {} };

    if (subjectId === 'organization') {
      data.iam.organization = role;
    } else {
      data.iam.users = [{ id: subjectId, role }];
    }

    try {
      await updateTable({
        variables: {
          id: tableId,
          hash: tableHash,
          data
        }
      });
    } catch (e) {
      dispatch(openAppSnackbarNotification({
        message: e.message,
        variant: 'ERROR'
      }));
    }
  }, [dispatch, tableHash, tableId, updateTable]);

  const handleRemoveClick = useCallback(async (userId, isOrganization) => {
    const data = { iam: {} };

    if (isOrganization) {
      data.iam.organization = rolesIds.NO_ACCESS;
    } else {
      data.iam.users = [{ id: userId, role: rolesIds.NO_ACCESS }];
    }

    try {
      await updateTable({
        variables: {
          id: tableId,
          hash: tableHash,
          data
        }
      });
    } catch (e) {
      dispatch(openAppSnackbarNotification({
        message: e.message,
        variant: 'ERROR'
      }));
    }
  }, [dispatch, tableHash, tableId, updateTable]);

  const showOrganization = hasAccess(organizationRole);

  const data = [
    showOrganization && {
      isOrganization: true,
      fullName: 'Organization',
      role: organizationRole
    },
    ...sortedUsers
  ].filter(Boolean);

  const table = useTable({
    columns: usersTableColumns,
    data,
    customState: {
      tableTitle: tableSettings.title,
      viewerMaxRole: tableSettings.viewerMaxRole,
      readOnly,
      viewerId: viewer?.id,
      onRoleChange: handleRoleChange,
      onRemoveUser: handleRemoveClick,
      sorting: {
        state: sort,
        onSort: handleSort
      }
    }
  });

  return (
    <Box pt="16px">
      <AddUsers
        onSubmit={handleAddUserSubmit}
        defaultUserIds={sortedUsers.map(i => i.userId)}
        readOnly={readOnly}
      />

      <UsersTable table={table} />
    </Box>
  );
};

UsersTab.propTypes = {
  tableSettings: PT.object.isRequired,
  readOnly: PT.bool
};

export default UsersTab;
