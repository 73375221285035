import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '../../../components/Avatar';
import { routes } from '../../../services/session/constants';
import { OrganizationNavStyled } from './styles';

function OrganizationNav({ organization }) {
  return (
    <OrganizationNavStyled
      to={routes.ORGANIZATION}
      className={({ isActive }) => isActive ? 'active' : undefined}
    >
      <Avatar
        name={organization.title}
        src={organization.picture.downloadURL}
        size={40}
        color={organization.avatarColor}
        round={false}
      />
      {organization.title}
    </OrganizationNavStyled>
  );
}


OrganizationNav.propTypes = {
  organization: PropTypes.object.isRequired
};

export default OrganizationNav;
