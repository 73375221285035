import React, { useCallback, useState } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import TabsProvider, { TabContent } from '../../../context/tabs';
import StructureTab from './StructureTab';
import UsersTab from './UsersTab';

import { TABS } from '../constants';
import useDocumentTitle from '../../../hooks/document-title';

const TabsBlock = ({ className, table, readOnly, onRefetch }) => {
  const { state } = useLocation(); // `state.tab` is for the default active tab

  const [activeTab, setActiveTab] = useState(
    Object.hasOwn(TABS, state?.tab) ? state.tab : TABS.STRUCTURE.value
  );

  useDocumentTitle(`${table.title} ${TABS[activeTab].label}`);

  const handleTabChange = useCallback((ev, value) => {
    setActiveTab(value);
  }, []);

  return (
    <Box className={className}>
      <TabsProvider value={activeTab}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab value={TABS.STRUCTURE.value} label={TABS.STRUCTURE.label} />
          <Tab value={TABS.MEMBERS.value} label={TABS.MEMBERS.label} />
        </Tabs>

        <TabContent value={TABS.STRUCTURE.value}>
          <StructureTab
            table={table}
            readOnly={readOnly}
            onRefetch={onRefetch}
          />
        </TabContent>

        <TabContent value={TABS.MEMBERS.value}>
          <UsersTab
            tableSettings={table}
            readOnly={readOnly}
            onRefetch={onRefetch}
          />
        </TabContent>
      </TabsProvider>
    </Box>
  );
};

TabsBlock.propTypes = {
  className: PT.string,
  table: PT.object.isRequired,
  readOnly: PT.bool,
  onRefetch: PT.func
};

export default styled(TabsBlock)`
  .MuiTabs-root {
    min-height: 42px;
  }

  .MuiTab-root {
    min-height: 42px;
    padding: 8px;
    text-transform: capitalize;
  }
`;
