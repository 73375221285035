import React, { forwardRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { ColumnSettingsBlock, Table, TableContainer } from '../../../../components/ItemsTable';

import { flexRender } from '../../../../hooks/table';
import FetchMore from '../../../../components/FetchMore/FetchMore';
import { openAppSnackbarNotification } from '../../../../services/snackbar-notifications/actions';
import { useDispatch } from 'react-redux';

import { StyledRow } from './styles';

const fetchMoreStyles = {
  pl: 3
};

const TableView = forwardRef(({
  onSettingsClick,
  table,
  loadingMore,
  selectedItems,
  pageInfo,
  fetchMoreError,
  onFetchMoreError,
  fetchMore,
  chosenItemId
},
ref) => {
  const dispatch = useDispatch();

  const colgroups = useMemo(() => {
    return table
      .headerGroups[0]
      ?.headers
      .map(({ id, colSpan, column }) => ({
        id,
        colSpan: column.isCollapsed() ? 1 : colSpan
      })) ?? [];
  }, [table.headerGroups]);

  const onEndReached = useCallback(async () => {
    try {
      await fetchMore({
        variables: {
          after: pageInfo?.endCursor
        }
      });
    } catch(e) {
      onFetchMoreError(e);
      dispatch(openAppSnackbarNotification({
        message: 'Something went wrong. Please contact support',
        variant: 'ERROR'
      }));
    }
  }, [
    fetchMore,
    onFetchMoreError,
    pageInfo?.endCursor,
    dispatch
  ]);

  return (
    <>
      <TableContainer>
        <Table colgroups={colgroups}>
          {table.headerGroups.map(({ id, headers }) => (
            <tr key={id} className="head">
              {headers.map(header => {
                if(header.column.parent?.isCollapsed())
                  return null;

                return (
                  <React.Fragment key={header.id}>
                    {flexRender(header.column.columnDef.header, { header, table })}
                  </React.Fragment>
                );
              })}
            </tr>
          ))}

          {table.rows.map(row => (
            <StyledRow
              key={row.id}
              chosen={chosenItemId === row.id}
            >
              {row.getCells().map(cell => {
                if(cell.column.isCollapsed())
                  return null;

                return (
                  <React.Fragment key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </React.Fragment>
                );
              })}
            </StyledRow>
          ))}
        </Table>

        <ColumnSettingsBlock
          onClick={onSettingsClick}
        />
      </TableContainer>

      <FetchMore
        rootRef={ref}
        sx={fetchMoreStyles}
        loading={loadingMore}
        hasMore={
          !selectedItems.state.displaySelected &&
                  pageInfo?.hasNextPage &&
                  !fetchMoreError
        }
        onFetchMore={onEndReached}
      />
    </>
  );
});

TableView.propTypes = {
  onSettingsClick: PropTypes.func.isRequired,
  table: PropTypes.object.isRequired,
  loadingMore: PropTypes.bool,
  selectedItems: PropTypes.object,
  pageInfo: PropTypes.object,
  fetchMoreError: PropTypes.object,
  onFetchMoreError: PropTypes.func.isRequired,
  fetchMore: PropTypes.func.isRequired,
  chosenItemId: PropTypes.string,
};

export default TableView;
