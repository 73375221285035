import { gql } from '@apollo/client';

const UPDATE_TABLE = gql`
  mutation UpdateTable($id: ID!, $hash: String!, $data: UpdateTableInput!) {
    updateTable(id: $id, hash: $hash, data: $data) {
      id
      title
      description
      hash
    }
  }
`;

export default UPDATE_TABLE;
