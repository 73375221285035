import React, { useCallback } from 'react';
import get from 'lodash/get';
import { useAnalytics } from 'use-analytics';

import {
  useQuery,
  useMutation
} from '@apollo/client';

import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import Page from './styles';
import { PageContent, PageSection } from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import WarningBlock from '../../components/WarningBlock';

import {
  GET_NOTIFICATION_PREFERENCES,
  CHANGE_NOTIFICATION_SETTINGS,
} from './services';
import useDocumentTitle from '../../hooks/document-title';
import { componentNames, TRACK_NOTIFICATION_SETTINGS } from '../../analytics/constants';

const warningTextStyles = {
  margin: 0
};

const COMMENTS_TEXT_VALUES = {
  MENTION: 'Send me an email notification only if I am mentioned in a comment.',
  NEVER: 'Do not send me any email notifications related to comments.',
  ALWAYS: 'Send me an email notification for all comments.'
};

function NotificationsSettings() {

  useDocumentTitle('Notification Settings');

  const { track } = useAnalytics();

  const { loading, data } = useQuery(GET_NOTIFICATION_PREFERENCES);
  const [changeNotificationSettings] = useMutation(CHANGE_NOTIFICATION_SETTINGS);

  const tableSharing = data?.viewer.emailNotificationSettings.tableSharing ?? false;
  const itemCommentsNotifications = get(data, 'viewer.emailNotificationSettings.itemComments.general');

  const handleTableSharingChange = useCallback(ev => {
    const { checked } = ev.target;

    changeNotificationSettings({
      variables: {
        input: {
          emailNotificationSettings: {
            tableSharing: checked
          }
        }
      },
      optimisticResponse: {
        updateProfile: {
          __typename: 'Viewer',
          id: data.viewer.id,
          emailNotificationSettings: {
            __typename: 'EmailNotificationSettings',
            ...data.viewer.emailNotificationSettings,
            tableSharing: checked
          }
        }
      }
    });
  }, [changeNotificationSettings, data]);

  const handleSampleCommentsChange = useCallback(ev => {
    const { value } = ev.target;

    track(TRACK_NOTIFICATION_SETTINGS.change, {
      component: componentNames.COMMENTS,
      additional_info: {
        ai_old_state: COMMENTS_TEXT_VALUES[itemCommentsNotifications],
        ai_new_state: COMMENTS_TEXT_VALUES[value]
      }
    });

    changeNotificationSettings({
      variables: {
        input: {
          emailNotificationSettings: {
            itemComments: value
          }
        }
      },
      optimisticResponse: {
        updateProfile: {
          __typename: 'Viewer',
          id: data.viewer.id,
          emailNotificationSettings: {
            __typename: 'EmailNotificationSettings',
            ...data.viewer.emailNotificationSettings,
            itemComments: {
              __typename: 'ItemCommentsNotifications',
              general: value
            }
          }
        }
      }
    });
  }, [data, changeNotificationSettings, track, itemCommentsNotifications]);

  return (
    <Page>
      <PageContent loading={loading}>
        <PageTitle>Email Notification Settings</PageTitle>

        <Typography variant="body2" className="page-subtitle">
          Decide which email notifications you would like to receive from us.
        </Typography>

        <PageSection title="Tables and folders">
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={tableSharing}
                  color="primary"
                  onChange={handleTableSharingChange}
                />
              }

              label={
                <Typography variant="body2">
                  Send me an email notification when access to a table or folder is granted.
                </Typography>
              }
            />
          </FormControl>
        </PageSection>

        <PageSection title="Comments">
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="Comments notifications"
              name="sample-comments"
              value={itemCommentsNotifications}
              onChange={handleSampleCommentsChange}
            >
              <FormControlLabel
                value="ALWAYS"
                control={<Radio color="primary" />}
                label={
                  <Typography variant="body2">
                    {COMMENTS_TEXT_VALUES.ALWAYS}
                  </Typography>
                }
              />

              {itemCommentsNotifications === 'ALWAYS' &&
                <WarningBlock icon={null}>
                  <p
                    className="warning-text"
                    style={warningTextStyles}
                  >
                    You will receive a notification for any comment posted in: (1) an item you created; (2) an item where you have previously commented; (3) an item where you have been mentioned.
                  </p>
                </WarningBlock>
              }

              <FormControlLabel
                value="MENTION"
                control={<Radio color="primary" />}
                label={
                  <Typography variant="body2">
                    {COMMENTS_TEXT_VALUES.MENTION}
                  </Typography>
                }
              />

              <FormControlLabel
                value="NEVER"
                control={<Radio color="primary" />}
                label={
                  <Typography variant="body2">
                    {COMMENTS_TEXT_VALUES.NEVER}
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </PageSection>
      </PageContent>
    </Page>
  );
}

export default NotificationsSettings;
