import styled from 'styled-components';
import Box from '@mui/material/Box';

const Container = styled(Box)`
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  border-radius: 6px;
  border: 1px solid #E1E4E7;
  gap: 8px;
  color: #8C8C8C;
`;

export default Container;
