import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import RegularOption from './RegularOption';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const useStyles = makeStyles({
  root: {
    height: '36px',
  }
});

const GroupOption = ({ data, onClick, selected, isSearching }) => {
  const classes = useStyles();

  const { label, children } = data;

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleMenuItem = useCallback(() => {
    setIsExpanded(s => !s);
  }, []);

  useEffect(() => {
    if (isSearching)
      setIsExpanded(true);
  }, [isSearching]);

  return (
    <>
      <MenuItem
        className={classes.root}
        onClick={toggleMenuItem}
      >
        {isExpanded ?
          <KeyboardArrowDownIcon
            size="small"
            sx={{ mr: '5px' }}
          /> :
          <KeyboardArrowRightIcon
            size="small"
            sx={{ mr: '5px' }}
          />
        }

        <ListItemText
          primaryTypographyProps={{
            noWrap: true,
            style: {
              fontSize: '14px',
              fontWeight: '500'
            }
          }}
        >
          {label}
        </ListItemText>
      </MenuItem>

      {children ?
        <Collapse in={isExpanded}>
          {children.map((child) => (
            <RegularOption
              key={child.value}
              data={child}
              onClick={onClick}
              selected={selected}
            />
          ))}
        </Collapse>
        : null
      }
    </>
  );
};

GroupOption.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
  }),
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  isSearching: PropTypes.bool,
};

export default GroupOption;
