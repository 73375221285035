import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  COLOR_WHITE,
  MEDIA_QUERIES_XS,
  MEDIA_QUERIES_MD,
  COLOR_PRIMARY,
  COLOR_PRIMARY_CONTRAST
} from '../../styles';

import LoadingBanner from '../LoadingBanner';

const PageContent = React.forwardRef(({ dndEvents, children, loading, ...props }, ref) => (
  <div
    {...dndEvents}
    {...props}
    ref={ref}
  >
    {loading ?
      <LoadingBanner
        spinner
        color={COLOR_PRIMARY}
      /> :
      children
    }
  </div>
));

PageContent.propTypes = {
  dndEvents: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  loading: PropTypes.bool
};

PageContent.defaultProps = {
  dndEvents: {}
};

export default styled(PageContent).attrs({ className: 'page-content' })`
  width: 100%;
  background: ${COLOR_WHITE};
  min-height: 100%;
  box-sizing: border-box;
  padding: 24px;
  box-shadow:
    0 2px 2px 0 rgba(0,0,0,.14),
    0 1px 5px 0 rgba(0,0,0,.12),
    0 3px 1px -2px rgba(0,0,0,.2);

  ${props => props.loading ?
    `
      display: flex;
      justify-content: center;
      align-items: center;
    ` :
    ''
}

  position: ${props => props.displaySpinner ? 'relative' : 'static'};

  &.on-drag-enter {
    outline-offset: -2px;
    outline: 2px dashed ${COLOR_PRIMARY};

    .dnd-area {
      --dnd-area-background-color: ${COLOR_PRIMARY_CONTRAST};
      --dnd-area-text-color: ${COLOR_PRIMARY};
    }
  }

  @media (${MEDIA_QUERIES_XS}) {
    box-shadow: none;
  }

  @media (${MEDIA_QUERIES_MD}) {
    padding-bottom: 100px;
  }

  > :first-child {
    margin-top: 0;
  }
`;
