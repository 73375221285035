import React from 'react';
import PT from 'prop-types';

import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const iconStyles = {
  fontSize: 16
};

const SortIcon = ({ order }) => {
  switch(order) {
    case 'ASC':
      return <KeyboardArrowUpIcon sx={iconStyles} />;

    case 'DESC':
      return <KeyboardArrowDownIcon sx={iconStyles} />;

    default:
      return <UnfoldMoreIcon sx={iconStyles} />;
  }
};

SortIcon.propTypes = {
  order: PT.oneOf(['ASC', 'DESC'])
};

export default SortIcon;
