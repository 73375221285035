import React, { useState, useCallback } from 'react';
import PT from 'prop-types';

import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { hasAccess, hasAdminAccess } from '../../../../utils/roles';
import TableCell from '../../cells/TableCell/TableCell';
import TableActionsMenu from './TableActionsMenu';
import FolderActionsMenu from './FolderActionsMenu';

const ActionsCell = ({ row }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = useCallback((ev) => {
    setAnchorEl(ev.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const isFolder = row.children;
  const isAdmin = hasAdminAccess(row.data.viewerMaxRole);
  const hasRead = hasAccess(row.data.viewerMaxRole);

  if (!isAdmin && isFolder || !hasRead) return <TableCell />;

  const ActionsMenu = isFolder ? FolderActionsMenu : TableActionsMenu;

  return (
    <TableCell>
      <IconButton
        onClick={handleMenuOpen}
        sx={{
          '&.MuiButtonBase-root': {
            p: 0,
            height: `18px`,
            width: `18px`
          },
          '& .MuiSvgIcon-root': {
            fontSize: 18
          }
        }}
      >
        <MoreVertIcon />
      </IconButton>

      <ActionsMenu
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        rowData={row.data}
      />
    </TableCell>
  );
};

ActionsCell.propTypes = {
  row: PT.shape({
    children: PT.array,
    data: PT.object
  })
};

export default ActionsCell;
