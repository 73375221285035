import React from 'react';
import PropTypes from 'prop-types';

import { createEditor } from 'slate';
import { Slate, withReact } from 'slate-react';
import Leaf from '../TextEditor/Leaf';

import Editable from '../TextEditor/Editable';
import CommentLikes from './CommentLikes';
import CommentShare from './CommentShare';
import CommentDelete from './CommentDelete';

import {
  CommentActionsContainer,
  CommentAction,
  CommentTextContainer
} from './styles';

function CommentText({
  sampleId,
  commentId,
  contents,
  likes,
  onStartEdit,
  onDelete,
  editable,
  onReaction
}) {
  const editorRef = React.useRef(withReact(createEditor()));

  const renderLeaf = React.useCallback(props => <Leaf {...props} />, []);

  editorRef.current.children = contents;

  return (
    <CommentTextContainer>
      <Slate
        editor={editorRef.current}
        value={contents}
      >
        <Editable
          readOnly
          renderLeaf={renderLeaf}
        />
      </Slate>

      <CommentActionsContainer>
        <CommentLikes
          sampleId={sampleId}
          commentId={commentId}
          likes={likes}
          onReaction={onReaction}
        />

        {'•'}

        {editable && (
          <React.Fragment>
            <CommentAction onClick={onStartEdit}>
              Edit
            </CommentAction>

            {'•'}

            <CommentDelete
              onDelete={onDelete}
            />

            {'•'}
          </React.Fragment>
        )}

        <CommentShare
          sampleId={sampleId}
          commentId={commentId}
        />
      </CommentActionsContainer>
    </CommentTextContainer>
  );
}

CommentText.propTypes = {
  sampleId: PropTypes.string.isRequired,
  commentId: PropTypes.string.isRequired,
  contents: PropTypes.array,
  likes: PropTypes.array,
  onStartEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  onReaction: PropTypes.func.isRequired,
};

export default CommentText;
