import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ProcessedDataContainerError = () =>
  <Box display="flex" p="12px">
    <Typography fontSize={14} color="error">
      Can't be rendered
    </Typography>
  </Box>;

export default ProcessedDataContainerError;
