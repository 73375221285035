import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import AddIcon from '@mui/icons-material/Add';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';

import Measurement from './Measurement';
import AddMeasurement from './AddMeasurement';
import MoveObjectDialog from '../../../SamplesTable/AssignedSampleItem/MoveObjectDialog';
import DeletionSubmitDialog from '../../../DeletionSubmitDialog';

import { isRoleEqualOrHigher, rolesIds } from '../../../../utils/roles';

import {
  ADD_MEASUREMENT,
  DELETE_MEASUREMENTS,
  UPDATE_MEASUREMENT
} from './services/gql';

import { openAppSnackbarNotification } from '../../../../services/snackbar-notifications/actions';
import useExpandList from '../hooks/useExpandList';

const MeasurementsTab = ({
  itemId,
  maxRole,
  sidebarState,
  measurements,
  onItemRefetchRequest
}) => {
  const [addMeasurementOpen, setAddMeasurementOpen] = useState(false);
  const [moveMeasurementObject, setMoveMeasurementObject] = useState(null);
  const [deleteMeasurementObject, setDeleteMeasurementObject] = useState(null);

  const dispatch = useDispatch();

  const {
    isAllExpanded,
    handleToggleExpand,
    handleToggleSingleObject,
    expandedList
  } = useExpandList(measurements);

  const [addMeasurement, {
    loading: addMeasurementLoading,
    error: addMeasurementError,
    reset
  }] = useMutation(ADD_MEASUREMENT, {
    onCompleted: (data) => {
      if (isAllExpanded){
        handleToggleSingleObject(data.updateTableItemFile.id);
      }
    }
  });

  const [deleteMeasurement] = useMutation(DELETE_MEASUREMENTS);

  const [updateMeasurement, {
    loading: updateMeasurementLoading,
  }] = useMutation(UPDATE_MEASUREMENT);

  const handleOpenAddMeasurement = useCallback(() => {
    setAddMeasurementOpen(true);
  }, []);

  const handleCloseAddMeasurement = useCallback(() => {
    setAddMeasurementOpen(false);
    reset();
  }, [reset]);

  const handleSubmitAddMeasurement = useCallback(async (data) => {
    try {
      await addMeasurement({
        variables: {
          ...data
        }
      });

      onItemRefetchRequest();
      handleCloseAddMeasurement();
    } catch(e) {
      dispatch(openAppSnackbarNotification({
        message: e.message,
        variant: 'ERROR'
      }));
    }
  },
  [addMeasurement, onItemRefetchRequest, handleCloseAddMeasurement, dispatch]);

  const handleMoveMeasurementOpen = useCallback(({ id, title }) => {
    setMoveMeasurementObject({ id, title, type: 'measurement' });
  }, []);

  const handleMoveMeasurementClose = useCallback(() => {
    setMoveMeasurementObject(null);
  }, []);

  const handleMoveMeasurementSubmit = useCallback(async () => {
    setMoveMeasurementObject(null);
    onItemRefetchRequest();
  }, [onItemRefetchRequest]);

  const handleDeleteMeasurementOpen = useCallback(async (id) => {
    const measurement = measurements.find(m => m.id === id);

    setDeleteMeasurementObject({
      id: measurement.id,
      title: measurement.title
    });
  }, [measurements]);

  const handleDeleteMeasurementClose = useCallback(() => {
    setDeleteMeasurementObject(null);
  }, []);

  const handleDeleteMeasurementSubmit = useCallback(async () => {
    try {
      await deleteMeasurement({
        variables: {
          deleteTableItemFileId: deleteMeasurementObject.id
        }
      });

      if (expandedList.includes(deleteMeasurementObject.id)){
        handleToggleSingleObject(deleteMeasurementObject.id);
      }
      setDeleteMeasurementObject(null);
      onItemRefetchRequest();
    } catch(e) {
      console.log(e);
    }
  }, [deleteMeasurement, deleteMeasurementObject?.id, expandedList, onItemRefetchRequest, handleToggleSingleObject]);

  const handleEditMeasurement = useCallback(async (updateTableItemFileId, file) => {
    try {
      const data = await updateMeasurement({
        variables: { updateTableItemFileId, file }
      });

      onItemRefetchRequest();

      return { data };
    } catch(error) {
      dispatch(openAppSnackbarNotification({
        message: error.message,
        variant: 'ERROR'
      }));

      return { error };
    }
  }, [updateMeasurement, onItemRefetchRequest, dispatch]);

  const hasWriteRole = isRoleEqualOrHigher(maxRole, rolesIds.WRITE);
  const displayNoMeasurements = !measurements.length && !addMeasurementOpen;

  return (
    <Box>
      {hasWriteRole ?
        <Box
          display="flex"
          justifyContent="space-between"
          mb="16px"
        >
          <Button
            variant="text"
            size="small"
            startIcon={<AddIcon />}
            onClick={handleOpenAddMeasurement}
          >
            Add
          </Button>

          {measurements?.length ?
            <Tooltip
              title={isAllExpanded ? 'Collapse All' : 'Expand All'}
              placement="top"
            >
              <IconButton
                onClick={handleToggleExpand}
                sx={{
                  '&:hover': {
                    backgroundColor: 'white'
                  }
                }}
              >
                {isAllExpanded ?
                  <UnfoldLessIcon
                    sx={{
                      fontSize: '14px'
                    }}
                  />
                  :
                  <UnfoldMoreIcon
                    sx={{
                      fontSize: '14px'
                    }}
                  />
                }
              </IconButton>
            </Tooltip> :
            null
          }
        </Box> :
        null
      }

      {displayNoMeasurements ?
        <Typography variant="body2">
          There are no measurements for this item
        </Typography> :
        null
      }

      {addMeasurementOpen ?
        <AddMeasurement
          loading={addMeasurementLoading}
          error={addMeasurementError}
          onCancel={handleCloseAddMeasurement}
          onSubmit={handleSubmitAddMeasurement}
          itemId={itemId}
        /> :
        null
      }

      {measurements?.length ?
        <Box>
          {measurements?.map(data => (
            <Measurement
              key={data.id}
              data={data}
              allowEdit={hasWriteRole}
              sidebarState={sidebarState}
              onEdit={handleEditMeasurement}
              onMove={handleMoveMeasurementOpen}
              onDelete={handleDeleteMeasurementOpen}
              loading={updateMeasurementLoading}
              onOpen={handleToggleSingleObject}
              isOpen={expandedList.includes(data.id)}
            />
          ))}
        </Box> :
        null
      }

      {moveMeasurementObject &&
        <MoveObjectDialog
          onSuccess={handleMoveMeasurementSubmit}
          onClose={handleMoveMeasurementClose}
          objectTitle={moveMeasurementObject.title}
          id={moveMeasurementObject.id}
          itemId={itemId}
          type={moveMeasurementObject.type}
        />
      }

      {deleteMeasurementObject ?
        <DeletionSubmitDialog
          name={deleteMeasurementObject.title}
          type="measurement"
          onClose={handleDeleteMeasurementClose}
          onSubmit={handleDeleteMeasurementSubmit}
        /> :
        null
      }
    </Box>
  );
};

MeasurementsTab.propTypes = {
  itemId: PT.string,
  maxRole: PT.string,
  sidebarState: PT.string,
  onItemRefetchRequest: PT.func.isRequired,
  measurements: PT.array.isRequired
};

export default MeasurementsTab;
