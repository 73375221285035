import React from 'react';
import PT from 'prop-types';
import { Typography, Box, TableCell } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';

import Avatar from '../../../Avatar';

const FONT_SIZE = 26;

const NameCell = ({ row, getValue }) => {
  const {
    data: {
      isOrganization,
      avatarColor = '#BDBDBD',
      pictureDownloadURL
    }
  } = row;

  return (
    <TableCell sx={{ p: 0 }}>
      <Box display="flex" gap="5px">
        {isOrganization
          ? (
            <Box
              sx={{
                borderRadius: '50%',
                display: 'flex',
                backgroundColor: '#ECF9FB',
                width: `${FONT_SIZE}px`,
                height: `${FONT_SIZE}px`
              }}
            >
              <BusinessIcon sx={{ fontSize: '18px', margin: 'auto' }} />
            </Box>
          )
          : (
            <Avatar
              name={getValue()}
              color={avatarColor}
              src={pictureDownloadURL}
              size={FONT_SIZE}
            />
          )
        }
        <Typography sx={{ fontSize: '14px', lineHeight: `${FONT_SIZE}px` }}>
          {getValue()}
        </Typography>
      </Box>
    </TableCell>
  );
};

NameCell.propTypes = {
  getValue: PT.func.isRequired,
  row: PT.shape({
    data: PT.shape({
      isOrganization: PT.bool,
      avatarColor: PT.string,
      pictureDownloadURL: PT.string
    })
  })
};

export default NameCell;
