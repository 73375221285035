import { gql } from '@apollo/client';

export const UNITS = gql`
  query Units {
    units {
      id
      name
    }
  }
`;
