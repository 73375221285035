import { gql } from '@apollo/client';

export const FOLDERS = gql`
query Folders {
  folders {
    id
    title
    parentId
    childFolders {
      id
      title
    }
  }
}
`;

export const TABLES = gql`
  query Tables {
    tables {
      id
      title
      folderId
      path {
        title
        id
      }
    }
  }
`;
