import React, { useState, useEffect } from 'react';
import PT from 'prop-types';

import CopyClipboard from '../../../CopyClipboard/CopyClipboard';

const CopyItemUrlAction = ({
  onCopied,
  text,
  children
}) => {
  const [isCopied, setCopied] = useState(false);

  useEffect(() => {
    if(!isCopied) return;

    const timerId = setTimeout(setCopied, 1e3, false);

    return () => {
      clearTimeout(timerId);
    };
  }, [isCopied]);

  const handleCopy = () => {
    setCopied(true);
    onCopied();
  };

  return (
    <CopyClipboard
      tooltip
      tooltipProps={{
        open: isCopied,
        title: 'Copied',
        placement: 'top',
      }}
      onCopy={handleCopy}
      text={text}
    >
      {children}
    </CopyClipboard>
  );
};

CopyItemUrlAction.propTypes = {
  onCopied: PT.func.isRequired,
  text: PT.string.isRequired,
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node
  ]),
};

export default CopyItemUrlAction;
