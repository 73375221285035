import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';

import { GET_FOLDERS, MOVE_FOLDER } from './gql';
import { COLOR_BLACK, COLOR_GREY_LIGHTER } from '../../../../../styles';
import { FOLDERS, TABLES } from '../../../gql';
import { hasAdminAccess } from '../../../../../utils/roles';

const styles = {
  dialog: {
    width: '530px'
  },
  label: {
    position: 'relative',
    transform: 'translate(0, 6px) scale(0.75)',
    color: COLOR_BLACK
  },
  select: {
    height: '32px',
    borderRadius: '4px',
    marginBottom: '16px',
    '.MuiOutlinedInput-input': {
      p: '4px 8px'
    }
  },
  placeholder: {
    color: COLOR_GREY_LIGHTER
  }
};

const MoveFolderDialog = ({ onClose, id, defaultFolder, refetchList }) => {

  const { data } = useQuery(GET_FOLDERS);

  const [moveFolder, { loading }] = useMutation(MOVE_FOLDER, {
    refetchQueries: refetchList ?? [FOLDERS, TABLES],
  });

  const [parentId, setParentId] = useState(defaultFolder ?? '');
  const [error, setError] = useState(null);

  const handleTitleChange = useCallback((e) => {
    setParentId(e.target.value);
  }, []);

  const handleSubmit = useCallback(async () => {
    const variables = {
      updateFolderId: id,
      data: {
        parentId: parentId || null
      }
    };

    try {
      await moveFolder({
        variables
      });

      onClose();
    } catch (e) {
      setError(e.message);
    }
  }, [moveFolder, id, onClose, parentId]);

  const foldersList = useMemo(() => {
    const folders = data?.folders ?? [];

    return folders.filter(folder => folder.id !== id && hasAdminAccess(folder.viewerMaxRole))
      .sort((first, second) => first.title.localeCompare(second.title));
  }, [data.folders, id]);

  const placeholderDisplay = useMemo(() => {
    return foldersList.find(folder => folder.id === parentId)?.title;
  }, [parentId, foldersList]);

  return (
    <Dialog
      open
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': styles.dialog
      }}
    >
      <DialogTitle>
        Move Folder
      </DialogTitle>

      <DialogContent>
        <FormControl
          fullWidth
        >
          <InputLabel
            shrink
            sx={styles.label}
          >
            Folder
          </InputLabel>

          <Select
            sx={styles.select}
            value={parentId}
            onChange={handleTitleChange}
            displayEmpty
            renderValue={(value) => (
              <Typography
                variant="body2"
                lineHeight="inherit"
                sx={!value ? styles.placeholder : null}
              >
                {placeholderDisplay || 'Select parent folder or leave empty'}
              </Typography>
            )}
          >
            <MenuItem
              value={''}
            >
              None
            </MenuItem>

            {foldersList.map(folder => (
              <MenuItem
                key={folder.id}
                value={folder.id}
              >
                {folder.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Alert
          severity="info"
        >
          Moving the folder will revoke permissions for users from the  parent folder.
        </Alert>

        {error ?
          <FormHelperText error>
            {error}
          </FormHelperText>
          : null
        }
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
        >
          Cancel
        </Button>

        <LoadingButton
          loading={loading}
          onClick={handleSubmit}
          variant="contained"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

MoveFolderDialog.propTypes = {
  id: PropTypes.string.isRequired,
  defaultFolder: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  refetchList: PropTypes.array,
};

export default MoveFolderDialog;
