import React, { useMemo } from 'react';
import PT from 'prop-types';
import transform from 'lodash/transform';
import groupBy from 'lodash/groupBy';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import WarningBlock from '../WarningBlock';

import {
  RESTRICT_PERMISSION,
  RESTRICT_LINKED,
  RESTRICT_NOT_ARCHIVED
} from '../../constants';

const DeleteItemsSubmitDialog = ({
  open,
  onSubmit,
  onClose,
  forbidden,
  loading
}) => {
  const forbiddenByReason = useMemo(
    () => transform(groupBy(forbidden, 'reason'), (res, v, k) => {
      res[k] = v.map(({ itemTitle }) => `"${itemTitle}"`);
    }, {}),
    [forbidden]
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Confirm the delete action</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete all selected items, protocols, and measurements?
        </DialogContentText>

        {forbiddenByReason[RESTRICT_PERMISSION] ?
          <WarningBlock sx={{ mt: 2 }}>
            {forbiddenByReason[RESTRICT_PERMISSION].join(', ')} cannot be deleted
            because you don’t have the necessary permissions
          </WarningBlock> :
          null
        }

        {forbiddenByReason[RESTRICT_LINKED] ?
          <WarningBlock sx={{ mt: 1 }}>
            {`${forbiddenByReason[RESTRICT_LINKED].join(', ')} cannot be deleted
            because there are other items linked to
            ${forbiddenByReason[RESTRICT_LINKED].length === 1 ? 'it' : 'them'}
            `}
          </WarningBlock> :
          null
        }

        {forbiddenByReason[RESTRICT_NOT_ARCHIVED] ?
          <WarningBlock sx={{ mt: 1 }}>
            {`${forbiddenByReason[RESTRICT_NOT_ARCHIVED].join(', ')}
            cannot be deleted because the
            ${forbiddenByReason[RESTRICT_NOT_ARCHIVED].length === 1 ? 'item itself is' : 'items themselves are'}
            not archived, only some of
            ${forbiddenByReason[RESTRICT_NOT_ARCHIVED].length === 1 ? 'its' : 'their'}
            protocols and measurements are archived
            `}
          </WarningBlock> :
          null
        }
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button
          onClick={onClose}
          color="primary"
        >
          Cancel
        </Button>

        <LoadingButton
          onClick={onSubmit}
          variant="contained"
          color="primary"
          loading={loading}
        >
          Yes, delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DeleteItemsSubmitDialog.propTypes = {
  open: PT.bool.isRequired,
  loading: PT.bool.isRequired,
  onSubmit: PT.func.isRequired,
  onClose: PT.func.isRequired,
  forbidden: PT.arrayOf(PT.shape({
    itemTitle: PT.string.isRequired,
    reason: PT.string.isRequired
  }))
};

export default DeleteItemsSubmitDialog;
