import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import ComplexProcessedDataStyled from './styles';

const ComplexProcessedData = ({ data }) =>
  <ComplexProcessedDataStyled>
    <div
      className="message"
      dangerouslySetInnerHTML={{
        __html: get(data, 'message')
      }}
    />
  </ComplexProcessedDataStyled>;

ComplexProcessedData.propTypes = {
  data: PropTypes.shape({
    message: PropTypes.string
  })
};

export default ComplexProcessedData;
