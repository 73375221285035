import styled from 'styled-components';

import IconButton from '@mui/material/IconButton';

import { COLOR_PRIMARY } from '../../../../styles';


export const IconButtonStyled = styled(IconButton)`
  &:hover {
    svg {
      color: ${COLOR_PRIMARY};
    }
  }
`;
