import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import FormulationForm from '../FormulationForm';

import CREATE_FORMULATION_PROTOCOL from './gql';
import PT from 'prop-types';

const CreateFormulationForm = ({ tableId, onClose, onSuccess }) => {

  const [createTableFormulation, { loading, error }] = useMutation(CREATE_FORMULATION_PROTOCOL);

  const handleSubmit = useCallback(async (data) => {
    try {
      await createTableFormulation({
        variables: {
          tableId,
          data
        }
      });

      onSuccess();
    } catch(e) {
      console.error(e);
    }
  }, [tableId, createTableFormulation, onSuccess]);

  return (
    <Box
      border="1px solid #eee"
      borderRadius="4px"
      boxShadow="0px 1px 1px 0px #00000014, 0px 1px 3px 0px #00000014"
      sx={{
        background: '#f6f6f6'
      }}
    >
      <Box
        height="44px"
        display="flex"
        alignItems="center"
        padding="0 26px 0 32px"
        borderBottom="1px solid #eee"
      >
        <Typography
          variant="body2"
          fontWeight="500"
        >
          Formulation
        </Typography>
      </Box>

      <FormulationForm
        loading={loading}
        error={error}
        onSubmit={handleSubmit}
        onCancel={onClose}
      />
    </Box>
  );
};

CreateFormulationForm.propTypes = {
  tableId: PT.string.isRequired,
  protocols: PT.arrayOf(PT.object),
  onClose: PT.func,
  onSuccess: PT.func
};

export default CreateFormulationForm;
