import { PARSER_VIEW_TYPES } from '../../utils/parsing';

export default {
  [PARSER_VIEW_TYPES.VIEW_2D_CUSTOM_AXES]: {
    allowAxisTransform: true,
    axes: [
      {
        id: 'x-axis',
        required: true,
        label: 'X axis',
        name: 'xAxis',
        defaultValue: 0,
        transform: null
      },
      {
        id: 'y-axis-left',
        required: true,
        multiple: true,
        label: 'Y axis (left)',
        name: 'yAxis',
        defaultValue: [1],
        transform: null
      },
      {
        id: 'y-axis-right',
        multiple: true,
        label: 'Y axis (right)',
        name: 'yAxisRight',
        placeholder: 'Select source',
        defaultValue: []
      }
    ]
  },
  [PARSER_VIEW_TYPES.VIEW_HEATMAP]: {
    axes: [
      {
        id: 'x-axis',
        required: true,
        label: 'X axis',
        name: 'xAxis',
        defaultValue: 0
      },
      {
        id: 'y-axis',
        required: true,
        label: 'Y axis',
        name: 'yAxis',
        defaultValue: [1]
      },
      {
        id: 'z-axis',
        required: true,
        label: 'Z axis',
        name: 'zAxis',
        defaultValue: 2
      }
    ]
  },
  [PARSER_VIEW_TYPES.VIEW_WAFER_MAP]: {
    axes: [
      {
        id: 'x-axis',
        required: true,
        label: 'X axis',
        name: 'xAxis',
        defaultValue: 0
      },
      {
        id: 'y-axis',
        required: true,
        label: 'Y axis',
        name: 'yAxis',
        defaultValue: [1]
      },
      {
        id: 'z-axis',
        required: true,
        label: 'Z axis',
        name: 'zAxis',
        defaultValue: 2
      }
    ]
  },
  [PARSER_VIEW_TYPES.VIEW_SCATTER_PLOT]: {
    axes: [
      {
        id: 'x-axis',
        required: true,
        label: 'X axis',
        name: 'xAxis',
        defaultValue: 0
      },
      {
        id: 'y-axis',
        required: true,
        label: 'Y axis',
        name: 'yAxis',
        defaultValue: [1]
      }
    ]
  },
  [PARSER_VIEW_TYPES.VIEW_BOX_PLOT]: {
    axes: [
      {
        id: 'y-axis',
        required: true,
        label: 'Source column',
        name: 'yAxis',
        defaultValue: [9]
      }
    ]
  },
  [PARSER_VIEW_TYPES.VIEW_HISTOGRAM_SINGLE]: {
    axes: [
      {
        id: 'x-axis',
        required: true,
        label: 'Source column',
        name: 'xAxis',
        defaultValue: 9
      }
    ]
  },
  [PARSER_VIEW_TYPES.VIEW_VECTOR_WAFER_MAP]: {
    axes: [
      {
        id: 'x-values',
        required: true,
        label: 'X values',
        name: 'xIndex',
        defaultValue: 0
      },
      {
        id: 'y-values',
        required: true,
        label: 'Y values',
        name: 'yIndex',
        defaultValue: 1
      },
      {
        id: 'x-offset',
        label: 'X offset',
        name: 'xOffsetIndex',
        defaultValue: 5
      },
      {
        id: 'y-offset',
        label: 'Y offset',
        name: 'yOffsetIndex',
        defaultValue: 6
      },
      {
        id: 'x1-values',
        required: true,
        label: 'OV X',
        name: 'x1Index',
        defaultValue: 15
      },
      {
        id: 'y1-values',
        required: true,
        label: 'OV Y',
        name: 'y1Index',
        defaultValue: 16
      }
    ]
  },
  [PARSER_VIEW_TYPES.VIEW_CORRELATION_MATRIX]: {
    axes: [
      {
        id: 'correlation-items',
        required: true,
        multiple: true,
        label: 'Columns',
        name: 'columns',
        defaultValue: [0, 1]
      },
    ]
  }
};
