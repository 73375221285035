import styled, { css } from 'styled-components';

import { COLOR_GREY } from '../../../../../../../styles';

export const EmptyPlaceholderStyled = styled.div`
  color: ${COLOR_GREY};
  display: flex;
  justify-content: center;
  padding: 10px;
`;

export const MenuStyled = styled.div`
  border: 1px solid rgb(204, 204, 204);
  border-top: 0;
  border-radius: 0 0 3px 3px;
  z-index: 1;
  width: 100%;
  background: white;
`;

const SELECT_HEIGHT = 30;

export default styled.div`
  * div {
    box-sizing: border-box;
  }

  .select__menu {
    z-index: 12;

    ${props => props.placement === 'bottom' && css`
      top: 45px;
    `}
  }

  .select__control {
    display: flex;
    background-color: #fff;
    color: #333;
    cursor: default;
    border-spacing: 0;
    border-collapse: initial;
    height: ${SELECT_HEIGHT}px;
    min-height: 0;
    outline: none;
    overflow: hidden;
    position: relative;
    width: 100%;
    border: none;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 2px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px
  }

  .select__value-container {
    padding: 0;
    min-height: 0px;
    height: 32px;
  }

  .select__dropdown-indicator {
    padding: 0;
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 25px;
  }

  .select__control--menu-is-open {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background: #fff;
    border-color: #b3b3b3 #ccc #d9d9d9;
    z-index: 11;
  }

  .select__single-value {
    padding-left: 6px;
  }

  .select__control--menu-is-open .select__dropdown-indicator {
    padding: 0;
  }

  .select__dropdown-indicator svg {
    padding: 0;
    height: auto;
    display: inline-block;
    margin: auto;
    width: 0px;
    border-color: rgb(153, 153, 153) transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
    padding-right: 0;
    transform: scale(0.8);
  }

  .select__control--menu-is-open .select__dropdown-indicator svg {
    transform: scale(0.8) rotate(180deg) translateY(4px);
    padding-top: 3px;
  }

  .Select-placeholder,
  .select__placeholder,
  .Select--single > .Select-control .Select-value {
    bottom: 0;
    color: #aaa;
    left: 0;
    line-height: 38px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    right: 0;
    top: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .select__placeholder {
    bottom: 0;
    left: 0;
    line-height: 33px;
    padding-left: 7px;
    padding-right: 10px;
    position: absolute;
    right: 0;
    top: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #4A5056;
    font-size: 14px;
  }

  .select__input-container {
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
  }

  .select__indicator-separator {
    display: none;
  }
`;
