import React from 'react';
import { ApolloProvider } from '@apollo/client';

import apolloClient from '../../services/apollo-client';

export default function withApolloProvider(Component) {
  return props => (
    <ApolloProvider client={apolloClient}>
      <Component {...props} />
    </ApolloProvider>
  );
}
