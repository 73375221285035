import { gql } from '@apollo/client';

export const CHANGE_PROFILE_DETAILS = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      id
      researchAreas
      materialsClass
    }
  }
`;
