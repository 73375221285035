import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import SingleNotification from './SingleNotification';

const NotificationsList = ({ items }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '8px' }}>
      {
        items.map(item => (
          <SingleNotification
            key={item.id}
            notification={item}
          />
        ))
      }
    </Box>
  );
};

NotificationsList.propTypes = {
  items: PropTypes.array.isRequired
};

export default NotificationsList;
