import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Snackbar from '@mui/material/Snackbar';
import withStyles from '@mui/styles/withStyles';

import AppSnackbarContentInfo from './AppSnackbarContentInfo';
import AppSnackbarContentError from './AppSnackbarContentError';
import AppSnackbarContentSuccess from './AppSnackbarContentSuccess';

import { getAppSnackbars } from '../../services/snackbar-notifications/selectors';
import {
  closeAppSnackbarNotification,
  deleteAppSnackbarNotification
} from '../../services/snackbar-notifications/actions';
import { SNACKBAR_TYPES } from './constants';

const mapStateToProps = state => ({
  snackbars: getAppSnackbars(state)
});

const mapDispatchToProps = {
  closeAppSnackbarNotification,
  deleteAppSnackbarNotification
};

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'right'
};

const styles = theme => ({
  snackbarsContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1400,

    [theme.breakpoints.up('md')]: {
      top: 24,
      left: 'auto',
      right: 24,
    }
  },
  snackbarRoot: {
    zIndex: 0,
    position: 'static',

    [theme.breakpoints.up('md')]: {
      marginBottom: 24
    }
  }
});

const renderSnackbarContent = (type, contentProps) => {
  switch(type) {
    case SNACKBAR_TYPES.INFO:
      return (
        <AppSnackbarContentInfo
          {...contentProps}
        />
      );
    case SNACKBAR_TYPES.SUCCESS:
      return (
        <AppSnackbarContentSuccess
          {...contentProps}
        />
      );
    case SNACKBAR_TYPES.ERROR:
      return (
        <AppSnackbarContentError
          {...contentProps}
        />
      );
    default:
      return null;
  }
};

const AppSnackbar = props => {
  const {
    snackbars,
    closeAppSnackbarNotification,
    deleteAppSnackbarNotification,
    classes
  } = props;
  const handleOnClose = id => () => closeAppSnackbarNotification(id);
  const handleOnExited = id => () => deleteAppSnackbarNotification(id);

  return (
    <div className={classes.snackbarsContainer}>
      {snackbars.map(({
        id, message, open, type
      }) => (
        <Snackbar
          key={id}
          anchorOrigin={anchorOrigin}
          open={open}
          onClose={handleOnClose(id)}
          TransitionProps={{
            onExited: handleOnExited(id)
          }}
          classes={{ root: classes.snackbarRoot }}
          autoHideDuration={10000}
        >
          <span>
            {renderSnackbarContent(type, { id: String(id), message, onClose: handleOnClose(id) })}
          </span>
        </Snackbar>
      ))}
    </div>
  );
};

AppSnackbar.propTypes = {
  snackbars: PropTypes.array.isRequired,
  closeAppSnackbarNotification: PropTypes.func.isRequired,
  deleteAppSnackbarNotification: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(AppSnackbar)
);
