import React, { useCallback, useState } from 'react';

import FormGroup from '@mui/material/FormGroup';

import ExtendedListItem from './ExtendedListItem';
import PropTypes from 'prop-types';

const FoldersList = ({ onCheck, data, value, isFiltered }) => {

  const [expandedList, setExpandedList] = useState({});

  const handleToggleOpen = useCallback((id) => {

    setExpandedList(state => ({
      ...state,
      [id]: !state[id]
    }));
  }, []);

  const handleChange = useCallback((e, ids) => {
    onCheck(ids, e.target.checked);
  }, [onCheck]);

  return (
    <FormGroup sx={{ paddingLeft: 0 }}>
      {data.map(table => (
        <ExtendedListItem
          key={table.id}
          isFolder={table.isFolder}
          onChange={handleChange}
          onToggle={handleToggleOpen}
          table={table}
          value={value}
          checked={value?.includes(table.id)}
          isParent={Boolean(table.children?.length)}
          expandedList={expandedList}
          isFiltered={isFiltered}
        />
      ))}
    </FormGroup>
  );
};

FoldersList.propTypes = {
  onCheck: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  isFiltered: PropTypes.bool.isRequired,
};

export default FoldersList;
