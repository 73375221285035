import { combineReducers } from 'redux';

import general from './general';

const reducer = combineReducers({
  general
});

export default function measurementsReducer(state, action) {
  return reducer(state, action);
}
