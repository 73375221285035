import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useIdleTimer } from 'react-idle-timer';

const VIEWER = gql`
  query ViewerIdleTime {
    viewer {
      idleSignOut
    }
  }
`;

const useAutoLogout = ({ onIdle, isUserExist }) => {

  const { data } = useQuery(VIEWER, { skip: !isUserExist });

  const delay = data?.viewer?.idleSignOut;

  const { start, pause } = useIdleTimer({
    onIdle,
    timeout: delay && delay * 60 * 1000,
    eventsThrottle: 1000,
    startOnMount: false,
    startManually: true,
    crossTab: true
  });

  useEffect(() => {
    if(isUserExist && delay) {
      start();
    } else
      pause();
  }, [isUserExist, delay, start, pause]);
};

export default useAutoLogout;
