import styled from 'styled-components';

import Collapse from '@mui/material/Collapse';

import { COLOR_PRIMARY } from '../../../styles';

const TableRow = styled.div.attrs({ className: 'table-row' })`
  display: flex;
  flex-direction: column;
  min-width: 550px;
  overflow-wrap: break-word;
  word-break: break-word;
  position: relative;

  .table-cell-disable-banner {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    background: white;
    opacity: 0.6;
  }

  .table-cell-main {
    display: flex;
    overflow-wrap: break-word;
    word-break: break-word;

    .clickable {
      cursor: pointer;
    }

    .checkbox-cell {
      .indeterminate {
        color: ${COLOR_PRIMARY};
      }
    }
  }
`;

export default TableRow;

export const TableCellChildren = styled(Collapse).attrs({
  className: 'table-cell-children'
})``;
