import React from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

const TableContainer = ({ children, className }) => (
  <div className={className}>
    <div className="container-border">&nbsp;</div>

    {children}

    <div className="container-border">&nbsp;</div>
  </div>
);

TableContainer.propTypes = {
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node
  ]),
  className: PT.string
};

export default styled(TableContainer)`
  width: max-content;
  display: flex;
  align-items: stretch;

  .container-border {
    flex: 0 0 24px;
    background: white;
  }

  .container-border:first-child {
    position: sticky;
    left: 0px;
    z-index: 2;
  }
`;
