import React, { useState, useCallback } from 'react';
import PT from 'prop-types';
import { Link } from 'react-router-dom';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';

import { MobileMenuStyled, MobileMenuItemStyled } from './styles';
import { COLOR_PRIMARY } from '../../../../../../../styles';

const ICON_STYLES = {
  width: '16px',
  height: '16px',
  marginRight: '6px'
};

const MobileLink = ({ linkTitle, onLink, onEdit, noAccess, canEdit }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = useCallback(e => {
    setAnchorEl(e.target);
  }, []);

  const handleClose = useCallback(e => {
    e.stopPropagation();
    setAnchorEl(null);
  }, []);

  const handleLink = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    setAnchorEl(null);
    onLink();
  }, [onLink]);

  const handleEdit = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    setAnchorEl(null);
    onEdit(e);
  }, [onEdit]);

  const handleLinkClick = useCallback(e => {
    e.preventDefault();
  }, []);

  const isMenuEmpty = !canEdit && noAccess;

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      onClick={handleOpen}
    >
      <Link
        onClick={handleLinkClick}
        target="_blank"
        style={{
          color: noAccess ? '#9e9e9e' : COLOR_PRIMARY,
          textDecoration: noAccess ? 'underline' : 'none',
          width: '100%'
        }}
      >
        <Tooltip title={linkTitle}>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              fontSize: '14px'
            }}
            noWrap
          >
            {linkTitle}
          </Typography>
        </Tooltip>
      </Link>

      {!isMenuEmpty &&
        <MobileMenuStyled
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {!noAccess &&
            <MobileMenuItemStyled onClick={handleLink}>
              <OpenInNewIcon sx={ICON_STYLES} />

              <Typography>
                Open Link
              </Typography>
            </MobileMenuItemStyled>
          }

          {canEdit &&
            <MobileMenuItemStyled onClick={handleEdit}>
              <EditIcon sx={ICON_STYLES} />

              Edit Cell
            </MobileMenuItemStyled>
          }
        </MobileMenuStyled>
      }
    </Box>
  );
};

MobileLink.propTypes = {
  linkTitle: PT.string.isRequired,
  onLink: PT.func.isRequired,
  onEdit: PT.func.isRequired,
  noAccess: PT.bool,
  canEdit: PT.bool
};

export default MobileLink;
