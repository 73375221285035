import styled from 'styled-components';

import Box from '@mui/material/Box';

export const Notification = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 10px 0 10px 8px;
  &:hover {
    background-color: #FAFAFA;
    cursor: pointer;
  }
`;
