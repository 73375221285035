import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

import { COLOR_GREY_LIGHT } from '../../styles';
import { useDidUpdateEffect } from '../../utils/hooks';

import { useInView } from 'react-intersection-observer';

const Option = ({
  data,
  selected,
  onVisibilityChange,
  index,
  onClick,
  focused
}) => {
  const { ref, inView, entry } = useInView({ threshold: 1 });

  const optionStyle = useMemo(() => ({
    boxSizing: 'border-box',
    height: 48,
    backgroundColor: focused ? COLOR_GREY_LIGHT : undefined
  }), [focused]);

  useEffect(() => {
    onVisibilityChange(index, inView);
  }, [inView, selected]); /* eslint-disable-line react-hooks/exhaustive-deps */

  useDidUpdateEffect(() => {
    /* used for dropdown's arrow (up/down) actions */

    if (focused && !inView) {
      /* in order to scroll only within parent's element keeping it fixed */
      entry.target?.scrollIntoView({
        block: 'nearest',
        inline: 'nearest'
      });
    }
  }, [focused]);

  const handleClick = useCallback(() => {
    onClick(data.value, index);
  }, [onClick, data.value, index]);

  return (
    <MenuItem
      ref={ref}
      style={optionStyle}
      onClick={handleClick}
      selected={selected}
    >
      <ListItemText primary={data.label} secondary={data.code} />
    </MenuItem>
  );
};

Option.propTypes = {
  data: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onVisibilityChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  focused: PropTypes.bool.isRequired
};

export default Option;
