import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';

import UPDATE_FOLDER from './gql';
import { COLOR_BLACK } from '../../../../../styles';

const styles = {
  dialog: {
    width: '530px'
  },
  label: {
    position: 'relative',
    transform: 'translate(0, 6px) scale(0.75)',
    color: COLOR_BLACK
  },
  input: {
    borderRadius: '4px',
    height: '32px',
    fontSize: '14px',
    '.MuiOutlinedInput-input': {
      p: '4px 8px'
    }
  },
  error: {
    marginTop: '10px'
  },
};

const RenameFolderDialog = ({ onClose, id, defaultName, refetchList }) => {

  const [renameFolder, { loading }] = useMutation(UPDATE_FOLDER, {
    refetchQueries: refetchList ?? null
  });

  const [title, setTitle] = useState(defaultName ?? '');
  const [error, setError] = useState('');

  const handleTitleChange = useCallback((e) => {
    setTitle(e.target.value);
  }, []);

  const handleSubmit = useCallback(async () => {
    setError('');

    try {
      await renameFolder({
        variables: {
          updateFolderId: id,
          data: {
            title
          }
        }
      });

      onClose();
    } catch (e) {
      setError(e.message);
    }
  }, [renameFolder, id, onClose, title]);

  return (
    <Dialog
      open
      onClose={onClose}
      sx={{
        '.MuiDialog-paper': styles.dialog
      }}
    >
      <DialogTitle>
        Rename Folder
      </DialogTitle>

      <DialogContent>
        <FormControl
          required
          fullWidth
        >
          <InputLabel
            shrink
            sx={styles.label}
          >
            Name
          </InputLabel>

          <OutlinedInput
            sx={styles.input}
            value={title}
            onChange={handleTitleChange}
            autoFocus
          />
        </FormControl>

        {error ?
          <FormHelperText error sx={styles.error}>
            {error}
          </FormHelperText>
          : null
        }
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
        >
          Cancel
        </Button>

        <LoadingButton
          loading={loading}
          onClick={handleSubmit}
          disabled={!title}
          variant="contained"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

RenameFolderDialog.propTypes = {
  id: PropTypes.string.isRequired,
  defaultName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  refetchList: PropTypes.array,
};

export default RenameFolderDialog;
