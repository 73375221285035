import React from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';

import ChartSettings from './ChartSettings';
import AppGraphics from '../../../../../AppGraphics';

import { getGraphicsViewType } from '../../../../../../services/graphics/utils';
import { getChartSettingsConfigByType } from '../../../../../ChartSettingsDialog';
import { mapDataToSettingsOptions } from '../../../../../../services/measurements/utils';

const AppGraphicsContainer = ({
  id,
  type,
  data,
  config,
  onGraphicsConfigChange,
  onGraphicsTypeChange,
  onRemoveGraphics
}) => {
  const settingOptions = getChartSettingsConfigByType(
    getGraphicsViewType(type)
  );

  return (
    <Box>
      {settingOptions ?
        <ChartSettings
          id={id}
          chartType={type}
          settingOptions={settingOptions}
          optionValues={mapDataToSettingsOptions(data).axes}
          value={config}
          onGraphicsConfigChange={onGraphicsConfigChange}
          onGraphicsTypeChange={onGraphicsTypeChange}
          onRemoveGraphics={onRemoveGraphics}
        /> :
        null
      }

      <AppGraphics
        key={id}
        type={type}
        data={data}
        config={config}
      />
    </Box>
  );
};

AppGraphicsContainer.propTypes = {
  id: PT.string,
  type: PT.string,
  data: PT.oneOfType([PT.object, PT.array]),
  config: PT.object,
  onGraphicsConfigChange: PT.func,
  onGraphicsTypeChange: PT.func,
  onRemoveGraphics: PT.func
};

export default AppGraphicsContainer;
