import get from 'lodash/get';
import { createSelector } from 'reselect';

import firebase from 'firebase/app';

import {
  getAuthUser,
  getCurrentUser,
} from '../common/selectors';

export {
  getAuthUser,
  getCurrentUser,
};
// general
export const isFetching = state => state.session.isFetching;
export const isSidebarOpen = state => state.session.sidebarOpen;
export const isMobileView = state => state.session.isMobileView;

// user
export const getError = state => state.session.error;
export const getErrorMsg = state => get(state.session.error, 'message');
export const getResetPasswordResponse = state => get(
  state.session.resetPasswordResponse,
  'message'
);

export const isAuthBySocialProviders = createSelector(
  getAuthUser,
  authUser => {
    if (!authUser) {
      return false;
    }

    const { providerData } = authUser;

    return providerData.every(i =>
      i.providerId !== firebase.auth.EmailAuthProvider.PROVIDER_ID
    );
  });
