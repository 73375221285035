import { generatePath } from 'react-router-dom';
import capitalize from 'lodash/capitalize';

import ModeCommentIcon from '@mui/icons-material/ModeComment';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import { ReactComponent as DatasetAccessIcon } from '../Icons/img/dataset-access.svg';
import { ReactComponent as Correlations } from '../Icons/img/correlations.svg';
import { ReactComponent as Histogram } from '../Icons/img/histogram.svg';
import { ReactComponent as MeasurementOverlay } from '../Icons/img/measurement_overlay.svg';
import { ReactComponent as Regression } from '../Icons/img/regression.svg';
import { ReactComponent as TimeSeries } from '../Icons/img/time_series.svg';
import { ReactComponent as ViolinPlot } from '../Icons/img/violin_plot.svg';
import TableIcon from '../Icons/TableIcon';

import {
  COMMENT_ADDED,
  COMMENT_MENTION,
  SHARE_VIEW,
  SHARE_FOLDER,
  SHARE_TABLE
} from './constants';
import { routes } from '../../services/session/constants';

const textByType = {
  [COMMENT_ADDED]: data => `${data.userName} added a comment on the '${data.title}' item.`,
  [COMMENT_MENTION]: data => `${data.userName} mentioned you in a comment on the '${data.title}' item.`,
  [SHARE_VIEW]: data => `${data.userName} shared a ${data.title ?? ''} view with you`,
  [SHARE_FOLDER]: data => `You have been granted ${capitalize(data.role)} access to the ${data.folder.title} folder by ${data.userName}`,
  [SHARE_TABLE]: data => `You have been granted ${capitalize(data.role)} access to the ${data.table.title} table by ${data.userName}`
};

export const textMap = (type, data) => textByType[type](data);

const iconByType = {
  [COMMENT_ADDED]: ModeCommentIcon,
  [COMMENT_MENTION]: ModeCommentIcon,
  [SHARE_VIEW]: PersonAddAlt1Icon,
  [SHARE_FOLDER]: DatasetAccessIcon,
  [SHARE_TABLE]: DatasetAccessIcon,
  TABLE: TableIcon,
  CORRELATIONS: Correlations,
  HISTOGRAM: Histogram,
  MEASUREMENT_OVERLAY: MeasurementOverlay,
  REGRESSION: Regression,
  TIME_SERIES: TimeSeries,
  VIOLIN_PLOT: ViolinPlot,
};

export const iconMap = (type) => iconByType[type];

export const generateLink = (type, data) => {
  switch(type) {
    case COMMENT_ADDED:
    case COMMENT_MENTION:
      return generatePath(routes.ITEMS, { sampleId: data.item.id });

    case SHARE_VIEW:
      return data.url.replace(window.location.origin, '');

    case SHARE_FOLDER:
      return `${routes.ALL_ITEMS}?filterFolder=${data.folder.id}`;

    case SHARE_TABLE:
      return `${routes.ALL_ITEMS}?filterTeam=${data.table.id}`;

    default:
      return routes.HOME_PAGE;
  }
};
