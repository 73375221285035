import styled from 'styled-components';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { COLOR_PRIMARY } from '../../../../styles';

export const Title = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  margin: 0 0 8px
`;

export const Content  = styled(Box)`
    display: flex;
    flex-direction: row;
    gap: 16px;
    flex-wrap: wrap;
`;

export const ViewStyled = styled(Box)`
    width: 200px;
    max-width: 270px;
    height: 80px;
    color: initial;
    text-transform: initial;
    text-decoration: none;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    border-left: 3px solid ${COLOR_PRIMARY};
    padding: 8px;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-grow: 1;

    &:hover {
        box-shadow: 0px 3px 6px 0px #0000001A;
        background-color: #F5F5F5;
        cursor: pointer;
    }
`;
