import React from 'react';
import PT from 'prop-types';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

const ColumnHeaderMenu = ({ onSortAsc, onSortDesc, onRemove, onClose, anchorEl }) => (
  <Menu
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={onClose}
  >
    {onSortAsc &&
      <MenuItem onClick={onSortAsc}>
        <ListItemText>Sort ascending</ListItemText>
      </MenuItem>
    }

    {onSortDesc &&
      <MenuItem onClick={onSortDesc}>
        <ListItemText>Sort descending</ListItemText>
      </MenuItem>
    }

    {(onSortAsc || onSortDesc) && <Divider />}

    <MenuItem onClick={onRemove}>
      <ListItemText>Remove from view</ListItemText>
    </MenuItem>
  </Menu>
);

ColumnHeaderMenu.propTypes = {
  onSortAsc: PT.func,
  onSortDesc: PT.func,
  onRemove: PT.func,
  onClose: PT.func,
  anchorEl: PT.object
};

export default ColumnHeaderMenu;
