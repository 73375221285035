import React from 'react';
import ColumnHeader from './ColumnHeader';
import NameCell from './cells/NameCell';
import ItemsCell from './cells/ItemsCell';
import ActionsCell from './cells/ActionsCell';
import UsersCell from './cells/UsersCell';
import DefaultCell from './cells/DefaultCell';
import LastUpdatedCell from './cells/LastUpdatedCell';

const columns = [
  {
    id: 'name',
    header: () => <ColumnHeader text="name" width={null} />,
    accessorKey: 'title',
    cell: NameCell
  },
  {
    id: 'features',
    header: () => <ColumnHeader text="features" />,
    accessorKey: 'numberOfTableParameters',
    cell: DefaultCell,
  },
  {
    id: 'items',
    accessorKey: 'numberOfTableItems',
    header: () => <ColumnHeader text="items" />,
    cell: ItemsCell
  },
  {
    id: 'users',
    accessorKey: ['numberOfUsers', 'iam'],
    header: () => <ColumnHeader text="members" />,
    cell: UsersCell
  },
  {
    id: 'lastUpdated',
    accessorKey: 'dateUpdated',
    header: () => <ColumnHeader text="last updated" />,
    cell: LastUpdatedCell
  },
  {
    id: 'actions',
    accessorKey: 'id',
    header: () => <ColumnHeader text="" width="28px" />,
    cell: ActionsCell
  }
];

export default columns;
