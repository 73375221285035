import React, { useState, useCallback } from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

import AddIcon from '@mui/icons-material/Add';

import ErrorBoundary from '../../../../../ErrorBoundary';
import ProcessedDataContainerError from '../ProcessedDataContainerError/ProcessedDataContainerError';

import AppGraphicsContainer from './AppGraphicsContainer';
import ComplexProcessedData from '../../../../../Assets/ComplexProcessedData/ComplexProcessedData';

import { isChartEditable, getChartTypeOptions } from '../../../../../ChartSettingsDialog';
import { getGraphicsViewType } from '../../../../../../services/graphics/utils';

const Graphics = ({
  viewType,
  isComplex,
  data,
  graphics,
  onGraphicsConfigChange,
  onGraphicsTypeChange,
  onRemoveGraphics,
  onAddGraphics
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleMenuOpen = useCallback(ev => {
    setMenuAnchorEl(ev.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const handleAddChart = useCallback(chartType => () => {
    onAddGraphics(getGraphicsViewType(chartType));
    handleMenuClose();
  }, [onAddGraphics, handleMenuClose]);

  const isEditable = isChartEditable(viewType);
  const chartTypeOptions = getChartTypeOptions();

  return (
    <Box
      sx={{
        mt: '16px'
      }}
    >
      <Typography
        variant="body2"
        fontWeight="bold"
        mb="8px"
      >
        Content
      </Typography>

      {isComplex ?
        <ComplexProcessedData data={data} /> :
        graphics.map(({
          id,
          type,
          config
        }, i) => (
          <ErrorBoundary
            key={id}
            fallback={<ProcessedDataContainerError />}
          >
            {i ?
              <Divider sx={{ my: '16px' }} /> :
              null
            }

            <AppGraphicsContainer
              id={id}
              type={type}
              data={data}
              config={config}
              onRemoveGraphics={graphics.length > 1 ? onRemoveGraphics : void 0}
              onGraphicsConfigChange={onGraphicsConfigChange}
              onGraphicsTypeChange={onGraphicsTypeChange}
            />
          </ErrorBoundary>
        ))
      }

      {isEditable ?
        <>
          <Button
            variant="text"
            size="small"
            startIcon={<AddIcon />}
            sx={{ mt: '16px' }}
            onClick={handleMenuOpen}
          >
            Add chart
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
          >
            {chartTypeOptions.map(({ name, value }) => (
              <MenuItem
                key={value}
                onClick={handleAddChart(value)}
              >
                {name}
              </MenuItem>
            ))}
          </Menu>
        </> :
        null
      }
    </Box>
  );
};

Graphics.propTypes = {
  viewType: PT.string,
  isComplex: PT.bool,
  data: PT.oneOfType([PT.object, PT.array]),
  graphics: PT.array,
  onGraphicsConfigChange: PT.func,
  onGraphicsTypeChange: PT.func,
  onRemoveGraphics: PT.func,
  onAddGraphics: PT.func
};

export default Graphics;
