import React from 'react';
import PT from 'prop-types';

import { GroupColumnHeader } from '../../components/ItemsTable';
import { PrimaryHeader, SecondaryHeader } from '../../components/ItemsTable/GroupColumnHeader';

const Header = ({ table, header, text, sortParam }) => {
  const { itemsSorting, selectedItems, onColumnRemove } = table.customState;
  const { state, onSort } = itemsSorting;
  const { state: { displaySelected } } = selectedItems;
  const sorted = state?.param === sortParam;
  const order = sorted ? state?.order : null;

  const handleRemove = () => {
    onColumnRemove({ id: header.id, table });
  };

  return (
    <GroupColumnHeader
      sorted={sorted}
      sortParam={sortParam}
      sortOrder={order}
      onSort={onSort}
    >
      <PrimaryHeader
        sortable={sortParam && !displaySelected}
        order={order}
        sortParam={sortParam}
        onSort={onSort}
        onRemove={handleRemove}
      >
        {text}
      </PrimaryHeader>

      <SecondaryHeader>
        -
      </SecondaryHeader>
    </GroupColumnHeader>
  );
};

Header.propTypes = {
  table: PT.shape({
    customState: PT.shape({
      itemsSorting: PT.shape({
        state: PT.object.isRequired,
        onSort: PT.func.isRequired
      }),
      selectedItems: PT.object.isRequired,
      onColumnRemove: PT.func.isRequired
    })
  }),
  header: PT.shape({ id: PT.string.isRequired }),
  text: PT.string.isRequired,
  sortParam: PT.string
};

export default Header;
