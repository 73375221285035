import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';

import ItemsSelectSection from '../../../Sample/ItemsSelectSection';

const useStyles = makeStyles({
  dialogContent: {
    paddingTop: 16,
    overflow: 'visible'
  }
});

const MoveObjectDialogContent = ({ onItemSelect, item  }) => {
  const classes = useStyles();

  return (
    <DialogContent className={classes.dialogContent}>
      <Box sx={{ paddingTop: '16px' }}>
        <ItemsSelectSection
          onItemChange={onItemSelect}
          value={item}
        />
      </Box>
    </DialogContent>
  );
};

MoveObjectDialogContent.propTypes = {
  onItemSelect: PropTypes.func.isRequired,
  item: PropTypes.object
};

export default MoveObjectDialogContent;
