import React, { useCallback } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';

import Box from '@mui/material/Box';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import SingleAction from './SingleAction';
import DashboardActions from './DashboardActions';

import { ReactComponent as MyItems } from './img/myItems.svg';

import { routes } from '../../../../services/session/constants';
import { generatePathWithSearchParams } from '../../../../utils';
import { componentNames, TRACK_OWNER_FILTER } from '../../../../analytics/constants';

const COLORS = {
  MY_ITEMS: '#C8E2E6',
  HISTOGRAM: '#A3ACFE',
  CORRELATIONS: '#CBA3FE',
  VIOLIN: '#FEA3E4',
  SUPPORT: '#FAE28D',
};

const SUPPORT_LINK = 'http://help.materials.zone/en';

const GET_VIEWER = gql`
  query ActionsViewer {
    viewer {
      id
      name
    }
  }
`;

const Actions = () => {
  const navigate = useNavigate();

  const { track } = useAnalytics();

  const { data: viewerResponse } = useQuery(GET_VIEWER, {
    fetchPolicy: 'cache-first'
  });

  const handleItemsClick = useCallback(() => {
    const url = generatePathWithSearchParams(routes.ALL_ITEMS, {
      filterOwner: viewerResponse.viewer.id
    });

    track(TRACK_OWNER_FILTER.update, {
      component: componentNames.DATASET_SELECTION,
      additional_info: {
        ai_owners_selected: [viewerResponse.viewer.name],
      }
    });

    navigate(url);
  }, [navigate, track, viewerResponse?.viewer]);

  const handleSupportClick = useCallback(() =>
    window.open(SUPPORT_LINK, '_blank'),
  []);

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <SingleAction
        title="View my items"
        icon={<MyItems />}
        onClick={handleItemsClick}
        color={COLORS.MY_ITEMS}
      />

      <DashboardActions />

      <SingleAction
        title="Get more info"
        icon={<QuestionMarkIcon color="action" />}
        onClick={handleSupportClick}
        color={COLORS.SUPPORT}
      />
    </Box>
  );
};

export default Actions;
