import { gql } from '@apollo/client';

const UPDATE_FORMULATION = gql`
mutation UpdateFormulationTableProtocol($id: ID!, $hash: String!, $data: UpdateFormulationTableProtocolInput!) {
  updateFormulationTableProtocol(id: $id, hash: $hash, data: $data) {
    id
  }
}
`;

export default UPDATE_FORMULATION;
