import React from 'react';
import dateFnsFormat from 'date-fns/format';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const FolderProperties = ({ dateUpdated }) => {
  return (
    <Box
      height="16px"
      display="flex"
      alignItems="center"
      gap="10px"
    >
      <Typography
        variant="body2"
        color="rgba(0, 0, 0, 0.60)"
      >
        Updated: {dateFnsFormat(dateUpdated, `dd/MM/yyyy`)}
      </Typography>
    </Box>
  );
};

FolderProperties.propTypes = {
  dateUpdated: PT.number.isRequired
};

export default FolderProperties;
