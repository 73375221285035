import React, { useCallback } from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';

import ItemSelect from '../../../../../ItemSelect';
import EditButtons from '../../EditButtons';
import { ITEM_ID_VALUE_TYPE } from './constants';
import { interceptKeyboardEvent } from './utils';

import { Container, buttonSize, buttonsGap, containerGap } from './styles';

const LinkEditValue = ({
  value,
  onChange,
  onSubmit,
  onCancel
}) => {
  const handleSubmit = useCallback(() => {
    onSubmit(ITEM_ID_VALUE_TYPE, value?.value ?? null);
  }, [onSubmit, value]);

  const handleChange = useCallback((value, options) => {
    if(options?.withSubmit)
      onSubmit(ITEM_ID_VALUE_TYPE, value.value);

    onChange(value);
  }, [onChange, onSubmit]);

  const handleKeyDown = useCallback(event => {
    interceptKeyboardEvent({
      event,
      onEnter() {
        onSubmit(ITEM_ID_VALUE_TYPE, value);
      },
      onEscape() {
        onCancel();
      }
    });
  }, [value, onSubmit, onCancel]);

  return (
    <Container onKeyDown={handleKeyDown}>
      <Box sx={{ width: `calc(100% - ${buttonSize * 2 + buttonsGap + containerGap}px)` }}>
        <ItemSelect
          value={value}
          onChange={handleChange}
          autoFocus
        />
      </Box>

      <EditButtons
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
    </Container>
  );
};

LinkEditValue.propTypes = {
  value: PT.string,
  onChange: PT.func.isRequired,
  onSubmit: PT.func.isRequired,
  onCancel: PT.func.isRequired,
};

export default LinkEditValue;
