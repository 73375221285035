import React, { useMemo, useCallback } from 'react';
import { generatePath, useNavigate, createSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useAnalytics } from 'use-analytics';

import { ReactComponent as Histogram } from './img/histogram.svg';
import { ReactComponent as Correlation } from './img/corelation.svg';
import { ReactComponent as ViolinPlot } from './img/violinPlot.svg';
import SingleAction from './SingleAction';

import { routes } from '../../../../services/session/constants';
import { DASHBOARDS } from './gql';
import { componentNames, TRACK_VIEW_TYPE } from '../../../../analytics/constants';

const COLORS = {
  HISTOGRAM: '#A3ACFE',
  CORRELATIONS: '#CBA3FE',
  VIOLIN: '#FEA3E4',
};

const DashboardActions = () => {
  const navigate = useNavigate();

  const { track } = useAnalytics();

  const { data } = useQuery(DASHBOARDS);

  const dashboardsByTitle = useMemo(
    () =>
      (data?.dashboards ?? []).reduce((acc, i) => ({
        ...acc,
        [i.title]: i
      }), {}),
    [data?.dashboards]
  );

  const handleActionClick = useCallback(dashboard => () => {
    const dashboardId = dashboard.id;
    const path = generatePath(routes.ALL_ITEMS);
    const searchParams = createSearchParams([
      ['dashboardId', dashboardId]
    ]);
    const fullPath = `${path}?${searchParams}`;

    track(TRACK_VIEW_TYPE.change, {
      component: componentNames.DATA_VIEW,
      additional_info: {
        ai_view_type: dashboard.title.toLowerCase()
      }
    });

    navigate(fullPath);
  }, [navigate, track]);

  const histogramDashboard = dashboardsByTitle.Histogram;
  const correlationsDashboard = dashboardsByTitle.Correlations;
  const violinDashboard = dashboardsByTitle['Violin Plot'];

  return (
    <>
      {histogramDashboard &&
        <SingleAction
          title="View histogram"
          icon={<Histogram />}
          onClick={handleActionClick(histogramDashboard)}
          color={COLORS.HISTOGRAM}
        />
      }

      {correlationsDashboard &&
        <SingleAction
          title="View correlations"
          icon={<Correlation />}
          onClick={handleActionClick(correlationsDashboard)}
          color={COLORS.CORRELATIONS}
        />
      }

      {violinDashboard &&
        <SingleAction
          title="View violin plot"
          icon={<ViolinPlot />}
          onClick={handleActionClick(violinDashboard)}
          color={COLORS.VIOLIN}
        />
      }
    </>
  );
};

export default DashboardActions;
