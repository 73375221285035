import { gql } from '@apollo/client';

export const FETCH_TOP_USERS = gql`
  query FetchActiveUsers {
    platformStats {
      topUsers {
      id
      name
      ... on CurrentUser {
        lastActive
        avatarColor
        picture {
          downloadURL
        }
      }
    }
  }
}
`;
