import React from 'react';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme
} from '@mui/material/styles';

import {
  COLOR_WHITE,
  COLOR_TEXT,
  COLOR_PRIMARY,
  COLOR_ACCENT,
  COLOR_PRIMARY_LIGHT,
  COLOR_GREY
} from '../../styles';

const MUI_THEME = {
  palette: {
    primary: {
      light: COLOR_PRIMARY_LIGHT,
      main: COLOR_PRIMARY,
      dark: COLOR_ACCENT,
      contrastText: COLOR_WHITE
    },
    secondary: {
      main: COLOR_WHITE,
      contrastText: COLOR_TEXT
    },
    neutral: {
      main: COLOR_GREY
    }
  },
  typography: {
    fontSize: 14,
    fontFamily: 'Inter, sans-serif',
    title: {
      color: COLOR_TEXT
    }
  },
  shape: {
    borderRadius: 2
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Inter, sans-serif';
          font-style: normal;
          font-display: swap;
        }
      `,
    },
  },
};

export const theme = createTheme(MUI_THEME);

export default function withMuiThemeProvider(Component) {
  return props => (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Component {...props} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

