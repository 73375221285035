import styled from 'styled-components';

const AuthPageStyled = styled.div`
  --auth-forms-side-margins: 10px;

  width: 100%;

  .header {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    background:
      url(/img/auth-page-header-left.svg)
        calc(50% - 300px) 50%
        / 180px
        no-repeat,
      url(/img/auth-page-header-right.svg)
        calc(50% + 300px) 50%
        / 180px
        no-repeat,
      #e6fdff;
  }

  h1, h2, h3 {
    text-align: center;
  }

  a {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
  }

  #main-logo {
    height: 80px;
    transform: translate(0, -25%);
  }

  .auth-forms-container {
    display: flex;
    justify-content: center;
  }

  .auth-forms-container > * {
    margin: 0 var(--auth-forms-side-margins);
    width: calc((768px - var(--auth-forms-side-margins) * 4) / 2);
  }

  .vertical-division {
    display: none;
    position: relative;
    text-align: center;
  }

  .vertical-division::before,
  .vertical-division::after {
    content: "";
    display: block;
    position: absolute;
    width: calc(50vw - 50px);
    height: 1px;
    background: #ddd;
    top: 50%;
  }

  .vertical-division::before {
    left: 20px;
    right: auto;
  }

  .vertical-division::after {
    left: auto;
    right: 20px;
  }

  .change-auth-flow {
    text-align: center;
  }

  .auth-error-container {
    padding: 20px;
    width: calc(768px - var(--auth-forms-side-margins) * 2);
    border: 1px solid #EF5350;
    margin: 0 auto 20px;
    box-sizing: border-box;
    background: #FFEBEE;
    font-size: 14px;

    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
  
  .contact-text {
    justify-content: center;
    width: 740px;
    margin: 20px auto;
    line-height: 1.45rem;
  }

  @media (max-width: 768px) {
    .header {
      height: 140px;
      background: #e6fdff;
    }

    #main-logo {
      height: auto;
      width: 80vw;
      max-width: 350px;
      transform: translate(0, -15%);
    }

    .vertical-division {
      display: block;
    }

    .auth-forms-container {
      flex-direction: column-reverse;
    }

    .auth-forms-container > * {
      width: auto;
    }

    .auth-error-container {
      width: calc(100vw - var(--auth-forms-side-margins) * 2);
    }
    
    .contact-text {
      width: auto;
      padding: 0 20px;
      margin: 0 10px;
    }
  }
`;

export default AuthPageStyled;
