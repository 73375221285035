import { gql } from '@apollo/client';

export const GET_VIEWER_ORGANIZATION = gql`
  query ViewerOrganization {
    viewer {
      organization {
        id
        title
        url
        picture {
          downloadURL
          fullPath
        }
        avatarColor
        members {
          ... on CurrentUser {
            id
            userId
            name
            lastName
            firstName
            email
            dateJoined
            dateLastActivity
            status
            picture {
              downloadURL
            }
            avatarColor
          }
        }
        pendingMembers {
          id
          email
          dateCreated
        } 
      }
    }
  }
`;

