import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { gql, useQuery } from '@apollo/client';
import withStyles from '@mui/styles/withStyles';

import ThumbUp from '@mui/icons-material/ThumbUp';
import ThumbUpOutlined from '@mui/icons-material/ThumbUpOutlined';

import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import Avatar from '../Avatar';

import { CommentAction } from './styles';

const popoverAnchormOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const styles = {
  popover: {
    pointerEvents: 'none'
  },
  listItemAvatar: {
    height: '24px',
    width: '24px',
    lineHeight: '24px'
  }
};

const GET_VIEWER = gql`
  query SampleCommentLikesViewerInfo {
    viewer {
      id
    }
  }
`;

function CommentLikes({
  sampleId,
  commentId,
  likes,
  classes,
  onReaction
}) {
  const { data: viewerResponse } = useQuery(GET_VIEWER, {
    fetchPolicy: 'cache-first'
  });

  const userUID = viewerResponse?.viewer.id;
  const liked = !!likes.find(({ id }) => id === userUID);

  const handleLikeComment = React.useCallback(() => {
    onReaction(!liked);
  }, [onReaction, liked]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleLikeMouseEnter = React.useCallback(ev => {
    setAnchorEl(ev.currentTarget);
  }, []);

  const handleLikeMouseLeave = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const popoverOpen = Boolean(anchorEl);

  return (
    <React.Fragment>
      <CommentAction
        onClick={handleLikeComment}
        aria-owns={popoverOpen ? `popover-${sampleId}-${commentId}` : undefined}
        aria-haspopup="true"
        onMouseEnter={handleLikeMouseEnter}
        onMouseLeave={handleLikeMouseLeave}
      >
        {liked ?
          <ThumbUp /> :
          <ThumbUpOutlined />
        }

        {likes.length > 0 &&
          <span className="likes-count">{likes.length}</span>
        }
      </CommentAction>

      {likes.length > 0 &&
        <Popover
          id={`popover-${sampleId}-${commentId}`}
          className={classes.popover}
          open={popoverOpen}
          anchorEl={anchorEl}
          anchorOrigin={popoverAnchormOrigin}
          onClose={handleLikeMouseLeave}
          disableRestoreFocus
        >
          <List dense>
            {likes.map(({ id, picture, avatarColor, name }) => (
              <ListItem key={id}>
                <ListItemAvatar className={classes.listItemAvatar}>
                  <Avatar
                    name={name}
                    src={get(picture, 'downloadURL')}
                    size={24}
                    color={avatarColor}
                    textSizeRatio={2}
                  />
                </ListItemAvatar>

                <ListItemText
                  primary={name}
                />
              </ListItem>
            ))}
          </List>
        </Popover>
      }
    </React.Fragment>
  );
}

CommentLikes.propTypes = {
  sampleId: PropTypes.string.isRequired,
  commentId: PropTypes.string.isRequired,
  likes: PropTypes.array,
  classes: PropTypes.object.isRequired,
  onReaction: PropTypes.func.isRequired
};

export default withStyles(styles)(CommentLikes);
