import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import { reactRef } from '../../../../../../../utils/prop-types';

const Input = ({ ...props }) =>
  <components.Input
    {...props}
    maxLength={200}
    innerRef={e => {
      props.innerRef(e); /* eslint-disable-line */ /* It's used internally by "react-select" */
      props.selectProps.inputRef.current = e;

      return e;
    }}
  />;

Input.propTypes = {
  selectProps: PropTypes.shape({
    inputRef: reactRef()
  })
};

export default Input;
