import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tooltip from '@mui/material/Tooltip';

const CopyClipboard = ({
  tooltip,
  tooltipProps,
  children,
  wrapperStyles = {},
  ...props
}) =>
  tooltip
    ? (
      <Tooltip {...tooltipProps}>
        <span style={{ ...wrapperStyles }}> {/* FIX: keep "span" as an additional wrapper to avoid unresolved error. https://mui.com/material-ui/react-tooltip/#disabled-elements */}
          <CopyToClipboard {...props}>
            {children}
          </CopyToClipboard>
        </span>
      </Tooltip>
    )
    : (
      <CopyToClipboard {...props}>
        {children}
      </CopyToClipboard>
    );

CopyClipboard.propTypes = {
  tooltip: PropTypes.bool,
  tooltipProps: PropTypes.object,
  children: PropTypes.node.isRequired,
  wrapperStyles: PropTypes.object
};

export default CopyClipboard;
