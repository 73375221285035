import React from 'react';
import PT from 'prop-types';

import { EditableBox } from '../SingleFeature/styles';
import LinkValue from '../SingleFeature/FeatureValue/LinkValue';
import ItemSelect from '../../../../ItemSelect';

const IngredientTitle = ({ readOnly, value, tableIds, usedItemIds, onChange }) => {
  const selectValue = value ?
    { value: value.id, label: value.title, code: value.code } :
    null;

  return (
    <EditableBox readOnly={readOnly}>
      {readOnly ?
        <LinkValue value={value} /> :
        <ItemSelect
          value={selectValue}
          tableIds={tableIds}
          excludeIds={usedItemIds}
          onChange={onChange}
          styles={{
            root: {
              flexGrow: 1
            },
            control: {
              minHeight: '30px'
            }
          }}
        />
      }
    </EditableBox>
  );
};

IngredientTitle.propTypes = {
  readOnly: PT.bool,
  value: PT.shape({
    id: PT.string,
    title: PT.string,
    code: PT.string,
    viewerMaxRole: PT.string
  }),
  tableIds: PT.arrayOf(PT.string),
  usedItemIds: PT.instanceOf(Set),
  onChange: PT.func
};

export default IngredientTitle;

