import {
  createRequestTypes,
  action
} from '../../actions';

import {
  UPDATE_GRAPHICS_CONFIG,
  SET_GRAPHICS_CONFIG,
  QUICK_CREATE_OPEN,
  SAVE_OBJECTS_ORDER,
  FETCH_OBJECTS_ORDER
} from './constants';

export const updateGraphicsConfigTypes = createRequestTypes(UPDATE_GRAPHICS_CONFIG);
export const setGraphicsConfigTypes = createRequestTypes(SET_GRAPHICS_CONFIG);
export const saveObjectsOrderTypes = createRequestTypes(SAVE_OBJECTS_ORDER);
export const fetchSampleObjectsOrderTypes = createRequestTypes(FETCH_OBJECTS_ORDER);


export const openQuickCreate = (sampleId, assetType) => action(QUICK_CREATE_OPEN, { sampleId, assetType });

