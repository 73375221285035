import styled from 'styled-components';

const PictureSettingsContainer = styled.div`
  display: flex;

  .user-avatar-wrapper {
    margin-right: 24px;
  }

  .actions-container {
    margin-top: 4px;

    > * {
      > *:first-child {
        width: 180px;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
`;

export default PictureSettingsContainer;
