/**
 * Analytics plugin: GA4
 * @param  {Object} userConfig
 * @param  {string} userConfig.token
 * @param  {boolean} [userConfig.debug=true]
 * @return {Object} - plugin object
 */
export default function ga4Plugin(userConfig) {
  return {
    name: 'ga4',
    config: {
      token: userConfig.token,
      debug: userConfig.debug ?? true,
      enabled: userConfig.enabled
    },

    initialize: ({ config }) => {
      const { token, debug } = config;
      const script = document.createElement('script');

      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${token}`;
      document.body.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      window.gtag = function gtag() {
        window.dataLayer.push(arguments);
      };
      window.gtag('js', new Date());
      window.gtag('config', token, { debug_mode: debug });
    },

    identify: ({ payload }) => {
      // https://developers.google.com/analytics/devguides/collection/ga4/user-id?platform=websites#send_user_ids
      window.gtag('set', { user_id: payload.userId });

      // https://developers.google.com/analytics/devguides/collection/ga4/user-properties?technology=websites
      window.gtag('set', 'user_properties', {
        organization_id: payload.traits.user.organization?.id ?? null
      });
    },

    reset: () => {
      window.gtag('set', { user_id: undefined });

      window.gtag('set', 'user_properties', {
        organization_id: undefined
      });
    },

    loaded: () => {
      // return boolean so analytics knows when it can send data to third party
      return true;
    }
  };
}
