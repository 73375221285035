import React, { useCallback } from 'react';
import PT from 'prop-types';
import { pick } from 'lodash';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import Input from '../../../../../components/Input';
import Textarea from '../../../../../components/Textarea';
import Content from './FormElements/Content';

import useTableProtocolForm from '../hooks/table-protocol-form.hook';
import { textToValue } from './FormElements/Content/FormulationSelect/FormulationDialog/utils/valueToText';

const ProtocolForm = ({
  initialData,
  protocols,
  readOnly,
  loading,
  error,
  onCancel,
  onSubmit,
}) => {
  const {
    state,
    setTitleValue,
    setTitleError,
    setDescriptionValue,
    addParameter,
    removeParameter,
    setParameterTitleType,
    setParameterTitle,
    setParameterValueType,
    setParameterValue,
    setParameterOrder,
    setParameterError,
    resetState
  } = useTableProtocolForm(initialData);

  const handleTitleChange = useCallback(ev => {
    setTitleValue(ev.target.value);
  }, [setTitleValue]);

  const handleDescriptionChange = useCallback(ev => {
    setDescriptionValue(ev.target.value);
  }, [setDescriptionValue]);

  const handleSaveProtocol = useCallback(() => {
    const title = state.title.value.trim();
    let hasErrors = false;

    if(!title) {
      setTitleError('The "Title" field is required');
      hasErrors = true;
    }

    if(protocols.find(protocol => protocol.title === title && protocol.id !== initialData?.id)) {
      setTitleError('The title must be unique within the table');
      hasErrors = true;
    }

    if(state.parameters.ids.length) {
      const paramTitles = {};

      state.parameters.ids.forEach(id => {
        const { title, value } = state.parameters.byId[id];
        const _title = typeof title.value === 'string' ?
          title.value.trim() :
          title.value?.value;

        if(!_title) {
          setParameterError(id, 'The "Title" field is required');
          hasErrors = true;
        } else if(paramTitles[_title] && paramTitles[_title] === title.type) {
          setParameterError(id, 'The title must be unique within the protocol');
          hasErrors = true;
        } else if(title.type === 'LINK' && value.type === 'LINK') {
          setParameterError(id, 'The title and value cannot both be links');
          hasErrors = true;
        } else {
          paramTitles[_title] = title.type;
        }
      });
    }

    if(!hasErrors) {
      onSubmit({
        title,
        description: state.description.value,
        tableParameters: state.parameters.ids.map(id => {
          const parameter = state.parameters.byId[id];

          const data = {
            valueType: parameter.value.type
          };

          if(parameter.id)
            data.id = id;

          if(parameter.title.type === 'TEXT')
            data.title = parameter.title.value.trim();
          else
            data.titleTableItemId = parameter.title.value.value;

          if(parameter.value.type === 'QUANTITY')
            data.unitId = parameter.value.value?.unit?.id || null;

          if(parameter.value.type === 'CALCULATION'){
            data.calculation = {};
            data.unitId = parameter.value.value?.unit?.id || null;
            data.calculation.contents = textToValue(parameter.value.value?.calculation) || null;
            data.description = parameter.value.value?.description;
          }

          if (parameter.value.type === 'ENUM') {
            data.enumValueOptions = (parameter.value.value?.enumValueOptions ?? [])
              .map(i => pick(i, ['id', 'title']));
          }

          return data;
        })
      });
    }

  }, [state, protocols, setTitleError, setParameterError, onSubmit, initialData]);

  const handleCancel = useCallback(() => {
    resetState();
    onCancel();
  }, [resetState, onCancel]);

  return (
    <Box
      padding="16px 32px"
      maxWidth="800px"
      display="flex"
      flexDirection="column"
      gap="16px"
    >
      <Input
        label="Title"
        required
        disabled={readOnly}
        value={state.title.value}
        error={state.title.error}
        onChange={handleTitleChange}
      />

      <Textarea
        label="Description"
        value={state.description.value}
        disabled={readOnly}
        onChange={handleDescriptionChange}
      />

      <Content
        protocolId={state.id || 'new-protocol'}
        columns={protocols}
        data={state.parameters}
        readOnly={readOnly}
        onAddParameter={addParameter}
        onRemoveParameter={removeParameter}
        onParameterTitleTypeChange={setParameterTitleType}
        onParameterTitleChange={setParameterTitle}
        onParameterValueTypeChange={setParameterValueType}
        onParameterValueChange={setParameterValue}
        onSetParameterOrder={setParameterOrder}
      />

      {error ?
        <FormHelperText sx={{ ml: 0 }} error>
          {error.message}
        </FormHelperText> :
        null
      }

      <Divider />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        gap="12px"
      >
        {readOnly ?
          <Button
            variant="contained"
            onClick={handleCancel}
          >
            Close detailed view
          </Button> :
          <>
            <Button
              onClick={handleCancel}
            >
              Cancel
            </Button>

            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleSaveProtocol}
              loading={loading}
            >
              Save protocol
            </LoadingButton>
          </>
        }
      </Box>
    </Box>
  );
};

ProtocolForm.propTypes = {
  protocols: PT.arrayOf(PT.object),
  columns: PT.array,
  initialData: PT.object,
  readOnly: PT.bool,
  loading: PT.bool,
  error: PT.object,
  onSubmit: PT.func,
  onCancel: PT.func
};

export default ProtocolForm;
