import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Link } from 'react-router-dom';

import NotificationActions from './NotificationActions';
import RootNotificationStyled from './styles';

const RootNotification = ({
  onClear,
  onRead,
  id,
  read,
  children,
  linkTo,
  item,
  hideActions,
  isDisabled
}) => {
  const handleClearItem = useCallback(e => {
    e.preventDefault();

    if (isDisabled) return;

    onClear(id, read);
  }, [id, read, onClear, isDisabled]);

  const handleMarkRead = useCallback(e => {
    e.preventDefault();

    if (isDisabled) return;

    onRead(id, !read);
  }, [id, read, onRead, isDisabled]);

  const handleBodyClick = useCallback(() => {
    if (!read) {
      onRead(id, !read);
    }
  }, [read, id, onRead]);

  const archived = get(item, 'archived');
  const ActivableLinkTagName = archived ? 'div' : Link;
  const dynamicLinkPops = {};

  if (!archived) {
    Object.assign(dynamicLinkPops, {
      to: linkTo,
      className: 'notification-link',
      target: '_blank'
    });
  }

  return (
    <RootNotificationStyled unread={!read}>
      <ActivableLinkTagName
        {...dynamicLinkPops}
      >
        <div className="notification-body" onClick={handleBodyClick}>
          {children}
        </div>
      </ActivableLinkTagName>

      {!hideActions &&
        <NotificationActions
          isRead={read}
          isDisabled={isDisabled}
          onClearItem={handleClearItem}
          onMarkRead={handleMarkRead}
        />
      }
    </RootNotificationStyled>
  );
};

RootNotification.propTypes = {
  id: PropTypes.string.isRequired,
  read: PropTypes.bool.isRequired,
  timestamp: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    avatarSrc: PropTypes.string,
    avatarColor: PropTypes.string,
    picture: PropTypes.object,
  }).isRequired,
  children: PropTypes.any.isRequired,
  onClear: PropTypes.func.isRequired,
  onRead: PropTypes.func.isRequired,
  linkTo: PropTypes.string.isRequired,
  item: PropTypes.object,
  hideActions: PropTypes.bool,
  disabledItemIds: PropTypes.object,
  loading: PropTypes.bool,
};

RootNotification.defaultProps = {
  isNotificationDisabled: () => {}
};

export default RootNotification;
