import React from 'react';
import PropTypes from 'prop-types';
import { quantile, ascending, deviation } from 'd3-array';

import ChartContainer from './ChartContainer';

import mapValidData from '../../../utils/graphics/validate-columns';
import getUnitsFromColumnTitle from '../../../utils/graphics/get-units-from-column-title';
import formatChartSummaryValues from '../../../utils/graphics/format-chart-summary-values';

class BoxPlot extends React.Component {
  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate(prevProps) {
    if(this.props.yAxis !== prevProps.yAxis) {
      this.chartContainerRef.innerHTML = '';

      this.renderChart();
    }
  }

  renderChart() {
    const {
      preview,
      yAxis
    } = this.props;

    const Bokeh = window.Bokeh;

    const plot_width = preview ? 150 : void 0;
    const plot_height = preview ? 150 : 300;
    const match_aspect = preview ? void 0 : true;
    const sizing_mode = preview ? void 0 : 'stretch_both';
    const toolbar_location = preview ? null : 'right';
    const tools = preview ? void 0 : [
      new Bokeh.PanTool(),
      new Bokeh.BoxZoomTool(),
      new Bokeh.ResetTool(),
      new Bokeh.SaveTool()
    ];

    const titles = this.props.data[0];
    const data = mapValidData(titles, this.props.data.slice(1));

    const values = data
      .map(row => row[yAxis])
      .sort(ascending);

    const q1 = quantile(values, .25);
    const q2 = quantile(values, .5);
    const q3 = quantile(values, .75);
    const iqr = q3 - q1;
    const min = q1 - 1.5 * iqr;
    const max = q3 + 1.5 * iqr;
    const mean = values.reduce((sum, value) => sum + value, 0) / values.length;
    const std = deviation(values);

    let ydr = new Bokeh.Range1d({
      start: values[0],
      end: values[values.length - 1]
    });

    // let ydr = new Bokeh.Range1d({
    //   start: min - Math.abs(min * .05),
    //   end: max + Math.abs(max * .05)
    // });

    const plot = new Bokeh.Plotting.figure({
      x_range: ['D12'],
      y_range: ydr,
      // plot sizing config
      width: plot_width,
      height: plot_height,
      match_aspect,
      sizing_mode,

      // toolbar config
      toolbar_location,
      tools
    });

    plot.segment('D12', min, 'D12', q1, { line_color: 'black' });
    plot.segment('D12', max, 'D12', q3, { line_color: 'black' });

    plot.vbar('D12', .2, q1, q3, { line_color: 'black', fill_color: null });

    // plot.dash('D12', [ min, max ], [ 10, 10 ], { color: 'black' });

    plot.vbar('D12', .05, min, min, { line_color: 'black' });
    plot.vbar('D12', .05, max, max, { line_color: 'black' });

    plot.vbar('D12', .2, q2, q2, { line_color: 'black', fill_alpha: 0 });

    plot.vbar('D12', .2, mean, mean, { line_color: 'red', fill_alpha: 0 });

    plot.circle(['D12', 'D12'], [values[0], values[values.length - 1]], {
      line_color: 'black',
      fill_color: 'black',
      size: 4
    });

    plot.xaxis[0].visible = false;

    let columns;

    if(preview) {
      plot.yaxis[0].visible = false;

      columns = plot;
    } else {
      plot.toolbar.logo = null;

      // axes config
      plot.yaxis[0].axis_label = titles[yAxis];
      // plot.yaxis[0].major_label_orientation = 'vertical';

      // toolbar config
      plot.toolbar.active_inspect = null;
      plot.toolbar.active_scroll = null;
      plot.toolbar.active_tap = null;

      const units = getUnitsFromColumnTitle(titles[yAxis]);

      const info = new Bokeh.Row({
        children: [
          new Bokeh.Widgets.PreText({
            text: [
              `MIN: ${formatChartSummaryValues(values[0], units, 14)}`,
              `MAX: ${formatChartSummaryValues(values[values.length - 1], units)}`,
              '\n',
              `AVG: ${formatChartSummaryValues(mean, units, 14)}`,
              `STD: ${formatChartSummaryValues(std, units)}`
            ].join('')
          })
        ],
        height: 48,
        align: 'center'
      });

      columns = new Bokeh.Column({
        children: [
          plot,
          info
        ],
        sizing_mode: 'stretch_width'
      });
    }

    Bokeh.Plotting.show(columns, this.chartContainerRef);
  }

  render() {
    return (
      <ChartContainer
        ref={ref => { this.chartContainerRef = ref; }}
        className={this.props.preview && 'preview'}
      />
    );
  }
}

BoxPlot.propTypes = {
  data: PropTypes.array.isRequired,
  preview: PropTypes.bool,
  yAxis: PropTypes.array
};

BoxPlot.defaultProps = {
  yAxis: [9]
};

export default BoxPlot;
