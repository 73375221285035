import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

import ItemActions from './ItemActions';
import ShortGeneralActions from './GeneralActions/ShortGeneralActions';
import FullGeneralActions from './GeneralActions/FullGeneralActions';

import { OPEN_STATE } from '../../constants';

import { DIALOG_BREAKPOINT } from '../../../../styles';

const ActionsBlock = ({
  readOnly,
  isFullscreen,
  onToggleFullScreen,
  onClose,
  data,
  onRemove,
  hideSidebarItems
}) => {
  const isMobileView = useMediaQuery(`(${DIALOG_BREAKPOINT})`);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleToggleFullScreen = useCallback(() => {
    onToggleFullScreen(isFullscreen ? OPEN_STATE.MINOR : OPEN_STATE.FULLSCREEN);
  }, [onToggleFullScreen, isFullscreen]);

  const showShortActions = !isFullscreen || isMobileView;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        gap: '4px'
      }}
    >
      <ItemActions
        readOnly={readOnly}
        fullscreen={isFullscreen}
        data={data}
        onRemove={onRemove}
      />

      {!hideSidebarItems ?
        showShortActions ?
          <ShortGeneralActions
            isMobileView={isMobileView}
            onFullScreen={handleToggleFullScreen}
            onClose={handleClose}
          />
          : <FullGeneralActions
            onClose={handleClose}
            onShortScreen={handleToggleFullScreen}
          />
        : null
      }
    </Box>
  );
};

ActionsBlock.propTypes = {
  readOnly: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  onToggleFullScreen: PropTypes.func,
  onClose: PropTypes.func,
  data: PropTypes.object,
  onRemove: PropTypes.func.isRequired,
  hideSidebarItems: PropTypes.bool,
};

export default ActionsBlock;
