import React, { useState, useCallback } from 'react';
import PT from 'prop-types';

import Typography from '@mui/material/Typography';

import TitleInput from './TitleInput';
import EditableContentGroup from '../EditableContentGroup';

const pathStringify = (path = [], end) => {
  const folders = path.map(i => i.title).join(' / ');

  return `${folders}${folders.length ? ' / ' : ''}${end}`;
};

const FolderTitle = ({ onChange, title, path, readOnly }) => {
  const [editModeOn, setEditModeOn] = useState(false);

  const handleToggleEditMode = useCallback(() => {
    setEditModeOn(state => !state);
  }, []);

  const handleTitleChange = useCallback(async (value) => {
    onChange(value);
    setEditModeOn(false);
  }, [onChange]);

  return (
    <>
      {editModeOn ?
        <TitleInput
          title={title}
          onSubmit={handleTitleChange}
          onCancel={handleToggleEditMode}
        /> :
        <EditableContentGroup
          onEdit={handleToggleEditMode}
          containerStyles={readOnly
            ? {
              '&:hover': {
                '.edit-button': {
                  display: 'none'
                }
              }
            }
            : undefined
          }
        >
          <Typography
            fontSize={24}
            lineHeight="32px"
            fontWeight="bold"
          >
            {pathStringify(path, title)}
          </Typography>
        </EditableContentGroup>
      }
    </>
  );
};

FolderTitle.propTypes = {
  onChange: PT.func.isRequired,
  title: PT.string.isRequired,
  path: PT.array,
  readOnly: PT.bool
};

export default FolderTitle;
