import React from 'react';
import PropTypes from 'prop-types';

import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';

import InputContainer from './styles';

function OutlinedInputWithLabel({
  id,
  label,
  value,
  type,
  onChange,
  inputLabelProps = {},
  ...props
}) {
  return (
    <InputContainer>
      <InputLabel
        shrink
        htmlFor={id}
        {...inputLabelProps}
      >
        {label}
      </InputLabel>

      <InputBase
        id={id}
        value={value}
        type={type}
        onChange={onChange}
        {...props}
      />
    </InputContainer>
  );
}

OutlinedInputWithLabel.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  inputLabelProps: PropTypes.object
};

export default OutlinedInputWithLabel;
