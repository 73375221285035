import { gql } from '@apollo/client';

const fragments = {
  profileData: gql`
    fragment ProfileData on Viewer {
      id
      name
      firstName
      lastName
      position
      country {
        alpha2
        en
      }
      phone
      mobile
      institution
      researchGroup
      principal
      url
      researchAreas
      materialsClass
      userId
      avatarColor
      picture {
        downloadURL
      }
      authProviders {
        providerId
        email
      }
      idleSignOut
      apiKey
    }
  `
};

export const VIEWER_PROFILE = gql`
  query ViewerProfile {
    viewer {
      ...ProfileData
    }
  }
  ${fragments.profileData}
`;

export const COUNTRY_LIST_GET = gql`
  query Countries {
    countries {
      alpha2
      en
    }
  }
`;
