import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import difference from 'lodash/difference';

import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';

import ListItem from '../../ListItem';

import getAllIds from '../utils';

import { COLOR_GREY_LIGHTER } from '../../../../../styles';
import ExtendedListItem from '../ExtendedListItem';

const styles = {
  customList: {
    paddingLeft: '24px',
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  selectAll: {
    color: COLOR_GREY_LIGHTER
  }
};

const InitialList = ({ onFilterUpdate, data, value, isFiltered, onFilterDelete }) => {

  const [expandedList, setExpandedList] = useState({});

  const handleToggleOpen = useCallback((id) => {

    setExpandedList(state => ({
      ...state,
      [id]: !state[id]
    }));
  }, []);

  const allIds = useMemo(() => getAllIds(data), [data]);

  const handleChange = useCallback((e, id) => {
    if (Array.isArray(id)) {
      onFilterUpdate(id, e.target.checked);
    } else if (typeof id === 'boolean'){
      onFilterUpdate(allIds, e.target.checked);
    } else {
      onFilterUpdate([id], e.target.checked);
    }
  }, [allIds, onFilterUpdate]);

  const isAllSelected = useMemo(() => Boolean(allIds.length) &&
      Boolean(value.length) &&
      difference(allIds, value).length === 0,
  [allIds, value]);

  return (
    <FormGroup sx={{ paddingLeft: 0 }}>
      <Box sx={styles.customList}>
        <ListItem
          onChange={handleChange}
          title="(Select All)"
          checked={isAllSelected}
          additionalStyles={styles.selectAll}
        />
      </Box>

      {data.map(table => (
        <ExtendedListItem
          key={table.id}
          isFolder={table.isFolder}
          onChange={handleChange}
          onToggle={handleToggleOpen}
          table={table}
          onFilterUpdate={onFilterUpdate}
          value={value}
          checked={value.includes(table.id)}
          isParent={Boolean(table.children?.length)}
          expandedList={expandedList}
          isFiltered={isFiltered}
          onFilterDelete={onFilterDelete}
        />
      ))}
    </FormGroup>
  );
};

InitialList.propTypes = {
  onFilterUpdate: PropTypes.func.isRequired,
  onFilterDelete: PropTypes.func.isRequired,
  data: PropTypes.array,
  unfilteredList: PropTypes.array,
  value: PropTypes.array,
  depth: PropTypes.number,
  isFiltered: PropTypes.bool
};

export default InitialList;
