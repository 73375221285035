import React from 'react';
import PT from 'prop-types';

import { Link } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Page from '../../components/Page';
import { PageContentStyled } from './styles';

const NoContent = ({
  contentType,
  defaultPageName,
  defaultPageURL
}) => (
  <Page>
    <PageContentStyled>
      <div className="image-container">
        <img src="/img/oops.png" alt="No content" />
      </div>

      <div>
        <Typography variant="h1" className="page-title">
          Failed to fetch the {contentType}
        </Typography>

        <Typography variant="body1" className="page-text-content">
          Sorry, it looks like you don’t have access to this {contentType}, or searched for a non-existent {contentType}.
        </Typography>

        <Button
          component={Link}
          to={defaultPageURL}
          variant="contained"
          color="primary"
          className="action-button"
        >
          Go back to the "{defaultPageName}" page
        </Button>
      </div>
    </PageContentStyled>
  </Page>
);

NoContent.propTypes = {
  contentType: PT.string.isRequired,
  defaultPageName: PT.string.isRequired,
  defaultPageURL: PT.string.isRequired
};

export default NoContent;
