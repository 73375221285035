import React, { useCallback, useState } from 'react';
import PT from 'prop-types';
import { generatePath, Link } from 'react-router-dom';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

import MoveTableDialog from './MoveTableDialog';
import DeleteTableDialog from './DeleteTableDialog/DeleteTableDialog';
import RenameTableDialog from './RenameTableDialog';

import { routes } from '../../../../services/session/constants';
import { TABS } from '../../../TableSettings/constants';
import DuplicateTableDialog from './DuplicateTableDialog';
import { hasAdminAccess } from '../../../../utils/roles';

const MenuListProps = {
  dense: true
};

const TableActionsMenu = ({
  rowData,
  onClose,
  anchorEl,
}) => {
  const [openMoveDialog, setOpenMoveDialog] = useState(false);
  const [openDuplicateDialog, setOpenDuplicateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openRenameDialog, setOpenRenameDialog] = useState(false);

  const handleMove = useCallback(() => {
    setOpenMoveDialog(state => !state);
    onClose();
  }, [onClose]);

  const handleDuplicate = useCallback(() => {
    setOpenDuplicateDialog(state => !state);
    onClose();
  }, [onClose]);

  const handleRename = useCallback(() => {
    setOpenRenameDialog(state => !state);
    onClose();
  }, [onClose]);

  const handleDelete = useCallback(() => {
    setOpenDeleteDialog(state => !state);
    onClose();
  }, [onClose]);

  const isAdmin = hasAdminAccess(rowData.viewerMaxRole);

  const actions = [
    isAdmin ? {
      label: 'move table',
      actionFn: handleMove
    } : null,
    {
      label: 'duplicate table',
      actionFn: handleDuplicate
    },
    isAdmin ? {
      label: 'edit structure',
      link: generatePath(routes.TABLE_SETTINGS, {
        id: rowData.id,
      }),
      state: {
        tab: TABS.STRUCTURE.value
      }
    } : null,
    isAdmin ? {
      label: 'manage members',
      link: generatePath(routes.TABLE_SETTINGS, {
        id: rowData.id,
      }),
      state: {
        tab: TABS.MEMBERS.value
      }
    } : null,
    isAdmin ? {
      label: 'rename',
      actionFn: handleRename
    } : null,
    isAdmin ? {
      label: 'Delete',
      actionFn: handleDelete
    } : null
  ].filter(Boolean);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        MenuListProps={MenuListProps}
      >
        {actions.map(i => (
          <MenuItem
            key={i.label}
            onClick={i.actionFn}
            component={i.link ? Link : MenuItem}
            to={i.link ?? null}
            state={i.state}
          >
            <ListItemText sx={{ textTransform: 'capitalize' }}>
              {i.label}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>

      {openMoveDialog ?
        <MoveTableDialog
          onClose={handleMove}
          id={rowData.id}
          hash={rowData.hash}
          defaultFolder={rowData.folderId}
        />
        : null
      }

      {openDuplicateDialog ?
        <DuplicateTableDialog
          tableId={rowData.id}
          defaultName={rowData.title}
          defaultDescription={rowData.description}
          onClose={handleDuplicate}
        />
        : null
      }

      {openDeleteDialog ?
        <DeleteTableDialog
          id={rowData.id}
          onClose={handleDelete}
        />
        : null
      }

      {openRenameDialog ?
        <RenameTableDialog
          id={rowData.id}
          hash={rowData.hash}
          defaultName={rowData.title}
          onClose={handleRename}
        />
        : null
      }
    </>
  );
};

TableActionsMenu.propTypes = {
  onClose: PT.func.isRequired,
  anchorEl: PT.instanceOf(Element),
  rowData: PT.object.isRequired,
};

export default TableActionsMenu;
