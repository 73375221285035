import React from 'react';
import { useQuery } from '@apollo/client';

import { Outlet, useLocation, Navigate } from 'react-router-dom';

import { useAuth } from '../../../context/auth/auth';

import { routes } from '../../../services/session/constants';

import VIEWER_ID from './gql/viewer-id';

const EmailNotVerified = () => {
  const { emailVerified } = useAuth();
  const location = useLocation();
  const { data, loading } = useQuery(VIEWER_ID, { skip: !emailVerified });

  if(emailVerified) {
    if(loading)
      return null; // TODO: find a better intermediate screen state

    if(data?.viewer) {
      const dest = location.state?.from ?? routes.HOME_PAGE;

      return (
        <Navigate
          to={dest}
          replace
        />
      );
    }

    return (
      <Navigate
        to={routes.REGISTRATION_DETAILS}
        replace
        state={{ from: location.state?.from }}
      />
    );
  }

  return (
    <Outlet />
  );
};

export default EmailNotVerified;
