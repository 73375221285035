import React, { useCallback } from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import ClearIcon from '@mui/icons-material/Clear';

import Select from '../Select';

import { getChartTypeOptions } from '../../../../../ChartSettingsDialog';
import { getGraphicsViewType } from '../../../../../../services/graphics/utils';

const ChartSettings = ({
  id,
  chartType,
  settingOptions,
  optionValues,
  value,
  onGraphicsConfigChange,
  onGraphicsTypeChange,
  onRemoveGraphics
}) => {
  const chartTypes = getChartTypeOptions();

  const handleTypeChange = useCallback(ev => {
    onGraphicsTypeChange(id, getGraphicsViewType(ev.target.value));
  }, [id, onGraphicsTypeChange]);

  const handleConfigChange = useCallback(ev => {
    const { name, value: configValue } = ev.target;

    onGraphicsConfigChange(id, {
      ...value,
      [name]: configValue
    });
  }, [id, value, onGraphicsConfigChange]);

  const handleTransformConfigChange = useCallback(ev => {
    const { name, checked } = ev.target;

    onGraphicsConfigChange(id, {
      ...value,
      axes: {
        ...value.axes,
        [name]: {
          transform: checked ? 'log' : null
        }
      }
    });
  }, [id, value, onGraphicsConfigChange]);

  const handleRemoveGraphics = useCallback(() => {
    onRemoveGraphics(id);
  }, [onRemoveGraphics, id]);

  return (
    <Box
      sx={{
        mb: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px'
        }}
      >
        <Select
          required
          label="Graphics type"
          value={chartType}
          options={chartTypes}
          onChange={handleTypeChange}
        />

        {onRemoveGraphics ?
          <Tooltip
            title="Remove chart"
            placement="top"
          >
            <IconButton
              size="small"
              onClick={handleRemoveGraphics}
              sx={{ mt: '22px' }}
            >
              <ClearIcon fontSize="16px" />
            </IconButton>
          </Tooltip> :
          null
        }
      </Box>

      {settingOptions.axes.map(({
        id,
        required,
        multiple,
        label,
        name,
        transform,
        defaultValue
      }) => (
        <React.Fragment key={id}>
          <Select
            required={required}
            label={label}
            name={name}
            value={value[name] ?? defaultValue}
            multiple={multiple}
            options={optionValues}
            onChange={handleConfigChange}
          />

          {(settingOptions.allowAxisTransform && transform !== void 0) ?
            <FormControlLabel
              control={
                <Checkbox
                  checked={value.axes[name]?.transform === 'log'}
                  onChange={handleTransformConfigChange}
                  value="log"
                  name={name}
                  color="primary"
                  disableRipple
                  size="small"
                />
              }
              componentsProps={{
                typography: { variant: 'body2' }
              }}
              label="Plot axis using logarithmic scale"
              sx={{
                mt: '-8px'
              }}
            /> :
            null
          }
        </React.Fragment>
      ))}
    </Box>
  );
};

ChartSettings.propTypes = {
  id: PT.string,
  chartType: PT.string,
  settingOptions: PT.object,
  optionValues: PT.array,
  value: PT.object,
  onGraphicsConfigChange: PT.func,
  onGraphicsTypeChange: PT.func,
  onRemoveGraphics: PT.func
};

export default ChartSettings;
