import identity from 'lodash/identity';

import mapSurfaceData from './map-surface-data';
import * as constants from './constants';
import { PARSER_VIEW_TYPES } from '../parsing';
import { GRAPHICS_TYPES } from '../../services/graphics/constants';

export const getResponseTypeByViewType = value => {
  switch (value) {
    case PARSER_VIEW_TYPES.VIEW_IMAGE:
    case PARSER_VIEW_TYPES.VIEW_PDF:
    case PARSER_VIEW_TYPES.VIEW_GRAPHICS:
      return constants.RESPONSE_TYPE_DOWNLOAD_URL;

    case PARSER_VIEW_TYPES.VIEW_2D:
    case PARSER_VIEW_TYPES.VIEW_2D_CUSTOM_AXES:
    case PARSER_VIEW_TYPES.VIEW_3D:
    case PARSER_VIEW_TYPES.VIEW_MODE_TABLE:
    case PARSER_VIEW_TYPES.VIEW_HEATMAP:
    case PARSER_VIEW_TYPES.VIEW_SCATTER_PLOT:
    case PARSER_VIEW_TYPES.VIEW_BOX_PLOT:
    case PARSER_VIEW_TYPES.VIEW_HISTOGRAM_SINGLE:
    case PARSER_VIEW_TYPES.VIEW_WAFER_MAP:
    case PARSER_VIEW_TYPES.VIEW_VECTOR_WAFER_MAP:
    case PARSER_VIEW_TYPES.VIEW_CORRELATION_MATRIX:
      return constants.RESPONSE_TYPE_JSON;

    case PARSER_VIEW_TYPES.VIEW_DOCX:
      return constants.RESPONSE_TYPE_TEXT;

    default:
      throw new Error('Unhandled parameter');
  }
};

export const getDataByViewType = (type, data) => {
  if (typeof type !== 'string') {
    throw new Error('[getDataByViewType]: incorrect params');
  }

  if (!PARSER_VIEW_TYPES[type]) {
    throw new Error(`[getDataByViewType]: ${type} is unhandled type.`);
  }

  let graphicsData;
  let mapData;
  let mapper = identity;

  if (type === PARSER_VIEW_TYPES.VIEW_3D) {
    graphicsData = data && mapSurfaceData(data);
    mapData = mapSurfaceData;
  }

  return {
    graphicsType: getGraphicsTypeByViewType(type),
    responseType: getResponseTypeByViewType(type),
    graphicsData: graphicsData || data,
    mapData,
    mapper
  };
};

export const getGraphicsTypeByViewType = type => {
  // TODO: check VIEW_MODE_TABLE
  switch(type) {
    case PARSER_VIEW_TYPES.VIEW_IMAGE:
    case PARSER_VIEW_TYPES.VIEW_GRAPHICS:
      return GRAPHICS_TYPES.GRAPHICS_TYPE_IMAGE;
    case PARSER_VIEW_TYPES.VIEW_PDF:
      return GRAPHICS_TYPES.GRAPHICS_TYPE_PDF;
    case PARSER_VIEW_TYPES.VIEW_2D:
    case PARSER_VIEW_TYPES.VIEW_2D_CUSTOM_AXES:
      return GRAPHICS_TYPES.GRAPHICS_TYPE_LINE_CHART;
    case PARSER_VIEW_TYPES.VIEW_3D:
      return GRAPHICS_TYPES.GRAPHICS_TYPE_SURFACE;
    case PARSER_VIEW_TYPES.VIEW_TABLE:
      return GRAPHICS_TYPES.GRAPHICS_TYPE_TABLE;
    case PARSER_VIEW_TYPES.VIEW_HEATMAP:
      return GRAPHICS_TYPES.GRAPHICS_TYPE_HEATMAP;
    case PARSER_VIEW_TYPES.VIEW_DOCX:
      return GRAPHICS_TYPES.GRAPHICS_TYPE_RAW_HTML;
    case PARSER_VIEW_TYPES.VIEW_SCATTER_PLOT:
      return GRAPHICS_TYPES.GRAPHICS_TYPE_SCATTER_PLOT;
    case PARSER_VIEW_TYPES.VIEW_BOX_PLOT:
      return GRAPHICS_TYPES.GRAPHICS_TYPE_BOX_PLOT;
    case PARSER_VIEW_TYPES.VIEW_HISTOGRAM_SINGLE:
      return GRAPHICS_TYPES.GRAPHICS_TYPE_HISTOGRAM_SINGLE;
    case PARSER_VIEW_TYPES.VIEW_VECTOR_WAFER_MAP:
      return GRAPHICS_TYPES.GRAPHICS_TYPE_VECTOR_WAFER_MAP;
    case PARSER_VIEW_TYPES.VIEW_WAFER_MAP:
      return GRAPHICS_TYPES.GRAPHICS_TYPE_WAFER_MAP;
    case PARSER_VIEW_TYPES.VIEW_CORRELATION_MATRIX:
      return GRAPHICS_TYPES.GRAPHICS_TYPE_CORRELATION_MATRIX;
    default:
      throw new Error(`[getGraphicsTypeByViewType]: ${type} is unhandled type.`);
  }
};
