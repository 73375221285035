import React, { useState, useCallback, useEffect, useRef } from 'react';
import firebase from 'firebase/app';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { createSearchParams, useLoaderData, useSearchParams } from 'react-router-dom';

import LinearProgress from '@mui/material/LinearProgress';

import { PageContent } from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import NoContent from '../NoContent';
import ItemSidebar from '../../components/ItemSidebar';
import Page from './styles';

import { APPLICATION, VIEWER_KEYS } from './services';

import { IS_E2E_TEST_USER } from '../../constants';
import useDocumentTitle from '../../hooks/document-title';
import { routes } from '../../services/session/constants';

import { openAppSnackbarNotification } from '../../services/snackbar-notifications/actions';
import { sidebarSources } from '../../analytics/constants';

const userType = IS_E2E_TEST_USER ? 'e2e-test' : 'regular';

function Application() {
  const [iframeHeight, setIframeHeight] = useState(0);
  const [iframeLoading, setIframeLoading] = useState(true);
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [authToken, setAuthToken] = useState(null);
  const [chosenItemId, setChosenItemId] = useState(null);

  const { id: appId, appTitle } = useLoaderData();

  const dispatch = useDispatch();

  useDocumentTitle(appTitle);

  const src = useRef();
  const iframeRef = useRef();

  const handleToggleSidebar = useCallback((id) => {
    setChosenItemId(id ?? null);
  }, []);

  useEffect(() => {
    setIframeLoading(true);
    setDashboardLoading(true);
  }, [appId]);

  const handleMessage = useCallback(ev => {
    if(ev.source !== iframeRef.current?.contentWindow)
      return;

    const message = JSON.parse(ev.data);

    if (message.hasOwnProperty('event') && message.event === 'OPEN_SNACKBAR'){
      dispatch(openAppSnackbarNotification({
        message: message.payload.message,
        variant: message.payload.type
      }));
    }

    if (message.hasOwnProperty('event') && message.event === 'OPEN_ITEM'){
      setChosenItemId(message.payload.itemId);
    }

    if(message.hasOwnProperty('docHeight'))
      setIframeHeight(message.docHeight);

    if(message.hasOwnProperty('isLoading'))
      setDashboardLoading(message.isLoading);
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  const {
    loading: loadingViewer,
    data: viewerData
  } = useQuery(VIEWER_KEYS);

  const { loading, data } = useQuery(APPLICATION, {
    variables: { id: appId }
  });

  useEffect(() => {
    const subscribe = firebase.auth().onIdTokenChanged(async (user) => {
      const token = await user.getIdToken();
      setAuthToken(token);

      if (iframeRef.current && !iframeLoading){
        iframeRef.current
          .contentWindow
          .postMessage({ event: 'AUTH_TOKEN_UPDATE', payload: token },
            data?.application.url
          );
      }
    });

    return () => subscribe();
  }, [data?.application.url, iframeLoading]);

  const handleIframeLoad = useCallback(() => {
    setIframeLoading(false);
  }, []);

  const [searchParams] = useSearchParams();

  if(loading || loadingViewer)
    return (
      <Page>
        <PageContent loading={loading} />
      </Page>
    );

  if(!data || !viewerData)
    return (
      <NoContent
        contentType="app"
        defaultPageName="Home page"
        defaultPageURL={routes.HOME_PAGE}
      />
    );

  const { application } = data;
  const { viewer } = viewerData;
  const isDashboardLoading = !iframeLoading && dashboardLoading && application.displayLoading;
  const paramsList = {
    user_key: viewer.id,
    api_key: viewer.apiKey,
    user_type: userType,
    auth_token: authToken,
    filterId: searchParams.get('filterId'),
  };

  if(iframeLoading)
    src.current = `${application.url}?${createSearchParams(paramsList)}`;

  return (
    <Page isDashboardLoading={isDashboardLoading}>
      <PageContent>
        <PageTitle>{application.title}</PageTitle>

        <div id="loading-spinner-container">
          <div id="loading-spinner"></div>
        </div>

        <div className="iframe-container">
          {iframeLoading &&
            <LinearProgress />
          }

          <iframe
            title={application.title}
            src={src.current}
            height={iframeHeight}
            onLoad={handleIframeLoad}
            ref={iframeRef}
            allow="clipboard-read; clipboard-write *"
          />
        </div>

        <ItemSidebar
          onClose={handleToggleSidebar}
          itemId={chosenItemId}
          clickSource={sidebarSources.DASHBOARD_ITEM}
        />
      </PageContent>
    </Page>
  );
}

export default Application;
