import React from 'react';
import { Link } from 'react-router-dom';
import PT from 'prop-types';

import Typography from '@mui/material/Typography';

import { COLOR_PRIMARY } from '../../../../styles';

import { routes } from '../../../../services/session/constants';

const TableItemsNumber = ({ id, number }) => {
  const link = `${routes.ALL_ITEMS}?filterTeam=${id}`;

  return (
    <Typography
      variant="body2"
      color="rgba(0, 0, 0, 0.60)"
      component={Link}
      to={link}
      sx={{
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
          color: COLOR_PRIMARY
        }
      }}
    >
      {number} item(s)
    </Typography>
  );
};

TableItemsNumber.propTypes = {
  id: PT.string.isRequired,
  number: PT.number.isRequired,
};

export default TableItemsNumber;
