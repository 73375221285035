import React, { useEffect, useMemo } from 'react';
import { useAnalytics } from 'use-analytics';
import PropTypes from 'prop-types';

import useMediaQuery from '@mui/material/useMediaQuery';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import VIDEO_LINKS from './constants';
import { componentNames, TRACK_HELP_VIDEO } from '../../analytics/constants';

const VideoHelperDialog = ({ link, onClose }) => {

  const { track } = useAnalytics();

  useEffect(() => {
    if (link){
      let name = '';

      switch (link) {
        case VIDEO_LINKS.SAVED_VIEW:
          name = 'saved_views';
          break;
        case VIDEO_LINKS.CREATE_TABLE:
          name = 'table';
          break;
      }

      track(TRACK_HELP_VIDEO.open, {
        component: componentNames.HELP,
        additional_info: {
          ai_name: name
        }
      });
    }
  }, [link, track]);

  const dialogTitle = useMemo(() => {
    switch (link) {
      case VIDEO_LINKS.SAVED_VIEW: {
        return 'Saved views';
      }
      case VIDEO_LINKS.CREATE_TABLE: {
        return 'Create table and folders';
      }
      case VIDEO_LINKS.CREATE_ITEM: {
        return 'Add item';
      }
      case VIDEO_LINKS.VIOLIN_PLOT: {
        return 'Violin plot';
      }
      case VIDEO_LINKS.CORRELATIONS: {
        return 'Correlations';
      }
      default:
        return '';
    }
  }, [link]);

  const maxDialogWidth = '672px';
  const isFullscreen = useMediaQuery(`(max-width:${maxDialogWidth})`);

  return (
    <Dialog
      open={Boolean(link)}
      onClose={onClose}
      fullScreen={isFullscreen}
      fullWidth={!isFullscreen}
      sx={{
        '.MuiDialog-paperFullWidth': {
          maxWidth: maxDialogWidth
        }
      }}
    >
      <DialogTitle>
        {dialogTitle}
      </DialogTitle>

      <DialogContent
        sx={{
          padding: '16px'
        }}
      >
        <iframe
          style={{
            width: `100%`,
            border: 0,
            aspectRatio: '16/9'//Ratio of the video in 16/9
          }}
          src={link}
        >
        </iframe>
      </DialogContent>

      {isFullscreen ?
        <DialogActions>
          <Button
            onClick={onClose}
          >
            Close
          </Button>
        </DialogActions>
        : null
      }
    </Dialog>
  );
};

VideoHelperDialog.propTypes = {
  link: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default VideoHelperDialog;
