import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

export default function tooltip(Component) {
  class TooltipComponent extends React.Component {
    static propTypes = {
      title: PropTypes.string.isRequired,
      placement: PropTypes.string,
      onClick: PropTypes.func
    };

    state = { open: false };

    handleOpen = () => {
      this.setState({ open: true });
    };

    handleClose = () => {
      this.setState({ open: false });
    };

    handleClick = e => {
      this.setState({ open: false });
      this.props.onClick(e);
    };

    render() {
      const { open } = this.state;
      const { title, placement, onClick, ...props } = this.props;
      const dynamicProps = {};

      if (onClick) {
        dynamicProps.onClick = this.handleClick;
      }

      return (
        <Tooltip
          title={title}
          open={open}
          placement={placement}
        >
          <Component
            {...props}
            {...dynamicProps}
            onMouseOver={this.handleOpen}
            onMouseLeave={this.handleClose}
          />
        </Tooltip>
      );
    }
  }

  return TooltipComponent;
}
