import React, { useCallback } from 'react';
import PT from 'prop-types';
import split from 'lodash/split';
import identity from 'lodash/identity';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import FormHelperText from '@mui/material/FormHelperText';

import { COLOR_ERROR } from '../../../../../../../../../styles';
import { REGEXP_OPTION_TITLES_SEPARATOR } from './constants';

const toOptionsList = value => {
  const list = split(
    value,
    REGEXP_OPTION_TITLES_SEPARATOR
  )
    .filter(identity)
    .map(value => value.trim());

  return list;
};

const EnumDialogOption = ({
  error,
  onRemove,
  provider,
  optionId,
  value,
  onNewOptions,
  onChange
}) => {
  const handleRemove = useCallback(() => {
    onRemove(optionId);
  }, [optionId, onRemove]);

  const handlePaste = useCallback(ev => {
    ev.preventDefault();

    const value = ev.clipboardData.getData('text');
    const [headOption, ...restNewOptions] = toOptionsList(value);

    onChange(optionId, headOption);

    if (restNewOptions.length) {
      onNewOptions(restNewOptions.map(i => ({ title: i })));
    }
  }, [onChange, onNewOptions, optionId]);

  const handleChange = useCallback(ev => {
    onChange(optionId, ev.target.value);
  }, [optionId, onChange]);

  return (
    <Box
      ref={provider.innerRef}
      sx={{ display: 'flex', flexDirection: 'column' }}
      {...provider.draggableProps}
    >
      <Box sx={{ display: 'flex', gap: '6px', flexGrow: 1 }}>
        <IconButton
          aria-label="drag"
          size="small"
          {...provider.dragHandleProps}
        >
          <DragIndicatorIcon fontSize="inherit" />
        </IconButton>

        <InputBase
          autoFocus={!value}
          value={value ?? ''}
          onChange={handleChange}
          onPaste={handlePaste}
          fullWidth
          sx={{
            height: '32px',
            px: '8px',
            py: '2px',
            background: 'white',
            border: '1px solid #DCDBDC',
            borderRadius: '4px',
            '.MuiInputBase-input': {
              padding: 0
            },
            fontSize: '14px',
            lineHeight: '18px'
          }}
        />

        <IconButton
          aria-label="drag"
          size="small"
          onClick={handleRemove}
        >
          <DeleteOutlineIcon fontSize="inherit" />
        </IconButton>
      </Box>

      {error &&
        <FormHelperText sx={{ ml: '34px', color: COLOR_ERROR }}>
          {error}
        </FormHelperText>
      }
    </Box>
  );
};

EnumDialogOption.propTypes = {
  error: PT.string,
  onNewOptions: PT.func.isRequired,
  onRemove: PT.func.isRequired,
  onChange: PT.func.isRequired,
  provider: PT.shape({
    innerRef: PT.func,
    draggableProps: PT.object,
    dragHandleProps: PT.object,
  }),
  optionId: PT.string.isRequired,
  value: PT.string.isRequired
};

export default EnumDialogOption;
