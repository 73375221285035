import React, { useCallback, useState } from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import SingleColumn from './SingleColumn';

const SingleProtocol = ({ data, editor, open }) => {
  const [localOpen, setLocalOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setLocalOpen(state => !state);
  }, []);

  const openState = open || localOpen;

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: 'fit-content',
          p: '2px 0',
          '&:hover': {
            cursor: 'pointer'
          }
        }}
        onClick={handleOpen}
      >
        {openState ?
          <KeyboardArrowDownIcon fontSize="12px" /> :
          <KeyboardArrowRightIcon fontSize="12px" />
        }

        <Typography
          sx={{
            fontSize: '12px',
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        >
          {data.title}
        </Typography>
      </Box>

      <Collapse in={openState} timeout="auto" unmountOnExit>
        <Box
          ml="24px"
        >
          {data.tableParameters.map((child) => (
            <SingleColumn
              key={child.id}
              data={child}
              editor={editor}
            />
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

SingleProtocol.propTypes = {
  data: PT.object.isRequired,
  editor: PT.object.isRequired,
  open: PT.bool,
};

export default SingleProtocol;
