import React, { useState, useCallback } from 'react';
import PT from 'prop-types';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import AddUsersDialog from './AddUsersDialog';

const AddUsers = ({ onSubmit, defaultUserIds, readOnly }) => {
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);

  const handleAddUserClick = useCallback(() => {
    setShowAddUserDialog(true);
  }, []);

  const handleAddUserDialogClose = useCallback(() => {
    setShowAddUserDialog(false);
  }, []);

  const handleAddUserSubmit = useCallback(async (users, organization) => {
    await onSubmit(users, organization);

    setShowAddUserDialog(false);
  }, [onSubmit]);

  return (
    <>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleAddUserClick}
        disabled={readOnly}
        sx={{
          textTransform: 'capitalize',
          lineHeight: 1,
          mb: '16px'
        }}
      >
        Add Member
      </Button>

      {showAddUserDialog &&
        <AddUsersDialog
          defaultUserIds={defaultUserIds}
          onSubmit={handleAddUserSubmit}
          onClose={handleAddUserDialogClose}
        />
      }
    </>
  );
};

AddUsers.propTypes = {
  onSubmit: PT.func.isRequired,
  defaultUserIds: PT.array.isRequired,
  readOnly: PT.bool
};

export default AddUsers;
