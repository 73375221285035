import React from 'react';
import PropTypes from 'prop-types';

import NotificationTypeIcon from './NotificationTypeIcon';
import Text from './Text';
import NotificationsTimestamp from './NotificationsTimestamp';

import { Wrapper, Content } from './styles';

const NotificationsBody = ({ timestamp, ...props }) => {

  return (
    <Wrapper>
      <NotificationTypeIcon {...props} />

      <Content>
        <Text {...props} />

        <NotificationsTimestamp timestamp={timestamp} />
      </Content>
    </Wrapper>
  );
};

NotificationsBody.propTypes = {
  timestamp: PropTypes.number.isRequired
};

export default NotificationsBody;
