import { mapInputToStateParamsValues } from '../../../ParametersFilter/utils';
import { createParameter, filterTypes } from '../index';

const createInitialParameters = (data, featuresById) => {
  if (Object.keys(data).length === 1 && !data.featureSliceBys?.length) {
    return [createParameter(null, filterTypes.PARAMETER)];
  }

  return Object.keys(data).reduce((acc, filter) => {
    switch (filter){
      case 'creatorIds': {
        return [
          ...acc,
          createParameter(data[filter], filterTypes.CREATOR_IDS)
        ];
      }
      case 'createdTimestamp': {
        return [
          ...acc,
          createParameter(data[filter], filterTypes.CREATED_DATE)
        ];
      }
      case 'featureSliceBys':
      default:
      {
        return [
          ...acc,
          ...data[filter].filter(v => featuresById?.[v.featureId])
            .map(
              mapInputToStateParamsValues(featuresById)
            )
        ];
      }
    }
  }, []);
};

export default createInitialParameters;
