import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';

import withStyles from '@mui/styles/withStyles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import AuthPageStyled from '../../components/AuthPageStyled';
import LogoMain from '../../components/Logos/LogoMain';
import RaisedInput from '../../components/RaisedInput';
import { AuthFormStyled } from '../../components/AuthPageStyled/AuthContainerStyled';
import SignInButton from '../Login/SignInButton';

import { logout, onCreateUser } from '../../services/session/actions';
import {
  getAuthUser,
  getError,
  getErrorMsg,
  isFetching,
  isAuthBySocialProviders
} from '../../services/session/selectors';
import { CREATE_USER, GET_INVITE } from './services';
import useDocumentTitle from '../../hooks/document-title';

const mapStateToProps = state => ({
  authUser: getAuthUser(state),
  error: getError(state),
  errorMessage: getErrorMsg(state),
  isFetching: isFetching(state),
  isAuthBySocialProviders: isAuthBySocialProviders(state)
});

const mapDispatchToProps = {
  logout,
  onCreateUser
};

const styles = {
  inputLabel: {
    transform: 'translate(0, -2px) scale(0.75)'
  },
  helperText: {
    marginLeft: 0
  }
};

const RegistrationDetails = ({
  authUser,
  logout,
  errorMessage,
  isFetching,
  isAuthBySocialProviders,
  onCreateUser,
  classes
}) => {
  useDocumentTitle('Registration Details');

  const [state, setState] = useState({});
  const [error, setError] = useState({});

  const [createUser] = useMutation(CREATE_USER, {
    onCompleted: () => {
      onCreateUser();
    }
  });

  const inviteResponse = useQuery(GET_INVITE);
  const invite = inviteResponse?.data?.invite;

  useEffect(() => {
    let displayName = '';

    if (authUser && authUser.displayName) displayName = authUser.displayName;

    const firstName = displayName.split(/\W+/g)[0] || '';
    const lastName = displayName.split(/\W+/g)[1] || '';

    setState({
      firstName,
      lastName,
      userid: displayName.split(/\W+/g).join('_'),
      accountTitle: '',
      isTermsAccepeted: !isAuthBySocialProviders
    });
  }, [authUser, isAuthBySocialProviders]);

  const checkError = useCallback(source => {
    return error && error.source === source;
  }, [error]);

  const getError =  useCallback(source => {
    if (!error) return null;

    return error.source === source ? error.message || true : null;
  }, [error]);

  const handleFirstNameChange =  useCallback(ev => {
    const { value } = ev.target;
    setState((state) => ({
      ...state,
      firstName: value
    }));
  }, []);

  const handleLastNameChange =  useCallback(ev => {
    const { value } = ev.target;
    setState((state) => ({
      ...state,
      lastName: value
    }));
  }, []);

  const handleUseridChange =  useCallback(ev => {
    const { value } = ev.target;
    setState((state) => ({
      ...state,
      userid: value
    }));
  }, []);

  const handleSubmit =  useCallback(async ev => {
    ev.preventDefault();

    const {
      firstName,
      lastName,
      userid,
    } = state;
    try {
      await createUser({
        variables: {
          input: {
            userId: userid,
            firstName,
            lastName
          }
        }
      });
    } catch (e) {
      console.error(e);

      setError({ source: 'general', message: e.message ?? 'Failed to create a user' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, createUser]);

  const handleSignOut =  useCallback(() => {
    logout();
  }, [logout]);

  const handleTermsAcceptedChange =  useCallback((ev, checked) => {
    setState((state) => ({
      ...state,
      isTermsAccepeted: checked
    }));
  }, []);

  const {
    firstName,
    lastName,
    userid,
    isTermsAccepeted,
  } = state;

  const generalError = getError('general');

  let formError = null;

  if (generalError)
    formError = (
      <FormHelperText error={true}>
        {generalError || 'Failed to create a user'}
      </FormHelperText>
    );

  if (!inviteResponse.loading && !invite)
    return (
      <AuthPageStyled>
        <div className="header">
          <LogoMain />
        </div>

        <div className="auth-error-container">
          <p>We couldn't find an invitation for the provided email address.</p>

          <p>
            If you were invited to the Materials Zone platform, you must sign up using the email address the invitation&nbsp;
            was sent to. Click <a href="/signup" onClick={handleSignOut}>here</a> to return to the signup page.
          </p>

          <p>
            If you don’t have an invitation, please&nbsp;
            <a href="mailto:contact@materials.zone">contact our sales team </a>
            for more information on how to join the Materials Zone platform.
          </p>
        </div>
      </AuthPageStyled>
    );

  return (
    <AuthPageStyled>
      <div className="header">
        <LogoMain />
      </div>

      <h1>Tell us about yourself</h1>

      <div className="auth-forms-container">
        <AuthFormStyled action="/" onSubmit={handleSubmit}>
          <FormControl error={checkError('firstName')} disabled={isFetching}>
            <InputLabel
              className={classes.inputLabel}
              htmlFor="firstname-input"
              shrink
            >
              First name *
            </InputLabel>
            <RaisedInput
              id="firstname-input"
              type="text"
              placeholder="First name"
              onChange={handleFirstNameChange}
              value={firstName}
              inputProps={{
                maxLength: '80',
                required: true
              }}
            />
          </FormControl>
          <FormControl error={checkError('lastName')} disabled={isFetching}>
            <InputLabel
              className={classes.inputLabel}
              htmlFor="lastname-input"
              shrink
            >
              Last name *
            </InputLabel>
            <RaisedInput
              id="lastname-input"
              type="text"
              placeholder="Last name"
              onChange={handleLastNameChange}
              value={lastName}
              inputProps={{
                maxLength: '80',
                required: true
              }}
            />
          </FormControl>

          <FormControl
            error={checkError('userid')}
            disabled={isFetching}
          >
            <InputLabel
              className={classes.inputLabel}
              htmlFor="userid-input"
              shrink
            >
              Userid *
            </InputLabel>

            <RaisedInput
              id="userid-input"
              type="text"
              placeholder="Userid"
              onChange={handleUseridChange}
              value={userid}
              inputProps={{
                maxLength: '80',
                required: true,
                pattern: '\\w+'
              }}
            />

            <FormHelperText
              id="userid-input-help"
              className={classes.helperText}
            >
              Should only contain latin letters, numbers and '_'
            </FormHelperText>

            {checkError('userid') && (
              <FormHelperText id="userid-input-error">
                {errorMessage}
              </FormHelperText>
            )}
          </FormControl>

          {isAuthBySocialProviders &&
          <FormControlLabel
            control={<Checkbox disableRipple color="primary" />}
            onChange={handleTermsAcceptedChange}
            label={
              <span>
                I agree to Materials Zone{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.materials.zone/privacy-and-terms"
                >
                  Privacy Policy and Terms of Use
                </a>
              </span>
            }
          />
          }

          <SignInButton
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={
              isFetching
              || inviteResponse.loading
              || !isTermsAccepeted
            }
          >
            Continue
          </SignInButton>

          <FormHelperText>
            Fields marked with asterisk (*) are required
          </FormHelperText>

          {formError}
        </AuthFormStyled>
      </div>

      <p className="change-auth-flow">
        {`Already have a team in Materials.Zone? `}
        <a href="/signin" onClick={handleSignOut}>
          Sign in
        </a>
      </p>
    </AuthPageStyled>
  );
};

RegistrationDetails.propTypes = {
  logout: PropTypes.func.isRequired,
  authUser: PropTypes.object,
  errorMessage: PropTypes.string,
  isFetching: PropTypes.bool,
  isAuthBySocialProviders: PropTypes.bool.isRequired,
  onCreateUser: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(styles)(RegistrationDetails)
);
