import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

import { GET_FOLDERS, DUPLICATE_TABLE } from './gql';
import { TABLES } from '../../../gql';

import { COLOR_BLACK } from '../../../../../styles';
import { hasAdminAccess } from '../../../../../utils/roles';

const styles = {
  formControl: {
    marginTop: '10px'
  },
  label: {
    position: 'relative',
    transform: 'translate(0, 6px) scale(0.75)',
    color: COLOR_BLACK
  },
  input: {
    borderRadius: '4px',
    height: '32px',
    fontSize: '14px',
    '.MuiOutlinedInput-input': {
      p: '4px 8px'
    }
  },
  textarea: {
    borderRadius: '4px',
    fontSize: '14px',
    p: '4px 8px'
  },
  error: {
    marginTop: '10px'
  },
};

const DuplicateTableDialog = ({
  onClose,
  tableId,
  defaultName,
  defaultDescription,
  refetchList
}) => {

  const { data } = useQuery(GET_FOLDERS);
  const [duplicateTable, { loading }] = useMutation(DUPLICATE_TABLE, {
    refetchQueries: refetchList ?? [TABLES],
  });

  const [title, setTitle] = useState(`Copy of ${defaultName}`);
  const [description, setDescription] = useState(defaultDescription ?? '');
  const [folder, setFolder] = useState('');
  const [error, setError] = useState(null);

  const handleTitleChange = useCallback((e) => {
    setTitle(e.target.value);
  }, []);

  const handleDescriptionChange = useCallback((e) => {
    setDescription(e.target.value);
  }, []);

  const handleFolderChange = useCallback((ev) => {
    setFolder(ev.target.value);
  }, []);

  const handleSubmit = useCallback(async () => {
    setError(null);

    const variables = {
      cloneTableId: tableId,
      data: {
        title: title,
        folderId: folder || null
      },
    };

    if (description)
      variables.data.description = description;

    try {
      await duplicateTable({
        variables
      });

      onClose();
    } catch (e) {
      setError(e.message);
    }
  }, [duplicateTable, tableId, title, description, folder, onClose]);

  const sortedFolders = useMemo(() => {
    const folders = data?.folders || [];

    return folders.filter(folder => hasAdminAccess(folder.viewerMaxRole))
      .sort((first, second) => first.title.localeCompare(second.title));
  }, [data?.folders]);

  return (
    <Dialog
      open
      onClose={onClose}
    >
      <DialogTitle>
        Duplicate Table
      </DialogTitle>

      <DialogContent>
        <FormControl
          required
          fullWidth
        >
          <InputLabel
            shrink
            sx={styles.label}
          >
            Name
          </InputLabel>

          <OutlinedInput
            sx={styles.input}
            value={title}
            onChange={handleTitleChange}
            autoFocus
          />
        </FormControl>

        <FormControl
          fullWidth
          sx={styles.formControl}
        >
          <InputLabel
            shrink
            sx={styles.label}
          >
            Description
          </InputLabel>

          <OutlinedInput
            sx={styles.textarea}
            multiline
            value={description}
            onChange={handleDescriptionChange}
            rows={2}
          />
        </FormControl>

        <FormControl
          fullWidth
          sx={styles.formControl}
        >
          <InputLabel
            shrink
            sx={styles.label}
          >
            Folder
          </InputLabel>

          <Select
            sx={styles.input}
            value={folder}
            onChange={handleFolderChange}
            renderValue={(value) => (
              <Typography variant="body2">
                {value ? sortedFolders.find(folder => folder.id === value).title : ''}
              </Typography>
            )}
          >
            <MenuItem
              value={''}
            >
              None
            </MenuItem>

            {sortedFolders.map(folder => (
              <MenuItem
                key={folder.id}
                value={folder.id}
              >
                {folder.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {error ?
          <FormHelperText
            error
            sx={styles.error}
          >
            {error}
          </FormHelperText>
          : null
        }
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
        >
          Cancel
        </Button>

        <LoadingButton
          loading={loading}
          onClick={handleSubmit}
          disabled={!title}
          variant="contained"
        >
          Duplicate
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DuplicateTableDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  tableId: PropTypes.string.isRequired,
  defaultName: PropTypes.string.isRequired,
  defaultDescription: PropTypes.string,
  refetchList: PropTypes.array,
};

export default DuplicateTableDialog;
