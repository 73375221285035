import React, { useCallback } from 'react';
import PT from 'prop-types';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MUISelect from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const checkBoxIconProps = {
  fontSize: '16px',
  sx: {
    mr: '6px'
  }
};

const Select = ({
  label,
  name,
  value,
  required,
  multiple,
  options,
  inputProps,
  onChange,
  ...rest
}) => {
  const renderValue = useCallback(selected => {
    if(!Array.isArray(selected)) {
      const item = options.find(({ value }) => selected === value);

      return item?.name ?? '';
    }

    return selected.length ?
      options.reduce(
        (result, { name, value }) =>
          selected.includes(value) ?
            result.concat(name) :
            result,
        []
      ).join(', ') :
      '';
  }, [options]);

  return (
    <FormControl
      fullWidth {...rest}
      required={required}
    >
      <InputLabel
        variant="outlined"
        disableAnimation
        shrink
        sx={{
          transform: 'translate(0px, 0px) scale(1)',
          fontSize: '12px',
          lineHeight: '20px',
          color: 'rgba(0,0,0,.6)'
        }}
      >
        {label}
      </InputLabel>

      <MUISelect
        multiple={multiple}
        name={name}
        value={value}
        onChange={onChange}
        input={
          <InputBase
            sx={{
              mt: '22px',
              px: '10px',
              py: '6px',
              background: 'white',
              border: '1px solid #DCDBDC',
              borderRadius: '4px',
              '.MuiInputBase-input': {
                padding: 0
              },
              fontSize: '14px'
            }}
            inputProps={inputProps}
          />
        }
        renderValue={renderValue}
      >
        {options?.map(({ value: optionValue, name }) => (
          <MenuItem
            key={optionValue}
            value={optionValue}
          >
            {multiple ?
              (value?.indexOf(optionValue) > -1 ?
                <CheckBoxIcon
                  color="primary"
                  {...checkBoxIconProps}
                /> :
                <CheckBoxOutlineBlankIcon
                  {...checkBoxIconProps}
                />
              ) :
              null
            }

            <ListItemText
              primary={name}
              primaryTypographyProps={{
                variant: 'body2'
              }}
            />
          </MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  );
};

Select.propTypes = {
  label: PT.string,
  name: PT.string,
  value: PT.oneOfType([
    PT.string,
    PT.number,
    PT.array
  ]),
  required: PT.bool,
  multiple: PT.bool,
  options: PT.arrayOf(PT.shape({
    value: PT.any,
    name: PT.string
  })),
  inputProps: PT.object,
  onChange: PT.func
};

export default Select;
