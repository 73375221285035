export * from '../../constants';

export const processingSingleSubjects = Object.freeze({
  INSTRUMENT: 'instrument',
  PHYSICAL_MEASUREMENT: 'physicalMeasurement',
  PARSER: 'parser',
  PRESENTATION: 'presentation',
  CODE: 'code'
});

export const FILES_LIST_LOAD = 'FILES_LIST_LOAD';
