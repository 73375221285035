import styled from 'styled-components';
import {
  COLOR_PAGE_BACKGROUND,
  MEDIA_QUERIES_XS,
} from '../../styles';

export default styled.div`
  width: 100%;
  padding: 24px;
  padding-top: 0;
  background-color: ${COLOR_PAGE_BACKGROUND};
  overflow-y: auto;

  .page-title {
    padding: 0;
  }

  .pane {
    height: 24px;
    background-color: ${COLOR_PAGE_BACKGROUND};
  }

  @media (${props => props.mediaQueries || MEDIA_QUERIES_XS}) {
    padding: 0;

    .pane {
      height: 0;
    }

    .page-title {
      padding: 8px;
    }
  }
`;
