import React, { useCallback } from 'react';
import PT from 'prop-types';

import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { ReactComponent as InfoIcon } from '../../../images/info.svg';

import { WrapperStyled, IconStyled } from '../SingleProtocol/styles';

import { getTooltipText } from '../SingleProtocol/utils';

const ProtocolContainer = ({ id, open, disableExpand, data, onClose, children }) => {
  const handleClose = useCallback(() => {
    if(!disableExpand)
      onClose(id);
  }, [id, disableExpand, onClose]);

  return (
    <WrapperStyled
      isopen={open ? 'true' : undefined}
      active={!disableExpand}
      className="singleProtocol"
    >
      <ListItemButton
        onClick={handleClose}
        disableRipple={disableExpand}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '6px'
          }}
        >
          <ListItemText
            sx={{
              '.MuiTypography-root': {
                fontSize: 14,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                maxWidth: '400px'
              }
            }}
          >
            {data?.title}
          </ListItemText>

          <IconStyled>
            <Tooltip
              title={
                <Box sx={{ whiteSpace: 'pre-line' }}>
                  {getTooltipText(data)}
                </Box>
              }
            >
              <InfoIcon />
            </Tooltip>
          </IconStyled>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
          }}
        >
          {!disableExpand ?
            <KeyboardArrowDownIcon
              sx={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                fontSize: '16px'
              }}
            />
            : null
          }
        </Box>
      </ListItemButton>

      <Collapse
        in={open}
      >
        <Box
          sx={{
            padding: '8px'
          }}
        >
          {children}
        </Box>
      </Collapse>

      <Divider />
    </WrapperStyled>
  );
};

ProtocolContainer.propTypes = {
  id: PT.string,
  open: PT.bool,
  disableExpand: PT.bool,
  data: PT.shape({
    title: PT.string,
    username: PT.string,
    dateCreated: PT.number,
    dateUpdated: PT.number
  }),
  onClose: PT.func,
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node
  ])
};

export default ProtocolContainer;
