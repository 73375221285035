export * from '../../constants';

export const USERS_LIST_GET = 'USERS_LIST_GET';
export const USER_BY_ID_GET = 'USER_BY_ID_GET';
export const ALL_USERS_LIST_GET = 'ALL_USERS_LIST_GET';
export const USERS_ADD = 'USERS_ADD';
export const USERS_LIST_WITH_ACCOUNTS_GET = 'USERS_LIST_WITH_ACCOUNTS_GET';
export const AVATARS_LIST_GET = 'AVATARS_LIST_GET';
export const QR_CODE_TITLE_SIZE =
  'QR_CODE_TITLE_SIZE';
