import { gql } from '@apollo/client';

const UPDATE_INFO = gql`
  mutation UpdateTableItem($updateTableItemId: ID!, $data: UpdateTableItemInput!) {
    updateTableItem(id: $updateTableItemId, data: $data) {
      id
      description
      dateFabricated
    }
  }
`;

export default UPDATE_INFO;
