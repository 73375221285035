import React, { useState, useCallback } from 'react';
import PT from 'prop-types';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import { generatePathWithSearchParams } from '../../../../utils';
import SavedViewIcon from '../../../../components/SavedViews/SavedViewIcon';
import { routes } from '../../../../services/session/constants';

const RestViews = ({ views }) => {
  const [menuAnchorEl, setMenuAnchorElement] = useState(null);

  const handleOpenMenu = useCallback(ev => {
    setMenuAnchorElement(ev.target);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setMenuAnchorElement(null);
  }, []);

  return (
    <>
      <Box
        id="rest-views-button"
        height="80px"
        display="flex"
        border="1px solid #EEEEEE"
        borderRadius="4px"
        p="8px"
        mt="8px"
        backgroundColor="#F9F9F9"
        sx={{
          '&:hover': {
            cursor: 'pointer',
            boxShadow: `0px 3px 6px 0px #0000001A`
          }
        }}
        onClick={handleOpenMenu}
      >
        <Typography variant="body2">
          +{views?.length}
        </Typography>
      </Box>

      <Menu
        id="saved-views-menu"
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        MenuListProps={{
          'aria-labelledby': 'rest-views-button',
          dense: true,
          component: 'div'
        }}
        sx={{
          '.MuiMenu-paper': {
            'maxWidth': '300px'
          }
        }}
        onClose={handleCloseMenu}
      >
        {views.map(({ id, title, dashboardId }) => (
          <Tooltip
            key={id}
            title={`Saved view: ${title}`}
            placement="left-start"
          >
            <MenuItem
              component={Link}
              to={generatePathWithSearchParams(routes.ALL_ITEMS, {
                savedView: id
              })}
            >
              <SavedViewIcon
                dashboardId={dashboardId}
                styles={{
                  color: 'inherit'
                }}
              />

              <Typography noWrap variant="body2">
                {title}
              </Typography>
            </MenuItem>
          </Tooltip>
        ))}
      </Menu>
    </>
  );
};

RestViews.propTypes = {
  views: PT.arrayOf(PT.shape({
    id: PT.string.isRequired,
    title: PT.string.isRequired
  }))
};

export default RestViews;
