import React from 'react';
import { AnalyticsProvider } from 'use-analytics';

import analytics from '../../analytics';

export default function withAnalyticsProvider(Component) {
  return props => (
    <AnalyticsProvider instance={analytics}>
      <Component {...props} />
    </AnalyticsProvider>
  );
}
