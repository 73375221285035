import styled from 'styled-components';

import { COLOR_PRIMARY } from '../../../styles';

export const ApiKeySettingsContainer = styled.div`
  && {
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.01071em;
  }
`;

export const Link = styled.a`
  color: ${COLOR_PRIMARY};
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
`;
