import omit from 'lodash/omit';

import * as constants from '../constants';

const initialState = {};

export default function quickCreate(state = initialState, action) {
  switch(action.type) {
    case constants.QUICK_CREATE_OPEN:
      return {
        ...state,
        [action.sampleId]: action.assetType
      };

    case constants.QUICK_CREATE_CLOSE:
      return omit(state, action.sampleId);

    default:
      return state;
  }
}
