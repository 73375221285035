export const TOOLBAR_HEIGHT_PX = 60;
export const FILTER_BAR_HEIGHT = 32;
export const TOOLBAR_MOBILE_VIEW_HEIGHT_PX = 144;

export const TABS_VALUES = {
  PROTOCOLS: 'PROTOCOLS',
  MEASUREMENTS: 'MEASUREMENTS',
  LINKED_ITEMS: 'LINKED_ITEMS',
  COMMENTS: 'COMMENTS',
};

export const TABLE_VIEW = 'TABLE_VIEW';
