import React, { useState, useCallback, useMemo } from 'react';
import PT from 'prop-types';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { parameterTypes } from './constants';

const TypeSelect = ({ options, selected, disabled, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = useCallback(ev => {
    setAnchorEl(ev.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleChange = useCallback(type => {
    onChange(type);
    handleClose();
  }, [onChange, handleClose]);

  const typeOptions = useMemo(() => {
    return options.map(option => parameterTypes[option]);
  }, [options]);

  const selectedType = useMemo(() => {
    if(!selected)
      return options[0];

    return selected;
  }, [selected, options]);

  const SelectedIcon = parameterTypes[selectedType].icon;
  const tooltipTitle = disabled ? 'The existing parameters in the table can not be changed' : null;

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <span>
          <Button
            variant="outlined"
            endIcon={
              <ArrowDropDownIcon />
            }
            sx={{
              height: '28px',
              py: 0,
              px: '8px',
              borderRadius: '4px',
              minWidth: '48px',
              fontSize: '16px',
              background: '#fff',
              color: '#8c8c8c',
              '&.MuiButton-outlined': {
                borderColor: '#d6d6d6'
              },
              '.MuiButton-endIcon': {
                ml: 0
              },
            }}
            disabled={disabled}
            onClick={handleOpen}
          >
            <SelectedIcon fontSize="inherit" />
          </Button>
        </span>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {typeOptions.map(({ id, label, icon: Icon }) => (
          <MenuItem
            key={id}
            selected={id === selected}
            onClick={() => { handleChange(id); }}
          >
            <ListItemIcon
              sx={{
                '&.MuiListItemIcon-root': {
                  minWidth: '14px'
                }
              }}
            >
              <Icon sx={{ fontSize: '14px' }} />
            </ListItemIcon>

            <ListItemText
              primaryTypographyProps={{
                variant: 'body2'
              }}
              sx={{
                ml: '6px'
              }}
            >
              {label}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const options = [
  'TEXT',
  'LINK',
  'BOOLEAN',
  'QUANTITY',
  parameterTypes.CALCULATION.id,
  parameterTypes.ENUM.id
];

TypeSelect.propTypes = {
  options: PT.arrayOf(PT.oneOf(options)),
  selected: PT.oneOf(options),
  disabled: PT.bool,
  onChange: PT.func
};

export default TypeSelect;
