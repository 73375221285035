import React, { useCallback } from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';

import EditEnumValueSingle from './EditEnumValueSingle';

const EditEnumValue = ({ data, tableParameter, onChange, onCancel }) => {
  const handleChangeSingleValue = useCallback((value, options) => {
    onChange(value && [value], options);
  }, [onChange]);

  return (
    <Box width="100%">
      <EditEnumValueSingle
        value={data?.[0] ?? null}
        options={tableParameter.enumValueOptions}
        onChange={handleChangeSingleValue}
        onCancel={onCancel}
      />
    </Box>
  );
};

EditEnumValue.propTypes = {
  data: PT.arrayOf(PT.shape({
    id: PT.string,
    title: PT.string
  })),
  tableParameter: PT.shape({
    enumValueOptions: PT.arrayOf(PT.shape({
      id: PT.string,
      title: PT.string
    }))
  }),
  onChange: PT.func,
  onCancel: PT.func
};

export default EditEnumValue;
