import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import useMediaQuery from '@mui/material/useMediaQuery';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';

import { DIALOG_BREAKPOINT } from '../../../../styles';
import { ChangePasswordModalContainer } from '../styles';
import OutlinedInputWithLabel from '../../../../components/OutlinedInputWithLabel';
import {
  isPasswordValid,
  PasswordPolicy
} from '../../../../components/Auth';

const useStyles = makeStyles({
  inputLabel: {
    transform: 'translate(0, 0) scale(0.75)'
  }
});

function ChangePasswordModal({
  open,
  onClose,
  onExit,
  onSubmit,
}) {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');

  const isMobileView = useMediaQuery(`(${DIALOG_BREAKPOINT})`);

  const classes = useStyles();

  const handlePasswordChange = useCallback(ev => {
    setPassword(ev.target.value);
  }, []);

  const handleNewPasswordChange = useCallback(ev => {
    setNewPassword(ev.target.value);
  }, []);

  const handleRepeatNewPasswordChange = useCallback(ev => {
    setRepeatNewPassword(ev.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit({
      password,
      newPassword
    });
  }, [onSubmit, password, newPassword]);

  const handleExit = useCallback(() => {
    setPassword('');
    setNewPassword('');
    setRepeatNewPassword('');

    if(typeof onExit === 'function')
      onExit();
  }, [onExit]);

  const isInputInalid = !isPasswordValid(newPassword)
    || !password
    || newPassword !== repeatNewPassword;

  return (
    <ChangePasswordModalContainer
      open={open}
      onClose={onClose}
      TransitionProps={{ onExit: handleExit }}
      fullScreen={isMobileView}
      aria-labelledby="change-password-modal-title"
      maxWidth="xs"
    >
      <DialogTitle id="change-password-modal-title">
        Change password
      </DialogTitle>

      <DialogContent>
        <FormControl required className="input-base-control form-control">
          <OutlinedInputWithLabel
            id="email-auth-current-password"
            label="Current password"
            value={password}
            type="password"
            onChange={handlePasswordChange}
            inputLabelProps={{ className: classes.inputLabel }}
          />
        </FormControl>

        <FormControl required className="input-base-control form-control">
          <OutlinedInputWithLabel
            id="email-auth-new-password"
            label="New password"
            value={newPassword}
            type="password"
            onChange={handleNewPasswordChange}
            inputLabelProps={{ className: classes.inputLabel }}
          />

          <FormHelperText
            className="new-password-helper-text"
            component="div"
          >
            <PasswordPolicy />
          </FormHelperText>
        </FormControl>

        <FormControl required className="input-base-control form-control">
          <OutlinedInputWithLabel
            id="email-auth-new-password-repeat"
            label="Repeat new password"
            value={repeatNewPassword}
            type="password"
            onChange={handleRepeatNewPasswordChange}
            inputLabelProps={{ className: classes.inputLabel }}
          />
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
        >
          Cancel
        </Button>

        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={isInputInalid}
        >
          Save
        </Button>
      </DialogActions>
    </ChangePasswordModalContainer>
  );
}

ChangePasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExit: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

export default ChangePasswordModal;
