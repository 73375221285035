import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';

import ButtonStyled from './styles';

const TextEditButtons = forwardRef(({ onCancel, onSubmit }, ref) => {
  return (
    <Box
      ref={ref}
      sx={{ display: 'flex', gap: '4px' }}
    >
      <ButtonStyled
        contained="true"
        onClick={onSubmit}
      >
        <DoneIcon />
      </ButtonStyled>

      <ButtonStyled
        onClick={onCancel}
      >
        <CloseIcon />
      </ButtonStyled>
    </Box>
  );
});

TextEditButtons.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default TextEditButtons;
