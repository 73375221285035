import styled from 'styled-components';

export const StyledRow = styled('tr').attrs({ className: 'line' })`
  height: 40px;
  
  & > td {
    border-top: 1px solid #EEEEEE;

    &.name-cell {
      cursor: pointer;      
    }
  }

  &:hover {
    background-color: #F5FCFD;
  }
`;
