import React, { useCallback } from 'react';
import PT from 'prop-types';

import TableCell from '@mui/material/TableCell';

import EditField from './EditField';
import ReadField from './ReadField';
import { rolesIds, hasAdminAccess } from '../../../../../utils/roles';

const PermissionCell = ({
  getValue,
  table: {
    customState: {
      readOnly,
      viewerId,
      onRoleChange
    } = {}
  },
  row: {
    data: { userId, isOrganization },
  }
}) => {
  const value = getValue();
  const validValue = hasAdminAccess(value) ? rolesIds.ADMIN : value;
  const canEdit = viewerId !== userId && !readOnly;

  const handleRoleChange = useCallback(value => {
    const subjectId = isOrganization ? 'organization' : userId;

    onRoleChange(subjectId, value);
  }, [isOrganization, onRoleChange, userId]);

  return (
    <TableCell sx={{ p: '0 0 0 5px' }}>
      {canEdit
        ? (
          <EditField
            value={validValue}
            onRoleChange={handleRoleChange}
          />
        )
        : <ReadField value={validValue} />
      }
    </TableCell>
  );
};

PermissionCell.propTypes = {
  getValue: PT.func.isRequired,
  table: PT.shape({
    customState: PT.shape({
      readOnly: PT.bool,
      viewerId: PT.string.isRequired,
      onRoleChange: PT.func.isRequired
    })
  }),
  row: PT.shape({
    data: PT.shape({
      userId: PT.string,
      isOrganization: PT.bool
    })
  })
};

export default PermissionCell;
