import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import SingleView from './SingleView';
import TableIcon from '../../../../components/Icons/TableIcon';

import { TABLE_VIEW } from '../../constants';
import { IconButtonStyled } from './styles';

const ViewsMenu = ({
  displayView,
  onChange,
  disableDashboard,
  insightsData,
  disabledText
}) => {
  const hasDashboards = Boolean(insightsData.insights.length);

  const dashboardsMenu = useMemo(() => {
    if (hasDashboards){
      const dashboards = insightsData.insights;
      return sortBy(
        dashboards,
        ({ title }) => title.toLowerCase())
        .map(item => ({
          id: item.id,
          label: item.title,
          icon: item.icon
        }));
    }

    return [];
  }, [hasDashboards, insightsData.insights]);

  const handleSwitchView = useCallback((value) => {
    onChange(value);
  }, [onChange]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        textWrap: 'nowrap',
      }}
    >
      <Typography
        sx={{
          fontSize: '12px'
        }}
      >
        View as:
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '0 10px',
          gap: '2px'
        }}
      >
        <Tooltip
          arrow
          title="Table"
        >
          <IconButtonStyled
            onClick={() => handleSwitchView(TABLE_VIEW)}
            active={displayView === TABLE_VIEW}
            disableRipple
          >
            <TableIcon sx={{ height: '14px' }} />
          </IconButtonStyled>
        </Tooltip>

        {dashboardsMenu.map((dashboardItem) => (
          <SingleView
            key={dashboardItem.id}
            dashboardItem={dashboardItem}
            onSwitchView={handleSwitchView}
            disabled={disableDashboard}
            disabledText={disabledText}
            active={displayView === dashboardItem.id}
          />
        ))}
      </Box>
    </Box>
  );
};

ViewsMenu.propTypes = {
  onChange: PropTypes.func.isRequired,
  displayView: PropTypes.string,
  disableDashboard: PropTypes.bool,
  insightsData: PropTypes.object,
  disabledText: PropTypes.string,
};

export default ViewsMenu;
