import React from 'react';
import { useQuery } from '@apollo/client';

import { Outlet, useLocation, Navigate } from 'react-router-dom';

import { useAuth } from '../../../context/auth/auth';

import { routes } from '../../../services/session/constants';

import VIEWER_BACIS from './gql/viewer-basic';

const NotAuthenticated = () => {
  const { user, emailVerified } = useAuth();
  const location = useLocation();
  const { data, loading } = useQuery(VIEWER_BACIS, { skip: !emailVerified });

  if(user) {
    const dest = location.state?.from;

    if(!emailVerified)
      return (
        <Navigate
          to={routes.EMAIL_VERIFICATION}
          replace
          state={{ from: dest }}
        />
      );

    if(loading && !data)
      return null; // TODO: find a better intermediate screen state

    if(!data?.viewer)
      return (
        <Navigate
          to={routes.REGISTRATION_DETAILS}
          replace
          state={{ from: dest }}
        />
      );

    return (
      <Navigate
        to={dest ?? routes.HOME_PAGE}
        replace
      />
    );
  }

  return (
    <Outlet />
  );
};

export default NotAuthenticated;
