import React, { useCallback, useState } from 'react';
import PT from 'prop-types';
import { useAnalytics } from 'use-analytics';

import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import EditIcon from '@mui/icons-material/Edit';

import EnumDialog from './EnumDialog';

import { TRACK_CHOICE_FIELD, componentNames } from '../../../../../../../../analytics/constants';

const Enum = ({ parameterTitle, value, onChange }) => {
  const { track } = useAnalytics();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleEdit = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const handleSubmit = useCallback(enumValueOptions => {
    onChange({ enumValueOptions });
    setIsDialogOpen(false);

    track(TRACK_CHOICE_FIELD.update, {
      component: componentNames.TABLE_STRUCTURE
    });
  }, [onChange, track]);

  const handleClose = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const options = value?.enumValueOptions ?? [];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: '4px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '4px',
          minWidth: '0',
          flexWrap: 'wrap'
        }}
      >
        {options.map(i =>
          <Chip
            key={i.title}
            label={i.title}
            sx={{
              borderRadius: '4px',
              height: '28px',
              background: '#EEEEEE'
            }}
          />
        )}
      </Box>

      <IconButton
        size="small"
        onClick={handleEdit}
      >
        <EditIcon fontSize="12px" />
      </IconButton>

      {isDialogOpen &&
        <EnumDialog
          items={options}
          onSubmit={handleSubmit}
          onClose={handleClose}
          parameterTitle={parameterTitle}
        />
      }
    </Box>
  );
};

Enum.propTypes = {
  parameterTitle: PT.string,
  onChange: PT.func,
  value: PT.shape({
    enumValueOptions: PT.arrayOf(
      PT.shape({
        title: PT.string
      })
    ).isRequired
  })
};

export default Enum;
