import { gql } from '@apollo/client/core';

export const GET_FOLDERS = gql`
  query Folders {
    folders {
      id
      title
      viewerMaxRole
    }
  }
`;

export const MOVE_FOLDER = gql`
  mutation UpdateFolder($updateFolderId: ID!, $data: UpdateFolderInput!) {
    updateFolder(id: $updateFolderId, data: $data) {
      id
      parentId
      path {
        id
      }
    }
  }
`;

